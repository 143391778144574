import React from 'react';
import { Slider, makeStyles } from 'connect-web-ui/mui';
import { Tooltip } from 'connect-web-ui';
import { thumbsUp } from 'assets/old_shared';

type ScoreSliderStylesType = {
  isSmall: boolean;
  value: number;
};

const useStyles = makeStyles({
  container: {
    marginRight: '14px',
    position: 'relative',
  },
  root: {
    height: (props: ScoreSliderStylesType) => (props.isSmall ? 10 : 14),
  },
  thumb: {
    height: props => (props.isSmall ? 16 : 22),
    width: props => (props.isSmall ? 16 : 22),
    backgroundColor: props => (props.value >= 80 ? '#30a960' : '#ff7d32'),
    border: props => `${props.isSmall ? 3 : 4}px solid white`,
    boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.45)',
    '&:focus, &:hover, &.MuiSlider-active': {
      boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.45)',
    },
  },
  valueLabel: {
    top: 2,
    fontSize: '12px',
    background: 'transparent',
    '& *': {
      background: 'transparent',
      fontWeight: 'bold',
      color: '#fe7d32',
    },
    '&> * > *::after': {
      content: '"%"',
    },
  },
  track: {
    backgroundImage: props =>
      props.value >= 80
        ? 'linear-gradient(to right, #ffb415 0%, #fe7d32 50%, #30a960 98%)'
        : 'linear-gradient(89deg, #ffb415 7%, #ffa83c 56%, #ff7d32 100%)',
    height: props => (props.isSmall ? 10 : 14),
    borderRadius: 4,
    borderColor: 'transparent',
  },
  rail: {
    height: props => (props.isSmall ? 10 : 14),
    borderRadius: 4,
    backgroundColor: 'rgba(33, 33, 33, 0.3)',
  },
  mark: {
    width: 2,
    height: props => (props.isSmall ? 10 : 14),
    marginTop: 0,
    backgroundColor: 'white',
  },
  endThumbs: {
    position: 'absolute',
    height: props => (props.isSmall ? 16 : 24),
    width: props => (props.isSmall ? 16 : 24),
    backgroundColor: 'white',
    right: props => (props.isSmall ? -13 : -20),
    top: props => (props.isSmall ? 10 : 8),
    boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.45)',
    borderRadius: '50%',
    '& img': {
      height: props => (props.isSmall ? 10 : 14),
      width: props => (props.isSmall ? 10 : 14),
    },
  },
});

const marks = [{ value: 20 }, { value: 40 }, { value: 60 }, { value: 80 }];

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip placement="bottom" content={value} variant="popover">
      {children}
    </Tooltip>
  );
}

export default function ScoreSlider(props) {
  const {
    value,
    showLabel = false,
    isSmall = false,
    showTooltip = false,
    tooltipContent,
  } = props;
  const { container, endThumbs, ...sliderStyles } = useStyles({
    isSmall,
    value,
  });

  const extraProps: {
    components?: Record<string, React.ComponentType>;
    valueLabelFormat?: string;
  } = {};
  if (showTooltip) {
    extraProps.components = { ValueLabel: ValueLabelComponent };
    extraProps.valueLabelFormat = tooltipContent;
  }

  return (
    <div className={container}>
      <Slider
        value={value}
        min={0}
        max={100}
        marks={marks}
        valueLabelDisplay={showLabel ? 'on' : 'off'}
        classes={sliderStyles}
        {...extraProps}
      />
      <span className={`${endThumbs} flex-centralized`}>
        <img src={thumbsUp} alt="" />
      </span>
    </div>
  );
}
