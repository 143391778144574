import React, { useState, useContext, useEffect } from 'react';

import {
  LegacyFormElement as FormElement,
  Checkbox,
  ToggleButton,
  Dropdown,
  Calendar,
} from 'connect-web-ui';
import { useRangeCalendar } from 'connect-web-ui/hooks';
import { HOURLY, invDateFormat } from '../../constants';
import {
  getFutureDate,
  getFormattedDate,
} from '../../../../utilities/DateUtils';
import { useLangStrings } from '../../../../utilities/CustomHooks';
import { InventoryContext } from '../../../../utilities/Context';
import { ValidFunnelsType } from 'interfaces/InventoryContextInterface';
import {
  DateSelectionPropsType,
  UseDateStateType,
  UseDateStateTypeReturnType,
} from 'modules/inventory/Inventory.type';
import { inventoryMaxFutureDays } from '../../../../constants';

export const getDropdownOptions = (validFunnels: ValidFunnelsType) =>
  validFunnels.map(item => ({
    label: item.label,
    value: item.key,
  }));

const dateValueMap = {
  0: 'Mon',
  1: 'Tue',
  2: 'Wed',
  3: 'Thu',
  4: 'Fri',
  5: 'Sat',
  6: 'Sun',
};

const DayOptions = [
  { value: 0, content: 'M' },
  { value: 1, content: 'T' },
  { value: 2, content: 'W' },
  { value: 3, content: 'T' },
  { value: 4, content: 'F' },
  { value: 5, content: 'S' },
  { value: 6, content: 'S' },
];

function DateSelection(props: DateSelectionPropsType) {
  const {
    //From hook
    popoverState,
    calendarProps,
    selectedDays,
    selectedContract,
    setSelectedContract,
    updateSelectedDays,
    showNettRates,
    setShowNettRates,
    showExtraRates,
    setShowExtraRates,
    //Extra info
    showRateOptions = false,
    inPopup = false,
    activeTab,
    errorMsg,
  } = props;

  const [STRINGS] = useLangStrings<'Inventory'>('Inventory');
  const { validFunnels } = useContext(InventoryContext);

  const minDate = new Date();
  const maxFutureDate = getFutureDate(minDate, inventoryMaxFutureDays);

  useEffect(() => {
    popoverState.showCalendar();
  }, []);

  return (
    <div className="d-flex marginT8" style={{ marginBottom: '-20px' }}>
      {showRateOptions && !inPopup && activeTab !== HOURLY && (
        <FormElement
          style={{ width: '180px', marginRight: '26px' }}
          label={STRINGS.POPUP_COMMON.SELECT_CONTRACT_TYPE}
        >
          <Dropdown
            disableClearable
            value={selectedContract}
            onChange={setSelectedContract}
            options={getDropdownOptions(validFunnels)}
            variant="default"
          />
        </FormElement>
      )}
      <FormElement
        label={STRINGS.POPUP_COMMON.DATE_RANGE_LABEL}
        errorMsg={errorMsg}
      >
        <Calendar
          type="input"
          popoverState={popoverState}
          calendarProps={calendarProps}
          disabledBefore={minDate}
          disabledAfter={maxFutureDate}
        />
      </FormElement>
      <FormElement
        label={`${STRINGS.POPUP_COMMON.SELECTED_DAYS} (${selectedDays.length})`}
        containerClass="sr-flex-col"
        style={{ marginBottom: inPopup ? '-8px' : '' }}
      >
        <ToggleButton
          data={DayOptions}
          value={selectedDays}
          onChange={updateSelectedDays}
          isMultiple
          disableRipple
        />
        {showRateOptions && inPopup && (
          <div className="mt-2">
            <Checkbox checked={showNettRates} onChange={setShowNettRates}>
              {`${STRINGS.POPUP_COMMON.NETT_RATE}`}
            </Checkbox>
          </div>
        )}
      </FormElement>
      {showRateOptions && !inPopup && activeTab !== HOURLY && (
        <div className="ml-auto sr-flex-col gap-3">
          <Checkbox checked={showNettRates} onChange={setShowNettRates}>
            {STRINGS.POPUP_COMMON.NETT_RATE}
          </Checkbox>
          <Checkbox checked={showExtraRates} onChange={setShowExtraRates}>
            {STRINGS.POPUP_COMMON.EXTRA_GUESTS}
          </Checkbox>
        </div>
      )}
    </div>
  );
}

export const useDateState: UseDateStateType = function (defaultContractType) {
  //Days selection
  const { popoverState, calendarProps } = useRangeCalendar();
  const [selectedDays, setSelectedDays] = useState(
    DayOptions.map(item => item.value),
  );
  const [showNettRates, setShowNettRates] = useState(false);
  const [showExtraRates, setShowExtraRates] = useState(false);
  const { validFunnels } = useContext(InventoryContext);
  const contractType =
    defaultContractType ?? getDropdownOptions(validFunnels)[0];
  const [selectedContract, setSelectedContract] = useState(contractType);

  const updateSelectedDays = newVal => {
    if (newVal.length !== 0) {
      setSelectedDays(newVal);
    }
  };

  return {
    popoverState,
    calendarProps,
    selectedDays,
    selectedContract,
    setSelectedContract,
    updateSelectedDays,
    showNettRates,
    setShowNettRates,
    showExtraRates,
    setShowExtraRates,
  };
};

export function getDisplayText(selectedDays: number[]) {
  if (selectedDays.length === 7) {
    return 'All Days';
  }
  return selectedDays.map(value => dateValueMap[value]).join(', ');
}

export function addDateInfo(payload, dateState: UseDateStateTypeReturnType) {
  payload.date_range_list = [
    {
      from_date: getFormattedDate(
        dateState.calendarProps.selectedDays.from,
        invDateFormat,
      ),
      to_date: getFormattedDate(
        dateState.calendarProps.selectedDays.to,
        invDateFormat,
      ),
    },
  ];
  if (dateState.selectedDays.length) {
    payload.day_list = dateState.selectedDays;
  }
  return payload;
}

export default DateSelection;
