import React, { useEffect, useState } from 'react';
import {
  CreateAccountCont,
  CreateAccountHeader,
  FormWrapper,
  ButtonWrapper,
  TncWrapper,
  HeaderRight,
  PasswordHintWrapper,
  Success,
  Error,
  CheckInboxWrapper,
  CheckInboxHeading,
  CheckInboxDesc,
  CheckInboxNote,
  FormFieldHint,
} from './CreateAccount.styles';
import { SignUpButton } from '../PageHeader/PageHeader.styles';
import {
  RedCrossIcon,
  CheckCircleIcon,
  InboxIcon,
  VisibilityIcon,
} from 'assets/modules/landing';
import { useForm } from 'react-hook-form';
import { ControlledCustomInputField } from 'components/cosmos-components/CustomInputField';
import {
  atleastOneAlphanumericRegex,
  emailRegex,
  pwdSpecialCharRegex,
  pwdWithAtleastOneAlpaNumericAndSplCharRegex,
} from 'utilities/allRegex';
import { colors } from 'style/zeplin-token-styledComponents';
import useStrings from 'modules/landingPage/hooks/useStrings';
import { useRegisterUser } from 'modules/landingPage/api/landingPageReactQueryHooks';
import { getFormattedString } from 'utilities/Utils';
import {
  EXTERNAL_URLS,
  PASSWORD_MIN_CHAR_LENGTH,
  COMMON_OMNITURE_VARIABLES,
} from 'modules/landingPage/constants';
import { OMNITURE_CATEGORIES, pushToOmniture } from 'utilities/gtm';
import { connectLogo } from 'assets/common';

const getPasswordHint = (password: string = '') => {
  const status = {
    minLength: false,
    alphanumeric: false,
    specialChar: false,
  };

  if (password.length >= PASSWORD_MIN_CHAR_LENGTH) {
    status.minLength = true;
  }

  if (password.match(atleastOneAlphanumericRegex)) {
    status.alphanumeric = true;
  }

  if (password.match(pwdSpecialCharRegex)) {
    status.specialChar = true;
  }

  return status;
};

const PasswordHint = ({ password = '' }) => {
  const [STRINGS] = useStrings();
  const { minLength, alphanumeric, specialChar } = getPasswordHint(password);

  return (
    <PasswordHintWrapper>
      {minLength ? (
        <Success>
          <CheckCircleIcon />
          &nbsp; {STRINGS.FORM_FIELD_HINTS.PASSWORD_MIN_CHAR}
        </Success>
      ) : (
        <Error>
          <RedCrossIcon />
          &nbsp; {STRINGS.FORM_FIELD_HINTS.PASSWORD_MIN_CHAR}
        </Error>
      )}
      {alphanumeric ? (
        <Success>
          <CheckCircleIcon />
          &nbsp; {STRINGS.FORM_FIELD_HINTS.PASSWORD_ALPHANUMERIC}
        </Success>
      ) : (
        <Error>
          <RedCrossIcon />
          &nbsp; {STRINGS.FORM_FIELD_HINTS.PASSWORD_ALPHANUMERIC}
        </Error>
      )}
      {specialChar ? (
        <Success>
          <CheckCircleIcon />
          &nbsp; {STRINGS.FORM_FIELD_HINTS.PASSWORD_SPECIAL_CHAR}
        </Success>
      ) : (
        <Error>
          <RedCrossIcon />
          &nbsp; {STRINGS.FORM_FIELD_HINTS.PASSWORD_SPECIAL_CHAR}
        </Error>
      )}
    </PasswordHintWrapper>
  );
};

const INITIAL_FORM_DATA = {
  fullName: '',
  email: '',
  createPassword: '',
  confirmPassword: '',
};

interface CreateAccountProps {
  email: string;
}

const buildErrorPipeString = (formData, formErrors) => {
  const result = [];

  if (formErrors.fullName) {
    result.push('name_missing');
  }

  if (formErrors.email) {
    if (formErrors.email.type === 'required') {
      result.push('email_missing');
    } else {
      result.push('email_invalid');
    }
  }

  if (formErrors.createPassword) {
    if (formErrors.createPassword.type === 'required') {
      result.push('password_missing');
    } else {
      const { minLength, alphanumeric, specialChar } = getPasswordHint(
        formData.password,
      );

      if (!minLength) {
        result.push('num_characters');
      }

      if (!alphanumeric) {
        result.push('alphanumeric');
      }

      if (!specialChar) {
        result.push('special_characters');
      }
    }
  }

  if (formErrors.confirmPassword) {
    if (formErrors.confirmPassword.type === 'required') {
      result.push('confirm_password_missing');
    } else {
      result.push('password_do_not_match');
    }
  }

  return result.join(' | ');
};

const CreateAccount = ({ email: verifiedEmail }: CreateAccountProps) => {
  const [STRINGS, COMMON] = useStrings();
  const [showSuccess, setShowSuccess] = useState(false);
  const { control, handleSubmit, watch, getValues, formState } = useForm({
    defaultValues: { ...INITIAL_FORM_DATA, email: verifiedEmail },
  });
  const { isSuccess, mutate: registerUser } = useRegisterUser();

  const { email, createPassword: password } = watch();

  const onSubmit = formData => {
    registerUser({
      email: formData.email,
      fullName: formData.fullName,
      password: formData.createPassword,
    });
  };

  const onError = errors => {
    pushToOmniture({
      ...COMMON_OMNITURE_VARIABLES,
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      loadedComponents: buildErrorPipeString(getValues(), errors),
      cta: {
        name: 'continue_failure',
        type: 'click',
        componentName: 'create_account',
      },
    });
  };

  const validateConfirmPassword = () => {
    const [createPassword, confirmPassword] = getValues([
      'createPassword',
      'confirmPassword',
    ]);

    if (createPassword !== confirmPassword) {
      return STRINGS.FORM_VALIDATIONS.CONFIRM_PASSWORD;
    } else if (formState.errors.createPassword) {
      return formState.errors.createPassword.message;
    }

    return true;
  };

  const handlePrivacyPolicyClick = () => {
    pushToOmniture({
      ...COMMON_OMNITURE_VARIABLES,
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      cta: {
        name: 'privacy_policy',
        type: 'click',
        componentName: 'create_account',
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setShowSuccess(true);

      pushToOmniture({
        ...COMMON_OMNITURE_VARIABLES,
        event: OMNITURE_CATEGORIES.CTA_LOAD,
        cta: {
          name: 'confirmation_link_sign_up',
          type: 'click',
          componentName: 'confirmation_link_sent',
        },
      });

      sessionStorage.setItem('confirmation_link_sent', 'true');
    }
  }, [isSuccess]);

  useEffect(() => {
    pushToOmniture({
      ...COMMON_OMNITURE_VARIABLES,
      event: OMNITURE_CATEGORIES.CTA_LOAD,
      cta: {
        name: 'create_account',
        type: 'load',
        componentName: 'create_account',
      },
    });
  }, []);

  return (
    <CreateAccountCont>
      {showSuccess ? (
        <CheckInboxWrapper>
          <InboxIcon />
          <CheckInboxHeading>
            {STRINGS.createAccountForm.check_inbox_data.title}
          </CheckInboxHeading>
          <CheckInboxDesc>
            {getFormattedString(
              STRINGS.createAccountForm.check_inbox_data.description,
              { email },
            )}
          </CheckInboxDesc>
          <CheckInboxNote>
            {STRINGS.createAccountForm.check_inbox_data.bottom_note}
            <span>
              {STRINGS.createAccountForm.check_inbox_data.bottom_note_subText}
            </span>
          </CheckInboxNote>
        </CheckInboxWrapper>
      ) : (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <CreateAccountHeader>
            <img
              src={connectLogo}
              width="52px"
              alt="ingo_logo"
              className="mr-3"
            />
            <HeaderRight>
              <p>{STRINGS.createAccountForm.heading}</p>
            </HeaderRight>
          </CreateAccountHeader>
          <FormWrapper>
            <ControlledCustomInputField
              placeholder={STRINGS.FORM_PLACEHOLDERS.FULL_NAME}
              labelColor={colors.grayMediumEmphasis}
              name="fullName"
              label={STRINGS.FORM_LABELS.FULL_NAME}
              control={control}
              rules={{
                required: COMMON.MESSAGES.VALID_NAME_TEXT,
              }}
              tabIndex={1}
            />
            <ControlledCustomInputField
              labelColor={colors.grayMediumEmphasis}
              placeholder={STRINGS.FORM_PLACEHOLDERS.EMAIL}
              name="email"
              label={STRINGS.FORM_LABELS.EMAIL}
              defaultValue={email}
              control={control}
              rules={{
                required: COMMON.MESSAGES.VALID_EMAIL_TEXT,
                pattern: {
                  value: emailRegex,
                  message: STRINGS.FORM_VALIDATIONS.EMAIL,
                },
              }}
              footerComponent={
                <FormFieldHint>{STRINGS.FORM_FIELD_HINTS.EMAIL}</FormFieldHint>
              }
            />
            <ControlledCustomInputField
              labelColor={colors.grayMediumEmphasis}
              type="password"
              placeholder={STRINGS.FORM_PLACEHOLDERS.CREATE_PASSWORD}
              name="createPassword"
              label={STRINGS.FORM_LABELS.PASSWORD}
              control={control}
              rules={{
                required: COMMON.MESSAGES.VALID_PWD_TEXT,
                pattern: pwdWithAtleastOneAlpaNumericAndSplCharRegex,
                minLength: PASSWORD_MIN_CHAR_LENGTH,
              }}
              footerComponent={
                password ? (
                  <PasswordHint password={password} />
                ) : (
                  <FormFieldHint>
                    {STRINGS.FORM_FIELD_HINTS.PASSWORD}
                  </FormFieldHint>
                )
              }
              endIcon={<VisibilityIcon />}
              tabIndex={2}
            />
            <ControlledCustomInputField
              labelColor={colors.grayMediumEmphasis}
              type="password"
              placeholder={STRINGS.FORM_PLACEHOLDERS.CONFIRM_PASSWORD}
              name="confirmPassword"
              label={STRINGS.FORM_LABELS.CONFIRM_PASSWORD}
              control={control}
              rules={{
                required: COMMON.MESSAGES.VALID_CONFIRM_PWD_TEXT,
                validate: validateConfirmPassword,
              }}
              endIcon={<VisibilityIcon />}
              tabIndex={3}
            />

            <ButtonWrapper>
              <SignUpButton type="submit">
                {STRINGS.createAccountForm.button_text}
              </SignUpButton>
            </ButtonWrapper>
            <TncWrapper>
              {STRINGS.createAccountForm.extraInfo}{' '}
              <a
                href={EXTERNAL_URLS.PRIVACY_POLICY}
                target="_blank"
                onClick={handlePrivacyPolicyClick}
              >
                {STRINGS.createAccountForm.privacyPolicy}
              </a>
            </TncWrapper>
          </FormWrapper>
        </form>
      )}
    </CreateAccountCont>
  );
};

export default CreateAccount;
