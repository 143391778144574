import { useEffect, useState } from 'react';
import { Toast } from 'connect-web-ui';
import { AppEvents } from '../utilities/EventBus';
import CustomToast from 'components/cosmos-components/CustomToast';

type State = {
  show: boolean;
  message: string;
  type: 'success' | 'info' | 'warning' | 'error';
  variant: 'cosmos' | 'default';
  persistUntilClear: boolean;
  title?: string;
};

const initialState: State = {
  variant: 'default',
  show: false,
  message: '',
  type: 'success',
  persistUntilClear: false,
  title: '',
};

export default function FloatingMessage() {
  const [
    { variant = 'default', show, message, type, persistUntilClear, title },
    showMessage,
  ] = useState(initialState);

  useEffect(() => {
    const showMessageHandler = data => {
      showMessage(oldData => {
        if (oldData.persistUntilClear) {
          return oldData;
        }
        return data;
      });
    };

    AppEvents.on('showToast', showMessageHandler);
    return () => {
      AppEvents.off('showToast', showMessageHandler);
    };
  }, []);

  const closeHandler = () => showMessage(initialState);

  if (!show) {
    return null;
  }

  return variant === 'cosmos' ? (
    <CustomToast
      type={type}
      message={message}
      visible={show}
      onClose={closeHandler}
      showOverlay={false}
    />
  ) : (
    <Toast
      type={type}
      onClose={closeHandler}
      open
      autoHide={!persistUntilClear}
      title={title}
      message={message}
    />
  );
}
