import { useContext, useEffect, useState } from 'react';

import { HotelContext } from 'utilities/Context';
import { isNullOrUndefined, showMessage } from 'utilities/Utils';
import { routes } from '../app/routes';
import { pushSLEventToGTM } from '../modules/sponsored-listing/SponsoredListingConstants';
import { getTouchPointsDetails } from '../modules/sponsored-listing/SponsoredListingGraphClient';
import {
  getPendingRTBRequestsData,
  getGroupRatesPersuasion,
} from './SharedGraphClient';

export function useRTBPendingBookings() {
  const hotelContext = useContext(HotelContext);
  const currency = hotelContext?.currentHotel?.base_currency ?? 'INR';
  const hotelCode = hotelContext?.currentHotel?.hotelcode ?? null;
  const [pendingRequestsData, setPendingRequestsData] = useState({});

  useEffect(() => {
    if (!hotelCode) return;
    getPendingRTBRequestsData(hotelCode)
      .then(res => {
        const data = res.data?.rawEndpoint.analyticsPersuasion.modelType1 ?? {};
        if (data.status === 'error') {
          showMessage({
            type: 'error',
            show: true,
            message: data.message,
          });
        }

        if (data && data.status === 'success' && data.data.count > 0) {
          data.showPersuasion = true;
          data.totalOpportunityLoss = `${currency} ${data.data.total_opportunity_loss}`;
        }

        setPendingRequestsData(data);
      })
      .catch(error => {
        console.error('Get pending RTB requests data', error);
      });
  }, [hotelCode]);

  return {
    pendingRequestsData,
  };
}

export function useTouchPointsSL(pageType, onSectionLoadUpdate, gtmLabel) {
  const {
    currentHotel: {
      hotelcode: hotelCode,
      isactive,
      voyagerid: voyagerId,
      mmt_id: mmtid,
      base_currency,
    },
  } = useContext(HotelContext);

  const [touchPoints, setTouchPoints] = useState({});

  useEffect(() => {
    if (!hotelCode || !isactive) {
      onSectionLoadUpdate && onSectionLoadUpdate(false, gtmLabel);
      return;
    }
    getTouchPointsDetails(hotelCode, mmtid, voyagerId, pageType, base_currency)
      .then(res => {
        onSectionLoadUpdate && onSectionLoadUpdate(true, gtmLabel);
        const { sponsoredList } = res.data.rawEndpoint ?? {};
        const touchPointsData = sponsoredList?.modelType1?.persuasionList;
        const gaConfig = {
          isPersuasion: true,
          pageType: pageType,
          functionality: touchPointsData[0]?.persuasion?.htmlContent,
          omnitureData: { skip: true },
        };
        const cta = touchPointsData[0]?.persuasion?.cta?.action;
        const isGraph = !isNullOrUndefined(touchPointsData[0]?.revenueGraph);
        if (!isNullOrUndefined(touchPointsData)) {
          pushSLEventToGTM(
            'sponsored_listing',
            `load|persuasion_${cta}_graph_${isGraph}`,
            gaConfig,
          );
        }
        setTouchPoints(touchPointsData);
      })
      .catch(() => {
        onSectionLoadUpdate && onSectionLoadUpdate(false, gtmLabel);
      });
  }, [hotelCode, pageType]);

  return {
    touchPoints,
  };
}

export function useGroupRatesPersuasion() {
  const {
    currentHotel: {
      hotelcode: hotelCode,
      has_enrolled_in_group_booking: isGBEnrolled,
      is_eligible_for_group_booking: isGBEligible,
    },
  } = useContext(HotelContext);

  const [persuasionData, setPersuasionData] = useState(null);

  useEffect(() => {
    if (isGBEligible && !isGBEnrolled) {
      getGroupRatesPersuasion(hotelCode)
        .then(response => {
          const data = response && {
            title: response.persuasion_title,
            desc: response.message,
            ctaName: 'Enroll Now',
            redirectionUrl: routes.groupBookings.base,
          };
          setPersuasionData(data);
        })
        .catch(error => {
          console.error('Get group rates persuasion', error);
        });
    }
  }, [hotelCode]);

  return {
    persuasionData,
  };
}
