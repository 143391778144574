import { doQuery, doMutation } from '../../base/GraphQLClient';
import {
  configKeeperQuery,
  createServiceTicketQuery,
  filterServiceTicketQuery,
} from './HelpCentreGraphQueries';

export const getConfigOptions = () => {
  const variables = {
    rawInput: {
      service: 'alt_acco',
      templateId: 'ticket_groups_INGO',
    },
  };
  return doQuery(configKeeperQuery, variables);
};
export const createTicket = props => {
  const {
    customerId,
    customerFname,
    customerLname,
    customerEmail,
    customerContact,
    issueCategory,
    issueSubCategory,
    hotelCode,
    comments,
  } = props;
  const variables = {
    rawInput: {
      customerId: customerId,
      customerFname: customerFname,
      customerMname: '',
      customerLname: customerLname,
      customerEmail: customerEmail,
      customerContact: customerContact,
      issueCategory: issueCategory,
      issueSubCategory: issueSubCategory,
      hotelCode: hotelCode,
      comments: comments,
      org: 'MMT',
    },
  };
  return doMutation(createServiceTicketQuery, variables);
};

export const filterTicket = (customerId, hotelCode) => {
  const variables = {
    rawInput: {
      customerId: String(customerId),
      hotelCode,
      issueCategory: '',
      issueSubCategory: '',
      status: [0, 1, 2, 3],
      limit: 500,
    },
  };
  return doMutation(filterServiceTicketQuery, variables);
};
