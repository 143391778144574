import { colors, typography } from 'style/zeplin-token-styledComponents';
import styled from 'styled-components';

export const DownloadAppSectionStyled = styled.section`
  background-image: linear-gradient(
    130deg,
    #fff2e3 6%,
    rgba(255, 233, 229, 0.74) 26%,
    #f4ead6 80%
  );
  padding: 60px 0;
  text-align: center;
`;

export const Heading = styled.h3`
  margin-bottom: 16px;
  ${typography.shoutoutBlack}
  color: ${colors.grayMediumEmphasis};
`;

export const SubHeading = styled.p`
  ${typography.subheadingRegular}
  color: ${colors.grayMediumEmphasis};

  span {
    ${typography.subheadingBold}
  }
`;

export const ImagesContainer = styled.div`
  margin-top: 47px;
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 65px;

  img:first-child {
    width: 427px;
    flex-shrink: 0;
  }

  img:last-child {
    width: 306px;
    flex-shrink: 0;
  }
`;

export const InfoMessage = styled.h5`
  margin-top: 45px;
  ${typography.subheadingRegular}
  color: ${colors.grayMediumEmphasis};
`;
