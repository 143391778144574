import { Modal } from 'connect-web-ui';
import { useContext, useEffect, useState } from 'react';
import { LogoutConfirmEvent } from 'utilities/EventBus';
import { AuthContext } from 'utilities/Context';
import { broadcastLoginInfoToOtherTabs, removeAPIToken } from 'utilities/Utils';

const LogoutConfirmPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  const {
    langStringsData: {
      langStrings: {
        Login: { LOGIN: STRINGS },
      },
    },
  } = useContext(AuthContext);

  const onConfirm = () => {
    setShowPopup(false);
    removeAPIToken();
    broadcastLoginInfoToOtherTabs('logout');

    window.location.reload();
  };

  useEffect(() => {
    LogoutConfirmEvent.on('logoutUser', () => setShowPopup(true));

    return () => {
      LogoutConfirmEvent.off('logoutUser', () => setShowPopup(true));
    };
  });

  if (showPopup) {
    return (
      <div>
        <Modal
          isOpen={showPopup}
          variant="info"
          header={{
            title: STRINGS.LOGOUT_CONFIRM_TITLE,
          }}
          footer={{
            primaryBtn: {
              text: STRINGS.RETURN,
              onClick: onConfirm,
            },
          }}
          customClasses={{
            footer: {
              primaryBtn: '!uppercase',
            },
          }}
        >
          <div>{STRINGS.LOGOUT_CONFIRM_TEXT}</div>
        </Modal>
      </div>
    );
  }
  return null;
};

export default LogoutConfirmPopup;
