import actionTypes from './actionTypes';
import { APIS, API_STATUS } from '../constants';

export const initialState = {
  apiStatus: {
    [APIS.CALSYNC_DATA]: { ...API_STATUS },
    [APIS.LEGACY_PARITY_PART_1]: { ...API_STATUS },
    [APIS.LEGACY_PARITY_PART_2]: { ...API_STATUS },
    [APIS.COMPSET_DATA]: { ...API_STATUS },
    [APIS.SALE_CAMPAIGNS]: { ...API_STATUS },
    [APIS.RESELLER_AGREEMENT]: { ...API_STATUS },
    [APIS.COMPSET_DATA]: { ...API_STATUS },
    [APIS.FEATURE_FLAGS]: { ...API_STATUS },
  },
  response: {
    [APIS.CALSYNC_DATA]: {
      calSyncEnabledRoomList: [],
      calSyncNotEnabledRoomList: [],
      calSyncMultiRoomList: [],
    },
    [APIS.LEGACY_PARITY_PART_1]: null,
    [APIS.LEGACY_PARITY_PART_2]: null,
    [APIS.COMPSET_DATA]: [],
    [APIS.SALE_CAMPAIGNS]: null,
    [APIS.RESELLER_AGREEMENT]: null,
    [APIS.COMPSET_DATA]: [],
    [APIS.FEATURE_FLAGS]: {
      newContentScoreFlag: false,
    },
  },
  error: {
    [APIS.CALSYNC_DATA]: null,
    [APIS.LEGACY_PARITY_PART_1]: null,
    [APIS.LEGACY_PARITY_PART_2]: null,
    [APIS.COMPSET_DATA]: null,
    [APIS.SALE_CAMPAIGNS]: null,
    [APIS.RESELLER_AGREEMENT]: null,
    [APIS.COMPSET_DATA]: null,
    [APIS.FEATURE_FLAGS]: null,
  },
};

const reducer = (
  state = initialState,
  { type = '', api = '', response = {}, exception = '' } = {},
) => {
  switch (type) {
    case actionTypes[api].FETCHING:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          [api]: {
            failed: null,
            fetching: true,
            success: null,
          },
        },
      };
    case actionTypes[api].SUCCESS:
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          [api]: {
            failed: false,
            fetching: false,
            success: true,
          },
        },
        response: {
          ...state.response,
          [api]: response,
        },
        error: {
          ...state.error,
          [api]: null,
        },
      };
    case actionTypes[api].FAILED:
      return {
        ...state,
        response: {
          ...state.response,
          [api]: null,
        },
        apiStatus: {
          ...state.apiStatus,
          [api]: {
            failed: true,
            fetching: false,
            success: false,
          },
        },
        error: {
          ...state.error,
          [api]: exception,
        },
      };
    case actionTypes[api].RESET:
      return {
        ...state,
        response: {
          ...state.response,
          [api]: null,
        },
        apiStatus: {
          ...state.apiStatus,
          [api]: { ...API_STATUS },
        },
        error: {
          ...state.error,
          [api]: null,
        },
      };
    case actionTypes[api].UPDATE: {
      return {
        ...state,
        response: {
          ...state.response,
          [api]: {
            ...response,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
