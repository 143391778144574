import { Box } from 'connect-web-ui/mui';
/** eslint-disable */
import {
  memo,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLangStrings, useWindowDimensions } from 'utilities/CustomHooks';
import * as NavigationIcons from '../../assets/modules/navigation';
import User from '../hotel/Users/User';

import { UserProfileType, UserProfileViewType } from 'app/hotel/constants';
import { routes } from 'app/routes';
import { ToggleButton, Typography, Divider } from 'connect-web-ui';
import { AppContext } from 'utilities/Context';
import { navigateTo } from 'utilities/Utils';
import { OMNITURE_CATEGORIES, pushToOmniture } from 'utilities/gtm';
import CounterCircle from './CounterCircle';
import { ChatAnimationIcon, ZeroChat } from 'assets/modules/app';
import SideNav from './SideNav/SideNavMain';
import { HEADER_HEIGHT, LEFT_NAV_WIDTH, MAX_SCREEN_BODY_WIDTH } from './const';
import type { LeftNavItem } from './SideNav/SideNav.types';
import { getUnreadSessionCounts } from 'group-property/modules/group-dashboard/GroupDashboardGraphClient';

type NavigationContainerProps = {
  children?: ReactNode;
  headerLeftComponent?: ReactNode;
  headerMiddleComponent?: ReactNode;
  showTopLevelUserProfileSwitcher?: boolean;
  userProfileView?: string;
  setUserProfileView?: React.Dispatch<
  React.SetStateAction<UserProfileViewType>
  >;
  hotelCode?: string;
  isChatEnabled?: boolean;
  pendingPrebuyOrdersCount?: number;
  tabList: LeftNavItem[];
  blockedForExtranet?: boolean;
};

const unReadMsgDataInitialState = {
  count: 0,
};

const getProfileOptions = ({ STRINGS, pendingPrebuyOrdersCount }) => [
  {
    content: STRINGS.NAVIGATION_CONTAINER.CONNECT,
    value: UserProfileViewType.INGO,
  },
  {
    content: (
      <div className="flex items-center gap-1">
        {STRINGS.NAVIGATION_CONTAINER.HOTEL_CLOUD}
        <CounterCircle count={pendingPrebuyOrdersCount} />
      </div>
    ),
    value: UserProfileViewType.HOTEL_TRAVEL,
  },
];

const NavigationContainer = (props: NavigationContainerProps) => {
  const {
    children,
    headerLeftComponent,
    headerMiddleComponent,
    userProfileView,
    setUserProfileView,
    showTopLevelUserProfileSwitcher = false,
    isChatEnabled,
    pendingPrebuyOrdersCount,
    hotelCode,
    tabList,
    blockedForExtranet = false,
  } = props;

  const { userProfileType, hotelList } = useContext(AppContext);
  const [STRINGS, COMMON] = useLangStrings<'Navigation'>('Navigation');

  const [chatUnreadMsgData, setChatUnreadMsgData] = useState(
    unReadMsgDataInitialState,
  );

  const { width: currentWindowWidth } = useWindowDimensions();

  const trackChatIconEvent = useCallback(
    (eventType: 'load' | 'hover_load' | 'click') => {
      const cta = {
        name: 'guest_chat_icon',
        componentName: 'property_header|top_right_icon_1',
        type: eventType,
      };
      const { count } = chatUnreadMsgData;
      switch (eventType) {
        case 'load':
          pushToOmniture({
            event: OMNITURE_CATEGORIES.CTA_LOAD,
            cta,
            loadedComponents: `unread_chats:${count}`,
          });

          break;
        case 'hover_load':
          pushToOmniture({
            event: OMNITURE_CATEGORIES.CTA_LOAD,
            cta,
            loadedComponents: `unread_chats:${count}`,
          });
          break;
        case 'click':
          pushToOmniture({
            event: OMNITURE_CATEGORIES.CTA_CLICK,
            cta,
            loadedComponents: `unread_chats:${count}`,
          });

          break;
      }
    },
    [chatUnreadMsgData],
  );

  const chatIconEventHandlers = useMemo(() => {
    const onMouseEnter: MouseEventHandler<HTMLElement> = () => {
      trackChatIconEvent('hover_load');
    };

    const onClick = () => {
      trackChatIconEvent('click');
      navigateTo(
        isChatEnabled
          ? chatUnreadMsgData.count
            ? routes.guestchat.unread
            : routes.guestchat.all
          : chatUnreadMsgData.count
            ? routes.groupchat.unread
            : routes.groupchat.all,
      );
    };
    return {
      onClick,
      onMouseEnter,
    };
  }, [trackChatIconEvent]);

  useEffect(() => {
    const chatDataLoaded = chatUnreadMsgData !== unReadMsgDataInitialState;
    if (!isChatEnabled || !chatDataLoaded) {
      return;
    }
    trackChatIconEvent('load');
  }, [isChatEnabled, chatUnreadMsgData, trackChatIconEvent]);
  const isGuestChatEnabled = useMemo(() => {
    if (hotelList && hotelList.length) {
      return hotelList.some(property => property?.is_chat_enabled);
    }
    return false;
  }, [hotelList]);

  const handleUserProfileToggle = val => {
    pushToOmniture({
      event: 'toggle',
      cta: {
        name: val,
        componentName: 'toggle_account',
      },
    });

    setUserProfileView(val);
  };

  const renderHotelTravelInfo = () => {
    switch (userProfileType) {
      case UserProfileType.BOTH:
        return (
          <div>
            <ToggleButton
              data={getProfileOptions({ STRINGS, pendingPrebuyOrdersCount })}
              value={userProfileView}
              displayType="rounded-chip"
              onChange={val => handleUserProfileToggle(val)}
            />
          </div>
        );
      case UserProfileType.HOTEL_TRAVEL:
        return <NavigationIcons.HotelTravelIcon className="mr-2" />;
      default:
        return null;
    }
  };
  useEffect(() => {
    getUnreadSessionCounts()
      .then((res: { totalUnreadSession: number }) => {
        setChatUnreadMsgData({
          count: res.totalUnreadSession,
        });
      })
      .catch(error => {
        console.error('getUnreadSessionCounts', error);
      });
  }, []);

  return (
    <div className="navigation-container">
      <div className={`navigation-header h-[${HEADER_HEIGHT}px]`}>
        <Box
          sx={{ maxWidth: MAX_SCREEN_BODY_WIDTH, width: '100%' }}
          className="sr-flex1"
        >
          <div className="flex px-4">
            {headerLeftComponent}
            <div className="sr-flex1">{headerMiddleComponent}</div>
            <div className="flex flex-row items-center lg:gap-5 xmd:gap-2">
              {/* User Profile Switcher */}
              {showTopLevelUserProfileSwitcher && renderHotelTravelInfo()}
              {isGuestChatEnabled && !!hotelCode && (
                <div className="flex lg:gap-5 xmd:gap-3 items-center">
                  <div
                    className="relative cursor-pointer"
                    {...chatIconEventHandlers}
                  >
                    <div className="flex items-center flex-col hover:bg-color-primary hover:rounded-md h-[52px] py-1 px-2">
                      <img
                        src={
                          chatUnreadMsgData?.count === 0
                            ? ZeroChat
                            : ChatAnimationIcon
                        }
                        alt="chat icon"
                        width="40px"
                        height="40px"
                      />
                      {chatUnreadMsgData.count > 0 && (
                        <div className="absolute right-[8px] top-[0px]">
                          <CounterCircle count={chatUnreadMsgData.count} />
                        </div>
                      )}

                      <Typography
                        variant="legends"
                        themeColor="text.primary"
                        className="absolute bottom-[0px]"
                      >
                        {COMMON.LABELS.CHAT}
                      </Typography>
                    </div>
                  </div>
                  <Divider orientation="vertical" className="h-[40px]" />
                </div>
              )}
              {/* User Profile */}
              <User />
            </div>
          </div>
        </Box>
      </div>
      <Box
        sx={{
          height: `calc(100vh - ${HEADER_HEIGHT}px)`,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div className={'navigation-body w-[100%]'}>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <SideNav tabs={tabList} blockedForExtranet={blockedForExtranet} />
          </Box>
          <Box
            sx={{
              width: currentWindowWidth - LEFT_NAV_WIDTH,
              display: 'flex',
              overflow: 'scroll',
              justifyContent: 'center',
              position: 'relative',
            }}
            className="sr-flex1 !overflow-hidden scrollbar-thin"
          >
            <div className="nav-container scrollbar-thin">{children}</div>
          </Box>
        </div>
      </Box>
    </div>
  );
};

export default memo(NavigationContainer);
