import { useEffect, useMemo } from 'react';
import {
  ControlledInput,
  Modal,
  ControlledCheckbox,
  Button,
  LegacyFormElement as FormElement,
} from 'connect-web-ui';

import { acceptTnC } from './HotelAPIClient';
import { useForm, useWatch } from 'react-hook-form';
import {
  useLangStrings,
  useOpenDocumentLink,
} from '../../utilities/CustomHooks';
import { showMessage } from 'utilities/Utils';
import { ITnC, TnCModalFormType } from './interfaces';
import {
  domPhoneRegex,
  emailRegex,
  nameRegex,
  phoneRegex,
} from 'utilities/allRegex';
import {
  GTM_ACTIONS,
  OMNITURE_CATEGORIES,
  OMNITURE_EVENT_NAMES,
  pushToOmniture,
} from 'utilities/gtm';
import { IAgreement } from 'interfaces/HotelContextInterface';

type CompProps = {
  data: ITnC;
  isDomHotel: boolean;
  hotelCode: string;
  onClose: () => void;
};

function agreementString(agreement_list, _STRINGS) {
  let isHSAgreement = false;
  let STRINGS = _STRINGS.NORMAL;

  for (let i = 0; i < agreement_list.length; i++) {
    if (agreement_list[i].agreement_id === 289) {
      STRINGS = _STRINGS.IMPACT_COVID;
    } else if (agreement_list[i].agreement_id === 275) {
      isHSAgreement = true;
      STRINGS = _STRINGS.HS_AGREEMENT;
    } else if (
      agreement_list[i].agreement_id === 285 ||
      agreement_list[i].agreement_id === 287
    ) {
      STRINGS = _STRINGS.CLEANSTAY_OR_CORPBOOKING;
    } else if (
      agreement_list[i].agreement_id === 283 ||
      agreement_list[i].agreement_id === 284 ||
      agreement_list[i].agreement_id === 288
    ) {
      STRINGS = _STRINGS.CLEANSTAY_OR_CORPBOOKING;
    }
  }

  return { STRINGS, isHSAgreement };
}

export default function TermsAndConditionsModal(props: CompProps) {
  const {
    data: { agreement_list: agreementList, insurmountable },
    isDomHotel,
    onClose,
    hotelCode,
  } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<TnCModalFormType>({ shouldUnregister: true });
  const [{ TERMS_AND_CONDITION: STRINGS }, COMMON] =
    useLangStrings<'Dashboard'>('Dashboard');
  const [openDocument] = useOpenDocumentLink();

  const checkbox = useWatch({
    control,
    name: 'ignore',
  });

  const trackEvent = (eventName: string, data?: string) => {
    const event = {
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      cta: {
        name: eventName,
        type: GTM_ACTIONS.CLICK,
        componentName: 'agreement_pop_up',
      },
      pageType: 'Agreement pop up',
      loadedComponents: String(insurmountable),
    };

    if (
      eventName === OMNITURE_EVENT_NAMES.LOADED ||
      eventName === OMNITURE_EVENT_NAMES.ERROR
    ) {
      event.cta.name = data;
      event.cta.type = GTM_ACTIONS.LOAD;
      event.event = OMNITURE_CATEGORIES.CTA_LOAD;
    }

    if (eventName === OMNITURE_EVENT_NAMES.ERROR) {
      event.cta.componentName = 'agreement_pop_up|error';
    }

    pushToOmniture(event);
  };

  useEffect(() => {
    trackEvent(
      OMNITURE_EVENT_NAMES.LOADED,
      props.data.agreement_list
        .map(agreement => agreement.agreement_name)
        .join('|'),
    );
  }, []);

  useEffect(() => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      trackEvent(
        OMNITURE_EVENT_NAMES.ERROR,
        errorKeys.map(key => `${key}: ${errors[key].message}`).join('|'),
      );
    }
  }, [JSON.stringify(errors)]);

  const showError = () =>
    showMessage({
      show: true,
      message: COMMON.MESSAGES.SOMETHING_WENT_WRONG,
      type: 'error',
    });

  const onSubmit = postdata => {
    postdata.hotelcode = hotelCode;
    acceptTnC(hotelCode, postdata).then(onClose).catch(showError);
    trackEvent(OMNITURE_EVENT_NAMES.ACCEPT);
  };

  const viewAgreement = agreementUrl => {
    trackEvent(OMNITURE_EVENT_NAMES.VIEW_DETAILS);
    openDocument(agreementUrl);
  };

  const renderAgreementList = (_agreementList: IAgreement[]) => {
    return (
      <div className="pad16" style={{ border: '1px solid #d3d3d3' }}>
        {_agreementList.map(agreement => (
          <div className="mt-3 mb-2">
            {agreement.agreement_display_name}
            <Button
              size="legacy"
              className="marginL8 capitalize !ml-6"
              variant="text"
              onClick={() => viewAgreement(agreement.sample_url)}
            >
              {COMMON.BUTTONS.VIEW_DETAILS}
            </Button>
          </div>
        ))}
      </div>
    );
  };

  const handleClose = () => {
    trackEvent(OMNITURE_EVENT_NAMES.CLOSE);
    onClose();
  };

  const {
    STRINGS: { HEADER, HOTELIER_MESSAGE, CHECKBOX },
    isHSAgreement,
  } = useMemo(
    () => agreementString(agreementList, STRINGS),
    [agreementList, STRINGS],
  );

  return (
    <Modal
      isOpen
      size="custom"
      header={{
        title: HEADER,
        hideCross: true,
      }}
      footer={{
        primaryBtn: {
          text: 'I accept',
          onClick: handleSubmit(onSubmit),
          disabled: !checkbox,
        },
        secondaryBtn: insurmountable
          ? null
          : {
            text: 'Close',
            onClick: handleClose,
          },
      }}
      onClose={handleClose}
      customClasses={{
        paper: 'w-[600px] z-0',
      }}
    >
      <div>
        <p>
          {STRINGS.GREET},<p className="marginT15">{HOTELIER_MESSAGE}</p>
        </p>

        {agreementList.length > 0 && renderAgreementList(agreementList)}

        <div className="sr-flex-row marginT15">
          <FormElement
            label="Name"
            errorMsg={errors?.name?.message?.toString()}
            isRequired
          >
            <ControlledInput
              name="name"
              type="text"
              control={control}
              placeholder="Full Name"
              pattern={nameRegex}
              isRequired
            />
          </FormElement>
          <FormElement
            label="Designation"
            isRequired
            errorMsg={errors?.designation?.message?.toString()}
          >
            <ControlledInput
              name="designation"
              type="text"
              control={control}
              placeholder="Designation"
              pattern={nameRegex}
              isRequired
            />
          </FormElement>
        </div>
        <div className="sr-flex-row">
          <FormElement
            label="Mobile No."
            isRequired
            errorMsg={errors?.mobile_number?.message?.toString()}
          >
            <ControlledInput
              name="mobile_number"
              type="text"
              control={control}
              placeholder="9999988888"
              pattern={isDomHotel ? domPhoneRegex : phoneRegex}
              isRequired
            />
          </FormElement>
          {isHSAgreement && (
            <FormElement
              label="Email"
              errorMsg={errors?.email?.message?.toString()}
              isRequired
            >
              <ControlledInput
                name="email"
                type="text"
                control={control}
                placeholder="user@example.com"
                pattern={emailRegex}
                isRequired
              />
            </FormElement>
          )}
        </div>

        <div className="marginTB15">{STRINGS.TC_AGREE_MESSAGE}</div>

        <ControlledCheckbox name="ignore" control={control} isRequired>
          {CHECKBOX}
        </ControlledCheckbox>
      </div>
    </Modal>
  );
}
