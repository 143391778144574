// file is tsx to allow for jsx in the showBffMessage function
import { AppEvents } from 'utilities/EventBus';
import RichText from './components/rich-text/RichText';
import { pushToOmniture } from 'utilities/gtm';
import { TrackingData } from 'shared_logic/new-cancellation-policy/types';
import type { ShowBffMessage } from './types/types';
import { getURLWithParams, navigateTo } from 'utilities/Utils';
import { ClientSideAction } from './components/render-button/TypeAndSchemas';

export const handleClientSideAction = (
  action: ClientSideAction,
  onClick?: (action: ClientSideAction) => void,
) => {
  if (onClick) {
    onClick(action);
  } else if (action.type === 'NAVIGATION') {
    navigateTo(getURLWithParams(action.url, action.navParams));
  }
};

export const pushToOmnitureBFF = (trackingData: TrackingData) => {
  if (trackingData?.omniturePayload?.event) {
    pushToOmniture(trackingData.omniturePayload);
  }
};

export const showBffMessage = (props: ShowBffMessage) => {
  const { message, title, tone, color } = props;
  const variant = color ? 'cosmos' : 'default';
  AppEvents.emit('showToast', {
    show: true,
    message: (
      <div className="w-[358px] flex justify-center">
        <RichText text={message} themeColor="common.white" />
      </div>
    ),
    type: tone || color,
    persistUntilClear: false,
    title,
    variant: variant,
  });
};
