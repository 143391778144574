import axios from 'axios';

import { USER } from 'base/Url';
import { apiWrapper } from 'base/APIClient';
import {
  ActivateUserRequest,
  ActivateUserResponse,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  LoginUserRequest,
  LoginUserResponse,
  RegisterUserRequest,
  RegisterUserResponse,
} from './api';

const registerUser = async (payload: RegisterUserRequest) => {
  try {
    const response = (await apiWrapper(
      axios.post(USER.REGISTRATION, payload),
    )) as RegisterUserResponse;

    if (!response?.response.success) {
      return await Promise.reject(response);
    }

    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

const loginUser = async (payload: LoginUserRequest) => {
  try {
    const response = (await apiWrapper(
      axios.post(USER.LOGIN, payload),
    )) as LoginUserResponse;

    if (!response?.response.success) {
      return await Promise.reject(response);
    }

    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

const initiatePasswordReset = async (payload: ForgotPasswordRequest) => {
  try {
    const response = (await apiWrapper(
      axios.post(USER.PASSWORD_RESET, payload),
    )) as ForgotPasswordResponse;

    if (!response?.response.success) {
      return await Promise.reject(response);
    }

    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

const activateUserAccount = async (
  payload: ActivateUserRequest,
): Promise<ActivateUserResponse> => {
  try {
    const response = (await apiWrapper(
      axios.post(USER.ACTIVATE_ACCOUNT, payload),
    )) as ActivateUserResponse;

    if (response?.response?.errorDetail) {
      return await Promise.reject(response);
    }

    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export { activateUserAccount, initiatePasswordReset, registerUser, loginUser };
