import React from 'react';
import './form-alert.scss';

function FormAlert(props) {
  const { message, type = 'warning', style } = props;
  return (
    <div style={style} className={`form--alert ${type}`}>
      <p>{message}</p>
    </div>
  );
}

export default FormAlert;
