import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { OMNITURE_CATEGORIES, pushToOmniture } from 'utilities/gtm';
import { navigateTo } from 'utilities/Utils';
import { HotelContext } from 'utilities/Context';

import { Carousel } from 'connect-web-ui';

import { getWhatsNewMetaDataQueryOptions } from '../utils/DashboardGraphClient';
import { omniture } from 'modules/sale-handling/SaleHandling.constants';
import { saleHanldingLink } from 'modules/sale-handling/utils';
import { DashboardCarouselItem } from './DashboardSaleCarouselItem';
import { CustomIndicator } from './CustomIndicator';
import { type WhatsNewMetaData } from '../Dashboard.types';

export const DashBoardSaleCarousel = () => {
  const {
    saleCampaigns,
    currentHotel: { mmt_id: mmtId },
    isSaleCampaignsLoading,
  } = useContext(HotelContext);

  const [imgsLoaded, setImgsLoaded] = useState({});
  const [carouselState, setCarouselState] = useState<{
    lastClick: 'right' | 'left' | '';
    activeItem: number;
  }>({ lastClick: '', activeItem: 0 });
  const [carouselItems, setCarouselItems] = useState<WhatsNewMetaData[]>([]);
  const multiCarouselItems = carouselItems.length > 1;

  const { data: whatsNewData, isLoading: isWhatsNewDataLoading } = useQuery(
    getWhatsNewMetaDataQueryOptions(mmtId?.toString()),
  );

  const checkAllImagesLoaded = useMemo(() => {
    let allImagesLoaded = true;
    carouselItems.forEach(item => {
      if (!imgsLoaded[item.id]) {
        allImagesLoaded = false;
      }
    });
    return allImagesLoaded;
  }, [carouselItems, imgsLoaded]);

  const redirect = useCallback((campaign: WhatsNewMetaData, index: number) => {
    const typeValue = campaign?.type?.split('_').join('');
    const subTypeValue = campaign?.subType?.split('_').join('');
    pushToOmniture({
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      cta: {
        name: campaign.sale
          ? omniture.sale_top_banner_click
          : `${index + 1}_${typeValue}_${subTypeValue}`,
        type: omniture.type.click,
        componentName: campaign.sale ? campaign.title : omniture.whats_new,
      },
    });

    if (!campaign.redirect_link) return;

    if (campaign.is_external_link)
      window.open(campaign.redirect_link, '_blank');
    else navigateTo(campaign.redirect_link);
  }, []);

  useEffect(() => {
    const mappedSaleCampaigns = saleCampaigns?.map(sc => ({
      sale: true,
      id: sc.campaignId,
      title: sc.campaignName,
      expiry_date: sc.campaignEndTime,
      background_image_url: sc.images.campaignMidInlet,
      redirect_link: saleHanldingLink(sc.campaignId),
      is_external_link: false,
    }));
    setCarouselItems([...(mappedSaleCampaigns ?? []), ...(whatsNewData ?? [])]);
  }, [saleCampaigns, whatsNewData]);

  useEffect(() => {
    let loadedComponents = '';
    saleCampaigns?.forEach((item, idx) => {
      loadedComponents +=
        item.campaignName + (idx === saleCampaigns.length - 1 ? '' : '|');
    });

    pushToOmniture({
      event: OMNITURE_CATEGORIES.CTA_LOAD,
      cta: {
        name: omniture.sale_top_banner,
        type: omniture.type.load,
      },
      loadedComponents,
    });
  }, [saleCampaigns]);

  useEffect(() => {
    if (whatsNewData?.length) {
      const loadedComponents = whatsNewData.reduce((acc, item, idx) => {
        const typeValue = item?.type?.split('_').join('');
        const subtypeValue = item?.subType?.split('_').join('');
        const separator = idx === whatsNewData.length - 1 ? '' : '|';
        return `${acc}${idx + 1}_${typeValue}_${subtypeValue}${separator}`;
      }, '');

      pushToOmniture({
        event: OMNITURE_CATEGORIES.CTA_LOAD,
        cta: {
          name: omniture.whats_new,
          type: omniture.type.load,
        },
        loadedComponents: `whatsnew|${loadedComponents}`,
      });
    }
  }, [whatsNewData]);

  useEffect(() => {
    const loadedComponents = carouselItems
      .filter(value => !value.sale)
      .map((value, index) => value.title || `whatsnew_${index}`)
      .join('|');
    pushToOmniture({
      event: OMNITURE_CATEGORIES.CTA_LOAD,
      cta: {
        name: omniture.whatsnew_banner,
        type: omniture.type.load,
      },
      loadedComponents,
    });
  }, [carouselItems]);

  if (
    isSaleCampaignsLoading ||
    isWhatsNewDataLoading ||
    carouselItems.length === 0
  ) {
    return null;
  }

  return (
    <div className="mb-4">
      <Carousel
        customIndicator={
          multiCarouselItems &&
          checkAllImagesLoaded && (
            <CustomIndicator
              activeItem={carouselState.activeItem}
              itemCount={carouselItems.length}
              lastClick={carouselState.lastClick}
            />
          )
        }
        customClasses={{
          container: 'gap-2',
          slider: multiCarouselItems ? '-ml-10 -mr-10' : '',
          indicatorContainer: '!-mb-5',
          leftButton: disabled =>
            `select-none shadow-liftShadow bg-color-white/40 h-18 px-1 flex items-center justify-center rounded-r ${
              disabled
                ? '[&>svg>path]:!fill-[#a6a6a6]'
                : '[&>svg>path]:!fill-text-primary'
            }`,
          rightButton: disabled =>
            `select-none shadow-liftShadow bg-color-white/40 h-18 px-1 flex items-center justify-center rounded-l ${
              disabled
                ? '[&>svg>path]:!fill-[#a6a6a6]'
                : '[&>svg>path]:!fill-text-primary'
            }`,
        }}
        onLeftClick={activeItemIdx =>
          setCarouselState({ activeItem: activeItemIdx, lastClick: 'left' })
        }
        onRightClick={activeItemIdx =>
          setCarouselState({ activeItem: activeItemIdx, lastClick: 'right' })
        }
        multiView={multiCarouselItems}
        showButtons={multiCarouselItems ? 'both' : 'none'}
      >
        {carouselItems.map((item, index) => (
          <DashboardCarouselItem
            key={item.id}
            carouselItem={item}
            setCarouselItems={setCarouselItems}
            setImgsLoaded={setImgsLoaded}
            redirect={clickedItem => redirect(clickedItem, index)}
            multiItem={multiCarouselItems}
          />
        ))}
      </Carousel>
    </div>
  );
};
