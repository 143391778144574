export const actionTypes = {
  UPDATE_STATE: 'UPDATE_STATE',
  RESET: 'RESET',
};

export const initialState = {
  search: '',
  selectedCategory: '',
  selectedIssue: '',
  issue_details: '',
  errors: {},
  hardRefresh: {
    create_ticket: true,
    view_ticket: true,
  },
  categories: [],
  tickets: {
    pending: [],
    resolved: [],
  },
};

export const reducer = (
  state = initialState,
  action = { type: '', data: null },
) => {
  const { type, data } = action;
  switch (type) {
    case actionTypes.UPDATE_STATE:
      return {
        ...state,
        ...data,
      };
    case actionTypes.RESET:
      return {
        ...initialState,
        hardRefresh: state.hardRefresh,
        categories: state.categories,
        tickets: state.tickets,
      };
    default:
      return state;
  }
};
