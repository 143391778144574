import { useCallback, useContext } from 'react';
import pushToGTM, { GTM_CATEGORIES } from 'utilities/gtm';
import { Box } from 'connect-web-ui/mui';
import { pushToCtaOmnitureCommon } from 'app/login/utils';
import { AppContext, AuthContext } from 'utilities/Context';
import { MAX_SCREEN_BODY_WIDTH } from 'app/navigation/const';
import Lottie from 'lottie-react';
import { connect } from 'assets/lottie';

const ErrorHeader = () => {
  const { logOutUser } = useContext(AuthContext);
  const appState: { hotelList } = useContext(AppContext);
  const { hotelList } = appState || {};

  const clearResellerSessionStorageData = () => {
    hotelList?.forEach(hotel => {
      if (hotel?.hotelcode) {
        sessionStorage.removeItem(`hide-reseller-popup-${hotel.hotelcode}`);
      }
    });
  };

  const logoutHandler = useCallback(() => {
    pushToCtaOmnitureCommon({
      ctaName: 'log_out',
      componentName: 'page_crash',
      pageName: 'error_screen',
    });

    logOutUser(true, () => {
      document.cookie = 'closed=;expires=Thu, 01 Jan 1970 00:00:00 UTC';
      pushToGTM(GTM_CATEGORIES.HEADER, 'signout');
      clearResellerSessionStorageData();
    });
  }, []);

  return (
    <div className="navigation-container">
      <div className={'navigation-header h-[72px]'}>
        <Box
          sx={{ maxWidth: MAX_SCREEN_BODY_WIDTH, width: '100%' }}
          className="flex justify-between items-center p-5"
        >
          <div className="flex px-4">
            <span className="flexCenterWrap w-[88px] justify-center">
              <Lottie
                animationData={connect}
                style={{ height: '50px', width: '70px' }}
                loop={2}
                className="cursor-pointer"
              />
            </span>
          </div>
          <div
            className="p-3 rounded hover sr-curPointer users blue-lt dib"
            onClick={logoutHandler}
          >
            <span className="fmed font14 width100 ">Logout</span>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default ErrorHeader;
