import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  HotelLegalEntityType,
  UserProfileType,
  UserProfileViewType,
} from '../constants';
import { hotelLegalEntityTypeMap } from '../../../constants';
import { resellerOnlyTabs } from '../HotelTabs';

const useRedirectToResellerView = ({
  userProfileType,
  legalEntityType,
  updateUserProfileView,
}) => {
  const location = useLocation();

  useEffect(() => {
    if (
      resellerOnlyTabs.includes(location.pathname) &&
      userProfileType !== UserProfileType.INGO &&
      legalEntityType &&
      hotelLegalEntityTypeMap[legalEntityType] ===
        HotelLegalEntityType.HOTEL_TRAVEL
    ) {
      updateUserProfileView(UserProfileViewType.HOTEL_TRAVEL);
    }
  }, [location.pathname, legalEntityType, userProfileType]);
};

export default useRedirectToResellerView;
