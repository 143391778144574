import { doQuery } from 'base/GraphQLClient';
import { combinedQueryForICSLinkageAndParentChildDetails } from './CalSyncGraphQueries';
import { getCombinedLinkgaeDetailsVariable } from './CalSyncVariables';
import SENTRY from 'utilities/Sentry';

export const fetchCombinedInternalCalSyncData = async (hotelcode: string) => {
  try {
    const res = await doQuery(
      combinedQueryForICSLinkageAndParentChildDetails,
      getCombinedLinkgaeDetailsVariable(hotelcode),
      {
        useLoader: false,
      },
    );
    if (res?.data) {
      return res.data;
    }
    return {
      success: false,
    };
  } catch (error) {
    SENTRY.logError(error);
    return {
      success: false,
    };
  }
};
