import { getFormattedDate, getFutureDate } from 'utilities/DateUtils';
import { classNames as joinTruthyValues, pick } from 'utilities/Utils';
import { apiDateFormat, inventoryMaxFutureDays } from '../../constants';
import {
  cancellationPolicies,
  SLOT_DURATION,
  HOURLY_POLICY_CATEGORYID,
  HOURLY_POLICY_CATEGORYID_CFP_TEMPLATE_ID,
} from './dayUseRooms.constant';

import {
  CPUpdateProps,
  OnboardingProps,
  PricingAndAvailabilityProps,
  PricingAndAvailabilityQueryProps,
  RoomUpdateProps,
  SettingsVariables,
} from './dayUseRooms.types';
import {
  POLICY_RP_TYPE,
  POLICY_TEMPLATE_ID,
} from 'shared_logic/new-cancellation-policy/constant';
import { getCancellationPolicyVariables } from 'shared_logic/new-cancellation-policy/CancellationPolicy.variables';
import { getCancellationPolicyQuery } from 'shared_logic/new-cancellation-policy/CancellationPolicy.query';

const cheapestRoomQuery = `getCheapestRoom(
  endpointRequestData: $endpointRequestData,
  grpcEndpointRequestData: $grpcEndpointRequestData,
  endpointBusinessLogicData: $cheapestRoomVariables){
  roomDetails{
    roomtype
    roomtypename
    roomtypecode
    noofrooms
    bedtype
    description
    max_guest_occupancy
    max_adult_occupancy
    max_child_occupancy
    base_adult_occupancy
    base_child_occupancy
    parent_id
  }
  roomAmneties{
    success
    amenities{
      amenity_id
      main_category
      amenity_name
      category_order
      is_selected
      id
    }
  }
  roomImages{
    success
    total_images
    total_approved_images
    data{
      id
      status
      url
      image
      image_square
      image_thumb
      image_rectangle
      isactive
    }
  }
  roomVideos{
    success
    error
    data{
      id
      status
      is_active
      video_url
      video_thumbnail
    }
  }
}`;

const bedTypesQuery = `rawEndpoint(rawEndpointRequestData: $bedTypes) {
  contentCommonGeneralData {
    modelType1 {
      data {    
        bed_type {
          label
          value
        }
      }
      success
    }
  }
}`;

export const hotelConfigQuery = `rawEndpoint(rawEndpointRequestData: $hotelConfigVariables) {
  getSlotBookingConfig {
    modelType1 {
      status
      message
      data {
        dayuse_multislot_enabled
      }
    }
  }
}`;

export const roomConfigQuery = `rawEndpoint(rawEndpointRequestData: $roomConfigVariables) {
    getSlotBookingConfig {
      modelType1 {
        status
        message
        data {
          housekeeping_time
          checkin_start
          checkout_end
          floor_price_3hr
          floor_price_6hr
          floor_price_9hr
          fullday_inventory_cutoff
        }
      }
    }
  }`;

export const getCheapestRoomQuery = `query Dayuse(
    $endpointRequestData: EndpointRequestData,
    $grpcEndpointRequestData: GRPCEndpointRequestData,
    $cheapestRoomVariables: EndpointBusinessLogicData
    $bedTypes: RawEndpointsRequestData
  ) {
    getCheapestRoom: ${cheapestRoomQuery}
    bedTypes: ${bedTypesQuery}
  }`;

export const getCheapestRoomVariables = (hotelcode: string) => ({
  cheapestRoomVariables: {
    cheapestRoomDayUseInput: {
      hotelcode: hotelcode,
      dates: getFormattedDate(new Date(), apiDateFormat),
    },
  },
  bedTypes: {
    contentCommonGeneralData: {
      modelType: 'modelType1',
    },
  },
});

export const onboardingQuery = (
  isNewCPEnabled: boolean,
) => `query DayuseOnboarding(
  $endpointRequestData: EndpointRequestData,
  $grpcEndpointRequestData: GRPCEndpointRequestData,
  $endpointBusinessLogicData: EndpointBusinessLogicData
) {
  dayuseOnboarding(
    endpointRequestData: $endpointRequestData,
    grpcEndpointRequestData: $grpcEndpointRequestData,
    endpointBusinessLogicData: $endpointBusinessLogicData){
    createRoom{
      success
      success
      room{
        roomtype
        roomtypecode
      }
    }
    createInventory{
      success
      message
      data{
        day_list
      }
    }
    rateplanCreation
    updateRates
    createRoomLevelSlotBooking{
      status
      message
    }
    createHotelLevelSlotBooking{
      status
      message
    }
    ${
  isNewCPEnabled
    ? 'upsertHourlyCancellationPolicy'
    : `createCancellationPolicy{
      success
      message
      data{
        related_to
      }
    }`
}
  }
}`;

export const onboardingVariables = (
  props: OnboardingProps,
  cheapestRateplanCode: number,
  showNewCancellationPolicy,
) => {
  const {
    hotelcode,
    room_name,
    room_description,
    room_type,
    bedtype,
    maximum_guest_occupancy,
    amenities,
    dayuse_images,
    dayuse_videos,
    parent_roomtypecode,
    inventory,
    hourly_rates,
    availability_period,
    availability_window,
    housekeeping_duration,
    allow_for_multibooking,
    is_free_cancellation,
    tax_inclusive,
    couple_friendly_policy,
    couple_friendly_policy_id,
  } = props;
  const dayUseParams = {
    createRateplanInput: {
      RP3HR: {
        body: {
          rateplan_name: 'dayuse rateplan 3hr',
          non_refundable: false,
          tax_included: tax_inclusive,
          meal_plan: 'EP',
          pay_at_hotel: 0,
          hotel_id: hotelcode,
          room_id: '',
          mapped_tiers: [],
          inclusions: [],
          is_active: 1,
          slot_duration: SLOT_DURATION.hours_3,
          contract_type: 'b2c',
        },
      },
      RP6HR: {
        body: {
          rateplan_name: 'dayuse rateplan 6hr',
          non_refundable: false,
          tax_included: tax_inclusive,
          meal_plan: 'EP',
          pay_at_hotel: 0,
          hotel_id: hotelcode,
          room_id: '',
          mapped_tiers: [],
          inclusions: [],
          is_active: 1,
          slot_duration: SLOT_DURATION.hours_6,
          contract_type: 'b2c',
        },
      },
      RP9HR: {
        body: {
          rateplan_name: 'dayuse rateplan 9hr',
          non_refundable: false,
          tax_included: tax_inclusive,
          meal_plan: 'EP',
          pay_at_hotel: 0,
          hotel_id: hotelcode,
          room_id: '',
          mapped_tiers: [],
          inclusions: [],
          // enable only if user enter RP9 Price
          is_active: hourly_rates.hours_9 ? 1 : 0,
          slot_duration: SLOT_DURATION.hours_9,
          contract_type: 'b2c',
        },
      },
    },
  };
  if (cheapestRateplanCode) {
    const linkedRatePlan = {
      parent_rateplan_id: cheapestRateplanCode,
      linkage_basis: 'percent',
      linkage_type: 'low',
      extra_guest_linkage_amount: 0,
      is_block_cta_ctd_linked: true,
      is_cutoff_linked: false,
      is_mlos_linked: false,
    };
    // @ts-ignore
    dayUseParams.createRateplanInput.RP3HR.body.linked_rateplan = {
      ...linkedRatePlan,
      linkage_amount: 100 - hourly_rates.hours_3,
    };
    // @ts-ignore
    dayUseParams.createRateplanInput.RP6HR.body.linked_rateplan = {
      ...linkedRatePlan,
      linkage_amount: 100 - hourly_rates.hours_6,
    };
    // @ts-ignore
    dayUseParams.createRateplanInput.RP9HR.body.linked_rateplan = {
      ...linkedRatePlan,
      linkage_amount: 100 - hourly_rates.hours_9,
    };
  } else {
    // @ts-ignore
    dayUseParams.updateRatesInput = {
      RP3HR: {
        body: {
          hotel_code: hotelcode,
          data: [
            {
              level: 'rate_plan',
              code_list: [],
              date_range_list: [
                {
                  from_date: getFormattedDate(
                    availability_period.from,
                    apiDateFormat,
                  ),
                  to_date: getFormattedDate(
                    availability_period.to,
                    apiDateFormat,
                  ),
                },
              ],
              contract_type_list: ['b2c'],
              rates: {
                sell_price: {
                  [maximum_guest_occupancy]: hourly_rates.hours_3,
                },
              },
              day_list: [0, 1, 2, 3, 4, 5, 6],
              restrictions: {},
            },
          ],
        },
      },
      RP6HR: {
        body: {
          hotel_code: hotelcode,
          data: [
            {
              level: 'rate_plan',
              code_list: [],
              date_range_list: [
                {
                  from_date: getFormattedDate(
                    availability_period.from,
                    apiDateFormat,
                  ),
                  to_date: getFormattedDate(
                    availability_period.to,
                    apiDateFormat,
                  ),
                },
              ],
              contract_type_list: ['b2c'],
              rates: {
                sell_price: {
                  [maximum_guest_occupancy]: hourly_rates.hours_6,
                },
              },
              day_list: [0, 1, 2, 3, 4, 5, 6],
              restrictions: {},
            },
          ],
        },
      },
      RP9HR: {
        body: {
          hotel_code: hotelcode,
          data: [
            {
              level: 'rate_plan',
              code_list: [],
              date_range_list: [
                {
                  from_date: getFormattedDate(
                    availability_period.from,
                    apiDateFormat,
                  ),
                  to_date: getFormattedDate(
                    availability_period.to,
                    apiDateFormat,
                  ),
                },
              ],
              contract_type_list: ['b2c'],
              rates: {
                sell_price: {
                  [maximum_guest_occupancy]: hourly_rates.hours_9 || 0,
                },
              },
              day_list: [0, 1, 2, 3, 4, 5, 6],
              restrictions: {},
            },
          ],
        },
      },
    };
  }
  let coupleFriendlyPolicyData = {};
  if (couple_friendly_policy) {
    const params = {};
    if (couple_friendly_policy_id) {
      // @ts-ignore
      params.id = params;
    }
    coupleFriendlyPolicyData = {
      updateHotelPolicyInput: {
        body: {
          hotelcode,
          category_id: HOURLY_POLICY_CATEGORYID,
          policies: [
            {
              template_id: HOURLY_POLICY_CATEGORYID_CFP_TEMPLATE_ID,
              selected_value: [couple_friendly_policy],
              ...params,
            },
          ],
        },
      },
    };
  }
  return {
    endpointBusinessLogicData: {
      dayuseOnboardingInput: {
        createRoomInput: {
          body: {
            amenity_mapping_ids: amenities,
            base_adult_occupancy: maximum_guest_occupancy,
            base_child_occupancy: 0,
            bedtype: bedtype.value,
            dayuse_images,
            dayuse_videos,
            description: room_description,
            hotelcode: hotelcode,
            is_dayuse_room: true,
            isactive: true,
            max_adult_occupancy: maximum_guest_occupancy,
            max_child_occupancy: 0,
            max_guest_occupancy: maximum_guest_occupancy,
            parent_roomtypecode: parent_roomtypecode,
            roomtype: room_type,
            roomtypename: room_name,
          },
        },
        createInventoryInput: {
          body: {
            hotel_code: hotelcode,
            data: [
              {
                level: 'room',
                code_list: [],
                date_range_list: [
                  {
                    from_date: getFormattedDate(
                      availability_period.from,
                      apiDateFormat,
                    ),
                    to_date: getFormattedDate(
                      availability_period.to,
                      apiDateFormat,
                    ),
                  },
                ],
                inventory: {
                  available: inventory,
                },
              },
            ],
          },
        },
        ...dayUseParams,
        createSlotBookingRoomLevel: {
          body: {
            related_to: 'room',
            related_code_list: [],
            config: {
              // slot_step_size is hardcoded as 60
              slot_step_size: 60,
              checkin_start: availability_window.from,
              checkout_end: availability_window.to,
              housekeeping_time: housekeeping_duration,
            },
          },
        },
        createSlotBookingHotelLevel: {
          body: {
            related_to: 'hotel',
            related_code_list: [hotelcode],
            config: {
              dayuse_booking_enabled: true,
              dayuse_multislot_enabled: allow_for_multibooking,
            },
          },
        },
        ...(showNewCancellationPolicy
          ? {
            upsertHourlyCancellationPolicyInput: {
              body: {
                ingoHotelId: hotelcode,
                cancellationPolicies: [
                  {
                    templateId: is_free_cancellation
                      ? POLICY_TEMPLATE_ID.FC_TILL_MID_NIGHT
                      : POLICY_TEMPLATE_ID.NON_REFUNDABLE,
                    policyType: 'HOURLY',
                    vendor: 'INGO',
                    isActive: true,
                  },
                ],
              },
            },
          }
          : {
            createCancellationPolicy: {
              body: {
                hotel_code: hotelcode,
                data: [
                  {
                    related_to: 'room',
                    related_code_list: [],
                    segment: 'dayuse',
                    policies: [
                      {
                        staystart: getFormattedDate(
                          new Date(),
                          apiDateFormat,
                        ),
                        stayend: '2099-12-31',
                        rules: is_free_cancellation
                          ? cancellationPolicies.free_cancellation
                          : cancellationPolicies.non_refundable,
                      },
                    ],
                  },
                ],
              },
            },
          }),
        ...coupleFriendlyPolicyData,
      },
    },
  };
};

export const getInfoForSettingsQuery = (
  isNewCPEnabled: boolean,
) => `query Dayuse(
  $endpointRequestData: EndpointRequestData
  $grpcEndpointRequestData: GRPCEndpointRequestData
  $cheapestRoomVariables: EndpointBusinessLogicData
  $hotelConfigVariables: RawEndpointsRequestData
  $roomConfigVariables: RawEndpointsRequestData
  $analyticsVariables: EndpointRequestData
  $hotelRatesVariables: EndpointBusinessLogicData
  ${
  isNewCPEnabled
    ? '$hourlyCancellationPolicy: RawEndpointsRequestData'
    : '$cancellationPolicyVariables: EndpointBusinessLogicData'
}
  $bedTypes: RawEndpointsRequestData
) {
  getCheapestRoom: ${cheapestRoomQuery}
  hotelConfig: ${hotelConfigQuery}
  roomConfig: ${roomConfigQuery}
  bedTypes: ${bedTypesQuery}
  analytics: ingoGroupBookingAnalyticsDetails(
    endpointRequestData: $analyticsVariables
  ) {
    total_booking_gb
    total_revenue_gb
  }
  hotelRates: dayuseGetRates(
    endpointRequestData: $endpointRequestData
    grpcEndpointRequestData: $grpcEndpointRequestData
    endpointBusinessLogicData: $hotelRatesVariables
  ) {
    success
    message
    response_data {
      data
    }
  }
  ${
  isNewCPEnabled
    ? `  
    cancellationPoliciesData: rawEndpoint(rawEndpointRequestData: $hourlyCancellationPolicy) {
    ${getCancellationPolicyQuery}
  }
    `
    : `cancellationPolicy: dayuseGetCancellationPolicy(
    endpointRequestData: $endpointRequestData
    grpcEndpointRequestData: $grpcEndpointRequestData
    endpointBusinessLogicData: $cancellationPolicyVariables
  ) {
    success
    results {
      stayend
      staystart
      collectivecplist
      id
    }
  }`
}
}
`;

const getAnalyticsVariables = (hotelcode, analyticsDate) => ({
  ingoGroupBookingAnalyticsRequests: {
    groupBookingAnalyticsFiltered: {
      body: {
        category: 'Histogram',
        pipeline: 'Transaction',
        action: 'overview',
        property: ['net_amount', 'bookings', 'room_nights'],
        start_date: getFormattedDate(analyticsDate, apiDateFormat),
        end_date: getFormattedDate(new Date(), apiDateFormat),
        brand: ['all'],
        date_type: 'booking',
        comparison: 'false',
        hotel_codes: [hotelcode],
        filter: {
          isDayUseBooking: [true],
        },
      },
    },
    groupBookingAnalytics: {
      body: {
        category: 'Histogram',
        pipeline: 'Transaction',
        action: 'overview',
        property: ['net_amount', 'bookings', 'room_nights'],
        start_date: getFormattedDate(analyticsDate, apiDateFormat),
        end_date: getFormattedDate(new Date(), apiDateFormat),
        brand: ['all'],
        date_type: 'booking',
        comparison: 'false',
        hotel_codes: [hotelcode],
      },
    },
  },
});

export const getInfoForSettingsVariables = (
  props: SettingsVariables,
  showNewCancellationPolicy: boolean,
) => {
  const { hotelcode, roomcode, analyticsDate, rateplanCode } = props;
  return {
    hotelConfigVariables: {
      getSlotBookingConfig: {
        endPointId: 1,
        modelType: 'modelType1',
        queryParams: {
          related_to: 'hotel',
          related_code: hotelcode,
        },
      },
    },
    roomConfigVariables: {
      getSlotBookingConfig: {
        endPointId: 1,
        modelType: 'modelType1',
        queryParams: {
          related_to: 'room',
          related_code: roomcode,
        },
      },
    },
    cheapestRoomVariables: {
      cheapestRoomDayUseInput: {
        hotelcode: hotelcode,
        roomcode: roomcode,
        dates: getFormattedDate(new Date(), apiDateFormat),
      },
    },
    analyticsVariables: getAnalyticsVariables(hotelcode, analyticsDate),
    hotelRatesVariables: {
      dayuseGetRatesInput: {
        body: {
          hotel_code: hotelcode,
          data: [
            {
              level: 'rate_plan',
              code_list: [rateplanCode],
              contract_type_list: ['b2c'],
              date_range_list: [
                {
                  from_date: getFormattedDate(new Date(), apiDateFormat),
                  to_date: getFormattedDate(new Date(), apiDateFormat),
                },
              ],
            },
          ],
        },
      },
    },
    ...(showNewCancellationPolicy
      ? {
        hourlyCancellationPolicy: getCancellationPolicyVariables(
          hotelcode,
          POLICY_RP_TYPE.HOURLY,
        ),
      }
      : {
        cancellationPolicyVariables: {
          dayuseGetCancellationPolicyInput: {
            queryParams: {
              hotelcode: hotelcode,
              formname: 'cancellationrulesinfo',
              page: 1,
              filter_type: 'roomcode',
              filter_values: roomcode,
            },
          },
        },
      }),
    bedTypes: {
      contentCommonGeneralData: {
        modelType: 'modelType1',
      },
    },
  };
};

export const pricingAndAvailabilityQuery = (
  props: PricingAndAvailabilityQueryProps,
) => `
mutation RawResponse(
  ${joinTruthyValues(
    props.updateRestriction && '$restrictions: RawEndpointsRequestData',
    props.updateSlotBookingHotelLevel &&
      '$slotBookingHotelLevel: RawEndpointsRequestData',
    props.updateSlotBookingRoomLevel &&
      '$slotBookingRoomLevel: RawEndpointsRequestData',
    props.updateRateplans &&
      `$endpointRequestData: EndpointRequestData 
       $grpcEndpointRequestData: GRPCEndpointRequestData 
       $rateplans: EndpointBusinessLogicData`,
  )}
) {
  ${joinTruthyValues(
    props.updateRestriction &&
      `updateRestriction: rawEndpoint(endpointRequestData: $restrictions) {
      updateRestrictionsDayUse {
        modelType1 {  
          success
          message
          data {
            success
            level
            product_code_list
          }
        }
      }
    }`,
    props.updateRateplans &&
      `updateRateplans: dayuseActivateRateplanAndUpdateRate(
        endpointRequestData: $endpointRequestData
        grpcEndpointRequestData: $grpcEndpointRequestData
        endpointBusinessLogicData: $rateplans
    ){
      rateplanUpdateResponse
    }`,
    props.updateSlotBookingHotelLevel &&
      `updateSlotBookingHotelLevel: rawEndpoint(endpointRequestData: $slotBookingHotelLevel) {
      updateSlotBookingConfig {
        modelType1 {
          status
          message
        }
      }
    }`,
    props.updateSlotBookingRoomLevel &&
      `updateSlotBookingRoomLevel: rawEndpoint(endpointRequestData: $slotBookingRoomLevel) {
      updateSlotBookingConfig {
        modelType1 {
          status
          message
        }
      }
    }`,
  )}
}
`;

export const pricingAndAvailabilityVariables = (
  props: PricingAndAvailabilityProps,
) => {
  const {
    hotel_code,
    room_id,
    rateplans,
    restrictions,
    slotBookingHotelLevel,
    slotBookingRoomLevel,
    pickValues,
  } = props;

  const payload = {
    restrictions: {
      updateRestrictionsDayUse: {
        endPointId: 1,
        modelType: 'modelType1',
        body: {
          hotel_code,
          data: [
            {
              restrictions: restrictions,
              level: 'room',
              code_list: [room_id],
              date_range_list: [
                {
                  from_date: getFormattedDate(new Date(), apiDateFormat),
                  to_date: getFormattedDate(
                    getFutureDate(new Date(), inventoryMaxFutureDays),
                    apiDateFormat,
                  ),
                },
              ],
              day_list: [0, 1, 2, 3, 4, 5, 6],
            },
          ],
        },
      },
    },
    rateplans: {
      dayuseActivateRateplanAndUpdateRateInput: rateplans,
    },
    slotBookingHotelLevel: {
      updateSlotBookingConfig: {
        endPointId: 1,
        modelType: 'modelType1',
        body: {
          related_to: 'hotel',
          related_code_list: [hotel_code],
          config: {
            dayuse_multislot_enabled:
              slotBookingHotelLevel?.dayuse_multislot_enabled,
          },
        },
      },
    },
    slotBookingRoomLevel: {
      updateSlotBookingConfig: {
        endPointId: 1,
        modelType: 'modelType1',
        body: {
          related_to: 'room',
          related_code_list: [room_id],
          config: {
            checkin_start: slotBookingRoomLevel?.checkin_start,
            checkout_end: slotBookingRoomLevel?.checkout_end,
            housekeeping_time: slotBookingRoomLevel?.housekeeping_time,
            fullday_inventory_cutoff:
              slotBookingRoomLevel?.fullday_inventory_cutoff,
          },
        },
      },
    },
  };
  return pick(payload, pickValues);
};

export const roomUpdateQuery = `mutation RawResponse($rawEndpointsRequestData: RawEndpointsRequestData) {
  rawEndpoint(endpointRequestData: $rawEndpointsRequestData) {
    updateRoomDayUse{
      modelType1{
        success
        message
      }
    }
  }
}`;

export const roomUpdateVariables = (props: RoomUpdateProps) => ({
  rawEndpointsRequestData: {
    updateRoomDayUse: {
      endPointId: 1,
      modelType: 'modelType1',
      urlParams: {
        roomTypeCode: props.urlParams.roomTypeCode,
      },
      body: props.body,
    },
  },
});

export const analyticsQuery = `query GroupBookingAnalyticsDetails($endpointRequestData: EndpointRequestData) {
  ingoGroupBookingAnalyticsDetails(
    endpointRequestData: $endpointRequestData
  ) {
    total_booking_gb
    total_revenue_gb
  }
}`;

export const analyticsVariables = (hotelcode, analyticsDate) => ({
  endpointRequestData: getAnalyticsVariables(hotelcode, analyticsDate),
});

export const cancellationPolicyUpdateQuery = `query DayuseGetCancellationPolicy(
  $endpointRequestData: EndpointRequestData
  $grpcEndpointRequestData: GRPCEndpointRequestData
  $endpointBusinessLogicData: EndpointBusinessLogicData
) {
  dayUseUpdateCancellation(
    endpointRequestData: $endpointRequestData
    grpcEndpointRequestData: $grpcEndpointRequestData
    endpointBusinessLogicData: $endpointBusinessLogicData
  ) {
    deleteCancellationPolicyResponse{
      success
      msg
      cancellation_id
    }
    createCancellationPolicyResponse{
      success
      message
      error_code
      data{
        cancellation_id
        success
        related_to
        related_code_list
        errors
      }
    }
  }
}`;

export const cancellationPolicyUpdateVariables = (props: CPUpdateProps) => {
  const { hotelcode, roomcode, id, newPolicy } = props;
  return {
    endpointBusinessLogicData: {
      dayUseUpdateCancellationPolicyInput: {
        deleteCancellationPolicy: {
          body: {
            hotelcode,
            id,
          },
        },
        createCancellationPolicy: {
          body: {
            hotel_code: hotelcode,
            data: [
              {
                related_to: 'room',
                related_code_list: [roomcode],
                segment: 'dayuse',
                policies: [newPolicy],
              },
            ],
          },
        },
      },
    },
  };
};

// Can merge later with chepestroom API
export const getCheapestRateplanQuery = `query getCheapestRateplan(
  $endpointRequestData: EndpointRequestData
  $grpcEndpointRequestData: GRPCEndpointRequestData
  $dayuseGetCheapestRateplanVariables: EndpointBusinessLogicData
){
  dayuseGetCheapestRateplan(
    endpointRequestData: $endpointRequestData
    grpcEndpointRequestData: $grpcEndpointRequestData
    endpointBusinessLogicData: $dayuseGetCheapestRateplanVariables
  ) {
    rateplanCode
    mealplan
    ratePlanInfo {
      date
      ratePlanPrice
    }
  }
}`;

export const cheapestRateplanVariables = (hotelCode, roomTypeCode) => {
  return {
    dayuseGetCheapestRateplanVariables: {
      dayuseGetCheapestRateplanInput: {
        hotelCode: hotelCode,
        roomTypeCode: roomTypeCode,
        fromDate: getFormattedDate(new Date(), apiDateFormat),
        toDate: getFormattedDate(new Date(), apiDateFormat),
      },
    },
  };
};

export const ariConfigAndRateplansQuery = `query ARIConfig(
  $endpointRequestData: EndpointRequestData
  $grpcEndpointRequestData: GRPCEndpointRequestData
  $endpointBusinessLogicData: EndpointBusinessLogicData
  $hotelConfigVariables: RawEndpointsRequestData
  $roomConfigVariables: RawEndpointsRequestData
) {
  cheapestRateplan: dayuseGetCheapestRateplan(
    endpointRequestData: $endpointRequestData
    grpcEndpointRequestData: $grpcEndpointRequestData
    endpointBusinessLogicData: $endpointBusinessLogicData
  ) {
    rateplanCode
    mealplan
    ratePlanInfo {
      date
      ratePlanPrice
    }
  }
  hotelConfig: ${hotelConfigQuery}
  roomConfig: ${roomConfigQuery}
}
`;

// TODO ->  Move below code in ARI graph client
type AriConfigAndRPProps = {
  roomTypeCode: string;
  dayUseParent: string;
  hotelCode: string;
  from: string;
  to: string;
};

export const ariConfigAndRateplansVariables = (props: AriConfigAndRPProps) => {
  const { hotelCode, roomTypeCode, dayUseParent, from, to } = props;
  return {
    endpointBusinessLogicData: {
      dayuseGetCheapestRateplanInput: {
        hotelCode: hotelCode,
        roomTypeCode: dayUseParent,
        fromDate: getFormattedDate(from, apiDateFormat),
        toDate: getFormattedDate(to, apiDateFormat),
      },
    },
    hotelConfigVariables: {
      getSlotBookingConfig: {
        endPointId: 1,
        modelType: 'modelType1',
        queryParams: {
          related_to: 'hotel',
          related_code: hotelCode,
        },
      },
    },
    roomConfigVariables: {
      getSlotBookingConfig: {
        endPointId: 1,
        modelType: 'modelType1',
        queryParams: {
          related_to: 'room',
          related_code: roomTypeCode,
        },
      },
    },
  };
};

export const slotBookingGridQuery = `query RawResponse($rawEndpointsRequestData: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $rawEndpointsRequestData) {
    getSlotBookingConfigGrid{
      modelType1{
        status
        message
        data{
          bookings{
            booking_id
            virtual_room_code
            guest_count
            sell_amount
            guest_name
            housekeeping_time
            checkout_time
            checkin_time
          }
          rooms {
            virtual_room_code
            is_blocked
            blocked_hours
          }
        }
      }
    }
  }
}`;

export const slotBookingGridVaribales = (
  roomTypeCode: string,
  checkinDate: string,
) => {
  return {
    rawEndpointsRequestData: {
      getSlotBookingConfigGrid: {
        endPointId: 1,
        modelType: 'modelType1',
        queryParams: {
          roomtypecode: roomTypeCode,
          checkin_date: checkinDate,
          vendor: 'all',
        },
      },
    },
  };
};
