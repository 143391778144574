import { updateObject } from 'utilities/Utils';
import {
  DASHBOARD_TOP_CARDS_TYPES_NEW as DASHBOARD_PERFORMANCE_CARDS,
  last7DaysPrefixSuffixMapper,
  performanceCardRouteMapper,
  shoutOutPrefixSuffixMapper,
} from '../../constants/Dashboard';
import { actionTypes } from '../../utils/utils';
import moment from 'moment';

const getInitialState = () => {
  const initialState = {};
  Object.values(DASHBOARD_PERFORMANCE_CARDS).forEach(key => {
    initialState[key] = {
      id: key,
      shoutOut: null,
      trend: null,
      last7Days: null,
      to: null,
      isLoading: true,
      isError: false,
      boost: null,
      loaded_at: null,
    };
  });
  return initialState;
};

export const initialState = {
  ...getInitialState(),
};

const initFetching = (state, action) => {
  const updatedProperty = updateObject(state[action.id], { isLoading: true });
  return updateObject(state, { [action.id]: updatedProperty });
};

const generateShoutOut = (id, value, currency) => {
  return {
    prefix: shoutOutPrefixSuffixMapper[id].prefix(currency),
    val:
      shoutOutPrefixSuffixMapper[id].value(value, currency) +
      shoutOutPrefixSuffixMapper[id].suffix,
    isInfo: shoutOutPrefixSuffixMapper[id].isInfo,
  };
};

const generateLast7Days = (id, value, currency) => {
  return (
    last7DaysPrefixSuffixMapper[id].prefix(currency) +
    last7DaysPrefixSuffixMapper[id].value(value, currency) +
    last7DaysPrefixSuffixMapper[id].suffix
  );
};

const updateSuccessData = (state, action) => {
  const updatedProperty = updateObject(state[action.id], {
    isLoading: false,
    isError: false,
    shoutOut: generateShoutOut(
      action.id,
      action.payload.shoutOut,
      action.payload.currency,
    ),
    last7Days: generateLast7Days(
      action.id,
      action.payload.last7Days,
      action.payload.currency,
    ),
    to: performanceCardRouteMapper[action.id],
    loaded_at: moment(),
  });
  return updateObject(state, { [action.id]: updatedProperty });
};

const updateFailureData = (state, action) => {
  const updatedProperty = updateObject(state[action.id], {
    isError: true,
    isLoading: false,
    shoutOut: null,
    trend: null,
    last7Days: null,
    boost: null,
  });
  return updateObject(state, { [action.id]: updatedProperty });
};

const resetData = (state, action) => {
  const updatedProperty = updateObject(state[action.id], {
    shoutOut: null,
    trend: null,
    last7Days: null,
    to: null,
    isLoading: false,
    isError: false,
    boost: null,
  });
  return updateObject(state, { [action.id]: updatedProperty });
};

const updatePropertyData = (state, action) => {
  const updatedProperty = updateObject(state[action.id], action.payload);
  return updateObject(state, { [action.id]: updatedProperty });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING:
      return initFetching(state, action);
    case actionTypes.SUCCESS:
      return updateSuccessData(state, action);
    case actionTypes.FAILED:
      return updateFailureData(state, action);
    case actionTypes.RESET:
      return resetData(state, action);
    case actionTypes.UPDATE:
      return updatePropertyData(state, action);
    default:
      return state;
  }
};

export default reducer;
