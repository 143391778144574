import React, { RefObject, useContext, useEffect, useState } from 'react';
import { Box, Popper } from 'connect-web-ui/mui';
import { ChevronRightIcon } from 'assets/modules/sideNav';
import { LeftNavItem } from './SideNav.types';
import { SideNavChildrenItems } from './SideNavChildrenItems';
import { SideNavBadgeIcon } from './Components/SideNavBadgeIcon';
import { checkVisibility, classNames } from 'utilities/Utils';
import { SideNavConditionalTooltipWrapper } from './SideNavConditionalTooltipWrapper';
import SideNavDataIdWrapper from './SideNavOmnitureEventWrapper';
import { HotelContext } from 'utilities/Context';
import { handleSideNavItemClick } from './SideNav.utils';
import { Lock } from 'assets/modules/navigation';
import { pushToOmniture } from 'utilities/gtm';

type ISideNavItemProp = {
  tabItem: LeftNavItem;
  isActive: boolean;
  pushedOmnitureTabs: Record<string, string>;
};

const SideNavItemComp = (
  { tabItem, isActive, pushedOmnitureTabs }: ISideNavItemProp,
  ref: RefObject<HTMLDivElement>,
) => {
  const [mouseOverTargetEl, setMouseOverTargetEl] = useState<
  (EventTarget & Element) | null
  >(null);
  const { setShowHelpdeskDrawer } = useContext(HotelContext) || {};

  const isOpen = !!mouseOverTargetEl;
  const showBlocker = !!tabItem?.additionalMetadata?.verificationBlocker;
  const disabled = showBlocker || tabItem.disabled;
  const hasSubItems = !!tabItem?.items?.length;

  const applyActiveClass = !disabled && isActive;
  const applyHoverClass = !disabled && isOpen;
  const uiConfig = {
    activeClass: applyActiveClass ? '!bg-[#F6F7FF]' : '',
    showTooltip: disabled,
    iconHoverCss: applyHoverClass ? 'bg-[#F2F2F2]' : '',
    showSelectedIcon: !disabled,
    showBadge: !disabled,
    textThemeColor: applyActiveClass
      ? 'text-primary-default'
      : disabled
        ? 'text-[#757575]'
        : 'text-common-medium',
    textFontWeight: applyActiveClass ? 'font-bold' : '',
    containerCursor: disabled ? 'cursor-not-allowed' : 'cursor-pointer',
  };

  const handleClick = () => {
    if (disabled || !tabItem.cta?.deeplink || hasSubItems) return;
    handleSideNavItemClick(tabItem?.cta, setShowHelpdeskDrawer);
  };

  useEffect(() => {
    if (!ref?.current) return;
    if (checkVisibility(ref)) {
      pushedOmnitureTabs[tabItem.data?.title] = 'fully-visible';
    } else {
      pushedOmnitureTabs[tabItem.data?.title] = 'not-visible';
    }
  }, [ref?.current]);

  useEffect(() => {
    if (isOpen && tabItem.trackingConfig) {
      pushToOmniture({
        event: 'ctaLoad',
        cta: {
          name: `${tabItem.data?.title}_hover`,
          type: 'load',
          componentName: 'left_nav',
        },
      });
    }
  }, [mouseOverTargetEl]);

  return (
    <div
      className={classNames(
        'flex items-center justify-center flex-shrink-0 select-none h-[68px] w-[72px]  py-[6px] px-1 rounded',
        uiConfig.iconHoverCss,
        uiConfig.activeClass,
        uiConfig.containerCursor,
      )}
      ref={ref}
      onClick={handleClick}
      onMouseEnter={e => {
        if (!disabled) setMouseOverTargetEl(e.currentTarget);
      }}
      onMouseLeave={() => setMouseOverTargetEl(null)}
    >
      <SideNavConditionalTooltipWrapper
        showTooltip={uiConfig.showTooltip}
        tooltipContent={tabItem?.data?.toast}
      >
        <>
          {hasSubItems && (
            <SideNavDataIdWrapper
              trackingConfig={tabItem.trackingConfig}
              addDataId
            >
              <Popper
                open={isOpen}
                anchorEl={mouseOverTargetEl}
                placement="right-start"
                className="z-[100]"
              >
                <Box
                  sx={{
                    width: 218,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 0.5,
                    paddingX: 0.5,
                    marginLeft: 1,
                    paddingY: 0.5,
                    borderRadius: 2,
                    border: '1px solid #cbcbcb',
                    boxShadow: '0 0 6px -4px rgba(0, 0, 0, 0.1)',
                    backgroundColor: 'white',
                  }}
                >
                  <SideNavChildrenItems
                    childrenTabs={tabItem?.items}
                    closePopOver={() => setMouseOverTargetEl(null)}
                  />
                </Box>
              </Popper>
            </SideNavDataIdWrapper>
          )}
          <div
            className={classNames(
              'p-1 flex flex-col gap-1 justify-center items-center rounded w-[60px]',
            )}
          >
            <div className="relative h-7 w-7">
              <img
                src={
                  applyActiveClass
                    ? tabItem.data?.selectedIconUrl
                    : tabItem.data?.iconUrl
                }
                className="w-[28px] h-[28px]"
              />
              {uiConfig.showBadge ? (
                <>
                  {hasSubItems ? (
                    <span className="absolute py-2 ml-1">
                      <ChevronRightIcon />
                    </span>
                  ) : undefined}
                  <SideNavBadgeIcon badgeItem={tabItem.badge} />
                </>
              ) : undefined}
              {showBlocker && <Lock className="absolute bottom-4 left-4" />}
            </div>
            <p
              className={classNames(
                '!font-lato text-center text-wrap text-size-12 max-h-7 overflow-hidden leading-[14px] w-16 !m-0',
                uiConfig.textFontWeight,
                uiConfig.textThemeColor,
              )}
            >
              {tabItem.data?.title}
            </p>
          </div>
        </>
      </SideNavConditionalTooltipWrapper>
    </div>
  );
};

export const SideNavItem = React.forwardRef<HTMLDivElement, ISideNavItemProp>(
  SideNavItemComp,
);
