import { CSSProperties, memo } from 'react';
import { Typography } from 'connect-web-ui';
import { classNames, isEmpty } from 'utilities/Utils';
import { RichTextProps } from './TypeAndSchemas';
import RenderIcon from '../render-icon/RenderIcon';
import { useClientSideAction } from 'bff/hooks/useClientSideAction';
import { COMPONENTS } from 'bff/constants';

const RichText = (props: RichTextProps) => {
  const {
    text,
    textVariant = 'body1',
    fb = false,
    themeColor = 'text.primary',
    className = '',
    style = {},
    buttonType = 'none',
    onClick,
  } = props;
  const { handleClientSideAction } = useClientSideAction();

  if (isEmpty(text)) return null;
  const {
    content,
    bold = fb,
    variant = textVariant,
    color = themeColor,
    isLink = false,
    linkAction: action,
  } = text;

  let _styles: CSSProperties = {};
  if (buttonType !== 'none') {
    _styles = {
      ..._styles,
      ...{
        cursor: 'pointer',
        fontSize: buttonType === 'button-medium' ? '16px' : '14px',
        fontWeight: 600,
        lineHeight: '20px',
      },
    };
  }
  _styles = Object.assign(_styles, text.style);
  return (
    <span className={`inline ${className}`} style={style}>
      {content.map((item, index) => {
        if (typeof item === 'string') {
          return (
            <Typography
              key={index}
              fb={bold}
              variant={variant}
              themeColor={color}
              isClickable={isLink}
              onClick={() =>
                onClick ? onClick(action) : handleClientSideAction(action)
              }
              className={classNames(
                'whitespace-pre-wrap',
                isLink ? 'underline !text-primary-default select-none' : '',
              )}
              style={_styles}
              inline
            >
              {item}
            </Typography>
          );
        } else if (item.type === COMPONENTS.TEXT) {
          return (
            <RichText
              key={index}
              text={{
                type: item.type,
                content: item.content,
                bold: item?.bold ?? false,
                variant: item?.variant ?? variant,
                color: item?.color ?? color,
                isLink: item?.isLink ?? false,
                linkAction: item.linkAction,
              }}
              onClick={onClick}
            />
          );
        } else if (item.type === COMPONENTS.ICON) {
          return <RenderIcon key={index} icon={item} />;
        }
        return null;
      })}
    </span>
  );
};

export default memo(RichText);
