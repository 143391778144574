import { useState, ReactNode, forwardRef } from 'react';
import {
  Container,
  EndIconWrap,
  ErrorMsg,
  FooterActionComponent,
  InfoMsg,
  Input,
  Label,
} from './CustomInputField.styles';
import { colors } from 'style/zeplin-token-styledComponents';

export interface CustomInputFieldProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'onChange'> {
  label: string;
  labelColor?: string;
  onChange: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void;
  isError?: boolean;
  errorMessage?: string;
  infoMessage?: string;
  endIcon?: ReactNode;
  onEndIconClick?: () => void;
  footerComponent?: ReactNode;
  footerActionComponent?: ReactNode;
}

const CustomInputField: React.FC<CustomInputFieldProps> = forwardRef<
HTMLInputElement,
CustomInputFieldProps
>(
  (
    {
      name,
      label,
      defaultValue = '',
      labelColor = colors.white,
      placeholder,
      type = 'text',
      onChange,
      isError,
      errorMessage,
      infoMessage,
      endIcon,
      onEndIconClick,
      footerComponent,
      onBlur,
      footerActionComponent,
      tabIndex,
    },
    ref,
  ) => {
    const [value, setValue] = useState(defaultValue);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const handleChange = e => {
      setValue(e?.target?.value);
      onChange(e?.target?.value, e);
    };

    const togglePasswordVisible = () => {
      onEndIconClick?.();
      setIsPasswordVisible(prevState => !prevState);
    };

    return (
      <Container>
        <Label htmlFor={label} labelColor={labelColor}>
          {label}
        </Label>
        <Input
          ref={ref}
          name={name}
          id={label}
          type={type === 'password' && isPasswordVisible ? 'text' : type}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          isError={isError}
          onBlur={onBlur}
          tabIndex={tabIndex}
        />
        {!!endIcon && (
          <EndIconWrap onClick={togglePasswordVisible}>{endIcon}</EndIconWrap>
        )}
        <FooterActionComponent>
          {!!isError && <ErrorMsg>{errorMessage}</ErrorMsg>}
          {!!footerActionComponent && footerActionComponent}
        </FooterActionComponent>
        {!!infoMessage && <InfoMsg>{infoMessage}</InfoMsg>}
        {!!footerComponent && footerComponent}
      </Container>
    );
  },
);

export default CustomInputField;
