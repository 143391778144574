import { Add, InfoOutlined, Remove } from 'connect-web-ui/mui';
import { useContext, useState } from 'react';

import * as PerformanceBooster from 'assets/modules/performance-booster';
import {
  Drawer,
  Modal,
  Tooltip,
  Typography,
  Calendar,
  LegacyButton as Button,
  LegacyFormElement as FormElement,
} from 'connect-web-ui';
import { useMultiSelectCalendar } from 'connect-web-ui/hooks';
import {
  CUSTOMER_TYPE_MAP,
  SELECT_CUSTOMER_TYPE_MAP,
  mmtBlackList,
  mmtSelectList,
  cugReverseMap,
} from 'modules/promotions-and-coupons/PnC.constants';
import { DealsContext } from 'utilities/Context';
import { useLangStrings } from 'utilities/CustomHooks';
import {
  formattedGroupDates,
  getFormattedDate,
  unGroupDates,
} from 'utilities/DateUtils';
import { GAFormatting } from 'utilities/Utils';
import { DiscardModal } from '..';
import MMTTierInput, { useMMTTierInput } from './MMTTierInput';

import { PNC_TAB } from 'interfaces/PncContextInterface';
import {
  createOfferValuePayload,
  sortOfferValueList,
} from 'modules/promotions-and-coupons/utils';
import { DRAWER_TYPE } from 'modules/promotions-and-coupons/performanceBooster/BoosterDrawerController';
import { legacyPushToGTM } from 'utilities/gtm';
import CONSTANTS, {
  CUGType,
} from 'modules/performanceBooster/PerformanceBooster.constants';

import './MMTBlackDrawer.scss';

const getTooltipContent = (STRINGS, isBlack) => {
  const {
    DRAWER_META: {
      MMT_COMMON_CUG: { TOOLTIP: tooltip },
    },
  } = STRINGS;

  const SegmentType = isBlack ? CUGType.MMT_BLACK : CUGType.MMT_SELECT;
  const toolTipInfo = isBlack ? tooltip : tooltip.slice(0, -1);
  return (
    <>
      {toolTipInfo.map((item, index) => {
        const { QUESTION: question, ANSWER: answer, NEST: nest } = item;
        return (
          <div className="mmt-black-section" key={index}>
            <h3>
              {question.replace('__placeholder__', SegmentType.toUpperCase())}
            </h3>
            <p>{answer.replace('__placeholder__', SegmentType)}</p>
            {nest && (
              <ul>
                {nest.map((nestedItem, nestedIndex) => {
                  const { TITLE: title } = nestedItem;
                  return (
                    <li key={nestedIndex}>
                      <strong>
                        {title.replace('__placeholder__', SegmentType)}{' '}
                      </strong>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        );
      })}
    </>
  );
};

const getAPIOfferListObject = (segment, offerValue) => ({
  isactive: 1,
  offer_basis: 'cug',
  channel: 'all',
  offer_type: 'percentage',
  offer_value: offerValue,
  segment,
});

export default function MMTBlackDrawer(props) {
  const {
    data,
    onDrawerClose,
    createCUG,
    updateCUG,
    cugAffiliates,
    type,
    isOfferAgain,
  } = props;
  const isBlack = type === DRAWER_TYPE.MMT_BLACK;
  const segmentType = isBlack ? 'MMT_BLACK' : 'MMT_SELECT';
  const gtmActionType = GAFormatting(CONSTANTS.TYPE[segmentType]);
  const {
    pncMetadata: {
      CUG: {
        offerCategories: { [cugReverseMap[segmentType]]: meta },
      },
    },
    cugBeingEdited,
    setAllCugStatus,
    activePncTab,
  } = useContext(DealsContext);

  const [STRINGS] = useLangStrings('PerformanceBooster');
  const [showMore, setShowMore] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(null);
  const isEdit = !!data?.offerCode && !isOfferAgain;
  const mmtTierState = useMMTTierInput(
    sortOfferValueList(data?.offerValueList || []),
    meta,
    isBlack,
  );
  const segmentListType = isBlack ? mmtBlackList : mmtSelectList;
  const blackOutList = unGroupDates(data.blackOut);
  const {
    monthlyBlackoutLimit: monthlyLimit,
    annualBlackoutLimit: annualLimit,
  } = meta;
  const blackOutOptions: {
    month?: number;
    year?: number;
  } = {};
  if (monthlyLimit) {
    blackOutOptions.month = monthlyLimit;
  }
  if (annualLimit) {
    blackOutOptions.year = annualLimit;
  }
  const [blackoutSelectedList, setBlackoutSelectedList] = useState([]);
  const blackoutCalendarProps = useMultiSelectCalendar({
    initialDate: blackOutList,
    callbackAfterDateSelection: setBlackoutSelectedList,
    multiSelectRestriction: {
      month: monthlyLimit,
      year: annualLimit,
      total: Infinity,
    },
  });
  const closeHandler = () => {
    const eventAction = `${gtmActionType}_edit_screen`;
    legacyPushToGTM({
      eventCategory: 'web_boostperformance',
      eventAction,
      eventLabel: 'close',
    });
    setShowCancelModal(true);
  };
  const getMapList = () => {
    let mapObject: {
      tiers?: string[];
      apiKeys?: string[];
    } = {};
    if (isBlack) {
      mapObject = {
        tiers: [STRINGS.PREFERRED, STRINGS.ELITE, STRINGS.EXCLUSIVE],
        apiKeys: mmtBlackList,
      };
    } else {
      mapObject = {
        tiers: [STRINGS.BRONZE, STRINGS.SILVER, STRINGS.GOLD],
        apiKeys: mmtSelectList,
      };
    }
    return mapObject;
  };

  const getModificationMap = () => {
    const { API_DATE_FORMAT: dateFormat } = CONSTANTS;
    const { tiers, apiKeys } = getMapList();
    const modificationMap = {};

    const { populateMMTTierData } = mmtTierState;
    const [updatedTier1, updatedTier2, updatedTier3] = populateMMTTierData();

    const [initialTier1, initialTier2, initialTier3] =
      activePncTab === PNC_TAB.expired
        ? [null, null, null]
        : data.offerValueList.map(offer => offer.offer_value);
    // Preferred check
    if (initialTier1 !== updatedTier1) {
      modificationMap[tiers[0]] = {
        apiKey: apiKeys[0],
        isOfferValue: true,
        type: 'number',
        initialValue: initialTier1,
        updatedValue: updatedTier1,
      };
    }
    // Elite check
    if (initialTier2 !== updatedTier2) {
      modificationMap[tiers[1]] = {
        apiKey: apiKeys[1],
        isOfferValue: true,
        type: 'number',
        initialValue: initialTier2,
        updatedValue: updatedTier2,
      };
    }
    // Exclusive check
    if (initialTier3 !== updatedTier3) {
      modificationMap[tiers[2]] = {
        apiKey: apiKeys[2],
        isOfferValue: true,
        type: 'number',
        initialValue: initialTier3,
        updatedValue: updatedTier3,
      };
    }

    const isBlackOutListChanged =
      activePncTab === PNC_TAB.expired ||
      blackOutList.join(',') !== blackoutSelectedList.join(',');

    // Blackout dates changes
    if (showMore && isBlackOutListChanged) {
      modificationMap[STRINGS.DRAWER.BLACKOUT] = {
        apiKey: 'checkinblackoutdates',
        isOfferValue: false,
        type: 'dateList',
        initialValue: blackOutList,
        updatedValue: formattedGroupDates(blackoutSelectedList, dateFormat),
      };
    }
    return modificationMap;
  };

  const performanceActions = modificationMap => {
    const {
      TYPE: { [segmentType]: segment },
      API_DATE_FORMAT: dateFormat,
    } = CONSTANTS;

    if (isEdit) {
      const payload: {
        offer_value_list?;
      } = {};
      const offerValueList = [];

      for (const modification in modificationMap) {
        const {
          apiKey,
          isOfferValue,
          updatedValue: apiKeyValue,
        } = modificationMap[modification];
        if (isOfferValue) {
          offerValueList.push(getAPIOfferListObject(apiKey, apiKeyValue));
        } else {
          payload[apiKey] = apiKeyValue;
        }
      }
      payload.offer_value_list = offerValueList;
      updateCUG(payload, segment, data.offerCode);
    } else {
      const { populateMMTTierData } = mmtTierState;
      const newMMTBlackSelectTier = populateMMTTierData();

      const segmentList = segmentListType;

      const checkIn = getFormattedDate(new Date(), dateFormat);
      const checkOut = getFormattedDate(
        new Date(new Date().getFullYear() + 10, 0),
        dateFormat,
      );

      // Sync with mobile!
      const payload: {
        checkindatestart: string;
        checkoutdateend: string;
        offer_value_list?;
        checkinblackoutdates?: string[][];
      } = {
        checkindatestart: checkIn,
        checkoutdateend: checkOut,
      };
      const offerValueList = newMMTBlackSelectTier.reduce(
        (acc, tier, index) => {
          acc.push(getAPIOfferListObject(segmentList[index], tier));
          return acc;
        },
        [],
      );

      payload.offer_value_list = offerValueList;
      if (showMore) {
        payload.checkinblackoutdates = formattedGroupDates(
          blackoutSelectedList,
          dateFormat,
        );
      }

      createCUG(payload, segment);
    }
  };

  const confirmBooster = () => {
    const eventAction = `${gtmActionType}_edit_screen`;
    legacyPushToGTM({
      eventCategory: 'web_boostperformance',
      eventAction,
      eventLabel: 'activate',
    });
    const hasError = mmtTierState.errors.some(tier => tier);
    if (hasError) {
      return;
    }
    const modificationMap = getModificationMap();
    if (Object.keys(modificationMap).length !== 0) {
      setConfirmModal(modificationMap);
    } else {
      onDrawerClose();
    }
  };

  const handleShowMore = () => {
    const eventAction = `${gtmActionType}_edit_screen`;
    const eventLabel = showMore
      ? 'advance_settings_hide'
      : 'advance_settings_show';
    legacyPushToGTM({
      eventCategory: 'web_boostperformance',
      eventAction,
      eventLabel,
    });
    setShowMore(c => !c);
  };

  const offerConfirm = () => {
    const eventAction = `${gtmActionType}_confirm`;
    legacyPushToGTM({
      eventCategory: 'web_boostperformance',
      eventAction,
      eventLabel: 'confirm',
    });
    performanceActions(confirmModal);
  };

  const offerCancel = () => {
    const eventAction = `${gtmActionType}_confirm`;
    legacyPushToGTM({
      eventCategory: 'web_boostperformance',
      eventAction,
      eventLabel: 'cancel',
    });
    setConfirmModal(null);
  };

  const mmtBlackIcon = (
    <img
      src={PerformanceBooster.DRAWER.MMT_BLACK}
      className="d-inflex marginR4"
      width="20"
      height="18"
      alt="MMTBlack"
    />
  );

  const blackoutDateLabel = STRINGS.DRAWER.BLACKOUT_DATE_LABEL.replace(
    '__year__',
    `${annualLimit}`,
  ).replace('__month__', `${monthlyLimit}`);

  const tooltipContent = getTooltipContent(STRINGS, isBlack);

  const setValuesWhileCreatingCUG = () => {
    const allTierData = mmtTierState.data;
    const offerValueData = createOfferValuePayload(allTierData, cugBeingEdited);
    let blackoutDates = [];
    for (const custValues in confirmModal) {
      const { updatedValue } = confirmModal[custValues];
      blackoutDates = Array.isArray(updatedValue) ? updatedValue : [];
    }
    setAllCugStatus(prev => ({
      ...prev,
      [cugBeingEdited]: {
        ...prev[cugBeingEdited],
        isCustomised: true,
        isActive: true,
        checkinblackout: blackoutDates,
        offer_value_list: offerValueData,
      },
    }));
    setConfirmModal(null);
    onDrawerClose();
  };

  const callbackFuntionOnConfirm =
    isEdit || isOfferAgain ? offerConfirm : setValuesWhileCreatingCUG;

  return (
    <Drawer
      isOpen
      header={{
        title: (
          <div className="flex gap-1">
            {isBlack && <div>{mmtBlackIcon}</div>}
            <Typography variant="h3" className="pt-[2px]">
              {STRINGS.DRAWER_META.MMT_COMMON_CUG.TITLE.replace(
                '__placeholder__',
                DRAWER_TYPE[segmentType],
              )}
            </Typography>
          </div>
        ),
        subTitle: STRINGS.DRAWER_META.MMT_COMMON_CUG.SUB_TITLE.replace(
          '__placeholder__',
          isBlack ? CUGType.MMT_BLACK_GO : CUGType.MMT_SELECT,
        ),
        rightElem: (
          <Tooltip
            content={tooltipContent}
            placement="bottom"
            variant="popover"
            customClasses={{
              tooltip: { root: '!mr-2' },
            }}
          >
            <InfoOutlined className="mt-1" />
          </Tooltip>
        ),
        hideCross: true,
      }}
      footer={{
        primaryBtn: {
          text: STRINGS.DRAWER_META.MMT_COMMON_CUG.POSITIVE_TEXT,
          onClick: confirmBooster,
        },
        secondaryBtn: {
          text: 'Close',
          onClick: closeHandler,
        },
      }}
      onClose={closeHandler}
    >
      <div className="mmt-black-drawer">
        <MMTTierInput
          {...mmtTierState}
          cugAffiliates={cugAffiliates}
          isBlack={isBlack}
        />

        <Button onClick={handleShowMore} variant="text" noPadding>
          {showMore ? (
            <>
              <Remove className="booster-advance-settings-icon" />
              {STRINGS.DRAWER.HIDE_ADVANCE_SETTINGS}
            </>
          ) : (
            <>
              <Add className="booster-advance-settings-icon" />
              {STRINGS.DRAWER.SHOW_ADVANCE_SETTINGS}
            </>
          )}
        </Button>
        {showMore && (
          <FormElement
            className="marginT28"
            label={STRINGS.DRAWER.CHOOSE_BLACKOUT_DATES}
          >
            <Calendar
              type="blackout"
              {...blackoutCalendarProps}
              disabledBefore={blackOutList[0] || new Date()}
              captionLabel={blackoutDateLabel}
            />
          </FormElement>
        )}

        {showCancelModal && (
          <DiscardModal
            gtmActionType={gtmActionType}
            onDrawerClose={onDrawerClose}
            setShowCancelModal={setShowCancelModal}
          />
        )}

        {confirmModal && (
          <Modal
            variant="confirm"
            isOpen
            header={{
              title: STRINGS.MMT_INFO_MODAL.TITLE.replace(
                '__placeholder__',
                DRAWER_TYPE[segmentType],
              ),
            }}
            footer={{
              primaryBtn: {
                onClick: callbackFuntionOnConfirm,
              },
              secondaryBtn: {
                onClick: offerCancel,
              },
            }}
          >
            <div className="confirm-tier-modal min-w-[468px]">
              <div>
                {Object.keys(confirmModal).map(key => {
                  const {
                    type: _type,
                    initialValue,
                    updatedValue,
                  } = confirmModal[key];
                  if (_type === 'dateList') {
                    const updatedValueList = unGroupDates(updatedValue)
                      .map(date =>
                        getFormattedDate(date, CONSTANTS.BOOSTER_DATE_FORMAT),
                      )
                      .join(', ');
                    return (
                      <p>
                        {key}: <strong>{updatedValueList}</strong>
                      </p>
                    );
                  }
                  return (
                    <p>
                      {STRINGS[segmentType]}{' '}
                      {(isBlack
                        ? CUSTOMER_TYPE_MAP[key]
                        : SELECT_CUSTOMER_TYPE_MAP[key]) || key}
                      :{' '}
                      {initialValue && (
                        <strong>
                          {initialValue}% {STRINGS.TO}{' '}
                        </strong>
                      )}
                      <strong>{updatedValue}%</strong>
                    </p>
                  );
                })}
                {cugAffiliates.map((affiliate, idx) => {
                  const findApiKey = Object.keys(confirmModal).find(
                    key => confirmModal[key].apiKey === affiliate.cug_segment,
                  );
                  if (!findApiKey) return null;
                  const { initialValue, updatedValue } =
                    confirmModal?.[findApiKey] || {};
                  return (
                    <p key={`affiliate-${idx}-update`}>
                      {STRINGS[segmentType]} {affiliate.display}:{' '}
                      {initialValue && (
                        <strong>
                          {initialValue * affiliate.offer_proportion}%
                          {STRINGS.TO}{' '}
                        </strong>
                      )}
                      <strong>
                        {updatedValue * affiliate.offer_proportion}%
                      </strong>
                    </p>
                  );
                })}
              </div>
              <span>{STRINGS.MMT_INFO_MODAL.INFO}</span>
            </div>
          </Modal>
        )}
      </div>
    </Drawer>
  );
}
