import noBooster from './img-no-booster.png';
import zeroPerformance from './img-zero-performance.png';
import loyalCustomers from './loyal-customers.svg';
import popUp from './pop-up.jpg';
import dailyStealLogo from './daily_steal_deal.svg';
import GEOGRAPHY from './geography.png';
import MMT_BLACK from './mmt-black.png';
import corporate from './my-biz.png';
import MOBILE from './mobile.svg';
import b2a from './my-partner.svg';
import gcc from './gcc.png';
import Holidays from './holidays.png';
import MMT_BLACKDrawer from './logo-mmt-black.svg';
import corporateDrawer from './logo-my-biz.svg';

const CUG = {
  GEOGRAPHY,
  MMT_BLACK,
  corporate,
  MOBILE,
  b2a,
  gcc,
  Holidays,
};

const DRAWER = {
  MMT_BLACK: MMT_BLACKDrawer,
  corporate: corporateDrawer,
};

export { default as Commission } from './commission.png';
export { default as MMTBranding } from './mmt-branding.png';
export { default as GoibiboBranding } from './goibibo-branding.png';
export { ReactComponent as MMTGI } from './mmt-gi.svg';
export { default as MyPartner } from './partner.png';
export { ReactComponent as MyBiz } from './biz.svg';
export { default as BusinessGrowth } from './growth.png';
export { ReactComponent as LastMinuteDeal } from './last-minute-deal.svg';
export { ReactComponent as BlackDiscount } from './black-discount.svg';
export { ReactComponent as SponsoredListing } from './sponsored-listing.svg';

export {
  dailyStealLogo,
  noBooster,
  zeroPerformance,
  loyalCustomers,
  popUp,
  CUG,
  DRAWER,
};
