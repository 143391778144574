export const campaigns = {
  mmtBlack: 'MMT_BLACK', //1
  coupon: 'COUPON', //3
  mobile: 'MOBILE', //2
} as const;

export const campaignStatus = {
  active: 'active',
  paused: 'paused',
  inactive: 'inactive',
} as const;

export const campaignStatusNumMap = {
  active: 1,
  paused: 0,
  inactive: 2,
} as const;

export const participationStatus = {
  pending: 'PENDING',
  accepted: 'ACCEPTED',
  terminated: 'TERMINATED',
} as const;

export const participationStatusNumMap = {
  PENDING: 0,
  ACCEPTED: 1,
  TERMINATED: 2,
} as const;

export const campaignTypes = {
  goStay: 'GO_STAY',
  mmtAdvantage: 'MMT_ADVANTAGE',
  dsd: 'DSD',
  sale: 'SALE',
} as const;

export const campaignTypesNumMap = {
  GO_STAY: 1,
  MMT_ADVANTAGE: 2,
  DSD: 3,
  SALE: 4,
} as const;

export const formNames = {
  discount: 'discount',
  blackoutDate: 'blackoutDate',
};

export const queryKeys = {
  getSaleCampaignDetails: 'getSaleCampaignDetails',
  participateInSale: 'participateInSale',
};

export const omniture = {
  event: {
    ctaClick: 'ctaClick',
    ctaLoad: 'ctaLoad',
    pageView: 'pageView',
  },
  type: {
    click: 'click',
    load: 'load',
  },
  sale_handling: 'sale_handling', //copying names from excel sheet, hence not used camel casing
  sale_top_banner_click: 'sale_top_banner_click',
  sale_top_banner: 'sale_top_banner',
  whatsnew_banner: 'whatsnew_banner',
  intro_video_click: 'intro_video_click',
  confirm_participation: 'confirm_participation',
  banner_sale_handling: 'banner_sale_handling',
  pageView: 'pageView',
  main_page: 'Main Page',
  cancel: 'cancel',
  popup_cancel_confirmation: 'popup_cancel_confirmation',
  blackout_dates_show: 'blackout_dates_show',
  blackout_dates_hide: 'blackout_dates_hide',
  popup_sale_intro_video: 'popup_sale_intro_video',
  sale_carousel: 'sale_carousel',
  black_out_dates: 'black_out_dates',
  whats_new: 'whats_new',
};

export const saleCampaign = 'sale-campaign';

export const pageNames = {
  bookings: 'bookings',
  ratesAndInventory: 'rates-inventory',
  promotions: 'promotions',
  dasboard: 'dashboard',
};

export const maxAllowedDiscount = 90;

export const ERRORS = {
  INTERNAL_ERROR: 'INTERNAL_ERROR',
  NETWORK_ERROR: 'NETWORK_ERROR',
  CAMPAIGN_404: 'CAMPAIGN_404',
};
