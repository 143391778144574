import React from 'react';
import { classNames } from 'utilities/Utils';
import { CountDown } from 'modules/sale-handling/components/CountDown';
import { type DashboardCarouselItemProps } from '../Dashboard.types';

export const DashboardCarouselItem = React.memo(
  (props: DashboardCarouselItemProps) => {
    const {
      carouselItem,
      setCarouselItems,
      setImgsLoaded,
      redirect,
      multiItem = false,
    } = props;
    return (
      <div
        className={classNames(
          `relative cursor-pointer ${
            multiItem
              ? 'w-full h-[160px]'
              : 'flex justify-center items-center !w-full'
          } `,
        )}
        onClick={() => redirect(carouselItem)}
      >
        <img
          src={carouselItem.background_image_url}
          alt={carouselItem.id}
          className={`select-none  ${
            multiItem ? 'w-auto h-[160px] rounded-lg' : '!w-full rounded-lg'
          }`}
          onLoad={() =>
            setImgsLoaded(prevImgsLoaded => ({
              ...prevImgsLoaded,
              [carouselItem.id]: true,
            }))
          }
          onError={() => {
            setCarouselItems(prevCarouselItems =>
              prevCarouselItems.filter(item => item.id !== carouselItem.id),
            );
          }}
        />
        {carouselItem.sale && (
          <div className="absolute top-[12px] right-[12px]">
            <CountDown end={carouselItem.expiry_date} />
          </div>
        )}
      </div>
    );
  },
);
