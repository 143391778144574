export const ADD_CALENDAR = `
mutation AddCalendarV2($rawEndpointRequestData: RawEndpointsRequestData) {
    rawEndpoint(endpointRequestData: $rawEndpointRequestData) {
        externalCalendarAddCalendarV2 {
            modelType1 {
                code
                 message
                 data
                 {
                    url
                    id
                    name
                }
                    success
                     }
                    }
                }
            }
`;

export const EDIT_CALENDAR = `
mutation EditCalendarV2($rawEndpointRequestData: RawEndpointsRequestData) {
    rawEndpoint(endpointRequestData: $rawEndpointRequestData) {
       externalCalendarEditCalendarV2 {
        modelType1 {
            code 
            message
            data
            {
                url
                id
                name
            }
            success
            }
        }
    }
}
`;

export const SYNC_ENABLED_ROOMS = `
query SyncEnabledRooms($rawEndpointRequestData: RawEndpointsRequestData) {
    rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
       fetchSyncRooms {
        modelType1 {
            message
            code
            success
            data
            {
                multi_inventory_rooms
                sync_enabled
                sync_not_enabled
            }
        }
    }
}
}
`;

export const FETCH_CALL_DETAILS = `
query FetchCalDetails($rawEndpointRequestData: RawEndpointsRequestData) {
    rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
        fetchCalDetails {
            modelType1 {
                message
                code
                success
                data
                {
                    brand_id
                    calendar_id
                    export_url
                    import_url,
                    other_brand_name
                }
                }
            }
        }
    }
`;

export const FETCH_TIMER = `
query FetchTimerDetails($rawEndpointRequestData: RawEndpointsRequestData) {
    rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
       fetchTimerDetails {
         modelType1 {
            message
            code
            success
            expires_on 
           }
        }
    }
}
`;

export const FETCH_BRAND_LIST = `
query FetchBrandList($endpointRequestData: EndpointRequestData) { 
    syncCalFetchBrand(endpointRequestData: $endpointRequestData) {
        fetchBrandList {
            code
            success
            data{
                label
                value
            }
            message
            }
        }
    }
`;

export const UPDATE_CREATE_API = `
mutation UpdateTimerDetails($rawEndpointRequestData: RawEndpointsRequestData) {
    rawEndpoint(endpointRequestData: $rawEndpointRequestData) {
        updateTimerDetails {
            modelType1 {
                message
                code
                success
                expires_on
                cal_id
               
            }
        }
    }
}
`;

export const REMOVE_CALENDAR_API = `
mutation RemoveCalendarV2($rawEndpointRequestData: RawEndpointsRequestData) {
    rawEndpoint(endpointRequestData: $rawEndpointRequestData) {
        externalCalendarRemoveCalendarV2 {
            modelType1 {
                success
                message
            }
        }
    }
}
`;

export const EXPORT_CALENDAR_API = `
mutation ExportCalendarV2($rawEndpointRequestData: RawEndpointsRequestData) {
    rawEndpoint(endpointRequestData: $rawEndpointRequestData) {
        externalCalendarExportCalendarV2 {
            modelType1 {
                message
                code
                data{
                    url
                    file_key
                }
                success
            }
        }
    }
}
`;

export const SYNC_CALENDAR_SYNC_API = `
mutation SyncCalSyncV2($rawEndpointRequestData: RawEndpointsRequestData) {
    rawEndpoint(endpointRequestData: $rawEndpointRequestData) {
        externalCalendarPerformSyncV2 {
             modelType1 {
                success
                message
                }
            }
        }
    }
`;
