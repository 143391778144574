export const persuasionDocumentQuery = `
	mutation AnalyticsV1Persuasions($rawEndpointRequestData: RawEndpointsRequestData){
		rawEndpoint(endpointRequestData: $rawEndpointRequestData){
			analyticsV1Persuasions {
				modelType1 {
					data
					message
					status
				}
			}
		}
	}
`;

export const persuasionHistogramQuery = `
	query AnalyticsHistogram($rawEndpointRequestData: RawEndpointsRequestData){
    rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData){
      analyticsHistogram{
        modelType1 {
          data
          message
          status
        }
      }
    }
  }
`;

export const updatePersuasionQuery = `
	mutation UpdateOfferAndResolve($businessLogicData: EndpointBusinessLogicData) {
		UpdateOfferAndResolve(
		  businessLogicData: $businessLogicData
		) {
		  updateOffer
		  updatePersuasion
		}
	}
`;
