import { Button, Modal } from 'connect-web-ui';
import { useState } from 'react';
import { capitalizeFirst, getURLWithParams, navigateTo } from 'utilities/Utils';
import PersuasionModal from './PersuasionModal';
import pushToGTM, { GTM_CATEGORIES } from 'utilities/gtm';
import { routes } from 'app/routes';
import { useCurrentHotel } from 'utilities/CustomHooks';
import { DYNAMIC_POPUP, DYNAMIC_ROUTE } from '../OpportunityCenter.constants';
import type { PersuasionObj } from '../OpportunityCentreDashboard';

type PersuasionActionButtonProps = {
  route: string;
  btn: {
    text: string;
    type: string;
    redirectionLink?: string;
  };
  persuasion: PersuasionObj;
  dashboard?: boolean;
  refreshData: () => void;
  rank: number;
};

const PersuasionActionButton = (props: PersuasionActionButtonProps) => {
  const {
    route,
    btn,
    persuasion,
    dashboard = false,
    refreshData,
    rank,
  } = props;

  const {
    data = {},
    title = '',
    type = '',
    sub_type: subType,
    id = '',
  } = persuasion || {};
  const {
    promo_code: promoCode = '',
    identifier = '',
    offer_type: offerType = [],
  } = data || {};
  const { text: btnText } = btn;
  const [hotelcode] = useCurrentHotel();
  const [openPopup, setOpenPopup] = useState(false);
  const roomTypeCode = id?.split('_')?.[1] || '';

  const getPromotionRoute = () => {
    const promotionRoutes = {
      no_promotion_applied: routes.businessBooster.pnc.lastMinute,
      no_lmr: routes.businessBooster.pnc.lastMinute,
      no_early_bird: routes.businessBooster.pnc.earlyBird,
    };
    return promotionRoutes[identifier] || '';
  };

  const getRouteAndQueryParam = () => {
    let queryParam = '';
    let updatedPncRoute = '';

    const isPromotionNotApplied =
      type === 'promotion' && subType === 'not_applied';
    const isInventorySoldOut =
      type === 'inventory' && subType === 'sold_out' && roomTypeCode;
    const hasPromoCode = !!promoCode;

    if (isPromotionNotApplied) {
      updatedPncRoute = getPromotionRoute();
    } else if (isInventorySoldOut) {
      queryParam = `?roomTypeCode=${roomTypeCode}`;
    } else if (hasPromoCode) {
      updatedPncRoute = routes.businessBooster.pnc.modify;
      queryParam = `?id=${promoCode}`;
    }

    return { updatedPncRoute, queryParam };
  };

  const getModalTitle = () => {
    const pncOfferType = offerType?.[0];
    return `${
      pncOfferType ? capitalizeFirst(pncOfferType) : 'Promotion/Coupon'
    } Expiring`;
  };

  const pushToGTMWithCondition = () => {
    const category = dashboard
      ? GTM_CATEGORIES.DASHBOARD
      : GTM_CATEGORIES.ACTION_DETAIL;
    const action = dashboard ? 'action_center' : 'action_center_l2';
    const label = `click_${type} | rank: ${rank} | ${title}`;
    pushToGTM(category, action, label);
  };

  const navigateToRoute = (updatedRoute: string) => {
    pushToGTMWithCondition();

    switch (updatedRoute) {
      case DYNAMIC_POPUP:
        setOpenPopup(true);
        break;
      case DYNAMIC_ROUTE:
        {
          let url = btn?.redirectionLink || routes.dashboard.base;
          if (type === 'rtb' && subType === 'requests') {
            const params = {
              vendorBookingId: data?.vendorBookingId,
            };
            url = getURLWithParams(url, params);
          }
          navigateTo(url);
        }
        break;
      default:
        navigateTo(updatedRoute);
    }
  };

  const getUpdatedRoute = () => {
    const { updatedPncRoute, queryParam } = getRouteAndQueryParam();
    const updatedRoute = (updatedPncRoute || route) + queryParam;
    return updatedRoute;
  };

  const handleButtonClick = () => {
    if (!route) return;
    const updatedRoute = getUpdatedRoute();
    navigateToRoute(updatedRoute);
  };

  return (
    <div className="persuasion-action-btn">
      <div className="btn-container">
        {route ? (
          <Button
            variant="text"
            onClick={handleButtonClick}
            data-test-id="oc_persuasion-btn"
            className="text-size-14"
          >
            {btnText}
          </Button>
        ) : (
          btnText
        )}
      </div>

      {openPopup && (
        <Modal
          isOpen
          header={{
            title: getModalTitle(),
          }}
          onClose={() => setOpenPopup(false)}
        >
          <PersuasionModal
            persuasion={persuasion}
            onModalClose={() => setOpenPopup(false)}
            dashboard
            hotelcode={hotelcode}
            refreshData={refreshData}
          />
        </Modal>
      )}
    </div>
  );
};

export default PersuasionActionButton;
