import { doMutation, doQuery } from 'base/GraphQLClient';
import { v4 as generateUuid } from 'uuid';
import {
  Campaign,
  CampaignEntityAgreement,
} from 'modules/campaign-manager/Campaigns.types';
import {
  CurrentHotel,
  RoomRateplan,
} from '../../interfaces/HotelContextInterface';
import { SYNC_ENABLED_ROOMS } from 'modules/inventory/external-cal-sync/ExternalCalSyncQueries';
import {
  campaignStatus,
  campaignStatusNumMap,
  campaignTypes,
  campaignTypesNumMap,
  participationStatus,
  participationStatusNumMap,
} from 'modules/sale-handling/SaleHandling.constants';
import { SaleResponseData } from 'modules/sale-handling/SaleHandling.types';
import { appendCDN, getValue, isNullOrUndefined, pick } from 'utilities/Utils';
import { RESELLER_AGREEMENT_ID } from './../../constants';
import { saleCampaignMetaObject } from './Hotel.utils';
import { roomRateMapObjects, isGroupRateplan } from './HotelAPIClient';
import {
  GET_ALL_SALES_QUERY,
  campaignEntityAgreement,
  fetchCompsetDataQuery,
  getDecideHotelAgreementPopupQuery,
  getResellerAgreementQuery,
  getRoomsRatePlansDetailsQuery,
  hotelAgrementAndTaxData,
  remainingLegacyAndCalenderSyncData,
  GET_FEATURE_FLAGS,
} from './HotelGraphQueries';
import { SaleCampaignMetaData } from './interfaces';
import { ORDER_STATUS } from 'modules/prebuy-ledger/constants';
import { FETCH_PENDING_PREBUY_ORDERS_COUNT } from 'modules/prebuy-ledger/api/prebuyLedgerGraphqlQueries';

export const getCampaignEntityAgreement = (
  hotelCode,
): Promise<CampaignEntityAgreement> => {
  const variable = {
    var: {
      campaignEntityAgreementInput: {
        code: hotelCode,
      },
    },
  };
  return doQuery(campaignEntityAgreement, variable).then(({ data, error }) => {
    if (!error) {
      const {
        campaignEntityAgreement: { data: campaigns },
      } = data;
      const nodes = campaigns.reduce((acc, node) => {
        const {
          campaign_data,
          metadata,
          related_json_data,
          status,
          id,
          is_dsd_activated,
        } = node;
        const { agreement_id, sample_url } = campaign_data.agreement ?? {};
        const {
          campaign_desc,
          campaign_name,
          cmp_long_desc,
          video_url,
          campaign_type,
          metadata: campaignMetaData,
          id: campaignId,
        } = campaign_data;

        if (status !== 'accepted' && status !== 'pending') {
          return acc;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let jsonData: any;
        try {
          if (typeof related_json_data === 'string') {
            jsonData = JSON.parse(related_json_data);
          } else {
            jsonData = related_json_data;
          }
        } catch (e) {
          jsonData = {};
        }

        const isAccepted = status === 'accepted';
        if (isAccepted && isNullOrUndefined(jsonData.accepted_on)) {
          return acc;
        }

        const campaign: Campaign = {
          agreement: {
            id: agreement_id,
            pdfURL: !isNullOrUndefined(sample_url)
              ? appendCDN(sample_url)
              : null,
          },
          enrollmentDate: jsonData.accepted_on,
          isAccepted,
          campaign_desc,
          campaign_name,
          cmp_long_desc,
          campaignId,
          id,
          status,
          video_url,
          metadata: { ...metadata, ...campaignMetaData },
          is_dsd_activated,
        };
        acc[campaign_type] = campaign;
        return acc;
      }, {});
      return Promise.resolve(nodes);
    }
    return Promise.resolve({});
  });
};

export const getHotelAgrementAndTaxData = variable => {
  return doQuery(hotelAgrementAndTaxData, variable, { useLoader: false });
};

export const getRemainingLegacyAndCalenderSyncData = variable => {
  return doQuery(remainingLegacyAndCalenderSyncData, variable, {
    useLoader: false,
  });
};

export async function fetchPendingPrebuyOrderCount(
  hotelId: CurrentHotel['mmt_id'],
  isResellerEnabled = false,
) {
  if (!isResellerEnabled) {
    return 0;
  }

  const variables = {
    endpointRequestData: {
      rpcGetHotelCloudPrebuyOrdersRequest: {
        getPendingPrebuyOrderRequest: {
          body: {
            correlationKey: generateUuid(),
            hotelId,
            status: [ORDER_STATUS.PENDING, ORDER_STATUS.EXTENSION_REQUESTED],
          },
        },
      },
    },
  };

  const { data } = await doQuery(FETCH_PENDING_PREBUY_ORDERS_COUNT, variables, {
    useLoader: false,
    showError: false,
  });

  return getValue(
    data,
    'rpcGetHotelCloudPrebuyOrders.pendingGetPrebuyOrders.totalOrders',
    0,
  ) as number;
}

export function syncEnabledRoomsAPI(hotelCode, options = { useLoader: true }) {
  const query = SYNC_ENABLED_ROOMS;
  const variables = {
    rawEndpointRequestData: {
      fetchSyncRooms: {
        endPointId: 1,
        modelType: 'modelType1',
        queryParams: {
          hotel_code: hotelCode,
        },
      },
    },
  };
  return doQuery(query, variables, { ...options }).then(response => {
    const syncNotEnabled =
      getValue(
        response,
        'data.rawEndpoint.fetchSyncRooms.modelType1.data.sync_not_enabled',
      ) ?? [];

    const syncEnabled =
      getValue(
        response,
        'data.rawEndpoint.fetchSyncRooms.modelType1.data.sync_enabled',
      ) ?? [];
    const multiInventoryRooms =
      getValue(
        response,
        'data.rawEndpoint.fetchSyncRooms.modelType1.data.multi_inventory_rooms',
      ) ?? [];

    return {
      calSyncEnabledRoomList: syncEnabled,
      calSyncNotEnabledRoomList: syncNotEnabled,
      calSyncMultiRoomList: multiInventoryRooms,
    };
  });
}

export function getFeatureFlags(mmt_id: string) {
  const query = GET_FEATURE_FLAGS;
  const variables = {
    CommonFeatureFlags: {
      featureFlags: {
        endPointId: 1,
        modelType: 'modelType1',
        body: {
          hotelId: mmt_id,
        },
      },
    },
  };
  return doMutation<{ newContentScoreFlag: boolean }>(query, variables, {
    useLoader: false,
  })
    .then(response => {
      const newContentScoreFlag =
        getValue(
          response,
          'data.featureFlags.featureFlags.modelType1.data.new_content_score',
        ) ?? false;

      return { newContentScoreFlag };
    })
    .catch(() => {
      return { newContentScoreFlag: false };
    });
}

export const getResellerAgreement = (hotelCode, { showLoader = true } = {}) => {
  const variables = {
    rawEndpointRequestData: {
      hotelAgreementsV3: {
        endPointId: 99,
        modelType: 'modelType1',
        urlParams: {
          hotelCode,
        },
      },
    },
  };
  return new Promise((resolve, reject) => {
    doQuery(getResellerAgreementQuery, variables, { useLoader: showLoader })
      .then(res => {
        const { data, error } = res;
        if (!error) {
          const agreements =
            getValue(
              data,
              'rawEndpoint.hotelAgreementsV3.modelType1.agreement_list',
            ) || [];

          const resellerAgreement = agreements.find(
            agreement => agreement.agreement_id === RESELLER_AGREEMENT_ID,
          );
          resolve(resellerAgreement);
        } else {
          resolve(null);
        }
      })
      .catch(reject);
  });
};

export const fetchCompsetData = hotelCode => {
  const variables = {
    rawEndpointRequestData: {
      analyticsFetchCompset: {
        endPointId: 99,
        modelType: 'modelType1',
        urlParams: {
          hotelcode: hotelCode,
        },
      },
    },
  };
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await doQuery(fetchCompsetDataQuery, variables, {
        useLoader: false,
      });
      const { data, status, status_code } =
        getValue(resp, 'data.rawEndpoint.analyticsFetchCompset.modelType1') ??
        [];

      if (status === 'success' || status_code === 404) {
        resolve(data);
      } else {
        reject(resp.error);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const getAllSalesMetaData = ({
  mmt_id,
  country,
}): Promise<Array<SaleCampaignMetaData>> => {
  //meta data only contains images, campaign-id, end-date
  const generateAllSalesVariables = mmtId => {
    return {
      endpointRequestData: {
        rpcGetCampaignOfferDetails: {
          getCampaignEntityRequest: {
            modelType: 'modelType1',
            body: {
              hotelId: mmtId,
              campaignType: campaignTypesNumMap[campaignTypes.sale], // for extranet always SALE
              campaignStatus: campaignStatusNumMap[campaignStatus.active],
              participationStatus:
                participationStatusNumMap[participationStatus.pending],
            },
          },
        },
      },
    };
  };
  return new Promise((resolve, reject) => {
    doQuery(GET_ALL_SALES_QUERY, generateAllSalesVariables(mmt_id), {
      useLoader: false,
      headers: {
        country,
        language: 'en',
        platform: 'Desktop',
        source: 'ingo_web',
      },
      showError: false,
    })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        const { errors } = res;

        //Network Error
        if (errors && errors?.message?.length !== 0) {
          reject({}); //rejecting a truthy value
        }

        const data: SaleResponseData = getValue(
          res,
          'data.rpcGetCampaignOfferDetails.data',
          null,
        );
        const internalError: { errorCode: string; errorMessage: string } =
          getValue(res, 'data.rpcGetCampaignOfferDetails.errorDetail', null);

        //API Fail
        if (internalError && internalError?.errorMessage?.length !== 0) {
          reject({});
        }

        const allCampaignsMeta = data.campaignHotelMappings;

        const metaDataArray: Array<SaleCampaignMetaData> = [];
        allCampaignsMeta.forEach(item => {
          if (
            item.participationStatus === participationStatus.pending &&
            item.campaign.campaignStatus === campaignStatus.active
          ) {
            // if sale is active then only display
            const metaObject = saleCampaignMetaObject(item);
            metaDataArray.push(metaObject);
          }
        });

        if (metaDataArray.length === 0) {
          resolve(null);
        }

        resolve(metaDataArray);
      })
      .catch(e => {
        reject(e);
      });
  });
};

export function decideShowDashboardTermsAndConditionsBanner(hotelcode: string) {
  const variables = {
    endpointRequests: {
      decideHotelAgreementPopup: {
        modelType: 'modelType1',
        urlParams: {
          hotelcode,
        },
      },
    },
  };
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async resolve => {
    try {
      const resp = await doQuery(getDecideHotelAgreementPopupQuery, variables, {
        useLoader: false,
      });
      const data =
        getValue(
          resp,
          'data.rawEndpoint.decideHotelAgreementPopup.modelType1',
        ) ?? {};

      if (data) {
        data.agreement_list = data?.agreement_list?.filter(
          item =>
            item.agreement_id !== 305 &&
            item.agreement_id !== 310 &&
            item.agreement_id !== 309 &&
            item.agreement_id !== 311,
        );
        if (data?.agreement_list?.length === 0) {
          data.showpopup = false;
        }
        resolve(data);
      } else {
        resolve(null);
      }
    } catch (error) {
      console.error('Error:', error);
      resolve(null);
    }
  });
}

export const getRoomRateplans = (
  hotelCode: string,
  options: { useLoader: boolean } = { useLoader: true },
) => {
  const variables = {
    inventoryGetRoomsRateplansRequestData: {
      externalCalendarSyncGetRoomsRatePlansAny: {
        endPointId: 1,
        modelType: 'modelType1',
        queryParams: {
          hid: hotelCode,
        },
      },
    },
  };

  // eslint-disable-next-line no-async-promise-executor
  return new Promise<RoomRateplan>(async (resolve, reject) => {
    try {
      const resp = await doQuery(getRoomsRatePlansDetailsQuery, variables, {
        ...options,
      });
      const response =
        getValue(
          resp,
          'data.externalCalendarSyncGetRoomsRatePlansAny.externalCalendarSyncGetRoomsRatePlansAny.modelType1',
        ) ?? {};
      const { success, data } = response;
      if (success) {
        const { roomDetails, rateplanDetails } = data;
        const hourlyRooms = [];
        const nonHourlyRooms = [];
        roomDetails.forEach(room => {
          if (room.is_slot_room) {
            hourlyRooms.push(room);
          } else {
            nonHourlyRooms.push(room);
          }
        });

        const hourlyRpDetails = pick(
          rateplanDetails,
          hourlyRooms.map(item => item.roomtypecode),
        );
        const nonHourlyAndGrpRpDetails = pick(
          rateplanDetails,
          nonHourlyRooms.map(item => item.roomtypecode),
        );

        const _nonHourlyAndGrpRpDetails = {};
        const grpRateplans = {};

        Object.keys(nonHourlyAndGrpRpDetails).forEach(roomCode => {
          _nonHourlyAndGrpRpDetails[roomCode] = [];
          const roomRateplans = rateplanDetails[roomCode];
          roomRateplans.forEach(rp => {
            const isGroupRp = isGroupRateplan(rp);
            if (isGroupRp) {
              if (grpRateplans[roomCode]?.length > 0) {
                grpRateplans[roomCode].push(rp);
              } else {
                grpRateplans[roomCode] = [rp];
              }
              return;
            }
            _nonHourlyAndGrpRpDetails[roomCode].push(rp);
          });
        });

        const {
          flatRateplans,
          parentRateplans,
          partnerIngoRPCodeMap,
          partnerRPCodeList,
          roomNameMap,
          rpGroupMapping,
          rpNameMap,
        } = roomRateMapObjects(nonHourlyRooms, _nonHourlyAndGrpRpDetails);
        resolve({
          roomDetails: nonHourlyRooms,
          hourlyStaysRoomDetails: hourlyRooms,
          rateplanDetails: _nonHourlyAndGrpRpDetails,
          hourlyStaysRateplanDetails: hourlyRpDetails,
          flatRateplans,
          roomNameMap,
          rpNameMap,
          parentRateplans,
          partnerRPCodeList: [...partnerRPCodeList],
          partnerIngoRPCodeMap,
          rpGroupMapping,
          grpRateplans,
        });
      } else {
        const fallback = {
          roomDetails: [],
          rateplanDetails: {},
          hourlyStaysRoomDetails: [],
          hourlyStaysRateplanDetails: {},
          flatRateplans: [],
          parentRateplans: {},
          partnerIngoRPCodeMap: {},
          partnerRPCodeList: [],
          roomNameMap: {},
          rpNameMap: {},
          rpGroupMapping: [],
          grpRateplans: {},
        };
        resolve(fallback);
      }
    } catch (error) {
      console.error('Error:', error);
      reject(null);
    }
  });
};
