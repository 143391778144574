import React, { useContext } from 'react';
import { Tooltip, Typography, Button } from 'connect-web-ui';
import * as SponsoredListingIcons from 'assets/modules/sponsored-listing';
import { Dashboard } from '../../../../utilities/TestIds';
import { isNullOrUndefined, navigateTo } from 'utilities/Utils';
import { HotelContext } from '../../../../utilities/Context';
import { useTouchPointsSL } from 'shared_logic';
import LineGraph from 'modules/sponsored-listing/listing/LineGraph';
import { useLangStrings } from 'utilities/CustomHooks';
import {
  pushSLEventToGTM,
  SL_CTA,
} from 'modules/sponsored-listing/SponsoredListingConstants';
import { SLLogo } from 'assets/common';
import { pushToOmniture } from 'utilities/gtm';

import './SLTouchPoints.scss';

export default function SLTouchPoints(props) {
  const { pageType, onSectionLoadUpdate, gtmLabel } = props;
  const [STRINGS] = useLangStrings('SponsoredListing');
  const {
    currentHotel: { hideSponsoredListing, isDomHotel },
  } = useContext(HotelContext);
  const { touchPoints } = useTouchPointsSL(
    pageType,
    onSectionLoadUpdate,
    gtmLabel,
  );

  const goToSponsoredListing = (
    cta,
    isGraph,
    persuasionContent,
    redirectText,
  ) => {
    const gaConfig = {
      isPersuasion: true,
      pageType: pageType,
      functionality: persuasionContent,
      omnitureData: {
        skip: true,
      },
    };
    if (redirectText) {
      const redirectTo = redirectText;
      const pattern = /^((http|https):\/\/)/;
      if (!pattern.test(redirectTo)) {
        pushSLEventToGTM(
          'sponsored_listing',
          `click|persuasion_${cta}_graph_${isGraph}`,
          gaConfig,
        );
        navigateTo(redirectTo);
      } else {
        window.open(redirectTo);
      }
    }
  };

  const onHandleClickOfSL = (cta, isGraph, persuasionContent, redirectText) => {
    pushToOmniture({
      event: 'ctaClick',
      cta: {
        name: 'create_campaign',
        type: 'click',
        componentName: 'Create Campaign',
      },
      pageName: 'dashboard',
    });
    goToSponsoredListing(cta, isGraph, persuasionContent, redirectText);
  };

  const getCtaLabel = cta => {
    switch (cta) {
      case SL_CTA.CREATE_CAMPAIGN:
        return STRINGS.SPONSORED_LISTING.CREATE_CAMPAIGN;
      case SL_CTA.VIEW_PERFORMANCE:
        return STRINGS.SPONSORED_LISTING.VIEW_PERFORMANCE;
      case SL_CTA.REFUND_DETAILS:
        return STRINGS.SPONSORED_LISTING.VIEW_REFUND_DETAILS;
      default:
        return '';
    }
  };

  if (hideSponsoredListing || isNullOrUndefined(touchPoints)) {
    return null;
  }

  return (
    <>
      {touchPoints?.length ? (
        <div>
          {touchPoints?.map(item => {
            const headerText = item?.persuasion?.headerText;
            const revenueText = item?.revenueGraph?.text;
            const toolTipData = item?.revenueGraph?.toolTipData;
            const graphData = item?.revenueGraph;
            const isGraph = !isNullOrUndefined(graphData);
            const persuasionContent = item?.persuasion?.htmlContent;
            const cta = item?.persuasion?.cta?.action;
            const redirectText = item?.persuasion?.cta?.redirectTxt;
            return (
              <div
                className="sr-panel SL-TouchPointsWrapper"
                data-test-id={Dashboard.sponsoredListing}
              >
                <div className="padB15">
                  <div className="sr-panel-heading d-flex sr-align-center">
                    <img
                      src={SLLogo}
                      alt="sponsored listing logo"
                      className="marginR8"
                    />

                    <h3 className="font16 margin0">
                      {STRINGS.SPONSORED_LISTING.SL_TITLE}
                    </h3>
                  </div>
                  <div>
                    {headerText && (
                      <p className="font18 fmed black padLR15 padT15 margin0">
                        {headerText}
                      </p>
                    )}

                    {!isNullOrUndefined(item?.revenueGraph) && (
                      <div className="pad10 marginT15">
                        <div className="d-flex sr-align-center">
                          <span className="font16 text-gray fb marginB25">
                            {revenueText}
                          </span>
                          <span className="d-flex sr-align-center marginL8 marginB25">
                            <Tooltip content={toolTipData} placement="bottom">
                              <img
                                style={{ marginLeft: '-2px' }}
                                alt=""
                                src={
                                  !isNullOrUndefined(toolTipData)
                                    ? SponsoredListingIcons.ToolTipIcon
                                    : null
                                }
                              />
                            </Tooltip>
                          </span>
                        </div>

                        <LineGraph
                          graphData={graphData}
                          fullWidth={true}
                          isPageType={true}
                        />
                      </div>
                    )}

                    {persuasionContent !== '' ? (
                      <div
                        className="padLR15 padTB15 font14 black fn lh-22"
                        dangerouslySetInnerHTML={{
                          __html: persuasionContent,
                        }}
                      />
                    ) : null}

                    {!isDomHotel && cta === SL_CTA.CREATE_CAMPAIGN ? (
                      <Typography
                        variant="body1"
                        className="mt-2 !font-bold px-[15px]"
                      >
                        {STRINGS.SPONSORED_LISTING.CONTACT_BD_MANAGER}
                      </Typography>
                    ) : (
                      <Button
                        onClick={() =>
                          onHandleClickOfSL(
                            cta,
                            isGraph,
                            persuasionContent,
                            redirectText,
                          )
                        }
                        className="marginLR15"
                      >
                        {getCtaLabel(cta)}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
}
