import axios, { apiWrapper } from '../../base/APIClient';
import { BOOKING_REVAMP } from '../../base/Url';
import { showError } from 'base/APIClient';

export const raiseRequestReview = (
  uid: string,
  brand: string,
  voyagerId: string,
) => {
  const { REQUEST_REVIEW } = BOOKING_REVAMP;
  const body = new URLSearchParams();
  body.append('client', brand.toLowerCase());
  body.append('voyagerid', voyagerId);
  return apiWrapper(axios.post(REQUEST_REVIEW(uid), body, { useLoader: true }));
};

export const downloadWavierDocument = doc => {
  const { DOWNLOAD_WAVIER_DOCUMENT: url } = BOOKING_REVAMP;

  return new Promise((resolve, reject) => {
    const data = { image_keys: [doc] };
    apiWrapper(axios.post(url, data, { useLoader: true }))
      .then((res: { message: string; success: boolean }) => {
        const { message, success } = res;
        if (success) {
          const docURL = message[0][doc];
          resolve(docURL);
        } else {
          reject();
        }
      })
      .catch(reject);
  });
};

export const verifyUserAndCreditCardPassword = payload => {
  const { CHECK_CC_PASSWORD } = BOOKING_REVAMP;
  return new Promise((resolve, reject) => {
    return apiWrapper(
      axios.post(CHECK_CC_PASSWORD, payload, { useLoader: true }),
    )
      .then(data => {
        if (data) {
          resolve(data);
        } else {
          showError(data);
        }
      })
      .catch(err => {
        const data = err.response?.data;
        showError(data);
        reject(data);
      });
  });
};

export const validateVCCPassword = (hotelCode, data) => {
  const { VALIDATE_VCC_PASSWORD } = BOOKING_REVAMP;
  const url = VALIDATE_VCC_PASSWORD(hotelCode);
  return apiWrapper(axios.post(url, data, { useLoader: true }));
};

export const downloadBookings = (
  hotelCode: string,
  bookingIds: string[],
  parentVendorIds: string[],
) => {
  const url = BOOKING_REVAMP.DOWNLOAD_BOOKINGS;
  const body = {
    toDate: '04/29/2022',
    hotelcode: hotelCode,
    fromDate: '04/29/2022',
    booking_id_list: bookingIds,
    vendor_parent_booking_ids: parentVendorIds,
  };
  return axios.post(url, body, { useLoader: true });
};
