import {
  RESELLER_ONBOARDING_REDIRECTED_FROM_STORAGE_KEY,
  RESELLER_ONBOARDING_REDIRECTED_FROM_STORAGE_VALUE,
} from '../../../constants';
import { useRef, useEffect } from 'react';

const useResellerOnboardingEffect = ({
  tabsInfo,
  resetTabs,
  isFetchingHotelMetadata,
}) => {
  const isTabsReset = useRef(null);

  useEffect(() => {
    if (
      sessionStorage.getItem(
        RESELLER_ONBOARDING_REDIRECTED_FROM_STORAGE_KEY,
      ) === RESELLER_ONBOARDING_REDIRECTED_FROM_STORAGE_VALUE &&
      !isFetchingHotelMetadata
    ) {
      resetTabs();
      isTabsReset.current = true;
    }
  }, [isFetchingHotelMetadata]);

  useEffect(() => {
    if (
      sessionStorage.getItem(
        RESELLER_ONBOARDING_REDIRECTED_FROM_STORAGE_KEY,
      ) === RESELLER_ONBOARDING_REDIRECTED_FROM_STORAGE_VALUE &&
      isTabsReset.current
    ) {
      sessionStorage.removeItem(
        RESELLER_ONBOARDING_REDIRECTED_FROM_STORAGE_KEY,
      );
      isTabsReset.current = false;
    }
  }, [tabsInfo]);
};

export default useResellerOnboardingEffect;
