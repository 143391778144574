import React, { Fragment, useState, useEffect, useContext } from 'react';
import { fetchVDItoShowCard } from '../utils/DashboardAPIClient';
import { AppEvents } from '../../../utilities/EventBus';
import { Button } from 'connect-web-ui';
import { navigateTo } from 'utilities/Utils';
import { useLangStrings } from '../../../utilities/CustomHooks';
import { routes } from '../../../app/routes';

import { legacyPushToGTM, GTM_CATEGORIES } from 'utilities/gtm';
import { AppContext } from 'utilities/Context';

function VDICard(props) {
  const [STRINGS, COMMON_STRINGS] = useLangStrings('Dashboard');
  const defaultState = {
    showVDICard: false,
  };
  const { hotel, onSectionLoadUpdate, gtmLabel } = props;
  const { isStaff } = useContext(AppContext);
  const [cardState, updateCardState] = useState(defaultState);

  const pushGAevent = (category, action, label) => {
    legacyPushToGTM({
      eventCategory: category,
      eventAction: action,
      eventLabel: label || '',
    });
  };

  const handleClick = () => {
    if (!isStaff) {
      navigateTo(routes.businessBooster.performance);
      AppEvents.emit('from-vdi-card');
      pushGAevent(GTM_CATEGORIES.DASHBOARD, 'vdi_activate_now');
    }
  };

  const fetchVDIData = hotelCode => {
    fetchVDItoShowCard(hotelCode)
      .then(resp => {
        const data = resp.data;
        updateCardState({
          showVDICard: data.can_show,
        });
        onSectionLoadUpdate(data.can_show, gtmLabel);
      })
      .catch(error => {
        console.error('Error fetching VDI data', error);
      });
  };

  useEffect(() => {
    updateCardState({
      showVDICard: false,
    });
    fetchVDIData(hotel.hotelId);
  }, [hotel.hotelId]);

  return (
    <Fragment>
      {cardState.showVDICard && (
        <div className="sr-panel">
          <div className="sr-panel-heading">
            {STRINGS.VDI.VDI_SHORTHAND}
            <span className="sr-label sr-label-success marginL5">
              {COMMON_STRINGS.LABELS.NEW_BADGE}
            </span>
          </div>
          <div className="sr-panel-body">
            <h4 className="black-ltr">
              {STRINGS.BOOST_PERFORMANCE.BOOST_PERFORMANCE_TITLE}
            </h4>
            <p>{STRINGS.VDI.AGREE_ON_MONTHLY_TARGETS}</p>
            <Button onClick={handleClick}>
              {STRINGS.VDI.ACTIVATE_VDI_BTN}
            </Button>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default VDICard;
