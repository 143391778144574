import React from 'react';

export const DefaultMessageSection = ({ message, action = () => {} }) => {
  return (
    <div
      className="full-size-centered no-data-msg"
      style={{ height: '200px' }}
      onClick={action}
    >
      {message}
    </div>
  );
};
