import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  MutateOptions,
  QueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import {
  activateUserAccount,
  initiatePasswordReset,
  loginUser,
  registerUser,
} from './landingPageApiClient';
import {
  broadcastLoginInfoToOtherTabs,
  getParsedErrorMessageFromAPI,
  navigateTo,
  setCookie,
  showMessage,
} from 'utilities/Utils';
import { AuthContext } from 'utilities/Context';
import { SSO_ONE_LOGIN_URL } from 'app/login/login.constant';
import {
  ActivateUserRequest,
  ActivateUserResponse,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  LoginUserRequest,
  LoginUserResponse,
  RegisterUserRequest,
  RegisterUserResponse,
  VerifyUserEmailErrorResponse,
  VerifyUserEmailRequest,
  VerifyUserEmailResponse,
} from './api';
import { appDocAccessCookieKey, COOKIE_EXPIRE_TIME } from '../../../constants';
import { verifyUserEmail } from './landingPageGraphqlClient';
import useStrings from '../hooks/useStrings';
import {
  INCORRECT_CREDS_ERROR_CODE,
  COMMON_OMNITURE_VARIABLES,
} from '../constants';
import { OMNITURE_CATEGORIES, pushToOmniture } from 'utilities/gtm';

export const useVerifyUserEmail = (
  { email }: VerifyUserEmailRequest,
  options?: QueryOptions<VerifyUserEmailResponse, VerifyUserEmailErrorResponse>,
) => {
  return useQuery({
    queryKey: ['verifyUserEmail'],
    queryFn: () => verifyUserEmail({ email }),
    retry: false,
    enabled: false,
    throwOnError: false,
    ...options,
  });
};

export const useLoginUser = (
  options?: MutateOptions<
  LoginUserResponse,
  LoginUserResponse,
  LoginUserRequest
  >,
) => {
  const [, COMMON] = useStrings();
  const { setApiToken } = useContext(AuthContext);
  const {
    location: { state },
  } = useHistory();

  const { from = '/' } = state || {};

  return useMutation({
    mutationKey: ['loginUser'],
    mutationFn: loginUser,
    onSuccess: async ({ response }) => {
      const apiToken = response?.apiToken;
      const isStaff = response?.internalRequest;

      if (isStaff) {
        window.location.href = SSO_ONE_LOGIN_URL;
        return;
      }

      setApiToken(apiToken);
      setCookie(appDocAccessCookieKey, apiToken, COOKIE_EXPIRE_TIME);
      broadcastLoginInfoToOtherTabs('login');

      if (apiToken) {
        //redirecting back to previously accessed url after login
        navigateTo(from);
      }

      pushToOmniture({
        ...COMMON_OMNITURE_VARIABLES,
        event: OMNITURE_CATEGORIES.CTA_CLICK,
        cta: {
          name: 'sign_in_success',
          type: 'click',
          componentName: 'log_in_account',
        },
      });
    },
    onError: error => {
      const errorCode = error?.response?.errorDetail?.errorCode;

      pushToOmniture({
        ...COMMON_OMNITURE_VARIABLES,
        event: OMNITURE_CATEGORIES.CTA_CLICK,
        loadedComponents:
          getParsedErrorMessageFromAPI(error?.response) ??
          COMMON.MESSAGES.SOMETHING_WENT_WRONG,
        cta: {
          name: 'sign_in_failure',
          type: 'click',
          componentName: 'log_in_account',
        },
      });

      if (errorCode && errorCode !== INCORRECT_CREDS_ERROR_CODE) {
        showMessage({
          type: 'error',
          show: true,
          commonMessage: COMMON.MESSAGES.SOMETHING_WENT_WRONG,
          message: error?.response,
        });
      }
    },
    ...options,
  });
};

export const useRegisterUser = (
  options?: MutateOptions<
  RegisterUserResponse,
  RegisterUserResponse,
  RegisterUserRequest
  >,
) => {
  const [, COMMON] = useStrings();

  return useMutation({
    mutationKey: ['registerUser'],
    mutationFn: registerUser,
    onSuccess: () => {
      pushToOmniture({
        ...COMMON_OMNITURE_VARIABLES,
        event: OMNITURE_CATEGORIES.CTA_CLICK,
        cta: {
          name: 'continue_success',
          type: 'click',
          componentName: 'create_account',
        },
      });
    },
    onError: error => {
      showMessage({
        type: 'error',
        show: true,
        commonMessage: COMMON.MESSAGES.SOMETHING_WENT_WRONG,
        message: error.response,
      });

      pushToOmniture({
        ...COMMON_OMNITURE_VARIABLES,
        event: OMNITURE_CATEGORIES.CTA_CLICK,
        loadedComponents:
          getParsedErrorMessageFromAPI(error?.response) ??
          COMMON.MESSAGES.SOMETHING_WENT_WRONG,
        cta: {
          name: 'continue_failure',
          type: 'click',
          componentName: 'create_account',
        },
      });
    },
    ...options,
  });
};

export const useForgotPassword = (
  options?: MutateOptions<
  ForgotPasswordResponse,
  ForgotPasswordResponse,
  ForgotPasswordRequest
  >,
) => {
  const [, COMMON] = useStrings();

  return useMutation({
    mutationKey: ['forgotPassword'],
    mutationFn: initiatePasswordReset,
    onSuccess: async ({ response }) => {
      const isStaff = response?.internalRequest;

      if (isStaff) {
        window.location.href = SSO_ONE_LOGIN_URL;
        return;
      }

      pushToOmniture({
        ...COMMON_OMNITURE_VARIABLES,
        event: OMNITURE_CATEGORIES.CTA_CLICK,
        cta: {
          name: 'link_sent_success',
          type: 'click',
          componentName: 'forgot_password',
        },
      });
    },
    onError: error => {
      pushToOmniture({
        ...COMMON_OMNITURE_VARIABLES,
        event: OMNITURE_CATEGORIES.CTA_CLICK,
        loadedComponents:
          getParsedErrorMessageFromAPI(error?.response) ??
          COMMON.MESSAGES.SOMETHING_WENT_WRONG,
        cta: {
          name: 'link_sent_failure',
          type: 'click',
          componentName: 'forgot_password',
        },
      });

      showMessage({
        type: 'error',
        show: true,
        commonMessage: COMMON.MESSAGES.SOMETHING_WENT_WRONG,
        message: error.response,
      });
    },
    ...options,
  });
};

export const useActivateUser = (
  options?: MutateOptions<
  ActivateUserResponse,
  ActivateUserResponse,
  ActivateUserRequest
  >,
) => {
  const [, COMMON] = useStrings();

  return useMutation({
    mutationKey: ['activateUser'],
    mutationFn: activateUserAccount,
    onError: async error => {
      showMessage({
        type: 'error',
        show: true,
        commonMessage: COMMON.MESSAGES.SOMETHING_WENT_WRONG,
        message: error.response,
      });
    },
    ...options,
  });
};
