import { showError } from 'base/APIClient';
import { useFormContext } from 'react-hook-form';
import { ApiAction, ClientSideAction, NavigationAction } from 'bff';
import apiWrapper, { bffDynamicOrigin } from 'bff/APIClient';
import { pushToOmnitureBFF, showBffMessage } from 'bff/utils';
import { ApiResponseType } from 'shared_logic/new-cancellation-policy/types';
import { getURLWithParams, goBack, navigateTo } from 'utilities/Utils';
import { useCallback, useContext } from 'react';
import { OtherProps } from 'bff/types/types';
import { HotelContext } from 'utilities/Context';

export const useClientSideAction = () => {
  const {
    currentHotel: { showListings, isEntireFlow },
  } = useContext(HotelContext) || { currentHotel: {} };
  const { handleSubmit } = useFormContext() || {};

  const handleAPIAction = useCallback(
    async (action: ApiAction, values: Object, otherProps?: OtherProps) => {
      const additionalData = action?.additionalData || {};
      const url = action.url;
      let payload: Object = {};
      if (otherProps?.createPayload) {
        payload = otherProps.createPayload?.(values, additionalData);
      } else {
        payload = { ...additionalData, ...values };
      }

      try {
        const response = (await apiWrapper({
          url: `${bffDynamicOrigin}${url}`,
          method: action?.method ?? 'GET',
          data: payload,
          useLoader: action?.method !== 'GET',
        })) as ApiResponseType;

        if (response.success) {
          showBffMessage(response.display);
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          await handleClientSideAction(action?.onSuccess);
        }
      } catch (e) {
        if (e?.success === false && e?.display) {
          showBffMessage(e.display);
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          await handleClientSideAction(action?.onFailure);
        } else {
          showError('Something went wrong');
        }
      }
    },
    [],
  );

  const handleNavigation = useCallback((action: NavigationAction) => {
    if (action?.url === 'GO_BACK') {
      goBack();
    } else {
      let url = getURLWithParams(action.url, action?.navParams || {});
      if (showListings) {
        url = url.replace('/property/rules-policies', '/listing');
        if (url === '/property/rooms' && isEntireFlow) {
          url = 'listing?view=rateplans';
        } else {
          url = url.replace('/property/rooms', '/listing/spaces');
        }
      }
      if (url === '/rate-plan') {
        if (showListings) {
          if (isEntireFlow) {
            url = 'listing?view=rateplans';
          } else {
            url = '/listing/spaces';
          }
        } else {
          url = '/property/rooms';
        }
      }
      navigateTo(url);
    }
  }, []);

  const handleClientSideAction = useCallback(
    (
      action: ClientSideAction,
      customFunc: (action: ClientSideAction) => void = () => {},
      otherProps?: OtherProps,
    ) => {
      pushToOmnitureBFF(action?.trackingData);
      switch (action?.type) {
        case 'API_CALL':
          return handleSubmit(values =>
            handleAPIAction(action, values, otherProps),
          )();
        case 'NAVIGATION':
          return handleNavigation(action);
        case 'CUSTOM':
          return customFunc(action);
        default:
      }
    },
    [handleAPIAction, handleNavigation, handleSubmit],
  );

  return {
    handleClientSideAction,
    handleAPIAction,
  };
};
