import React from 'react';
import ContainerShimmer from './ContainerShimmer';
import { HeaderShimmer } from './HeaderShimmer';
import { LeftSectionShimmer } from './LeftSectionShimmer';

const GlobalShimmer = () => {
  return (
    <div className="navigation-container ">
      <div className={'navigation-header'}>
        <HeaderShimmer />
      </div>
      <div className="nav-container max-h-[calc(100vh-72px)] overflow-clip scrollbar-thin">
        <LeftSectionShimmer />
        <ContainerShimmer hasLeftNav />
      </div>
    </div>
  );
};

export default GlobalShimmer;
