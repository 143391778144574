import styled from 'styled-components';
import { colors, typography } from 'style/zeplin-token-styledComponents';

export const CreateAccountCont = styled.div``;

export const CreateAccountHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.div`
  width: 32px;
  flex-shrink: 0;
  margin-right: 16px;
  display: flex;
`;

export const HeaderRight = styled.div`
  flex: 1;
  p {
    ${typography.titleBold};
    color: ${colors.grayMediumEmphasis};
  }
`;

export const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  margin-top: 16px;
`;

export const ButtonWrapper = styled.div``;

export const TncWrapper = styled.p`
  margin-top: 24px;
  text-align: center;
  ${typography.microTextRegular};
  a {
    color: ${colors.linkBlueDefault};
  }
`;

export const PasswordHintWrapper = styled.div`
  display: flex;
  align-items: center;
  ${typography.microTextRegular};
  margin-top: 4px;
  gap: 20px;
`;

export const Success = styled.span`
  display: flex;
  align-items: center;
  color: ${colors.warningDefault};
`;

export const Error = styled.span`
  display: flex;
  align-items: center;
  color: ${colors.errorDefault};
`;

export const CheckInboxWrapper = styled.div`
  text-align: center;
  padding: 0px 19px;
`;

export const CheckInboxHeading = styled.p`
  ${typography.subheadingBlack};
  color: ${colors.grayHighEmphasis};
  margin-top: 20px !important;
`;

export const CheckInboxDesc = styled.p`
  ${typography.bodyTextBaseBold};
  color: ${colors.grayMediumEmphasis};
  margin-top: 16px !important;
`;

export const CheckInboxNote = styled.p`
  ${typography.bodyTextBaseBold};
  color: ${colors.grayHighEmphasis};
  margin-top: 16px !important;
  padding-bottom: 20px;
  span {
    ${typography.bodyTextBaseRegular};
  }
`;

export const FormFieldHint = styled.p`
  ${typography.subTextRegular};
  color: ${colors.grayMediumEmphasis};
`;
