import React, { cloneElement } from 'react';
import { TrackingConfig } from './SideNav.types';

export enum EVENT_NAME {
  click = 'onClick',
  mouseEnter = 'onMouseEnter',
}

type IOmnitureEventWrapper = {
  trackingConfig: TrackingConfig;
  addDataId?: boolean;
  dataTestId?: string;
  eventName?: EVENT_NAME;
  children: React.ReactElement;
};

export default function SideNavDataIdWrapper({
  children,
  addDataId = true,
  dataTestId,
  trackingConfig,
}: IOmnitureEventWrapper) {
  const generateDataId = () => {
    return `${trackingConfig?.ctaName}-${trackingConfig?.componentName}`;
  };

  return cloneElement(children, {
    ...(addDataId
      ? {
        'data-test-id': dataTestId ?? (generateDataId() as React.Attributes),
      }
      : {}),
  });
}
