import { colors, typography } from 'style/zeplin-token-styledComponents';
import styled, { css } from 'styled-components';
import ORANGE_TICK from 'assets/modules/landing/orange_tick.svg';

export const Wrapper = styled.section`
  background: ${colors.grayScreen};
`;

export const InnerWrapper = styled.div`
  padding: 42px 0px 42px 38px;
`;

export const BodyContainer = styled.div`
  margin-top: 22px;
  display: flex;
  justify-content: space-between;
`;

export const LeftSection = styled.div`
  flex: 1;
  margin-right: 41px;
`;

export const ListItem = styled.div<{ active: boolean }>`
  padding: ${({ active }) =>
    active ? '22px 14px 22px 22px' : '16px 14px 16px 22px'};
  border-bottom: 1px solid ${colors.grayDisabled};
  position: relative;

  &:last-child::after {
    display: none;
  }

  &::after {
    width: 1px;
    height: 90%;
    background-color: ${colors.grayDisabled};
    top: 30px;
    left: -25px;
    content: '';
    position: absolute;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${colors.white};
      border-top: 2px solid ${colors.orange};
      border-bottom: 0;
      margin-top: -1px;

      &::after {
        width: 2px;
        background-color: ${colors.orange};
        left: -25.5px;
      }
    `}
`;

export const Heading = styled.p<{ active: boolean }>`
  position: relative;
  cursor: pointer;
  padding-right: 30px;
  ${({ active }) =>
    active ? typography.bodyTextBaseBold : typography.bodyTextBaseRegular};
  color: ${colors.grayMediumEmphasis};

  &::after {
    content: '';
    display: ${({ active }) => (active ? 'none' : 'inline-block')};
    border: solid ${colors.grayMediumEmphasis};
    border-width: 0 1.25px 1.25px 0;
    padding: 3.5px;
    transform: rotate(45deg);
    position: absolute;
    right: 0;
    top: 5px;
  }
`;

export const SubHeading = styled.p`
  margin-top: 13px !important;
  padding-right: 30px;
  ${typography.subTextRegular};
  color: ${colors.grayMediumEmphasis};
`;

export const RightSection = styled.div`
  width: 595px;
  flex-shrink: 0;
  align-items: center;
  display: flex;
`;

export const FeatureIcon = styled.span<{ active: boolean }>`
  width: 19px;
  height: 19px;
  display: inline-flex;
  position: absolute;
  background-color: ${colors.grayScreen};
  border: 1px solid ${colors.grayDisabled};
  border-radius: 50%;
  left: -34px;
  top: ${({ active }) => (active ? '22px' : '16px')};
  align-items: center;
  justify-content: center;
  z-index: 1;

  &::before {
    width: 10px;
    height: 10px;
    background-color: ${colors.orange};
    border-radius: 50%;
    content: '';
  }

  &::after {
    border: 3px solid ${colors.grayScreen};
    width: 25px;
    height: 25px;
    border-radius: 50%;
    position: absolute;
    top: -4px;
    left: -4px;
    content: '';
    border-radius: 50%;
  }

  ${({ active }) =>
    active &&
    css`
      background: ${colors.grayScreen} url(${ORANGE_TICK}) no-repeat center
        center;
      background-size: 19px 19px;
      border: 0;

      &::before {
        display: none;
      }

      &::after {
        width: 23px;
        height: 23px;
        top: -2px;
        left: -2px;
      }
    `}
`;
