export const LISTING_TYPE_MAPPING = {
  entire: 'Entire',
  room: 'Room by Room',
};

export const HOTEL = 'Hotel';

export enum STEP_STATE {
  'NO_EDIT' = 'NO_EDIT',
  'MINOR_EDIT' = 'MINOR_EDIT',
}

export const EDIT_TYPE_MAPPING = [
  {
    label: 'No Inputs Required',
    state: STEP_STATE.NO_EDIT,
  },
  {
    label: 'Minimal Inputs required',
    state: STEP_STATE.MINOR_EDIT,
  },
];

export const ONBOARING_TABS: Array<{
  label: string;
  state: STEP_STATE;
}> = [
  { label: 'Basic Information', state: STEP_STATE.NO_EDIT },
  { label: 'Location', state: STEP_STATE.NO_EDIT },
  { label: 'Rooms & Spaces', state: STEP_STATE.MINOR_EDIT },
  { label: 'Photos', state: STEP_STATE.MINOR_EDIT },
  { label: 'Amenities', state: STEP_STATE.NO_EDIT },
  { label: 'Pricing & Availability', state: STEP_STATE.MINOR_EDIT },
  { label: 'Policies', state: STEP_STATE.NO_EDIT },
  { label: 'Finance & Legal', state: STEP_STATE.NO_EDIT },
];

export const HOTEL_ID = 'hotel_id';

export const ENTIRE = 'ENTIRE';
export const ROOM = 'ROOM';
export const MIN_ITEMS_FOR_SEARCH_BAR = 5;
export const FINANCE_AND_LEGAL_DUPLICATION_COMPLETED =
  'FINANCE_AND_LEGAL_DUPLICATION_COMPLETED';
export const TnC_LINK =
  'https://gos3.ibcdn.com/31a3453e854c11ed88d60a58a9feac02.pdf';

export const PROPERTY_TYPE_MAPPING = {
  ENTIRE: 'Entire Property',
  ROOM: 'Private Room',
  TYPE: 'entire',
};

export const IMPORTANT_KEYS = ['bathroom', 'bedroom', 'kitchen'];
