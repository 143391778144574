import { fetchCombinedInternalCalSyncData } from 'modules/inventory/cal-sync/CalSyncGraphClient';
import {
  fetchCompsetData,
  getAllSalesMetaData,
  getFeatureFlags,
  getResellerAgreement,
  syncEnabledRoomsAPI,
} from '../HotelGraphClient';
import { APIS } from '../constants';
import {
  ensureHotelAgrementAndTaxData,
  ensureRemainingLegacyAndCalenderSyncData,
} from '../legacyParity';

export const API_MAPPER = {
  [APIS.CALSYNC_DATA]: currHotel =>
    syncEnabledRoomsAPI(currHotel.hotelcode, { useLoader: false }),
  [APIS.LEGACY_PARITY_PART_1]: currHotel =>
    ensureHotelAgrementAndTaxData(currHotel),
  [APIS.LEGACY_PARITY_PART_2]: currHotel =>
    ensureRemainingLegacyAndCalenderSyncData(currHotel),
  [APIS.COMPSET_DATA]: hotelcode => fetchCompsetData(hotelcode),
  [APIS.SALE_CAMPAIGNS]: args => getAllSalesMetaData(args),
  [APIS.RESELLER_AGREEMENT]: currHotel =>
    getResellerAgreement(currHotel.hotelcode, { showLoader: false }),
  [APIS.COMPSET_DATA]: hotelcode => fetchCompsetData(hotelcode),
  [APIS.FEATURE_FLAGS]: mmtId => getFeatureFlags(mmtId),
  [APIS.ICS_COMBINED_DATA]: hotelcode =>
    fetchCombinedInternalCalSyncData(hotelcode),
};
