import {
  AltAccoEntirePlaceholderIcon,
  HotelEntirePlaceholderIcon,
} from 'assets/modules/navigation';
import { PropertyData } from 'group-property/modules/group-dashboard/GroupDashboard.types';
import { TOKEN_STATUS } from './property-vibe/constants';
import { navigateTo } from 'utilities/Utils';
import { routes } from 'app/routes';
import { baseUrl } from '../constants';

export const checkIsHomeStay = (property: PropertyData) => {
  let isHomeStay;
  if ('propertyDetails' in property) {
    isHomeStay = property.propertyDetails.propertyType === 'Homestay';
  } else if ('listingMetaData' in property) {
    isHomeStay = property.listingMetaData.isHomeStayFlow;
  }
  return isHomeStay;
};

export const getThumbnailUrl = (url, isHomeStay: boolean) => {
  if (url) {
    return url;
  }

  return isHomeStay ? AltAccoEntirePlaceholderIcon : HotelEntirePlaceholderIcon;
};

export const getInstagramStatus = tokenStatus => {
  let status = 'connect_with_insta';
  if (tokenStatus === TOKEN_STATUS.AUTHORISED) {
    status = 'connected_with_insta';
  } else if (tokenStatus === TOKEN_STATUS.EXPIRED) {
    status = 'reauthenticate_with_insta';
  } else if (tokenStatus === TOKEN_STATUS.BLOCKED) {
    status = 'insta_blocked';
  }
  return status;
};

const blackEnrollPageUrl = routes.businessBooster.blackGoTribe.enroll;
export const navigateToBlackEnrollPage = () => {
  navigateTo(blackEnrollPageUrl);
};

export const openBlackEnrollPageWindow = () => {
  window.open(blackEnrollPageUrl, '_blank');
};

export const openPnCActivePageWindow = () => {
  window.open(`/${baseUrl}${routes.businessBooster.pnc.active}`, '_blank');
};
