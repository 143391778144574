import { NumberPctIncrInput } from 'connect-web-ui';
import { OFFER_VALUE_SEGMENT_TYPE } from 'interfaces/PncContextInterface';
import {
  mmtBlackSegment,
  mmtSelectSegment,
} from 'modules/promotions-and-coupons/PnC.constants';
import { useEffect, useMemo, useState } from 'react';
import { useLangStrings } from 'utilities/CustomHooks';
import { capitalizeFirstLetter } from 'utilities/Utils';
export default function MMTTierInput(props) {
  const {
    data: [firstTier, secondTier, thirdTier],
    handler: [setFirstTier, setSecondTier, setThirdTier],
    validation: [firstTierValidator, secondTierValidator, thirdTierValidator],
    errors: [hasTierOneError, hasTierTwoError, hasTierThreeError],
    baseThirdTier, // sum of firstTier + secondTier
    cugAffiliates = [],
    isBlack,
  } = props;

  const [STRINGS] = useLangStrings('PerformanceBooster');
  const { MMT_COMMON_DRAWER } = STRINGS;

  const tierTwoDisabled = firstTier === '' ? '0%' : `${firstTier}%`;
  const tierThreeDisabled = baseThirdTier === '' ? '0%' : `${baseThirdTier}%`;

  const tierTwoMinIncr =
    secondTierValidator.min - firstTier < 0
      ? 0
      : secondTierValidator.min - firstTier;
  const tierThreeMinIncr =
    thirdTierValidator.min - baseThirdTier < 0
      ? 0
      : thirdTierValidator.min - baseThirdTier;

  return (
    <>
      <table className="mmt-tier-container">
        <tr>
          <td className="mmt-info">
            <h4>{MMT_COMMON_DRAWER.TIER1.LABEL}</h4>
            <span>
              {MMT_COMMON_DRAWER.MINIMUM} {firstTierValidator.min}%
            </span>
          </td>
          <td className="mmt-details">
            <NumberPctIncrInput
              value={firstTier}
              onChange={value => setFirstTier(value)}
              incrMin={firstTierValidator.min}
              max={firstTierValidator.max}
              error={hasTierOneError}
            />
            {hasTierOneError && (
              <span className="tier-error">
                {MMT_COMMON_DRAWER.TIER1.ERROR_MESSAGE}
              </span>
            )}
          </td>
        </tr>
        <tr>
          <td className="mmt-info">
            <h4>{MMT_COMMON_DRAWER.TIER2.LABEL}</h4>
            <span>
              {MMT_COMMON_DRAWER.MINIMUM} {secondTierValidator.min}%
            </span>
          </td>
          <td className="mmt-details">
            <div className="inline-mmt-input">
              <div className="previous-min flex-centralized">
                {tierTwoDisabled}
              </div>
              <span>+</span>
              <NumberPctIncrInput
                value={secondTier}
                onChange={value => setSecondTier(value)}
                incrMin={tierTwoMinIncr}
                max={secondTierValidator.max - firstTier || 1}
                error={hasTierTwoError}
              />
            </div>
            {hasTierTwoError && (
              <span className="tier-error">
                {MMT_COMMON_DRAWER.TIER2.ERROR_MESSAGE}
              </span>
            )}
          </td>
        </tr>
        <tr>
          <td className="mmt-info">
            <h4>{MMT_COMMON_DRAWER.TIER3.LABEL}</h4>
            <span>
              {MMT_COMMON_DRAWER.MINIMUM} {thirdTierValidator.min}%
            </span>
          </td>
          <td className="mmt-details">
            <div className="inline-mmt-input">
              <div className="previous-min flex-centralized">
                {tierThreeDisabled}
              </div>
              <span>+</span>
              <NumberPctIncrInput
                value={thirdTier}
                onChange={value => setThirdTier(value)}
                incrMin={tierThreeMinIncr}
                max={thirdTierValidator.max - baseThirdTier || 1}
                error={hasTierThreeError}
              />
            </div>
            {hasTierThreeError && (
              <span className="tier-error">
                {MMT_COMMON_DRAWER.TIER3.ERROR_MESSAGE}
              </span>
            )}
          </td>
        </tr>
        {cugAffiliates.map((affiliate, idx) => (
          <tr key={`affiliate-${idx}`}>
            <td className="mmt-info">
              <h4>{MMT_COMMON_DRAWER.AFFILIATES.LABEL}</h4>
              <span>
                {affiliate.offer_proportion * 100}%{' '}
                {isBlack
                  ? MMT_COMMON_DRAWER.AFFILIATES.DISCOUNT_DESC.replace(
                    '__placeholder__',
                    capitalizeFirstLetter(mmtBlackSegment.EXCLUSIVE),
                  )
                  : MMT_COMMON_DRAWER.AFFILIATES.DISCOUNT_DESC.replace(
                    '__placeholder__',
                    capitalizeFirstLetter(mmtSelectSegment.GOLD),
                  )}
              </span>
            </td>
            <td className="mmt-details">
              <div className="inline-mmt-input">
                <div className="previous-min flex-centralized mmt-affiliate-flex">
                  <span>
                    {(baseThirdTier + thirdTier) * affiliate.offer_proportion}
                  </span>
                  <span>%</span>
                </div>
              </div>
              {hasTierThreeError && (
                <span className="tier-error">
                  {MMT_COMMON_DRAWER.TIER3.ERROR_MESSAGE}
                </span>
              )}
            </td>
          </tr>
        ))}
      </table>
      {!!cugAffiliates.length && (
        <div className="tier-discount-note">
          {STRINGS.AFFILIATE_MIN_DESCRIPTION}
        </div>
      )}
    </>
  );
}

const generateMMTBlackValidator = meta => {
  const res = [
    {
      min: meta.minOfferValue,
      max: meta.maxOfferValue,
    },
  ];

  const mmtBlack1 = {
    min: meta.segmentData.mmtBlack1.minOfferValue,
    max: meta.segmentData.mmtBlack1.maxOfferValue,
  };
  const mmtBlack2 = {
    min: meta.segmentData.mmtBlack2.minOfferValue,
    max: meta.segmentData.mmtBlack2.maxOfferValue,
  };
  res.push(mmtBlack1, mmtBlack2);

  return res;
};

const generateMMTSelectValidator = meta => {
  const res = [
    {
      min: meta.minOfferValue,
      max: meta.maxOfferValue,
    },
  ];

  const mmtSelect1 = {
    min: meta.segmentData.mmtSelect1.minOfferValue,
    max: meta.segmentData.mmtSelect1.maxOfferValue,
  };
  const mmtSelect2 = {
    min: meta.segmentData.mmtSelect2.minOfferValue,
    max: meta.segmentData.mmtSelect2.maxOfferValue,
  };
  res.push(mmtSelect1, mmtSelect2);
  return res;
};

const offerListInit = offerValueList => {
  //eslint-disable-next-line prefer-const
  let [firstTierValue, secondTierValue, thirdTierValue] = offerValueList.map(
    offer => offer.offer_value,
  );

  if (thirdTierValue && secondTierValue) {
    thirdTierValue -= secondTierValue;
  }
  if (secondTierValue) {
    secondTierValue -= firstTierValue;
  }

  return [firstTierValue, secondTierValue, thirdTierValue];
};

export function useMMTTierInput(offerValues, meta, isBlack) {
  const offerValueList = offerValues?.filter(
    offer => offer.segment !== OFFER_VALUE_SEGMENT_TYPE.Affiliate,
  );
  const [firstTierValidator, secondTierValidator, thirdTierValidator] = useMemo(
    () =>
      isBlack
        ? generateMMTBlackValidator(meta)
        : generateMMTSelectValidator(meta),
    [],
  );
  const [firstTierValue, secondTierValue, thirdTierValue] = useMemo(
    () => offerListInit(offerValueList),
    [],
  );
  const [firstTier, setFirstTier] = useState(
    () => firstTierValue ?? firstTierValidator.min,
  );
  const [secondTier, setSecondTier] = useState(() => {
    if (secondTierValue) {
      return secondTierValue;
    }
    const val = secondTierValidator.min - firstTier;
    return val <= 0 ? 0 : val;
  });
  const [thirdTier, setThirdTier] = useState(() => {
    if (thirdTierValue) {
      return thirdTierValue;
    }
    // Exclusive have default value of 15, where as it's minimum is 10.
    const val =
      offerValueList.length === 0
        ? 5
        : thirdTierValidator.min - (secondTier + firstTier);
    return val <= 0 ? 0 : val;
  });
  const [baseThirdTier, setBaseThirdTier] = useState(0);

  useEffect(() => {
    setBaseThirdTier(firstTier + secondTier);
  }, [firstTier, secondTier]);

  const hasTierOneError =
    firstTier < firstTierValidator.min || firstTier > firstTierValidator.max;
  const hasTierTwoError =
    baseThirdTier < secondTierValidator.min ||
    baseThirdTier > secondTierValidator.max;
  const hasTierThreeError =
    baseThirdTier + thirdTier < thirdTierValidator.min ||
    baseThirdTier + thirdTier > thirdTierValidator.max;

  const populateMMTTierData = () => [
    firstTier,
    baseThirdTier,
    baseThirdTier + thirdTier,
  ];

  // Do not change the order
  return {
    data: [firstTier, secondTier, thirdTier],
    handler: [setFirstTier, setSecondTier, setThirdTier],
    validation: [firstTierValidator, secondTierValidator, thirdTierValidator],
    errors: [hasTierOneError, hasTierTwoError, hasTierThreeError],
    baseThirdTier,
    populateMMTTierData,
  };
}
