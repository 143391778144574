import { css } from 'styled-components';

// Colors
export const colors = {
  grayScreen: 'rgb(242, 242, 242)',
  grayDisabled: 'rgb(203, 203, 203)',
  grayLowEmphasis: 'rgb(117, 117, 117)',
  grayMediumEmphasis: 'rgb(74, 74, 74)',
  grayHighEmphasis: 'rgb(0, 0, 0)',
  white: 'rgb(255, 255, 255)',
  primaryButtonDark: 'rgb(34, 46, 167)',
  primaryButtonDefault: 'rgb(53, 67, 191)',
  brandLight: 'rgb(246, 247, 255)',
  brandDark: 'rgb(34, 46, 167)',
  brandDefault: 'rgb(53, 67, 191)',
  linkBlueLight: 'rgb(234, 245, 255)',
  errorLight: 'rgb(252, 218, 219)',
  linkBlueDefault: 'rgb(0, 140, 255)',
  errorDefault: '#ff0008',
  errorDark: 'rgb(166, 23, 27)',
  warningDefault: 'rgb(0, 126, 125)',
  warningLight: 'rgb(230, 255, 249)',
  warningDark: 'rgb(0, 103, 102)',
  linkBlueDark: 'rgb(3, 115, 208)',
  successDefault: 'rgb(0, 126, 125)',
  orange: '#de521f', // this color is not in the Zeplin style guide, but will be added in future
  warningDefault2: 'rgb(207, 129, 0)', // this color is not in the Zeplin style guide, but will be added in future
};

// Typography
export const typography = {
  shoutoutBold: css`
    font-family: Lato;
    font-size: 36px;
    font-weight: 700;
  `,
  headingBold: css`
    font-family: Lato;
    font-size: 32px;
    font-weight: 700;
  `,
  subheadingRegular: css`
    font-family: Lato;
    font-size: 24px;
    font-weight: 400;
  `,
  titleBlack: css`
    font-family: Lato;
    font-size: 18px;
    font-weight: 900;
  `,
  headingRegular: css`
    font-family: Lato;
    font-size: 32px;
    font-weight: 400;
  `,
  titleBoldUnderline: css`
    font-family: Lato;
    font-size: 18px;
    font-weight: 700;
    text-decoration: underline;
  `,
  headingBlack: css`
    font-family: Lato;
    font-size: 32px;
    font-weight: 900;
  `,
  subheadingBlack: css`
    font-family: Lato;
    font-size: 24px;
    font-weight: 900;
  `,
  bodyTextBaseBoldUnderline: css`
    font-family: Lato;
    font-size: 16px;
    font-weight: 700;
    text-decoration: underline;
  `,
  titleRegular: css`
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
  `,
  titleBold: css`
    font-family: Lato;
    font-size: 18px;
    font-weight: 700;
  `,
  subTextRegular: css`
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
  `,
  subheadingBold: css`
    font-family: Lato;
    font-size: 24px;
    font-weight: 700;
  `,
  shoutoutBlack: css`
    font-family: Lato;
    font-size: 36px;
    font-weight: 900;
  `,
  bodyTextBaseBlack: css`
    font-family: Lato;
    font-size: 16px;
    font-weight: 900;
  `,
  bodyTextBaseRegular: css`
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
  `,
  bodyTextBaseBold: css`
    font-family: Lato;
    font-size: 16px;
    font-weight: 700;
  `,
  subTextBoldUnderline: css`
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    text-decoration: underline;
  `,
  microTextBlack: css`
    font-family: Lato;
    font-size: 12px;
    font-weight: 900;
  `,
  subTextBold: css`
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
  `,
  legendRegular: css`
    font-family: Lato;
    font-size: 10px;
    font-weight: 400;
  `,
  microTextRegular: css`
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
  `,
  subTextBlack: css`
    font-family: Lato;
    font-size: 14px;
    font-weight: 900;
  `,
  microTextBold: css`
    font-family: Lato;
    font-size: 12px;
    font-weight: 700;
  `,
  bodyTextBaseRegularUnderline: css`
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    text-decoration: underline;
  `,
  legendBold: css`
    font-family: Lato;
    font-size: 10px;
    font-weight: 700;
  `,
  fontFamilyLato: css`
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
  `,
};
