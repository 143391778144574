import ACTION_TYPES from './actionTypes';
import { GenericActionCreator } from './types';

export const fetching: GenericActionCreator = api => ({
  type: ACTION_TYPES[api].FETCHING,
  api,
});
export const success: GenericActionCreator = (api, response) => ({
  type: ACTION_TYPES[api].SUCCESS,
  response,
  api,
});
export const error: GenericActionCreator = (api, exception) => ({
  type: ACTION_TYPES[api].FAILED,
  api,
  exception,
});
export const reset: GenericActionCreator = api => ({
  type: ACTION_TYPES[api].RESET,
  api,
});

export const update: GenericActionCreator = (api, response) => ({
  type: ACTION_TYPES[api].UPDATE,
  api,
  response,
});
