import { Grid } from 'connect-web-ui/mui';
import { ErrorMsgIcon } from 'assets/common';
import { Button, Modal } from 'connect-web-ui';
import { GenericFunction } from 'interfaces/types';
import { noop } from 'utilities/Utils';

export interface IErrorData {
  title: string;
  content: {
    heading?: string;
    subText?: string[];
    bulletedList?: string[];
  }[];
}

interface IActionNotAllowedModal {
  showModal: boolean;
  confirmText: string;
  cancelText?: string;
  cancelHandler?: GenericFunction;
  confirmHandler?: GenericFunction;
  errorData: IErrorData;
}

function ActionNotAllowedModal({
  showModal = false,
  confirmText,
  cancelText,
  cancelHandler = noop,
  confirmHandler = noop,
  errorData,
}: IActionNotAllowedModal) {
  return (
    <Modal
      isOpen={showModal}
      size="custom"
      header={{
        title: (
          <Grid container alignItems="center" gap={'8px'}>
            <ErrorMsgIcon />
            <div>{errorData.title}</div>
          </Grid>
        ),
        hideCross: true,
      }}
      footer={{
        primaryBtn: {
          onClick: noop,
          wrapper: () => (
            <Button
              className="!normal-case"
              onClick={confirmHandler}
              toLowerCase={false}
            >
              {confirmText}
            </Button>
          ),
        },
        secondaryBtn: {
          text: cancelText,
          onClick: cancelHandler,
        },
      }}
      customClasses={{
        container: '!w-[590px]',
        footer: {
          root: '!justify-between',
          primaryBtn: '!normal-case',
        },
      }}
      onClose={cancelHandler}
    >
      <>
        {errorData?.content.map((content, index) => (
          <div key={index} className="mb-3">
            <div className="font-medium">{content.heading}</div>
            <span>{content.subText?.join(' ')}</span>
            <ul className="ml-5 m-0 list-disc font-normal">
              {content.bulletedList?.map((error, _idx) => (
                <li key={_idx}>{error}</li>
              ))}
            </ul>
          </div>
        ))}
      </>
    </Modal>
  );
}

export default ActionNotAllowedModal;
