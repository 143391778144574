export const getPropertyListingDataVariable = (hotelcode: string) => ({
  rawEndpointRequestData: {
    fetchPropertyListingRequest: {
      endPointId: 99,
      modelType: 'modelType1',
      body: {
        ingoHotelId: hotelcode,
      },
    },
  },
});

export const getCombinedLinkgaeDetailsVariable = (hotecode: string) => ({
  rawEndpointRequestData: {
    fetchPropertyListingRequest: {
      endPointId: 99,
      modelType: 'modelType1',
      body: {
        ingoHotelId: hotecode,
      },
    },
  },
  rawEndpointsRequestData2: {
    internalCalendarGetLinkage: {
      modelType: 'modelType1',
      queryParams: {
        hotelCode: hotecode,
      },
    },
  },
});
