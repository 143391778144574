import { EventDetailSchema } from 'utilities/pdt/pdt.types';
import { WebVitalMetricWithAttribution } from './webVitals.types';
import { pushToPdt } from 'utilities/pdt';

// This payload is specific for PDT logging.
// If a different logging mechanism is used, this function should be updated or replaced.
export function createWebVitalPayloadForPdt(
  metric: WebVitalMetricWithAttribution,
) {
  const { name, value, rating } = metric;

  const payload: EventDetailSchema = {
    event_name: `web_vitals_values_${name}`,
    event_type: 'user_action',
    event_value: value.toString(),
    components: {
      content_details: [
        {
          category: rating,
          position: {},
          id: 'NA',
          type: 'NA',
          category_id: 'NA',
        },
      ],
    },
    event_id: 'NA',
    event_timestamp: Date.now(),
  };

  switch (metric.name) {
    case 'CLS':
      payload.event_id = metric.attribution.largestShiftTarget;
      break;
    case 'LCP':
      payload.event_id = metric.attribution.element;
      break;
    case 'INP': {
      payload.event_id = metric.attribution.interactionTarget;
      payload.components.content_details[0].type =
        metric.attribution.interactionType;
      payload.components.content_details[0].category_id =
        metric.attribution.loadState;
      payload.components.content_details[0].id =
        metric.attribution.interactionTime.toString();
      break;
    }
    default:
      break;
  }

  return payload;
}

export function handleWebVitalMetric(metric: WebVitalMetricWithAttribution) {
  const payload = createWebVitalPayloadForPdt(metric);

  pushToPdt(payload);
}
