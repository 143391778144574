import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AppEvents } from '../../utilities/EventBus';
import { FloatingCSBanner } from '../../modules/analytics/sections/content_score/ActionCard';
import { useLangStrings } from '../../utilities/CustomHooks';
import { isNullOrUndefined } from 'utilities/Utils';

export default function ContentScoreBanner() {
  const { pathname } = useLocation();
  const [bannerData, setBannerData] = useState(null);

  const [{ CONTENT_SCORE: STRINGS }] = useLangStrings<'Analytics'>('Analytics');

  useEffect(() => {
    AppEvents.on('CSBannerData', setBannerData);
    return () => {
      AppEvents.off('CSBannerData', setBannerData);
    };
  }, []);

  useEffect(() => {
    if (!isNullOrUndefined(bannerData)) {
      document.querySelector('.nav-body').scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [bannerData]);

  const dismissBanner = () => {
    setBannerData(null);
  };

  if (isNullOrUndefined(bannerData) || pathname !== bannerData?.redirectUrl) {
    return null;
  }

  return (
    <div className="sr-container">
      <FloatingCSBanner
        STRINGS={STRINGS}
        data={bannerData}
        onClose={dismissBanner}
      />
    </div>
  );
}
