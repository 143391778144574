import { isNullOrUndefined, navigateTo } from 'utilities/Utils';
import { COMPONENT_ID_ENUM } from './SideNav.constants';
import type {
  CTA,
  ILeftNavDataResponse,
  LeftNavItem,
  TrackingConfig,
} from './SideNav.types';
import { routeComponentMap } from 'app/hotel/HotelTabs';
import { JsxElement } from 'typescript';
import { routes } from 'app/routes';
import { GTM_CATEGORIES, legacyPushToGTM } from 'utilities/gtm';

export const getLeftNavTabItemList = (
  apiResponse: ILeftNavDataResponse,
  isGroupPropertyView: boolean,
  isResellerView: boolean,
) => {
  const getComponentId = () => {
    if (isGroupPropertyView) return COMPONENT_ID_ENUM.COMMON_GROUP_SIDE_NAV;
    if (isResellerView) return COMPONENT_ID_ENUM.COMMON_RESELLER_SIDE_NAV;
    return COMPONENT_ID_ENUM.COMMON_SIDE_NAV;
  };

  const componentId = getComponentId();
  const variantId = apiResponse.sequenceData.data.find(
    sequenceItem => sequenceItem.componentId === componentId,
  )?.variantId;

  return apiResponse.data.componentData[variantId]?.data.items || [];
};

export const handleOmnitureEvent = (trackingConfig: TrackingConfig) => {
  legacyPushToGTM({
    eventCategory:
      trackingConfig?.trackedAttributes?.eventCategory ??
      GTM_CATEGORIES.WEB_LEFTDRAWER,
    eventAction: trackingConfig?.trackedAttributes?.eventAction,
    omnitureData: {
      event: trackingConfig?.ctaType,
      name:
        trackingConfig?.trackedAttributes?.omnitureData?.name ??
        trackingConfig?.trackedAttributes?.name,
    },
    eventLabel: trackingConfig?.trackedAttributes?.eventLabel,
  });
};

export const handleSideNavItemClick = (cta: CTA, openRaiseATicketDrawer) => {
  handleOmnitureEvent(cta.trackingConfig);
  if (cta?.deeplinkType === 'COMPONENT') {
    if (cta?.deeplink === 'HelpCentre' && openRaiseATicketDrawer) {
      openRaiseATicketDrawer(true);
    }
  } else if (cta?.deeplinkType === 'SCREEN_REDIRECT') {
    navigateTo(cta?.deeplink || '/');
  } else if (cta?.deeplinkType === 'PARAMETER') {
    let redirectUrl: string = cta.deeplink;
    if (redirectUrl === '__helpCenterUrl__') {
      redirectUrl = window.helpCenterUrl;
    }
    window.open(redirectUrl, '_blank');
  } else {
    navigateTo('/');
  }
};

const comparePath = (pathname: string, tab: LeftNavItem): boolean => {
  let toReturn = false;
  const deeplink = tab.cta?.deeplink;
  if (pathname === '/' || deeplink === '/')
    toReturn =
      pathname === deeplink || pathname === routes.onboarding.myProperty;
  else if (pathname.startsWith(deeplink)) toReturn = true;
  return toReturn;
};

export const getActiveMenuItem = (
  tabs: LeftNavItem[],
  pathname: string,
): string => {
  let activeMenuLabel = '';
  tabs.forEach((tab: LeftNavItem) => {
    if (comparePath(pathname, tab)) {
      activeMenuLabel = tab.data?.title;
    }
    if (tab?.items?.length) {
      tab.items.forEach((item: LeftNavItem) => {
        if (comparePath(pathname, item)) {
          activeMenuLabel = tab.data?.title;
        }
      });
    }
  });
  return activeMenuLabel;
};

export const getTabsComponets = (
  leftNavData: ILeftNavDataResponse,
  isResellerView: boolean,
  grpPropertyView: boolean = false,
) => {
  if (isNullOrUndefined(leftNavData)) return { tabList: [], flatTabsArray: [] };

  const tabList = getLeftNavTabItemList(
    leftNavData,
    grpPropertyView,
    isResellerView,
  );
  const flatTabsArray: Array<{ route: string; component: JsxElement }> = (
    tabList || []
  ).reduce((acc, item) => {
    const items = item?.items || [];
    const isTopLevel = item?.cta?.deeplink;

    if (isTopLevel) {
      const mainItem = {
        route: item?.cta?.deeplink,
        component: routeComponentMap[item?.cta?.deeplink],
      };
      if (
        mainItem.route &&
        mainItem.component &&
        !item?.additionalMetadata?.verificationBlocker
      ) {
        return [...acc, mainItem];
      }
      return acc;
    }

    const subItems = items
      .map(subitem => {
        const route = subitem?.cta?.deeplink;
        const component = routeComponentMap[route];
        if (
          route &&
          component &&
          !subitem?.additionalMetadata?.verificationBlocker
        ) {
          return { route, component };
        }
        return null;
      })
      .filter(subitem => subitem !== null);
    return [...acc, ...subItems];
  }, []);
  return { tabList, flatTabsArray };
};

export const isWebkitBrowser = () => {
  return navigator?.userAgent?.indexOf('AppleWebKit') > -1;
};

export const calculateRightPosition = (screenWidth: number) => {
  if (screenWidth <= 1366) {
    return '35px'; // 16 + 19
  }
  return 'calc((100vw - 1240px - 88px) / 2 + 16px)';
};
