import React from 'react';
import { CloseIcon, ModalOverlay, ModalWrapper } from './styles';
import { createPortal } from 'react-dom';

export interface CustomModalProps {
  children: React.ReactNode;
  width?: string;
  padding?: string;
  closeIcon?: boolean;
  onClose?: () => void;
  visible?: boolean;
  closeOnOverlayClick?: boolean;
}

const CustomModal = ({
  children,
  width,
  padding,
  closeIcon,
  onClose,
  visible,
  closeOnOverlayClick = false,
}: CustomModalProps) => {
  if (!visible) return null;

  return createPortal(
    <>
      <ModalWrapper width={width} padding={padding}>
        {closeIcon && <CloseIcon onClick={onClose} />}
        {children}
      </ModalWrapper>
      <ModalOverlay onClick={closeOnOverlayClick ? onClose : undefined} />
    </>,
    document.body,
  );
};

export default CustomModal;
