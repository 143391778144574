import { DYNAMIC_FILTER_KEYS_MAP } from 'modules/bookings-revamp/bookings.constants';
import { FilterCardsConfig } from './filter.types';
import { HotelLegalEntityType, UserProfileType } from 'app/hotel/constants';
import { hotelLegalEntityTypeMap } from '../../../../../constants';

export const BRAND_CONFIG = (
  isResellerView,
  legalEntityType,
  userProfileType,
) =>
  !isResellerView
    ? [
      {
        label: 'Goibibo',
        value: ['Goibibo'],
      },
      {
        label: 'MakeMyTrip',
        value: ['MakeMyTrip', 'MMTGCC'],
      },
      ...(hotelLegalEntityTypeMap[legalEntityType] ===
          HotelLegalEntityType.HOTEL_TRAVEL &&
        userProfileType === UserProfileType.BOTH
        ? [
          {
            label: 'HotelCloud',
            value: ['HotelCloud'],
          },
        ]
        : []),
    ]
    : [
      {
        label: 'HotelCloud',
        value: ['HotelCloud'],
      },
    ];

export const BOOKING_STATUS_CONFIG = [
  {
    label: 'Acknowledged',
    value: 'confirmed',
  },
  {
    label: 'Cancelled',
    value: 'cancelled',
  },
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Modified',
    value: 'modified',
  },
  {
    label: 'Check-in-denied',
    value: 'rejected',
  },
];

export const PAYMENT_STATUS_CONFIG = [
  {
    label: 'Pending',
    value: 'pending',
  },
  // For Future purpose.
  // {
  //   label: 'Processing',
  //   value: 'processing'
  // },
  {
    label: 'Processed',
    value: 'processed',
  },
];

export const SALES_CHANNELS_CONFIG = [
  {
    label: 'All Bookings',
    value: 'all',
  },
  {
    label: 'Corporate Bookings',
    value: 'Corporate',
  },
];

export const GUEST_PAYMODE_CONFIG = [
  {
    label: 'Paid Online',
    value: ['PAS'],
  },
  {
    label: 'Pay@Hotel',
    value: ['PAH', 'PAH_WITHOUT_CC', 'PAH_WITH_CC'],
  },
];

export const INVOICE_STATUS_CONFIG = [
  {
    label: 'Pending',
    value: 'pending,scrutiny',
  },
  {
    label: 'Uploaded',
    value: 'approved,verified',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
  {
    label: 'Under Verification',
    value: 'in_wip',
  },
];

export const DATE_FILTERS_CONFIG = [
  {
    label: 'Check-in',
    value: 'checkin',
  },
  {
    label: 'Check-out',
    value: 'checkout',
  },
  {
    label: 'Booking Dates',
    value: 'bookingDate',
  },
];

export const hourlyApiFormatMap = {
  hourly: 'isDayUseBooking',
  group: 'isGroupBooking',
  rtb: 'isRTBRequest',
  // url: 'urlBookings'
  frn: 'isFrnBooking',
};

export const HOURLY_GROUP_URL_RTB_CONFIG = [
  { label: 'Hourly Booking', value: 'hourly' },
  { label: 'Group Booking', value: 'group' },
  // { label: 'Url Booking', value: 'url' },
  { label: 'RTB Booking', value: 'rtb' },
  { label: 'Free Room Night', value: 'frn' },
];

export const FILTER_FIELDS_KEYS = {
  BRAND: 'vendorId',
  BOOKING_STATUS: 'confirmStatusValue',
  SALES_CATEGORY: 'salesCategory',
  PAYMENT_STATUS: 'paymentStatus',
  CUSTOMER_PAYMODE: 'payModeValue',
  INVOICE_STATUS: 'gstInvoiceStatus',
  HOURLY_GROUP_URL_RTB_BOOKING: 'hourlyOrGroupOrUrlOrRTBbooking',
  BOOKING_DATE: 'bookingDateType',
  ADDITIONAL: 'additional',
  UPCOMING_PAST: 'upcomingOrPast',
} as const;

export const FILTER_CARDS_CONFIG = (
  isResellerView,
  legalEntityType,
  userProfileType,
) => [
  {
    [FILTER_FIELDS_KEYS.SALES_CATEGORY]: {
      key: FILTER_FIELDS_KEYS.SALES_CATEGORY,
      config: SALES_CHANNELS_CONFIG,
      title: 'Channels',
      compId: 'sales',
      type: 'radio',
      defaultValue: SALES_CHANNELS_CONFIG[0].value,
    },
    [FILTER_FIELDS_KEYS.BRAND]: {
      key: FILTER_FIELDS_KEYS.BRAND,
      config: BRAND_CONFIG(isResellerView, legalEntityType, userProfileType),
      title: 'Bookings From',
      compId: 'brand',
      type: 'checkbox',
      disabled: isResellerView,
    },
    [FILTER_FIELDS_KEYS.BOOKING_DATE]: {
      key: FILTER_FIELDS_KEYS.BOOKING_DATE,
      config: DATE_FILTERS_CONFIG,
      title: 'Date Filters',
      compId: 'date-filters',
      type: 'radio',
      defaultValue: DATE_FILTERS_CONFIG[0].value,
    },
    [FILTER_FIELDS_KEYS.BOOKING_STATUS]: {
      key: FILTER_FIELDS_KEYS.BOOKING_STATUS,
      config: BOOKING_STATUS_CONFIG,
      title: 'Booking Status',
      compId: 'booking-status',
      type: 'radio',
      defaultValue: BOOKING_STATUS_CONFIG[0].value,
    },
    [FILTER_FIELDS_KEYS.CUSTOMER_PAYMODE]: {
      key: FILTER_FIELDS_KEYS.CUSTOMER_PAYMODE,
      config: GUEST_PAYMODE_CONFIG,
      title: 'Customer Payment Mode',
      compId: 'paymode',
      type: 'checkbox',
    },
    [FILTER_FIELDS_KEYS.PAYMENT_STATUS]: {
      key: FILTER_FIELDS_KEYS.PAYMENT_STATUS,
      config: PAYMENT_STATUS_CONFIG,
      title: 'Payment Status',
      compId: 'payments',
      type: 'radio',
    },
    [FILTER_FIELDS_KEYS.INVOICE_STATUS]: {
      key: FILTER_FIELDS_KEYS.INVOICE_STATUS,
      config: INVOICE_STATUS_CONFIG,
      title: 'GST Invoice Status',
      compId: 'invoice',
      type: 'radio',
    },
    [FILTER_FIELDS_KEYS.HOURLY_GROUP_URL_RTB_BOOKING]: {
      key: FILTER_FIELDS_KEYS.HOURLY_GROUP_URL_RTB_BOOKING,
      config: HOURLY_GROUP_URL_RTB_CONFIG,
      title: 'Booking Type',
      compId: 'booking',
      type: 'radio',
    },
  },
];

export const getDateRangeRadioOptions = STRINGS => {
  return [
    {
      label: STRINGS.PENDING_TASKS.BOOKING,
      value: FILTER_FIELDS_KEYS.BOOKING_DATE,
    },
    {
      label: STRINGS.ROOM_DETAILS.CHECK_IN,
      // @ts-ignore
      value: FILTER_FIELDS_KEYS.CHECKIN,
    },
    {
      label: STRINGS.ROOM_DETAILS.CHECK_OUT,
      // @ts-ignore
      value: FILTER_FIELDS_KEYS.CHECKOUT,
    },
  ];
};

export const getFilteredFieldsConfig = (
  filterState,
  dynamicFilterMap,
  isDomHotel,
  isChain,
  isResellerView,
  legalEntityType,
  userProfileType,
): FilterCardsConfig[] => {
  const modifiedFieldsConfig = JSON.parse(
    JSON.stringify(
      FILTER_CARDS_CONFIG(isResellerView, legalEntityType, userProfileType),
    ),
  );
  const { confirmStatusValue, hourlyOrGroupOrUrlOrRTBbooking } = filterState;

  const hourlyGroupDayuseKeys = {
    hasRtbBookings: 'rtb',
    hasGroupBookings: 'group',
    hasDayUseBookings: 'hourly',
  };

  const fieldKey = FILTER_FIELDS_KEYS.HOURLY_GROUP_URL_RTB_BOOKING;
  const fieldObj = modifiedFieldsConfig[0][fieldKey];

  for (const key of Object.keys(hourlyGroupDayuseKeys)) {
    if (!dynamicFilterMap[key]) {
      fieldObj.config = fieldObj?.config?.filter(item => {
        return item.value !== hourlyGroupDayuseKeys[key];
      });
    }
  }
  if (fieldObj.config?.length === 0) {
    delete modifiedFieldsConfig[0][fieldKey];
  } else {
    modifiedFieldsConfig[0][fieldKey] = fieldObj;
  }

  if (!dynamicFilterMap[DYNAMIC_FILTER_KEYS_MAP.HAS_PAH_BOOKINGS]) {
    delete modifiedFieldsConfig[0][FILTER_FIELDS_KEYS.CUSTOMER_PAYMODE];
  }

  if (!dynamicFilterMap[DYNAMIC_FILTER_KEYS_MAP.HAS_CORPORATE_BOOKINGS]) {
    delete modifiedFieldsConfig[0][FILTER_FIELDS_KEYS.SALES_CATEGORY];
  }

  if (!isDomHotel || isChain) {
    delete modifiedFieldsConfig[0][FILTER_FIELDS_KEYS.PAYMENT_STATUS];
  }

  if (
    confirmStatusValue === BOOKING_STATUS_CONFIG[1].value ||
    confirmStatusValue === BOOKING_STATUS_CONFIG[4].value ||
    hourlyOrGroupOrUrlOrRTBbooking === HOURLY_GROUP_URL_RTB_CONFIG[0].value ||
    hourlyOrGroupOrUrlOrRTBbooking === HOURLY_GROUP_URL_RTB_CONFIG[1].value ||
    !dynamicFilterMap[DYNAMIC_FILTER_KEYS_MAP.IS_GSTN_ASSURED]
  ) {
    if (filterState.gstInvoiceStatus.length === 0) {
      delete modifiedFieldsConfig[0][FILTER_FIELDS_KEYS.INVOICE_STATUS];
    }
  }
  return Object.values(modifiedFieldsConfig[0]);
};
