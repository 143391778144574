export const APIS = {
  HOTEL_DETAIL: 'hotelDetail',
  CT_DETAIL: 'clearTaxDetail',
  ROOM_RATEPLANS_INFO: 'roomRatePlans',
  DEMAND_DATA: 'demandData',
  PERSUATION_DATA: 'persuationData',
  HOTEL_MO_FLAG: 'hotelMOFlag',
  QSS_STATE: 'qssState',
  CAMPAIGN_ENTITY_AGREEMENT: 'campaignEntityAgreement',
  CALSYNC_DATA: 'calSyncData',
  LEGACY_PARITY_PART_1: 'legacyParityOne',
  LEGACY_PARITY_PART_2: 'legacyParityTwo',
  COMPSET_DATA: 'compsetData',
  SALE_CAMPAIGNS: 'saleCampaigns',
  RESELLER_AGREEMENT: 'resellerAgreement',
  FEATURE_FLAGS: 'featureFlags',
  ICS_COMBINED_DATA: 'icsCombinedData',
};

export const API_STATUS = {
  fetching: false,
  success: null,
  failed: null,
};

export enum UserProfileType {
  INGO = 'ingo',
  HOTEL_TRAVEL = 'hotelTravel',
  BOTH = 'both',
}

export enum UserProfileViewType {
  INGO = 'ingo',
  HOTEL_TRAVEL = 'hotelTravel',
}

export enum HotelLegalEntityType {
  INGO = 'ingo',
  HOTEL_TRAVEL = 'hotelTravel',
}
