import {
  PartyMusicDanceIcon,
  BoardGameDiceIcon,
  OutdoorsLandscapeMeadowIcon,
  OutdoorsCampFireGuitarIcon,
  PartyConfettiIcon,
} from 'assets/modules/property-vibe';
export const TOKEN_STATUS = {
  BLOCKED: 'BLOCKED',
  EXPIRED: 'EXPIRED',
  AUTHORISED: 'AUTHORISED',
  DISCONNECTED: 'DISCONNECTED',
  UNBLOCKED: 'UNBLOCKED',
  CONNECT: 'CONNECT',
};
export const DISCONNECT = 'DISCONNECT';
export const INSTAGRAM_VIBES = 'instagram_vibes';
export const HOTEL_INSTAGRAM = 'HOTEL_INSTAGRAM';
export const VIDEOURL = 'https://www.youtube.com/embed/obGormjfBpQ?autoplay=1';

export const FAQ = [
  {
    QUESTION:
      'Is it safe to provide my Instagram login credentials on the Extranet?',
    ANSWER:
      'Yes, it is safe because your credentials never get saved on the Extranet. MakeMyTrip only authenticates your profile through these credentials.',
  },
  {
    QUESTION: 'What kind of content should I post on my Instagram account?',
    ANSWERS: [
      {
        ICON: PartyMusicDanceIcon,
        TEXT: 'Add videos of guests interacting with each other and enjoying their stay at your property.',
      },
      {
        ICON: BoardGameDiceIcon,
        TEXT: 'If you offer any indoor/outdoor games or host movie nights, post photos or reels showcasing these experiences.',
      },
      {
        ICON: OutdoorsLandscapeMeadowIcon,
        TEXT: 'Is your property located in a scenic location? Post photos or videos of the views from your property.',
      },
      {
        ICON: OutdoorsCampFireGuitarIcon,
        TEXT: 'Do you host events or parties at your property? Create reels on them.',
      },
      {
        ICON: PartyConfettiIcon,
        TEXT: 'Another great idea is to showcase the lounging zones or spaces of your property.',
      },
    ],
  },
  {
    QUESTION:
      'How often do I need to re-authenticate/update my Instagram connection?',
    ANSWER:
      'You need to re-authenticate your Instagram connection every 60 days.',
  },
  {
    QUESTION: 'Can my Instagram Connection be blocked in MakeMyTrip?',
    ANSWER: `<b>We may block your Instagram connection from the Extranet & MMT App in the below-mentioned circumstances:</b>
      <ol type="1" class="list-decimal m-0 p-3">
        <li>If you post obscene content on your Instagram feed.</li>
        <li>If you share your contact details on your Instagram feed.</li>
      </ol>
      `,
  },
];

export const NOTES = `Please Note: <div class="m-1">
<ol type="1" class="list-decimal m-0 p-0 pl-3">
  <li><b>MMT may restrict your Instagram account if images/videos/reels sharing obscene content or your contact details are uploaded. We advise you to remove such content before connecting the account.</b></li>
  <li>Do not connect your personal/ property chain account. Only add your property's official Instagram account.</li>
  <li>The account must have a minimum of 9 posts and at least one post uploaded in the last 90 days for the content to go live on MakeMyTrip.</li>
  <li>Newly uploaded posts on Instagram take up to 2 hours to reflect on the MMT App & website.</li>
</ol>
</div>
`;
