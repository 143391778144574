import { colors, typography } from 'style/zeplin-token-styledComponents';
import styled from 'styled-components';

export const Heading = styled.p`
  position: relative;
  padding-left: 46px;
  ${typography.subTextBold}
  color:${colors.orange};

  &::before {
    height: 2px;
    width: 36px;
    background-color: ${colors.orange};
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const Subheading = styled.p`
  margin-top: 10px !important;
  ${typography.headingRegular}
  color: ${colors.grayMediumEmphasis};
`;
