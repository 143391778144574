import { ACTIVE_STATUS, POLICY_RP_TYPE, POLICY_VENDOR } from './constant';

// vendorType is always ingo in case of group rates and hourly
export const getCancellationPolicyVariables = (
  hotelcode: string,
  ratePlanType = POLICY_RP_TYPE.NORMAL,
  vendorType = POLICY_VENDOR.INGO,
) => {
  return {
    rpcGetCancellationPolicy: {
      modelType: 'modelType1',
      body: {
        ingoHotelId: hotelcode,
        filters: {
          vendorList: [vendorType],
          statusList: [ACTIVE_STATUS],
          policyTypeList: [ratePlanType],
        },
      },
    },
  };
};
