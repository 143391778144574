import React, { useEffect } from 'react';

import {
  LegacyFormElement as FormElement,
  LegacyButton as Button,
  Calendar,
  Typography,
  ToggleButton,
} from 'connect-web-ui';
import { addToDate, getFormattedDate } from 'utilities/DateUtils';
import {
  YES,
  displayDateFormatWithComma,
} from 'modules/promotions-and-coupons/PnC.constants';
import { classNames } from 'utilities/Utils';
import { ChevronDown, ChevronUp } from 'assets/common';

interface StayDatesProps {
  visibility;
  toggleVisibility: (section: string) => void;
  stayStartCalendarProps;
  stayEndCalendarProps;
  toClearEndDate: boolean;
  handleClearEndDate: () => void;
  blackOutToggle;
  setBlackOutToggle;
  blackoutCalendarProps;
  showCalError;
  STRINGS;
  COMMON_STRINGS;
  stayEndDate;
  today;
  isEdit: boolean;
  bookingStartDate;
  stayStartDate;
  blackoutDatesRangeErr;
  setBlackoutDatesRangeErr;
}

const StayDates: React.FC<StayDatesProps> = ({
  visibility,
  toggleVisibility,
  stayStartCalendarProps,
  stayEndCalendarProps,
  toClearEndDate,
  handleClearEndDate,
  blackOutToggle,
  setBlackOutToggle,
  blackoutCalendarProps,
  showCalError,
  STRINGS,
  COMMON_STRINGS,
  stayEndDate: stayNewEndDate,
  stayStartDate: stayNewStartDate,
  today,
  bookingStartDate,
  blackoutDatesRangeErr,
  setBlackoutDatesRangeErr,
}) => {
  const stayStartDate = getFormattedDate(
    stayStartCalendarProps?.calendarProps?.selectedDays,
    displayDateFormatWithComma,
  );
  const stayEndDate = getFormattedDate(
    stayEndCalendarProps?.calendarProps?.selectedDays,
    displayDateFormatWithComma,
  );

  const stayDatesMessage = !toClearEndDate
    ? `${STRINGS.STAY_DATES_FROM} ${stayStartDate} ${STRINGS.STAY_DATES_ONWARDS}`
    : `${STRINGS.STAY_DATES_FROM} ${stayStartDate} ${STRINGS.STAY_DATES_TO} ${stayEndDate}`;
  const parseDate = dateString => {
    const cleanString = dateString.replace(/(\d+)(th|st|nd|rd)/, '$1');
    return new Date(cleanString);
  };

  useEffect(() => {
    if (stayNewEndDate < stayNewStartDate) {
      stayEndCalendarProps.calendarProps?.setSelectedDays(
        addToDate(0, 'days', stayNewStartDate),
      );
    }
  }, [stayNewStartDate]);

  useEffect(() => {
    const selectedDays =
      blackoutCalendarProps.calendarProps?.selectedDays || [];
    const startDate = parseDate(getFormattedDate(stayNewStartDate.getTime()));
    const endDate = parseDate(getFormattedDate(stayNewEndDate.getTime()));

    const hasBlackoutError = selectedDays.some(blackoutDate => {
      const blackoutDateParsed = parseDate(
        getFormattedDate(blackoutDate.getTime()),
      );
      return blackoutDateParsed < startDate || blackoutDateParsed > endDate;
    });

    setBlackoutDatesRangeErr(hasBlackoutError);
  }, [
    blackoutCalendarProps.calendarProps?.selectedDays,
    stayNewStartDate,
    stayNewEndDate,
  ]);

  useEffect(() => {
    if (stayNewStartDate < bookingStartDate) {
      stayStartCalendarProps.calendarProps?.setSelectedDays(
        addToDate(0, 'days', bookingStartDate),
      );
    }
  }, [bookingStartDate]);
  return (
    <>
      <div className="flex justify-between items-center mt-[10px]">
        <Typography
          variant="subHeadingMedium"
          className="mr-4 flex items-center"
        >
          {STRINGS.STAY_DATES}
        </Typography>
        <Button
          onClick={() => toggleVisibility('stayDates')}
          variant="text"
          className="!capitalize !text-size-14"
        >
          {visibility.stayDates
            ? COMMON_STRINGS.BUTTONS.HIDE
            : COMMON_STRINGS.BUTTONS.SHOW}
          {visibility.stayDates ? (
            <ChevronUp fill="#008CFF" className="ml-1" width={20} height={20} />
          ) : (
            <ChevronDown
              fill="#008CFF"
              className="ml-1"
              width={20}
              height={20}
            />
          )}
        </Button>
      </div>
      <Typography variant="body1" className="mb-4">
        {stayDatesMessage}
      </Typography>
      {visibility.stayDates && (
        <div>
          <div className="flex">
            <FormElement label={STRINGS.DRAWER.APPLICABLE_STAY_START_NEW}>
              <Calendar
                type="input"
                {...stayStartCalendarProps}
                disabledBefore={(bookingStartDate || today) as Date}
              />
            </FormElement>
            <FormElement
              label={STRINGS.DRAWER.APPLICABLE_STAY_END_NEW}
              className="!mr-[0px]"
            >
              <Calendar
                type="input"
                {...stayEndCalendarProps}
                disabledBefore={addToDate(0, 'days', stayNewStartDate)}
              />
            </FormElement>
          </div>
          {toClearEndDate && (
            <div className="w-[100%] flex justify-end pr-4 mt-[-14px]">
              <Button
                variant="text"
                noPadding
                onClick={handleClearEndDate}
                className="!capitalize"
              >
                {STRINGS.CLEAR_END_DATE}
              </Button>
            </div>
          )}
          <Typography variant="body1" fb themeColor="gray.main">
            {STRINGS.BLACKOUT_DATES}
          </Typography>
          <div className="flex mb-4 justify-between mt-2">
            <div className="flex justify-between">
              <div className="w-[58%]">
                <Typography variant="body1">
                  {STRINGS.BLOCK_DISCOUNTS_PROMPT}
                </Typography>
              </div>
              <ToggleButton
                variant="YN"
                displayType="radio-chip"
                value={blackOutToggle}
                onChange={setBlackOutToggle}
              />
            </div>
          </div>
          {blackOutToggle === YES && (
            <div className={`${showCalError ? '' : 'mb-4'}`}>
              <Calendar
                type="blackout"
                {...blackoutCalendarProps}
                placeholder={STRINGS.CHOOSE_BLACKOUT_DATES}
                inputClasses={classNames(
                  showCalError
                    ? '!border-[1px] border-solid border-[#C62828]'
                    : '',
                )}
                disabledBefore={(stayNewStartDate || today) as Date}
                disabledAfter={stayNewEndDate as Date}
              />
            </div>
          )}
          {showCalError && blackOutToggle === YES && (
            <Typography
              variant="body1"
              themeColor="error.dark"
              className="mb-4"
            >
              {STRINGS.BLACKOUT_DATE_ERROR}
            </Typography>
          )}
          {blackoutDatesRangeErr && !showCalError && blackOutToggle === YES && (
            <Typography
              variant="body1"
              themeColor="error.dark"
              className="mb-4"
            >
              {STRINGS.BLACKOUT_DATE_RANGE_ERROR}
            </Typography>
          )}
        </div>
      )}
    </>
  );
};

export default StayDates;
