import { useLangStrings } from '../../utilities/CustomHooks';
import { routes } from '../../app/routes';
import { UndrawIcon } from 'assets/common';
import { TabIcons } from 'assets/modules/hotelHeader';
import { navigateTo } from 'utilities/Utils';
import { UserProfileViewType } from 'app/hotel/constants';
import { useEffect } from 'react';
import { getCurrentPageName, pushToOmniture } from 'utilities/gtm';
import { Button } from 'connect-web-ui';

import './notfound.scss';

const getRedirectionButtons = COMMON_STRINGS => [
  {
    label: COMMON_STRINGS.LABELS.BOOKINGS,
    icon: TabIcons.BookingImg,
    redirectRoute: routes.bookings.BookingsListBase,
    omnitureModuleName: 'bookings',
  },
  {
    label: COMMON_STRINGS.LABELS.RATES_AND_INVENTORY,
    icon: TabIcons.InventoryImg,
    redirectRoute: routes.inventory.all,
    omnitureModuleName: 'inventory',
  },
  {
    label: COMMON_STRINGS.LABELS.PREBUY_LEDGER,
    icon: TabIcons.PrebuyLedgerImg,
    redirectRoute: routes.prebuyLedger.base,
    omnitureModuleName: 'hc_pbledger',
  },
];

export default function NotFound(props) {
  const { userProfileView } = props;

  const [COMMON_STRINGS] = useLangStrings('Common');

  useEffect(() => {
    if (userProfileView === UserProfileViewType.HOTEL_TRAVEL) {
      pushToOmniture({
        event: 'pageView',
        pageName: `${getCurrentPageName()}_view_does_not_exist`,
      });
    }
  }, []);

  const handleRedirectionButtonClick = button => {
    pushToOmniture({
      event: 'ctaClick',
      cta: {
        name: `${button.omnitureModuleName}_hoteltravel_click`,
        componentName: 'hoteltravel_view_does_not exist',
      },
      pageName: `${getCurrentPageName()}_view_does_not_exist`,
    });

    navigateTo(button.redirectRoute);
  };

  return (
    <div className="wrap-404-inner">
      {userProfileView !== UserProfileViewType.HOTEL_TRAVEL ? (
        <>
          <img
            style={{ filter: 'invert(100%)' }}
            src="https://gos3.ibcdn.com/404img-1561450536.png"
            alt="404 error"
            width="650px"
          />
          <p>{COMMON_STRINGS.MESSAGES.PAGE_TEMPORARILY_UNAVAILABLE}</p>
          <Button onClick={() => navigateTo(routes.dashboard.base)}>
            {COMMON_STRINGS.LABELS.RETURN_TO_DASHBOARD}
          </Button>
        </>
      ) : (
        <div className="new-sr-container items-center justify-center">
          <UndrawIcon />
          <div className="text-[28px] font-[700] mt-8 mb-6">
            {COMMON_STRINGS.MESSAGES.HOTEL_TRAVEL_DOESNOT_HAVE_THIS_VIEW_YET}
          </div>
          <div className="mb-4">
            {COMMON_STRINGS.MESSAGES.PLEASE_SELECT_AN_OPTION_FROM_BELOW}
          </div>
          <div className="flex flex-row">
            {getRedirectionButtons(COMMON_STRINGS).map(button => {
              return (
                <Button
                  onClick={() => handleRedirectionButtonClick(button)}
                  className="mx-3 w-48"
                  IconComp={button.icon}
                  variant="outlined"
                  size="small"
                >
                  {button.label}
                </Button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
