// @ts-nocheck
import { doMutation, doQuery } from '../../base/GraphQLClient';
import {
  COMBINED_BOOKING_DETAILS,
  VIEW_GI_BOOKING_VOUCHER,
  VIEW_MMT_BOOKING_VOUCHER,
  RESEND_GI_BOOKING_VOUCHER,
  RESEND_MMT_BOOKING_VOUCHER,
  GET_REJECT_REASONS,
  HOTEL_BOOKING_STATUS,
  CREATE_NOTE,
  UPDATE_NOTE,
  UPDATE_BOOKING_STATUS,
  GET_WAIVER_DETAILS,
  INITIATE_WAIVER,
  BOOKING_VOUCHER_DETAILS,
  OPPORTUNITIES_QUERY,
  BOOKING_VOUCHER_EMAIL_DATA,
  HOURLY_ROOM_CONFIG_QUERY,
  GET_PENDING_TASKS_VIEW,
  BOOKINGS_LIST_DATA_MUTATION,
  GET_PERSUASION_BOOKING_NETAMOUNT,
  ANALYTICS_DOCS_QUERY,
  CONTACT_LIST_DATA_QUERY,
  PAYMENT_DETAILS_QUERY,
  PAYMENT_DETAILS_QUERY_VCC,
  GET_FRN_DETAILS_QUERY,
  RESEND_BOOKING_VOUCHER,
  VIEW_VOUCHER,
} from './BookingsGraphQuery';
import { Note } from './L2/bookingdetails.types';
import { getFormattedDate, getFutureDate } from 'utilities/DateUtils';
import { routes } from 'app/routes';
import {
  BOOKING_AMEND_TYPE,
  CONFIRM_STATUS,
  filteredDataNodeMap,
  filterQueryMap,
  upcomingPastMap,
} from './bookings.constants';
import {
  generateCorrelationKey,
  getValue,
  isNullOrUndefined,
  roundOff,
} from 'utilities/Utils';
import { BookingDataType, FilterFieldsType } from './bookings.types';
import { apiDateFormat } from '../../constants';
import { hourlyApiFormatMap } from './L1/sections/filter/filter.fields';
import { formatBookingIds } from './bookings.common';
import { v4 as generateUuidKey } from 'uuid';
import SENTRY from '../../utilities/Sentry';

const contextHeaders = {
  isAdtechCall: true,

  requestId: generateCorrelationKey(),
};
const _contextHeaders = {
  requestId: generateCorrelationKey(),
};

export const getBookingVoucherEmailData = (bookingId: string) => {
  const query = BOOKING_VOUCHER_EMAIL_DATA;
  const variables = {
    endpointRequests: {
      fetchBookingVoucherRequests: {
        fetchBookingVoucher: {
          urlParams: {
            bookingId: bookingId,
          },
        },
      },
    },
    businessLogicData: {
      bookingRevampInput: {
        onlyFetchBookingVoucher: true,
      },
    },
  };
  return doQuery(query, variables).then(
    res =>
      res?.data?.bookingRevamp?.groupedBookingDetails?.bookingVoucherDetails,
  );
};
const getBookingDetailsVaraibles = (
  bookingId: string,
  parentVendorBookingId: string,
  isMultiRoom: boolean | string,
  bookingDate: string,
  hotelCodes: [string],
  userId: string,
) => {
  const splitBookingId = bookingId?.split(',')?.sort()[0];
  return {
    endpointRequests: {
      fetchCustomerDetailsRequests: {
        fetchCustomerDetails: {
          urlParams: {
            bookingId: bookingId,
          },
        },
      },
      getHotelNotesRequests: {
        getHotelNotes: {
          queryParams: {
            related_to: 'booking',
            start_date: [bookingDate],
            end_date: [bookingDate],
            related_code: splitBookingId,
            filter_by_source: 'ingo_app',
            isactive: true,
          },
        },
      },
      moPaymentDetailsRequests: {
        moPaymentDetails: {
          urlParams: {
            bookingId: splitBookingId,
          },

          queryParams: {
            hotelcode: hotelCodes[0],
          },
        },
      },
      repeatCustomerInfoRequest: {
        repeatCustomerInfo: {
          body: {
            category: 'Histogram',
            property: ['bookings'],
            start_date: getFormattedDate(
              new Date(new Date().setFullYear(new Date().getFullYear() - 4)),
              apiDateFormat,
            ),
            end_date: getFormattedDate(new Date(), apiDateFormat),
            brand: ['all'],
            filter: {
              'user.signedInUserId': userId,
              confirmStatus: ['2'],
            },
            exclude: {
              'user.signedInUserId': '',
            },
            interval_type: 'month',
            pipeline: 'Transaction',
            date_type: 'stay',
            hotel_codes: hotelCodes,
          },
        },
      },
      fetchBookingVoucherRequests: {
        fetchBookingVoucher: {
          urlParams: {
            bookingId: bookingId,
          },
        },
      },
      getBookingDetailsV3Requests: {
        getBookingDetailsV3: {
          urlParams: {
            bookingId: isMultiRoom ? parentVendorBookingId : bookingId,
          },
          ...(isMultiRoom
            ? {
              queryParams: {
                is_multi_room: isMultiRoom,
              },
            }
            : null),
        },
      },
    },
  };
};

export function getCombinedBookingDetails(
  bookingId: string,
  parentVendorBookingId: string,
  isMultiRoom: boolean | string,
  bookingDate: string,
  hotelCodes: [string],
  userId: string,
) {
  const query = COMBINED_BOOKING_DETAILS;
  const variables = getBookingDetailsVaraibles(
    bookingId,
    parentVendorBookingId,
    isMultiRoom,
    bookingDate,
    hotelCodes,
    userId,
  );
  return doQuery(query, variables).then(
    res => res.data.bookingRevamp.groupedBookingDetails,
  );
}

export function getBookingVoucherDetails(bookingId: string) {
  const query = BOOKING_VOUCHER_DETAILS;
  const variables = {
    endpointRequests: {
      fetchBookingVoucherRequests: {
        fetchBookingVoucher: {
          urlParams: {
            bookingId: bookingId,
          },
        },
      },
    },
  };
  return doQuery(query, variables);
}

export function viewGIBookingVoucher(bookingId: string, hotelCode: string) {
  const query = VIEW_GI_BOOKING_VOUCHER;
  const variables = {
    endpointRequests: {
      viewGIBookingVoucher: {
        modelType: 'modelType1',
        urlParams: {
          bookingId: bookingId,
        },
        queryParams: {
          hotelcode: hotelCode,
          download: true,
        },
      },
    },
  };
  return doQuery(query, variables);
}

export function viewMMTBookingVoucher(bookingId: string, hotelCode: string) {
  const query = VIEW_MMT_BOOKING_VOUCHER;
  const variables = {
    endpointRequests: {
      viewMMTBookingVoucher: {
        modelType: 'modelType1',
        urlParams: {
          bookingId: bookingId,
        },
        queryParams: {
          hotelcode: hotelCode,
          download: true,
        },
      },
    },
  };
  return doQuery(query, variables).then(
    res => res.data?.rawEndpoint?.viewMMTBookingVoucher?.modelType1,
  );
}

export function resendGIBookingVoucher(
  bookingId: string,
  emails: string[],
  type: string,
) {
  const mutation = RESEND_GI_BOOKING_VOUCHER;
  const variables = {
    endpointRequests: {
      resendGIBookingVoucher: {
        modelType: 'modelType1',
        urlParams: {
          bookingId: bookingId,
        },
        body: {
          emailid: emails,
          type: type,
        },
      },
    },
  };
  return doMutation(mutation, variables).then(
    res => res.data?.rawEndpoint?.resendGIBookingVoucher?.modelType1,
  );
}

export function resendMMTBookingVoucher(
  bookingId: string,
  hotelCode: string,
  emails: string[],
) {
  const mutation = RESEND_MMT_BOOKING_VOUCHER;
  const variables = {
    endpointRequests: {
      resendMMTBookingVoucher: {
        modelType: 'modelType1',
        queryParams: {
          hotelcode: hotelCode,
          bookingid: bookingId,
        },
        body: {
          emailIds: emails,
          type: 'booking',
        },
      },
    },
  };
  return doMutation(mutation, variables).then(
    res => res.data?.rawEndpoint?.resendMMTBookingVoucher?.modelType1,
  );
}

export function resendBookingVoucher(
  confirmBookingId: string,
  hotelCode: string,
  emails: string[],
  parentVendorBookingId: string,
  isMultiRoom: boolean,
  vendorBookingid: string,
  isDirectConnectHotel: boolean,
  amendType?: string,
) {
  const newConfirmBookingId = confirmBookingId
    ?.split(',')
    .map(item => item.trim())
    .filter(item => item);

  // Split the first element by comma and trim any extra spaces
  const mutation = RESEND_BOOKING_VOUCHER;

  const variables = {
    rawEndpointRequestData: {
      rpcResendVoucher: {
        modelType: 'modelType1',

        body: {
          hotelCode: hotelCode,
          confirmBookingId: newConfirmBookingId,
          vendorBookingId:
            amendType === BOOKING_AMEND_TYPE.REBOOKING || isMultiRoom
              ? parentVendorBookingId
              : vendorBookingid,
          isNonIngoBooking: isDirectConnectHotel,
          receiverEmailId: emails,
        },
      },
    },
  };
  return doMutation(mutation, variables, {
    headers: {
      ..._contextHeaders,
    },
  }).then(res => res.data?.rawEndpoint?.rpcResendVoucherResponse);
}

export function viewVoucher(
  confirmBookingId: string,
  hotelCode: string,
  parentVendorBookingId: string,
  isMultiRoom: boolean,
  vendorBookingid: string,
  isDirectConnectHotel: boolean,
  brand: string,
  amendType?: string,
) {
  const mutation = VIEW_VOUCHER;
  const newConfirmBookingId = confirmBookingId
    .split(',')
    .map(item => item.trim())
    .filter(item => item);

  const variables = {
    rawEndpointRequestData: {
      rpcViewAndDownloadVoucher: {
        modelType: 'modelType1',
        body: {
          hotelCode: hotelCode,
          confirmBookingId: newConfirmBookingId,
          vendorBookingId:
            amendType === BOOKING_AMEND_TYPE.REBOOKING || isMultiRoom
              ? parentVendorBookingId
              : vendorBookingid,
          isNonIngoBooking: isDirectConnectHotel,
          viewType: [0],
        },
      },
    },
  };

  return doMutation(mutation, variables, {
    headers: { ..._contextHeaders },
  }).then(res => {
    const response = res.data?.rawEndpoint?.rpcViewAndDownloadVoucherResponse;
    if (response?.success) {
      const key =
        brand === 'GI' || brand === 'Goibibo'
          ? confirmBookingId
          : amendType === BOOKING_AMEND_TYPE.REBOOKING || isMultiRoom
            ? parentVendorBookingId
            : vendorBookingid;

      const firstKey = Object.keys(response.voucherData)[0];

      const keys = key?.split(',');
      const voucherData = {};
      keys.forEach(k => {
        const data = response.voucherData?.[k];
        if (data) {
          voucherData[k] = data;
        } else if (!data && firstKey) {
          // Fall back to the first key if data is not available
          voucherData[k] = response.voucherData[firstKey];
        }
      });
      if (Object.keys(voucherData).length > 0) {
        return {
          success: true,
          voucherData: voucherData,
        };
      }

      throw new Error('Voucher not found');
    } else {
      const errorMessage = response.message || 'Failed to fetch voucher data';
      throw new Error(`${errorMessage}`);
    }
  });
}

export function getRejectReasons(confirmStatus: string) {
  const query = GET_REJECT_REASONS;
  const variables = {
    endpointRequests: {
      getBookingStatusReasons: {
        modelType: 'modelType1',
        queryParams: {
          type: 'ingo_express',
          status: confirmStatus,
        },
      },
    },
  };
  return doQuery(query, variables).then(res => {
    const reasons: object =
      res.data?.rawEndpoint?.getBookingStatusReasons?.modelType1?.reasons ?? [];
    return reasons;
  });
}

export function saveRejectReason(
  bookingId: string,
  parentVendorBookingId: string,
  isMultiRoom: boolean,
  hotelCode: string,
  source: string,
  reason: string,
) {
  const mutation = HOTEL_BOOKING_STATUS;
  const variables = {
    endpointRequests: {
      updateHotelBookingStatus: {
        modelType: 'modelType1',
        urlParams: {
          booking_id: bookingId,
        },
        body: {
          hotelcode: hotelCode,
          booking_id: !isMultiRoom ? bookingId : '',
          vendor_parent_booking_id: isMultiRoom ? parentVendorBookingId : '',
          has_multi_room: isMultiRoom,
          status: 'rejected',
          source: source,
          reason: reason,
        },
      },
    },
  };
  return doMutation(mutation, variables).then(
    res => res.data?.rawEndpoint?.hotelBookingStatusUpdateResponse?.modelType1,
  );
}

export function createNote(bookingId: string, note: Note) {
  const mutation = CREATE_NOTE;
  const variables = {
    rawEndpointsRequestData: {
      hostAppCreateNote: {
        modelType: 'modelType1',
        body: {
          related_to: 'booking',
          related_code: bookingId,
          notes: [
            {
              text: note.text,
              start_date: note.startDate,
              end_date: note.endDate,
              source: 'ingo_app',
              misc: {},
            },
          ],
        },
      },
    },
  };
  return doMutation(mutation, variables).then(
    res => res.data?.rawEndpoint?.hostAppCreateNote?.modelType1,
  );
}

export function updateNote(note: Note) {
  const mutation = UPDATE_NOTE;
  const variables = {
    rawEndpointsRequestData: {
      hostAppUpdateNote: {
        modelType: 'modelType1',
        body: {
          notes: [
            {
              note_id: note.id,
              text: note.text,
            },
          ],
        },
      },
    },
  };
  return doMutation(mutation, variables).then(
    res => res.data?.rawEndpoint?.hostAppUpdateNote?.modelType1,
  );
}

const CALENDAR_LISTINGS_FIELDS = [
  'hotelId',
  'checkin',
  'checkout',
  'user',
  'totalOccupancy',
  'roomName',
  'noOfRooms',
  'bookingId',
  'parentVendorBookingId',
  'salesCategory',
  'salesChannelName',
  'paymentBreakup',
  'netAmount',
  'netTaxes',
  'mealPlan',
  'mealPlanValue',
  'createdOn',
  'payModeValue',
  'vendorBookingId',
  'customerType',
  'specialRequests',
  'currency',
  'isExpressCheckinEnabled',
  'freeCancellationDate',
  'isMultiRoom',
  'bookingAutoConfirm',
  'confirmStatusValue',
  'cancelChargesToHotel',
  'cancelCharges',
  'rejectReasonValue',
  'isRefundRequest',
  'mealPlanValue',
  'isCheckedIn',
  'creditCardCheckCo',
  'isVccBooking',
  'collectionAmount',
  'bookingNote',
  'refundAmountFromHotel',
  'refundAmount',
  'checkinHour',
  'checkoutHour',
  'gstInfo',
  'vendorId',
  'isCheckedIn',
  'isSameHotel',
  'rejectReason',
  'expressCheckinStatus',
  'isExpressCheckin',
  'hotelEarnings',
  'houseKeepingTime',
  'virtualRoomCodes',
  'isDayUseBooking',
  'gstnInvoiceReq',
  'isRTBRequest',
  'refundRequestStatus',
  'isGroupBooking',
  'isFrnBooking',
  'frnCode',
  'cancelBookingTime',
  'upgradeInfo',
  'resellerBookingId',
  'prebuyOrderId',
  'oldVendorBookingId',
  'amendType',
];

export const getTimelineViewData = (data, setShowBookingDetails) => {
  if (!data) return {};
  return Object.keys(data).reduce((acc, curr) => {
    const dayUseBookings: BookingDataType[] = [];
    const normalBookings: BookingDataType[] = [];
    data[curr]?.docs?.forEach(booking => {
      if (booking.confirmStatusValue !== CONFIRM_STATUS.CANCELLED) {
        if (booking.isDayUseBooking) {
          dayUseBookings.push(booking);
        } else {
          normalBookings.push(booking);
        }
      }
    });

    const bookings = [];
    const virtualRoomCodes = [];
    dayUseBookings.forEach(bookingDetail => {
      // in most of the cases we will get one virtualRoomcodes but sometime there will be multiple virtualRoomcodes. that's why iterating through virtualRoomCodes
      bookingDetail.virtualRoomCodes.forEach(virtualRoomCode => {
        const bookingDetailsProps = {
          bookingId: bookingDetail.bookingId,
          parentVendorBookingId: bookingDetail.parentVendorBookingId,
          isMultiRoom: bookingDetail.isMultiRoom,
          bookingDate: getFormattedDate(bookingDetail.createdOn, apiDateFormat),
          hotelCode: bookingDetail.hotelId,
          userId: bookingDetail.user.signedInUserId,
          salesChannel: bookingDetail.salesChannelName,
        };

        if (!virtualRoomCodes.includes(virtualRoomCode)) {
          virtualRoomCodes.push(virtualRoomCode);
        }

        const checkOutTime =
          bookingDetail.checkoutHour === '00:00:00'
            ? '24:00:00'
            : bookingDetail.checkoutHour;

        bookings.push({
          booking_id: bookingDetail.bookingId,
          checkin_time: bookingDetail.checkinHour,
          checkout_time: checkOutTime,
          guest_count: bookingDetail.totalOccupancy,
          guest_name: bookingDetail.user.name,
          housekeeping_time: bookingDetail.houseKeepingTime,
          sell_amount: bookingDetail.paymentBreakup.sellingPrice,
          virtual_room_code: virtualRoomCode,
          onClick: () => setShowBookingDetails(bookingDetailsProps),
        });
      });
    });

    const rooms = virtualRoomCodes.map(code => ({
      is_blocked: false,
      virtual_room_code: code,
    }));
    const bookingTimelineData = {
      response: {
        bookings,
        rooms,
      },
      date: new Date(curr),
      config: {
        firstCheckIn: null,
        lastCheckOut: null,
        houseKeepingTime: null,
        isMultibooking: null,
      },
      timelineType: 'bookings',
      normalBookingsInfo: {
        checkIns: normalBookings.length,
      },
    };
    acc[curr] = bookingTimelineData;
    return acc;
  }, {});
};

export const getOpportunitiesData = (
  params,
  isGBEnrolled = false,
  isInsights = false,
) => {
  const { date, hotelcode, voyagerId, mmtId, channelType, currency } = params;
  const variables = {
    endpointRequests: {
      GetOpportunityFlagsBookingV3APIRequests: {
        modelType: 'modelType1',
        queryParams: {
          hotelcode,
          channel_type: channelType?.toLowerCase(),
          date,
        },
      },
    },
  };

  if (!isGBEnrolled) {
    variables.endpointRequests.analyticsPersuasion = {
      modelType: 'modelType1',
      queryParams: {
        type: 'group_booking',
        size: 10,
        consumers: ['web'],
        sub_type: 'enrollment',
        offset: 0,
        entity_id: hotelcode,
      },
    };
  }

  if (isInsights) {
    variables.endpointRequests.sponsoredList = {
      modelType: 'modelType1',
      endPointId: 1,
      urlParams: {
        hotelCode: hotelcode,
      },
      body: {
        entityIds: [
          {
            id: mmtId,
            idType: 'MMT',
          },
          {
            id: voyagerId,
            idType: 'GI',
          },
          {
            id: hotelcode,
            idType: 'INGO',
          },
        ],
        requestingLob: 'INGO',
        requestId: 'tst1234',
        pageType: 'BOOKING',
      },
    };
  }

  return doQuery(OPPORTUNITIES_QUERY, variables, {
    headers: {
      ...contextHeaders,
      currency: currency,
    },
    useLoader: false,
  }).then(res => {
    const opportunitiesList = getValue(
      res,
      'data.rawEndpoint.GetOpportunityFlagsBookingV3APIResponses.modelType1.data.opportunities',
      {},
    );

    const slData = getValue(
      res,
      'data.rawEndpoint.sponsoredList.modelType1.persuasionList[0]',
      null,
    );

    const rawData = getValue(
      res,
      'data.rawEndpoint.analyticsPersuasion.modelType1.data.persuasions[0].consumers.web',
      null,
    );

    const rawObj = {};
    if (rawData) {
      rawObj.group_booking_persuasion = {
        title: rawData.persuasion_title,
        desc: rawData.message,
        ctaName: 'Enroll Now',
        redirectionUrl: routes.groupBookings.base,
      };
    }
    if (slData) {
      rawObj.sponsored_listing_persuasion = {
        key: 'SL',
        data: slData,
        title: slData?.revenueGraph?.text ?? 'Sponsored Listing',
        desc: slData?.persuasion?.htmlContent,
        ctaName: slData?.persuasion?.cta?.action,
        redirectionUrl: slData?.persuasion?.cta?.redirectTxt,
      };
    }
    return { opportunitiesList, rawObj };
  });
};

const processCustomerDetails = data => {
  let res = [];
  if (data && data.length > 0) {
    data.forEach(obj => {
      res = res.concat(obj.data);
    });
  }

  return res;
};

const getCommonParamsForPendingTasks = (
  hotelId: string,
  channel: string,
  type: string,
) => {
  const today = getFormattedDate(new Date(), apiDateFormat);
  const body = {
    pipeline: 'transactions',
    size: 100,
    fields: ['bookingId', 'checkin', 'vendorId'],
    group_by: ['parentVendorBookingId'],
    sort: {
      checkin: 'asc',
    },
    include: {
      hotelId: [hotelId],
      ...(type === 'unAcknowledged' ? { bookingAutoConfirm: false } : null),
      ...(channel === 'Corporate' ? { salesCategory: 'Corporate' } : null),
    },
    exclude: {
      ...(type !== 'unAcknowledged'
        ? { confirmStatusValue: 'cancelled', confirmStatus: 7 }
        : null),
      bookingId: null,
    },
  };
  switch (type) {
    case 'unAcknowledged':
      body.include = {
        ...body.include,
        confirmStatusValue: 'pending',
        checkin: {
          gte: today,
        },
      };
      break;
    case 'refunds':
      body.include = {
        ...body.include,
        isRefundRequest: true,
        refundRequestStatus: 'pending',
        checkout: {
          gte: getFormattedDate(getFutureDate(new Date(), -2), apiDateFormat),
        },
      };
      break;
    case 'invoices':
      body.include = {
        ...body.include,
        'gstInfo.gstInvoiceStatus': ['pending'],
        checkin: {
          gte: getFormattedDate(getFutureDate(new Date(), -365), apiDateFormat),
        },
        checkout: {
          lte: today,
        },
        gstnInvoiceReq: true,
      };
      break;
    case 'rejected_invoices':
      body.include = {
        ...body.include,
        'gstInfo.gstInvoiceStatus': ['rejected'],
        checkin: {
          gte: getFormattedDate(getFutureDate(new Date(), -365), apiDateFormat),
        },
        checkout: {
          lte: today,
        },
        gstnInvoiceReq: true,
      };
      break;
    case 'noshow_checkin':
      body.include = {
        ...body.include,
        checkin: {
          lte: today,
        },
        checkout: {
          gte: getFormattedDate(getFutureDate(new Date(), -7), apiDateFormat),
        },
        payModeValue: ['PAH', 'PAH_WITH_CC', 'PAH_WITHOUT_CC', 'PAHX'],
        confirmStatusValue: ['pending', 'confirmed'],
        isCheckedIn: false,
      };
      break;
    default:
      break;
  }
  return body;
};

const getPendingTasksVariables = (hotelId, channel, mmt_id) => {
  return {
    endpointRequests: {
      analyticsDocumentsAPIV2Requests: {
        getUnAcknowledgedAnalyticsDocumentV2: {
          body: getCommonParamsForPendingTasks(
            hotelId,
            channel,
            'unAcknowledged',
          ),
        },
        getRefundsAnalyticsDocumentV2: {
          body: getCommonParamsForPendingTasks(hotelId, channel, 'refunds'),
        },
        getInvoicesAnalyticsDocumentV2: {
          body: getCommonParamsForPendingTasks(hotelId, channel, 'invoices'),
        },
        getRejectedInvoicesAnalyticsDocumentV2: {
          body: getCommonParamsForPendingTasks(
            hotelId,
            channel,
            'rejected_invoices',
          ),
        },
        getCheckinOrNoShowHTTPAnalyticsDocumentV2: {
          body: getCommonParamsForPendingTasks(
            hotelId,
            channel,
            'noshow_checkin',
          ),
        },
      },
    },
    getShortLinkDataRawEndpointRequestData: {
      getShortLinkData: {
        endPointId: 99,
        modelType: 'modelType1',
        body: {
          hotelId: mmt_id,
        },
      },
    },
  };
};

const getFilterViewVariables = (
  filterkey: string,
  hotelCode: string,
  filterState: FilterFieldsType,
) => {
  const body = {
    pipeline: 'transactions',
    fields: [
      'bookingId',
      'vendorId',
      'isDayUseBooking',
      'isGroupBooking',
      'isRTBRequest',
      'payModeValue',
      'salesChannelName',
      'salesCategory',
      'gstnInvoiceReq',
    ],
    include: {
      hotelId: [hotelCode],
      confirmStatusValue: filterState.confirmStatusValue,
      ...(filterState.salesCategory === 'Corporate'
        ? { salesCategory: 'Corporate' }
        : null),
      ...(filterState.payModeValue.length > 0
        ? {
          payModeValue: filterState.payModeValue
            .map(x => x.split(','))
            .flat(1),
        }
        : null),
      ...(filterState.gstInvoiceStatus
        ? {
          'gstInfo.gstInvoiceStatus': [
            ...(filterState?.gstInvoiceStatus?.split(',') || {}),
          ],
        }
        : null),
      ...(filterState.vendorId.length > 0
        ? {
          vendorId: filterState.vendorId
            .flat()
            .map(item => (typeof item === 'string' ? item : null))
            .filter(item => !isNullOrUndefined(item))
            .map(item => item.split(','))
            .flat(),
        }
        : []),
      ...(filterState.hourlyOrGroupOrUrlOrRTBbooking
        ? {
          [hourlyApiFormatMap[filterState.hourlyOrGroupOrUrlOrRTBbooking]]:
              true,
        }
        : null),
    },

    exclude: {
      'rtbInfo.rtbStatus': ['pending', 'rejected', 'expired'], // Excluding RTB Non Confirmed Bookings
      bookingId: null,
    },
    group_by: ['parentVendorBookingId'],
  };

  if (filterState?.paymentStatus === 'pending') {
    body.exclude.isFrnBooking = true;
  }

  const isPastSelected = filterState.upcomingOrPast === upcomingPastMap.PAST;
  const isUpcomingSelected =
    filterState.upcomingOrPast === upcomingPastMap.UPCOMING;

  const endDate = filterState.dateRange.end;
  const isEndDateValid = endDate && !isNaN(new Date(endDate).getTime());
  const fromDate = isPastSelected
    ? getFormattedDate(getFutureDate(new Date(), -30), apiDateFormat)
    : isUpcomingSelected
      ? getFormattedDate(new Date(), apiDateFormat)
      : filterState.dateRange.start;

  const toDate = isUpcomingSelected
    ? getFormattedDate(getFutureDate(new Date(), 90), apiDateFormat)
    : isPastSelected
      ? getFormattedDate(getFutureDate(new Date(), -1), apiDateFormat)
      : isEndDateValid
        ? getFormattedDate(new Date(endDate), apiDateFormat)
        : fromDate;

  const dateRangeObj = {
    gte: fromDate,
    lte: toDate,
  };

  const queryFormat = {
    endpointRequests: {
      analyticsDocumentsAPIV2Requests: {},
      ...(filterState.paymentStatus
        ? {
          mojoBookingDetailsAPIRequest: {
            getPaymentStatusFilteredBookingsID: {
              body: {
                paymentStatus: [filterState.paymentStatus],
                hotelId: [hotelCode],
                confirmStatusValue: [filterState.confirmStatusValue],
                vendorId: body.include.vendorId,
              },
            },
          },
        }
        : null),
    },
  };

  switch (filterkey) {
    case 'Corporate_checkin':
      body.fields.push('checkin');
      body.sort = {
        checkin: 'asc',
      };
      body.include.checkin = dateRangeObj;
      queryFormat.endpointRequests.analyticsDocumentsAPIV2Requests = {
        getCorporateCheckinDateWise: {
          body: body,
        },
      };
      if (filterState.paymentStatus) {
        queryFormat.endpointRequests.mojoBookingDetailsAPIRequest.getPaymentStatusFilteredBookingsID.body.checkin =
          dateRangeObj;
      }
      break;
    case 'Corporate_checkout':
      body.fields.push('checkout');

      body.sort = {
        checkout: 'asc',
      };
      body.include.checkout = dateRangeObj;
      queryFormat.endpointRequests.analyticsDocumentsAPIV2Requests = {
        getCorporateCheckoutDateWise: {
          body: body,
        },
      };
      if (filterState.paymentStatus) {
        queryFormat.endpointRequests.mojoBookingDetailsAPIRequest.getPaymentStatusFilteredBookingsID.body.checkout =
          dateRangeObj;
      }
      break;
    case 'Corporate_bookingDate':
      body.fields.push('bookingDate');

      body.sort = {
        bookingDate: 'desc',
      };
      body.include.bookingDate = {
        gte: `${dateRangeObj.gte} 00:00:00`,
        lte: `${dateRangeObj.lte} 23:59:59`,
      };
      queryFormat.endpointRequests.analyticsDocumentsAPIV2Requests = {
        getCorporateBookingDateWise: {
          body: body,
        },
      };
      if (filterState.paymentStatus) {
        queryFormat.endpointRequests.mojoBookingDetailsAPIRequest.getPaymentStatusFilteredBookingsID.body.bookingDate =
          {
            gte: `${dateRangeObj.gte} 00:00:00`,
            lte: `${dateRangeObj.lte} 23:59:59`,
          };
      }
      break;
    case 'hourly_booking':
    case 'all_checkin':
      body.fields.push('checkin');

      body.sort = {
        checkin: 'asc',
      };
      body.include.checkin = dateRangeObj;
      queryFormat.endpointRequests.analyticsDocumentsAPIV2Requests = {
        getAllBookingsCheckinDateWise: {
          body: body,
        },
      };
      if (filterState.paymentStatus) {
        queryFormat.endpointRequests.mojoBookingDetailsAPIRequest.getPaymentStatusFilteredBookingsID.body.checkin =
          dateRangeObj;
      }

      break;
    case 'all_checkout':
      body.fields.push('checkout');

      body.sort = {
        checkout: 'asc',
      };
      body.include.checkout = dateRangeObj;
      queryFormat.endpointRequests.analyticsDocumentsAPIV2Requests = {
        getAllBookingsCheckOutDateWise: {
          body: body,
        },
      };
      if (filterState.paymentStatus) {
        queryFormat.endpointRequests.mojoBookingDetailsAPIRequest.getPaymentStatusFilteredBookingsID.body.checkout =
          dateRangeObj;
      }
      break;
    case 'all_bookingDate':
      body.fields.push('bookingDate');

      body.sort = {
        bookingDate: 'desc',
      };
      body.include.bookingDate = {
        gte: `${dateRangeObj.gte} 00:00:00`,
        lte: `${dateRangeObj.lte} 23:59:59`,
      };
      queryFormat.endpointRequests.analyticsDocumentsAPIV2Requests = {
        getAllBookingsDateWise: {
          body: body,
        },
      };
      if (filterState.paymentStatus) {
        queryFormat.endpointRequests.mojoBookingDetailsAPIRequest.getPaymentStatusFilteredBookingsID.body.bookingDate =
          {
            gte: `${dateRangeObj.gte} 00:00:00`,
            lte: `${dateRangeObj.lte} 23:59:59`,
          };
      }
      break;
    default:
      break;
  }
  return queryFormat;
};

export function getFilterData(filterKey, hotelCode, filterState) {
  const query = filterQueryMap[filterKey];
  const variables = getFilterViewVariables(filterKey, hotelCode, filterState);
  return doQuery(query, variables, { useLoader: false }).then(res => {
    return res.data?.bookingRevamp?.getPendingTasks?.[
      filteredDataNodeMap[filterKey]
    ]?.data;
  });
}

export function getPendingTasks(hotelCode: string, channel: string, mmt_id) {
  const query = GET_PENDING_TASKS_VIEW;
  const variables = getPendingTasksVariables(hotelCode, channel, mmt_id);
  return doQuery(query, variables, { useLoader: false }).then(res =>
    formatBookingIds(res.data?.bookingRevamp?.getPendingTasks),
  );
}

export const getSearchData = (hotelcode, autoSuggest, searchKey) => {
  const variables = {
    rawEndpointRequestData: {
      analyticsDocuments: {
        endPointId: 99,
        modelType: 'modelType1',
        body: {
          pipeline: 'transactions',
          fields: ['parentVendorBookingId', 'bookingId', 'vendorId'],
          include: {
            hotelId: [hotelcode],
            autosuggest: autoSuggest,
            ...(searchKey === 'search_name'
              ? {
                confirmStatusValue: [
                  'confirmed',
                  'cancelled',
                  'noshow',
                  'pending',
                  'alternate',
                  'rejected',
                  'amended',
                  'alternate_cancelled',
                ],
              }
              : null),
          },
          sort: {
            checkin: 'asc',
          },
          group_by: ['parentVendorBookingId'],
        },
      },
    },
  };

  return doQuery(ANALYTICS_DOCS_QUERY, variables).then(res => {
    const result = res?.data?.rawEndpoint?.analyticsDocuments?.modelType1?.data;
    delete result?.doc_count;
    delete result?.group_count;
    return result;
  });
};

const getL1BookingCardVariables = (type, params, paymentStatus) => {
  const {
    hotelcode,
    parentIds = [],
    dateRange = {
      start: getFormattedDate(new Date(), 'YYYY-MM-DD'),
      end: getFormattedDate(getFutureDate(new Date(), 7), apiDateFormat),
    },
  } = params;
  const body = {
    pipeline: 'transactions',
    fields: CALENDAR_LISTINGS_FIELDS,
    include: {},
  };

  if (paymentStatus === 'pending') {
    body.exclude = {
      isFrnBooking: true,
    };
  }

  const queryFormat = {
    rawEndpointRequestData: {
      bookingListingGeneric: {
        endPointId: 99,
        modelType: 'modelType1',
      },
    },
  };

  switch (type) {
    case 'search_name':
    case 'search_id':
      body.include = {
        hotelId: [hotelcode],
        parentVendorBookingId: parentIds,
      };
      body.sort = {
        checkin: 'asc',
      };
      break;
    case 'hourly_booking':
    case 'checkin':
      body.include = {
        hotelId: [hotelcode],
        checkin: {
          gte: dateRange.start,
          lte: dateRange.end,
        },
        parentVendorBookingId: parentIds,
      };
      body.intervals = {
        field: 'checkin',
        type: 'day',
      };
      break;
    case 'checkout':
      body.include = {
        checkout: {
          gte: dateRange.start,
          lte: dateRange.end,
        },
        parentVendorBookingId: parentIds,
      };
      body.intervals = {
        field: 'checkout',
        type: 'day',
      };
      break;
    case 'bookingDate':
      body.include = {
        bookingDate: {
          gte: `${dateRange.start?.split(' ')[0]} 00:00:00`,
          lte: `${dateRange.end?.split(' ')[0]} 23:59:59`,
        },
        parentVendorBookingId: parentIds,
      };
      body.intervals = {
        field: 'bookingDate',
        type: 'day',
      };
      break;
    case 'offer_refund':
    case 'pending_bookings':
      body.include = {
        parentVendorBookingId: parentIds,
      };
      break;
    case 'no_show_checkin':
      body.include = {
        parentVendorBookingId: parentIds,
      };
      break;
    case 'invoices':
      body.include = {
        parentVendorBookingId: parentIds,
      };
      break;
    default:
      break;
  }
  queryFormat.rawEndpointRequestData.bookingListingGeneric.body = body;
  return queryFormat;
};

export function getL1BookingCardDetails(
  type,
  params,
  datewise = false,
  paymentStatus?,
) {
  const mutation = BOOKINGS_LIST_DATA_MUTATION;
  const variables = getL1BookingCardVariables(type, params, paymentStatus);

  return doMutation(mutation, variables, { useLoader: false }).then(res => {
    const result = datewise
      ? res?.data?.rawEndpoint?.bookingListingGeneric?.modelType1?.data
      : res?.data?.rawEndpoint?.bookingListingGeneric?.modelType1?.data?.docs;
    if (datewise) {
      delete result?.doc_count;
      delete result?.docs;
      delete result?.group_count;
    }
    return result;
  });
}

const getVariablesForBookingPersuasuionNetAmount = hotelCode => {
  return {
    rawEndpointRequestData: {
      analyticsHistogram: {
        endPointId: 99,
        modelType: 'modelType1',
        body: {
          pipeline: 'transactions',
          include: {
            confirmStatusValue: 'pending',
            checkin: {
              gte: getFormattedDate(new Date(), 'YYYY-MM-DD'),
            },
            hotelId: [hotelCode],
            bookingAutoConfirm: false,
          },
          exclude: {
            bookingId: null,
          },
          KPI: [
            {
              netAmount: 'sum',
            },
          ],
        },
      },
    },
  };
};

export function getPersuasionBookingNetAmount(hotelCode) {
  const query = GET_PERSUASION_BOOKING_NETAMOUNT;
  const variables = getVariablesForBookingPersuasuionNetAmount(hotelCode);
  return doQuery(query, variables).then(
    res => res.data?.rawEndpoint?.analyticsHistogram?.modelType1?.data,
  );
}

export function getPaymentDetails(bookingId) {
  const query = PAYMENT_DETAILS_QUERY;
  const variables = {
    rawEndpointRequestData: {
      MOPaymentDetailsV1: {
        endPointId: 1,
        modelType: 'modelType1',
        urlParams: {
          bookingId,
        },
      },
    },
  };
  return doQuery(query, variables, { useLoader: true }).then(res => {
    return res.data?.rawEndpoint?.MOPaymentDetailsV1?.modelType1?.data;
  });
}

export function getPaymentDetailsForVCC(bookingId) {
  const query = PAYMENT_DETAILS_QUERY_VCC;
  const variables = {
    rawEndpointsRequestData: {
      vccGetRealization: {
        modelType: 'modelType1',
        queryParams: {
          booking_id: bookingId,
        },
      },
    },
  };
  return doQuery(query, variables, { useLoader: true }).then(res => {
    return res.data?.rawEndpoint?.vccGetRealization?.modelType1?.responseData;
  });
}

export function getCustomerDetails(bookingIds) {
  const query = CONTACT_LIST_DATA_QUERY;
  const variables = {
    endpointBusinessLogicData: {
      bookingIDs: bookingIds,
    },
  };
  return doQuery(query, variables, { useLoader: false }).then(res => {
    return processCustomerDetails(
      res.data?.fetchCustomerDetails?.fetchCustomerDetails,
    );
  });
}

export function updateBookingStatus(
  hotelCode: string,
  bookingIds: string[],
  parentVendorBookingIds: string[],
  bookingStatus: string,
  showLoader = false,
) {
  const query = UPDATE_BOOKING_STATUS;
  const variables = {
    endpointRequests: {
      BookingHotelBookingStatusV1: {
        modelType: 'modelType1',
        urlParams: {
          bookingId: bookingIds[0],
        },
        body: {
          hotelcode: hotelCode,
          booking_id: bookingIds.join(','),
          vendor_parent_booking_id: parentVendorBookingIds.join(','),
          has_multi_room: parentVendorBookingIds.length > 0,
          status: bookingStatus,
          source: 'extranet',
          reason: 'auto_confirmed',
        },
      },
    },
  };
  return doQuery(query, variables, { useLoader: showLoader }).then(
    res => res.data?.rawEndpoint?.BookingHotelBookingStatusV1?.modelType1,
  );
}

export function getWaiverDetails(bookingId: string, isMultiRoom: boolean) {
  const query = GET_WAIVER_DETAILS;
  const variables = {
    endpointRequests: {
      BookingWaiverDetaisV1: {
        modelType: 'modelType1',
        queryParams: {
          booking_id: bookingId,
          is_multiroom: isMultiRoom,
          is_vendor_parent_booking_id: isMultiRoom,
        },
      },
    },
  };

  return doQuery(query, variables).then(res => {
    const data = res?.data?.rawEndpoint?.BookingWaiverDetaisV1?.modelType1;
    if (data.success) {
      const { waiver_details: waiverDetails = [] } = data;
      let cancellationCharges = 0;
      let bkgAmt = 0;
      let cancellationPercent = 0;
      let roomDetails = '';
      let waiverLabel = '';
      let waiverReason = '';
      let requestedDaysAgo = '';
      let waiverRequested = '';
      const wavierDocument = [];

      waiverDetails.forEach(item => {
        cancellationCharges += item.cancellation_charges;
        bkgAmt += item.bookingamount;
        waiverLabel = item.waiver_message_map.label;
        waiverReason = item.waiver_message_map.value;
        requestedDaysAgo = item.requested_days_ago;
        waiverRequested = item.waiver_requested;
        item.roomstay.forEach(stayDetail => {
          roomDetails += `${item.roomtypename} ( `;

          if (stayDetail[0] > 0) {
            roomDetails += `${stayDetail[0]} ${
              stayDetail[0] === '1' ? 'adult' : 'adults'
            } `;
          }
          if (stayDetail[1] > 0) {
            roomDetails += `${stayDetail[1]} ${
              stayDetail[1] === '1' ? 'child' : 'children'
            } `;
          }
          roomDetails += '), ';
        });
        item.waiver_document_keys.forEach(doc => wavierDocument.push(doc));
      });

      cancellationPercent = roundOff((cancellationCharges / bkgAmt) * 100, 2);
      roomDetails = roomDetails.substr(0, roomDetails.length - 2);

      return {
        cancellationPercent,
        bkgAmt,
        waiverLabel,
        waiverReason,
        roomDetails,
        wavierDocument,
        requested_days_ago: requestedDaysAgo,
        waiver_requested: waiverRequested,
      };
    }
    return null;
  });
}

export function initiateWaiver(body) {
  const mutation = INITIATE_WAIVER;
  const variables = {
    rawEndpointsRequestData: {
      initiateWaiver: {
        modelType: 'modelType1',
        body: body,
      },
    },
  };
  return doMutation(mutation, variables).then(
    res => res?.data?.rawEndpoint?.initiateWaiver?.modelType1,
  );
}

export const getHourlyRoomConfig = (roomcode: string, hotelcode: string) => {
  const variables = {
    roomConfigVariables: {
      getSlotBookingConfig: {
        endPointId: 1,
        modelType: 'modelType1',
        queryParams: {
          related_to: 'room',
          related_code: roomcode,
        },
      },
    },
    hotelConfigVariables: {
      getSlotBookingConfig: {
        endPointId: 1,
        modelType: 'modelType1',
        queryParams: {
          related_to: 'hotel',
          related_code: hotelcode,
        },
      },
    },
  };
  return doQuery(HOURLY_ROOM_CONFIG_QUERY, variables, {
    useLoader: false,
  })
    .then(({ data, error }) => {
      if (!error) {
        const roomConfig = getValue(
          data.roomConfig,
          'getSlotBookingConfig.modelType1.data',
        );
        const dayuseMultislotEnabled = getValue(
          data.hotelConfig,
          'getSlotBookingConfig.modelType1.data.dayuse_multislot_enabled',
        );
        return {
          ...roomConfig,
          checkout_end:
            roomConfig?.checkout_end === '00:00:00'
              ? '24:00:00'
              : roomConfig?.checkout_end,
          dayuse_multislot_enabled: dayuseMultislotEnabled,
        };
      }
      throw error;
    })
    .catch(error => {
      SENTRY.logError(error);
    });
};

export const getFrnDetails = (mmtID: string | number, frnCode: string) => {
  const variables = {
    endpointRequestData: {
      getFRN: {
        getFrnRequest: {
          body: {
            hotelId: mmtID,
            frnCodeList: [frnCode],
            correlationKey: generateUuidKey(),
          },
        },
      },
    },
  };

  return new Promise((resolve, reject) => {
    doQuery(GET_FRN_DETAILS_QUERY, variables)
      .then(response => {
        const res = getValue(response, 'data.getFRN.frnInfo') || [];
        resolve(res);
      })
      .catch(reject);
  });
};
