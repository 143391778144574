import { Skeleton } from 'connect-web-ui/mui';
import React, { Fragment } from 'react';

export const RightSectionShimmer = () => (
  <div className="bg-color-white shadow-dropShadow p-4 border border-solid border-common-dividerLight w-[320px] rounded-lg">
    {[...new Array(3)].map((_, index) => (
      <Fragment key={index}>
        <Skeleton
          height={'20px'}
          width={'150px'}
          variant="rectangular"
          className="mt-2 rounded"
        />
        <Skeleton
          height={'24px'}
          variant="rectangular"
          className="mt-2 rounded"
        />
        <Skeleton
          height={'24px'}
          variant="rectangular"
          className="mt-2 rounded"
        />
        <Skeleton
          height={'90px'}
          variant="rectangular"
          className="mt-2 rounded"
        />
      </Fragment>
    ))}
  </div>
);
