import { useContext, useEffect, useState } from 'react';
import {
  WhatNewForHost,
  ChangesInListing,
  HostMigrationAgreement,
} from './section';
import { triggerMigration } from './HostWebMigrationAPIClient';
import { showMessage } from 'utilities/Utils';
import { HotelContext } from '../../utilities/Context';
import { useLangStrings } from '../../utilities/CustomHooks';
import { Overlay } from 'connect-web-ui';

import { legacyPushToGTM } from 'utilities/gtm';

const eventCategory = 'web_host_transition';
const mapStepToGTMLabel = {
  0: 'new_in_listing',
  1: 'changes',
  2: 'agreement',
};

type Props = {
  onClose: () => void;
};

const element = document.getElementById('react-portal');
const HostWebMigrationOverlay = (props: Props) => {
  const { onClose } = props;
  const {
    currentHotel: { hotelname: hotelName, hotelcode: hotelCode },
    setCurrentHotel,
  } = useContext(HotelContext);

  const [step, setStep] = useState<number>(1);
  const [showConfirm, setShowConfirm] = useState<boolean>(true);

  const [STRINGS, COMMON_STRINGS] =
    useLangStrings<'HostWebMigration'>('HostWebMigration');

  const migrateProperty = () => {
    triggerMigration(hotelCode)
      .then(response => {
        if (response.data.success) {
          legacyPushToGTM({
            eventCategory,
            eventAction: 'progress',
            eventLabel: 'transition_start_successful',
          });
          setCurrentHotel(hotel => ({
            ...hotel,
            blockedForExtranet: true,
            hostweb_migration: 'pending',
          }));
        } else {
          throw new Error(response.data.error);
        }
      })
      .catch(err => {
        legacyPushToGTM({
          eventCategory,
          eventAction: 'progress',
          eventLabel: 'transition_start_error',
        });
        showMessage({ type: 'error', show: true, message: err.message });
        setCurrentHotel(hotel => ({
          ...hotel,
          blockedForExtranet: true,
          hostweb_migration: 'failed',
        }));
      })
      .finally(() => {
        onClose();
      });
  };

  const onConfirmClick = () => {
    if (step === 3) {
      const eventAction = mapStepToGTMLabel[2];
      legacyPushToGTM({
        eventCategory,
        eventAction,
        eventLabel: 'confirm',
      });
      migrateProperty();
    } else {
      setStep(prevStep => {
        const eventAction = mapStepToGTMLabel[prevStep];
        legacyPushToGTM({
          eventCategory,
          eventAction,
          eventLabel: 'next',
        });
        return prevStep + 1;
      });
    }
  };

  const onCancelClick = () => {
    const eventAction = mapStepToGTMLabel[step];
    legacyPushToGTM({
      eventCategory,
      eventAction,
      eventLabel: 'cancel',
    });
    onClose();
  };

  const onBackClick = () => {
    setStep(prevStep => {
      const eventAction = mapStepToGTMLabel[prevStep];
      legacyPushToGTM({
        eventCategory,
        eventAction,
        eventLabel: 'back',
      });
      return prevStep - 1;
    });
  };

  useEffect(() => {
    const eventAction = mapStepToGTMLabel[step];
    legacyPushToGTM({
      eventCategory,
      eventAction,
      omnitureData: {
        skip: true,
      },
      eventLabel: 'load',
    });
  }, [step]);

  return (
    <Overlay
      domElement={element}
      title={STRINGS.OVERLAY.TITLE}
      subtitle={hotelName}
      onClose={onCancelClick}
      onConfirm={onConfirmClick}
      positiveText={
        step === 3
          ? COMMON_STRINGS.BUTTONS.CONTINUE
          : COMMON_STRINGS.BUTTONS.NEXT
      }
      optionalBtn={step !== 1 && COMMON_STRINGS.BUTTONS.BACK}
      onOptionalClick={onBackClick}
      confirmDisabled={!showConfirm}
      showBrandLogo
    >
      {step === 1 && <WhatNewForHost />}
      {step === 2 && (
        <ChangesInListing
          setShowConfirm={setShowConfirm}
          eventCategory={eventCategory}
          eventAction={mapStepToGTMLabel[step]}
        />
      )}
      {step === 3 && (
        <HostMigrationAgreement
          showConfirm={showConfirm}
          setShowConfirm={setShowConfirm}
          eventCategory={eventCategory}
          eventAction={mapStepToGTMLabel[step]}
        />
      )}
    </Overlay>
  );
};

export default HostWebMigrationOverlay;
