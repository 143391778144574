import { pushToOmniture } from 'utilities/gtm';

export const pushToCtaOmnitureCommon = ({
  ctaName,
  componentName,
  pageName,
  errMsg = '',
}) => {
  pushToOmniture({
    event: 'ctaClick',
    cta: {
      name: ctaName,
      type: 'click',
      componentName,
    },
    loadedComponents: errMsg?.length ? `failure:${errMsg}` : 'success',
    pageName,
  });
};
