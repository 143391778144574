import { useCallback } from 'react';
import { routes } from 'app/routes';
import { navigateTo } from 'utilities/Utils';
import { useLangStrings } from 'utilities/CustomHooks';
import { Button, Typography } from 'connect-web-ui';
import { pushEventToGtm } from 'modules/dashboard/utils/utils';

export const PriorityBookingsSectionSkeleton = props => {
  const { children } = props;
  const [STRINGS, COMMON_STRINGS] = useLangStrings<'Dashboard'>('Dashboard');

  const GTM_EVENT_ACTION = 'new_bookings';

  const viewAllHandler = useCallback(() => {
    pushEventToGtm(GTM_EVENT_ACTION, 'view_all');
    navigateTo(routes.bookings.base);
  }, []);

  return (
    <div data-test-id="dashboard_section_new_bookings">
      <div className=" bg-color-white mb-5 rounded border-solid border-x-[1px] border-y-0 border-[#e1e1e1] shadow-[0_0_10px_rgba(50,50,50,0.17)]">
        <div className="flex justify-between items-center p-4 border-solid border-0 border-b-2 border-divider">
          <Typography variant="subHeadingMedium" className="!font-bold">
            {STRINGS.NEW_BOOKINGS.HEADING}
          </Typography>
          <Button onClick={viewAllHandler}>
            {COMMON_STRINGS.BUTTONS.VIEW_ALL}
          </Button>
        </div>
        <div className="p-5">{children}</div>
      </div>
    </div>
  );
};
