import { getCurrentPageName } from 'utilities/gtm';
import {
  EventTrackingContextSchema,
  PageContextSchema,
  DatasetSchema,
  SearchContextSchema,
} from './pdt.types';
import { PDT_CONSTANTS } from './pdt.constants';

// Refer to the PDT Schema for additional details on the fields.
export function createCommonPayload(): Omit<DatasetSchema, 'event_detail'> {
  const pageContext: PageContextSchema = {
    lob: 'ingo',
    page_name: getCurrentPageName(''),
  };
  const eventTrackingContext: EventTrackingContextSchema = {
    template_id: PDT_CONSTANTS.PDT_SCHEMA_TEMPLATE_ID,
    topic_name: PDT_CONSTANTS.PDT_DATASET_NAME,
    env: import.meta.env.MODE,
  };

  // This object is not required for INGO, but it is a mandatory field for PDT schema.
  const searchContext: SearchContextSchema = {
    to: {
      locus: {
        locus_id: 'NA',
        locus_type: 'NA',
        country: 'NA',
      },
    },
    to_date_time: null,
    from_date_time: null,
    travel_class: 'NA',
    pax: {
      rooms: null,
      count: null,
      details: {
        adult: {
          count: null,
        },
        child: {
          count: null,
          ages: [],
        },
      },
    },
  };

  return {
    page_context: pageContext,
    event_tracking_context: eventTrackingContext,
    search_context: searchContext,
  };
}
