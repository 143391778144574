import chrome from './chrome.svg';
import firefox from './firefox.svg';
import edge from './edge.svg';
import safari from './safari.svg';

// Unsupported browser
export const browsers = {
  chrome,
  firefox,
  edge,
  safari,
};

//Footer
export { default as googlPlayIcon } from './googleplay.png';
export { default as appStoreIcon } from './appstore.png';

//RateRecommendation
export { default as FlatOfferIcon } from './flat-offer-icon.jpg';
export { default as OccupancyIcon } from './ic-occupant.svg';
export { default as DownArrowIcon } from './ic-arrow.svg';

// Report
export { default as MMTLogo } from './mmt.jpg';
export { default as IbiboLogo } from './ibibo.jpg';

//HelpCenter
export { ReactComponent as RaiseATicketIcon } from './raise-a-ticket.svg';
export { default as HelpCentreIcon } from './help-centre.svg';

//Instay
export { default as noIssues } from './no-issues.svg';

//Amenities
export { default as chief } from './chief.svg';

//DemandTrend
export { default as demandTrend } from './ic-demand-trend.svg';

export { default as ContentScore } from './content-score.svg';

export { default as RatingsIcon } from './Rating.webp';

export { default as ChatAnimationIcon } from './Chat.gif';

export { default as CelebrateIconGif } from './celebrate.gif';

export { default as ZeroChat } from './ZeroChat.svg';
