export const CREATE_UPDTAE_PROMOTION_QUERY = `mutation RpcUpsertPromotion ($rawEndpointRequestData: RawEndpointsRequestData) {
    rawEndpoint(endpointRequestData: $rawEndpointRequestData) {
        rpcUpsertPromotion {
            success
            mmtId
            message
            errorCode
            correlationKey
            data {
                success
                errorCode
                message
                offerCode
                segment
            }
        }
    }
}`;
