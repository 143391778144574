import {
  updateBookingStatus,
  getPaymentDetails,
  getPaymentDetailsForVCC,
  resendBookingVoucher,
  viewVoucher,
} from './BookingsGraphClient';
import { downloadBookings } from 'modules/bookings-revamp/BookingsApiClient';
import {
  showMessage,
  downloadFile,
  pushEventToGtm,
  isNullOrUndefined,
  createBlobUrlFromHtml,
} from 'utilities/Utils';
import {
  SALES_CHANNELS,
  VIEW_TYPES,
  paymentStatusMap,
  PersuasionsList,
  STATUS_KEYS,
  upcomingPastMap,
  bookingsFlagsMap,
  DYNAMIC_FILTER_KEYS_MAP,
  PAYMENT_MODE,
  CLEAR_FILTER_THRESHOLD,
  CLEAR_ALL_BUTTON_THRESHOLD,
  BRAND_MAP,
  amendTypeToBookingIdMap,
  dynamicFiltersMap,
} from './bookings.constants';
import {
  getDateDifference,
  getDiffBetweenTimes,
  getFormattedDate,
  isTimeGreater,
  onlyDateComparison,
} from 'utilities/DateUtils';
import {
  BookingDataType,
  InitialIdsFormat,
  ReviewStatusNode,
} from './bookings.types';
import { BookingEvent, BOOKING_EVENT_NAME } from 'utilities/EventBus';
import { FILTER_FIELDS_KEYS } from './L1/sections/filter/filter.fields';
import { ISTRINGS } from 'interfaces/Strings';
import { pushToOmniture } from 'utilities/gtm';
import { BookingDetails } from './L2/bookingdetails.types';

export const isReviewReceived = (reviewData: ReviewStatusNode, isGi) => {
  if (isNullOrUndefined(reviewData)) {
    return false;
  }
  return isGi
    ? reviewData.status === 'approved'
    : reviewData.status === 'pushed';
};

export const clubBookingDates = data => {
  const datesMap = {};
  let docs = [];
  for (const key in data) {
    if (data[key].doc_count > 0) {
      datesMap[key] = data[key].docs.map(doc => doc.parentVendorBookingId);
      docs = [...docs, ...data[key].docs];
    } else {
      datesMap[key] = null;
    }
  }
  return { datesMap, docs };
};

export const filterNullBookingDates = data => {
  const res = {};
  for (const i in data) {
    if (data[i]) {
      res[i] = data[i];
    }
  }
  return res;
};

export const shouldShowRTR = (
  checkinDate,
  checkoutDate,
  reviewData: ReviewStatusNode,
) => {
  const now = new Date();
  const checkinComp = onlyDateComparison(new Date(checkinDate), now);
  const checkoutComp = onlyDateComparison(new Date(checkoutDate), now);
  const checkFourPm =
    checkinComp < 0 ||
    (checkinComp === 0 &&
      isTimeGreater(getFormattedDate(new Date(), 'HH:MM'), '16:00')); //to show RTR when Checkin is on same day and time is more than 4:00 PM
  //Data & checkin/checkout condition
  let canShowRTR = true;
  if (isNullOrUndefined(reviewData) || checkinComp > 0 || checkoutComp < 0) {
    //No Review data or today doesn't lie between checkin/checkout
    canShowRTR = false;
  }

  if (reviewData?.status !== 'created') {
    //Don't show any RTR buttons when status is not created
    canShowRTR = false;
  }

  //Final showRTR
  let showRTR = false;
  if (reviewData?.reviewRequested === false) {
    //If review never requested
    showRTR = true;
  }
  if (
    reviewData?.reviewRequested === true &&
    getDiffBetweenTimes(new Date(reviewData.rtrTimestamp), new Date()) >= 24
  ) {
    //If last request was 24 hrs+ ago
    showRTR = true;
  }
  return { canShowRTR, showRTR, checkFourPm };
};

export const getCorrespondingParentIds = (refBookingIds, bookingIds) => {
  return bookingIds.map(
    bid =>
      refBookingIds.find(x => x.bookingIds.includes(bid))
        ?.parentVendorBookingId,
  );
};

export const acknowledgeNoShowCheckinBookings = params => {
  const {
    hotelcode,
    bookingIds,
    parentVendorBookingIds,
    statusKey,
    onSuccess,
    onFailure,
    onFinallyCb,
    showLoader,
    isL1 = true,
    showGtmFunctionality,
  } = params;
  let gtmAction = 'bookings_';
  let gtmLabel = '';
  switch (statusKey) {
    case STATUS_KEYS.acknowledge:
      gtmAction += 'acknowledge';
      gtmLabel = 'acknowledge';
      break;
    case STATUS_KEYS.checkin:
      gtmAction += 'checkin';
      gtmLabel = 'checkin';
      break;
    case STATUS_KEYS.noshow:
      gtmAction += 'noshow';
      gtmLabel = 'noshow';
      break;
    default:
      break;
  }
  const eventLabel = gtmLabel.concat('_', isL1 ? 'l1' : 'l2');

  pushEventToGtm(
    gtmAction,
    eventLabel,
    showGtmFunctionality ? { functionality: true } : {},
    'web_bookings_calendar',
  );
  updateBookingStatus(
    hotelcode,
    bookingIds,
    parentVendorBookingIds,
    statusKey,
    showLoader ?? false,
  )
    .then(data => {
      if (data?.success) {
        showMessage({
          type: 'success',
          show: true,
          message: data?.message,
        });
        onSuccess?.(bookingIds);
      } else {
        onFailure?.();
        showMessage({
          type: 'error',
          show: true,
          message: data?.message,
        });
      }
    })
    .catch(error => {
      console.error('Error acknowledging bookings', error);
    })
    .finally(() => {
      onFinallyCb?.();
    });
};

export const getDownloadBookings = (
  hotelCode: string,
  bookingIds: string[],
  parentVendorIds: string[],
  STRINGS: ISTRINGS['BookingsRevamp'],
  isL1: boolean = true,
) => {
  const bkgCount = bookingIds.length + parentVendorIds.length;
  pushEventToGtm(
    `booking_${isL1 ? 'l1' : 'l2'}`,
    `download_${bkgCount}`,
    { omnitureData: { skip: false } },
    'web_bookings_calendar',
  );
  downloadBookings(hotelCode, bookingIds, parentVendorIds)
    .then(response => {
      if (bkgCount <= 400) {
        downloadFile(response);
      } else {
        showMessage({
          show: true,
          message: STRINGS.BOOKINGS_DOWNLOAD_MAILED,
          type: 'success',
        });
      }
    })
    .catch(err => {
      pushToOmniture({
        event: 'ctaLoad',
        cta: {
          name: err.message,
          type: 'load',
          misc_id_3: '/api/v1/booking/download-bookings/',
          misc_id_2: err.response?.status ?? '',
          misc_id_1: 'Download Excel',
        },
        pageName: `booking_${isL1 ? 'l1' : 'l2'}`,
      });
      showMessage({
        show: true,
        message: STRINGS.BOOKINGS_DOWNLOAD_FAILED,
        type: 'error',
      });
    });
};

export const openVoucher = async ({
  bookingId,
  hotelCode,
  brand,
  showGtmFunctionality,
  parentVendorBookingId,
  vendorBookingId,
  isDirectConnectHotel,
  isMultiRoom,
  isL1 = false,
  amendType,
  bid = '',
}) => {
  let voucherResponse;
  let url = null;
  try {
    voucherResponse = await viewVoucher(
      bookingId,
      hotelCode,
      parentVendorBookingId,
      isMultiRoom,
      vendorBookingId,
      isDirectConnectHotel,
      brand,
      amendType,
    );

    if (voucherResponse.success) {
      const firstKey = Object.keys(voucherResponse.voucherData)[0];
      url = voucherResponse.voucherData[bid ? bid : firstKey]?.voucherHTML;

      if (!url) {
        console.error('No voucher HTML found for key:', firstKey);
      }
    } else {
      console.error(
        `Error fetching voucher for ${brand}:`,
        voucherResponse.message,
      );
    }

    if (url) {
      const blobUrl = createBlobUrlFromHtml(url);
      if (blobUrl) {
        setTimeout(() => {
          const newTab = window.open(); // Open a blank tab
          if (newTab) {
            newTab.location.href = blobUrl; // Set the location to the Blob URL
            setTimeout(() => {
              URL.revokeObjectURL(blobUrl);
            }, 1000);
          }
        }, 100);
      }
    } else {
      console.error('No URL found to open.');
    }
  } catch (error) {
    showMessage({
      type: 'error',
      message: error.message || 'An unexpected error occurred.',
      show: true,
    });
  }

  // Push event to GTM
  pushEventToGtm(
    'bookings_view_voucher',
    `voucher_${isL1 ? 'l1' : 'l2'}`,
    showGtmFunctionality
      ? { functionality: true, omnitureData: { skip: true } }
      : { omnitureData: { skip: true } },
    'web_bookings_calendar',
  );
};

export const resendVoucher = (
  parentVendorBookingId: string,
  emails: string[],
  hotelCode,
  vendorBookingId,
  isDirectConnectHotel,
  confirmBookingId,
  isMultiRoom,
  isL1?: boolean,
  amendType?: string,
) => {
  let resultPromise: Promise<void> = Promise.reject();

  // Always call resendBookingVoucher with the provided parameters
  resultPromise = resendBookingVoucher(
    confirmBookingId,
    hotelCode,
    emails,
    parentVendorBookingId,
    isMultiRoom,
    vendorBookingId,
    isDirectConnectHotel,
    amendType,
  );
  pushEventToGtm(
    'bookings_send_voucher',
    `voucher_${isL1 ? 'l1' : 'l2'}`,
    {
      omnitureData: {
        event: 'ctaClick',
        name: 'share_voucher',
      },
    },
    'web_bookings_calendar',
  );
  return resultPromise;
};

export const sortDates = (dateArray: Array<string>) => {
  return dateArray.sort((a, b) => getDateDifference(new Date(a), new Date(b)));
};

export const syncDatesMapData = (
  existingMap: Record<string, string[]>,
  currentMap: Record<string, string[]>,
) => {
  const resultMap = { ...existingMap };
  for (const key in currentMap) {
    if (resultMap[key] && resultMap[key].length > 0) {
      resultMap[key] = resultMap[key].concat(currentMap[key]);
    } else {
      resultMap[key] = currentMap[key];
    }
  }
  return resultMap;
};

export const getSlicedDatesMap = (
  datesMap: Record<string, Array<string> | null>,
  lastRenderedDate: string,
) => {
  const resultMap = {};
  for (const key in datesMap) {
    if (key >= lastRenderedDate) {
      resultMap[key] = datesMap[key];
    }
  }
  return resultMap;
};

export const getFirstDateToRender = (
  datesMap: Record<string, Array<string> | null>,
  firstParentId: string,
) => {
  let result = '';
  for (const key in datesMap) {
    if (datesMap[key]) {
      if (datesMap[key].includes(firstParentId)) {
        result = key;
        break;
      }
    }
  }
  return result;
};

export const checkNameorBookingId = (value: string) => {
  const alphaNumericRgx = /^[a-zA-Z0-9]+$/,
    numericRgx = /^[0-9]+$/,
    alphabetRgx = /^[a-zA-Z]+$/;
  if (alphabetRgx.test(value)) {
    return false;
  } else if (alphaNumericRgx.test(value) || numericRgx.test(value)) {
    return true;
  }
  return false;
};

export const getDatesCountMap = (
  data: InitialIdsFormat[],
  bookingDateType: string,
) => {
  return data?.reduce((acc, item) => {
    const key =
      bookingDateType === 'bookingDate' && item[bookingDateType]
        ? item[bookingDateType]?.split(' ')[0]
        : item[bookingDateType];
    if (acc[key]) {
      acc[key].push(item.parentVendorBookingId);
    } else {
      acc[key] = [item.parentVendorBookingId];
    }
    return acc;
  }, {});
};

export const checkPersuasionRedirection = () => {
  const persuasionKeys = Object.keys(PersuasionsList).map(item =>
    item.replace(/_/g, '-').toLowerCase(),
  );
  const routeKeys = window.location.pathname.split('/');
  return persuasionKeys.includes(routeKeys[routeKeys.length - 1])
    ? routeKeys[routeKeys.length - 1].replaceAll('-', '_')
    : null;
};

export const getSegregratedIds = (data: Partial<InitialIdsFormat>[]) => {
  const giVendorIds = [],
    mmtVendorIds = [];
  data.forEach(item => {
    if (item.vendorId === 'Goibibo') {
      giVendorIds.push(item.parentVendorBookingId);
    } else {
      mmtVendorIds.push(item.parentVendorBookingId);
    }
  });
  return { giVendorIds, mmtVendorIds };
};

const checkAndUpdateFlags = (dynamicFilterFlag, doc) => {
  Object.keys(dynamicFilterFlag).forEach(key => {
    if (!dynamicFilterFlag[key]) {
      if (key === DYNAMIC_FILTER_KEYS_MAP.HAS_PAH_BOOKINGS) {
        dynamicFilterFlag[key] =
          doc[dynamicFiltersMap[key]] !== PAYMENT_MODE.PAS;
      } else if (key === DYNAMIC_FILTER_KEYS_MAP.HAS_CORPORATE_BOOKINGS) {
        dynamicFilterFlag[key] =
          doc[dynamicFiltersMap[key]] === SALES_CHANNELS.MYBIZ ||
          doc[dynamicFiltersMap[key]] === SALES_CHANNELS.Q2T;
      } else if (doc[dynamicFiltersMap[key]]) {
        dynamicFilterFlag[key] = true;
      }
    }
  });
};

export const getBookingIds = (
  data,
  bookingDateType: string,
  sortType = 'asc',
  viewType = '',
) => {
  const processedData: Array<InitialIdsFormat> = [];
  const localDynamicFilterFlag = JSON.parse(
    localStorage.getItem('bookingsDynamicFlags'),
  );
  const dynamicFilterFlag = localDynamicFilterFlag ?? bookingsFlagsMap;

  if (data) {
    Object.keys(data).forEach(key => {
      if (key !== '' && key !== 'doc_count' && key !== 'group_count') {
        const tempObj = {
          bookingIds: [],
          parentVendorBookingId: key,
          isMultiRoom: data[key]?.doc_count > 1,
          vendorId: data[key]?.docs?.[0]?.vendorId,
          [bookingDateType]: data[key]?.docs?.[0]?.[bookingDateType],
        };
        (data?.[key]?.docs || []).forEach(doc => {
          const dynamicFilterValues = Object.values(dynamicFilterFlag);
          if (
            dynamicFilterValues.filter(Boolean).length <
              dynamicFilterValues.length &&
            viewType === VIEW_TYPES.FILTER_VIEW
          ) {
            checkAndUpdateFlags(dynamicFilterFlag, doc);
          }
          tempObj.bookingIds.push(doc.bookingId);
        });
        processedData.push(tempObj);
      }
    });
  }

  if (viewType === VIEW_TYPES.FILTER_VIEW) {
    BookingEvent.emit(
      BOOKING_EVENT_NAME.DYNAMIC_FILTER_EVENT,
      dynamicFilterFlag,
    );
    localStorage.setItem(
      'bookingsDynamicFlags',
      JSON.stringify(dynamicFilterFlag),
    );
  }
  return processedData.sort((a, b) => {
    if (a[bookingDateType] > b[bookingDateType])
      return sortType === 'asc' ? 1 : -1;
    if (a[bookingDateType] < b[bookingDateType])
      return sortType === 'asc' ? -1 : 1;
    return 0;
  });
};

export const formatBookingIds = data => {
  return {
    pending_bookings: getBookingIds(data?.unAcknowledged?.data, 'checkin'),
    refund_requests: getBookingIds(data?.refunds?.data, 'checkin'),
    pending_invoices: getBookingIds(data?.invoices?.data, 'checkin'),
    rejected_invoices: getBookingIds(data?.rejectedInvoices?.data, 'checkin'),
    checkin_noshow_bookings: getBookingIds(
      data?.checkinOrNoShow?.data,
      'checkin',
    ),
  };
};

export const checkTooManyFilters = (filterState, isClearAllButton = false) => {
  let filterCount = 0;
  for (const key in filterState) {
    if (Array.isArray(filterState[key])) {
      filterCount += filterState[key].length;
    } else if (
      typeof filterState[key] !== 'object' &&
      key !== FILTER_FIELDS_KEYS.UPCOMING_PAST &&
      filterState[key].length > 0
    ) {
      filterCount++;
    }
  }
  return (
    filterCount >
    (isClearAllButton ? CLEAR_ALL_BUTTON_THRESHOLD : CLEAR_FILTER_THRESHOLD)
  );
};

export const initializeFiters = isResellerView => {
  return {
    vendorId: isResellerView ? [BRAND_MAP.HotelCloud] : [],
    salesCategory: 'all',
    bookingDateType: 'checkin',
    confirmStatusValue: 'confirmed',
    payModeValue: [],
    gstInvoiceStatus: '',
    paymentStatus: '',
    hourlyOrGroupOrUrlOrRTBbooking: '',
    upcomingOrPast: upcomingPastMap.UPCOMING,
    dateRange: {
      start: null,
      end: null,
    },
  };
};
export const getProcessedPaymentDetails = (isVcc, bookingId) => {
  const result = {
    paymentStatus: '',
    netPayable: 0,
    timeStamp: null,
    details: [],
    currency: '',
  };
  const splitBookingId = bookingId?.split(',')?.sort()[0];
  return new Promise((resolve, reject) => {
    if (isVcc) {
      getPaymentDetailsForVCC(bookingId)
        .then(res => {
          result.currency = res.currency;
          result.netPayable = res.totalAmount;
          result.details =
            res?.transactions?.map(item => {
              return {
                amount: item.merchantAmount,
                currency: item.merchantCurrency,
                paymentRefId: '',
                merchantName: item.merchantName,
                status: item.settled ? 'Success' : 'Failure',
              };
            }) ?? [];
          result.timeStamp =
            res?.transactions?.find(item => item.settled)?.transactionTime ??
            '';
          result.paymentStatus =
            res?.transactions?.findIndex(item => item.settled) > -1
              ? paymentStatusMap.PROCESSED
              : paymentStatusMap.PENDING;
          resolve(result);
        })
        .catch(err => {
          reject(err);
        });
    } else {
      getPaymentDetails(splitBookingId)
        .then(res => {
          result.currency = res.currency;
          result.paymentStatus =
            res?.payment_status?.length === 0 ? 'Pending' : res?.payment_status;
          result.netPayable = res.nett_payable;
          result.details =
            res?.details?.map(item => {
              return {
                amount: item.amount,
                currency: item.currency,
                paymentType: item.payment_type,
                paymentRefId: item.ref_id,
                status: item.amount_label ?? 'NA',
                timeStamp: item.timestamp,
              };
            }) ?? [];
          if (res?.details?.length) {
            result.timeStamp = res.details[0].timestamp;
          }
          resolve(result);
        })
        .catch(err => {
          reject(err);
        });
    }
  });
};

export const getAmendedBookingId = (
  bookingDetails: BookingDetails | BookingDataType,
  fallbackKey = 'parentVendorBookingId',
) => {
  const bookingIdKey = amendTypeToBookingIdMap[bookingDetails?.amendType];
  const amendedBookingId =
    bookingDetails?.[bookingIdKey] ?? bookingDetails?.[fallbackKey];

  return amendedBookingId;
};
