import { isProd } from 'base/APIClient';

export const getWhatsNewMetaDataVariables = (
  mmtId: string,
  consumersKey = 'web_dashboard',
) => {
  return {
    rawEndpointRequestData: {
      analyticsDocuments: {
        modelType: 'modelType1',
        endPointId: 1,
        body: {
          pipeline: isProd ? 'persuasions_v2' : 'testing_persuasions_v2',
          fields: {
            include: [
              'type',
              'sub_type',
              'priority',
              'expiry_date',
              'id',
              'title',
              'data',
              `consumers.${consumersKey}`,
            ],
          },
          include: {
            expiry_date: {
              gte: 'now',
            },
            hotelId: [mmtId],
            status: 'unresolved',
            category: 'WHATS NEW CAROUSEL',
          },
          exclude: {
            [`consumers.${consumersKey}`]: null,
          },
          size: 5,
          sort: {
            priority: 'asc',
          },
        },
      },
    },
  };
};
