import React, { useRef, useContext, useMemo, useState } from 'react';
import { Popover, Drawer, Button } from 'connect-web-ui';
import {
  useFlagState,
  useLangStrings,
  useOpenDocumentLink,
} from '../../../../utilities/CustomHooks';
import {
  HotelContext,
  BankDetailsContext,
} from '../../../../utilities/Context';
import { showMessage } from 'utilities/Utils';

import { pushGTMEvent, GTM_ACTIONS } from '../utils';
import { updateDocument } from '../BankDetailsGraphClient';
import * as BankDetails from 'assets/modules/bank-details';

export const DocumentItemStyle = {
  backgroundColor: '#f8f8f8',
  border: '1px solid #eaeaea',
  borderRadius: '3px',
  padding: '10px',
  marginBottom: '10px',
};

const LinkOptionItemStyle = {
  color: '#000',
  fontSize: '15px',
  padding: '18px 12px',
  width: '235px',
  borderBottom: '1px solid #f8f8f8',
  cursor: 'pointer',
};

const LinkOptionsSheet = props => {
  const {
    options = [],
    linkedDocType,
    linkDocument,
    id,
    closeLinkOptions,
  } = props;
  const [active, setActive] = useState(linkedDocType);
  const [STRINGS] = useLangStrings('Property');

  const handleSaveLink = () => {
    pushGTMEvent({
      eventAction: GTM_ACTIONS.agreement_and_docs,
      eventLabel: `link_document_${active}`,
    });
    linkDocument(id, active);
    closeLinkOptions();
  };

  return (
    <div>
      {options.map(item => (
        <div
          onClick={() => setActive(item.value)}
          className={`linkoption_item d-flex sr-justify-space hover ${
            active === item.value ? 'active' : ''
          }`}
          style={LinkOptionItemStyle}
        >
          <span>{item.label}</span>
          {active === item.value ? (
            <img src={BankDetails.TickIconBlue} alt="" />
          ) : (
            <img src={BankDetails.TickIcon} alt="" />
          )}
        </div>
      ))}
      <div style={{ padding: '12px' }}>
        <Button onClick={handleSaveLink}>
          {STRINGS.FINANCE.AGREEMENTS_AND_DOCS.DONE}
        </Button>
      </div>
    </div>
  );
};

const DocumentItem = props => {
  const { title, fileURL, linkedDocType, linkDocument, id, linkOptions } =
    props;
  const [isVisibleLinkOptions, showLinkOptions, hideLinkOptions] =
    useFlagState(false);

  const [STRINGS] = useLangStrings('Property');
  const [openDocument] = useOpenDocumentLink();

  const docRef = useRef();
  const linkButtonName = linkedDocType
    ? STRINGS.FINANCE.AGREEMENTS_AND_DOCS.CHANGE_LINKAGE
    : STRINGS.FINANCE.AGREEMENTS_AND_DOCS.LINK_DOCUMENT;
  return (
    <div style={DocumentItemStyle}>
      <div className="d-flex sr-align-baseline sr-justify-space ">
        <p
          style={{
            color: '#212121',
            textTransform: 'capitalize',
            fontWeight: 500,
            width: '200px',
          }}
        >
          {title}
        </p>
        <span ref={docRef}>
          <Button
            style={{ width: '130px' }}
            onClick={() => {
              pushGTMEvent({
                eventAction: GTM_ACTIONS.agreement_and_docs,
                eventLabel: linkedDocType ? 'change_document' : 'link_document',
              });
              showLinkOptions();
            }}
            noPadding={true}
            size="legacy"
            variant="text"
          >
            {linkButtonName}
          </Button>
        </span>
      </div>
      <Button
        variant="text"
        onClick={() => openDocument(fileURL)}
        size="legacy"
      >
        {STRINGS.FINANCE.AGREEMENTS_AND_DOCS.VIEW_DOCUMEMT}
      </Button>

      <Popover
        open={isVisibleLinkOptions}
        anchorEl={docRef.current}
        onClose={hideLinkOptions}
      >
        <LinkOptionsSheet
          id={id}
          linkDocument={linkDocument}
          linkedDocType={linkedDocType}
          closeLinkOptions={hideLinkOptions}
          options={linkOptions}
        />
      </Popover>
    </div>
  );
};

const AgreementItem = props => {
  const { title, fileURL, status, onClose, id } = props;
  const [STRINGS] = useLangStrings('Property');
  const [openDocument] = useOpenDocumentLink();
  const { currentHotel, decideTnCBanner } = useContext(HotelContext);

  return (
    <div style={DocumentItemStyle}>
      <div className="d-flex sr-align-baseline sr-justify-space ">
        <p style={{ color: '#212121', width: '200px', fontWeight: 500 }}>
          {title}
        </p>
        {status !== 'accepted' && (
          <Button
            onClick={() => {
              decideTnCBanner(currentHotel.hotelcode, 'cta');
              onClose();
            }}
            style={{ width: '130px' }}
            noPadding={true}
            size="legacy"
            variant="text"
          >
            {STRINGS.FINANCE.AGREEMENTS_AND_DOCS.ACCEPT_AGREEMENT}
          </Button>
        )}
      </div>
      <Button
        variant="text"
        size="legacy"
        onClick={() => {
          pushGTMEvent({
            eventAction: GTM_ACTIONS.agreement_and_docs,
            eventLabel: `view_agreement_${title}`,
            misc_id: id,
          });
          openDocument(fileURL);
        }}
      >
        {STRINGS.FINANCE.AGREEMENTS_AND_DOCS.VIEW_AGREEMENT_DETAILS}
      </Button>
    </div>
  );
};

function AgreementsDrawer(props) {
  const { onClose, isOpen, agreements, documents, count } = props;
  const { refreshBankDetails } = useContext(BankDetailsContext);
  const { refreshHotelDocuments } = useContext(BankDetailsContext);
  const { hotelcode } = useContext(HotelContext).currentHotel;

  const [STRINGS] = useLangStrings('Property');

  let linkOptions = [];

  linkOptions = useMemo(() => {
    return Object.entries(STRINGS.FINANCE.AGREEMENTS_AND_DOCS.LINK_OPTIONS).map(
      ([key, val]) => {
        return {
          value: key,
          label: val,
        };
      },
    );
  });

  const linkDocument = (id, linkedDocType) => {
    const payload = {
      hotelcode: hotelcode,
      legal_doc_type: linkedDocType,
    };
    updateDocument(id, payload)
      .then(res => {
        pushGTMEvent({
          eventAction: GTM_ACTIONS.agreement_and_docs,
          eventLabel: `link_document_${linkedDocType}_success`,
        });
        onClose();
        showMessage({
          show: true,
          message: 'Document linked successfully',
          type: 'success',
        });

        refreshHotelDocuments();
        refreshBankDetails();
        return res;
      })
      .catch(err => {
        pushGTMEvent({
          eventAction: GTM_ACTIONS.agreement_and_docs,
          eventLabel: `link_document_${linkedDocType}_error`,
        });
        onClose();
        showMessage({
          show: true,
          message: err.response.data.error,
          type: 'error',
        });
      });
  };

  return (
    <div>
      <Drawer
        isOpen={isOpen}
        header={{
          title: `${STRINGS.FINANCE.AGREEMENTS_AND_DOCS.AGREEMENTS_AND_OTHER} (${count})`,
          hideCross: true,
        }}
        footer={{
          secondaryBtn: {
            text: 'Close',
            onClick: onClose,
          },
        }}
        onClose={onClose}
      >
        {agreements.map(item => (
          <AgreementItem
            key={item.agreement_id}
            id={item.agreement_id}
            title={item.agreement_display_name}
            fileURL={
              item.status === 'accepted'
                ? item.signed_agreement_url
                : item.sample_url
            }
            status={item.status}
            onClose={onClose}
          />
        ))}

        {documents.map(item => (
          <DocumentItem
            key={item.id}
            id={item.id}
            title={
              item.legal_doc_type
                ? item.legal_doc_type.split('_').join(' ')
                : item.document_name
            }
            linkOptions={linkOptions}
            linkedDocType={item.legal_doc_type}
            linkDocument={linkDocument}
            fileURL={item.document}
          />
        ))}
      </Drawer>
    </div>
  );
}

export default AgreementsDrawer;
