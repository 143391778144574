import {
  GoTribe,
  GoTribeLogo,
  HomeStays,
  HomeStaysLogo,
  MyBiz,
  MyBizLogo,
  MyPartner,
  MyPartnerLogo,
} from 'assets/modules/landing';
import {
  Card,
  CardContainer,
  CardDescription,
  CardTitle,
  Heading,
  OfferingsWrapper,
  Partners,
  PartnersBody,
  PartnersCard,
  PartnersCardDesc,
  PartnersCardTitle,
  PartnersImageWrapper,
  SubHeading,
} from './PartnerOfferings.styles';
import { LandingPageContainer } from 'modules/landingPage/LandingPage.styles';
import useStrings from 'modules/landingPage/hooks/useStrings';
import {
  CONTENT_CONFIG,
  // COMMON_OMNITURE_VARIABLES,
} from 'modules/landingPage/constants';
import { getValue } from 'utilities/Utils';
// import { useIntersectionObserver } from 'utilities/CustomHooks';
// import { pushToOmniture, OMNITURE_CATEGORIES } from 'utilities/gtm';

const PartnerOfferings = () => {
  const [STRINGS] = useStrings();

  const getPartnersImages = (partnerId: string) => {
    switch (partnerId) {
      case 'myBiz':
        return { logo: <MyBizLogo />, image: MyBiz };
      case 'myPartner':
        return { logo: <MyPartnerLogo />, image: MyPartner };
      case 'loyaltyPrograms':
        return { logo: <GoTribeLogo />, image: GoTribe };
      case 'homeStays':
        return { logo: <HomeStaysLogo />, image: HomeStays };
      default:
        return {};
    }
  };

  // useIntersectionObserver(
  //   'info_section',
  //   ([entry]) => {
  //     if (entry.isIntersecting) {
  //       pushToOmniture({
  //         ...COMMON_OMNITURE_VARIABLES,
  //         event: OMNITURE_CATEGORIES.CTA_LOAD,
  //         cta: {
  //           name: 'info_section',
  //           type: 'load',
  //           componentName: 'info',
  //         },
  //       });
  //     }
  //   },
  //   [],
  //   { rootMargin: '-50% 0px' },
  // );

  return (
    <OfferingsWrapper className="info_section">
      <LandingPageContainer>
        <CardContainer>
          {CONTENT_CONFIG.offerings_card.map((card, index) => (
            <Card key={index}>
              <CardTitle>{getValue(STRINGS, card.title)}</CardTitle>
              <CardDescription>
                {getValue(STRINGS, card.description)}
              </CardDescription>
            </Card>
          ))}
        </CardContainer>
        <Heading>{STRINGS.offerings_partners.heading}</Heading>
        <SubHeading>{STRINGS.offerings_partners.subHeading}</SubHeading>
        <Partners>
          {CONTENT_CONFIG.partners_list.map(partner => (
            <PartnersCard key={partner.title}>
              <PartnersImageWrapper>
                <img
                  src={getPartnersImages(partner.id).image}
                  alt={partner.title}
                />
                <span>{getPartnersImages(partner.id).logo}</span>
              </PartnersImageWrapper>
              <PartnersBody>
                <PartnersCardTitle>
                  {getValue(STRINGS, partner.title)}
                </PartnersCardTitle>
                <PartnersCardDesc>
                  {getValue(STRINGS, partner.description)}
                </PartnersCardDesc>
              </PartnersBody>
            </PartnersCard>
          ))}
        </Partners>
      </LandingPageContainer>
    </OfferingsWrapper>
  );
};

export default PartnerOfferings;
