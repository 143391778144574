import { colors, typography } from 'style/zeplin-token-styledComponents';
import styled from 'styled-components';

export const FaqsSection = styled.section`
  background-color: ${colors.white};
  padding: 56px 0;
`;

export const Heading = styled.h3`
  text-align: center;
  margin-bottom: 16px;
  ${typography.headingRegular}
  color: ${colors.grayMediumEmphasis};
`;

export const SubHeading = styled.p`
  text-align: center;
  ${typography.titleRegular}
  color: ${colors.grayMediumEmphasis};
`;

export const FaqsList = styled.ul`
  width: 900px;
  margin: 56px auto 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  list-style-type: none;
`;

export const FaqItem = styled.li<{ active: boolean }>`
  padding: 38px;
  border-radius: 18px;
  box-shadow: 0 5px 16px 0 rgba(8, 15, 52, 0.06);
  background-color: ${colors.white};
  border: 2px solid ${props => (props.active ? colors.orange : colors.white)};
  transition: all 0.3s linear;
`;

export const FaqQuestion = styled.h4`
  position: relative;
  padding-right: 80px;
  cursor: pointer;
  ${typography.titleBold}
  color: ${colors.grayMediumEmphasis};
`;

export const BtnArrow = styled.span<{ active: boolean }>`
  width: 50px;
  height: 50px;
  box-shadow: 0 5px 16px 0 rgba(8, 15, 52, 0.06);
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: -13px;
  cursor: pointer;
  &::after {
    content: '';
    border: solid ${colors.orange};
    border-width: 0 2px 2px 0;
    padding: 3px;
    position: absolute;
    transform: rotate(45deg);
    transition: all 0.3s linear;
  }
  ${props =>
    props.active &&
    `
    &::after {
      transform: rotate(-135deg);
    }
  `}
`;

export const FaqAnswer = styled.p<{ active: boolean }>`
  max-height: ${props => (props.active ? '200px' : '0')};
  overflow: hidden;
  line-height: 24px;
  padding-right: 80px;
  transition: all 0.3s linear;
  margin-top: ${props => (props.active ? '18px' : '0')};
  ${typography.bodyTextBaseRegular}
  color: ${colors.grayMediumEmphasis};
`;

export const ReadAllContainer = styled.div`
  width: 900px;
  margin: 44px auto 0;
  padding: 38px;
  border-radius: 18px;
  box-shadow: 0 5px 16px 0 rgba(8, 15, 52, 0.06);
  background-color: ${colors.white};
  text-align: center;
`;

export const ReadAllText = styled.p`
  ${typography.titleBold}
  color: ${colors.grayMediumEmphasis};
`;

export const ReadAllButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 14px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  background-color: ${colors.orange};
  outline: 0;
  border: 0;
  cursor: pointer;
  margin-top: 28px;
  ${typography.subTextBold}
  color: ${colors.white};
  transition: all 0.3s linear;
  &:hover {
    background-color: #b63f15;
  }
`;
