import React, { useEffect, useRef } from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import {
  BodyContainer,
  FeatureIcon,
  Heading,
  InnerWrapper,
  LeftSection,
  ListItem,
  RightSection,
  SubHeading,
  Wrapper,
} from './Features.styles';
import { LandingPageContainer } from 'modules/landingPage/LandingPage.styles';
import useStrings from 'modules/landingPage/hooks/useStrings';
import {
  COMMON_OMNITURE_VARIABLES,
  CONTENT_CONFIG,
} from 'modules/landingPage/constants';
import { getValue } from 'utilities/Utils';
import { OMNITURE_CATEGORIES, pushToOmniture } from 'utilities/gtm';
// import { useIntersectionObserver } from 'utilities/CustomHooks';

interface FeaturesProps {
  featuresRef: React.RefObject<HTMLDivElement>;
}

const Features = ({ featuresRef }: FeaturesProps) => {
  const [STRINGS] = useStrings();
  const intervalRef = useRef(null);
  const isFeaturesVisible =
    featuresRef?.current?.getBoundingClientRect().top <
    window.innerHeight * 0.5;

  const [activeIndex, setActiveIndex] = React.useState(0);

  useEffect(() => {
    if (isFeaturesVisible) {
      intervalRef.current = setInterval(() => {
        setActiveIndex(currentIdx => {
          if (currentIdx >= CONTENT_CONFIG.features_list.length - 1) {
            clearInterval(intervalRef.current);
            return currentIdx;
          }
          return currentIdx + 1;
        });
      }, 1500);
    }

    return () => clearInterval(intervalRef.current);
  }, [isFeaturesVisible]);

  const handleAccordion = (
    index: number,
    feature: typeof CONTENT_CONFIG.features_list[0],
  ) => {
    clearInterval(intervalRef.current);
    setActiveIndex(index);

    pushToOmniture({
      ...COMMON_OMNITURE_VARIABLES,
      ...feature.omnitureVariables,
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      cta: {
        name: 'show',
        type: 'click',
        componentName: 'features',
      },
    });
  };

  // useIntersectionObserver(
  //   'feature_section',
  //   ([entry]) => {
  //     if (entry.isIntersecting) {
  //       pushToOmniture({
  //         ...COMMON_OMNITURE_VARIABLES,
  //         event: OMNITURE_CATEGORIES.CTA_LOAD,
  //         cta: {
  //           name: 'feature_section',
  //           type: 'load',
  //           componentName: 'features',
  //         },
  //       });
  //     }
  //   },
  //   [],
  //   { rootMargin: '-50% 0px' },
  // );

  return (
    <Wrapper ref={featuresRef} className="feature_section">
      <LandingPageContainer>
        <InnerWrapper>
          <SectionHeader
            heading={STRINGS.features_data.heading}
            subHeading={STRINGS.features_data.subHeading}
          />
          <BodyContainer>
            <LeftSection>
              {CONTENT_CONFIG.features_list.map((feature, index) => (
                <ListItem
                  key={index}
                  active={activeIndex === index ? true : undefined}
                >
                  <FeatureIcon
                    active={activeIndex === index ? true : undefined}
                  />
                  <Heading
                    active={activeIndex === index ? true : undefined}
                    onClick={() => handleAccordion(index, feature)}
                  >
                    {getValue(STRINGS, feature.title)}
                  </Heading>
                  {activeIndex === index && (
                    <SubHeading>
                      {getValue(STRINGS, feature.description)}
                    </SubHeading>
                  )}
                </ListItem>
              ))}
            </LeftSection>
            <RightSection>
              <img
                src={CONTENT_CONFIG.features_list[activeIndex].image}
                alt="Feature image"
              />
            </RightSection>
          </BodyContainer>
        </InnerWrapper>
      </LandingPageContainer>
    </Wrapper>
  );
};

export default Features;
