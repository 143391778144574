import React, { useEffect } from 'react';
import {
  ButtonWrapper,
  Container,
  FormWrapper,
  Header,
  HeaderRight,
  Icon,
} from './ForgotPassword.styles';
import { CreateAccountIcon } from 'assets/modules/landing';
import { SignUpButton } from '../PageHeader/PageHeader.styles';

import useStrings from 'modules/landingPage/hooks/useStrings';
import { ControlledCustomInputField } from 'components/cosmos-components/CustomInputField';
import { useForm } from 'react-hook-form';
import { emailRegex } from 'utilities/allRegex';
import { colors } from 'style/zeplin-token-styledComponents';
import { ForgotPasswordRequest } from 'modules/landingPage/api/api';
import { OMNITURE_CATEGORIES, pushToOmniture } from 'utilities/gtm';
import { COMMON_OMNITURE_VARIABLES } from '../../constants';

export interface ForgotPasswordProps {
  email: string;
  onSubmit: (data: ForgotPasswordRequest) => void;
}

const ForgotPassword = ({ email, onSubmit }: ForgotPasswordProps) => {
  const [STRINGS, COMMON] = useStrings();
  const { control, handleSubmit } = useForm({
    defaultValues: { email },
  });

  const handleFormError = error => {
    pushToOmniture({
      ...COMMON_OMNITURE_VARIABLES,
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      loadedComponents: error.email.message,
      cta: {
        name: 'link_sent_failure',
        type: 'click',
        componentName: 'forgot_password',
      },
    });
  };

  useEffect(() => {
    pushToOmniture({
      ...COMMON_OMNITURE_VARIABLES,
      event: OMNITURE_CATEGORIES.CTA_LOAD,
      cta: {
        name: 'forgot_password',
        type: 'load',
        componentName: 'forgot_password',
      },
    });
  }, []);

  return (
    <Container>
      <Header>
        <Icon>
          <CreateAccountIcon />
        </Icon>
        <HeaderRight>
          <p>{STRINGS.forgotPasswordData.heading}</p>
          <p>{STRINGS.forgotPasswordData.subHeading}</p>
        </HeaderRight>
      </Header>
      <FormWrapper>
        <ControlledCustomInputField
          label={STRINGS.FORM_LABELS.EMAIL}
          labelColor={colors.grayMediumEmphasis}
          placeholder={STRINGS.FORM_PLACEHOLDERS.EMAIL}
          name="email"
          control={control}
          defaultValue={email}
          rules={{
            required: COMMON.MESSAGES.VALID_EMAIL_TEXT,
            pattern: {
              value: emailRegex,
              message: STRINGS.FORM_VALIDATIONS.EMAIL,
            },
          }}
        />
        <ButtonWrapper>
          <SignUpButton onClick={handleSubmit(onSubmit, handleFormError)}>
            {STRINGS.forgotPasswordData.button_text}
          </SignUpButton>
        </ButtonWrapper>
      </FormWrapper>
    </Container>
  );
};

export default ForgotPassword;
