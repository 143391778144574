import { colors, typography } from 'style/zeplin-token-styledComponents';
import styled from 'styled-components';

export const OfferingsWrapper = styled.section`
  background-color: ${colors.white};
  padding-bottom: 64px;
`;

export const CardContainer = styled.div`
  gap: 24px;
  display: flex;
  margin-top: -120px;
  position: relative;
  padding: 0 63px;
`;

export const Card = styled.div`
  padding: 24px;
  border-radius: 8px;
  background-color: ${colors.white};
  box-shadow: 0px 0px 32px 0px #0000001a;
  flex: 1;
`;

export const CardTitle = styled.p`
  ${typography.subheadingBold};
  color: ${colors.orange};
`;

export const CardDescription = styled.p`
  ${typography.bodyTextBaseRegular};
  color: ${colors.grayHighEmphasis};
  margin-top: 20px !important;
`;

export const Heading = styled.p`
  margin-top: 68px !important;
  padding: 0px 224px;
  text-align: center;
  ${typography.headingRegular};
  color: ${colors.grayMediumEmphasis};
`;

export const SubHeading = styled.p`
  padding: 0px 224px;
  text-align: center;
  margin-top: 20px !important;
  ${typography.titleRegular};
  color: ${colors.grayMediumEmphasis};
`;

export const Partners = styled.div`
  display: flex;
  gap: 21px;
  margin-top: 54px;
`;

export const PartnersCard = styled.div`
  border-radius: 8px;
  padding: 8px;
  background-color: ${colors.white};
  border: 1px solid rgba(247, 247, 247, 1);
  box-shadow: 0px 7.85px 23.54px 0px rgba(162, 182, 212, 0.15);
  flex: 1;
`;

export const PartnersImageWrapper = styled.div`
  position: relative;

  & > img {
    width: 100%;
    height: 162px;
    object-fit: cover;
    border-radius: 8px;
  }

  & > span {
    position: absolute;
    right: 12px;
    bottom: 12px;
  }

  &::before {
    background: linear-gradient(
      180.09deg,
      rgba(0, 0, 0, 0) 0.17%,
      rgba(0, 0, 0, 0.4) 99.92%
    );
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 8px;
  }
`;

export const PartnersBody = styled.div`
  padding: 16px 8px 8px 8px;
`;

export const PartnersCardTitle = styled.p`
  ${typography.bodyTextBaseBold};
  color: ${colors.grayMediumEmphasis};
`;

export const PartnersCardDesc = styled.p`
  margin-top: 5px !important;
  ${typography.subTextRegular};
  color: ${colors.grayMediumEmphasis};
`;
