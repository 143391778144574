import {
  COMPONENT_ID_ENUM,
  ITEM_TYPE_ENUM,
  LAYOUT_ID_ENUM,
} from './SideNav.constants';
import type { ILeftNavDataResponse } from './SideNav.types';

export const getDefaultSideNavData = (): ILeftNavDataResponse => {
  return {
    error: null,
    requestId: '',
    sequenceData: {
      data: [
        {
          componentId: COMPONENT_ID_ENUM.COMMON_SIDE_NAV,
          variantId: 'EXTRANET_SIDE_NAV_V1',
          layoutId: LAYOUT_ID_ENUM.COMMON_GRID,
        },
        {
          componentId: COMPONENT_ID_ENUM.COMMON_GROUP_SIDE_NAV,
          variantId: 'EXTRANET_GROUP_SIDE_NAV_V1',
          layoutId: LAYOUT_ID_ENUM.COMMON_GRID,
        },
      ],
    },
    data: {
      componentData: {
        EXTRANET_SIDE_NAV_V1: {
          componentId: COMPONENT_ID_ENUM.COMMON_GROUP_SIDE_NAV,
          variantId: 'EXTRANET_GROUP_SIDE_NAV_V1',
          style: {},
          layout: {
            id: LAYOUT_ID_ENUM.COMMON_GRID,
            scroll: 'DISABLED',
            state: 'EXPANDED',
            type: 'GRID',
            isActive: true,
          },
          trackingConfig: null,
          cta: null,
          data: {
            items: [
              {
                additionalMetadata: {
                  verificationBlocker: false,
                },
                clickable: true,
                cta: {
                  type: 'COMPONENT',
                  deeplink: '/dashboard',
                  deeplinkType: 'SCREEN_REDIRECT',
                },
                data: {
                  iconUrl:
                    'https://promos.makemytrip.com/connectproduct/extranet_left_navigation/Home_Un.svg',
                  selectedIconUrl:
                    'https://promos.makemytrip.com/connectproduct/extranet_left_navigation/Home_Se.svg',
                  title: 'Dashboard',
                },
                disabled: false,
                itemId: ITEM_TYPE_ENUM.LABELLED_ICON,
                scrollable: false,
              },
            ],
          },
        },
        EXTRANET_GROUP_SIDE_NAV_V1: {
          componentId: COMPONENT_ID_ENUM.COMMON_GROUP_SIDE_NAV,
          variantId: 'EXTRANET_GROUP_SIDE_NAV_V1',
          style: {},
          layout: {
            id: LAYOUT_ID_ENUM.COMMON_GRID,
            scroll: 'DISABLED',
            state: 'EXPANDED',
            type: 'GRID',
            isActive: true,
          },
          trackingConfig: null,
          cta: null,
          data: {
            items: [
              {
                clickable: true,
                cta: {
                  type: 'COMPONENT',
                  deeplink: '/',
                  deeplinkType: 'SCREEN_REDIRECT',
                },
                data: {
                  iconUrl:
                    'https://promos.makemytrip.com/connectproduct/extranet_left_navigation/MyProps_Un.svg',
                  selectedIconUrl:
                    'https://promos.makemytrip.com/connectproduct/extranet_left_navigation/MyProps_Se.svg',
                  title: 'My Properties',
                },
                disabled: false,
                itemId: ITEM_TYPE_ENUM.LABELLED_ICON,
                scrollable: false,
                additionalMetadata: {
                  verificationBlocker: false,
                },
              },
            ],
          },
        },
      },
    },
  };
};
