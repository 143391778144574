import { APIS } from '../constants';

const actionTypes = {
  [APIS.CALSYNC_DATA]: {
    FETCHING: 'FETCH_CALSYNC_DATA',
    SUCCESS: 'SUCCESS_CALSYNC_DATA',
    FAILED: 'FAILED_CALSYNC_DATA',
    RESET: 'RESET_CALSYNC_DATA',
    UPDATE: 'UPDATE_CALSYNC_DATA',
  },
  [APIS.LEGACY_PARITY_PART_1]: {
    FETCHING: 'FETCH_LEGACY_PARITY_PART_1',
    SUCCESS: 'SUCCESS_LEGACY_PARITY_PART_1',
    FAILED: 'FAILED_LEGACY_PARITY_PART_1',
    RESET: 'RESET_LEGACY_PARITY_PART_1',
    UPDATE: 'UPDATE_LEGACY_PARITY_PART_1',
  },
  [APIS.LEGACY_PARITY_PART_2]: {
    FETCHING: 'FETCH_LEGACY_PARITY_PART_2',
    SUCCESS: 'SUCCESS_LEGACY_PARITY_PART_2',
    FAILED: 'FAILED_LEGACY_PARITY_PART_2',
    RESET: 'RESET_LEGACY_PARITY_PART_2',
    UPDATE: 'UPDATE_LEGACY_PARITY_PART_2',
  },
  [APIS.RESELLER_AGREEMENT]: {
    FETCHING: 'FETCH_RESELLER_AGREEMENT',
    SUCCESS: 'SUCCESS_RESELLER_AGREEMENT',
    FAILED: 'FAILED_RESELLER_AGREEMENT',
    RESET: 'RESET_RESELLER_AGREEMENT',
    UPDATE: 'UPDATE_RESELLER_AGREEMENT',
  },
  [APIS.COMPSET_DATA]: {
    FETCHING: 'FETCH_COMPSET_DATA',
    SUCCESS: 'SUCCESS_COMPSET_DATA',
    FAILED: 'FAILED_COMPSET_DATA',
    RESET: 'RESET_COMPSET_DATA',
    UPDATE: 'UPDATE_COMPSET_DATA',
  },
  [APIS.SALE_CAMPAIGNS]: {
    FETCHING: 'FETCH_SALE_CAMPAIGNS',
    SUCCESS: 'SUCCESS_SALE_CAMPAIGNS',
    FAILED: 'FAILED_SALE_CAMPAIGNS',
    RESET: 'RESET_SALE_CAMPAIGNS',
    UPDATE: 'UPDATE_SALE_CAMPAIGNS',
  },
  [APIS.FEATURE_FLAGS]: {
    FETCHING: 'FETCH_FEATURE_FLAGS',
    SUCCESS: 'SUCCESS_FEATURE_FLAGS',
    FAILED: 'FAILED_FEATURE_FLAGS',
    RESET: 'RESET_FEATURE_FLAGS',
    UPDATE: 'UPDATE_FEATURE_FLAGS',
  },
  [APIS.ICS_COMBINED_DATA]: {
    FETCHING: 'FETCH_ICS_COMBINED_DATA',
    SUCCESS: 'SUCCESS_ICS_COMBINED_DATA',
    FAILED: 'FAILED_ICS_COMBINED_DATA',
    RESET: 'RESET_ICS_COMBINED_DATA',
    UPDATE: 'UPDATE_ICS_COMBINED_DATA',
  },
};

export default actionTypes;
