export enum CP_PAGE {
  POLICY_LISTING = 'POLICY_LISTING',
  EDIT_POLICY = 'EDIT_POLICY',
  CREATE_POLICY = 'CREATE_POLICY',
}

export enum WIDGET_TYPE {
  POLICY_LIST = 'POLICY_LIST',
  ALERT_INFO = 'ALERT_INFO',
}
export enum TAG_COLOR {
  DEFAULT = 'default',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
  SUCCESSMEDIUM = 'successmedium',
  GREEN = 'green',
}

export const ACTIVE_STATUS = 1;

export enum POLICY_VENDOR {
  INGO = 1,
}

export enum POLICY_RP_TYPE {
  NORMAL = 1,
  HOURLY = 2,
  GROUP_RATES = 3,
}

export enum TIMELINE_BAR_LABELS {
  CHECKIN = 'Check-in',
  Booking = 'Booking',
  NON_REFUNFABLE = 'Non-refundable',
}

export enum POLICY_TEMPLATE_ID {
  NON_REFUNDABLE = 'NR',
  FREE_CANCELLATION = 'FC',
  FC_TILL_MID_NIGHT = 'FC_0DMN',
  FC_TILL_7DAYS = 'FC_7D',
  FC_TILL_15DAYS_50PERCENT_TILL_7DAYS = 'FC_15D__50P_7D',
}

export enum POLICY_NAME_HOURLY {
  NON_REFUNDABLE = 'Non refundable',
  FC_TILL_MID_NIGHT = 'Free cancellation: till midnight before check-in',
}
