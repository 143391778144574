import {
  AllCugStatus,
  PostDealPriceReducerInterface,
} from 'modules/promotions-and-coupons/PnCTypes';

enum SEGMENT_KEY {
  mmtSelect1 = 'mmtSelect1',
  mmtSelect2 = 'mmtSelect2',
  mmtBlack1 = 'mmtBlack1',
  mmtBlack2 = 'mmtBlack2',
}

export enum CUG_CATEGORY_TYPE {
  corporate = 'corporate',
  geography = 'geography',
  gcc = 'gcc',
  b2a = 'b2a',
  holidays = 'holidays',
  mmtSelect = 'mmtSelect',
  mmtBlack = 'mmtBlack',
  mobile = 'mobile',
  member = 'member',
}

export enum CUG_CATEGORY_TYPE_EXCLUSIVE_BLACK {
  corporate = 'corporate',
  geography = 'geography',
  gcc = 'gcc',
  b2a = 'b2a',
  holidays = 'holidays',
  select = 'select',
  select1 = 'select1',
  select2 = 'select2',
  mobile = 'mobile',
  member = 'member',
}

export enum CUG_CATEGORY_TYPE_BLACK {
  black = 'black',
  black1 = 'black1',
  black2 = 'black2',
}

export interface ICugBlackCategoryServiceData {
  id: string;
  isActive: boolean;
  leafCategoryName: string;
}

export type NewCugCategoryType =
  | CUG_CATEGORY_TYPE_EXCLUSIVE_BLACK
  | CUG_CATEGORY_TYPE_BLACK;

export enum PROMOTION_CATEGORY_TYPE {
  date = 'date',
  lastMinute = 'lastminute',
  earlyBird = 'earlybird',
  customised = 'customised',
  day = 'day',
  los = 'los',
  fnd = 'fnd',
  fbl = 'fbl',
}

export enum PNC_JUMBO_TAB {
  all = 'all',
  create = 'create',
}

export enum PNC_TAB {
  active = 'active',
  expired = 'expired',
}
export enum PNC_SORTING_ORDER {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface SegmentDataValueInterface {
  maxOfferValue: number;
  minOfferValue: number;
  recommendedValue: number;
}

export interface TimeBasedMetadataInterface {
  minDuration: number;
  minOfferValue: number;
  recommendedEndTime: string;
  recommendedStartTime: string;
  recommendedValue: number;
}

export interface CugOfferCategoryValueInterface {
  segmentData: {
    [key in SEGMENT_KEY]?: SegmentDataValueInterface;
  };
  isCreated: boolean;
  display: string;
  description: string;
  title: string;
  isRecommended: boolean;
  minDuration: number;
  minOfferValue: number;
  priority: number;
  annualBlackoutLimit: number;
  monthlyBlackoutLimit: number;
  maxOfferValue: number;
  recommendedValue: number;
  activeCount: number;
  expiredCount: number;
  inactiveCount: number;
  maxBaseOfferValue?: number;
  timeBasedMetadata?: TimeBasedMetadataInterface;
}

export interface PromotionOfferCategoryValueInterface {
  loggedinValue: number;
  activeCount: number;
  expiredCount: number;
  inactiveCount: number;
  maxBaseOfferValue?: number;
  description: string;
  title: string;
  priority: number;
  isRecommended: boolean;
  key: string;
  icon?: React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string;
  }
  >;
  infoDesc?: string;
  minStayDuration?: number;
}

export interface CouponOfferCategoryValueInterface {
  loggedinValue: number;
  activeCount: number;
  expiredCount: number;
  inactiveCount: number;
  maxBaseOfferValue?: number;
  description: string;
  title: string;
  priority: number;
  isRecommended: boolean;
}

export interface PncActionTypes {
  UPDATE_DEAL_METADATA: string;
  CLEAR_DEAL_METADATA: string;
  UPDATE_ALL_DEALS_DATA: string;
  SET_ACTIVE_JUMBO_TAB: string;
  SET_ACTIVE_DEALS_TAB: string;
  SET_SCROLL_TO: string;
  UPDATE_SORTING_ORDER: string;
  UPDATE_PAGINATION: string;
  UPDATE_FILTER_DATA: string;
  CUG_BEING_EDITED: string;
  UPDATE: string;
  RESET: string;
  UPDATE_PROMOTIONS_INFO_DESC: string;
  SET_META_DATA_LOADING: string;
  SET_ALL_PNC_LOADING: string;
}

export enum PNC_TABLE_KEYS {
  dealName = 'DEAL_NAME',
  dealType = 'DEAL_TYPE',
  bookingDate = 'BOOKING_DATE',
  stayDate = 'STAY_DATE',
  discount = 'DISCOUNT',
  expiringIn = 'EXPIRING_IN',
  roomNights = 'ROOM_NIGHTS',
  revenue = 'REVENUE',
  deactivatedOn = 'DEACTIVATED_ON',
  cta = 'CTA',
}

export enum DISCOUNT_TYPE {
  percentage = 'percentage',
  flat_price = 'flat_price',
  flatdprpn = 'flatdprpn',
  fixed = 'fixed',
  flatpprpn = 'flatpprpn',
}

export enum OFFER_VALUE_SEGMENT_TYPE {
  b2c = 'b2c',
  loggedin = 'loggedin',
  MOBILE = 'MOBILE',
  mobile = 'MOBILE', // eslint-disable-line
  Holidays = 'Holidays',
  holidays = 'Holidays', // eslint-disable-line
  MMT_BLACK = 'MMT BLACK',
  MMT_BLACK1 = 'MMT BLACK1',
  MMT_BLACK2 = 'MMT BLACK2',
  b2a = 'b2a',
  corporate = 'corporate',
  gcc = 'gcc',
  bundled = 'bundled',
  GEOGRAPHY = 'GEOGRAPHY',
  geography = 'GEOGRAPHY', // eslint-disable-line
  IN_POS = 'GEOGRAPHY', // eslint-disable-line
  'IN-POS' = 'GEOGRAPHY', // eslint-disable-line
  Affiliate = 'Affiliate',
  b_2_b = 'b_2_b',
  b2b = 'b2b',
  MMT_SELCT = 'MMT SELCT',
  MMT_SELCT1 = 'MMT SELCT1',
  MMT_SELCT2 = 'MMT SELCT2',
  member = 'member',
  vistarafly = 'vistarafly',
}

export const BlackOfferNewOldMap = {
  [OFFER_VALUE_SEGMENT_TYPE.MMT_BLACK1]: CUG_CATEGORY_TYPE_BLACK.black1,
  [OFFER_VALUE_SEGMENT_TYPE.MMT_BLACK2]: CUG_CATEGORY_TYPE_BLACK.black2,
};

export enum DEAL_TYPE {
  PROMOTIONS = 'PROMOTIONS',
  CUG = 'CUG',
  COUPONS = 'COUPONS',
}

export enum PERFORMANCE_TIME {
  THIS_WEEK = 'THIS_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  LAST_7 = 'LAST_7',
  LAST_14 = 'LAST_14',
  LAST_180 = 'LAST_180',
  LAST_365 = 'LAST_365',
  ALL_TIME = 'ALL_TIME',
}
export interface OfferValueInterface {
  offerValue: number;
  offerType: string;
  segment?: OFFER_VALUE_SEGMENT_TYPE;
  id: string;
  isActive: boolean;
  discountErrorMessage: string;
}

export enum DEAL_TYPE_LABEL {
  cug = 'cug',
  promotion = 'promotion',
  coupon = 'coupon',
}

export enum DEAL_STATUS {
  ACTIVE = 'ACTIVE',
  IN_ACTIVE = 'IN_ACTIVE',
  EXPIRED = 'EXPIRED',
}

export interface OfferValueData {
  segment: string;
  isActive: boolean;
  offerValue: number;
}

export interface TimeBasedOfferData {
  offerCode: string;
  startTime: string;
  endTime: string;
  isApplicableTillNextDay: boolean;
  offerValueDataList: OfferValueData[];
}

export interface DealDataInterface {
  offerCode: string;
  dealName: string;
  dealType: DEAL_TYPE_LABEL;
  expiringIn: number;
  status: DEAL_STATUS;
  modifiedOn: string;
  createdOn: string;
  checkindatestart: string;
  checkoutdateend: string;
  checkinblackoutdates: string;
  bookingdatestart: string;
  bookingdateend: string;
  deactivatedOn: string;
  isCheckinNoEndDate: boolean;
  isBookingNoEndDate: boolean;
  lockedInDate: string;
  offerPerformanceData: {
    offerRoomNights: number;
    offerRevenue: number;
  };
  offerValueDataList: OfferValueInterface[] | null;
  isActive: boolean;
  offerCategory: PROMOTION_CATEGORY_TYPE;
  minNights: number;
  night: string;
  showModification: boolean;
  isMmtBlack: boolean;
  isMmtSelect: boolean;
  segment: OFFER_VALUE_SEGMENT_TYPE;
  earlyBirdMin: number;
  earlyBirdMax: number;
  is_editable: boolean;
  parentGroupId: string;
  campaignDetails?: {
    campaignId: number;
    imageUrl: string;
  }; // in case of campaigns
  blackBenefitOffered?: string;
  timeBasedOfferData?: TimeBasedOfferData[];
  blackServicesOffered?: Record<
  CUG_CATEGORY_TYPE_BLACK,
  ICugBlackCategoryServiceData[]
  >;
}

export interface AllPncDataInterface {
  offerDataList: DealDataInterface[];
  hotelPerformanceData: {
    hotelRoomNights: number;
    hotelRevenue: number;
  };
  statusCountData: {
    active: number;
    expired: number;
    inActive: number;
  };
  fetchingAllPnc: boolean;
  apiFailed: boolean;
}

export interface PromotionMetadataInterface {
  offerCategories: {
    [key in PROMOTION_CATEGORY_TYPE]?: PromotionOfferCategoryValueInterface;
  };
  metaData: {
    isRecommended: boolean;
  };
  isRecommended: boolean;
  createdCount: number;
  activeCount: number;
  expiredCount: number;
  inactiveCount: number;
  description: string;
  title: string;
  subTitle: string;
}

export interface CugMetadataInterface {
  offerCategories: {
    [key in CUG_CATEGORY_TYPE]?: CugOfferCategoryValueInterface;
  };
  metaData: {
    isRecommended: boolean;
  };
  activeCount: number;
  createdCount: number;
  expiredCount: number;
  inactiveCount: number;
  isRecommended: boolean;
  description: string;
  title: string;
  subTitle: string;
}

export interface CouponMetadataInterface {
  offerCategories: {
    customised: CouponOfferCategoryValueInterface;
  };
  loggedinValue: number;
  activeCount: number;
  expiredCount: number;
  inactiveCount: number;
  baseOfferValue?: number;
  description: string;
  title: string;
  subTitle: string;
  priority: number;
  isRecommended: boolean;
}
export interface PncMetaDataInterface {
  PROMOTIONS: PromotionMetadataInterface;
  CUG: CugMetadataInterface;
  COUPONS: CouponMetadataInterface;
  isNewUser: boolean;
  baseOfferValue: number;
  affiliates: unknown[];
  fetchingMetaData: boolean;
  apiFailed: boolean;
}

export interface SortDealInterface {
  label: PNC_TABLE_KEYS;
  order: PNC_SORTING_ORDER;
}

export interface PaginationInterface {
  pageNo: number;
  size: number;
}

export interface OfferFilterType {
  value: string;
  label: string;
  dealType: string;
}
export interface FilterDataInterface {
  dealTypeList?: DEAL_TYPE[];
  performanceTimePeriod?: PERFORMANCE_TIME | string;
  bookingStartDate?: Date | string;
  bookingEndDate?: Date | string;
  checkinDateStart?: Date | string;
  checkoutDateEnd?: Date | string;
  offerCodeList?: OfferFilterType[] | string[];
}

export interface OmnitureFilterDataInterface extends FilterDataInterface {
  bookingDateRange?: string;
  stayDateRange?: string;
  offerCode?: string;
}

export interface PncReducerInterface {
  pncMetadata: PncMetaDataInterface;
  allPncData: AllPncDataInterface;
  promotionData;
  activeJumboTab: PNC_JUMBO_TAB;
  activePncTab: PNC_TAB;
  actionTypes: PncActionTypes;
  sortPncBy: SortDealInterface;
  pagination: PaginationInterface;
  showPerformanceData: boolean;
  filterData: FilterDataInterface;
  allCugStatus: AllCugStatus;
  cugBeingEdited: string;
  defaultDiscount: number;
  scrollToTop: () => void;
  cugCreationPersuation?: {
    bookingId?: {
      comp_value: number;
      value: number;
    };
    meta_info?: {
      compset_hotel_count: number;
    };
  };
  offerList: OfferFilterType[];
}

export interface Action {
  type: string;
  data?;
}

export default interface PncContextInterface extends PncReducerInterface {
  dispatch: React.Dispatch<Action>;
  setDrawerState: (data: boolean) => void;
  fetchAllPnc: (
    pagination: PaginationInterface,
    sortPncBy: SortDealInterface,
    filterData: FilterDataInterface,
    showPerformanceData: boolean,
    activeTab?: PNC_TAB,
  ) => void;
  refreshAllPncPage: (activePncTab?: PNC_TAB) => void;
  fetchPncMetaData: (getOffers?: number) => void;
  setAllCugStatus: (data) => void;
  showCUGDrawer: (data, isEditFlow: boolean, isOfferAgain?: boolean) => void;
  showCouponDrawer: (data, isOfferAgain?: boolean) => void;
  navigateToActive: (params?: {
    offerType?: string;
    isCreated?: boolean;
  }) => void;
  setUpdateStatus: (data) => void;
  removeOfferAndUpdatePnc: () => void;
  postDealPriceData: PostDealPriceReducerInterface;
  postDealDispatch: React.Dispatch<Action>;
}
