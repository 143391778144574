import { CurrentHotel } from 'interfaces/HotelContextInterface';
import { IHotel, IHotelMetadata, SaleCampaignMetaData } from './interfaces';
import { CampaignHotelMappings } from 'modules/sale-handling/SaleHandling.types';
import { isNullOrUndefined } from 'utilities/Utils';

export function combineHotelDetail(
  currHotel: IHotel,
  hotelDetail: IHotelMetadata,
  isStaff,
): CurrentHotel {
  const {
    activity_events,
    locality,
    starrating,
    hotel_type,
    is_gostay,
    is_advantage,
    property_category,
    property_type,
    is_eligible_for_loan,
    can_optin_for_gstn_invoice,
    gstn_assured,
    is_price_recommendation_enabled,
    timezone,
    timezoneOffset,
    pricing_model,
    max_infant_age,
    max_child_age,
    max_adolescent_age,
    checkintime,
    ec_counts,
    is_chat_enabled,
    is_rtb_enabled,
    show_rtb_settings,
    preapproved_rtb_segments,
    address,
    latitude,
    longitude,
    mmt_id,
    loggedin_promo_amount,
    is_compt_analysis_enabled,
    is_user_hard_blocked,
    is_static_optin,
    is_copy_optin,
    isactive,
    is_host_detail_missing,
    is_host_missing,
    is_vcc,
    add_extraguest_to_declared_flag,
    zipcode,
    city,
    country,
    source_partner,
    chain_id,
    show_star_rating,
    show_sell_property_as,
    is_call_center_enabled,
    current_property_view,
    hostweb_migration,
    hotel_offline_booking_enabled,
    offline_booking_blocked_from_admin,
    showListings,
    dayuse_booking_eligible,
    dayuse_multislot_enabled,
    has_enrolled_in_group_booking,
    is_eligible_for_group_booking,
    group_level_commission,
    hotel_suspected,
    is_dayuse_rate_linked,
    hotel_level_commission,
    locus_city_code,
    city_code,
    nav_state_code,
    is_child_allowed = false,
    new_child_policy_status = false,
    legal_entity_type,
    is_instagram_enabled,
    is_sustainability_enabled,
    restrict_max_child_age = true,
    is_new_linkage_enabled = false,
    blackDnd = false,
    minBlackBenefit,
    blackEnrolledOn,
    excludeUpgrade = false,
    blackMinDuration,
    minBlackDifference,
    duplicated_from,
    duplicated_from_property_name,
    duplicated_to,
    duplicated_to_property_name,
    is_onboarded_to_new_cp,
  } = hotelDetail;
  // Indian or domestic hotels
  const isDomHotel = currHotel.country.toLowerCase() === 'india';
  const isHomeStay = property_category.toLowerCase() === 'homestay';
  const isChain = !isNullOrUndefined(chain_id);
  //hiding for Oyo
  const hideSponsoredListing =
    isNullOrUndefined(mmt_id) || chain_id === 897 || !isactive;

  //Possible values - 'Hostapp Ingo', 'Hostapp MMT', 'HostApp GI', 'Ingo Legacy', 'Ingoexpress'
  const isHostApp = !isStaff && /hostapp/i.test(current_property_view);
  const hostWebMigration =
    isStaff && hostweb_migration === 'failed'
      ? 'not-eligible'
      : hostweb_migration;
  const isIngoExpress = /express/i.test(current_property_view);
  const blockedForExtranet =
    !showListings &&
    (hotel_suspected ||
      isHostApp ||
      isIngoExpress ||
      hostWebMigration === 'pending' ||
      hostWebMigration === 'failed');
  const isEntireFlow = isHomeStay && property_type === 'entire';

  const hideDirectBooking = offline_booking_blocked_from_admin || !isHomeStay;

  return {
    ...currHotel,
    activity_events,
    isDomHotel,
    isHomeStay,
    isEntireFlow,
    showListings,
    blockedForExtranet,
    isIngoExpress,
    hideSponsoredListing,
    mmt_id,
    locality,
    starrating,
    hotel_type,
    is_gostay,
    is_advantage,
    property_category,
    property_type,
    chain_id,
    isChain,
    is_eligible_for_loan,
    can_optin_for_gstn_invoice,
    gstn_assured,
    is_price_recommendation_enabled,
    timezone,
    timezoneOffset,
    pricing_model,
    max_infant_age,
    max_child_age,
    max_adolescent_age,
    checkintime,
    ec_counts,
    is_chat_enabled,
    is_rtb_enabled,
    show_rtb_settings,
    preapproved_rtb_segments,
    locationInfo: {
      address,
      latitude,
      longitude,
      zipcode,
      city,
      country,
    },
    loggedin_promo_amount,
    is_compt_analysis_enabled,
    is_user_hard_blocked,
    is_copy_optin,
    is_static_optin,
    isactive,
    is_host_detail_missing,
    is_host_missing,
    is_vcc,
    add_extraguest_to_declared_flag,
    source_partner,
    show_star_rating,
    show_sell_property_as,
    is_call_center_enabled,
    current_property_view,
    has_enrolled_in_group_booking,
    hotel_offline_booking_enabled,
    group_level_commission,
    offline_booking_blocked_from_admin,
    is_eligible_for_group_booking,
    hideDirectBooking,
    dayuse_booking_eligible,
    dayuse_multislot_enabled,
    hostweb_migration: hostWebMigration,
    hotel_suspected,
    hotel_level_commission,
    is_dayuse_rate_linked,
    locusCityCode: locus_city_code,
    hotelCityId: city_code,
    vendorStateCode: nav_state_code,
    is_child_allowed,
    new_child_policy_status,
    legalEntityType: legal_entity_type,
    is_instagram_enabled,
    is_sustainability_enabled,
    restrict_max_child_age,
    is_new_linkage_enabled,
    blackDnd,
    minBlackBenefit,
    blackEnrolledOn,
    excludeUpgrade,
    blackMinDuration,
    minBlackDifference,
    duplicated_from,
    duplicated_from_property_name,
    duplicated_to,
    duplicated_to_property_name,
    showNewCancellationPolicy: is_onboarded_to_new_cp,
  };
}

export function saleCampaignMetaObject(
  saleCampaign: CampaignHotelMappings,
): SaleCampaignMetaData {
  //object for meta data
  const data = saleCampaign?.campaign;
  const metaObject = {
    images: {
      campaignLongInlet: data?.campaignLongInlet,
      campaignMidInlet: data?.campaignMidInlet,
      campaignShortInlet: data?.campaignShortInlet,
      campaignLogo: data?.campaignLogo,
    },
    campaignEndTime: data?.campaignEndTime,
    campaignId: data?.campaignId,
    campaignName: data?.campaignName,
  };
  return metaObject;
}
