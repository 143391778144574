import { classNames } from 'utilities/Utils';
import './DashboardCarousel.scss';

type CustomIndicatorProps = {
  activeItem: number;
  itemCount: number;
  lastClick: 'left' | 'right' | '';
};

/*
The component displays a maximum of 5 indicator dots.
If the active item is among the first two, the corresponding dot(s) will be highlighted.
If the active item is among the last two, the last two dots (i.e., at index 3 and 4) will be highlighted.
For all other cases, the middle dot (at index 2) will be highlighted.
*/

export const CustomIndicator = (props: CustomIndicatorProps) => {
  const { activeItem, itemCount, lastClick } = props;

  const activeDotIndex = () => {
    if (itemCount <= 5 || activeItem <= 1) return activeItem;
    else if (activeItem >= itemCount - 2)
      return itemCount === activeItem + 1 ? 4 : 3;
    return 2;
  };

  return (
    <div className="absolute h-[10px] border-solid border-[0.5px] border-color-white/[0.36] bg-color-black/30 flex items-center px-[2px] gap-[2px] rounded-full w-fit overflow-hidden bottom-5 left-1/2 z-10 ">
      {Array.from({ length: Math.min(itemCount, 5) }).map((_, index) => (
        <div
          key={index}
          className={classNames(
            'rounded-full h-[6px]',
            activeDotIndex() === index
              ? `w-[15px] bg-color-white ${
                lastClick === 'right' ? 'animate-right' : 'animate-left'
              }`
              : 'w-[6px] bg-[#4d4d4d]',
          )}
        />
      ))}
    </div>
  );
};
