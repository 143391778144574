import React, { useEffect } from 'react';
import { BankDetailPersuasion } from '../../../property/bank-details/agreement-docs';
import { navigateTo } from 'utilities/Utils';
import { routes } from '../../../../app/routes';
import {
  useBankPersuasionType,
  useGlobalAccountStatus,
  ACCOUNT_STATUS,
} from '../../../property/bank-details/utils';
import { getBankPersuasions } from 'modules/dashboard/utils/DashboardAPIClient';
import { useFlagState } from '../../../../utilities/CustomHooks';
import { useState } from 'react';

function BankDetailsPersuasionSection(props) {
  const { hotel, onSectionLoadUpdate, gtmLabel } = props;
  const [
    isVisibleBankDetailsPersuasion,
    showBankDetailsPersuasion,
    hideBankDetailsPersuasion,
  ] = useFlagState(false);

  const [bankDetails, setBankDetails] = useState(null);

  const bankPersuasionType = useBankPersuasionType(bankDetails);
  const { globalAccountStatus, rejectReason } =
    useGlobalAccountStatus(bankDetails);

  useEffect(() => {
    if (
      bankPersuasionType &&
      globalAccountStatus &&
      globalAccountStatus !== ACCOUNT_STATUS.PENDING
    ) {
      showBankDetailsPersuasion();
      onSectionLoadUpdate(true, gtmLabel);
    } else {
      hideBankDetailsPersuasion();
      onSectionLoadUpdate(false, gtmLabel);
    }
  }, [bankPersuasionType, hotel.hotelId]);

  useEffect(() => {
    getBankPersuasions(hotel.hotelId)
      .then(res => {
        setBankDetails(res);
      })
      .catch(() => {
        onSectionLoadUpdate(false, gtmLabel);
      });
  }, [hotel.hotelId]);

  if (!(isVisibleBankDetailsPersuasion && bankDetails)) {
    return null;
  }

  return (
    <BankDetailPersuasion
      type={bankPersuasionType}
      rejectReason={rejectReason}
      onClose={hideBankDetailsPersuasion}
      openBankSection={() => navigateTo(routes.property.finance)}
    />
  );
}

export default BankDetailsPersuasionSection;
