import axios, { apiWrapper } from '../../base/APIClient';
import { INSTAY } from '../../base/Url';
import moment from 'moment';
import { getFormattedDate, getDiffInMinutes } from '../../utilities/DateUtils';
import { clearQueryParams } from 'utilities/Utils';
import { goibiboIcon, makeMyTripIcon } from 'assets/common';

export const getRequiredFields = issue => {
  const issueSubCat = [];
  const categories = [];
  let timeRemaining;
  let isResolutionInTime;
  if (!issue) {
    return null;
  }
  Object.keys(issue.complaint_data.category).forEach(category => {
    categories.push(category);
    issue.complaint_data.category[category].forEach(sub =>
      issueSubCat.push(sub),
    );
  });

  const subCategoriesText = issueSubCat.join('/ ');
  const categoriesText = categories.join(', ');
  if (issue.status === 1) {
    isResolutionInTime = !issue.is_overdue;
  } else {
    timeRemaining =
      issue.duration - getDiffInMinutes(moment(), issue.created_time);
    if (timeRemaining < 0) {
      timeRemaining = 0;
    }
  }

  let threshold;
  if (issue.time_remaining_threshold) {
    if (timeRemaining >= issue.time_remaining_threshold[1]) {
      threshold = 'green';
    } else if (timeRemaining < issue.time_remaining_threshold[1]) {
      threshold = 'red';
    } else {
      threshold = 'orange';
    }
  }

  if (timeRemaining > 60) {
    const hours = Math.floor(timeRemaining / 60);
    const minutes = timeRemaining % 60;
    timeRemaining = `${hours} hour ${minutes}`;
  }

  const media = [];

  issue.complaint_meta_data.media_urls.forEach(url => {
    const obj = { url: url };
    media.push(obj);
  });

  const data = {
    categoriesText: categoriesText,
    timeRemaining: timeRemaining,
    threshold: threshold,
    issueSubCat: issueSubCat,
    categories: categories,
    customerName: issue.booking_details.customer_details
      ? issue.booking_details.customer_details.customer_name
      : '',
    isPremium: issue.is_premium_user,
    reportedAt: getFormattedDate(issue.created_time, 'DD MMM, hh:mm A'),
    checkedIn: getFormattedDate(
      issue.booking_details.check_in,
      'DD MMM, hh:mm A',
    ),
    media: media,
    status: issue.status,
    complaintId: issue.complaint_id,
    overDue: issue.is_overdue || timeRemaining <= 0,
    createdDate: getFormattedDate(issue.created_time, 'DD MMM'),
    resolutionGivenTime: issue.resolution_related_data
      ? getFormattedDate(
        issue.resolution_related_data.resolution_time,
        'DD MMM, hh:mm A',
      )
      : null,
    resolutionText:
      issue.resolution_related_data &&
      issue.resolution_related_data.description,
    isResolutionInTime: isResolutionInTime,
    subCategoriesText: subCategoriesText,
    vendor:
      issue.booking_details.vendor === 'makemytrip'
        ? makeMyTripIcon
        : goibiboIcon,
    email: issue.booking_details.customer_details
      ? issue.booking_details.customer_details.customer_email
      : '',
    bookingId: issue.booking_details.booking_id,
    bookingDate: getFormattedDate(
      issue.booking_details.booking_date,
      'DD MMM, YYYY',
    ),
    checkOut: getFormattedDate(issue.booking_details.check_out, 'DD MMM, YYYY'),
    checkIn: getFormattedDate(issue.booking_details.check_in, 'DD MMM, YYYY'),
    mobileNumber: issue.booking_details.customer_details
      ? issue.booking_details.customer_details.customer_phone
      : '',
  };
  return data;
};

const parseData = (data, type = 0) => {
  if (type === 1) {
    const resTemp = [];
    const unResTemp = [];
    data.forEach(issue => {
      if (issue.status !== 1) {
        unResTemp.push(getRequiredFields(issue));
      } else {
        resTemp.push(getRequiredFields(issue));
      }
    });
    return [resTemp, unResTemp];
  }
  const temp = [];
  data.forEach(issue => {
    temp.push(getRequiredFields(issue));
  });
  return temp;
};

export const getInstayIssues = (
  hotelCode,
  nextURL,
  status,
  booking_id,
  vendor,
  is_overdue,
  unResolved,
  resolved,
  STRINGS,
  type,
  id,
  pathname,
  search,
  initial,
) => {
  let url = INSTAY.GET_INSTAY_ISSUES(hotelCode);
  const params = {};
  if (nextURL) {
    url = nextURL;
  } else {
    params.status =
      status && status.length > 0 ? JSON.stringify(status) : undefined;
    params.booking_id = booking_id || undefined;
    params.vendor = vendor || undefined;
    params.overdue = is_overdue || undefined;
  }
  return apiWrapper(
    axios.get(url, { params, useLoader: !params.booking_id }),
  ).then(res => {
    const values = {};
    if (
      status &&
      status.join('|') === [1].join('|') &&
      res.results.length === 0
    ) {
      values.activeType = STRINGS.UNRESOLVED;
    }
    const getCurrentIssue = clearQueryParams(pathname, search, 'id');
    if (getCurrentIssue) {
      values.currentIssue = parseInt(getCurrentIssue);
    } else if (id) {
      values.currentIssue = id;
    } else {
      values.currentIssue = 0;
    }
    if (!status || booking_id) {
      const parsedData = parseData(res.results, 1);
      if (parsedData[1].length !== 0) {
        let temp;
        if (type === 1 || !unResolved) {
          temp = [...parsedData[1]];
        } else {
          temp = [...unResolved];
          temp.push(...parsedData[1]);
        }
        values.unResolved = temp;
        values.nextPageUnRes = res.next;
        values.unResolvedCount = parsedData[1].length;
      } else {
        values.unResolved = [];
        values.unResolvedCount = 0;
      }
      if (parsedData[0].length !== 0) {
        let temp;
        if (type === 1 || !resolved) {
          temp = parsedData[0];
        } else {
          temp = [...resolved];
          temp.push(...parsedData[0]);
        }
        values.resolved = temp;
        values.nextPageRes = res.next;
        values.resolvedCount = parsedData[0].length;
      } else {
        values.resolved = [];
        values.resolvedCount = 0;
      }
    } else if (
      status.join('|') === [0].join('|') ||
      status.join('|') === [10].join('|') ||
      status.join('|') === [0, 10].join('|') ||
      status.join('|') === [10, 0].join('|')
    ) {
      if (!initial) {
        values.resolved = [];
        values.resolvedCount = 0;
      }
      const issues = [];
      res.results.forEach(issue => {
        issues.push(getRequiredFields(issue));
      });
      const parsedData = issues;
      if (parsedData.length !== 0) {
        let temp;
        if (type === 1 || !unResolved) {
          temp = parsedData;
        } else {
          temp = [...unResolved];
          temp.push(...parsedData);
        }
        values.unResolved = temp;
        values.nextPageUnRes = res.next;
        values.unResolvedCount = res.count;
      } else {
        values.unResolved = [];
        values.unResolvedCount = 0;
      }
    } else {
      const issues = [];
      res.results.forEach(issue => {
        issues.push(getRequiredFields(issue));
      });
      const parsedData = issues;
      if (parsedData.length !== 0) {
        let temp;
        if (type === 1 || !resolved) {
          temp = parsedData;
        } else {
          temp = [...resolved];
          temp.push(...parsedData);
        }
        values.resolved = temp;
        values.nextPageRes = res.next;
        values.resolvedCount = res.count;
      } else {
        values.resolved = [];
        values.resolvedCount = 0;
      }
    }
    return values;
  });
};

export const updateInstayIssues = (
  hotelCode,
  complaint_id,
  status,
  description,
) => {
  const url = INSTAY.UPDATE_INSTAY_ISSUES(hotelCode);
  const data = {
    complaint_id: complaint_id,
    status: status,
    description: description,
  };
  return apiWrapper(axios.post(url, data, { useLoader: true }))
    .then(res => {
      return { data: res };
    })
    .catch(err => err);
};
