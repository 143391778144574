import { useState, useEffect, useMemo } from 'react';
import { findValueByPath } from 'utilities/Utils';

import { legacyPushToGTM } from 'utilities/gtm';
import { goibiboIcon, makeMyTripIcon } from 'assets/common';

export const GST_NOC_ID = 277;

export const vendorLogo = {
  goibibo: goibiboIcon,
  makemytrip: makeMyTripIcon,
};

export const ACCOUNT_TYPES = [
  { label: 'Savings', value: 'saving' },
  { label: 'Current', value: 'current' },
];

export const BRANDS = {
  GOIBIBO: 'Goibibo',
  MAKEMYTRIP: 'MakeMyTrip',
};

export function isCorporate(item) {
  return item.is_corporate;
}

export function getErrorString(errors, fieldName) {
  return findValueByPath(errors, fieldName)?.message;
}

export function dispatchFormSubmit(ref) {
  ref.current.dispatchEvent(
    new Event('submit', { cancelable: true, bubbles: true }),
  );
}

export const ACCOUNT_STATUS = {
  PENDING: 'AWAITING FINANCE APPROVAL',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};
export const isAccountRejected = account =>
  account.status === ACCOUNT_STATUS.REJECTED;

export const isAccountPending = account =>
  account.status === ACCOUNT_STATUS.PENDING;

export const isAccountApproved = account =>
  account.status === ACCOUNT_STATUS.APPROVED;

export const isAccountStopped = account =>
  /^STOP/i.test(account.payment_term_code);

export const areAllAccountsStopped = (bankData = {}) => {
  if (bankData.bank_details?.length === 0) return false;
  return bankData.bank_details?.every(isAccountStopped);
};

export const isAnyAccountStopped = (bankData = {}) => {
  return bankData.bank_details?.some(isAccountStopped);
};

export const areAllAccountsRejected = accounts => {
  if (accounts.length === 0) return false;
  return accounts.every(isAccountRejected);
};

export const areAllAccountsPending = accounts => {
  if (accounts.length === 0) return false;
  return accounts.every(isAccountPending);
};

export const isAnyAccountPending = accounts => {
  return accounts.filter(isAccountPending);
};

export const areAllAccountsApproved = accounts => {
  if (accounts.length === 0) return false;
  return accounts.every(isAccountApproved);
};

export const isAnyAccountRejected = accounts => {
  const rejectedAccounts = accounts.filter(isAccountRejected);
  return {
    anyAccountRejected: rejectedAccounts.length > 0,
    rejectedAccounts,
  };
};

export function useBankPersuasionType(bankDetails) {
  const [type, setType] = useState();
  const { anyAccountRejected } = isAnyAccountRejected(
    bankDetails?.bank?.bank_details || [],
  );

  useEffect(() => {
    if (!bankDetails) {
      return;
    }
    const { hasBank } = bankDetails;
    //strict check with false, since dashboard persuasion API won't have that flag
    if (hasBank === false || anyAccountRejected) {
      setType('reject');
    } else if (areAllAccountsStopped(bankDetails.bank)) {
      setType('stoppay');
    } else {
      setType();
    }
  }, [bankDetails]);

  return type;
}

export function useToggleVendor() {
  const [hiddenVendor, setHiddenVendor] = useState(null);
  const hideVendor = brand => {
    if (brand === 'both') {
      return;
    }
    if (brand === BRANDS.GOIBIBO) {
      setHiddenVendor(BRANDS.MAKEMYTRIP);
    } else {
      setHiddenVendor(BRANDS.GOIBIBO);
    }
  };

  const showVendor = () => {
    setHiddenVendor(null);
  };

  return { hideVendor, showVendor, hiddenVendor };
}

export function useGlobalAccountStatus(bankDetails) {
  const allAccountsApproved = areAllAccountsApproved(
    bankDetails?.bank?.bank_details || [],
  );
  const anyAccountPending = isAnyAccountPending(
    bankDetails?.bank?.bank_details || [],
  );
  const { anyAccountRejected, rejectedAccounts } = isAnyAccountRejected(
    bankDetails?.bank?.bank_details || [],
  );

  const globalAccountStatus = useMemo(() => {
    if (allAccountsApproved) {
      return ACCOUNT_STATUS.APPROVED;
    }
    if (anyAccountPending.length > 0 && !anyAccountRejected) {
      return ACCOUNT_STATUS.PENDING;
    }
    if (anyAccountRejected) {
      return ACCOUNT_STATUS.REJECTED;
    }
  }, [allAccountsApproved, anyAccountPending, anyAccountRejected]);

  return {
    globalAccountStatus,
    rejectId: rejectedAccounts[0]?.id,
    rejectReason:
      rejectedAccounts.length === 0 ? null : rejectedAccounts[0].reject_reason,
    rejectReasonList:
      rejectedAccounts.length === 0
        ? []
        : rejectedAccounts[0].reject_reason_list,
  };
}

//Returns vendors whose bank details are not available (empty vendor)
export const hasAnyEmptyVendor = bank_details => {
  const emptyVendors = [];
  if (bank_details?.both) {
    return [];
  }

  if (!bank_details[BRANDS.GOIBIBO.toLowerCase()]) {
    emptyVendors.push(BRANDS.GOIBIBO);
  }

  if (!bank_details[BRANDS.MAKEMYTRIP.toLowerCase()]) {
    emptyVendors.push(BRANDS.MAKEMYTRIP);
  }

  return emptyVendors;
};

export const hasAgreementSigned = (hotelAgreements, agreementId) => {
  const agreement = hotelAgreements.find(
    item => item.agreement_id === agreementId,
  );
  if (agreement) {
    return agreement.status === 'accepted';
  }
  return false;
};

export const qcReasonIds = [26, 27, 28];
export const vendorReasonIds = [17, 18, 19, 20];

export const showStatusMessage = (rejectId, altAccoIncorrectReason) => {
  if (
    !altAccoIncorrectReason &&
    (rejectId || rejectId === 0) &&
    !qcReasonIds.includes(rejectId)
  ) {
    return false;
  }
  return true;
};

export function isRejectedBecauseOfMismatch(reasons = [], type = 'qc') {
  const reasonIds = type === 'qc' ? qcReasonIds : vendorReasonIds;
  return reasons.find(reason => reasonIds.indexOf(parseInt(reason.id)) > -1);
}

export function isRejecteBecauseOfIncorrectAltAccoDocs(reasons = []) {
  const _qcReasonIds = [14];
  return reasons.find(reason => _qcReasonIds.indexOf(parseInt(reason.id)) > -1);
}

export function isAdharValidationRequired(dirtyFields) {
  const { pan_details, bank_details, gst_details, tan_details } = dirtyFields;

  const skipAdharValidation = true;

  const aadharUpdatationString = [];

  if (pan_details?.pannumber) {
    aadharUpdatationString.push('PAN');
  }

  if (bank_details?.both.ifsc_code) {
    aadharUpdatationString.push('IFSC');
  }

  if (bank_details?.both.account_no) {
    aadharUpdatationString.push('ACCOUNT NUMBER');
  }

  if (bank_details?.both.acctype) {
    aadharUpdatationString.push('ACCOUNT TYPE');
  }

  if (gst_details?.gstnumber) {
    aadharUpdatationString.push('GST');
  }

  if (tan_details?.tannumber) {
    aadharUpdatationString.push('TAN');
  }

  // if (
  //   pan_details?.pannumber ||
  //   bank_details?.both.ifsc_code ||
  //   bank_details?.both.account_no ||
  //   bank_details?.both.acctype ||
  //   gst_details?.gstnumber ||
  //   tan_details?.tannumber
  // ) {
  //   skipAdharValidation = false;
  // }

  return { skipAdharValidation, aadharUpdatationString };
}

export function pushGTMEvent(gaObj) {
  legacyPushToGTM({
    eventCategory: 'web_property',
    ...gaObj,
  });
}

export const GTM_ACTIONS = {
  fnb_set_key: 'fnb_set_key',
  finance_bank_details: 'finance_bank_details',
  fnb_provide_key: 'fnb_provide_key',
  persuasions: 'persuasions',
  persuasions_click: 'persuasions_click',
  upload_lease: 'upload_lease',
  agreement_and_docs: 'agreement_and_docs',
  update_history: 'update_history',
  finance_details: 'finance_details',
  pan_card: 'pan_card',
};
