import React, { useEffect } from 'react';
import { pushToOmniture } from 'utilities/gtm';
import { Modal } from 'connect-web-ui';
import { useLangStrings } from '../../../../utilities/CustomHooks';

import './GstnPopUp.scss';

const GstnPopup = props => {
  const { onGstnClose, createCorporateBookingsTab } = props;
  const [STRINGS] = useLangStrings('Dashboard');
  useEffect(() => {
    pushToOmniture({
      event: 'pageView',
      pageName: 'bookings',
      pageType: 'Popup_GstnPopUp',
    });
  }, []);
  return (
    <Modal
      isOpen
      size="custom"
      footer={{
        primaryBtn: {
          text: STRINGS.GSTN_POPUP.VIEW_REQUIREMENTS_BTN,
          onClick: createCorporateBookingsTab,
        },
        secondaryBtn: {
          text: STRINGS.GSTN_POPUP.MAYBE_LATER_BTN,
          onClick: onGstnClose,
        },
        hideFooterDivider: true,
      }}
      customClasses={{ paper: 'z-50' }}
    >
      <div className="gstn-popup">
        <h2>{STRINGS.GSTN_POPUP.START_ACCEPTING_COPORATE_BOOKINGS}</h2>
        <p>
          <span>{STRINGS.GSTN_POPUP.BUSINESS_TRAVEL}</span>
          <span>
            {STRINGS.GSTN_POPUP.ENROLLING_YOUR_HOTEL_FOR_CORPORATE_SEGMENT}
          </span>
          <span>{STRINGS.GSTN_POPUP.YOUR_TURN_NOW}</span>
        </p>
      </div>
    </Modal>
  );
};

export default GstnPopup;
