import { doQuery } from 'base/GraphQLClient';
import { getParsedErrorMessageFromAPI, getValue } from 'utilities/Utils';

import { VERIFY_USER_EMAIL } from './landingPageGraphqlQueries';
import { VerifyUserEmailRequest } from './api';

export const verifyUserEmail = async (payload: VerifyUserEmailRequest) => {
  const variables = {
    rawEndpointRequestData: {
      getUserDetail: {
        modelType: 'modelType1',
        body: {
          columnName: 'email',
          columnData: payload.email,
        },
      },
    },
  };

  const { data, error } = await doQuery(VERIFY_USER_EMAIL, variables, {
    headers: { 'meta-data-brand': '' },
  });
  const errorCode = getValue(data, 'getUserDetail.getUserDetail.error_code');

  if (errorCode || error) {
    const errorMessage =
      getValue(data, 'getUserDetail.getUserDetail.display_message') ||
      getParsedErrorMessageFromAPI(error);

    return Promise.reject({
      errorCode,
      errorMessage,
    });
  }

  return {
    firstName: getValue(
      data,
      'getUserDetail.getUserDetail.basicInfo.firstName',
      '',
    ),
    lastName: getValue(
      data,
      'getUserDetail.getUserDetail.basicIngo.lastName',
      '',
    ),
  };
};
