import { routes } from 'app/routes';
import { TABLE } from 'interfaces/Strings';
import { transformBlackoutDates } from 'modules/promotions-and-coupons/utils';
import { useEffect, useState } from 'react';
import {
  getDateDifference,
  getFormattedDate,
  millisToMinutesAndSeconds,
} from 'utilities/DateUtils';
import { campaigns } from './SaleHandling.constants';
import {
  CampaignCategory,
  ExistingOfferValue,
  FinalDiscountTable,
  OfferCategories,
} from './SaleHandling.types';
import {
  apiDateFormat,
  mmtBlackSegments1_2,
} from 'modules/promotions-and-coupons/PnC.constants';
import {
  DealDataInterface,
  DEAL_TYPE_LABEL,
  OFFER_VALUE_SEGMENT_TYPE,
} from 'interfaces/PncContextInterface';

export const createTable = (
  key: typeof campaigns[keyof typeof campaigns],
  STRINGS: TABLE,
  existingDiscounts,
  discount: number,
): FinalDiscountTable => {
  switch (key) {
    case campaigns.mobile:
    case campaigns.coupon:
      return {
        header: [
          {
            heading: STRINGS.EXISTING_DISCOUNT.replace(
              '__category__',
              key,
            ).toLowerCase(),
          },
          {
            heading: STRINGS.FINAL_DISCOUNT.replace(
              '__category__',
              key,
            ).toLowerCase(),
            tooltip: true,
          },
        ],
        rows: [
          [
            {
              value: `${existingDiscounts}%`,
              lock: true, //to show lock icon
            },
            {
              value:
                typeof existingDiscounts === 'number'
                  ? `${existingDiscounts + discount}%`
                  : '',
              showShine: true,
            },
          ],
        ],
      };
    case campaigns.mmtBlack:
      return {
        header: [
          {
            heading: STRINGS.MMT_BLACK,
          },
          {
            heading: STRINGS.EXISTING_DISCOUNT.replace('__category__', ''),
          },
          {
            heading: STRINGS.FINAL_DISCOUNT.replace('__category__', ''),
            tooltip: true,
          },
        ],
        rows: [
          [
            {
              value: STRINGS.GOLD,
            },
            {
              value: `${existingDiscounts?.mmtBlack1}%`,
              lock: true, //to show lock icon
            },
            {
              value: `${existingDiscounts?.mmtBlack1 + discount}%`,
              showShine: true,
            },
          ],
          [
            {
              value: STRINGS.PLATINUM,
            },
            {
              value: `${existingDiscounts?.mmtBlack2}%`,
              lock: true, //to show lock icon
            },
            {
              value: `${existingDiscounts?.mmtBlack2 + discount}%`,
              showShine: true,
            },
          ],
        ],
      };
    default:
      return null;
  }
};

export const createExistingDiscounts = (
  offerType: typeof campaigns[keyof typeof campaigns],
  offerMetaData: OfferCategories,
): ExistingOfferValue => {
  let existingOfferValue: ExistingOfferValue = 0;

  const mmtBlackDiscounts = offerMetaData?.CUG?.offerCategories?.mmtBlack;
  const mobileDiscount =
    offerMetaData?.CUG?.offerCategories?.mobile.maxValueExistingOffer;
  const couponDiscount =
    offerMetaData?.COUPONS?.offerCategories?.customised?.maxValueExistingOffer;
  switch (offerType) {
    case campaigns.mmtBlack:
      if (!mmtBlackDiscounts) {
        existingOfferValue = {
          mmtBlack1: 0,
          mmtBlack2: 0,
        };
      } else {
        const segData = mmtBlackDiscounts?.segmentData;
        existingOfferValue = {
          mmtBlack1: segData?.mmtBlack1?.maxValueExistingOffer ?? 0,
          mmtBlack2: segData?.mmtBlack2?.maxValueExistingOffer ?? 0,
        };
      }
      break;

    case campaigns.mobile:
      if (!mobileDiscount) {
        existingOfferValue = 0;
      } else {
        existingOfferValue = mobileDiscount;
      }
      break;

    case campaigns.coupon:
      if (couponDiscount) {
        existingOfferValue = couponDiscount;
      } else {
        existingOfferValue = 0;
      }
      break;

    default:
      existingOfferValue = 0;
  }
  return existingOfferValue;
};

export const createBlackOutObjects = (dates: string): string[] | string => {
  const arr = transformBlackoutDates(dates); // using exiting function to get array of strings
  const dateArray = [];
  if (arr === '') {
    return arr;
  }
  arr.forEach(tuple => {
    dateArray.push(new Date(tuple[0]));
  });
  return dateArray; // [[date,date], [date,date]] -> [date,date,date,date]
};

export const saleHanldingLink = (id, offerCode = null) => {
  if (offerCode) {
    return `${routes.sale_handling.base}/?campaignId=${id}&offerCode=${offerCode}`;
  }
  return `${routes.sale_handling.base}/?campaignId=${id}`;
};

export const finalDiscount = (
  type: CampaignCategory,
  existingDiscounts,
  additionalDiscount: number,
) => {
  switch (type) {
    case campaigns.mmtBlack:
      return {
        MMT_BLACK1: Number(existingDiscounts.mmtBlack1) + additionalDiscount,
        MMT_BLACK2: Number(existingDiscounts.mmtBlack2) + additionalDiscount,
      };
    case campaigns.coupon:
      return {
        [campaigns.coupon]: Number(existingDiscounts) + additionalDiscount,
      };
    case campaigns.mobile:
      return {
        [campaigns.mobile]: Number(existingDiscounts) + additionalDiscount,
      };
    default:
      return null;
  }
};

export const hasCampaignExpired = (campaignEndTime: string): boolean => {
  const diff = getDateDifference(
    new Date(campaignEndTime),
    new Date(),
    'seconds',
  );
  return diff <= 0;
};

export const CountDownTimer = ({ timer = 60000, timerEnded }) => {
  const [time, setTime] = useState(timer);
  const { minutes, seconds, hours, days } = millisToMinutesAndSeconds(
    time,
    'days',
  ); //added days

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(prev => {
        if (prev < 2000) {
          clearInterval(interval);
          timerEnded();
          return 0;
        }
        return prev - 1000;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return [days, hours, minutes, seconds];
};

export const apiFormatBlackoutDates = (
  dates: string[],
  isUpdateFlow = false,
) => {
  if (!dates || !dates.length) {
    return isUpdateFlow ? [''] : undefined;
  }
  const dateArray = [];
  dates.forEach(date => {
    dateArray.push(
      `${getFormattedDate(date, apiDateFormat)},${getFormattedDate(
        date,
        apiDateFormat,
      )}`,
    );
  });
  return dateArray;
};

export const findExistingDiscounts = (
  offerType: typeof campaigns[keyof typeof campaigns],
  offerDataList: DealDataInterface[],
  additionalDiscount: number = 0,
): ExistingOfferValue => {
  switch (offerType) {
    case campaigns.coupon: {
      //find coupon in the list
      const couponOffer = offerDataList.find(
        item => item.dealType === DEAL_TYPE_LABEL.coupon,
      );
      return (
        (couponOffer?.offerValueDataList[0]?.offerValue ?? 0) -
        additionalDiscount
      );
    }
    case campaigns.mobile: {
      //find mobile
      const mobileOffer = offerDataList.find(
        item =>
          item.dealType === DEAL_TYPE_LABEL.cug &&
          item.offerValueDataList[0].segment ===
            OFFER_VALUE_SEGMENT_TYPE.MOBILE,
      );
      return (
        (mobileOffer?.offerValueDataList[0]?.offerValue ?? 0) -
        additionalDiscount
      );
    }
    case campaigns.mmtBlack: {
      //find mmt black
      const mmtBlackOffer = offerDataList.find(
        item =>
          item.dealType === DEAL_TYPE_LABEL.cug &&
          !!item.offerValueDataList.find(offer =>
            mmtBlackSegments1_2.includes(offer?.segment),
          ),
      );
      const offerValue = mmtBlackOffer.offerValueDataList ?? [];
      const mmtBlack1Value =
        (offerValue.find(
          offer => offer.segment === OFFER_VALUE_SEGMENT_TYPE.MMT_BLACK1,
        )?.offerValue ?? 0) - additionalDiscount;
      const mmtBlack2Value =
        (offerValue.find(
          offer => offer.segment === OFFER_VALUE_SEGMENT_TYPE.MMT_BLACK2,
        )?.offerValue ?? 0) - additionalDiscount;
      return {
        mmtBlack1: mmtBlack1Value > 0 ? mmtBlack1Value : 0,
        mmtBlack2: mmtBlack2Value > 0 ? mmtBlack2Value : 0,
      };
    }
    default:
      return {};
  }
};
