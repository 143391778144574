import { createRoot } from 'react-dom/client';
import { checkAndLogGtmLoginEvent } from 'app/hotel/legacyParity';

import Main from './MainWrapper';
import initializeWebVitalsLogging from 'utilities/webVitals';
import { initializePdt } from 'utilities/pdt';

const container = document.getElementById('new-react-shell');
const root = createRoot(container);

checkAndLogGtmLoginEvent();
initializePdt();
initializeWebVitalsLogging();

root.render(<Main />);
