// import {
//   FacebookIcon,
//   InstagramIcon,
//   XIcon,
//   YoutubeIcon,
// } from 'assets/modules/landing';
import {
  FooterBarInner,
  FooterBarWrapper,
  // SocialLinks,
} from './FooterBar.styles';
import { LandingPageContainer } from 'modules/landingPage/LandingPage.styles';
import {
  COPYRIGHT_MSG,
  // EXTERNAL_URLS
} from 'modules/landingPage/constants';

const FooterBar = () => {
  return (
    <FooterBarWrapper>
      <LandingPageContainer>
        <FooterBarInner>
          <p>{COPYRIGHT_MSG}</p>
          {/* <SocialLinks>
            <li>
              <a
                href={EXTERNAL_URLS.FACEBOOK}
                aria-label="Facebook"
                target="_blank"
              >
                <FacebookIcon />
              </a>
            </li>
            <li>
              <a
                href={EXTERNAL_URLS.TWITTER}
                aria-label="twitter"
                target="_blank"
              >
                <XIcon />
              </a>
            </li>
            <li>
              <a
                href={EXTERNAL_URLS.INSTAGRAM}
                aria-label="Instagram"
                target="_blank"
              >
                <InstagramIcon />
              </a>
            </li>
            <li>
              <a
                href={EXTERNAL_URLS.YOUTUBE}
                aria-label="Youtube"
                target="_blank"
              >
                <YoutubeIcon />
              </a>
            </li>
          </SocialLinks> */}
        </FooterBarInner>
      </LandingPageContainer>
    </FooterBarWrapper>
  );
};

export default FooterBar;
