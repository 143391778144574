import { routes } from 'app/routes';
import * as OpportunityCenter from '../../assets/modules/dashboard';

export const DYNAMIC_ROUTE = 'dynamic_route';
export const DYNAMIC_POPUP = 'popup';

export type PersuasionCategories =
  | 'NEW TASKS'
  | 'LISTING QUALITY'
  | 'PROMOTION IMPACT'
  | 'CUSTOMER RESPONSIVENESS';

type Categories = {
  [K in PersuasionCategories]: { img: string; title: string };
};

export const categoryOrder = [
  'LISTING QUALITY',
  'PROMOTION IMPACT',
  'NEW TASKS',
  'CUSTOMER RESPONSIVENESS',
];

export const opportunityCategoryDataMapping: Categories = {
  'LISTING QUALITY': {
    img: OpportunityCenter.ListingQuality,
    title: 'Improve listing quality to drive visibility and traffic',
  },
  'PROMOTION IMPACT': {
    img: OpportunityCenter.PromotionImpact,
    title: 'Maximize Promotion Impact to drive more bookings for your property',
  },
  'NEW TASKS': {
    img: OpportunityCenter.NewTasks,
    title: 'Participate in new tasks to target incremental room nights',
  },
  'CUSTOMER RESPONSIVENESS': {
    img: OpportunityCenter.CustomerChats,
    title: 'Respond to customer chats and reviews',
  },
};
export const routeMapping = ({
  isDerbyOrSynxis,
  hotelcode,
  showListings,
  showNewContentScore,
}) => {
  const routeType = showListings ? 'listing' : 'property';
  const contentScoreType = showNewContentScore
    ? 'newContentScore'
    : showListings
      ? 'base'
      : 'contentScore';

  return {
    content: {
      missing: routes[routeType][contentScoreType],
      host_profile_missing: routes.userManagement.team,
    },
    coupon: {
      not_active: routes.businessBooster.pnc.create,
    },
    dayuse: {
      enrollment: routes.dayUse.base,
    },
    direct_booking: {
      enrollment: routes.property.directBooking,
    },
    disparity: {
      cancellation: `${routes.competitiveAnalysis.base}?sub_type=cancellation`,
      mealplan: `${routes.competitiveAnalysis.base}?sub_type=mealplan`,
      rate: `${routes.competitiveAnalysis.base}?sub_type=rate`,
      sold_out: `${routes.competitiveAnalysis.base}?sub_type=soldOut`,
    },
    group_booking: {
      enrollment: routes.groupBookings.base,
    },
    guest_chat: {
      reply_pending: routes.guestchat.all,
    },
    inventory: {
      blocked: routes.inventory.all,
      blocked_high_demand: routes.inventory.low,
      no_calendar_link: routes.inventory.all,
      sold_out: routes.inventory.all,
      low: routes.inventory.all,
    },
    mmt_black: {
      inclusion: routes.property.mmtBlackInclusions,
    },
    mmt_select: {
      inclusion: routes.property.mmtSelectInclusions,
    },
    promotion: {
      coupon_expiring: DYNAMIC_POPUP,
      deactivation_exceeded: routes.businessBooster.pnc.expired,
      not_applied: routes.businessBooster.pnc.create,
      offer_expiring: routes.businessBooster.pnc.active,
      lower_discount_than_basic: routes.businessBooster.pnc.active,
      mypartner_inactive: routes.businessBooster.pnc.create,
    },
    quality_score: {
      tasks: routes.qualityScore.base,
    },
    rtb: {
      to_instant_book: showListings
        ? routes.listing.base
        : routes.property.reservation,
      requests: DYNAMIC_ROUTE,
    },
    corporate_invoices: {
      upload_pending: isDerbyOrSynxis
        ? routes.bookings.corporate
        : routes.bookings.persuasions.pendingInvoices,
    },
    free_cancellation: {
      enrollment: `${routes.property.cancellation.base}?type=add`,
    },
    external_cal: {
      link_expired: routes.inventory.calSync,
      link_expiring_soon: routes.inventory.calSync,
      no_sync: routes.inventory.calSync,
      sync_failed: routes.inventory.calSync,
    },
    instagram_vibes: {
      connect: showListings
        ? routes.listing.propertyVibe
        : routes.property.vibe,
    },
    pricing: {
      child_rate_missing: `${routes.inventory.default}?pageType=static&openFirst=true`,
    },
    listing: {
      duplicate: `${routes.duplicateListing.create}?hotel_id=${hotelcode}`,
    },
  };
};
