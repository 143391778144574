import React, { useEffect, useState } from 'react';
import {
  LegacyFormElement as FormElement,
  Box,
  Button,
  __LegacyDropdown__,
  Dropdown,
  LegacyMultiLineInput,
  Typography,
  Divider,
} from 'connect-web-ui';
import { getConfigOptions } from '../HelpCentreGraphClient';
import { actionTypes } from '../HelpCentre.reducer';
import {
  helpCenterPushGTM,
  renderSearchOptions,
  sanitizeConfigOptions,
} from '../HelpCentre.utils';
import { showToast } from 'utilities/Utils';
import { ChevronDown } from 'assets/common';
import { EmailIcon, PhoneIcon } from 'assets/modules/bookings';

const designationMapping = {
  contractbdo: 'Business Development Manager',
  contractmanager: 'Zonal Manager',
};

export const CreateTicket = props => {
  const {
    state,
    dispatch,
    setMainScreen,
    STRINGS,
    onDrawerClose,
    handleSubmit,
    contactDetails,
  } = props;
  const [combineStringsOptions, setCombineStringsOptions] = useState([]);
  const [issues, setIssues] = useState([]);
  const [viewContactDetails, setViewContactDetails] = useState(false);

  useEffect(() => {
    if (state.hardRefresh.create_ticket) {
      getConfigOptions()
        .then(res => {
          if (
            res.data.configKeeperData &&
            res.data.configKeeperData.status === 'success'
          ) {
            const data = res.data.configKeeperData?.data?.ticket_groups_INGO
              ?.data ?? { subcategories: [] };
            const categories = data.subcategories.map(sub => ({
              label: sub.name,
              options: sub.options,
            }));
            setCombineStringsOptions(sanitizeConfigOptions(categories));
            dispatch({
              type: actionTypes.UPDATE_STATE,
              data: {
                categories,
                hardRefresh: {
                  ...state.hardRefresh,
                  create_ticket: false,
                },
              },
            });
          } else showToast(STRINGS.SOMETHING_WENT_WRONG, 'error');
        })
        .catch(() => {
          showToast(STRINGS.SOMETHING_WENT_WRONG, 'error');
        });
    } else {
      setCombineStringsOptions(sanitizeConfigOptions(state.categories));
    }
  }, []);

  const handleCategory = (value, selectedIssue = '') => {
    const { label, options } = value;
    setIssues(options);
    dispatch({
      type: actionTypes.UPDATE_STATE,
      data: {
        selectedIssue,
        selectedCategory: label,
      },
    });
  };

  const handleSearchInput = value => {
    helpCenterPushGTM('CTA search', 'click');
    if (!value) {
      dispatch({
        type: actionTypes.RESET,
      });
    }
    if (combineStringsOptions.includes(value)) {
      const [category, issue] = value.split('>').map(x => x.trim());
      dispatch({
        type: actionTypes.UPDATE_STATE,
        data: { search: value },
      });
      const selectedOptions = state.categories.find(c => c.label === category);
      if (selectedOptions) {
        handleCategory(selectedOptions, issue);
      }
    }
  };

  const handleIssues = selectedIssue => {
    dispatch({
      type: actionTypes.UPDATE_STATE,
      data: { selectedIssue },
    });
  };

  const navigateToMyIssue = () => {
    helpCenterPushGTM('CTA my_issues', 'click');
    setMainScreen(false);
  };

  const renderContactDetails = () => {
    return Object.keys(contactDetails).map((contact, idx) => {
      if (
        typeof contactDetails[contact] !== 'object' ||
        contactDetails[contact] === null
      )
        return null;
      return (
        <Box
          className={`px-4 py-3 bg-color-white rounded-lg border border-common-dividerDark border-solid ${
            idx === 0 && 'my-4'
          }`}
        >
          <Typography fb>{contactDetails[contact]?.name}</Typography>
          <Typography variant="subtitle1" themeColor="gray.main">
            {designationMapping[contact]}
          </Typography>
          <Divider className="!bg-color-dividerLight -mx-4 mt-2" />
          {!!contactDetails[contact]?.mobile && (
            <Box className="mt-4 flex items-center">
              <img src={PhoneIcon} className="-ml-1" alt="phone" />
              <Typography className="text-primary-default ml-3">
                {contactDetails[contact].mobile}
              </Typography>
            </Box>
          )}
          {!!contactDetails[contact]?.email && (
            <Box className="mt-4 mb-1 flex items-center">
              <img src={EmailIcon} alt="email" />
              <a
                className="text-primary-default ml-3"
                href={`mailto:${contactDetails[contact].email}`}
              >
                {contactDetails[contact].email}
              </a>
            </Box>
          )}
        </Box>
      );
    });
  };

  const toggleContactDetails = () => {
    const gtmEventAction = viewContactDetails
      ? 'bdm_contact_details_close'
      : 'bdm_contact_details_open';
    helpCenterPushGTM(gtmEventAction, 'click');
    setViewContactDetails(prev => !prev);
  };

  return (
    <div className="create-ticket-container">
      <div className="header">
        <div className="font18 fb">
          {STRINGS.HELP_CENTRE_MAIN_SCREEN.TITLE.RAISE_A_TICKET}
        </div>
        <Button
          variant="text"
          onClick={navigateToMyIssue}
          className="font-normal link uppercase"
        >
          {STRINGS.HELP_CENTRE_MAIN_SCREEN.MY_ISSUES}
        </Button>
      </div>
      <FormElement
        label={STRINGS.HELP_CENTRE_MAIN_SCREEN.FORM_FIELDS.SEARCH.SEARCH_ISSUES}
        className="sr-width100 marginTB10"
        tooltip={
          <div>
            {STRINGS.HELP_CENTRE_MAIN_SCREEN.FORM_FIELDS.SEARCH.TOOLTIP}
          </div>
        }
        tooltipBgBlack={true}
      >
        <__LegacyDropdown__
          searchable
          placeholder={
            STRINGS.HELP_CENTRE_MAIN_SCREEN.FORM_FIELDS.SEARCH.PLACEHOLDER
          }
          options={combineStringsOptions}
          value={state.search}
          onChange={handleSearchInput}
          renderOption={renderSearchOptions}
          noOptionsText={STRINGS.NO_RESULTS_FOUND}
        />
      </FormElement>
      <div>{STRINGS.HELP_CENTRE_MAIN_SCREEN.OR}</div>
      <div className="create-ticket-ddl-container marginT5">
        <FormElement
          label={STRINGS.HELP_CENTRE_MAIN_SCREEN.FORM_FIELDS.ISSUE_CATEGORY}
          className="sr-width100"
          errorMsg={state.errors?.selectedCategory ?? null}
        >
          <Dropdown
            disableClearable
            options={state.categories}
            value={state.selectedCategory}
            onChange={handleCategory}
            variant="default"
          />
        </FormElement>
        <FormElement
          label={STRINGS.HELP_CENTRE_MAIN_SCREEN.FORM_FIELDS.ISSUE_DETAIL}
          className="sr-width100"
          errorMsg={state.errors?.selectedIssue ?? null}
        >
          <Dropdown
            disableClearable
            options={issues}
            value={state.selectedIssue}
            onChange={handleIssues}
            disabled={!state.selectedCategory}
            variant="default"
          />
        </FormElement>
      </div>
      <div className="marginT10">
        <FormElement
          label={`${
            STRINGS.HELP_CENTRE_MAIN_SCREEN.FORM_FIELDS.EXPLAIN_YOUR_ISSUE
          } ${
            /others/i.test(state.selectedIssue)
              ? STRINGS.HELP_CENTRE_MAIN_SCREEN.FORM_FIELDS.REQUIRED
              : STRINGS.HELP_CENTRE_MAIN_SCREEN.FORM_FIELDS.OPTIONAL
          }`}
          className="sr-width100"
          errorMsg={state.errors?.issue_details ?? null}
        >
          <LegacyMultiLineInput
            value={state.issue_details}
            onChange={({ value }) =>
              dispatch({
                type: actionTypes.UPDATE_STATE,
                data: {
                  issue_details: value,
                },
              })
            }
            className="whiteBg"
            disabled={!state.selectedIssue}
          />
        </FormElement>
      </div>
      <Box className="flex justify-end">
        <Button variant="outlined" className="mr-4" onClick={onDrawerClose}>
          {STRINGS.HELP_CENTRE_MAIN_SCREEN.FORM_FIELDS.CLOSE}
        </Button>
        <Button onClick={handleSubmit}>
          {STRINGS.HELP_CENTRE_MAIN_SCREEN.FORM_FIELDS.SUBMIT}
        </Button>
      </Box>

      <Box className="mt-6 p-5 -mr-5 -ml-5 bg-color-light border-t-common-dividerLight border-b-common-dividerLight border-solid border-r-0 border-l-0 border-t border-b contact-transition">
        <Box className="flex justify-between">
          <Typography>{STRINGS.CONTACT_DETAILS}</Typography>
          <ChevronDown
            className={`cursor-pointer transition-chevron ${
              viewContactDetails ? 'rotate-chevron' : ''
            }`}
            onClick={toggleContactDetails}
          />
        </Box>
        {viewContactDetails && renderContactDetails()}
      </Box>
    </div>
  );
};
