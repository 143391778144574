import { Modal } from 'connect-web-ui';
import { useLangStrings } from 'utilities/CustomHooks';

import { legacyPushToGTM } from 'utilities/gtm';

const eventCategory = 'web_boostperformance';

export default function DiscardModal(props) {
  const { gtmActionType, onDrawerClose, setShowCancelModal } = props;

  const [STRINGS] = useLangStrings('PerformanceBooster');

  const discardConfirm = () => {
    const eventAction = `${gtmActionType}_cancel`;
    legacyPushToGTM({
      eventCategory,
      eventAction,
      eventLabel: 'keep_editing',
    });
    setShowCancelModal(false);
  };

  const discardCancel = () => {
    const eventAction = `${gtmActionType}_cancel`;
    legacyPushToGTM({
      eventCategory,
      eventAction,
      eventLabel: 'discard',
    });
    onDrawerClose();
  };

  return (
    <Modal
      size="small"
      variant="confirm"
      isOpen
      header={{
        title: STRINGS.DISCARD_MODAL.TITLE,
        hideCross: true,
      }}
      footer={{
        primaryBtn: {
          text: STRINGS.DISCARD_MODAL.POSITIVE_TEXT,
          onClick: discardConfirm,
        },
        secondaryBtn: {
          text: STRINGS.DISCARD_MODAL.NEGATIVE_TEXT,
          onClick: discardCancel,
        },
      }}
    >
      <p className="marginB8">{STRINGS.DISCARD_MODAL.BODY}</p>
    </Modal>
  );
}
