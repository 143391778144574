import {
  LegacyFormElement as FormElement,
  ControlledInput,
  Modal,
  ControlledCheckbox,
  ControlledRadio,
} from 'connect-web-ui';

import { updateTanNumber } from './HotelAPIClient';
import { useForm, useWatch } from 'react-hook-form';
import { useLangStrings } from '../../utilities/CustomHooks';
import { showMessage } from 'utilities/Utils';
interface HotelTanModalProps {
  hotelCode: string;
  onClose: () => void;
}

const options = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

type FormInputs = {
  tan_checkbox: 'yes' | 'no';
  tan_status: boolean;
  tan_number: string;
};

export default function HotelTanModal(props: HotelTanModalProps) {
  const { onClose, hotelCode } = props;
  const {
    control,
    handleSubmit,

    formState: { errors },
  } = useForm<FormInputs>({ shouldUnregister: true });
  const { tan_checkbox, tan_status, tan_number } = useWatch({
    control,
  });
  const [{ HOTEL_TAN: STRINGS }, COMMON] =
    useLangStrings<'Dashboard'>('Dashboard');

  const showError = () => {
    showMessage({
      show: true,
      message: COMMON.MESSAGES.SOMETHING_WENT_WRONG,
      type: 'error',
    });
  };
  const onSubmit = (payload: FormInputs) => {
    const finalPayload = new FormData();
    finalPayload.append('hotelcode', hotelCode);

    if (payload.tan_checkbox === 'yes') {
      finalPayload.append('tan_number', payload.tan_number);
      finalPayload.append('tan_status', 'false');
    } else {
      finalPayload.append('tan_number', '');
      finalPayload.append('tan_status', `${payload.tan_status}`);
    }

    updateTanNumber(finalPayload)
      .then(({ data }: { data }) => {
        if (!data.showpopup) {
          onClose();
        }
      })
      .catch(showError);
  };

  return (
    <Modal
      isOpen
      header={{
        title: 'TAN Number',
        hideCross: true,
      }}
      footer={{
        primaryBtn: {
          text: 'I accept',
          onClick: handleSubmit(onSubmit),
          disabled: !(tan_status || tan_number),
        },
      }}
      onClose={onClose}
    >
      <div>
        <p>
          {STRINGS.GREET},
          <p className="marginT15 justify-center">
            {STRINGS.HOTELIER_MESSAGE_1}
          </p>
          <p>{STRINGS.HOTELIER_MESSAGE_2}</p>
        </p>

        <FormElement
          label="Do you have a TAN number?"
          className="sr-flex-row sr-align-center width100"
          isRequired
        >
          <ControlledRadio
            name="tan_checkbox"
            options={options}
            control={control}
            defaultValue="yes"
            inline
          />
        </FormElement>

        {tan_checkbox === 'no' ? (
          <ControlledCheckbox name="tan_status" control={control} isRequired>
            {STRINGS.CHECKBOX}
          </ControlledCheckbox>
        ) : (
          <FormElement
            label="TAN Number"
            errorMsg={errors.tan_number?.message}
            isRequired
          >
            <ControlledInput
              name="tan_number"
              type="text"
              control={control}
              placeholder="TAN Number (Eg: DELA99999B)"
              pattern={/^[A-Z]{4}[0-9]{5}[A-Z]{1}$/i}
              isRequired
            />
          </FormElement>
        )}
      </div>
    </Modal>
  );
}
