import { ReactComponent as InventoryImg } from './ratesTabImage.svg';
import { ReactComponent as BookingImg } from './bookingImage.svg';
import { ReactComponent as PaymentsImg } from './payments.svg';
import { ReactComponent as PromotionsImg } from './promotionTabImage.svg';
import { ReactComponent as DashBoardImg } from './dashboardTabImage.svg';
import { ReactComponent as PlbImg } from './marginOfferTabImage.svg';
import { ReactComponent as PBImg } from './perfBoosterTabImage.svg';
import { ReactComponent as PropImg } from './propertyTabImage.svg';
import { ReactComponent as InvoiceImg } from './invoiceTabImage.svg';
import { ReactComponent as ReviewsImg } from './reviewsTabImage.svg';
import { ReactComponent as AnalyticsImg } from './analyticsTabImage.svg';
import { ReactComponent as CompetitiveAnalysisImg } from './competitveAnalysisTabImages.svg';
import { ReactComponent as SponsporedListingImg } from './sponsoredListingTabImages.svg';
import { ReactComponent as GuestChatImg } from './guestChatImage.svg';
import { ReactComponent as MoreImage } from './moreImage.svg';
import { ReactComponent as NewOfferImg } from './newOffering.svg';
import { ReactComponent as PrebuyLedgerImg } from './prebuyLedger.svg';

import { default as arrowLogo } from './arrowLogo.svg';
import { default as goLogo } from './goLogo.png';
import { default as searchLogo } from './searchLogo.png';
import { default as previewLogo } from './previewLogo.png';
import { default as starLogo } from './starLogo.png';
import { ReactComponent as NestedArrow } from './nestedArrow.svg';

const TabIcons = {
  InventoryImg,
  BookingImg,
  PaymentsImg,
  PromotionsImg,
  DashBoardImg,
  PlbImg,
  PBImg,
  PropImg,
  InvoiceImg,
  ReviewsImg,
  AnalyticsImg,
  CompetitiveAnalysisImg,
  SponsporedListingImg,
  GuestChatImg,
  MoreImage,
  NewOfferImg,
  PrebuyLedgerImg,
};

export {
  arrowLogo,
  goLogo,
  searchLogo,
  previewLogo,
  starLogo,
  TabIcons,
  NestedArrow,
};
