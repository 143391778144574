import { doMutation } from 'base/GraphQLClient';
import { getValue } from 'utilities/Utils';
import { GET_CONTENT_SCORE } from './ContentScoreRevampQueries';
import { isProd } from 'base/APIClient';

type ContentScoreVariableParams = {
  mmtId: number | string;
  hotelCode?: string;
  getOnlyContentScore?: boolean;
};

type ContentScoreApiParams = ContentScoreVariableParams & {
  query?: string;
};

const getContentScoreBody = (mmtId: string | number) => ({
  hotelId: mmtId,
  comparison: ['compset'],
});

const getAnalyticsV1PersuasionsBody = (mmtId: string | number) => ({
  pipeline: isProd ? 'persuasions_v2' : 'testing_persuasions_v2',
  include: {
    hotelId: mmtId,
    type: 'contentscore',
  },
  exclude: {
    status: 'expired',
  },
  size: 10,
  sort: {
    priority: 'asc',
  },
  group_by: ['data.action_category', 'data.content_level', 'data.room_name'],
  fields: {
    include: [
      'type',
      'sub_type',
      'id',
      'priority',
      'title',
      'consumers.content_score_web',
      'data.action_category',
      'data.action_sub_category',
      'data.room_name',
      'data.content_level',
      'status',
    ],
  },
});

const getAverageContentScoreBody = (hotelCode: string) => ({
  pipeline: 'contentscore',
  include: {
    'hotelNode.hotelCode': hotelCode,
  },
  KPI: [
    {
      totalContentScore: 'avg',
    },
  ],
  comparison: ['compset'],
});

export const getContentScoreVariables = (
  params: ContentScoreVariableParams,
) => {
  const { mmtId, hotelCode = '', getOnlyContentScore = false } = params;

  const getContentScore = {
    modelType: 'modelType1',
    endPointId: 1,
    body: getContentScoreBody(mmtId),
  };

  if (getOnlyContentScore) {
    return {
      GetContentScore: {
        getContentScore,
      },
    };
  }

  const contentScorePersuasions = {
    modelType: 'modelType1',
    endPointId: 1,
    body: getAnalyticsV1PersuasionsBody(mmtId),
  };

  const avgCompititorsContentScore = {
    modelType: 'modelType1',
    body: getAverageContentScoreBody(hotelCode),
  };

  return {
    GetContentScore: {
      getContentScore,
    },
    AnalyticsV1Persuasions: {
      contentScorePersuasions,
    },
    AverageContentScore: {
      avgCompititorsContentScore,
    },
  };
};

export async function fetchContentScore(props: ContentScoreApiParams) {
  const {
    mmtId,
    hotelCode,
    query = GET_CONTENT_SCORE,
    getOnlyContentScore = false,
  } = props;
  const variables = getContentScoreVariables({
    mmtId,
    hotelCode,
    getOnlyContentScore,
  });
  return doMutation(query, variables, { useLoader: false }).then(response => {
    const data = response?.data;
    const success1 =
      getValue(
        data,
        'avgCompititorsContentScore.avgCompititorsContentScore.modelType1',
      ) ?? '';
    const success2 =
      getValue(
        data,
        'contentScorePersuasions.contentScorePersuasions.modelType1',
      ) ?? '';
    const success3 =
      getValue(data, 'getContentScore.getContentScore.modelType1') ?? null;
    const getContentScoreData =
      getValue(data, 'getContentScore.getContentScore.modelType1.data') ?? {};
    const getAvgCompititorsContentScore =
      getValue(
        data,
        'avgCompititorsContentScore.avgCompititorsContentScore.modelType1.data',
      ) ?? {};
    const getContentScorePersuasions =
      getValue(
        data,
        'contentScorePersuasions.contentScorePersuasions.modelType1',
      ) ?? {};
    const errorMessage1 =
      getValue(
        data,
        'avgCompititorsContentScore.avgCompititorsContentScore.modelType1.message',
      ) ?? '';

    const errorMessage2 =
      getValue(
        data,
        'contentScorePersuasions.contentScorePersuasions.modelType1.message',
      ) ?? '';

    const errorMessage3 =
      getValue(
        data,
        'getContentScore.getContentScore.modelType1.error.errorMessage',
      ) ?? '';
    return {
      success1,
      success2,
      success3,
      getContentScoreData,
      getAvgCompititorsContentScore,
      getContentScorePersuasions,
      errorMessage1,
      errorMessage2,
      errorMessage3,
    };
  });
}
