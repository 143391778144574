const {
  VITE_CONTENT_BFF_ORIGIN: CONTENT_CB_BASE_URL,
  VITE_DYNAMIC_BFF_ORIGIN: dynamicBffOrigin,
} = import.meta.env;
/*
  Import required CB environment variables from .env file
*/

const ONBOARDING = {
  ENROLL_INFO: (draftId = '') =>
    `${CONTENT_CB_BASE_URL}/api/v3/enroll/basic-info${
      draftId ? `/${draftId}` : ''
    }`,
  ENROLL_HOTEL: (hotelCode = '') =>
    `${CONTENT_CB_BASE_URL}/api/v3/enroll/location${
      hotelCode ? `/${hotelCode}` : ''
    }`,
  ENROLL_ROOM: (roomId = '') =>
    `${CONTENT_CB_BASE_URL}/api/v3/enroll/room${roomId ? `/${roomId}` : ''}`,
  SAVE_HOST: `${CONTENT_CB_BASE_URL}/api/v1/common/save-host-details`,
};

export { ONBOARDING };

export const BFF_URLS = {
  CANCELLATION: {
    GET_POLICIES_LIST_PAGE: (hotelCode: string) =>
      `${dynamicBffOrigin}/api/v1/web/hotel/${hotelCode}/policy/cancellation/list/`,
    CREATE_CP_PAGE: (hotelCode: string) =>
      `${dynamicBffOrigin}/api/v1/web/hotel/${hotelCode}/policy/cancellation/create`,
    EDIT_CP_PAGE: (hotelCode: string, policyId: string) =>
      `${dynamicBffOrigin}/api/v1/web/hotel/${hotelCode}/policy/cancellation/update/${policyId}`,
  },
  HOST_PROFILE: {
    GENERATE_OTP: `${CONTENT_CB_BASE_URL}/api/v1/common/generate-otp`,
    VERIFY_OTP: `${CONTENT_CB_BASE_URL}/api/v1/common/verify-otp`,
  },
};
