import {
  decideShowTanPopup,
  getGSTstatus,
  getCurrentHotelParser,
  getRoomRateplansParser,
} from './HotelAPIClient';
import {
  fetchPendingPrebuyOrderCount,
  getHotelAgrementAndTaxData,
  getRemainingLegacyAndCalenderSyncData,
} from './HotelGraphClient';
import { getSocialMediaMetadata } from 'shared_logic/SharedGraphClient';
import { HOTEL_INSTAGRAM } from 'shared_logic/property-vibe/constants';
import { getFormattedDate } from '../../utilities/DateUtils';
import { IHotel } from './interfaces';
import pushToGTM, { GTM_CATEGORIES } from 'utilities/gtm';
import { appendCDN, getValue, isNullOrUndefined } from 'utilities/Utils';
import { APIS } from './constants';
import { Campaign } from 'modules/campaign-manager/Campaigns.types';
import { CurrentHotel } from 'interfaces/HotelContextInterface';

function getHotelAgrementAndTaxDataVariable(hotelCode) {
  return {
    hotelMetaDataRequestData: {
      hotelMetadataV2: {
        endPointId: 1,
        modelType: 'modelType1',
        urlParams: {
          hotelCode,
        },
      },
    },
    hotelInvoicingRequestData: {
      getHotelInvoicingData: {
        endPointId: 1,
        modelType: 'modelType1',
        queryParams: {
          hotelcode: hotelCode,
        },
      },
    },
    businessLogicData: {
      campaignEntityAgreementInput: {
        code: hotelCode,
      },
    },
  };
}

function getRemainingLegacyAndCalenderSyncDataVariable(hotelCode) {
  return {
    inventoryGetRoomsRateplansRequestData: {
      externalCalendarSyncGetRoomsRatePlansAny: {
        endPointId: 1,
        modelType: 'modelType1',
        queryParams: {
          hid: hotelCode,
        },
      },
    },
    analyticsHotelDemandRequestData: {
      analyticsHotelDemand: {
        endPointId: 1,
        modelType: 'modelType1',
        urlParams: {
          hotelcode: hotelCode,
        },
        queryParams: {
          no_of_days: 60,
          start_date: getFormattedDate(new Date(), 'YYYY-MM-DD') as string,
        },
      },
    },
    allowMoHotelPaymentRequestData: {
      allowMoHotelPayment: {
        endPointId: 1,
        modelType: 'modelType1',
        urlParams: {
          hotelcode: hotelCode,
        },
      },
    },
    hotelQualityScoreRequestData: {
      hotelQualityScore: {
        endPointId: 1,
        modelType: 'modelType1',
        urlParams: {
          hotelcode: hotelCode,
        },
      },
    },
  };
}

export function checkAndLogGtmLoginEvent() {
  try {
    const gtmLoginEventTrackKey = 'LOGIN_BUTTON_CLICK';
    const loginButtonClick = localStorage.getItem(gtmLoginEventTrackKey);
    if (loginButtonClick === '1') {
      pushToGTM(GTM_CATEGORIES.LOGIN, 'signin');
      localStorage.removeItem(gtmLoginEventTrackKey);
    }
  } catch (error) {
    console.error(error);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ensureLegacyParity(currHotel: IHotel, makeRequest?: any) {
  //Setting everything needed on client side
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  updateCookie(currHotel.hotelcode);

  //decoupling and API calls whose data is needed
  makeRequest(APIS.LEGACY_PARITY_PART_1, currHotel);
  makeRequest(APIS.CALSYNC_DATA, currHotel);
  makeRequest(APIS.LEGACY_PARITY_PART_2, currHotel);
  makeRequest(APIS.RESELLER_AGREEMENT, currHotel);
  makeRequest(APIS.FEATURE_FLAGS, currHotel?.mmt_id ?? '');
}

export function independentAPICalls(
  hotelcode: string,
  country: string,
  hotelId: CurrentHotel['mmt_id'],
  isResellerEnabled: boolean,
) {
  //Call to update the header count info
  return Promise.all([
    getGSTstatus(hotelcode, country),
    decideShowTanPopup(hotelcode, country),
    getSocialMediaMetadata(HOTEL_INSTAGRAM),
    fetchPendingPrebuyOrderCount(hotelId, isResellerEnabled),
  ]);
}

function updateCookie(hotelcode: string) {
  const date = new Date();
  const expiryDate = new Date(date.getTime() + 1000 * 60 * 60 * 24 * 365);
  document.cookie = `ingoibibo-hotelid=${hotelcode};path=/;domain=.${
    window.location.host
  };expires=${expiryDate.toUTCString()}`;
}

export function ensureHotelAgrementAndTaxData(currHotel: IHotel) {
  const variable = getHotelAgrementAndTaxDataVariable(currHotel.hotelcode);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return new Promise<any>((resolve, reject) => {
    getHotelAgrementAndTaxData(variable)
      .then(response => {
        const campaigns =
          getValue(response, 'data.campaignEntityAgreement.data') ?? [];
        const campaignsData = campaigns.reduce((acc, node) => {
          const {
            campaign_data,
            metadata,
            related_json_data,
            status,
            id,
            is_dsd_activated,
          } = node;
          const { agreement_id, sample_url } = campaign_data.agreement ?? {};
          const {
            campaign_desc,
            campaign_name,
            cmp_long_desc,
            video_url,
            campaign_type,
            metadata: campaignMetaData,
            id: campaignId,
          } = campaign_data;

          if (status !== 'accepted' && status !== 'pending') {
            return acc;
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let jsonData: any;
          try {
            if (typeof related_json_data === 'string') {
              jsonData = JSON.parse(related_json_data);
            } else {
              jsonData = related_json_data;
            }
          } catch (e) {
            jsonData = {};
          }

          const isAccepted = status === 'accepted';
          if (isAccepted && isNullOrUndefined(jsonData.accepted_on)) {
            return acc;
          }

          const campaign: Campaign = {
            agreement: {
              id: agreement_id,
              pdfURL: !isNullOrUndefined(sample_url)
                ? appendCDN(sample_url)
                : null,
            },
            enrollmentDate: jsonData.accepted_on,
            isAccepted,
            campaign_desc,
            campaign_name,
            cmp_long_desc,
            campaignId,
            id,
            status,
            video_url,
            metadata: { ...metadata, ...campaignMetaData },
            is_dsd_activated,
          };
          acc[campaign_type] = campaign;
          return acc;
        }, {});

        const currentHotel = getCurrentHotelParser(
          getValue(response, 'data.hotelMetaData.hotelMetadataV2.modelType1'),
          {
            channelManagerName: currHotel.channel_manager_name,
            channelManagerId: currHotel.channel_manager_id,
          },
        );

        const clearTax = getValue(
          response,
          'data.hotelInvoicingData.getHotelInvoicingData.modelType1.details',
        );

        resolve({
          data: {
            [APIS.CAMPAIGN_ENTITY_AGREEMENT]: campaignsData,
            [APIS.HOTEL_DETAIL]: currentHotel,
            [APIS.CT_DETAIL]: clearTax,
          },
        });
      })
      .catch(error => reject(error));
  });
}

export function ensureRemainingLegacyAndCalenderSyncData(currHotel: IHotel) {
  const variable = getRemainingLegacyAndCalenderSyncDataVariable(
    currHotel.hotelcode,
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return new Promise<any>((resolves, rejects) => {
    getRemainingLegacyAndCalenderSyncData(variable)
      .then(response => {
        const roomsRateplan = getRoomRateplansParser(
          getValue(
            response,
            'data.externalCalendarSyncGetRoomsRatePlansAny.externalCalendarSyncGetRoomsRatePlansAny.modelType1',
          ),
        );
        const demandData =
          getValue(
            response,
            'data.analyticsHotelDemandData.analyticsHotelDemand.modelType1.data.demand',
          ) ?? {};

        const hotelMOFlag =
          getValue(
            response,
            'data.allowMoHotelPaymentData.allowMoHotelPayment.modelType1.data.can_allow',
          ) ?? false;

        const qsState = getValue(
          response,
          'data.hotelQualityScoreData.hotelQualityScore.modelType1.qsInfo',
        ) ?? { is_qs_active: false };
        resolves({
          data: {
            [APIS.ROOM_RATEPLANS_INFO]: roomsRateplan,
            [APIS.DEMAND_DATA]: demandData,
            [APIS.HOTEL_MO_FLAG]: hotelMOFlag,
            [APIS.QSS_STATE]: qsState,
          },
        });
      })
      .catch(error => rejects(error));
  });
}
