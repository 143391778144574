import { AxiosResponse } from 'axios';
import axios, { apiWrapper } from '../../base/APIClient';
import { HOST_WEB_MIGRATION } from '../../base/Url';
import { IHotelMetadata } from '../../app/hotel/interfaces';

import {
  IOccupancy,
  TriggerMigrationResponse,
} from './HostWebMigration.interface';
import { getValue } from 'utilities/Utils';

const parseOccupancy = data => {
  const response: IOccupancy[] = [];
  if (data.property?.property_type === 'room') {
    data.spaces.forEach(space => {
      const occupancy: IOccupancy = {
        spaceName: space.space_name,
        oldMinOccupancy: space.old_min_occupancy,
        newMinOccupancy: space.new_min_occupancy,
        oldMaxOccupancy: space.old_max_occupancy,
        newMaxOccupancy: space.new_max_occupancy,
      };

      response.push(occupancy);
    });
  } else {
    response.push({
      oldMinOccupancy: data.property.old_min_occupancy,
      newMinOccupancy: data.property.new_min_occupancy,
      oldMaxOccupancy: data.property.old_max_occupancy,
      newMaxOccupancy: data.property.new_max_occupancy,
    });
  }

  return response;
};

export const getMigrationPreview = (
  hotelCode: string,
): Promise<IOccupancy[]> => {
  const { PREVIEW } = HOST_WEB_MIGRATION;
  const url = PREVIEW(hotelCode);

  return new Promise((resolve, reject) => {
    apiWrapper(axios.get(url))
      .then((response: object) => {
        const parseResponse = parseOccupancy(getValue(response, 'data'));
        resolve(parseResponse);
      })
      .catch(reject);
  });
};

export const triggerMigration = (
  hotelCode: string,
): Promise<AxiosResponse<TriggerMigrationResponse>> => {
  const { TRIGGER: url } = HOST_WEB_MIGRATION;

  const data = {
    hotelcode: hotelCode,
  };
  return axios.post(url, data, { useLoader: true });
};

export const getHotelMetaData = (hotelCode: string) => {
  const { METADATA } = HOST_WEB_MIGRATION;
  const url = METADATA(hotelCode);
  return apiWrapper(axios.get<string, AxiosResponse<IHotelMetadata>>(url));
};
