import React from 'react';

import {
  LegacyButton as Button,
  Typography,
  Counters,
  ToggleButton,
  TimePickerRange,
  Input,
} from 'connect-web-ui';

import { time24hrTo12Hr } from 'utilities/Utils';
import { YES } from 'modules/promotions-and-coupons/PnC.constants';
import { formatTimeString } from 'utilities/DateUtils';
import { ChevronDown, ChevronUp, NewCugIcon } from 'assets/common';
import { PlusIcon } from 'assets/modules/rooms';
import TimeBasedTimeline from 'modules/promotions-and-coupons/components/TimeBasedTimeline';
import { legacyPushToGTM } from 'utilities/gtm';

interface TimeBasedDiscountsProps {
  visibility;
  toggleVisibility: (section: string) => void;
  timeBasedDiscount: string;
  setTimeBasedDiscount;
  startTime: string;
  endTime: string;
  changeHandler;
  offerValue: number;
  overallDiscount: number;
  setOverallDiscount;
  STRINGS;
  COMMON_STRINGS;
  mobileMeta;
  data;
  error: string;
  timeZone: string;
  timeZoneOffset: string;
  timeBasedData;
  isApplicableTillNextDay: boolean;
  isEdit: boolean;
  showTotalTimeBasedError: string;
  segmentKey: string;
}

const TimeBasedDiscounts: React.FC<TimeBasedDiscountsProps> = ({
  visibility,
  toggleVisibility,
  timeBasedDiscount,
  setTimeBasedDiscount,
  startTime,
  endTime,
  changeHandler,
  offerValue,
  overallDiscount,
  setOverallDiscount,
  STRINGS,
  COMMON_STRINGS,
  mobileMeta,
  data,
  error,
  timeZone,
  timeZoneOffset,
  timeBasedData,
  isEdit,
  isApplicableTillNextDay: _isApplicableTillNextDay,
  showTotalTimeBasedError,
  segmentKey,
}) => {
  const formattedStartTime = time24hrTo12Hr(startTime);
  const formattedEndTime = time24hrTo12Hr(endTime);
  const getDiscountMessage = _data => {
    if (
      _data &&
      timeBasedDiscount === YES &&
      _data?.startTime &&
      _data?.endTime
    ) {
      const {
        offerValue: _offerValue,
        startTime: _startTime,
        endTime: _endTime,
      } = _data;

      const formattedStartTimeNew = time24hrTo12Hr(_startTime);
      const formattedEndTimeNew = time24hrTo12Hr(_endTime);
      const _formattedStartTime = formatTimeString(formattedStartTimeNew);
      const _formattedEndTime = formatTimeString(formattedEndTimeNew);
      const newOfferValue =
        _data?.offerValueDataList?.length > 0
          ? _data?.offerValueDataList?.[0]?.offerValue
          : _offerValue;

      const timeMessage = `${_formattedStartTime}-${_formattedEndTime}${
        _isApplicableTillNextDay ? ' (Next day)' : ''
      }`;

      return `Discount offered: ${newOfferValue}%, Valid from ${timeMessage}`;
    }

    return STRINGS.OFFER_ADDITIONAL_DISCOUNT;
  };
  // Render the discount message
  const discountMessage =
    isEdit && timeBasedDiscount === YES
      ? `Discount offered: ${
        offerValue + overallDiscount
      }%, Valid from ${formatTimeString(
        formattedStartTime,
      )} - ${formatTimeString(formattedEndTime)}${
        _isApplicableTillNextDay
          ? _isApplicableTillNextDay
            ? ' (Next day)'
            : ''
          : ''
      }`
      : getDiscountMessage(timeBasedData);

  const additionalDiscountMessage = `${STRINGS.ADDITIONAL_DISCOUNT_OFFERED} ${formattedStartTime} - ${formattedEndTime}`;
  const minDiscountValue = mobileMeta?.timeBasedMetadata?.minOfferValue;
  const maxDiscountValue = 90 - offerValue;
  const handleOverallDiscountChange = (value: number) => {
    let actualValue = value;
    if (value > maxDiscountValue) {
      actualValue = maxDiscountValue;
    }
    if (value < minDiscountValue) {
      actualValue = minDiscountValue;
    }
    setOverallDiscount(actualValue);
    const eventAction = 'time_based_discount';
    const eventLabel = value > overallDiscount ? 'increase' : 'decrease';
    const eventCategory = segmentKey === 'Geography' ? 'ipos' : 'mobile';

    legacyPushToGTM({
      eventCategory,
      eventAction,
      eventLabel,
    });
  };

  return (
    <>
      <div className="flex justify-between mt-4">
        <Typography
          variant="subHeadingMedium"
          className="mr-4 flex items-center"
        >
          {STRINGS.TIME_BASED_DISCOUNTS} <NewCugIcon className="ml-1" />
        </Typography>

        <Button
          onClick={() => toggleVisibility('timeBasedDiscounts')}
          variant="text"
          className="!capitalize !text-size-14"
        >
          {visibility.timeBasedDiscounts
            ? COMMON_STRINGS.BUTTONS.HIDE
            : COMMON_STRINGS.BUTTONS.SHOW}
          {visibility.timeBasedDiscounts ? (
            <ChevronUp fill="#008CFF" className="ml-1" width={20} height={20} />
          ) : (
            <ChevronDown
              fill="#008CFF"
              className="ml-1"
              width={20}
              height={20}
            />
          )}
        </Button>
      </div>
      <Typography variant="body1" className="mt-1 mb-4">
        {discountMessage}
      </Typography>
      {visibility.timeBasedDiscounts && (
        <div>
          <div className="flex mb-4 justify-between mt-4">
            <div className="flex justify-between">
              <div className="w-[50%]">
                <Typography variant="body1">
                  {STRINGS.OFFER_ADDITIONAL_DISCOUNT_PROMPT}
                </Typography>
              </div>
              <div className="flex1">
                <ToggleButton
                  variant="YN"
                  displayType="radio-chip"
                  value={timeBasedDiscount}
                  onChange={setTimeBasedDiscount}
                />
              </div>
            </div>
          </div>
          {timeBasedDiscount === YES && (
            <div>
              <Typography variant="body1" fb themeColor="gray.main">
                {STRINGS.SET_TIME_DURATION.replace(
                  '__time_zone',
                  timeZone,
                ).replace('__time_zone_name', timeZoneOffset)}
              </Typography>

              <TimePickerRange
                from={startTime}
                to={endTime}
                callback={changeHandler}
                fromProps={{ label: STRINGS.START_TIME }}
                toProps={{ label: STRINGS.END_TIME }}
                is24HrFormat={false}
                BoxProps={{ width: '100%' }}
                minuteInterval={60}
                autoFocus={false}
                showNextDayText={true}
              />

              {error && (
                <Typography variant="body1" themeColor="error.dark">
                  {error}
                </Typography>
              )}
              <Typography variant="body1" fb className="mt-[20px] mb-2">
                {additionalDiscountMessage}
              </Typography>

              <div className="flex flex-1 justify-between items-center">
                <div>
                  <div className="mb-1">
                    {data?.segment === 'MOBILE'
                      ? STRINGS.MOBILE_USER_DISCOUNT
                      : STRINGS.INDIA_POS_DISCOUNT}
                  </div>

                  <Input
                    type="number"
                    value={offerValue}
                    placeholder={STRINGS.DRAWER.OFFER_VALUE_PLACEHOLDER}
                    rightElement="%"
                    decimalPoints={0}
                    disabled
                    className="!w-[126px]"
                  />
                </div>

                <div>
                  <PlusIcon fill="#787878" className="h-3 w-3 mt-6" />
                </div>
                <div>
                  <div className="mb-1">
                    {STRINGS.DISCOUNT_FOR_TIME_DURATION}
                  </div>
                  <Counters
                    value={overallDiscount}
                    minLimit={minDiscountValue}
                    maxLimit={maxDiscountValue}
                    changeCounter={handleOverallDiscountChange}
                    endAdornment={<div className="-mr-1 -ml-1"> %</div>}
                  />
                </div>
              </div>
              {showTotalTimeBasedError && (
                <Typography
                  variant="body1"
                  className="mt-1"
                  themeColor="error.dark"
                >
                  {showTotalTimeBasedError}
                </Typography>
              )}
              <div className="mt-[20px] mb-4">
                <TimeBasedTimeline
                  timeLineStart={startTime}
                  timeLineEnd={endTime}
                  offerValue={offerValue}
                  overallDiscount={overallDiscount}
                  segmentType={data?.segment}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TimeBasedDiscounts;
