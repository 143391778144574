import * as QS from 'assets/modules/quality-score';

export const tierMap = {
  silver: {
    bgImg:
      'linear-gradient(85.1deg, #A0A0A0 1.95%, #EBEBEB 50.19%, #6D6D6D 99.44%)',
    emoji: '😐',
  },
  gold: {
    bgImg:
      'linear-gradient(264.6deg, rgba(255, 219, 92, 0.5) 0.58%, rgba(255, 238, 176, 0.5) 48.46%, rgba(249, 169, 15, 0.5) 100.02%)',
    emoji: '😎',
  },
  platinum: {
    bgImg:
      'linear-gradient(264.6deg, rgba(190, 191, 220, 0.5) 0.58%, rgba(231, 229, 248, 0.5) 45.38%, rgba(82, 86, 128, 0.5) 100.02%)',
    emoji: '👑',
  },
};

export const boostMap = {
  silver: {
    bgImg: `url(${QS.silverBoost})`,
  },
  gold: {
    bgImg: `url(${QS.goldBoost})`,
  },
  platinum: {
    bgImg: `url(${QS.platinumBoost})`,
  },
};
export const tierType = {
  SILVER: 'silver',
  GOLD: 'gold',
  PLATINUM: 'platinum',
};
