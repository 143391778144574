export const getAgreementsQuery = `query HotelAgreementsRawResponse($rawEndpointRequestData: RawEndpointsRequestData) {
    rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
        hotelAgreementsV3 {
            modelType1 {
                msg
                success
                agreement_list{
                    agreement_name
                    agreement_url
                    agreement_id
                    mode
                    status
                    agreement_number
                    sample_url
                    agreement_display_name
                    signed_agreement_url
                    agreement_sign_time
                }
                __typename
            }
            __typename
        }
        __typename
    }
}`;

export const updateDocumentQuery = `mutation DocumentUpdateRawResponse($rawEndpointRequestData: RawEndpointsRequestData) {
    rawEndpoint(endpointRequestData: $rawEndpointRequestData) {
        documentUpdateV3{
             modelType1 {
                error
                success
                data { 
                    id 
                    document_name
                    document_type
                    document_description
                    last_modified_by
                    content_type
                    is_verified
                    legal_doc_type
                    isactive
                    url
                }
                __typename
            }
        __typename
        }
        __typename
    }
}`;

export const updateDocumentVariables = (id: string, payload: object) => ({
  rawEndpointRequestData: {
    documentUpdateV3: {
      endPointId: 99,
      modelType: 'modelType1',
      urlParams: {
        id,
      },
      body: payload,
    },
  },
});

export const getAgreementsVariables = (hotelId: string) => ({
  rawEndpointRequestData: {
    hotelAgreementsV3: {
      endPointId: 99,
      modelType: 'modelType1',
      urlParams: {
        hotelCode: hotelId,
      },
    },
  },
});
