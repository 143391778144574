const propertyListingParts = `
{
  draftId
  hotelName
  ingoHotelId
  isNewFlow
  duplicationDetails{
      parentHotelCode
      childHotelCode
      duplicationInProgress
  }
  propertyDetails{
      propertyType
      sellableUnit
      propertySubType
  }
  progressListingMetaFlags{
      isGuestChatEnabled
      isGoStay
      isRtbEnabled
  }
  propertyState
  thumbnailUrl
  isEligibleForDuplicateListing
  createdOn
  modifiedOn
  location{
      country{
          locationDetails{
              name
          }
      }
      state{
          locationDetails{
              name
          }
      }
  }
}
`;

export const PROPERTY_LISTING_QUERY = `
query FetchPropertyListingData($endpointRequestData: EndpointRequestData) {
  fetchPropertyListingData(endpointRequestData: $endpointRequestData) {
    correlationId
    success
    error{
      errors{
        field
        message
      }
    }
    activeData ${propertyListingParts}
    inactiveData ${propertyListingParts}
    progressData ${propertyListingParts}
    draftData {
      draftId
      hotelName
      ingoHotelId
      status
      email
      mobile
      starRating
      yearHotelBuilt
      propertyState
      channelManagerId
      fcLeadId
      createdOn
      modifiedOn
      isNewFlow
      propertyDetails{
        propertyType
        sellableUnit
        propertySubType
      }
      listingMetaData{
        isHomeStayFlow
        isHostWeb
        source
      }
      duplicationDetails{
        parentHotelCode
        childHotelCode
        duplicationInProgress
      }
      onboardingDetails{
        onboardingStage
        onboardingCompletedText
        onboardingCompletionPercentage
      }
    }
  }
}
`;

export const GET_PROPERTY_METRICS_QUERY = `query GroupPropertyData(
  $groupChat: AltAccoSSOUserAssociatedRequest
  $endpointRequestData: EndpointRequestData
  $grpcEndpointRequestData: GRPCEndpointRequestData
  $endpointBusinessLogicData: EndpointBusinessLogicData
  $endpointRequestDataGI: EndpointRequestData
) {
  userLevel: altAccoSSOUserAssociatedEndpoints(endpointRequest: $groupChat) {
    unreadChatCount {
      success
      error
      total_unread_message
      hotels_unread_count
    }
  }

  pendingRequests : altAccoGetRTBPendingRequests(
    endpointRequestData: $endpointRequestData,
    grpcEndpointRequestData: $grpcEndpointRequestData,
    endpointBusinessLogicData: $endpointBusinessLogicData){
    newPendingRTBRequestsCount
    allPendingRTBBookingCount
    rtbBookings{
      hotelCode
      count
    }
  }
  ratingDataGI: altAccoActivityAndPerformanceDashboard(
    endpointRequestData: $endpointRequestDataGI
    grpcEndpointRequestData: $grpcEndpointRequestData
  ) {
    rnrRatingInfo {
      status
      message
      data
    }
  }
}
`;

export const getInternalCalendarGetLinkage = `
query RawResponse($rawEndpointsRequestData: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $rawEndpointsRequestData) {
    internalCalendarGetLinkage {
      modelType1 {
        data {
          roomtypecode
          linkage_id
          linked_parent_room
          linked_parent_hotel
          linked_room_hotelcode
        }
        success
        error
      }
    }
  }
}
`;

export const UNREAD_CHAT_SESSION = `query UnreadChat(
  $rawInput: AltAccoSSOUserAssociatedRequest
  ) {
      altAccoSSOUserAssociatedEndpoints(endpointRequest: $rawInput) {
            unreadChatCount {
              success
              error
              total_unread_message
              total_unread_session
              hotels_unread_count
          }
      }
    }`;
