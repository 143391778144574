import { Fragment, useState } from 'react';
import {
  Unstable_Grid2 as Grid,
  Box,
  Skeleton,
  makeStyles,
  Info as InfoIcon,
} from 'connect-web-ui/mui';
import { Typography } from 'connect-web-ui';
import { getMigrationPreview } from '../HostWebMigrationAPIClient';
import { IOccupancy } from '../HostWebMigration.interface';
import {
  HostMigrationSpaceIntro,
  HostMigrationHostProfile,
} from 'assets/modules/host-migrations';
import { useHotelChange, useLangStrings } from '../../../utilities/CustomHooks';

import { legacyPushToGTM } from 'utilities/gtm';
import { isNullOrUndefined } from 'utilities/Utils';
import { ISTRINGS } from 'interfaces/Strings';

const useStyles = makeStyles(theme => ({
  warning: {
    backgroundColor: theme.palette.backgrounds.warning,
    borderRadius: 4,
    border: `1px solid ${theme.palette.warning.main}`,
  },
  infoIcon: {
    color: theme.palette.warning.main,
    marginRight: 14,
  },
  card: {
    boxShadow: '0 5px 16px rgba(1, 16, 34, 0.1)',
    width: 360,
    height: '100%',
  },
  cardIcon: {
    '& > path': {
      stroke: theme.palette.primary.main,
    },
  },
  cardList: {
    margin: 0,
    listStyleType: 'disc',
    paddingLeft: 16,
  },
  cardListItem: {
    color: theme.palette.gray.main,
  },
}));

type DetailSectionProps = {
  title: string;
  list: string[];
};

const DetailSection = (props: DetailSectionProps) => {
  const classes = useStyles();

  return (
    <Box marginY={2}>
      <Typography themeColor="gray.main" fb>
        {props.title}:
      </Typography>
      <ul className={classes.cardList}>
        {props.list.map((item, index) => (
          <li key={index} className={classes.cardListItem}>
            {item}
          </li>
        ))}
      </ul>
    </Box>
  );
};

const getOccupancyList = (
  STRINGS: ISTRINGS['HostWebMigration']['CHANGES'],
  occupancy: IOccupancy,
) => {
  const list = [];
  if (occupancy.oldMinOccupancy === occupancy.newMinOccupancy) {
    list.push(STRINGS.NO_CHANGE_MIN_OCCUPANCY);
  } else {
    list.push(
      STRINGS.MIN_OCCUPANCY.replace(
        '__MIN__',
        `${occupancy.oldMinOccupancy}`,
      ).replace('__MAX__', `${occupancy.newMinOccupancy}`),
    );
  }
  if (occupancy.oldMaxOccupancy === occupancy.newMaxOccupancy) {
    list.push(STRINGS.NO_CHANGE_MAX_OCCUPANCY);
  } else {
    list.push(
      STRINGS.MAX_OCCUPANCY.replace(
        '__MIN__',
        `${occupancy.oldMaxOccupancy}`,
      ).replace('__MAX__', `${occupancy.newMaxOccupancy}`),
    );
  }
  return list;
};

function CardSkelton() {
  const info = (
    <Box marginY={2}>
      <Skeleton variant="text" height={14} animation="wave" />
      <Grid container>
        <Grid xs={1}>
          <Skeleton
            variant="circular"
            width={14}
            height={14}
            animation={false}
          />
        </Grid>
        <Grid xs={11}>
          <Skeleton variant="rectangular" height={32} animation="wave" />
        </Grid>
      </Grid>
    </Box>
  );

  const list = [0, 1, 2].map(key => <Fragment key={key}>{info}</Fragment>);

  return (
    <Box width={360} padding={3} boxShadow="0 5px 16px rgba(1, 16, 34, 0.1)">
      <Grid container spacing={3}>
        <Grid xs={1}>
          <Box marginTop={0.5}>
            <Skeleton
              variant="circular"
              width={16}
              height={16}
              animation={false}
            />
          </Box>
        </Grid>
        <Grid xs={11}>
          <Box>
            <Skeleton variant="text" height={24} animation="wave" />
            <Skeleton variant="rectangular" height={60} animation="wave" />
          </Box>
          {list}
        </Grid>
      </Grid>
    </Box>
  );
}

type ChangesInListingProps = {
  setShowConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  eventCategory: string;
  eventAction: string;
};

export default function ChangesInListing(props: ChangesInListingProps) {
  const { setShowConfirm, eventCategory, eventAction } = props;
  const [occupancyList, setOccupancyList] = useState<IOccupancy[]>(null);
  const classes = useStyles();

  const [STRINGS] = useLangStrings<'HostWebMigration'>('HostWebMigration');

  useHotelChange(hotelCode => {
    setShowConfirm(false);
    getMigrationPreview(hotelCode)
      .then(response => {
        const eventLabel = response.some(
          item =>
            item.newMaxOccupancy !== item.oldMaxOccupancy ||
            item.newMinOccupancy !== item.oldMinOccupancy,
        )
          ? 'occupancy_settings_changed'
          : 'occupancy_settings_same';
        legacyPushToGTM({
          eventCategory,
          eventAction,
          eventLabel,
        });
        setOccupancyList(response);
        setShowConfirm(true);
      })
      .catch(() => {
        setOccupancyList([]);
        setShowConfirm(true);
      });
  }, []);

  return (
    <Box>
      <Box marginTop={5}>
        <Typography variant="h1">{STRINGS.CHANGES.TITLE}</Typography>
        <Typography themeColor="gray.main">
          {STRINGS.CHANGES.SUBTITLE}
        </Typography>
      </Box>
      <Box marginY={2} padding={1.5} className={classes.warning}>
        <Grid container alignItems="center">
          <InfoIcon className={classes.infoIcon} />
          <Typography>{STRINGS.CHANGES.CONTENT}</Typography>
        </Grid>
      </Box>
      {isNullOrUndefined(occupancyList) ? (
        <Grid container spacing={2}>
          <Grid>
            <CardSkelton />
          </Grid>
          <Grid>
            <CardSkelton />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid>
            <Box className={classes.card} padding={3}>
              <Grid container spacing={3}>
                <Grid xs={1}>
                  <HostMigrationSpaceIntro className={classes.cardIcon} />
                </Grid>
                <Grid xs={11}>
                  <Typography themeColor="primary.main" fb>
                    {STRINGS.CHANGES.CARDS.ROOM_AND_SPACE.TITLE}
                  </Typography>
                  <Typography themeColor="gray.main">
                    {STRINGS.CHANGES.CARDS.ROOM_AND_SPACE.CONTENT}
                  </Typography>
                  {STRINGS.CHANGES.CARDS.ROOM_AND_SPACE.SECTIONS.map(
                    (SECTION, index) => (
                      <DetailSection
                        key={index}
                        title={SECTION.TITLE}
                        list={SECTION.LIST}
                      />
                    ),
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid>
            <Box className={classes.card} padding={3}>
              <Grid container spacing={3}>
                <Grid xs={1}>
                  <HostMigrationHostProfile className={classes.cardIcon} />
                </Grid>
                <Grid xs={11}>
                  <Typography themeColor="primary.main" fb>
                    {STRINGS.CHANGES.CARDS.OCCUPANCY_SETTINGS.TITLE}
                  </Typography>
                  <Typography themeColor="gray.main">
                    {STRINGS.CHANGES.CARDS.OCCUPANCY_SETTINGS.CONTENT}
                  </Typography>
                  {occupancyList.map((occupancy, index) => {
                    const title =
                      occupancy.spaceName ??
                      STRINGS.CHANGES.OCCUPANCY_PROPERTY_TITLE;
                    const list = getOccupancyList(STRINGS.CHANGES, occupancy);

                    return (
                      <DetailSection key={index} title={title} list={list} />
                    );
                  })}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
