import React from 'react';
import { RightSectionShimmer } from './RightSectionShimmer';
import { MainSectionShimmer } from './MainSectionShimmer';

type ContainerShimmerProps = {
  hasLeftNav?: boolean;
};

export const BodyShimmer = ({ hasLeftNav = true }) => (
  <div
    id={'container-shimmer'}
    className="w-full flex flex-1 flex-row gap-4 justify-center max-w-screen-lg justify-self-center h-[calc(100vh-104px)] overflow-hidden"
  >
    <MainSectionShimmer />
    {hasLeftNav && <RightSectionShimmer />}
  </div>
);

const ContainerShimmer = (props: ContainerShimmerProps) => {
  const { hasLeftNav = true } = props;
  return (
    <div
      className={
        'new-sr-container overflow-clip flex justify-center flex-1 h-[calc(100vh-73px)]'
      }
    >
      <BodyShimmer hasLeftNav={hasLeftNav} />
    </div>
  );
};

export default ContainerShimmer;
