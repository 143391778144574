import axios, { apiWrapper, enigmaGraphBase } from 'base/APIClient';
import { doQuery, doMutation } from '../../../../../base/GraphQLClient';
import { MEDIA_TYPE, statusKeys } from './media.constants';
import {
  BULK_UPDATE_MEDIA_QUERY,
  FETCH_MEDIA_QUERY,
  getMediaDataQuery,
  UPDATE_MEDIA_ORDER_QUERY,
  UPDATE_MEDIA_QUERY,
  UPLOAD_IMAGE_QUERY,
  UPLOAD_VIDEO_QUERY,
} from './MediaGraphQueries';
import { MediaDataType, MediaTagsType } from './media.types';
import { getValue } from 'utilities/Utils';

const parseMediaData = (res, spacesContextListArr = []): MediaDataType => {
  const dataObj = {};
  const status = {
    [statusKeys.rejected]: 0,
    [statusKeys.approved]: 0,
    [statusKeys.pending]: 0,
  };
  let imagesCount = 0;
  let videosCount = 0;
  const spaces = { unknown: [] };
  const imagesIdOrderArr = [];
  const filteredActiveMediaData = (res.data ?? []).filter(val => val.is_active);
  filteredActiveMediaData.forEach(obj => {
    dataObj[obj.id] = obj;
    imagesIdOrderArr.push(obj.id);

    if (obj.status === statusKeys.rejected) {
      status[statusKeys.rejected] += 1;
    } else if (obj.status === statusKeys.pending) {
      status[statusKeys.pending] += 1;
    }
    //  else if (obj.status === statusKeys.approved) {
    //   status[statusKeys.approved] += 1;
    // }
    if (!obj.space_ids.length) {
      spaces.unknown.push(obj.id);
    } else {
      obj.space_ids.forEach(space => {
        if (!spaces[space]) {
          spaces[space] = [];
        }
        spaces[space].push(obj.id);
      });
    }

    if (obj.media_type === MEDIA_TYPE.PHOTOS) {
      imagesCount += 1;
    } else {
      videosCount += 1;
    }
  });
  spacesContextListArr.forEach(space => {
    if (!spaces[space.id]) {
      spaces[space.id] = [];
    }
  });
  return {
    count: filteredActiveMediaData.length,
    data: dataObj,
    status,
    spaces,
    imagesIdOrderArr,
    imagesCount,
    videosCount,
  };
};

export const getMediaData = (requestBody, spaces) => {
  const variables = {
    rawEndpointsRequestData: {
      fetchMediaDetails: {
        modelType: 'modelType1',
        body: requestBody,
      },
    },
  };
  return doQuery(getMediaDataQuery, variables).then(res => {
    return parseMediaData(
      res.data?.rawEndpoint?.fetchMediaDetails?.modelType1,
      spaces,
    );
  });
};

const parseMediaTags = (tags): MediaTagsType => {
  return {
    images: tags.images.homestay,
    videos: tags.videos.homestay,
  };
};

export const fetchMediaTags = () => {
  const variables = {
    rawEndpointsRequestData: {
      mediaTagsDetails: {
        modelType: 'modelType1',
      },
    },
  };
  return doQuery(FETCH_MEDIA_QUERY, variables).then(res => {
    return parseMediaTags(
      res.data?.rawEndpoint?.mediaTagsDetails?.modelType1.data,
    );
  });
};

export const deleteMedia = ({ mediaId, mediaType, hotelcode }) => {
  const variables = {
    rawEndpointsRequestData: {
      mediaUpdateDetails: {
        modelType: 'modelType1',
        urlParams: {
          media_id: String(mediaId),
        },
        body: {
          hotelcode,
          delete: true,
          media_type: mediaType,
        },
      },
    },
  };
  return doMutation(UPDATE_MEDIA_QUERY, variables).then(
    res => res.data?.rawEndpoint.mediaUpdateDetails.modelType1,
  );
};

export const updateMediaDetails = ({
  mediaId,
  mediaType,
  tags,
  caption,
  spaces,
  hotelcode,
  unmappedSpaces,
}) => {
  const variables = {
    rawEndpointsRequestData: {
      mediaUpdateDetails: {
        modelType: 'modelType1',
        urlParams: { media_id: String(mediaId) },
        body: {
          hotelcode,
          tags,
          media_type: mediaType,
          caption,
          mappings: {
            space: spaces,
          },
          unmappings: {
            space: unmappedSpaces,
          },
        },
      },
    },
  };

  return doMutation(UPDATE_MEDIA_QUERY, variables).then(
    res => res.data?.rawEndpoint.mediaUpdateDetails.modelType1,
  );
};

export const bulkMediaUpdate = ({ hotelcode, mediaMappingData }) => {
  const variables = {
    rawEndpointsRequestData: {
      bulkMediaMappingUpdate: {
        modelType: 'modelType1',
        body: {
          hotelcode,
          mapping_data: mediaMappingData,
        },
      },
    },
  };
  return doMutation(BULK_UPDATE_MEDIA_QUERY, variables).then(
    res => res.data?.rawEndpoint.bulkMediaMappingUpdate.modelType1,
  );
};

export const updateMediaOrder = ({
  hotelcode,
  entity,
  entityId,
  mediaOrder,
}) => {
  const variables = {
    rawEndpointsRequestData: {
      mediaOrderUpdateDetails: {
        modelType: 'modelType1',
        body: {
          hotelcode,
          entity,
          entity_id: entityId,
          media_order: mediaOrder,
        },
      },
    },
  };

  return doMutation(UPDATE_MEDIA_ORDER_QUERY, variables).then(
    res => res.data?.rawEndpoint.mediaOrderUpdateDetails.modelType1,
  );
};

const modifyImageResponse = (res, payload, isMinOneSpaceSelected) => {
  const { success, data, message } = res;
  const {
    tags,
    id,
    url,
    caption,
    status,
    image_scores: imageScores,
    image,
  } = data ?? {};
  return {
    success,
    message,
    data: {
      caption,
      description: caption,
      tags,
      id,
      image_thumb: url,
      image_scores: imageScores,
      space_ids: isMinOneSpaceSelected
        ? payload.type_ids?.split(',').map(val => Number(val))
        : [],
      status,
      media_type: MEDIA_TYPE.PHOTOS,
      image,
    },
  };
};

const modifyVideoResponse = (res, payload, isMinOneSpaceSelected) => {
  const { success, data, message } = res;
  const {
    tags,
    id,
    video_url: videoUrl,
    caption,
    status,
    video_thumbnail: videoThumbnail,
  } = data ?? {};
  return {
    success,
    message,
    data: {
      caption,
      description: caption,
      tags,
      id,
      video_url: videoUrl,
      video_thumbnail: videoThumbnail,
      space_ids: isMinOneSpaceSelected
        ? payload.type_ids?.split(',').map(val => Number(val))
        : [],
      status,
      media_type: MEDIA_TYPE.VIDEOS,
    },
  };
};

export const uploadImage = (payload, file, isMinOneSpaceSelected) => {
  const formData = new FormData();
  const variables = {
    rawEndpointsRequestData: {
      hostAppCreateImage: {
        modelType: 'modelType1',
        endPointId: 59,
        files: [{ key: 'image', file: null }],
        formData: {
          upload_order: 0,
          ...payload,
        },
      },
    },
  };
  formData.append(
    'operations',
    JSON.stringify({ query: UPLOAD_IMAGE_QUERY, variables }),
  );
  formData.append(
    'map',
    JSON.stringify({
      '0': [
        'variables.rawEndpointsRequestData.hostAppCreateImage.files.0.file',
      ],
    }),
  );
  formData.append('0', file);

  return apiWrapper(axios.post(enigmaGraphBase, formData)).then(
    (res: object) => {
      return modifyImageResponse(
        getValue(res, 'data.rawEndpoint.hostAppImageCreate.modelType1'),
        payload,
        isMinOneSpaceSelected,
      );
    },
  );
};

export const uploadVideo = (payload, file, isMinOneSpaceSelected) => {
  const formData = new FormData();
  const variables = {
    rawEndpointsRequestData: {
      hostAppCreateVideo: {
        modelType: 'modelType1',
        endPointId: 59,
        files: [{ key: 'file', file: null }],
        formData: {
          file_name: file.name,
          file_size: file.size,
          file_format: file.type,
          ...payload,
        },
      },
    },
  };
  formData.append(
    'operations',
    JSON.stringify({ query: UPLOAD_VIDEO_QUERY, variables }),
  );

  formData.append(
    'map',
    JSON.stringify({
      '0': [
        'variables.rawEndpointsRequestData.hostAppCreateVideo.files.0.file',
      ],
    }),
  );
  formData.append('0', file);

  return apiWrapper(axios.post(enigmaGraphBase, formData)).then((res: object) =>
    modifyVideoResponse(
      getValue(res, 'data.rawEndpoint.hostAppCreateVideo.modelType1'),
      payload,
      isMinOneSpaceSelected,
    ),
  );
};
