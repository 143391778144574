import { State, Action, PopoverState } from './FeatureTour.interface';

export const computePopState = (el: DOMRectReadOnly): PopoverState => {
  const anchorHorizontal = window.outerWidth / 2 > el.left ? 'right' : 'left';
  const anchorVertical = 'center';

  const transformHorizontal = anchorHorizontal === 'left' ? 'right' : 'left';
  const transformVertical = 'center';

  return {
    anchorHorizontal,
    anchorVertical,
    transformHorizontal,
    transformVertical,
  };
};

export const initialState: State = {
  stepIndex: 0,
  anchorEl: null,
  selectedEl: null,
  popoverState: {
    anchorHorizontal: 'left',
    anchorVertical: 'center',
    transformHorizontal: 'right',
    transformVertical: 'center',
  },
  checkboxChecked: false,
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'next':
      return { ...state, stepIndex: state.stepIndex + 1 };

    case 'previous':
      return { ...state, stepIndex: state.stepIndex - 1 };

    case 'compute_dimension': {
      const { anchorEl, selectedEl } = action.data;
      const popoverState = computePopState(selectedEl);
      return {
        ...state,
        anchorEl,
        selectedEl: {
          ...state.selectedEl,
          top: selectedEl.y,
          left: selectedEl.x,
          height: selectedEl.height,
          width: selectedEl.width,
        },
        popoverState: {
          ...state.popoverState,
          anchorHorizontal: popoverState.anchorHorizontal,
          anchorVertical: popoverState.anchorVertical,
          transformHorizontal: popoverState.transformHorizontal,
          transformVertical: popoverState.transformVertical,
        },
      };
    }

    case 'reset_dimension':
      return { ...state, anchorEl: null, selectedEl: null };

    case 'reset':
      return { ...initialState };
    case 'toggle_checkbox':
      return { ...state, checkboxChecked: action.checked };

    default:
      return state;
  }
};
