import { Add, InfoOutlined, Remove } from 'connect-web-ui/mui';
import { useContext, useState, useEffect, useRef } from 'react';

import {
  LegacyFormElement as FormElement,
  LegacyButton as Button,
  Input,
  Drawer,
  Tooltip,
  Calendar,
  Typography,
  Counters,
  InlineMsgAlerts,
  Divider,
} from 'connect-web-ui';
import { DealsContext, HotelContext } from 'utilities/Context';
import { useLangStrings } from 'utilities/CustomHooks';
import {
  capitalizeFirstLetter,
  GAFormatting,
  isNullOrUndefined,
} from 'utilities/Utils';
import { ConfirmBoosterModal, confirmBoosterType, DiscardModal } from '..';
import useBoosterCalendar, { CALENDAR_TYPE } from '../useBoosterCalendar';

import {
  CUG_NAME_MAP,
  NO,
  VISIBILITY_CONSTANTS,
  YES,
  cugReverseMap,
  defaultEndDate,
} from 'modules/promotions-and-coupons/PnC.constants';
import {
  createOfferValuePayload,
  getInitialBlackOutToggle,
  getInitialEndTime,
  getInitialOverallDiscount,
  getInitialStartTime,
  getInitialTimeBasedDiscount,
  processBoosterData,
} from 'modules/promotions-and-coupons/utils';
import { getFormattedDate } from 'utilities/DateUtils';
import { parse, differenceInMinutes, isBefore } from 'date-fns';
import { legacyPushToGTM } from 'utilities/gtm';
import CONSTANTS from 'modules/performanceBooster/PerformanceBooster.constants';
import TimeBasedDiscounts from './TimeBasedDiscounts';
import StayDates from './StayDates';
import BookingDates from './BookingDates';
import { ChevronDown, ChevronUp } from 'assets/common';

const gtmActionType = GAFormatting(CONSTANTS.TYPE.HOLIDAYS);

const calendarConfig = {
  hasBookingEndSync: true,
  showMore: [
    CALENDAR_TYPE.BOOKING_START,
    CALENDAR_TYPE.BOOKING_END,
    CALENDAR_TYPE.BLACKOUT,
    CALENDAR_TYPE.STAY_START,
    CALENDAR_TYPE.STAY_END,
  ],

  createOnly: [],
};
const getCalendarConfig = blackOutOptions => ({
  ...calendarConfig,
  blackOutOptions,
});

export default function GenericCugDrawer(props) {
  const { data, onDrawerClose, createCUG, updateCUG, isOfferAgain } = props;

  const {
    segment,
    drawerText: {
      TITLE: title,
      SUB_TITLE: subTitle,
      TOOLTIP: specificTooltip,
      BANNER_TEXT: bannerText,
    },
    offerCode,
    checkIn,
    checkOut,
    bookingStart,
    bookingEnd,
    blackOut,
  } = data;

  const segmentKey = capitalizeFirstLetter(data?.segment?.toLowerCase() ?? '');
  const formattedSegmentKey =
    segmentKey === 'Geography' ? segmentKey.toUpperCase() : segmentKey;

  const {
    pncMetadata: {
      CUG: {
        offerCategories: { [cugReverseMap[CONSTANTS.TYPE.HOLIDAYS]]: meta },
      },
    },
    pncMetadata: {
      CUG: {
        offerCategories: {
          [cugReverseMap[formattedSegmentKey as keyof typeof cugReverseMap]]:
            mobileMeta,
        },
      },
    },
    cugBeingEdited,
    allCugStatus,
    setAllCugStatus,
  } = useContext(DealsContext);
  const { currentHotel } = useContext(HotelContext);

  const [STRINGS, COMMON_STRINGS] = useLangStrings('PerformanceBooster');

  const isEdit = !!offerCode;
  const stayEndDateRef = useRef(defaultEndDate);
  const [showMore, setShowMore] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(null);
  const [isApplicableTillNextDay, setIsApplicableTillNextDay] = useState(
    data?.timeBasedOfferData?.[0]?.isApplicableTillNextDay || false,
  );
  const [offerValue, setOfferValue] = useState(
    data.offerValue || meta.minOfferValue,
  );
  const [blackoutDatesRangeErr, setBlackoutDatesRangeErr] = useState(false);
  const [visibility, setVisibility] = useState<
  Record<VISIBILITY_CONSTANTS, boolean>
  >({
    advancedSettings: false,
    timeBasedDiscounts: true,
    stayDates: false,
    bookingDates: false,
  });
  const initialFlowData =
    allCugStatus?.[cugBeingEdited]?.time_based_offer_data?.offerValueList?.[0]
      ?.isActive?.value ||
    allCugStatus?.[cugBeingEdited]?.time_based_offer_data?.offerValueList
      ?.isActive?.value;

  const [overallDiscount, setOverallDiscount] = useState(() =>
    initialFlowData && !isEdit
      ? Math.abs(
        data.offerValue -
            allCugStatus?.[cugBeingEdited]?.time_based_offer_data?.offerValue,
      )
      : getInitialOverallDiscount(data, mobileMeta),
  );

  const [timeBasedDiscount, setTimeBasedDiscount] = useState<'yes' | 'no'>(
    mobileMeta?.timeBasedMetadata &&
      isNullOrUndefined(initialFlowData) &&
      !isEdit
      ? 'no'
      : mobileMeta?.timeBasedMetadata && !initialFlowData && !isEdit
        ? 'no'
        : initialFlowData && !isEdit
          ? 'yes'
          : getInitialTimeBasedDiscount(data),
  );

  const [startTime, setStartTime] = useState(
    initialFlowData && !isEdit
      ? allCugStatus?.[cugBeingEdited]?.time_based_offer_data?.startTime
      : getInitialStartTime(data, mobileMeta),
  );
  const [endTime, setEndTime] = useState(
    initialFlowData && !isEdit
      ? allCugStatus?.[cugBeingEdited]?.time_based_offer_data?.endTime
      : getInitialEndTime(data, mobileMeta),
  );
  const [error, setError] = useState<string | null>(null);

  const [showCalError, setShowCalError] = useState<Boolean>(false);
  const [showTotalTimeBasedError, setShowTotalTimeBasedError] = useState<
  string | null
  >(null);
  const toggleVisibility = (section: VISIBILITY_CONSTANTS) => {
    setVisibility(_prev => {
      const newVisibility = {
        advancedSettings: _prev.advancedSettings,
        timeBasedDiscounts: _prev.timeBasedDiscounts,
        stayDates: _prev.stayDates,
        bookingDates: _prev.bookingDates,
      };
      let eventAction;
      let eventLabel;

      switch (section) {
        case VISIBILITY_CONSTANTS.ADVANCED_SETTINGS:
          newVisibility.advancedSettings = !_prev.advancedSettings;
          if (!newVisibility.advancedSettings) {
            newVisibility.timeBasedDiscounts = false;
            newVisibility.stayDates = false;
            newVisibility.bookingDates = false;
          }
          eventAction = 'advancesettings';
          eventLabel = newVisibility.advancedSettings
            ? 'advance_settings_show'
            : 'advance_settings_hide';
          break;

        case VISIBILITY_CONSTANTS.TIME_BASED_DISCOUNTS:
          newVisibility.timeBasedDiscounts = !_prev.timeBasedDiscounts;
          if (newVisibility.timeBasedDiscounts) {
            newVisibility.stayDates = false;
            newVisibility.bookingDates = false;
          }
          eventAction = 'time_based_discount';
          eventLabel = newVisibility.timeBasedDiscounts
            ? 'time_based_discount_show'
            : 'time_based_discount_hide';
          break;

        case VISIBILITY_CONSTANTS.STAY_DATES:
          newVisibility.stayDates = !_prev.stayDates;
          if (newVisibility.stayDates) {
            newVisibility.timeBasedDiscounts = false;
            newVisibility.bookingDates = false;
          }
          eventAction = 'stay_dates';
          eventLabel = newVisibility.stayDates
            ? 'stay_dates_show'
            : 'stay_dates_hide';
          break;
          break;

        case VISIBILITY_CONSTANTS.BOOKING_DATES:
          newVisibility.bookingDates = !_prev.bookingDates;
          if (newVisibility.bookingDates) {
            newVisibility.timeBasedDiscounts = false;
            newVisibility.stayDates = false;
          }
          eventAction = 'booking_dates';
          eventLabel = newVisibility.bookingDates
            ? 'booking_dates_show'
            : 'booking_dates_hide';
          break;

        default:
          break;
      }
      if (eventAction && eventLabel) {
        legacyPushToGTM({
          eventCategory: segmentKey === 'Geography' ? 'ipos' : 'mobile',
          eventAction,
          eventLabel,
        });
      }

      return newVisibility;
    });
  };
  const parseTime = (time, referenceDate) => {
    const hasSeconds = time?.includes(':') && time?.split(':').length === 3;
    const format = hasSeconds ? 'HH:mm:ss' : 'HH:mm';
    return parse(time, format, referenceDate);
  };

  const changeHandler = ({ from, to }: { from: string; to: string }): void => {
    const _today = new Date();
    if (from && to) {
      const fromDate = parseTime(from, _today);
      const toDate = parseTime(to, _today);

      const applicableTillNextDay = isBefore(toDate, fromDate);

      setIsApplicableTillNextDay(applicableTillNextDay);
    }
    const formattedStartTime = from.includes(':') ? from.slice(0, 5) : from;
    const formattedEndTime = to.includes(':') ? to.slice(0, 5) : to;
    const startDate = parse(`${formattedStartTime}:00`, 'HH:mm:ss', new Date());
    const endDate = parse(`${formattedEndTime}:00`, 'HH:mm:ss', new Date());

    const eventAction = 'time_based_discount';
    const eventLabel =
      formattedStartTime !== startTime
        ? 'start_time'
        : formattedEndTime !== endTime
          ? 'end_time'
          : '';
    const eventCategory = segmentKey === 'Geography' ? 'ipos' : 'mobile';

    if (eventLabel) {
      legacyPushToGTM({
        eventCategory,
        eventAction,
        eventLabel,
      });
    }

    if (isBefore(endDate, startDate)) {
      endDate.setDate(endDate.getDate() + 1);
    }

    const timeDifference = differenceInMinutes(endDate, startDate);

    // Check if the difference is less than 60 minutes
    if (timeDifference < 60) {
      setError('The time difference must be at least 60 minutes.');
      setEndTime(formattedEndTime);
    } else {
      setError(null); // Clear error if valid
      setStartTime(formattedStartTime);
      setEndTime(formattedEndTime);
    }
  };

  const calendarInitDate = {
    [CALENDAR_TYPE.BLACKOUT]: blackOut,
    [CALENDAR_TYPE.BOOKING_START]: bookingStart,
    [CALENDAR_TYPE.BOOKING_END]: bookingEnd,
    [CALENDAR_TYPE.STAY_START]: checkIn,
    [CALENDAR_TYPE.STAY_END]: checkOut,
  };

  const {
    monthlyBlackoutLimit: monthlyLimit,
    annualBlackoutLimit: annualLimit,
  } = meta;
  const blackOutOptions: {
    month?: number;
    year?: number;
  } = {};
  if (monthlyLimit) {
    blackOutOptions.month = monthlyLimit;
  }
  if (annualLimit) {
    blackOutOptions.year = annualLimit;
  }

  const _calendarConfig = getCalendarConfig(blackOutOptions);

  const {
    calendarProps,
    calendarDates,
    generateModifiedDates,
    appendDatePayload,
  } = useBoosterCalendar(calendarInitDate, _calendarConfig);

  const {
    [CALENDAR_TYPE.BLACKOUT]: blackoutCalendarProps,
    [CALENDAR_TYPE.BOOKING_START]: bookingStartCalendarProps,
    [CALENDAR_TYPE.BOOKING_END]: bookingEndCalendarProps,
    [CALENDAR_TYPE.STAY_START]: stayStartCalendarProps,
    [CALENDAR_TYPE.STAY_END]: stayEndCalendarProps,
  } = calendarProps;

  const [blackOutToggle, setBlackOutToggle] = useState<'yes' | 'no'>(
    allCugStatus[cugBeingEdited]?.checkinblackout?.length > 0 && !isEdit
      ? 'yes'
      : getInitialBlackOutToggle(data),
  );

  const {
    [CALENDAR_TYPE.STAY_START]: stayStartDate,
    [CALENDAR_TYPE.STAY_END]: stayEndDate,
    [CALENDAR_TYPE.BOOKING_START]: bookingStartDate,
    [CALENDAR_TYPE.BOOKING_END]: bookingEndDate,
  } = calendarDates;
  const { today, MAX_CALENDAR_DATE: maxDate } = CONSTANTS;

  const drawerCloseHandler = () => {
    const eventAction = `${gtmActionType}_edit_screen`;
    legacyPushToGTM({
      eventCategory: 'web_boostperformance',
      eventAction,
      eventLabel: 'close',
    });
    setShowCancelModal(true);
  };

  const getModificationList = () => {
    const modificationMap: {
      timeBasedOfferData?;
    } = {};
    if (offerValue !== data.offerValue) {
      modificationMap[STRINGS.DRAWER.OFFER_VALUE] = {
        apiKey: 'offer_value',
        type: confirmBoosterType.NUMBER,
        initialValue: data.offerValue,
        updatedValue: offerValue,
      };
    }
    // Extracting the first offer data
    const offerData = data?.timeBasedOfferData?.[0];
    const offerValueData = offerData?.offerValueDataList?.[0];
    const timeBasedOfferData = offerValueData?.isActive ? 'yes' : 'no';

    if (
      offerValue !== data?.offerValue &&
      timeBasedDiscount === YES &&
      !visibility.advancedSettings &&
      isEdit
    ) {
      modificationMap.timeBasedOfferData = {
        apiKey: 'timeBasedOfferData',
        type: confirmBoosterType.OBJECT,
        initialValue: mobileMeta.timeBasedMetadata,
        updatedValue: [
          {
            startTime: startTime,
            endTime: endTime,
            offerValueList: [
              {
                isActive: { value: true },
                offerValue: { value: offerValue + overallDiscount },
                segment: formattedSegmentKey?.toUpperCase(),
              },
            ],
            offerCode: {
              value: data?.timeBasedOfferData?.[0]?.offerCode || '',
            },
          },
        ],
      };
    } else if (
      timeBasedDiscount === YES &&
      visibility.advancedSettings &&
      offerValue !== data?.offerValue
    ) {
      // Handle case for YES with advanced settings
      modificationMap.timeBasedOfferData = {
        apiKey: 'timeBasedOfferData',
        type: confirmBoosterType.OBJECT,
        initialValue: mobileMeta.timeBasedMetadata,
        updatedValue: [
          {
            startTime: startTime,
            endTime: endTime,
            offerValueList: [
              {
                isActive: { value: true },
                offerValue: { value: offerValue + overallDiscount },
                segment: formattedSegmentKey?.toUpperCase(),
              },
            ],
            offerCode: {
              value: data?.timeBasedOfferData?.[0]?.offerCode || '',
            },
          },
        ],
      };
    } else if (
      timeBasedDiscount === YES &&
      visibility.advancedSettings &&
      (timeBasedOfferData !== timeBasedDiscount ||
        offerData?.startTime !== startTime ||
        offerData?.endTime !== endTime ||
        offerValueData.offerValue !== data.offerValue + overallDiscount)
    ) {
      // Handle case for YES with advanced settings
      modificationMap.timeBasedOfferData = {
        apiKey: 'timeBasedOfferData',
        type: confirmBoosterType.OBJECT,
        initialValue: mobileMeta.timeBasedMetadata,
        updatedValue: [
          {
            startTime: startTime,
            endTime: endTime,
            offerValueList: [
              {
                isActive: { value: true },
                offerValue: { value: offerValue + overallDiscount },
                segment: formattedSegmentKey?.toUpperCase(),
              },
            ],
            offerCode: {
              value: data?.timeBasedOfferData?.[0]?.offerCode || '',
            },
          },
        ],
      };
    } else if (
      timeBasedDiscount === NO &&
      timeBasedOfferData !== timeBasedDiscount &&
      isEdit &&
      !isNullOrUndefined(data?.timeBasedOfferData) &&
      !visibility.advancedSettings
    ) {
      // Handle case for NO , but Yes
      modificationMap.timeBasedOfferData = {
        apiKey: 'timeBasedOfferData',
        type: confirmBoosterType.OBJECT,
        initialValue: mobileMeta.timeBasedMetadata,
        updatedValue: [
          {
            startTime: startTime,
            endTime: endTime,
            offerValueList: [
              {
                isActive: { value: true },
                offerValue: { value: offerValue + overallDiscount },
                segment: formattedSegmentKey?.toUpperCase(),
              },
            ],
            offerCode: {
              value: data?.timeBasedOfferData?.[0]?.offerCode || '',
            },
          },
        ],
      };
    } else if (
      timeBasedDiscount === NO &&
      timeBasedOfferData !== timeBasedDiscount &&
      isEdit &&
      !isNullOrUndefined(data?.timeBasedOfferData) &&
      visibility.advancedSettings
    ) {
      // Handle case for NO
      modificationMap.timeBasedOfferData = {
        apiKey: 'timeBasedOfferData',
        type: confirmBoosterType.OBJECT,
        initialValue: mobileMeta?.timeBasedMetadata,
        updatedValue: [
          {
            timeBasedDiscount: NO,
            startTime: startTime,
            endTime: endTime,
            offerValueList: [
              {
                isActive: { value: false },
                segment: formattedSegmentKey?.toUpperCase(),
              },
            ],
            offerCode: {
              value: data?.timeBasedOfferData?.[0]?.offerCode || '',
            },
          },
        ],
      };
    }

    const newBlackOutToggle = data?.blackOut?.length > 0 ? 'yes' : 'no';

    if (visibility.advancedSettings && newBlackOutToggle !== blackOutToggle) {
      modificationMap['Blackout Date'] = {
        apiKey: 'checkinblackoutdates',
        type: 'dateList',
        initialValue: [],
        updatedValue: [],
      };
    }

    generateModifiedDates(
      modificationMap,
      isEdit,
      showMore || visibility.advancedSettings,
      visibility.advancedSettings,
      blackOutToggle === NO,
    );

    return modificationMap;
  };

  const performanceActions = modificationMap => {
    if (isEdit) {
      const payload: {
        timeBasedOfferData?;
      } = {};
      for (const modification in modificationMap) {
        const { apiKey, updatedValue: apiKeyValue } =
          modificationMap[modification];
        payload[apiKey] = apiKeyValue;
      }
      if (modificationMap.timeBasedOfferData) {
        const timeBasedData = modificationMap.timeBasedOfferData.updatedValue;
        if (Array.isArray(timeBasedData)) {
          payload.timeBasedOfferData = timeBasedData.map(offer => ({
            startTime: offer.startTime,
            endTime: offer.endTime,
            offerValueList: offer.offerValueList.map(item => ({
              segment: item.segment,
              ...((timeBasedDiscount === YES ||
                (timeBasedDiscount === NO && !visibility.advancedSettings)) && {
                offerValue: {
                  value: item?.offerValue?.value,
                },
              }),
              isActive:
                timeBasedDiscount === NO && visibility.advancedSettings
                  ? { value: false }
                  : item.isActive,
            })),
            ...(offer.offerCode && {
              offerCode: {
                value: data?.timeBasedOfferData?.[0]?.offerCode
                  ? data?.timeBasedOfferData?.[0]?.offerCode?.value ||
                    data?.timeBasedOfferData?.[0]?.offerCode
                  : '',
              },
            }),
          }));
        }
      }

      updateCUG(payload, segment, offerCode);
    } else {
      const payload = {
        offer_value: offerValue,
        segment,
        timeBasedOfferData: [],
      };

      if (modificationMap.timeBasedOfferData) {
        const timeBasedData = modificationMap.timeBasedOfferData.updatedValue;
        if (Array.isArray(timeBasedData)) {
          payload.timeBasedOfferData = timeBasedData.map(offer => ({
            startTime: offer.startTime,
            endTime: offer.endTime,
            offerValueList: offer.offerValueList.map(item => ({
              segment: item.segment,
              offerValue: {
                value: item.offerValue.value,
              },
              isActive: { value: item.isActive },
            })),
            ...(offer.offerCode && {
              offerCode: { value: '' },
            }),
          }));
        }
      }
      appendDatePayload(payload, showMore || visibility.advancedSettings);
      createCUG(payload, segment);
    }
  };

  const isOfferValid = offerValue < meta.minOfferValue;
  const tooltip = specificTooltip ?? STRINGS.DRAWER_META.GENERIC.TOOLTIP;
  const invalidOfferMsg =
    STRINGS.DRAWER_META.GENERIC.INVALID_OFFER_VALUE.replace(
      '__segment__',
      CUG_NAME_MAP[segment?.toLowerCase()],
    ).replace('__minValue__', `${meta.minOfferValue}`);

  const confirmBooster = () => {
    const eventAction = `${gtmActionType}_edit_screen`;
    legacyPushToGTM({
      eventCategory: 'web_boostperformance',
      eventAction,
      eventLabel: 'activate',
    });
    if (isOfferValid) {
      return;
    }
    if (overallDiscount + offerValue > 90 && timeBasedDiscount === YES) {
      setShowTotalTimeBasedError(
        'The Time Based Discount value should not exceed 90%',
      );
      setVisibility({
        advancedSettings: true,
        timeBasedDiscounts: true,
        stayDates: false,
        bookingDates: false,
      });
      return;
    }

    if (
      blackOutToggle === YES &&
      !blackoutCalendarProps?.calendarProps?.selectedDays?.length &&
      mobileMeta?.timeBasedMetadata
    ) {
      setShowCalError(true);
      if (showCalError || blackoutDatesRangeErr) {
        setVisibility({
          advancedSettings: true,
          timeBasedDiscounts: false,
          stayDates: true,
          bookingDates: false,
        });
      }
      return;
    }

    if (blackOutToggle === YES && blackoutDatesRangeErr) {
      setVisibility({
        advancedSettings: true,
        timeBasedDiscounts: false,
        stayDates: true,
        bookingDates: false,
      });
      return;
    }

    if (error && timeBasedDiscount === YES) {
      setError('The time difference must be at least 60 minutes.');
      if (error) {
        setVisibility({
          advancedSettings: true,
          timeBasedDiscounts: true,
          stayDates: false,
          bookingDates: false,
        });
      }
      return;
    }
    setError(null);
    setShowCalError(false);
    setShowTotalTimeBasedError(null);

    const modificationMap = getModificationList();
    const additionalData = {
      'New Stay Start': {
        type: 'date',
        updatedValue: stayStartDate,
      },
      'New Stay End': {
        type: 'date',
        updatedValue: stayEndDate,
      },
      'New Booking Start': {
        type: 'date',
        updatedValue: bookingStartDate,
      },
      'New Booking End': {
        type: 'date',
        updatedValue: bookingEndDate,
      },
    };
    const combinedData = {
      ...modificationMap,
      ...additionalData,
    };
    const processedData = processBoosterData(combinedData);

    const _eventAction = 'time_based_discount';
    // const eventCategory = segmentKey === 'Geography' ? 'ipos' : 'mobile';

    // Send data to GTM
    legacyPushToGTM({
      eventCategory: processedData,
      eventAction: _eventAction,
      eventLabel: 'save_changes',
    });

    if (Object.keys(modificationMap).length !== 0) {
      setConfirmModal(combinedData);
    } else {
      onDrawerClose();
    }
  };

  const toClearEndDate =
    getFormattedDate(
      stayEndCalendarProps?.calendarProps?.selectedDays as Date,
      CONSTANTS.API_DATE_FORMAT,
    ) !==
    getFormattedDate(stayEndDateRef.current as Date, CONSTANTS.API_DATE_FORMAT);
  const toClearBookingEndDate =
    getFormattedDate(
      bookingEndCalendarProps?.calendarProps?.selectedDays as Date,
      CONSTANTS.API_DATE_FORMAT,
    ) !==
    getFormattedDate(stayEndDateRef.current as Date, CONSTANTS.API_DATE_FORMAT);

  const offerValueChange = value => setOfferValue(value);

  const handleShowMore = () => {
    const eventAction = `${gtmActionType}_edit_screen`;
    const eventLabel =
      showMore || visibility.advancedSettings
        ? 'advance_settings_hide'
        : 'advance_settings_show';
    legacyPushToGTM({
      eventCategory: 'web_boostperformance',
      eventAction,
      eventLabel,
    });
    setShowMore(c => !c);
  };

  const offerConfirm = () => {
    const eventAction = `${gtmActionType}_confirm`;
    legacyPushToGTM({
      eventCategory: 'web_boostperformance',
      eventAction,
      eventLabel: 'confirm',
    });
    performanceActions(confirmModal);
  };

  const offerCancel = () => {
    const eventAction = `${gtmActionType}_confirm`;
    legacyPushToGTM({
      eventCategory: 'web_boostperformance',
      eventAction,
      eventLabel: 'cancel',
    });
    setConfirmModal(null);
  };

  const setValuesWhileCreatingCUG = () => {
    const stayStartData = stayStartCalendarProps?.calendarProps?.selectedDays;
    const stayEndData = stayEndCalendarProps?.calendarProps?.selectedDays;

    const bookingStartData =
      bookingStartCalendarProps?.calendarProps?.selectedDays;
    const bookingEndData = bookingEndCalendarProps?.calendarProps?.selectedDays;

    const offerValueData = createOfferValuePayload(offerValue, cugBeingEdited);

    let blackoutDates = [];

    // Assuming confirmModal has keys like "Blackout Date", "timeBasedOfferData", etc.
    for (const key in confirmModal) {
      // Check if the key corresponds to a "Blackout Date"
      if (key === 'Blackout Date') {
        const { updatedValue } = confirmModal[key];

        // If updatedValue is an array, concatenate to blackoutDates
        if (Array.isArray(updatedValue)) {
          blackoutDates = blackoutDates.concat(updatedValue);
        } else if (typeof updatedValue === 'string') {
          // If it's a string, split by comma and trim whitespace to get individual dates
          blackoutDates = blackoutDates.concat(
            updatedValue.split(',').map(date => date.trim()),
          );
        }
      }
    }

    // Optional: Remove duplicates if necessary, but only if blackoutDates has been modified
    blackoutDates = blackoutDates.length > 0 ? [...new Set(blackoutDates)] : [];
    const getFormattedValue = (condition: boolean, date: Date) =>
      condition
        ? getFormattedDate(date as Date, CONSTANTS.API_DATE_FORMAT)
        : '';

    const shouldShowDates = () =>
      showMore || visibility.bookingDates || visibility.advancedSettings;

    const shouldShowStayDates = () =>
      showMore || visibility.stayDates || visibility.advancedSettings;

    const shouldShowBlackout = () =>
      showMore || blackOutToggle === YES || visibility.advancedSettings;

    const newBookingdateend = getFormattedValue(
      shouldShowDates(),
      bookingEndData,
    );

    const newCheckoutdateend = getFormattedValue(
      shouldShowStayDates(),
      stayEndData,
    );

    setAllCugStatus(prev => ({
      ...prev,
      [cugBeingEdited]: {
        ...prev[cugBeingEdited],
        isCustomised: true,
        isActive: true,
        bookingdatestart: getFormattedValue(
          shouldShowDates(),
          bookingStartData,
        ),
        ...(!!newBookingdateend && { bookingdateend: newBookingdateend }),
        checkindatestart: getFormattedValue(
          shouldShowStayDates(),
          stayStartData,
        ),
        ...(!!newCheckoutdateend && { checkoutdateend: newCheckoutdateend }),
        checkinblackout: shouldShowBlackout() ? blackoutDates : [],
        offer_value_list: offerValueData,
        time_based_offer_data:
          (segment === 'MOBILE' || segment === 'GEOGRAPHY') &&
          visibility.advancedSettings
            ? timeBasedDiscount === YES
              ? {
                startTime: startTime,
                endTime: endTime,
                isApplicableTillNextDay:
                    timeBasedDiscount === YES ? isApplicableTillNextDay : false,
                offerValue: offerValue + overallDiscount,
                offerValueList: {
                  isActive: { value: true },
                },
                offerCode: { value: '' },
                segment: formattedSegmentKey?.toUpperCase(),
              }
              : {
                identifier: 'time_based_discount_inactive', // Identifier for inactive time-based discounts
                offerValueList: {
                  isActive: { value: false },
                },
              }
            : !visibility.advancedSettings &&
              data?.offerValue !== offerValue &&
              timeBasedDiscount === YES &&
              isEdit
              ? {
                startTime: startTime,
                endTime: endTime,
                isApplicableTillNextDay:
                  timeBasedDiscount === YES ? isApplicableTillNextDay : false,
                offerValue: offerValue + overallDiscount,
                offerValueList: {
                  isActive: { value: true },
                },
                offerCode: { value: '' },
                segment: formattedSegmentKey?.toUpperCase(),
              }
              : null,
      },
    }));
    setConfirmModal(null);
    onDrawerClose();
  };

  const onConfirmCallbackFunc =
    isEdit || isOfferAgain ? offerConfirm : setValuesWhileCreatingCUG;

  useEffect(() => {
    if (showCalError) {
      setVisibility({
        advancedSettings: true,
        timeBasedDiscounts: false,
        stayDates: true,
        bookingDates: false,
      });
    }
    if (blackoutCalendarProps?.calendarProps?.selectedDays?.length > 0) {
      setShowCalError(false);
    }
  }, [blackoutCalendarProps?.calendarProps?.selectedDays, showCalError]);

  useEffect(() => {
    if (overallDiscount + offerValue > 90) {
      setShowTotalTimeBasedError(
        'The Time Based Discount value should not exceed 90%',
      );
    } else {
      setShowTotalTimeBasedError(null);
    }
  }, [overallDiscount + offerValue > 90]);

  useEffect(() => {
    const eventAction = 'time_based_discount';
    const eventLabel = timeBasedDiscount === NO ? 'no' : 'yes';
    const eventCategory = segmentKey === 'Geography' ? 'ipos' : 'mobile';
    if (visibility.timeBasedDiscounts && visibility.advancedSettings) {
      legacyPushToGTM({
        eventCategory,
        eventAction,
        eventLabel,
      });
    }
  }, [timeBasedDiscount, segmentKey, visibility.advancedSettings]);

  const handleClearEndDate = () => {
    stayEndDateRef.current = defaultEndDate;
    stayEndCalendarProps?.calendarProps?.setSelectedDays(defaultEndDate);
  };
  const handleClearBookingEndDate = () => {
    stayEndDateRef.current = defaultEndDate;
    bookingEndCalendarProps?.calendarProps?.setSelectedDays(
      stayEndCalendarProps?.calendarProps?.selectedDays,
    );
  };

  return (
    <Drawer
      isOpen
      header={{
        title,
        subTitle,
        rightElem: (
          <Tooltip
            content={tooltip}
            placement="bottom"
            customClasses={{
              tooltip: { root: '!mr-2' },
            }}
            variant="popover"
          >
            <InfoOutlined />
          </Tooltip>
        ),

        hideCross: true,
      }}
      footer={{
        primaryBtn: {
          text: STRINGS.DRAWER_META.GENERIC.POSITIVE_TEXT,
          onClick: confirmBooster,
          disabled: isOfferValid,
        },
        secondaryBtn: {
          text: 'Close',
          onClick: drawerCloseHandler,
        },
      }}
      onClose={drawerCloseHandler}
    >
      {mobileMeta?.timeBasedMetadata ? (
        <div>
          <div className="flex mb-2 justify-between">
            <Typography variant="body1" fb className="mt-2 mr-4">
              {data?.segment === 'MOBILE'
                ? STRINGS.COUNTER_HEADING_MOBILE
                : STRINGS.COUNTER_HEADING_IPOS}
            </Typography>
            <div className="flex items-center">
              <Counters
                value={offerValue}
                minLimit={meta.minOfferValue}
                maxLimit={timeBasedDiscount === NO ? 90 : 90 - overallDiscount}
                changeCounter={val => {
                  setOfferValue(val);
                }}
                endAdornment={<div className="-mr-1 -ml-1"> %</div>}
              />
            </div>
          </div>
          {bannerText && (
            <InlineMsgAlerts
              severity={'info'}
              message={bannerText}
              inerClasses="w-full !p-1"
              isMultiLine
            />
          )}
          <div>
            <div className="flex mt-6 justify-between">
              <Typography variant="h3" className="mr-4 flex items-center">
                {STRINGS.ADVANCED_SETTINGS}
              </Typography>
              <div className="flex items-center">
                <Button
                  onClick={() =>
                    toggleVisibility(VISIBILITY_CONSTANTS.ADVANCED_SETTINGS)
                  }
                  variant="text"
                  className="!capitalize !text-size-14"
                >
                  {visibility.advancedSettings
                    ? COMMON_STRINGS.BUTTONS.HIDE
                    : COMMON_STRINGS.BUTTONS.SHOW}
                  {visibility.advancedSettings ? (
                    <ChevronUp
                      fill="#008CFF"
                      className="ml-1"
                      width={20}
                      height={20}
                    />
                  ) : (
                    <ChevronDown
                      fill="#008CFF"
                      className="ml-1"
                      width={20}
                      height={20}
                    />
                  )}
                </Button>
              </div>
            </div>
            {visibility.advancedSettings && (
              <>
                <TimeBasedDiscounts
                  visibility={visibility}
                  toggleVisibility={toggleVisibility}
                  timeBasedDiscount={timeBasedDiscount}
                  setTimeBasedDiscount={setTimeBasedDiscount}
                  startTime={startTime}
                  endTime={endTime}
                  changeHandler={changeHandler}
                  offerValue={offerValue}
                  overallDiscount={overallDiscount}
                  setOverallDiscount={setOverallDiscount}
                  STRINGS={STRINGS}
                  data={data}
                  COMMON_STRINGS={COMMON_STRINGS}
                  mobileMeta={mobileMeta}
                  error={error}
                  showTotalTimeBasedError={showTotalTimeBasedError}
                  timeZone={currentHotel?.timezone}
                  isApplicableTillNextDay={isApplicableTillNextDay}
                  timeZoneOffset={currentHotel?.timezoneOffset}
                  isEdit={isEdit}
                  timeBasedData={
                    isEdit
                      ? data?.timeBasedOfferData?.[0]
                      : allCugStatus[segmentKey?.toLowerCase()]
                        ?.time_based_offer_data
                  }
                  segmentKey={segmentKey}
                />
                <div>
                  <Divider />
                </div>
                {visibility.advancedSettings && (
                  <>
                    <StayDates
                      blackoutDatesRangeErr={blackoutDatesRangeErr}
                      setBlackoutDatesRangeErr={setBlackoutDatesRangeErr}
                      visibility={visibility}
                      toggleVisibility={toggleVisibility}
                      stayStartCalendarProps={stayStartCalendarProps}
                      stayEndCalendarProps={stayEndCalendarProps}
                      toClearEndDate={toClearEndDate}
                      handleClearEndDate={handleClearEndDate}
                      blackOutToggle={blackOutToggle}
                      setBlackOutToggle={setBlackOutToggle}
                      blackoutCalendarProps={blackoutCalendarProps}
                      showCalError={showCalError}
                      STRINGS={STRINGS}
                      COMMON_STRINGS={COMMON_STRINGS}
                      stayEndDate={stayEndDate}
                      today={today}
                      isEdit={isEdit}
                      bookingStartDate={bookingStartDate}
                      stayStartDate={stayStartDate}
                    />
                    <div>
                      <Divider />
                    </div>
                    {visibility.advancedSettings && (
                      <>
                        <BookingDates
                          visibility={visibility}
                          toggleVisibility={toggleVisibility}
                          bookingStartCalendarProps={bookingStartCalendarProps}
                          bookingEndCalendarProps={bookingEndCalendarProps}
                          isEdit={isEdit}
                          STRINGS={STRINGS}
                          COMMON_STRINGS={COMMON_STRINGS}
                          toClearBookingEndDate={toClearBookingEndDate}
                          handleClearBookingEndDate={handleClearBookingEndDate}
                          stayStartDate={stayStartDate}
                          today={today}
                          maxDate={maxDate}
                          stayEndDate={stayEndDate}
                        />
                      </>
                    )}
                    <div>
                      <Divider />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <>
          <FormElement
            className="margin0 marginT28"
            label={STRINGS.DRAWER.OFFER_VALUE_PLACEHOLDER}
            errorMsg={isOfferValid ? invalidOfferMsg : ''}
          >
            <Input
              type="number"
              value={offerValue}
              onChange={offerValueChange}
              placeholder={STRINGS.DRAWER.OFFER_VALUE_PLACEHOLDER}
              max={meta.maxOfferValue}
              rightElement="%"
              error={isOfferValid}
              decimalPoints={0}
            />
          </FormElement>
          {bannerText && (
            <p className="drawer-persuasion-info-banner">{bannerText}</p>
          )}

          <Button
            onClick={handleShowMore}
            variant="text"
            noPadding
            className="!mt-[10px]"
          >
            {showMore ? (
              <>
                <Remove className="booster-advance-settings-icon" />
                {STRINGS.DRAWER.HIDE_ADVANCE_SETTINGS}
              </>
            ) : (
              <>
                <Add className="booster-advance-settings-icon" />
                {STRINGS.DRAWER.SHOW_ADVANCE_SETTINGS}
              </>
            )}
          </Button>
          {showMore && (
            <>
              <FormElement
                className="marginT28"
                label={STRINGS.DRAWER.APPLICABLE_STAY_START}
              >
                <Calendar
                  type="input"
                  {...stayStartCalendarProps}
                  disabledBefore={(bookingStartDate || today) as Date}
                  disabled={isEdit}
                />
              </FormElement>
              <FormElement
                className="marginT28"
                label={STRINGS.DRAWER.APPLICABLE_STAY_END}
              >
                <Calendar type="input" {...stayEndCalendarProps} />
              </FormElement>
              <FormElement
                className="marginT28"
                label={STRINGS.DRAWER.APPLICABLE_BOOKING_START}
              >
                <Calendar
                  type="input"
                  {...bookingStartCalendarProps}
                  disabledAfter={(stayStartDate || maxDate) as Date}
                  disabled={isEdit}
                />
              </FormElement>
              <FormElement
                className="marginT28"
                label={STRINGS.DRAWER.APPLICABLE_BOOKING_END}
              >
                <Calendar
                  type="input"
                  {...bookingEndCalendarProps}
                  disabledBefore={(stayStartDate || today) as Date}
                  disabledAfter={(stayEndDate || maxDate) as Date}
                />
              </FormElement>
              <FormElement
                className="marginT28"
                label={STRINGS.DRAWER.CHOOSE_BLACKOUT_DATES}
              >
                <Calendar
                  type="blackout"
                  inputClasses="!w-[250px]"
                  {...blackoutCalendarProps}
                  disabledBefore={(stayStartDate || today) as Date}
                  disabledAfter={stayEndDate as Date}
                />
              </FormElement>
            </>
          )}
        </>
      )}
      {showCancelModal && (
        <DiscardModal
          gtmActionType={gtmActionType}
          onDrawerClose={onDrawerClose}
          setShowCancelModal={setShowCancelModal}
        />
      )}

      {confirmModal && (
        <ConfirmBoosterModal
          data={confirmModal}
          onConfirm={onConfirmCallbackFunc}
          onClose={offerCancel}
        />
      )}
    </Drawer>
  );
}
