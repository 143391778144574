import { Skeleton } from 'connect-web-ui/mui';
import React from 'react';

export function HeaderShimmer() {
  return (
    <div
      id={'global-shimmer'}
      className="bg-color-white p-2 flex w-full items-center justify-center h-[72px]"
    >
      <div className="flex justify-between w-full">
        <div className="flex items-center w-full">
          <Skeleton
            height={44}
            width={44}
            variant="rectangular"
            className="rounded-3xl ml-6"
          />
          <div className="ml-4">
            <Skeleton
              height={16}
              width={190}
              variant="rectangular"
              className="w-full rounded"
            />
            <Skeleton
              height={16}
              width={246}
              variant="rectangular"
              className="rounded mt-2"
            />
          </div>
          <Skeleton
            height={36}
            variant="rectangular"
            className="w-1/3 rounded ml-2"
          />
        </div>
        <div className="flex w-full items-center justify-end">
          <Skeleton
            height={44}
            width={44}
            variant="rectangular"
            className="rounded"
          />
          <Skeleton
            height={44}
            width={44}
            variant="rectangular"
            className="rounded-3xl ml-4"
          />
        </div>
      </div>
    </div>
  );
}
