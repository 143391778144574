import { campaignTypes, campaignTypesNumMap } from './SaleHandling.constants';
import { PostPayload } from './SaleHandling.types';

export const GET_CAMPAIGN_QUERY = `
query RPCGetCampaignOfferDetails($endpointRequestData: EndpointRequestData)
{
    rpcGetCampaignOfferDetails(endpointRequestData: $endpointRequestData)
    {
        data
        {
            offerMetaData
            offerDataList
            campaignHotelMappings
            {
                campaignMappingId
                participationStatus
                hotelId
                campaign
                {
                    campaignId
                    campaignName
                    campaignStatus
                    campaignEndTime
                    stayStartDate
                    stayEndDate
                    discountValue
                    videoUrl
                    campaignParticipation
                    campaignLogo
                    bookingEndTime
                    discountValue
                }
                hotelCampaignAttributes
                {
                    additionalDiscount
                    offerCategory
                }
            }
        }
        errorDetail
        {
            errorCode
            errorMessage
            errorType
            displayMessage
            httpStatusCode
        }
        message
    }
}`;

export const generateSalesVariable = (mmt_id, offerCode) => {
  return {
    endpointRequestData: {
      rpcGetCampaignOfferDetails: {
        getCampaignEntityRequest: {
          modelType: 'modelType1',
          body: {
            hotelId: mmt_id,
            campaignType: campaignTypesNumMap[campaignTypes.sale], //for extranet always SALE
            offerCodeList: offerCode ? [offerCode] : null,
          },
        },
      },
    },
  };
};

export const MODIFY_SALE_QUERY = `
query RpcModifyCampaignMappingDetails($rawEndpointRequestData: RawEndpointsRequestData)
{
  rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData)
  {
    rpcModifyCampaignMappingDetails
    {
      message
      errorDetail
      {
          errorCode
          errorMessage
          errorType
          displayMessage
          httpStatusCode
      }
      campaignMappingId
    }
  }
}`;

export const generateModifySaleVariables = ({
  hotelId,
  campaignId,
  participationStatus,
  additionalDiscount,
  finalDiscount,
  blackoutDates,
  offerCode,
}: PostPayload) => ({
  rawEndpointRequestData: {
    rpcModifyCampaignMappingDetails: {
      modelType: 'modelType1',
      body: {
        hotelId,
        campaignId,
        participationStatus,
        additionalDiscount,
        finalDiscount,
        blackoutDates,
        offerCode,
      },
    },
  },
});

export const GET_DEALS_LIST_QUERY = `query RpcGetOffer($rawEndpointRequestData: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
    rpcGetOffer {
      success
      message
      errorCode
      data{
        offerDataList{
          offerCode
          campaignDetails {
            campaignId
            imageUrl
          }
        }
      } 
      __typename
    }
    __typename
  }
}
`;
