import React from 'react';
import { autobind } from '../../../utilities/Autobind';
import { SummaryCard } from './SummaryCard';
import * as AC from '../constants/Dashboard';
import {
  convertArrayToStringArray,
  substractFromToday,
  INTLFormat,
} from '../utils/utils';
import {
  DashboardLoader as Loader,
  DefaultMessageSection,
} from '../components';
import { Refresh2 } from '../images';
import { wrappedStrings } from '../../../utilities/CustomHooks';
import { Dashboard } from '../../../utilities/TestIds';
import { getTopCardsData } from '../utils/DashboardGraphClient';

const DataDisplaySection = ({ context, STRINGS }) => {
  return (
    <SummaryCard
      cardName={context.info}
      details={context.state.data}
      tooltip={context.state.tooltip}
      STRINGS={STRINGS}
    />
  );
};

const SectionBody = ({ context, STRINGS }) => {
  let filler = (
    <DefaultMessageSection message={context.defaultSectionMessage} />
  );
  if (context.state.isLoading) {
    filler = <Loader />;
  } else if (Object.keys(context.state.data).length > 0) {
    filler = <DataDisplaySection context={context} STRINGS={STRINGS} />;
  }
  return filler;
};

class TodaysAverageRoomRent extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
    const { STRINGS, COMMON_STRINGS } = this.props;
    this.hotel = props.hotel;
    this.property = ['room_nights', 'sell_amount'];
    this.brand = ['all'];
    this.compType = 'hotel';
    this.interval = 'days';
    this.period = 7;
    this.intervalField = 'stay';
    this.defaultSectionMessage = COMMON_STRINGS.MESSAGES.NO_DATA_FOUND;
    this.info = STRINGS.TODAYS_AVG_ROOMRENT.INFO;
    this.state = {
      isLoading: false,
      data: {},
      showSection: true,
      tooltip: {
        heading: STRINGS.TODAYS_AVG_ROOMRENT.TOOLTIP_HEADING,
        positiveText: STRINGS.TODAYS_AVG_ROOMRENT.POSITIVE_TEXT,
        negativeText: STRINGS.TODAYS_AVG_ROOMRENT.NEGATIVE_TEXT,
      },
    };
  }

  componentWillReceiveProps(props) {
    if (this.props.hotel.hotelId !== props.hotel.hotelId) {
      this.hotel = props.hotel;
      this.fetchData();
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.setState({ isLoading: true });
    getTopCardsData(
      AC.DASHBOARD_TOP_CARDS_TYPES.TODAYS_AVERAGE_RENT,
      this.hotel.hotelId,
    )
      .then(this.onSuccess)
      .catch(this.onFailure);
  }

  onSuccess(response) {
    if (response.data.status === 'failure') {
      this.defaultSectionMessage = response.data.message;
      this.props.onSectionLoadUpdate(true, this.props.gtmLabel);
      const parsedData = {};
      parsedData.count = 0;
      parsedData.boost = false;
      parsedData.boostPc = '0%';
      parsedData.next7DayCount = 0;
      parsedData.currency = this.hotel.currency;
      this.setState({ isLoading: false, data: parsedData, showSection: true });
      return;
    }
    this.props.onSectionLoadUpdate(true, this.props.gtmLabel);
    const data = response.data.data;
    const parsedData = {};
    parsedData.count = INTLFormat(data.value, this.hotel.currency);
    parsedData.boost = data.boost;
    parsedData.boostPc = `${data.boost_pc}%`;
    parsedData.next7DayCount = INTLFormat(
      data.upcoming_week_value,
      this.hotel.currency,
    );
    parsedData.currency = this.hotel.currency;

    this.setState({ data: parsedData, isLoading: false, showSection: true });
  }

  onFailure() {
    this.props.onSectionLoadUpdate(true, this.props.gtmLabel);
    this.defaultSectionMessage = (
      <img
        onClick={this.fetchData}
        src={Refresh2}
        alt="Refresh"
        className="sr-cursor"
      />
    );
    this.setState({ isLoading: false, data: {}, showSection: true });
  }

  buildRequest() {
    const request = {};
    request.property = convertArrayToStringArray(this.property);
    request.period = this.period;
    request.interval = this.interval;
    request.start_date = substractFromToday(this.period, this.interval);
    request.comp_data = true;
    request.comp_type = this.compType;
    request.brand = convertArrayToStringArray(this.brand);
    request.interval_field = this.intervalField;
    request.pipeline = AC.TRANSACTION;
    return request;
  }

  render() {
    const { STRINGS } = this.props;
    return (
      this.state.showSection && (
        <div className="sr-flex1 marginL15" data-test-id={Dashboard.roomrate}>
          <div className="sr-panel">
            <SectionBody context={this} STRINGS={STRINGS} />
          </div>
        </div>
      )
    );
  }
}
export default wrappedStrings(TodaysAverageRoomRent, 'Dashboard');
