import { Typography } from 'connect-web-ui';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { classNames } from 'utilities/Utils';
import { SideNavBadgeIcon } from './Components/SideNavBadgeIcon';
import { SideNavConditionalTooltipWrapper } from './SideNavConditionalTooltipWrapper';
import SideNavDataIdWrapper from './SideNavOmnitureEventWrapper';
import { useLocation } from 'react-router-dom';
import { LeftNavItem } from './SideNav.types';
import { SCREEN_LEVEL } from './SideNav.constants';
import { Lock } from 'assets/modules/navigation';
import { handleSideNavItemClick } from './SideNav.utils';
import { HotelContext } from 'utilities/Context';

type ISideNavChildrenItems = {
  childrenTabs: LeftNavItem[];
  closePopOver: () => void;
};
export const SideNavChildrenItems = ({
  childrenTabs,
  closePopOver,
}: ISideNavChildrenItems) => {
  const { setShowHelpdeskDrawer } = useContext(HotelContext) || {};
  const { pathname } = useLocation();
  const [isMenuItemActive, setIsMenuItemActive] = useState<string>();

  useEffect(() => {
    childrenTabs.forEach((tab: LeftNavItem) => {
      if (pathname.includes(tab.cta?.deeplink)) {
        setIsMenuItemActive(tab.data?.title);
      }
    });
  }, []);

  return (
    <Fragment>
      {childrenTabs.map(tab => {
        const showBlocker = tab?.additionalMetadata?.verificationBlocker;
        const disabled = showBlocker || tab.disabled;
        return (
          <SideNavDataIdWrapper
            trackingConfig={tab.trackingConfig}
            addDataId
            key={tab.data.title}
          >
            <div
              className={classNames(
                isMenuItemActive === tab.data.title ? '!bg-color-primary' : '',
                ' rounded-md w-full p-2 ',
                disabled || tab.disabled
                  ? 'cursor-not-allowed'
                  : 'hover:bg-[#F2F2F2] cursor-pointer',
              )}
              onClick={e => {
                e.stopPropagation();
                if (tab.disabled || !tab.cta?.deeplink || disabled) return;
                handleSideNavItemClick(tab.cta, setShowHelpdeskDrawer);
                closePopOver();
              }}
            >
              <SideNavConditionalTooltipWrapper
                showTooltip={tab.disabled}
                tooltipContent={tab?.data?.toast}
              >
                <div>
                  <div className="flex items-center">
                    <Typography
                      variant="body1"
                      themeColor={
                        isMenuItemActive === tab.data.title
                          ? 'primary.main'
                          : tab.disabled
                            ? 'common.gray_75'
                            : 'gray.main'
                      }
                      className={`${
                        isMenuItemActive === tab.data.title ? 'font-bold' : ''
                      } text-wrap relative !font-lato`}
                    >
                      {tab.data?.title}
                    </Typography>
                    {!showBlocker ? (
                      <SideNavBadgeIcon
                        badgeItem={tab.badge}
                        fromScreen={SCREEN_LEVEL.L2}
                      />
                    ) : (
                      <Lock className="ml-2" />
                    )}
                  </div>
                  <Typography
                    variant="body1"
                    themeColor={
                      isMenuItemActive === tab.data.title
                        ? 'primary.main'
                        : 'common.gray_75'
                    }
                    className={'text-wrap relative !font-lato'}
                  >
                    {tab.data?.subtitle}
                  </Typography>
                </div>
              </SideNavConditionalTooltipWrapper>
            </div>
          </SideNavDataIdWrapper>
        );
      })}
    </Fragment>
  );
};
