import React from 'react';
export const SummaryCard = ({ STRINGS, cardName, details, tooltip = {} }) => {
  const { count, next7DayCount, currency, boost, boostPc } = details;
  const prefix = currency ? currency : '';
  return (
    <div data-test-id="top-estimation-cards" className="sr-panel-body">
      <div className="sr-panel_dashboard">
        <span className="flex-centralized posRel">
          {cardName}
          <span
            className="glyphicon glyphicon-info-sign tooltip-trigger"
            style={{ marginLeft: 'auto', color: 'grey' }}
          />
          <div className="tooltip-box">
            <p className="tooltip-heading">{tooltip.heading}</p>
            <div className="tooltip-body">
              <span
                className="glyphicon glyphicon-arrow-up"
                style={{ fontSize: '12px', color: '#30a960', margin: '0 8px' }}
              />
              <span className="tooltip-text">{tooltip.positiveText}</span>
              <span
                className="glyphicon glyphicon-arrow-down"
                style={{ fontSize: '12px', color: '#f06159', margin: '0 8px' }}
              />
              <span className="tooltip-text">{tooltip.negativeText}</span>
            </div>
          </div>
        </span>
      </div>
      <div className="d-flex sr-align-center">
        <h2 className="detail_percentage d-flex sr-align-center">
          <span className="font15">{prefix}</span>
          {count}
        </h2>
        <span
          className="sr-label marginL8 font10"
          style={{
            backgroundColor: '#767a83',
          }}
        >
          {boostPc}
        </span>
        <span
          className={`glyphicon ${
            boost ? 'glyphicon-arrow-up' : 'glyphicon-arrow-down'
          }`}
          style={{
            color: boost ? '#30a960' : '#f06159',
            fontSize: '14px',
            marginLeft: '4px',
          }}
        />
      </div>
      <p className="sr-panel_dashboard">
        {STRINGS.SUMMARY_CARD.NEXT_7_DAYS} &nbsp;
        <b className="black-ltr">
          {prefix}&nbsp;
          {next7DayCount}
        </b>
      </p>
    </div>
  );
};
