import { Add, InfoOutlined, Remove } from 'connect-web-ui/mui';
import { useContext, useState } from 'react';

import * as PerformanceBooster from 'assets/modules/performance-booster';
import {
  LegacyFormElement as FormElement,
  Input,
  Dropdown,
  Drawer,
  Typography,
  Tooltip,
  Calendar,
  Button,
} from 'connect-web-ui';
import { DealsContext } from 'utilities/Context';
import { useLangStrings } from 'utilities/CustomHooks';
import { GAFormatting } from 'utilities/Utils';
import { ConfirmBoosterModal, confirmBoosterType, DiscardModal } from '..';
import useBoosterCalendar, { CALENDAR_TYPE } from '../useBoosterCalendar';

import { cugReverseMap } from 'modules/promotions-and-coupons/PnC.constants';
import { createOfferValuePayload } from 'modules/promotions-and-coupons/utils';
import { getFormattedDate } from 'utilities/DateUtils';
import { legacyPushToGTM } from 'utilities/gtm';
import CONSTANTS from 'modules/performanceBooster/PerformanceBooster.constants';

import './CorporateDrawer.scss';

const gtmActionType = GAFormatting(CONSTANTS.TYPE.CORPORATE);

const getAdvancePurchaseOptions = STRINGS => {
  const { ADVANCE_PURCHASE_DROPDOWN } = STRINGS;
  return [
    {
      label: ADVANCE_PURCHASE_DROPDOWN.CUSTOMISED,
      value: CONSTANTS.OFFER_CATEGORY_TYPE.CUSTOMISED,
    },
    {
      label: ADVANCE_PURCHASE_DROPDOWN.LAST_MINUTE,
      value: CONSTANTS.OFFER_CATEGORY_TYPE.LAST_MINUTE,
    },
    {
      label: ADVANCE_PURCHASE_DROPDOWN.EARLY_BIRD,
      value: CONSTANTS.OFFER_CATEGORY_TYPE.EARLY_BIRD,
    },
  ];
};

const getInitialDaysBeforeCheckIn = (
  offerCategory,
  earlyBirdMin,
  earlyBirdMax,
) => {
  if (offerCategory === CONSTANTS.OFFER_CATEGORY_TYPE.EARLY_BIRD) {
    return earlyBirdMin;
  } else if (offerCategory === CONSTANTS.OFFER_CATEGORY_TYPE.LAST_MINUTE) {
    return earlyBirdMax;
  }
  return CONSTANTS.OFFER_CATEGORY_INITIAL_VALUE;
};

const isAdvancePurchase = category =>
  category === CONSTANTS.OFFER_CATEGORY_TYPE.EARLY_BIRD ||
  category === CONSTANTS.OFFER_CATEGORY_TYPE.LAST_MINUTE;

const calendarConfig = {
  hasBookingEndSync: true,
  showMore: [
    CALENDAR_TYPE.BOOKING_START,
    CALENDAR_TYPE.BOOKING_END,
    CALENDAR_TYPE.BLACKOUT,
    CALENDAR_TYPE.STAY_START,
    CALENDAR_TYPE.STAY_END,
  ],

  createOnly: [],
};
const getCalendarConfig = blackOutOptions => ({
  ...calendarConfig,
  blackOutOptions,
});

const getToolTipContent = STRINGS => {
  const {
    DRAWER_META: {
      CORPORATE: { TOOLTIP: toolTip },
    },
  } = STRINGS;

  return (
    <>
      {toolTip.map((item, index) => {
        const { QUESTION: question, ANSWER: answer } = item;
        return (
          <div className="corporate-section" key={index}>
            <h3>{question}</h3>
            <p>{answer}</p>
          </div>
        );
      })}
    </>
  );
};

export default function CorporateDrawer(props) {
  const { data, onDrawerClose, createCUG, updateCUG, isOfferAgain } = props;

  const {
    pncMetadata: {
      CUG: {
        // @ts-ignore
        offerCategories: { [cugReverseMap[CONSTANTS.TYPE.CORPORATE]]: meta },
      },
    },
    cugBeingEdited,
    setAllCugStatus,
  } = useContext(DealsContext);

  const [STRINGS] = useLangStrings('PerformanceBooster');

  const {
    offerCode,
    checkIn,
    checkOut,
    bookingStart,
    bookingEnd,
    blackOut,
    offerCategory,
    earlyBirdMin,
    earlyBirdMax,
  } = data;
  const isEdit = !!offerCode && !isOfferAgain;

  const [showMore, setShowMore] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(null);
  const [offerValue, setOfferValue] = useState(
    data.offerValue || meta.minOfferValue,
  );

  const calendarInitDate = {
    [CALENDAR_TYPE.BLACKOUT]: blackOut,
    [CALENDAR_TYPE.BOOKING_START]: bookingStart,
    [CALENDAR_TYPE.BOOKING_END]: bookingEnd,
    [CALENDAR_TYPE.STAY_START]: checkIn,
    [CALENDAR_TYPE.STAY_END]: checkOut,
  };

  const {
    monthlyBlackoutLimit: monthlyLimit,
    annualBlackoutLimit: annualLimit,
  } = meta;
  const blackOutOptions: {
    month?: number;
    year?: number;
  } = {};
  if (monthlyLimit) {
    blackOutOptions.month = monthlyLimit;
  }
  if (annualLimit) {
    blackOutOptions.year = annualLimit;
  }

  const _calendarConfig = getCalendarConfig(blackOutOptions);
  const {
    calendarProps,
    calendarDates,
    generateModifiedDates,
    appendDatePayload,
  } = useBoosterCalendar(calendarInitDate, _calendarConfig);

  const {
    [CALENDAR_TYPE.BLACKOUT]: blackoutCalendarProps,
    [CALENDAR_TYPE.BOOKING_START]: bookingStartCalendarProps,
    [CALENDAR_TYPE.BOOKING_END]: bookingEndCalendarProps,
    [CALENDAR_TYPE.STAY_START]: stayStartCalendarProps,
    [CALENDAR_TYPE.STAY_END]: stayEndCalendarProps,
  } = calendarProps;

  const {
    [CALENDAR_TYPE.STAY_START]: stayStartDate,
    [CALENDAR_TYPE.STAY_END]: stayEndDate,
    [CALENDAR_TYPE.BOOKING_START]: bookingStartDate,
  } = calendarDates;
  const { today, MAX_CALENDAR_DATE: maxDate } = CONSTANTS;

  // Advance purchase
  const [advancedPurchaseData, setAdvancedPurchaseData] = useState(() => {
    const dropdownOption = getAdvancePurchaseOptions(STRINGS);
    if (isAdvancePurchase(offerCategory)) {
      // Removing default no select option.
      dropdownOption.splice(0, 1);
    }
    const daysBeforeCheckIn = getInitialDaysBeforeCheckIn(
      offerCategory,
      earlyBirdMin,
      earlyBirdMax,
    );
    const dropdownData =
      dropdownOption.find(option => option.value === offerCategory) ||
      dropdownOption[0];

    return { daysBeforeCheckIn, dropdownData, dropdownOption };
  });

  const {
    dropdownData: { value: updatedCategory },
  } = advancedPurchaseData;
  const hasAdvancePurchase = isAdvancePurchase(updatedCategory);

  const changeAdvancePurchaseOption = value =>
    setAdvancedPurchaseData(prev => ({ ...prev, dropdownData: value }));

  const changeAdvancePurchaseValue = value =>
    setAdvancedPurchaseData(prev => ({ ...prev, daysBeforeCheckIn: value }));

  const drawerCloseHandler = () => {
    const eventAction = `${gtmActionType}_edit_screen`;
    legacyPushToGTM({
      eventCategory: 'web_boostperformance',
      eventAction,
      eventLabel: 'close',
    });
    setShowCancelModal(true);
  };

  const getModificationList = () => {
    const {
      OFFER_CATEGORY_TYPE: { CUSTOMISED: customised },
    } = CONSTANTS;
    const modificationMap: {
      advancePurchase?;
    } = {};

    // Offer value
    if (offerValue !== data.offerValue) {
      modificationMap[STRINGS.DRAWER.OFFER_VALUE] = {
        apiKey: 'offer_value',
        type: confirmBoosterType.NUMBER,
        initialValue: data.offerValue,
        updatedValue: offerValue,
      };
    }

    generateModifiedDates(modificationMap, isEdit, showMore, false, false);

    // Advance payment
    if (showMore && hasAdvancePurchase) {
      const daysBeforeCheckIn = getInitialDaysBeforeCheckIn(
        offerCategory,
        earlyBirdMin,
        earlyBirdMax,
      );
      const { daysBeforeCheckIn: updatedDaysBeforeCheckIn } =
        advancedPurchaseData;

      if (
        offerCategory !== updatedCategory ||
        daysBeforeCheckIn !== updatedDaysBeforeCheckIn
      ) {
        const hasPurchaseNoUpdate =
          offerCategory === customised || offerCategory === '';

        modificationMap.advancePurchase = {
          apiKey: 'advance_purchase',
          type: confirmBoosterType.OBJECT,
          data: {
            [STRINGS.DRAWER.ADVANCE_PURCHASE_TYPE]: {
              type: confirmBoosterType.STRINGS,
              initialValue: offerCategory,
              updatedValue: updatedCategory,
            },
            [STRINGS.DRAWER.ADVANCE_PURCHASE_RULE]: {
              type: confirmBoosterType.STRINGS,
              initialValue: hasPurchaseNoUpdate ? null : daysBeforeCheckIn,
              updatedValue: advancedPurchaseData.daysBeforeCheckIn,
              suffix: STRINGS.DAYS,
            },
          },
        };
      }
    }

    return modificationMap;
  };

  const appendAdvancePayment = payload => {
    const { OFFER_CATEGORY_TYPE, DEFAULT_EARLY_BIRD_MAX } = CONSTANTS;
    if (
      offerCategory !== OFFER_CATEGORY_TYPE.CUSTOMISED &&
      offerCategory !== ''
    ) {
      const {
        daysBeforeCheckIn,
        dropdownData: { value: _offerCategory },
      } = advancedPurchaseData;

      let earlybirdmin = 0;
      let earlybirdmax = DEFAULT_EARLY_BIRD_MAX;
      if (_offerCategory === OFFER_CATEGORY_TYPE.EARLY_BIRD) {
        earlybirdmin = daysBeforeCheckIn;
      } else if (_offerCategory === OFFER_CATEGORY_TYPE.LAST_MINUTE) {
        earlybirdmax = daysBeforeCheckIn;
      }
      payload.earlybirdmin = earlybirdmin;
      payload.earlybirdmax = earlybirdmax;
      payload.offercategory = _offerCategory;
    }
  };

  const performanceActions = modificationMap => {
    const {
      TYPE: { CORPORATE: segment },
    } = CONSTANTS;
    if (isEdit) {
      const payload = {};
      for (const modification in modificationMap) {
        const { apiKey, updatedValue: apiKeyValue } =
          modificationMap[modification];
        if (apiKey === 'advance_purchase') {
          appendAdvancePayment(payload);
        } else {
          payload[apiKey] = apiKeyValue;
        }
      }

      updateCUG(payload, segment, offerCode);
    } else {
      const payload = { offer_value: offerValue, segment };

      appendDatePayload(payload, showMore);
      // Advance payment
      if (showMore) {
        appendAdvancePayment(payload);
      }

      createCUG(payload, segment);
    }
  };

  const isOfferValid = offerValue < meta.minOfferValue;
  const invalidOfferMsg =
    STRINGS.DRAWER_META.GENERIC.INVALID_OFFER_VALUE.replace(
      '__segment__',
      STRINGS.MY_BIZ,
    ).replace('__minValue__', `${meta.minOfferValue}`);
  const confirmBooster = () => {
    const eventAction = `${gtmActionType}_edit_screen`;
    legacyPushToGTM({
      eventCategory: 'web_boostperformance',
      eventAction,
      eventLabel: 'activate',
    });
    if (isOfferValid) {
      return;
    }
    const modificationMap = getModificationList();
    if (Object.keys(modificationMap).length !== 0) {
      setConfirmModal(modificationMap);
    } else {
      onDrawerClose();
    }
  };

  const offerValueChange = value => setOfferValue(value);

  const handleShowMore = () => {
    const eventAction = `${gtmActionType}_edit_screen`;
    const eventLabel = showMore
      ? 'advance_settings_hide'
      : 'advance_settings_show';
    legacyPushToGTM({
      eventCategory: 'web_boostperformance',
      eventAction,
      eventLabel,
    });
    setShowMore(c => !c);
  };

  const offerConfirm = () => {
    const eventAction = `${gtmActionType}_confirm`;
    legacyPushToGTM({
      eventCategory: 'web_boostperformance',
      eventAction,
      eventLabel: 'confirm',
    });
    performanceActions(confirmModal);
  };

  const offerCancel = () => {
    const eventAction = `${gtmActionType}_confirm`;
    legacyPushToGTM({
      eventCategory: 'web_boostperformance',
      eventAction,
      eventLabel: 'cancel',
    });
    setConfirmModal(null);
  };

  const corporateLogo = (
    <img
      src={PerformanceBooster.DRAWER.corporate}
      className="d-inflex marginR4"
      width="36"
      height="18"
      alt="My Biz"
    />
  );

  const tooltipContent = getToolTipContent(STRINGS);

  const setValuesWhileCreatingCUG = () => {
    const stayStartData = stayStartCalendarProps?.calendarProps?.selectedDays;
    const stayEndData = stayEndCalendarProps?.calendarProps?.selectedDays;

    const bookingStartData =
      bookingStartCalendarProps?.calendarProps?.selectedDays;
    const bookingEndData = bookingEndCalendarProps?.calendarProps?.selectedDays;

    const offerValueData = createOfferValuePayload(offerValue, cugBeingEdited);
    let blackoutDates = [];
    for (const custValues in confirmModal) {
      const { updatedValue } = confirmModal[custValues];
      blackoutDates = Array.isArray(updatedValue) ? updatedValue : [];
    }

    setAllCugStatus(prev => ({
      ...prev,
      [cugBeingEdited]: {
        ...prev[cugBeingEdited],
        isCustomised: true,
        isActive: true,
        bookingdatestart: showMore
          ? getFormattedDate(
            bookingStartData as Date,
            CONSTANTS.API_DATE_FORMAT,
          )
          : '',
        bookingdateend: showMore
          ? getFormattedDate(bookingEndData as Date, CONSTANTS.API_DATE_FORMAT)
          : '',
        checkindatestart: getFormattedDate(
          stayStartData as Date,
          CONSTANTS.API_DATE_FORMAT,
        ),
        checkoutdateend: getFormattedDate(
          stayEndData as Date,
          CONSTANTS.API_DATE_FORMAT,
        ),
        checkinblackout: showMore ? blackoutDates : [],
        offer_value_list: offerValueData,
      },
    }));
    setConfirmModal(null);
    onDrawerClose();
  };

  const onConfirmCallbackFunc =
    isEdit || isOfferAgain ? offerConfirm : setValuesWhileCreatingCUG;

  return (
    <Drawer
      isOpen
      header={{
        title: (
          <div className="flex gap-1">
            <div>{corporateLogo}</div>
            <Typography variant="h3" className="pt-[2px]">
              {STRINGS.DRAWER_META.CORPORATE.TITLE}
            </Typography>
          </div>
        ),
        subTitle: STRINGS.DRAWER_META.CORPORATE.SUB_TITLE,
        rightElem: (
          <Tooltip
            content={tooltipContent}
            placement="bottom"
            customClasses={{
              tooltip: {
                root: 'corporate-tooltip !mr-2',
              },
            }}
            variant="popover"
          >
            <InfoOutlined className="mt-1" />
          </Tooltip>
        ),
        hideCross: true,
      }}
      footer={{
        primaryBtn: {
          text: STRINGS.DRAWER_META.CORPORATE.POSITIVE_TEXT,
          onClick: confirmBooster,
          disabled: isOfferValid,
        },
        secondaryBtn: {
          text: 'Close',
          onClick: drawerCloseHandler,
        },
      }}
      onClose={drawerCloseHandler}
    >
      <div className="corporate-drawer">
        <FormElement
          className="margin0 marginT28"
          label={STRINGS.DRAWER.CORPORATE_OFFER_LABEL}
          errorMsg={isOfferValid ? invalidOfferMsg : ''}
        >
          <Input
            type="number"
            value={offerValue}
            onChange={offerValueChange}
            placeholder={STRINGS.DRAWER.OFFER_VALUE_PLACEHOLDER}
            max={meta.maxOfferValue}
            rightElement="%"
            error={isOfferValid}
            decimalPoints={0}
          />
        </FormElement>
        <p className="drawer-persuasion-info-banner">
          {STRINGS.DRAWER.CORPORATE_BANNER}
        </p>

        <Button
          onClick={handleShowMore}
          variant="text"
          size="legacy"
          className="!mt-[10px]"
        >
          {showMore ? (
            <>
              <Remove className="booster-advance-settings-icon" />
              {STRINGS.DRAWER.HIDE_ADVANCE_SETTINGS}
            </>
          ) : (
            <>
              <Add className="booster-advance-settings-icon" />
              {STRINGS.DRAWER.SHOW_ADVANCE_SETTINGS}
            </>
          )}
        </Button>
        {showMore && (
          <>
            <FormElement
              className="marginT28"
              label={STRINGS.DRAWER.APPLICABLE_STAY_START}
            >
              <Calendar
                type="input"
                {...stayStartCalendarProps}
                disabledBefore={(bookingStartDate || today) as Date}
                disabled={isEdit}
              />
            </FormElement>
            <FormElement
              className="marginT28"
              label={STRINGS.DRAWER.APPLICABLE_STAY_END}
            >
              <Calendar type="input" {...stayEndCalendarProps} />
            </FormElement>
            <FormElement
              className="marginT28"
              label={STRINGS.DRAWER.APPLICABLE_BOOKING_START}
            >
              <Calendar
                type="input"
                {...bookingStartCalendarProps}
                disabledAfter={(stayStartDate || maxDate) as Date}
                disabled={isEdit}
              />
            </FormElement>
            <FormElement
              className="marginT28"
              label={STRINGS.DRAWER.APPLICABLE_BOOKING_END}
            >
              <Calendar
                type="input"
                {...bookingEndCalendarProps}
                disabledBefore={(stayStartDate || today) as Date}
                disabledAfter={(stayEndDate || maxDate) as Date}
              />
            </FormElement>
            <FormElement
              className="marginT28"
              label={STRINGS.DRAWER.APPLY_FOR_LAST_MINUTE_OR_EARLY_BIRD_PRICES}
            >
              <Dropdown
                options={advancedPurchaseData.dropdownOption}
                value={advancedPurchaseData.dropdownData}
                onChange={changeAdvancePurchaseOption}
                disableClearable
                variant="default"
              />
            </FormElement>
            {hasAdvancePurchase && (
              <FormElement
                label={STRINGS.DRAWER.DAYS_BEFORE_CHECK_IN}
                className="marginT28"
              >
                <Input
                  type="number"
                  value={advancedPurchaseData.daysBeforeCheckIn}
                  onChange={changeAdvancePurchaseValue}
                  decimalPoints={0}
                  max={20}
                />
              </FormElement>
            )}

            <FormElement
              className="marginT28"
              label={STRINGS.DRAWER.CHOOSE_BLACKOUT_DATES}
            >
              <Calendar
                type="blackout"
                {...blackoutCalendarProps}
                disabledBefore={stayStartDate as Date}
                disabledAfter={stayEndDate as Date}
              />
            </FormElement>
          </>
        )}

        {showCancelModal && (
          <DiscardModal
            gtmActionType={gtmActionType}
            onDrawerClose={onDrawerClose}
            setShowCancelModal={setShowCancelModal}
          />
        )}

        {confirmModal && (
          <ConfirmBoosterModal
            data={confirmModal}
            onConfirm={onConfirmCallbackFunc}
            onClose={offerCancel}
          />
        )}
      </div>
    </Drawer>
  );
}
