import { doMutation, doQuery } from 'base/GraphQLClient';
import { getValue, showToast } from 'utilities/Utils';
import {
  getPersuasionContentVariables,
  getPersuasionsQuery,
  getRTBPendingBookingRequests,
  getSocialMediaDetailsQuery,
  getSocialMediaDetailsVariables,
  getSocialMediaMetadataQuery,
  getSocialMediaMetadataVariables,
  groupRatesPersuaionQuery,
  SUMMARY_API,
  createSocialMediaConnectionQuery,
  createSocialMediaConnectionVariables,
  updateSocialMediaConnectionVariables,
  updateSocialMediaConnectionQuery,
  getStaffInfoQuery,
  getStaffInfoVariable,
  GET_IP_USER_ELIGIBILTY_QUERY,
  GET_CONTENT_SCORE_QUERY,
} from './SharedGraphQueries';
import { isProd } from 'base/APIClient';

//RTB
const pendingRTBRequestsVariables = hotelCode => {
  const variables = {
    rawEndpointRequestData: {
      analyticsPersuasion: {
        modelType: 'modelType1',
        queryParams: {
          type: 'rtb',
          consumers: ['web'],
          size: 1,
          sub_type: 'pending',
          offset: 0,
          entity_id: hotelCode,
          filters: {
            sort: [{ key: 'data.expiry_time', order: 'asc' }],
            includes: [
              { key: 'expiry_date', value: { gte: 'now' } },
              { key: 'status', value: 'unresolved' },
            ],
          },
        },
      },
    },
  };
  return variables;
};

export function getPendingRTBRequestsData(hotelCode) {
  const query = getRTBPendingBookingRequests();
  const variables = pendingRTBRequestsVariables(hotelCode);
  return doQuery(query, variables, { useLoader: false });
}

export const getGroupRatesPersuasion = (hotelcode: string) => {
  const variables = {
    endpointRequests: {
      analyticsPersuasion: {
        modelType: 'modelType1',
        queryParams: {
          type: 'group_booking',
          size: 10,
          consumers: ['web'],
          sub_type: 'enrollment',
          offset: 0,
          entity_id: hotelcode,
        },
      },
    },
  };

  return doQuery(groupRatesPersuaionQuery, variables, {
    useLoader: false,
  }).then(res => {
    const rawData =
      res.data?.rawEndpoint.analyticsPersuasion?.modelType1.data.persuasions[0]
        ?.consumers.web ?? null;

    return rawData;
  });
};

const getModifiedPersuasions = persuasions => {
  const propertyData = [];
  const roomData = [];

  for (let i = 0; i < persuasions.length; i++) {
    const temp = persuasions[i].data;
    if (temp.category === 'room') {
      roomData.push(persuasions[i]);
    } else {
      propertyData.push(persuasions[i]);
    }
  }

  const combinedList = {
    propertyData,
    roomData,
  };

  return combinedList;
};

const getAAModifiedPersuasions = persuasions => {
  const basicInfoData = [];
  const rules = [];
  const spaces = {};

  for (let i = 0; i < persuasions.length; i++) {
    if (persuasions[i]?.consumers) {
      const temp = persuasions[i].data;
      if (temp.category === 'property') {
        basicInfoData.push(persuasions[i]);
      } else if (temp.category === 'rules') {
        rules.push(persuasions[i]);
      } else if (temp.category === 'space') {
        if (spaces[temp.space_type]) {
          spaces[temp.space_type].push(persuasions[i]);
        } else {
          spaces[temp.space_type] = [persuasions[i]];
        }
      }
    }
  }

  const spacesData = {
    'Basic Info': basicInfoData.length ? basicInfoData : null,
    ...spaces,
    'Property rules': rules.length ? rules : null,
  };
  if (!spacesData['Basic Info']) delete spacesData['Basic Info'];
  if (!spacesData['Property rules']) delete spacesData['Property rules'];

  return spacesData;
};

export function getContentScoreDetails(
  hotelCode,
  isWeb = false,
  { showLoader = true } = {},
) {
  const variables = getPersuasionContentVariables(hotelCode, isWeb);
  return doQuery(getPersuasionsQuery, variables, { useLoader: showLoader })
    .then(resp => {
      if (!resp.error) {
        const content =
          resp?.data?.altAccoActivityAndPerformanceDashboard?.getMissingContent;
        const totalContentScore =
          resp?.data?.rawEndpoint?.contentScoreResponses?.modelType1?.data
            ?.docs[0]?.totalContentScore || 0;
        let persuasions = {};

        if (content.status === 'success' && content?.data?.[hotelCode]) {
          const data = content?.data?.[hotelCode]?.persuasions || [];
          persuasions = isWeb
            ? getModifiedPersuasions(data)
            : getAAModifiedPersuasions(data);
        }
        return Promise.resolve({
          persuasions,
          totalContentScore: totalContentScore.toFixed(2),
        });
      }
      return Promise.resolve({ persuasions: {}, totalContentScore: 0 });
    })
    .catch(() => {
      return Promise.resolve({ persuasions: {}, totalContentScore: 0 });
    });
}

//External Cal Sync
export function summaryAPI(hotelCode) {
  const query = SUMMARY_API;

  const variables = {
    rawEndpointRequestData: {
      fetchCalSummary: {
        endPointId: 1,
        modelType: 'modelType1',
        body: {
          hotel_codes: [hotelCode],
        },
      },
    },
  };
  return doMutation(query, variables, { useLoader: true }).then(response => {
    const dataMapping = getValue(
      response,
      `data.rawEndpoint.fetchCalSummary.modelType1.data.${hotelCode}`,
    );
    const platformDataMapping =
      (dataMapping &&
        Object.keys(dataMapping).map(key => ({
          key,
          platformData: dataMapping[key]?.brands,
          brandStatus: dataMapping[key]?.stats,
        }))) ??
      [];
    return platformDataMapping;
  });
}

export function getSocialMediaMetadata(platform) {
  const variables = getSocialMediaMetadataVariables(platform);
  return new Promise((resolve, reject) => {
    doQuery(getSocialMediaMetadataQuery, variables, { useLoader: false })
      .then(response => {
        resolve(
          getValue(
            response,
            'data.rawEndpoint.socialMediaMetadata.modelType1.platforms',
          ) || [],
        );
      })
      .catch(reject);
  });
}
export function getSocialMediaDetails(mmtHotelId, platform) {
  const variables = getSocialMediaDetailsVariables(mmtHotelId, platform);
  return new Promise((resolve, reject) => {
    doQuery(getSocialMediaDetailsQuery, variables)
      .then(response => {
        const status = getValue(
          response,
          'data.rawEndpoint.socialMediaDetails.modelType1.status',
        );
        if (status === 'success') {
          resolve(
            getValue(
              response,
              'data.rawEndpoint.socialMediaDetails.modelType1.accesses.[0]',
            ) || null,
          );
        } else {
          resolve(null);
        }
      })
      .catch(reject);
  });
}

export function createSocialMediaConnection(params) {
  const variables = createSocialMediaConnectionVariables(params);
  return new Promise((resolve, reject) => {
    doMutation(createSocialMediaConnectionQuery, variables)
      .then(response => {
        const { status, errors } = getValue(
          response,
          'data.rawEndpoint.createSocialMediaConnection.modelType1',
        );

        if (status === 'success') {
          const mesage =
            'Your property\'s Instagram account is successfully connected now.';
          showToast(mesage, 'success');
          resolve({ status: true, mesage });
        } else {
          const mesage = errors?.[0]?.message || 'Something went wrong';
          showToast(mesage, 'error');
          resolve({ status: false, mesage });
        }
      })
      .catch(reject);
  });
}

export function updateSocialMediaConnection(params) {
  const variables = updateSocialMediaConnectionVariables(params);
  return new Promise((resolve, reject) => {
    doMutation(updateSocialMediaConnectionQuery, variables)
      .then(response => {
        const { status, errors } = getValue(
          response,
          'data.rawEndpoint.updateSocialMediaConnection.modelType1',
        );

        if (status === 'success') {
          showToast(
            'Your property\'s Instagram account is successfully disconnected now.',
            'success',
          );
          resolve(true);
          return;
        }
        const mesage = errors?.[0]?.message || 'Something went wrong';
        showToast(mesage, 'error');
        reject(new Error(mesage));
      })
      .catch(reject);
  });
}

export function getStaffInfo(hotelCode) {
  const variables = getStaffInfoVariable(hotelCode);
  return new Promise(resolve => {
    doQuery(getStaffInfoQuery, variables, { useLoader: false })
      .then(response => {
        const { contractbdo } =
          getValue(response, 'data.rawEndpoint.staffInfo.modelType1') || {};
        resolve({ contractbdo });
      })
      .catch(error => {
        console.error('Get staff info query', error);
      });
  });
}

export function fetchIPUserEligibility(userId, options = { useLoader: false }) {
  const query = GET_IP_USER_ELIGIBILTY_QUERY;

  const variables = {
    endpointRequestData: {
      fetchIpsUserEligibility: {
        rpcUserProfileRequest: {
          modelType: 'modelType1',
          endPointId: 99,
          body: {
            userId: userId,
            optionalParams: ['groups'],
          },
        },
      },
    },
  };

  return doQuery(query, variables, { ...options }).then(response => {
    const iPUserInfoData = getValue(response, 'data.fetchIpsUserEligibility');

    return iPUserInfoData || {};
  });
}

export function fetchContentScorePersuausion(
  actionCategory,
  contentLevel,
  mmt_id,
  options = { useLoader: false },
) {
  const query = GET_CONTENT_SCORE_QUERY;

  const variables = {
    AnalyticsV1Persuasions: {
      contentScorePersuasions: {
        modelType: 'modelType1',
        endPointId: 1,
        body: {
          pipeline: isProd ? 'persuasions_v2' : 'testing_persuasions_v2',
          include: {
            hotelId: mmt_id,
            type: 'contentscore',
            'data.action_category': actionCategory,
            status: 'unresolved',
            'data.content_level': contentLevel,
          },
          exclude: {
            status: 'expired',
          },
          size: 10,
          sort: {
            priority: 'asc',
          },
          group_by: ['data.action_category', 'data.content_level'],
          fields: {
            include: [
              'consumers.content_score_web.persuasion_title',
              'status',
              'content_level',
            ],
          },
        },
      },
    },
  };

  return doMutation(query, variables, { ...options }).then(response => {
    const contentScorePersuausionData = getValue(
      response,
      'data.contentScorePersuasions.contentScorePersuasions.modelType1.data',
    );

    return contentScorePersuausionData || {};
  });
}
