import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AppEvents } from '../../utilities/EventBus';
import { showMessage, navigateTo } from 'utilities/Utils';
import { useCurrentHotel, useLangStrings } from '../../utilities/CustomHooks';
import { routes } from '../routes';

import './QualityScoreBanner.scss';

import pushToGTM, { GTM_CATEGORIES } from 'utilities/gtm';

const routeCategoryMap = {
  [routes.inventory.all]: GTM_CATEGORIES.RATES_AND_INVENTORY,
  [routes.businessBooster.performance]: GTM_CATEGORIES.BOOST_PERFORMANCE,
  [routes.dashboard.base]: GTM_CATEGORIES.DASHBOARD,
  [routes.reviews.base]: GTM_CATEGORIES.RATINGS_AND_REVIEWS,
  [routes.property.contentScore]: GTM_CATEGORIES.PROPERTY,
  [routes.bookings.base]: GTM_CATEGORIES.BOOKINGS,
};

export default function QualityScoreBanner() {
  const { pathname } = useLocation();
  const [contextData, setContextData] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [showTaskAgain, setShowTaskAgain] = useState(false);
  const [, COMMON_STRINGS] = useLangStrings('QualityScore');
  const [hotelCode] = useCurrentHotel();

  useEffect(() => {
    const handler = data => {
      setContextData(data);
      setIsVisible(true);
      setShowTaskAgain(false);
    };
    AppEvents.on('qualityScoreBannerData', handler);
    return () => {
      AppEvents.off('qualityScoreBannerData', handler);
    };
  }, []);

  if (
    !isVisible ||
    pathname !== contextData.url ||
    hotelCode !== contextData.hotelCode
  ) {
    return null;
  }

  const markTaskDone = () => {
    contextData
      .taskDoneAPICall(routeCategoryMap[contextData.url])
      .then(res => {
        if (res.updateStatus === 'completed') {
          showMessage({ type: 'success', show: true, message: res.msg });
          setIsVisible(false);
        } else {
          setContextData({
            ...contextData,
            details: { ...contextData.details, mainText: res.msg },
          });
          setShowTaskAgain(true);
        }
      })
      .catch(contextData.errorHandler);
  };

  const navigateToQS = () => {
    navigateTo(routes.qualityScore.base);
  };

  const dismissBanner = () => {
    pushToGTM(
      routeCategoryMap[contextData.url],
      contextData.eventAction,
      `${contextData.eventLabel}_dismiss`,
    );
    setIsVisible(false);
  };

  return (
    <div className="qs-context-container sr-container">
      <div className="card">
        <span className="text-area">
          <span className="main-text">{contextData.details.mainText}</span>
          {contextData.details.subText && (
            <span className="sub-text">{contextData.details.subText}</span>
          )}
        </span>
        <button
          className="btn task-done-btn"
          onClick={showTaskAgain ? navigateToQS : markTaskDone}
        >
          {showTaskAgain
            ? COMMON_STRINGS.BUTTONS.SEE_TASK_AGAIN
            : COMMON_STRINGS.BUTTONS.MARK_TASK_DONE}
        </button>
        <span className="glyphicon glyphicon-remove" onClick={dismissBanner} />
      </div>
    </div>
  );
}
