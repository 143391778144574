import React, { useContext, useState, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Dashboard from './Dashboard';
import Footer from './footer/Footer';
import { HotelContext, AppContext } from '../../utilities/Context';
import GstnPopUp from './components/Gstn/GstnPopUp';
import { pushEventToGtm } from '../dashboard/utils/utils';
import {
  clearQueryParams,
  isNullOrUndefined,
  navigateTo,
} from 'utilities/Utils';
import { routes } from '../../app/routes';
import ResellerOnboardingPopup from './components/ResellerOnboardingPopup';
import { HOTEL_CLOUD } from './constants/Dashboard';
import { UserProfileType, HotelLegalEntityType } from 'app/hotel/constants';
import { hotelLegalEntityTypeMap } from 'constants';
import { OMNITURE_CATEGORIES, pushToOmniture } from 'utilities/gtm';

export const isUnverfiedUser = user => {
  const userProfile = user?.profile ?? {};
  const isAllVerified =
    userProfile.mobile_verified &&
    userProfile.email_verified &&
    userProfile.whatsapp_number_verified;
  return isAllVerified;
};

export default function DashboardMain() {
  const { currentHotel, resellerAgreement } = useContext(HotelContext);
  const { legalEntityType } = currentHotel;
  const appState = useContext(AppContext);
  const { isStaff, userProfileType } = appState;
  const hotel = {
    hotelId: currentHotel.hotelcode,
    currency: currentHotel.base_currency,
    voyagerId: currentHotel.voyagerid,
    hotelName: currentHotel.hotelname,
    property_category: currentHotel.property_category,
    is_eligible_for_loan: currentHotel.is_eligible_for_loan,
    country: currentHotel.country,
    is_chat_enabled: currentHotel.is_chat_enabled,
    chain_id: currentHotel.chain_id,
  };

  const { search, pathname } = useLocation();
  const isRedirectedFromHotelCloudLink = useMemo(
    () => clearQueryParams(pathname, search, 'session', true) === HOTEL_CLOUD,
    [search, pathname],
  );
  const isResellerEligible = Boolean(
    hotelLegalEntityTypeMap[legalEntityType] === HotelLegalEntityType.INGO &&
      Object.keys(resellerAgreement ?? {}).length &&
      userProfileType !== UserProfileType.HOTEL_TRAVEL,
  );
  const hideResellerPopup =
    !isRedirectedFromHotelCloudLink &&
    Boolean(
      JSON.parse(
        sessionStorage.getItem(`hide-reseller-popup-${currentHotel.hotelcode}`),
      ),
    );

  const { can_optin_for_gstn_invoice, gstn_assured } = currentHotel;
  const gtmCategory = 'web_dashboard';
  const gtmAction = 'gstin_enrollment_popup';
  const isGSTNClosedOnce =
    (!isNullOrUndefined(sessionStorage.getItem('gstnClosedOnce')) &&
      sessionStorage.getItem('gstnClosedOnce') === 'true') ||
    false;
  const [isGstnPopUpOpen, setIsGstnPopUpOpen] = useState(
    !isStaff &&
      !isGSTNClosedOnce &&
      can_optin_for_gstn_invoice &&
      !gstn_assured,
  );

  const onGstnClose = () => {
    setIsGstnPopUpOpen(false);
    sessionStorage.setItem('gstnClosedOnce', true);
    pushEventToGtm(gtmAction, 'maybe_later', undefined, gtmCategory);
  };
  const createCorporateBookingsTab = () => {
    setIsGstnPopUpOpen(false);
    sessionStorage.setItem('gstnClosedOnce', true);
    navigateTo(routes.property.corporate);
    pushEventToGtm(gtmAction, 'view_requirements', undefined, gtmCategory);
  };

  useEffect(() => pushToOmniture({ event: OMNITURE_CATEGORIES.PAGE_VIEW }), []);

  return (
    <>
      <Dashboard hotel={hotel} />
      {isGstnPopUpOpen && (
        <GstnPopUp
          createCorporateBookingsTab={createCorporateBookingsTab}
          onGstnClose={onGstnClose}
        />
      )}
      {!isStaff && !hideResellerPopup && isResellerEligible && (
        <ResellerOnboardingPopup
          key={hotel.hotelId}
          isRedirectedFromResellerLink={isRedirectedFromHotelCloudLink}
          resellerAgreement={resellerAgreement}
        />
      )}

      <Footer />
    </>
  );
}
