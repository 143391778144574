import { Typography } from 'connect-web-ui';
import React from 'react';
import { MAX_COUNT, MAX_DISPLAY_COUNT } from './constants';

interface CounterCircleProps {
  count: number;
}

const CounterCircle: React.FC<CounterCircleProps> = ({ count }) => {
  if (!count) return null;
  return (
    <div
      className={`w-fit h-[16px] px-[5px] rounded-[20px] ${
        count ? 'bg-color-redCounterCircle' : 'bg-color-primaryBlack'
      }`}
    >
      <Typography
        variant="subtitle1"
        className="!text-[white] flex items-center justify-center"
        fb
      >
        {count <= MAX_COUNT ? count : MAX_DISPLAY_COUNT}
      </Typography>
    </div>
  );
};

export default CounterCircle;
