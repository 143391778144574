export const configKeeperQuery = `
  query ConfigKeeperQuery($rawInput: ConfigKeeperRequest){
    configKeeperData(configKeeperInput: $rawInput) {
      status
      message
      data
    }
  }
`;

export const createServiceTicketQuery = `
  mutation CreateServiceTicket($rawInput: ServiceDeskTicketCreateRequest) {
    createServiceTicket(createServiceTicketRequest: $rawInput) {
      response {
        id
        category
        channel
        status
        primarytype
        secondarytype
        ternarytype
        comments
        createdat
        updatedat
      }
      errorDTO
      offset
      limit
    }
  }
`;

export const filterServiceTicketQuery = `
  mutation FilterServiceTicket($rawInput: ServiceDeskTicketFilterRequest) {
    filterServiceTicket(filterServiceTicketRequest: $rawInput) {
      errorDTO
      offset
      limit
      response {
        id
        category
        channel
        status
        primarytype
        secondarytype
        ternarytype
        comments
        otherinfo
        createdat
        updatedat
        closedat
      }
    }
  }
`;
