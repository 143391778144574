export const campaignEntityAgreement = `
query CampaignEntityAgreement($var: EndpointBusinessLogicData) {
  campaignEntityAgreement(businessLogicData: $var) {
    data {
      id
      status
      metadata
      related_json_data
      is_dsd_activated
      campaign_data {
        id
        metadata
        campaign_desc
        campaign_name
        cmp_long_desc
        video_url
        campaign_type
        agreement {
          agreement_id
          agreementurl
          sample_url
        }
      }
    }
  }
}
`;

export const getResellerAgreementQuery = `query HotelAgreementsRawResponse(
  $rawEndpointRequestData: RawEndpointsRequestData
) {
  rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
    hotelAgreementsV3 {
      modelType1 {
        msg
        success
        agreement_list {
          agreement_name
          agreement_url
          agreement_id
          mode
          status
          agreement_number
          sample_url
          agreement_display_name
          signed_agreement_url
          agreement_sign_time
        }
      }
    }
  }
}`;

export const remainingLegacyAndCalenderSyncData = `
query GetHotelData($inventoryGetRoomsRateplansRequestData: RawEndpointsRequestData, $analyticsHotelDemandRequestData: RawEndpointsRequestData, $analyticsClubbedPersuasionsRequestData: RawEndpointsRequestData, $allowMoHotelPaymentRequestData: RawEndpointsRequestData, $hotelQualityScoreRequestData: RawEndpointsRequestData){
  externalCalendarSyncGetRoomsRatePlansAny: rawEndpoint(rawEndpointRequestData: $inventoryGetRoomsRateplansRequestData){
    externalCalendarSyncGetRoomsRatePlansAny{
      modelType1{
       message
       data {
         roomDetails
         rateplanDetails
         effectiveRateFlag
       }
       success
      }
    }
  }  
  analyticsHotelDemandData: rawEndpoint(rawEndpointRequestData: $analyticsHotelDemandRequestData){
    analyticsHotelDemand{
      modelType1{
        message      
        data{
          hotel_code
        }
        status
      }
    }
  }
  analyticsClubbedPersuasionsData: rawEndpoint(rawEndpointRequestData: $analyticsClubbedPersuasionsRequestData){    
    analyticsClubbedPersuasions{      
      modelType1
      {        
        message        
        data        
        status
      }
    }
  }  
  allowMoHotelPaymentData: rawEndpoint(rawEndpointRequestData: $allowMoHotelPaymentRequestData){    
    allowMoHotelPayment{      
      modelType1{
        message        
        data{
          can_allow
        }        
        success
      }
    }
  }  
  hotelQualityScoreData: rawEndpoint(rawEndpointRequestData: $hotelQualityScoreRequestData){
    hotelQualityScore{
      modelType1{
        message
        status
        qsInfo{
          tasksPartiallyCompleted
          qsPopup{
            header
            levelInfo
            ctaText
            description
            ctaLink
          }
          tasksCompleted
        }
      }
    }
  }
}
`;

export const hotelAgrementAndTaxData = `
query GetHotelData($hotelMetaDataRequestData: RawEndpointsRequestData, $hotelInvoicingRequestData: RawEndpointsRequestData, $businessLogicData: EndpointBusinessLogicData){
  campaignEntityAgreement(businessLogicData: $businessLogicData){
    data{
      id
      status
      metadata
      related_json_data
      is_dsd_activated
      campaign_data{
        id
        metadata
        campaign_desc
        campaign_name
        cmp_long_desc
        video_url
        campaign_type
        agreement{
          agreement_id
          agreementurl
          sample_url
        }
      }
    }
  }
  hotelMetaData: rawEndpoint(rawEndpointRequestData: $hotelMetaDataRequestData){
    hotelMetadataV2{
      modelType1{
        activity_events{
          is_active
          name
        }
        hotelname
        hotelcode
        isactive
        city
        voyagerid
        displayname
        mmt_id
        description
        createdon
        modifiedon
        country
        is_chat_enabled
        is_gostay
        is_advantage
        is_offline_booking_allowed
        images
        name_change_allowed
        is_child_allowed
        new_child_policy_status
        hotel_category
        is_eligible_for_loan
        hotel_type
        hotel_suspected
        property_type
        enable_orc_access
        starrating
        locality
        is_express_checkin
        city_code
        nav_state_code
        locus_city_code
        is_new_linkage_enabled
        blackDnd
        minBlackBenefit
        blackEnrolledOn
        blackMinDuration
        minBlackDifference
        excludeUpgrade
        property_category
        can_optin_for_gstn_invoice
        gstn_assured
        is_price_recommendation_enabled
        pricing_model
        max_infant_age
        max_child_age
        dayuse_price_percentages
        max_adolescent_age
        checkintime
        is_vcc
        hostel_type
        latitude
        longitude
        address
        zipcode
        loggedin_promo_amount
        is_instagram_enabled
        is_sustainability_enabled
        is_onboarded_to_new_cp
        source_config
        base_currency
        vendor
        state
        ec_counts{
          total_count
          rejected
          approved
          pending
          doc_uploaded
        }
        is_static_optin
        is_copy_optin
        is_compt_analysis_enabled
        source_partner
        source_hotelcode
        add_extraguest_to_declared_flag
        is_rtb_enabled
        show_rtb_settings
        preapproved_rtb_segments
        payathotelflag
        termsandconditionsflag
        is_direct_connect
        is_dayuse_rate_linked
        is_onboarding
        chain_id
        chain_name
        physically_verified_flag
        email
        is_prebook_chat_enabled,
        timezone,
        timezoneOffset,
        is_user_hard_blocked
        show_star_rating
        show_sell_property_as
        is_ingo_express
        is_multi_inventory
        is_call_center_enabled
        hotel_offline_booking_enabled
        offline_booking_blocked_from_admin
        current_property_view
        current_property_view_web
        is_host_detail_missing
        is_host_missing
        has_enrolled_in_group_booking
        is_eligible_for_group_booking
        group_level_commission
        hostweb_migration
        dayuse_booking_eligible
        dayuse_multislot_enabled
        hotel_level_commission
        legal_entity_type
        restrict_max_child_age
        duplicated_from
        duplicated_from_property_name
        duplicated_to
        duplicated_to_property_name
      }
    }
  }
  hotelInvoicingData: rawEndpoint(rawEndpointRequestData: $hotelInvoicingRequestData){
    getHotelInvoicingData{
      modelType1{
        key
        message
        details{
          is_eligible
          next_invoice_id
          pannumber
          advanced_receipt_number
          is_enrolled
          hotel_address
          showpopup
          hotel_name
          hotel_address_line_2
        }
        success
      }
    }
  }
}
`;

export const fetchCompsetDataQuery = `query AnalyticsFetchCompset($rawEndpointRequestData: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
    analyticsFetchCompset {
      modelType1 {
       message
       data {
        is_chain
        property_category
        hotel_code
        address
        city
        mmt_id
        hotel_name
        image_url
      }
      status
      status_code
    }
  }
}
}`;

export const GET_ALL_SALES_QUERY = `
query RPCGetCampaignOfferDetails($endpointRequestData: EndpointRequestData)
{
    rpcGetCampaignOfferDetails(endpointRequestData: $endpointRequestData)
    {
        data
        {
            campaignHotelMappings
            {
                campaignMappingId
                participationStatus
                hotelId
                campaign
                {
                    campaignId
                    campaignName
                    campaignStatus
                    campaignEndTime
                    campaignLogo
                    campaignShortInlet
                    campaignMidInlet
                    campaignLongInlet
                }
            }
        }
        errorDetail
        {
            errorCode
            errorMessage
            errorType  
            displayMessage
            httpStatusCode    
        }
        message
    }
}`;

export const getDecideHotelAgreementPopupQuery = `query DecideHotelAgreementPopup($endpointRequests: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $endpointRequests) {
    decideHotelAgreementPopup {
      modelType1 {
        msg
        agreement_list
        showpopup
        insurmountable
      }
    }
  }
}`;

export const getRoomsRatePlansDetailsQuery = `query GetRoomsRatePlansDetails($inventoryGetRoomsRateplansRequestData: RawEndpointsRequestData) {
  externalCalendarSyncGetRoomsRatePlansAny: rawEndpoint(rawEndpointRequestData: $inventoryGetRoomsRateplansRequestData) {
    externalCalendarSyncGetRoomsRatePlansAny {
      modelType1 {
        message
        data {
          roomDetails
          rateplanDetails
          effectiveRateFlag
        }
        success
      }
    }
  }
}`;

export const GET_FEATURE_FLAGS = `mutation FeatureFlags($CommonFeatureFlags: RawEndpointsRequestData) { 
  featureFlags: rawEndpoint(    
    endpointRequestData: $CommonFeatureFlags\n  ) {    
      featureFlags {     
        modelType1 {        
          data        
          error       
        }   
      } 
    } 
  }`;
