import { routes } from 'app/routes';
import { SPACE_TYPE } from 'shared_logic/spaces/spaces.types';
import { capitalizeFirstLetter } from 'utilities/Utils';

export const REORDER_ENUM = {
  TOP: 'top',
  BOTTOM: 'bottom',
  FEATURED: 'featured',
};

export const spaceEnum = {
  SPACE: 'space',
  HOTEL: 'hotel',
  ROOM: 'room',
};

export const uploadConfigEnum = {
  unassigned: 'unassigned',
  computer: 'computer',
};

export const tabsEnum = {
  ALL: 'all',
  COLLECTIONS: 'collections',
};

export const statusKeys = {
  rejected: 'rejected',
  pending: 'pending',
  approved: 'approved',
};

export const statusMap = {
  [statusKeys.rejected]: 'Rejected',
  [statusKeys.pending]: 'Under Review',
};

export const MEDIA_TYPE = {
  PHOTOS: 'image',
  VIDEOS: 'video',
};

export const MEDIA_DETAILS = {
  TAGS: 'Tags',
  CAPTION: 'Caption',
  COLLECTION: 'Collection',
};

export const MEDIA_REORDER_OPTIONS = [
  { label: 'Top', value: REORDER_ENUM.TOP },
  { label: 'Bottom', value: REORDER_ENUM.BOTTOM },
  { label: 'Featured', value: REORDER_ENUM.FEATURED },
];

export const breadCrumbArr = (spaceId, spaceName, spaceType) => {
  return spaceId
    ? [
      {
        label: 'Listings',
        url: routes.listing.base,
      },
      {
        label: 'Rooms & Spaces',
        url: routes.listing.spaces,
      },
      {
        label: spaceType === 'kitchen' ? 'Kitchen' : spaceName,
        url:
            spaceType === 'kitchen'
              ? `${routes.listing.spaces}?tab=kitchen`
              : `${routes.listing.spaces}?id=${spaceId}`,
      },
    ]
    : [
      {
        label: 'Listings',
        url: routes.listing.base,
      },
      {
        label: 'Photos & Videos',
        url: routes.listing.photosAndVideos.base,
      },
    ];
};

export const breadCrumPossibleTabs = (spaceName, isKitchen) => [
  {
    label: isKitchen
      ? `${capitalizeFirstLetter(SPACE_TYPE.KITCHEN)} - Collections`
      : spaceName
        ? `${spaceName} - Collections`
        : 'Collections',
  },
];

export const acceptedFilesToUpload = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
  'video/mp4',
  'video/webm',
];
export const acceptedFilesToUploadForMenu = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
];

export const MAX_MEDIA_UPLOAD_COUNT = 20;

export const addConfig = STRINGS => [
  {
    label: STRINGS.ASSIGN_FROM_UPLOADED,
    value: uploadConfigEnum.unassigned,
    gtmEventLabel: 'assign_from_uploaded',
  },
  {
    label: STRINGS.SELECT_FROM_COMPUTER,
    value: uploadConfigEnum.computer,
    gtmEventLabel: 'select_from_computer',
  },
  {
    label: '',
    value: uploadConfigEnum.computer,
    gtmEventLabel: 'Add Photos',
  },
];

export const tabsConfig = STRINGS => [
  {
    label: STRINGS.ALL_MEDIA,
    value: tabsEnum.ALL,
  },
  {
    label: STRINGS.COLLECTIONS,
    value: tabsEnum.COLLECTIONS,
  },
];

export const MEDIA_EVENT_ACTION = 'listing_info|photos_and_videos';
export const MEDIA_COLLECTIONS_EVENT_ACTION = 'photos_and_videos|collections';
export const MEDIA_ADD_MEDIA_EVENT_ACTION = 'photos_and_videos|add_media';
export const MEDIA_EDIT_MEDIA_EVENT_ACTION =
  'listing_info|photos_and_videos|edit_media';

export const IMAGE_UPLOAD_EVENT_ACTION = 'image_upload';
