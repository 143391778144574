export const percentageLimit = {
  minLimit: 0,
  maxLimit: 90,
};

// new promotion types supported
export const newPromotionTypes = {
  DATE: 'date',
  DAY: 'day',
  FND: 'fnd',
  EBD: 'earlybird',
  LMD: 'lastminute',
  LOS: 'los',
  CUSTOM: 'customised',
} as const;

export const dayOptions = [
  { value: 0, content: 'M' },
  { value: 1, content: 'T' },
  { value: 2, content: 'W' },
  { value: 3, content: 'T' },
  { value: 4, content: 'F' },
  { value: 5, content: 'S' },
  { value: 6, content: 'S' },
];
export const defaultPromotionName = {
  LMR: 'LMR-',
  LMD: 'Last Minute',
  FND: 'LOS-FRN-',
  EBD: 'Early Bird',
  DATE: 'Basic-',
  DAY: 'DayDeal-',
  LOS: 'LOS-',
};

export const gtmActionPromotionsMap = {
  [newPromotionTypes.DATE]: 'basic_promotion',
  [newPromotionTypes.DAY]: 'day_promotion',
  [newPromotionTypes.FND]: 'free_night_promotion',
  [newPromotionTypes.EBD]: 'earlybird_promotion',
  [newPromotionTypes.LMD]: 'lastminutedeal',
  [newPromotionTypes.LOS]: 'long_stay_promotion',
  [newPromotionTypes.CUSTOM]: 'custom_promotion',
};

export const defaultAlluserDiscount = 10;
export const defaultLoggedInDiscount = 5;
export const defaultMaxLosValue = 200;
export const defaultMinLosValue = 1;
export const defaultEarlyBirdMin = 0;
export const defaultEarlyBirdMax = 550;
export const earlyBirdDDMinVal = 2;
export const earlyBirdDDMaxVal = 90;
export const minLosDDVal = 1;
export const maxLosDDVal = 30;
export const noEndDate = new Date('2099-12-31');
export const dateAPIFormat = 'YYYY-MM-DD';
export const dateTimeFormat = 'T00:00:00.000Z';
export const eventCategory = 'web_promotions';
export const paginationSize = 5;
export const defaultEndDateString = '2099-12-31';
export const days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
export const LMDOptions = [
  { label: 'Same day check-in', value: 0 },
  { label: 'Within 24 Hours', value: 1 },
  { label: 'Within 48 Hours', value: 2 },
  { label: 'Within 3 days', value: 3 },
  { label: 'Within 4 days', value: 4 },
  { label: 'Within 5 days', value: 5 },
  { label: 'Within 6 days', value: 6 },
  { label: 'Within 7 days', value: 7 },
];

export const yesNoOpts = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

export const bundleOptions = [
  { label: 'B2C', value: 'b2c' },
  { label: 'Bundled Rates', value: 'bundled' },
];

export const checkInOpts = [
  { label: 'Same Day', value: 'sameday' },
  { label: 'Next Day', value: 'nextday' },
];
export const nightOptions = [
  { label: 'Last Night', value: 'last-x-nights' },
  { label: 'Any Night', value: 'any-x-nights' },
  { label: 'Cheapest Night', value: 'cheapest-x-nights' },
];

export const offerTypes = {
  percentage: 'percentage',
  fixed: 'flatdprpn',
  flatprice: 'flatpprpn',
};

export const getOfferTypeOptions = isEditFlowOnly => {
  const options = [
    {
      label: 'Percentage',
      value: offerTypes.percentage,
    },
    {
      label: 'Fixed',
      value: offerTypes.fixed,
    },
  ];

  if (isEditFlowOnly) {
    options.push({
      label: 'Flat Price',
      value: offerTypes.flatprice,
    });
  }
  return options;
};

export const datesApplicabilityOptions = [
  {
    label: 'Stay Date',
    value: 'stay_date',
  },
  {
    label: 'Booking & Stay Date',
    value: 'bkg_stay_date',
  },
];

export const weekDaysToNumberMap = {
  mon: 0,
  tue: 1,
  wed: 2,
  thu: 3,
  fri: 4,
  sat: 5,
  sun: 6,
};

export function getPromotionDurationOption(days_) {
  const option = { label: null, value: null };
  if (days_ === 1) {
    option.label = `${days_} Day`;
  } else {
    option.label = `${days_} Days`;
  }
  option.value = days_;
  return option;
}

export function getPromotionDuration(min, max) {
  const options = [];
  if (min === 1) {
    options.push(getPromotionDurationOption(1));
    min++;
  }
  for (let i = min; i <= max; i++) {
    options.push(getPromotionDurationOption(i));
  }
  return options;
}

export const EarlyBirdOpts = getPromotionDuration(
  earlyBirdDDMinVal,
  earlyBirdDDMaxVal,
);
export const LOSOpts = getPromotionDuration(minLosDDVal, maxLosDDVal);

export const optionsTypeMap = {
  [newPromotionTypes.LMD]: LMDOptions,
  [newPromotionTypes.EBD]: EarlyBirdOpts,
  [newPromotionTypes.LOS]: LOSOpts,
};

export function getSuggestions(type, value) {
  return optionsTypeMap[type].find(item => item.value === value);
}

export const LMDSuggestions = [
  {
    label: 'Book within 48 hours for 10% Off',
    suggestionDays: getSuggestions(newPromotionTypes.LMD, 2),
    allDis: 10,
  },
  {
    label: 'Book within 24 hours for 20% Off',
    suggestionDays: getSuggestions(newPromotionTypes.LMD, 1),
    allDis: 20,
  },
  {
    label: 'Book same day for 30% Off',
    suggestionDays: getSuggestions(newPromotionTypes.LMD, 0),
    allDis: 30,
  },
];

export const LosSuggestions = [
  {
    label: 'Stay for 7 Days for 20% Off',
    suggestionDays: getSuggestions(newPromotionTypes.LOS, 7),
    allDis: 20,
  },
  {
    label: 'Stay for 14 Days for 30% Off',
    suggestionDays: getSuggestions(newPromotionTypes.LOS, 14),
    allDis: 30,
  },
  {
    label: 'Stay for 21 Days for 40% Off',
    suggestionDays: getSuggestions(newPromotionTypes.LOS, 21),
    allDis: 40,
  },
];

export const earlyBirdSuggestions = [
  {
    label: '10% Off on 7 Day advance purchase ',
    suggestionDays: getSuggestions(newPromotionTypes.EBD, 7),
    allDis: 10,
  },
  {
    label: '15% Off on 15 Day advance purchase',
    suggestionDays: getSuggestions(newPromotionTypes.EBD, 15),
    allDis: 15,
  },
  {
    label: '20% Off on 30 Day advance purchase',
    suggestionDays: getSuggestions(newPromotionTypes.EBD, 30),
    allDis: 20,
  },
];

// Promotion revamp

export const earlyBirdBpOptions = STRINGS => [
  {
    label: STRINGS.DAYS_OR_MORE_5,
    value: '5',
  },
  {
    label: STRINGS.DAYS_OR_MORE_7,
    value: '7',
  },
  {
    label: STRINGS.DAYS_OR_MORE_14,
    value: '14',
  },
  {
    label: STRINGS.DAYS_OR_MORE_21,
    value: '21',
  },
  {
    label: STRINGS.DAYS_OR_MORE_30,
    value: '30',
  },
];

export const lastminuteOptions = (isIHOrAltAcco, STRINGS) => {
  const options = [
    {
      label: STRINGS.SAME_DAY_CHECKIN,
      value: '0',
    },
    {
      label: STRINGS.UPTO_1_DAY_CHECKIN,
      value: '1',
    },
    {
      label: STRINGS.UPTO_2_DAY_CHECKIN,
      value: '2',
    },
  ];

  if (isIHOrAltAcco) {
    options.push({ label: STRINGS.UPTO_7_DAY_CHECKIN, value: '7' });
  }
  return options;
};

export const CHECKIN = 'checkin';
export const BOOKING_AND_CHECKING = 'booking&checkin';
export const BOOKING = 'booking';

export const promotionBasedOnOptions = (promotionType, STRINGS) => {
  if (promotionType === 'earlybird') {
    return [
      { label: STRINGS.CHECKIN, value: CHECKIN },
      { label: STRINGS.BOOKINGCHECKIN, value: BOOKING_AND_CHECKING },
    ];
  }

  return [
    { label: STRINGS.BOOKING, value: BOOKING },
    { label: STRINGS.BOOKINGCHECKIN, value: BOOKING_AND_CHECKING },
  ];
};

export const EARLY_BIRD_BOOKABLE_PERIOD = '5';
export const BOOKABLE_PERIOD = '2';

export const OFFER_SET_VALUE = 3;
export const LOS_OFFER_SET_VALUE = 5;

export const DefaultCheckInWeekDay = ['0', '1', '2', '3', '4', '5', '6'];

export const DEFAULT_FIRST_BLACKOUTDATE_IDX = 0;
export const DEFAULT_LAST_BLACKOUTDATE_IDX = 2;
