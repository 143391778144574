import React, { useState } from 'react';
import { DocumentItemStyle } from './AgreementsDrawer';
import { getFormattedDate } from '../../../../utilities/DateUtils';
import { useLangStrings } from '../../../../utilities/CustomHooks';
import { pushGTMEvent, GTM_ACTIONS } from '../utils';
import { Drawer, Tabs } from 'connect-web-ui';

const HistoryItem = props => {
  const { title, username, modifiedon, render } = props;
  const [STRINGS] = useLangStrings('Property');
  return (
    <div style={{ fontSize: '14px', ...DocumentItemStyle }}>
      <div className="d-flex sr-justify-space">
        <div>
          <p
            style={{
              color: '#212121',
              fontWeight: 'bold',

              marginBottom: '0',
            }}
          >
            {title}
          </p>
          {username && username.length > 0 && (
            <p>
              {STRINGS.FINANCE.AGREEMENTS_AND_DOCS.HISTORY_SUBMITTED_BY}:{' '}
              {username}
            </p>
          )}
        </div>
        <p style={{ color: '#212121', fontSize: '12px' }}>
          {getFormattedDate(modifiedon)}
        </p>
      </div>
      {render()}
    </div>
  );
};

const tabList = [
  { label: 'Goibibo', value: 'goibibo' },
  { label: 'MakeMyTrip', value: 'makemytrip' },
];

function HistoryDrawer(props) {
  const { onClose, isOpen, history } = props;
  const [activeTab, setActiveTab] = useState(tabList[0].value);
  const [STRINGS] = useLangStrings('Property');

  const { tan_history, pan_history, gst_history, bank_account_history } =
    history;

  const bankHistory = [
    ...(bank_account_history?.[activeTab] || []),
    ...(bank_account_history?.both || []),
  ];

  const panHistory = [
    ...(pan_history?.[activeTab] || []),
    ...(pan_history?.both || []),
  ];

  const tanHistory = [
    ...(tan_history?.[activeTab] || []),
    ...(tan_history?.both || []),
  ];

  return (
    <div>
      <Drawer
        isOpen={isOpen}
        header={{
          title: STRINGS.FINANCE.AGREEMENTS_AND_DOCS.HISTORY_OF_UPDATES,
          hideCross: true,
        }}
        footer={{
          secondaryBtn: {
            text: 'Close',
            onClick: onClose,
          },
        }}
        onClose={onClose}
      >
        <Tabs
          tabs={tabList}
          activeTab={activeTab}
          onChange={val => {
            pushGTMEvent({
              eventAction: GTM_ACTIONS.update_history,
              eventLabel: val,
            });
            setActiveTab(val);
          }}
        />

        {bankHistory.map(item => (
          <HistoryItem
            title={STRINGS.FINANCE.AGREEMENTS_AND_DOCS.HISTORY_BANK}
            username={item.user}
            modifiedon={item.modifiedon}
            render={() => (
              <React.Fragment>
                <p>
                  {STRINGS.FINANCE.AGREEMENTS_AND_DOCS.HISTORY_ACC_NUM}:{' '}
                  {item.account_number}
                </p>
                <p>
                  {STRINGS.FINANCE.AGREEMENTS_AND_DOCS.HISTORY_STATUS}:{' '}
                  {item.status}
                </p>
                {item.reject_reason && item.reject_reason.length > 0 && (
                  <p>{item.reject_reason}</p>
                )}
                {/* <p>Approved By: {item.approved_by}</p> */}
              </React.Fragment>
            )}
          />
        ))}

        {tanHistory.map(item => (
          <HistoryItem
            title={STRINGS.FINANCE.AGREEMENTS_AND_DOCS.HISTORY_TAN}
            username={item.user}
            modifiedon={item.createdon}
            render={() => (
              <React.Fragment>
                <p>
                  {STRINGS.FINANCE.AGREEMENTS_AND_DOCS.HISTORY_TAN}:{' '}
                  {item.tan_number}
                </p>
              </React.Fragment>
            )}
          />
        ))}

        {panHistory.map(item => (
          <HistoryItem
            title={STRINGS.FINANCE.AGREEMENTS_AND_DOCS.HISTORY_PAN}
            username={item.user}
            modifiedon={item.createdon}
            render={() => (
              <React.Fragment>
                <p>
                  {STRINGS.FINANCE.AGREEMENTS_AND_DOCS.HISTORY_PAN}:{' '}
                  {item.pan_number}
                </p>
                {/* <p>Approved By: {item.approved_by}</p> */}
              </React.Fragment>
            )}
          />
        ))}

        {gst_history && Object.keys(gst_history.gstn_details).length > 0 && (
          <HistoryItem
            title={STRINGS.FINANCE.AGREEMENTS_AND_DOCS.HISTORY_GSTN}
            username={gst_history.gstn_details.user}
            modifiedon={gst_history.gstn_details.createdon}
            render={() => (
              <React.Fragment>
                <p>
                  {STRINGS.FINANCE.AGREEMENTS_AND_DOCS.HISTORY_GSTN}:{' '}
                  {gst_history.gstn_details.gstn}
                </p>
              </React.Fragment>
            )}
          />
        )}
      </Drawer>
    </div>
  );
}

export default HistoryDrawer;
