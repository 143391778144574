export const getMediaDataQuery = `query RawResponse($rawEndpointsRequestData : RawEndpointsRequestData) {
    rawEndpoint(
        rawEndpointRequestData: $rawEndpointsRequestData
    ) {
      fetchMediaDetails {
        modelType1 {
          count
          success
          message
          total
          data {
            status
            description
            image
            is_active
            image_thumb
            video_thumbnail
            width
            height
            video_url
            caption
            id
            image_scores{
              final_score
            }
            tags
            media_type
            space_ids
          }
        }
      }
    }
  }`;

export const FETCH_MEDIA_QUERY = `
  query RawResponse($rawEndpointsRequestData : RawEndpointsRequestData) {
    rawEndpoint(
        rawEndpointRequestData: $rawEndpointsRequestData
    ) {
      mediaTagsDetails {
        modelType1 {
          data {
            images
            videos
          }
          success
        }
      }
    }
  }
  `;

export const UPDATE_MEDIA_QUERY = `
mutation RawResponse($rawEndpointsRequestData : RawEndpointsRequestData) {
  rawEndpoint(
      endpointRequestData: $rawEndpointsRequestData
  ) {
    mediaUpdateDetails {
      modelType1 {
        success
        message
      }
    }
  }
}
`;

export const BULK_UPDATE_MEDIA_QUERY = `
mutation BulkMediaMappingUpdate($rawEndpointsRequestData: RawEndpointsRequestData) {
  rawEndpoint(endpointRequestData: $rawEndpointsRequestData) {
    bulkMediaMappingUpdate {
      modelType1 {
        data
        success
        message
      }
    }
  }
}
`;

export const UPDATE_MEDIA_ORDER_QUERY = `
mutation RawResponse($rawEndpointsRequestData : RawEndpointsRequestData) {
  rawEndpoint(
      endpointRequestData: $rawEndpointsRequestData
  ) {
    mediaOrderUpdateDetails {
      modelType1 {
        success
        message
      }
    }
  }
}
`;

export const UPLOAD_IMAGE_QUERY = `
mutation RawResponse($rawEndpointsRequestData : RawEndpointsRequestData) {
  rawEndpoint(
      endpointRequestData: $rawEndpointsRequestData
  ) {
    hostAppImageCreate {
      modelType1 {
        data {
          id
          status
          image
          image_rectangle
          image_thumb
          caption
          image_square
          tags
          url
        }
        success
        message
      }
    }
  }
}
`;

export const UPLOAD_VIDEO_QUERY = `
mutation RawResponse($rawEndpointsRequestData : RawEndpointsRequestData) {
  rawEndpoint(
      endpointRequestData: $rawEndpointsRequestData
  ) {
    hostAppCreateVideo {
      modelType1 {
        data {
          id
          status
          caption
          tags
          video_url
          video_thumbnail
        }
        success
      }
    }
  }
}
`;
