import styled from 'styled-components';
import {
  colors,
  typography,
} from '../../../../style/zeplin-token-styledComponents';

export const HeaderWrapper = styled.header<{ issticky: boolean }>`
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  height: 72px;
  background-color: ${({ issticky }) =>
    issticky ? colors.white : 'transparent'};
  transition: all 0.3s ease-in-out;
  box-shadow: ${({ issticky }) =>
    issticky ? '0 1px 7px 0 rgb(0 0 0 / 40%)' : ''};

  > div {
    width: 100%;
    max-width: 1280px;
  }
`;

export const HeaderLogo = styled.a`
  cursor: pointer;
  display: flex;
`;

export const HeaderInner = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 1px 0px;
`;

export const HeaderRightContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderNavBar = styled.nav<{ issticky: boolean }>`
  padding-right: 16px;

  ul {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  li {
    cursor: pointer;
    ${typography.subTextBold};
    color: ${({ issticky }) =>
    issticky ? colors.grayMediumEmphasis : colors.white};
    padding: 10px 14px;
    border-radius: 8px;
    transition: all 0.3s linear;
    outline: 0;
    border: 1px transparent solid;
    &:hover {
      ${({ issticky }) =>
    issticky
      ? `border: 1px ${colors.grayHighEmphasis} solid; background: ${colors.grayScreen}`
      : `box-shadow:  0px 1px 2px 0px rgba(0, 0, 0, 0.05);
      background: rgba(255, 255, 255, 0.2);}`}
    }
  }
`;

export const HeaderBtns = styled.div`
  display: flex;
  gap: 16px;
`;

export const HeaderBtnSignIn = styled.button`
  padding: 10px 14px;
  border-radius: 8px;
  color: ${colors.white};
  background: ${colors.orange};
  outline: 0;
  border: 0;
  ${typography.subTextBold};
  transition: all 0.3s linear;
  &:hover {
    background: #b63f15;
  }
`;

export const HeaderBtnDownload = styled.button<{ issticky: boolean }>`
  padding: 3px 12px 4px 12px;
  border-radius: 8px;
  transition: all 0.3s linear;
  background: ${({ issticky }) =>
    issticky ? colors.white : 'rgba(255, 255, 255, 0.2)'};
  outline: 0;
  border: ${({ issticky }) =>
    issticky
      ? `1px ${colors.grayMediumEmphasis} solid`
      : '1px  rgba(255, 255, 255, 0) solid'};
  display: flex;
  align-items: flex-start;

  &:hover {
    background: ${({ issticky }) =>
    issticky ? colors.grayScreen : 'rgba(255, 255, 255, 0.4)'};
  }

  span {
    padding: 10px 0px 4px 5px;
    ${typography.subTextBold};
    color: ${({ issticky }) =>
    issticky ? colors.grayMediumEmphasis : colors.white};
  }
`;
