import axios, { deleteAuthTransform, apiWrapper } from '../../base/APIClient';
import { APP_MAIN } from '../../base/Url';
import {
  ClearTaxInfo,
  RoomRateplan,
} from '../../interfaces/HotelContextInterface';
import Sentry from '../../utilities/Sentry';
import { IHotelMetadata, IHotelMetadataAPI } from './interfaces';
import { RESELLER_AGREEMENT_ID, SOURCE_PARTNER } from '../../constants';
import { isNullOrUndefined, pick } from 'utilities/Utils';
import { HOTEL_TRAVEL } from 'modules/inventory/constants';

export const getLangStrings = (lang, { showLoader } = { showLoader: true }) => {
  const { STRINGS } = APP_MAIN;
  return axios.get(STRINGS(lang), {
    useLoader: showLoader,
    transformRequest: deleteAuthTransform,
  });
};

//Main App APIs
export const getCurrentHotel = (
  hotelCode: string,
  datasource = '',
  channelManagerInfo = { channelManagerName: null, channelManagerId: null },
) => {
  return new Promise<IHotelMetadata>((resolve, reject) => {
    const { HOTEL_DETAIL } = APP_MAIN;
    const url = HOTEL_DETAIL(hotelCode);
    apiWrapper(axios.get<IHotelMetadataAPI>(url, { useLoader: true }))
      .then((response: IHotelMetadata) => {
        if (isNullOrUndefined(response.property_category)) {
          Sentry.logError(
            new Error(
              `Property Category is missing for hotelcode ${hotelCode}`,
            ),
          );
        }

        const showListings = response.current_property_view_web === 'HostWeb';
        const source = showListings ? 'host_web_view' : 'ingo_legacy';
        const { channelManagerName, channelManagerId } = channelManagerInfo;
        window.hotelGA = {
          isActive: response.isactive ? 1 : 0,
          hotelCode: response.hotelcode,
          city: response.city,
          country: response.country,
          propertyCategory: response.property_category,
          hotelType: response.hotel_type,
          source,
        };

        window.adobeHotelGA = {
          onboardingId: datasource,
          lob:
            response.country.toLowerCase() === 'india'
              ? 'domestic'
              : 'international',
          hotelCode: response.hotelcode,
          hotelActiveStatus: response.isactive ? 'active' : 'non-active',
          starRating: response.starrating,
          chainType: response.chain_id ? 'chain' : 'non-chain',
          channelManager: channelManagerName
            ? `${channelManagerId}|${channelManagerName}`
            : 'NA',
          propertyBadge: response.is_gostay
            ? 'go-stay'
            : response.is_advantage
              ? 'mmt-select'
              : 'NA',
          hotelType: response.hotel_type || 'NA',
          propertyType: response.property_category || 'NA',
          propertyTypeMeta: response.property_type || 'NA',
          source,
        };
        resolve({ ...response, showListings });
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const updateHotelMeta = (
  hotelCode: string,
  payload: { [key: string]: boolean },
) => {
  const { V2_HOTEL } = APP_MAIN;
  const url = V2_HOTEL(hotelCode);
  return apiWrapper(axios.put(url, payload, { useLoader: true }));
};

export const getClearTaxDetails = (hotelCode: string) => {
  const { CT_DETAILS } = APP_MAIN;
  return new Promise<Partial<ClearTaxInfo>>(resolve => {
    apiWrapper(axios.get(CT_DETAILS(hotelCode)))
      .then((res: { details: object }) => {
        resolve(res.details);
      })
      .catch(() => {
        resolve({});
      });
  });
};

export const isGroupRateplan = rp =>
  rp.valid_contract_types?.length === 1 && rp.valid_contract_types[0] === 'grp';

export const roomRateMapObjects = (
  roomDetails: RoomRateplan['roomDetails'],
  rateplanDetails: RoomRateplan['rateplanDetails'],
) => {
  const flatRateplans: RoomRateplan['flatRateplans'] = [];
  const roomNameMap: RoomRateplan['roomNameMap'] = {};
  const rpNameMap: RoomRateplan['rpNameMap'] = {};
  const parentRateplans: RoomRateplan['parentRateplans'] = {};
  const partnerRPCodeList = new Set<string>();
  const partnerIngoRPCodeMap: RoomRateplan['partnerIngoRPCodeMap'] = {};
  const rpGroupMapping: RoomRateplan['rpGroupMapping'] = [];
  roomDetails.forEach(room => {
    roomNameMap[room.roomtypecode] = {
      isRoomUpgradePossible: room.is_room_upgrade_possible,
      isActive: room.isactive,
      roomName: room.roomtypename,
      partnerRoomCode: room.source_roomtypecode,
      sourceConfig: room.source_config,
      hasIngoRatePlan: false,
      maxChildOccupancy: room.max_child_occupancy,
      roomBaseOccupancy: room.base_adult_occupancy,
    };
  });

  Object.keys(rateplanDetails).forEach(roomKey => {
    const roomRateplans = rateplanDetails[roomKey];
    roomRateplans.forEach(rp => {
      const isGroupRp = isGroupRateplan(rp);

      rp.isGroupBooking = isGroupRp;
      if (isGroupRp && rp.isactive) {
        if (rp.parent_id === 0) {
          rpGroupMapping.push(rp.rateplancode);
        }
        roomNameMap[roomKey].isGroupBooking = true;
      }
      rp.isHotelTravelRP = rp.valid_contract_types.includes(HOTEL_TRAVEL);
      rpNameMap[rp.rateplancode] = {
        roomName: rp.roomtype__roomtypename,
        roomCode: roomKey,
        rpName: rp.rateplanname,
        partnerRPCode: rp.source_rateplancode,
        sourceConfig: rp.source_config,
        isGroupBooking: isGroupRp,
        is_net_rate_model: rp?.is_net_rate_model,
      };

      if (rp.parent_id > 0) {
        parentRateplans[rp.parent_rateplancode] = true;
      }
      if (rp.source_config !== SOURCE_PARTNER.INGO && rp.source_rateplancode) {
        const partnerRPCode = rp.source_rateplancode;
        partnerRPCodeList.add(partnerRPCode);
        if (isNullOrUndefined(partnerIngoRPCodeMap[partnerRPCode])) {
          partnerIngoRPCodeMap[partnerRPCode] = [rp.rateplancode];
        } else {
          partnerIngoRPCodeMap[partnerRPCode].push(rp.rateplancode);
        }
      }
      flatRateplans.push(rp);
      if (rp.source_config === SOURCE_PARTNER.INGO) {
        roomNameMap[roomKey].hasIngoRatePlan = true;
      }
    });
  });

  return {
    flatRateplans,
    roomNameMap,
    rpNameMap,
    parentRateplans,
    partnerRPCodeList: [...partnerRPCodeList],
    partnerIngoRPCodeMap,
    rpGroupMapping,
  };
};

export const getMiscDetails = (hotelCode: string) => {
  const { GET_MISC_DETAILS } = APP_MAIN;
  const url = GET_MISC_DETAILS(hotelCode);
  return apiWrapper(axios.get(url));
};

export function decideShowDashboardTermsAndConditionsBanner(hotelCode: string) {
  return new Promise(resolve => {
    const { DECIDE_FCL_POPUP } = APP_MAIN;
    const url = DECIDE_FCL_POPUP(hotelCode);
    apiWrapper(axios.get(url))
      .then(
        (data: {
          agreement_list: { agreement_id: Number }[];
          showpopup?: boolean;
        }) => {
          data.agreement_list = data.agreement_list.filter(
            item =>
              item.agreement_id !== 305 &&
              item.agreement_id !== 310 &&
              item.agreement_id !== RESELLER_AGREEMENT_ID &&
              item.agreement_id !== 311,
          );
          if (data?.agreement_list?.length === 0) {
            data.showpopup = false;
          }
          resolve(data);
        },
      )
      .catch(() => resolve(null));
  });
}

export function acceptTnC(hotelCode: string, payload) {
  const { ACCEPT_FCL_TNC } = APP_MAIN;
  const url = ACCEPT_FCL_TNC(hotelCode);
  return apiWrapper(axios.post(url, payload, { useLoader: true }));
}

export function decideShowTanPopup(hotelCode: string, country: string) {
  return new Promise(resolve => {
    if (country.toLowerCase() === 'india') {
      const { DECIDE_TAN_POPUP } = APP_MAIN;
      const url = DECIDE_TAN_POPUP(hotelCode);
      apiWrapper(axios.get(url))
        .then(response => resolve(response))
        .catch(() => resolve(null));
    } else {
      resolve(null);
    }
  });
}

export function updateTanNumber(payload: FormData) {
  const { UPDATE_TAN_NUMBER: url } = APP_MAIN;
  return apiWrapper(axios.post(url, payload, { useLoader: true }));
}

export function getGSTstatus(hotelCode, country) {
  return new Promise(resolve => {
    const { GST } = APP_MAIN;
    const url = GST(hotelCode);
    apiWrapper(axios.get(url), undefined, false)
      .then((data: { gstn: Number }) => {
        if (country.toLowerCase() === 'india') {
          if (data.gstn) {
            resolve(true);
          } else {
            resolve(false);
          }
        } else {
          resolve(true);
        }
      })
      .catch(() => resolve(true));
  });
}

export const getCurrentHotelParser = (
  response,
  channelManagerInfo = { channelManagerName: null, channelManagerId: null },
) => {
  if (isNullOrUndefined(response.property_category)) {
    Sentry.logError(
      new Error(
        `Property Category is missing for hotelcode ${response.hotelcode}`,
      ),
    );
  }

  const showListings = response.current_property_view_web === 'HostWeb';
  const { channelManagerName, channelManagerId } = channelManagerInfo;
  const source = showListings ? 'host_web_view' : 'ingo_legacy';
  window.hotelGA = {
    isActive: response.isactive ? 1 : 0,
    hotelCode: response.hotelcode,
    city: response.city,
    country: response.country,
    propertyCategory: response.property_category,
    hotelType: response.hotel_type,
    source,
  };

  window.adobeHotelGA = {
    onboardingId: '',
    lob:
      response.country.toLowerCase() === 'india' ? 'domestic' : 'international',
    hotelCode: response.hotelcode,
    hotelActiveStatus: response.isactive ? 'active' : 'non-active',
    starRating: response.starrating,
    chainType: response.chain_id ? 'chain' : 'non-chain',
    channelManager: channelManagerName
      ? `${channelManagerId}|${channelManagerName}`
      : 'NA',
    propertyBadge: response.is_gostay
      ? 'go-stay'
      : response.is_advantage
        ? 'mmt-select'
        : 'NA',
    hotelType: response.hotel_type,
    propertyType: response.property_category,
    propertyTypeMeta: response.property_type || 'NA',
    source,
  };
  return { ...response, showListings };
};

export const getRoomRateplansParser = response => {
  const { success, data } = response;
  if (success) {
    const { roomDetails, rateplanDetails } = data ?? {};
    const hourlyRooms = [];
    const nonHourlyRooms = [];
    roomDetails?.forEach(room => {
      if (room.is_slot_room) {
        hourlyRooms.push(room);
      } else {
        nonHourlyRooms.push(room);
      }
    });

    const hourlyRpDetails = pick(
      rateplanDetails,
      hourlyRooms.map(item => item.roomtypecode),
    );
    const nonHourlyAndGrpRpDetails = pick(
      rateplanDetails,
      nonHourlyRooms.map(item => item.roomtypecode),
    );

    const _nonHourlyAndGrpRpDetails = {};
    const grpRateplans = {};

    Object.keys(nonHourlyAndGrpRpDetails).forEach(roomCode => {
      _nonHourlyAndGrpRpDetails[roomCode] = [];
      const roomRateplans = rateplanDetails[roomCode];
      roomRateplans.forEach(rp => {
        const isGroupRp = isGroupRateplan(rp);
        if (isGroupRp) {
          if (grpRateplans[roomCode]?.length > 0) {
            grpRateplans[roomCode].push(rp);
          } else {
            grpRateplans[roomCode] = [rp];
          }
          return;
        }
        _nonHourlyAndGrpRpDetails[roomCode].push(rp);
      });
    });

    const {
      flatRateplans,
      parentRateplans,
      partnerIngoRPCodeMap,
      partnerRPCodeList,
      roomNameMap,
      rpGroupMapping,
      rpNameMap,
    } = roomRateMapObjects(nonHourlyRooms, _nonHourlyAndGrpRpDetails);
    return {
      roomDetails: nonHourlyRooms,
      hourlyStaysRoomDetails: hourlyRooms,
      rateplanDetails: _nonHourlyAndGrpRpDetails,
      hourlyStaysRateplanDetails: hourlyRpDetails,
      flatRateplans,
      roomNameMap,
      rpNameMap,
      parentRateplans,
      partnerRPCodeList: [...partnerRPCodeList],
      partnerIngoRPCodeMap,
      rpGroupMapping,
      grpRateplans,
    };
  }
  const fallback = {
    roomDetails: [],
    rateplanDetails: {},
    hourlyStaysRoomDetails: [],
    hourlyStaysRateplanDetails: {},
    flatRateplans: [],
    parentRateplans: {},
    partnerIngoRPCodeMap: {},
    partnerRPCodeList: [],
    roomNameMap: {},
    rpNameMap: {},
    rpGroupMapping: [],
    grpRateplans: {},
  };
  return fallback;
};
