import { useEffect, useState } from 'react';
import {
  CounterText,
  ToastCounter,
  ToastMessage,
  ToastOverlay,
  ToastWrapper,
} from './CustomToast.styles';
import { RoundTickIcon, CrossIcon } from 'assets/modules/landing';

const IconMapping = {
  success: RoundTickIcon,
  error: CrossIcon,
};

const CustomToast = ({
  visible,
  message,
  onClose,
  duration = 3,
  type = 'success',
  showOverlay = true,
}: {
  visible: boolean;
  message: string;
  onClose?: () => void;
  duration?: number;
  type?: 'success' | 'error' | 'info' | 'warning';
  showOverlay?: boolean;
}) => {
  const [counter, setCounter] = useState(duration);

  useEffect(() => {
    let timer;

    if (visible) {
      setCounter(duration);

      timer = setInterval(() => {
        setCounter(prev => {
          if (prev === 1) {
            clearInterval(timer);
            onClose?.();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [visible, duration, onClose]);

  const Icon = IconMapping[type];

  return (
    <>
      {visible && (
        <>
          <ToastWrapper className={type === 'error' ? 'cosmos-error' : type}>
            <Icon width={28} height={28} fill="#fff" />
            <ToastMessage>{message}</ToastMessage>
            <ToastCounter duration={duration}>
              <div className="circle__item">
                <svg
                  className="circle__spinner"
                  viewBox="0 0 50 50"
                  style={{ transform: 'rotate(90deg) scaleX(-1)' }}
                >
                  <circle
                    className="path"
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    stroke="#93bfec"
                    strokeWidth="1"
                  ></circle>
                </svg>
                <CounterText>{counter}</CounterText>
              </div>
            </ToastCounter>
          </ToastWrapper>
          {showOverlay && <ToastOverlay onClick={onClose} />}
        </>
      )}
    </>
  );
};

export default CustomToast;
