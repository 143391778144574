import { getValue } from 'utilities/Utils';
import { FtrLinks } from './FooterLinks.styles';
import useStrings from 'modules/landingPage/hooks/useStrings';
import { OMNITURE_CATEGORIES, pushToOmniture } from 'utilities/gtm';
import { COMMON_OMNITURE_VARIABLES } from 'modules/landingPage/constants';

interface FooterLinksProps {
  title: string;
  links?: { href: string; text: string }[];
  address?: string;
}

const handleFooterLinkClick = (linkText: string) => {
  pushToOmniture({
    ...COMMON_OMNITURE_VARIABLES,
    event: OMNITURE_CATEGORIES.CTA_CLICK,
    cta: {
      name: linkText,
      type: 'click',
      componentName: 'footer',
    },
  });
};

const FooterLinks = ({ title, links, address }: FooterLinksProps) => {
  const [STRINGS] = useStrings();

  return (
    <FtrLinks>
      {title && <h6>{getValue(STRINGS, title)}</h6>}
      {links && links.length > 0 && (
        <ul>
          {links.map((link, index) => (
            <li key={index}>
              <a
                {...link}
                onClick={() =>
                  handleFooterLinkClick(getValue(STRINGS, link.text))
                }
              >
                {getValue(STRINGS, link.text)}
              </a>
            </li>
          ))}
        </ul>
      )}
      {address && <p>{getValue(STRINGS, address)}</p>}
    </FtrLinks>
  );
};

export default FooterLinks;
