export const COMPONENTS = {
  TEXT: 'TEXT',
  ICON: 'ICON',
  FORM_GROUP: 'FORM_GROUP',
  SHOW_HIDE_SECTION: 'SHOW_HIDE_SECTION',
  POLICY_SELECTION: 'POLICY_SELECTION',
  ALERT_INFO: 'ALERT_INFO',
  RATE_PLAN_SECTION: 'RATE_PLAN_SECTION',
} as const;

export const ACTION_TYPES = {
  NAVIGATION: 'NAVIGATION',
} as const;
