import { Tooltip as MuiTooltip } from 'connect-web-ui/mui';
import { Tooltip, Typography } from 'connect-web-ui';
import { PaletteColorsType } from 'connect-web-ui/types';
import { useEffect, useRef, useState } from 'react';
import type { TooltipVariantType, VariantType } from 'connect-web-ui/types';

export type ToolTipPlacementType = Pick<
React.ComponentProps<typeof MuiTooltip>,
'placement'
>['placement'];

interface IProps {
  children: React.ReactNode;
  toolTipContent?: React.ReactNode;
  className?: string;
  fb?: boolean;
  placement?: ToolTipPlacementType;
  typographyVariant?: VariantType;
  themeColor?: PaletteColorsType;
  onClick?: () => void;
  variant?: TooltipVariantType;
}

const WithTooltipTypography = (props: IProps) => {
  const {
    children,
    toolTipContent = null,
    className = '',
    fb = false,
    variant = 'popover',
    typographyVariant = 'body1',
    placement = 'top',
    themeColor = 'text.primary',
    onClick = () => {},
  } = props;

  const ref = useRef<HTMLParagraphElement>(null);
  const [isOverflow, setisOverflow] = useState(false);

  //this is to show tooltip only on elemnts where content is overflowing
  useEffect(() => {
    if (ref?.current) {
      const _isOverflow = ref.current.scrollWidth > ref.current.clientWidth;
      setisOverflow(_isOverflow);
    }
  }, [ref?.current?.scrollWidth]);

  const renderContent = () => {
    return isOverflow ? (
      toolTipContent ? (
        <Typography fb={fb} themeColor={themeColor} variant={typographyVariant}>
          {toolTipContent}
        </Typography>
      ) : (
        children
      )
    ) : (
      ''
    );
  };
  return (
    <Tooltip
      placement={placement as ToolTipPlacementType}
      content={renderContent()}
      variant={variant}
    >
      <Typography
        className={`overflow-hidden whitespace-nowrap ${
          isOverflow ? 'text-ellipsis' : ''
        }  ${className}`}
        ref={ref}
        fb={fb}
        themeColor={themeColor}
        variant={typographyVariant}
        onClick={onClick}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};

export default WithTooltipTypography;
