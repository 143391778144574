import React, { ReactNode } from 'react';
import { withRouter } from 'react-router-dom';
import Sentry from '../utilities/Sentry';
import ErrorScreen from './special-screens/ErrorScreen';

interface ErrorBoundaryState {
  isError: boolean;
}

type LocationProps = {
  pathname: string;
  hash: string;
  search: string;
  state: unknown;
};

type ModuleErrorBoundary = {
  handleModule: true;
  hotelCode?: string;
  showHeader: false;
};

type AppErrorBoundary = {
  handleModule?: never;
  hotelCode?: never;
  showHeader: true;
};

type ErrorBoundaryProps = {
  location: LocationProps;
  children: ReactNode;
} & (AppErrorBoundary | ModuleErrorBoundary);

class ErrorBoundary extends React.Component<
ErrorBoundaryProps,
ErrorBoundaryState
> {
  state = {
    isError: false,
  };

  componentDidCatch(error) {
    this.setState({ isError: true });
    Sentry.logFatalException(error);
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    if (this.handleModuleError(prevProps)) {
      this.setState({ isError: false });
    }
  }

  handleModuleError(prevProps: ErrorBoundaryProps): boolean {
    // Module or App Error module. If App then handleModule will be undefined or false.
    if (!this.props.handleModule) {
      return false;
    }

    return (
      this.props.location.pathname !== prevProps.location.pathname ||
      this.props.hotelCode !== prevProps.hotelCode
    );
  }

  render() {
    const { isError } = this.state;
    const { children } = this.props;
    return isError ? (
      <ErrorScreen
        data-test-id="js-error-msg-container"
        showHeader={this.props?.showHeader}
      />
    ) : (
      <React.Fragment>{children}</React.Fragment>
    );
  }
}

export default withRouter(ErrorBoundary);
