import { colors } from 'style/zeplin-token-styledComponents';
import styled from 'styled-components';

interface ModalProps {
  width?: string;
  padding?: string;
  closeIcon?: boolean;
}

export const ModalWrapper = styled.div<ModalProps>`
  width: ${({ width }) => width || '530px'};
  padding: ${({ padding }) => padding || '32px'};
  border-radius: 8px;
  position: fixed;
  z-index: 1001;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${colors.white};
`;

export const CloseIcon = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 18px;
  right: 18px;
  cursor: pointer;
  display: inline-flex;
  &:before,
  &:after {
    position: absolute;
    left: 7px;
    content: ' ';
    height: 16px;
    width: 1.25px;
    background-color: ${colors.grayMediumEmphasis};
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
`;
