import React, {
  useContext,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';

import HistoryDrawer from './HistoryDrawer';
import AgreementsDrawer from './AgreementsDrawer';
import UploadLeaseDrawer from './UploadLeaseDrawer';

import {
  useFlagState,
  useLangStrings,
} from '../../../../utilities/CustomHooks';

import {
  HotelContext,
  BankDetailsContext,
} from '../../../../utilities/Context';

import {
  pushGTMEvent,
  GTM_ACTIONS,
  useBankPersuasionType,
  ACCOUNT_STATUS,
  showStatusMessage,
} from '../utils';

import * as BankDetails from 'assets/modules/bank-details';
import { Button } from 'connect-web-ui';

import './agreement-docs.scss';
import './../common.scss';

function HotelInfoCard(props) {
  const { hotelname, isDomHotel, city, country, isChain } =
    useContext(HotelContext).currentHotel;
  const [STRINGS] = useLangStrings('Property');

  const { openUploadLeaseDrawer, hasPropertyDocument, hasNOCAgreement } = props;
  return (
    <div className="hotel-info-card sr-card">
      <img src="" alt="" />
      <p className="name">{hotelname}</p>
      <p className="city">{`${city}, ${country}`}</p>
      {!isChain && isDomHotel && !hasPropertyDocument && !hasNOCAgreement && (
        <Button size="legacy" onClick={openUploadLeaseDrawer} variant="text">
          {STRINGS.FINANCE.AGREEMENTS_AND_DOCS.UPLOAD_LEASE_BUTTON_TEXT}
        </Button>
      )}
    </div>
  );
}

export function BankDetailPersuasion(props) {
  const {
    rejectId,
    onClose,
    openBankSection,
    type,
    rejectReason,
    altAccoIncorrectReason,
    mismatchReason,
    openRelationshipDocDrawer,
    openUploadLeaseDrawer,
  } = props;
  const [STRINGS] = useLangStrings('Property');

  const handleClick = () => {
    if (mismatchReason) {
      openRelationshipDocDrawer();
      return;
    } else if (altAccoIncorrectReason) {
      openUploadLeaseDrawer();
      return;
    }

    pushGTMEvent({
      eventAction: GTM_ACTIONS.persuasions_click,
      eventLabel: 'add_bank_details',
    });
    openBankSection('edit');
  };

  const handleClose = () => {
    pushGTMEvent({
      eventAction: GTM_ACTIONS.persuasions_click,
      eventLabel: 'dismiss_bank',
    });
    onClose();
  };

  if (type !== 'reject') {
    return null;
  }

  const isRejectIdOtherThanQC = showStatusMessage(
    rejectId,
    altAccoIncorrectReason,
  );

  let persuasionText;
  if (type !== 'reject') {
    persuasionText = STRINGS.FINANCE.PERSUASIONS.STOPPAY;
  } else if (!isRejectIdOtherThanQC) {
    persuasionText = STRINGS.FINANCE.PERSUASIONS.QC_REJECT.replace(
      '__reason__',
      rejectReason,
    );
  } else {
    persuasionText = STRINGS.FINANCE.PERSUASIONS.WE_ARE_NOT_PROCESSING.replace(
      '__reason__',
      rejectReason,
    );
  }
  return (
    <div
      style={{ marginBottom: '15px' }}
      className="finance-revamp_persuasion red"
    >
      <p>{persuasionText}</p>

      {type === 'reject' && isRejectIdOtherThanQC && (
        <Button onClick={handleClick} variant="outlined" size="legacy">
          {mismatchReason || altAccoIncorrectReason
            ? STRINGS.FINANCE.PERSUASIONS.UPLOAD_DOCS
            : STRINGS.FINANCE.PERSUASIONS.UPDATE_BANK}
        </Button>
      )}

      {type === 'reject' && isRejectIdOtherThanQC && (
        <Button
          onClick={handleClose}
          size="legacy"
          className="text-common-white ml-4"
          style={{ color: 'inherit' }}
          variant="text"
        >
          {STRINGS.FINANCE.PERSUASIONS.DISMISS}
        </Button>
      )}
    </div>
  );
}

export function AgreementPersuasion(props) {
  const { agreement, buttonText, onClose } = props;
  const [STRINGS] = useLangStrings('Property');
  const { currentHotel, decideTnCBanner } = useContext(HotelContext);

  const handleDismiss = () => {
    pushGTMEvent({
      eventAction: GTM_ACTIONS.persuasions_click,
      eventLabel: `dismiss_${agreement.agreement_display_name}`,
    });
    onClose();
  };

  return (
    <div style={{ marginBottom: '15px' }} className="persuasion">
      <p>{`${agreement.agreement_display_name}${' '}${
        STRINGS.FINANCE.AGREEMENTS_AND_DOCS.ACCEPTANCE_IS_PENDING
      }`}</p>

      <Button
        onClick={() => {
          decideTnCBanner(currentHotel.hotelcode, 'cta');
          pushGTMEvent({
            eventAction: GTM_ACTIONS.persuasions_click,
            eventLabel: `view_agreement_${agreement.agreement_display_name}`,
          });
        }}
        className="!border-2 !border-common-white text-common-white"
        size="legacy"
        variant="outlined"
      >
        {buttonText}
      </Button>
      {/* </a> */}
      <Button
        onClick={handleDismiss}
        size="legacy"
        className="text-common-white ml-4"
        variant="text"
      >
        {STRINGS.FINANCE.AGREEMENTS_AND_DOCS.DISMISS}
      </Button>
    </div>
  );
}

export function GstPersuasion(props) {
  const { onClose, openGSTSection } = props;
  const [STRINGS] = useLangStrings('Property');

  const handleClick = () => {
    pushGTMEvent({
      eventAction: GTM_ACTIONS.persuasions_click,
      eventLabel: 'add_gst_details',
    });
    openGSTSection();
  };

  return (
    <div style={{ marginBottom: '15px' }} className="persuasion">
      <p>{STRINGS.FINANCE.AGREEMENTS_AND_DOCS.GST_DETAILS_FOR_YOUR}</p>

      <Button
        className="!border-2 !border-common-white text-common-white"
        size="legacy"
        variant="outlined"
        onClick={handleClick}
      >
        {STRINGS.FINANCE.AGREEMENTS_AND_DOCS.ADD_GST_DETAIL}
      </Button>

      <Button
        onClick={() => {
          pushGTMEvent({
            eventAction: GTM_ACTIONS.persuasions_click,
            eventLabel: 'dismiss_gst',
          });
          onClose();
        }}
        size="legacy"
        className="text-common-white ml-4"
        variant="text"
      >
        {STRINGS.FINANCE.AGREEMENTS_AND_DOCS.DISMISS}
      </Button>
    </div>
  );
}
function DocumentsCard(props) {
  const { openHistoryDrawer, openAgreementsDrawer, headline, count } = props;
  const { is_vcc } = useContext(HotelContext).currentHotel;
  const [STRINGS] = useLangStrings('Property');

  return (
    <div className="docs-card sr-card">
      <div
        onClick={openAgreementsDrawer}
        className="sr-cursor docs-item-card d-flex sr-align-center sr-justify-space"
      >
        <div>
          <p className="title">{`${STRINGS.FINANCE.AGREEMENTS_AND_DOCS.AGREEMENTS_AND_OTHER} (${count})`}</p>
          <p className="subtitle">{headline}</p>
        </div>
        <img src={BankDetails.RightArrowIcon} alt="" />
      </div>
      {!is_vcc && (
        <div
          onClick={openHistoryDrawer}
          className="sr-cursor docs-item-card d-flex sr-align-center sr-justify-space"
        >
          <div>
            <p className="title">
              {STRINGS.FINANCE.AGREEMENTS_AND_DOCS.HISTORY_OF_UPDATES}
            </p>
          </div>
          <img src={BankDetails.RightArrowIcon} alt="" />
        </div>
      )}
    </div>
  );
}

function AgreementDocs(props, ref) {
  const {
    agreements = [],
    cityAgreements,
    documents,
    history,
    isDomestic,
    bankDetails,
    bankRef,
    // panRef,
    gstRef,
    hasAgreementSigned,
  } = props;

  const {
    rejectId,
    globalAccountStatus,
    rejectReason,
    mismatchReason,
    altAccoIncorrectReason,
    openRelationshipDocDrawer,
    openUploadLeaseDrawer,
  } = useContext(BankDetailsContext);

  const { isHomeStay } = useContext(HotelContext).currentHotel;
  const [lastUnacceptedAgreement, setLastUnacceptedAgreement] = useState();
  const [STRINGS] = useLangStrings('Property');

  const [isVisibleHistoryDrawer, showHistoryDrawer, hideHistoryDrawer] =
    useFlagState(false);

  const [
    isVisibleAgreementsDrawer,
    showAgreementsDrawer,
    hideAgreementsDrawer,
  ] = useFlagState(false);

  const [
    isVisibleUploadLeaseDrawer,
    showUploadLeaseDrawer,
    hideUploadLeaseDrawer,
  ] = useFlagState(false);

  const [
    isVisibleAgreementPersuasion,
    showAgreementPersuasion,
    hideAgreementPersuasion,
  ] = useFlagState(false);

  const [isVisibleGSTPersuasion, showGSTPersuasion, hideGSTPersuasion] =
    useFlagState(false);

  const [
    isVisibleBankDetailsPersuasion,
    showBankDetailsPersuasion,
    hideBankDetailsPersuasion,
  ] = useFlagState(false);

  const bankPersuasionType = useBankPersuasionType(bankDetails);

  const firstTwoAgreements = agreements.slice(0, 2);
  const headline = firstTwoAgreements
    .map(item => item.agreement_display_name)
    .join(', ');

  useEffect(() => {
    const persuasionsGTMLabels = [];
    const { hasGST } = bankDetails;

    // Show agreement persuasion
    const lastUnaccepted = agreements
      .filter(item => item.status !== 'accepted')
      .pop();

    setLastUnacceptedAgreement(lastUnaccepted);

    if (lastUnaccepted) {
      showAgreementPersuasion();
      persuasionsGTMLabels.push(
        `agreement_${lastUnaccepted.agreement_display_name}`,
      );
    } else {
      hideAgreementPersuasion();
    }

    // Show GST persuasaion
    if (
      isDomestic &&
      !hasGST &&
      !hasAgreementSigned(agreements, 277) &&
      globalAccountStatus !== ACCOUNT_STATUS.PENDING
    ) {
      showGSTPersuasion();
      persuasionsGTMLabels.push('gst');
    } else {
      hideGSTPersuasion();
    }

    // Show bank persuasion
    if (
      bankPersuasionType &&
      globalAccountStatus &&
      globalAccountStatus !== ACCOUNT_STATUS.PENDING
    ) {
      showBankDetailsPersuasion();
      persuasionsGTMLabels.push('bank');
    } else {
      hideBankDetailsPersuasion();
    }

    if (persuasionsGTMLabels.length > 0) {
      pushGTMEvent({
        eventAction: GTM_ACTIONS.persuasions,
        eventLabel: persuasionsGTMLabels.join('|'),
        omnitureData: {
          event: 'ctaLoad',
          cta: {
            name: persuasionsGTMLabels.join('|'),
            type: 'load',
            componentName: 'AgreementDocs',
          },
        },
      });
    }
  }, [bankDetails, agreements]);

  useImperativeHandle(
    ref,
    () => ({
      ...ref.current,
      openUploadLeaseDrawer: showUploadLeaseDrawer,
    }),
    [],
  );

  const hasPropertyDocument =
    documents.findIndex(
      item =>
        item.legal_doc_type === 'leaserent' ||
        item.legal_doc_type === 'registration',
    ) > -1;

  const hasNOCAgreement =
    agreements.findIndex(item => {
      const isLeaseNOCAccepted =
        item.agreement_id === cityAgreements.lease.id &&
        item.status === 'accepted';
      const isOwnershipNOCAccepted =
        item.agreement_id === cityAgreements.owned.id &&
        item.status === 'accepted';

      return isLeaseNOCAccepted || isOwnershipNOCAccepted;
    }) > -1;

  return (
    <div id="agreement-section">
      {isVisibleBankDetailsPersuasion && (
        <BankDetailPersuasion
          rejectId={rejectId}
          type={bankPersuasionType}
          rejectReason={rejectReason}
          mismatchReason={mismatchReason}
          altAccoIncorrectReason={altAccoIncorrectReason}
          openBankSection={bankRef?.openBankSection}
          openRelationshipDocDrawer={openRelationshipDocDrawer}
          openUploadLeaseDrawer={openUploadLeaseDrawer}
          isHomeStay={isHomeStay}
          onClose={hideBankDetailsPersuasion}
        />
      )}
      {isVisibleGSTPersuasion && (
        <GstPersuasion
          openGSTSection={gstRef?.openGSTSection}
          onClose={hideGSTPersuasion}
        />
      )}
      {lastUnacceptedAgreement && isVisibleAgreementPersuasion && (
        <AgreementPersuasion
          buttonText={STRINGS.FINANCE.AGREEMENTS_AND_DOCS.VIEW_AGREEMENT}
          agreement={lastUnacceptedAgreement}
          onClose={hideAgreementPersuasion}
        />
      )}
      <HotelInfoCard
        hasNOCAgreement={hasNOCAgreement}
        hasPropertyDocument={hasPropertyDocument}
        openUploadLeaseDrawer={showUploadLeaseDrawer}
      />

      <DocumentsCard
        count={agreements.length + documents.length}
        headline={headline}
        openAgreementsDrawer={() => {
          pushGTMEvent({
            eventAction: GTM_ACTIONS.agreement_and_docs,
            eventLabel: 'click',
          });
          showAgreementsDrawer();
        }}
        openHistoryDrawer={() => {
          pushGTMEvent({
            eventAction: GTM_ACTIONS.update_history,
            eventLabel: 'click',
          });
          showHistoryDrawer();
        }}
      />
      <HistoryDrawer
        history={history}
        isOpen={isVisibleHistoryDrawer}
        onClose={() => {
          pushGTMEvent({
            eventAction: GTM_ACTIONS.update_history,
            eventLabel: 'close',
          });
          hideHistoryDrawer();
        }}
      />
      <AgreementsDrawer
        count={agreements.length + documents.length}
        agreements={agreements}
        documents={documents}
        isOpen={isVisibleAgreementsDrawer}
        onClose={() => {
          pushGTMEvent({
            eventAction: GTM_ACTIONS.agreement_and_docs,
            eventLabel: 'close',
          });
          hideAgreementsDrawer();
        }}
      />
      {isVisibleUploadLeaseDrawer && (
        <UploadLeaseDrawer
          cityAgreements={cityAgreements}
          agreements={agreements}
          isOpen={isVisibleUploadLeaseDrawer}
          onClose={hideUploadLeaseDrawer}
        />
      )}
    </div>
  );
}

export default forwardRef(AgreementDocs);
