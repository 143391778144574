import React, { forwardRef } from 'react';
import { useController, Control } from 'react-hook-form';

import CustomInputField, { CustomInputFieldProps } from './CustomInputField';

interface ControlledCustomInputFieldProps
  extends Omit<CustomInputFieldProps, 'onChange'> {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  rules?: object;
}

const ControlledCustomInputField: React.FC<ControlledCustomInputFieldProps> =
  forwardRef<HTMLInputElement, ControlledCustomInputFieldProps>(
    (props, ref) => {
      const {
        field: controllerFieldProps,
        fieldState: { error },
      } = useController(props);

      const handleChange = (
        _: string,
        event: React.ChangeEvent<HTMLInputElement>,
      ) => {
        controllerFieldProps.onChange(event);
      };

      return (
        <CustomInputField
          {...props}
          {...controllerFieldProps}
          ref={ref}
          onChange={handleChange}
          isError={!!error}
          errorMessage={error?.message}
          tabIndex={props.tabIndex}
        />
      );
    },
  );

export default ControlledCustomInputField;
