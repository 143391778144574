import React, {
  useRef,
  useState,
  useContext,
  useMemo,
  useLayoutEffect,
  useEffect,
  useCallback,
} from 'react';
import { useLocation } from 'react-router-dom';
import { FixedSizeList } from 'react-window';
import AnimatedNumbers from 'react-animated-numbers';
import { Search as SearchIcon, Skeleton } from 'connect-web-ui/mui';
import {
  Input,
  Radio,
  Tooltip,
  Typography,
  Divider,
  Button,
} from 'connect-web-ui';
import { AppContext, HotelContext } from '../../../utilities/Context';
import {
  classNames,
  getURLWithParams,
  isNullOrUndefined,
  navigateTo,
  processInternalCalSyncData,
} from 'utilities/Utils';
import {
  ChevronDown,
  ChevronRight,
  goibiboIcon,
  RedirectIcon,
} from '../../../assets/common';
import { debounce } from 'modules/dashboard/utils/utils';
import { CurrentHotel } from '../../../interfaces/HotelContextInterface';
import { ISTRINGS } from '../../../interfaces/Strings';
import { IHotel } from '../interfaces';
import { routes } from '../../routes';
import { OMNITURE_CATEGORIES, pushToOmniture } from 'utilities/gtm';
import { ContentScore, RatingsIcon } from 'assets/modules/app';
import {
  useClickOutside,
  useLangStrings,
  usePairedProperty,
} from 'utilities/CustomHooks';
import { mmtBig } from 'assets/modules/ratings-and-reviews';
import { LinkageIcon } from 'assets/modules/inventory';
import { checkIcsLinkage } from 'modules/inventory/utils';
import Lottie from 'lottie-react';
import { connect } from 'assets/lottie';
import { fetchContentScore } from 'modules/property/content-score-revamp/ContentScoreRevampGraphClient';
import { GET_ONLY_CONTENT_SCORE } from 'modules/property/content-score-revamp/ContentScoreRevampQueries';
import { getRatings } from 'modules/analytics/utils/AnalyticsAPIClient';
import { omnitureOnHotelCodeSearch } from '../HotelMain';

import 'connect-web-ui/styles/Scrollbar.scss';
import './HotelHeader.scss';

interface HotelHeaderProps {
  hotelList: IHotel[];
  currentHotel: CurrentHotel;
  setCurrentHotel: (currHotel: CurrentHotel) => void;
  handleHotelCodeSearch: (hotelCode: string) => void;
  STRINGS: ISTRINGS['Dashboard']['HOTEL_HEADER'];
  showNewContentScore: boolean;
}

const HotelItemRender = props => {
  const { index, style, data } = props;
  const {
    hotelList,
    isStaff,
    hotelDropDownChange,
    openHotelInNewTab,
    STRINGS,
    currentHotelCode,
  } = data;
  const { internalCalSyncCombinedData } = useContext(HotelContext);
  const [showTooltip, setShowTooltip] = useState(false);
  const ref = useRef<HTMLSpanElement>();
  const moveHotelToTop = (_hotelList, hotelcode) => {
    const idx = _hotelList.findIndex(hotel => hotel.hotelcode === hotelcode);
    if (idx > -1) {
      const hotel = _hotelList.splice(idx, 1)[0];
      _hotelList.unshift(hotel);
    }
    return _hotelList;
  };

  const parentDetails = processInternalCalSyncData(
    internalCalSyncCombinedData?.parentChildDetails?.fetchPropertyListingData
      ?.parentPropertyListing,
  );

  const childDetails = processInternalCalSyncData(
    internalCalSyncCombinedData?.parentChildDetails?.fetchPropertyListingData
      ?.childPropertyListing,
  );

  const pairedProperty = usePairedProperty(
    internalCalSyncCombinedData?.parentChildDetails,
    currentHotelCode,
    true,
  );
  if (
    currentHotelCode === parentDetails?.[0]?.data[0]?.ingoHotelId ||
    currentHotelCode === childDetails?.[0]?.data[0]?.ingoHotelId
  ) {
    moveHotelToTop(hotelList, pairedProperty);
  }
  const isIcsLinked = checkIcsLinkage(
    internalCalSyncCombinedData?.icsLinkageDetails?.internalCalendarGetLinkage
      ?.modelType1,
    parentDetails?.[0]?.data[0]?.ingoHotelId,
    childDetails?.[0]?.data[0]?.ingoHotelId,
  );
  const hotel = hotelList[index];
  const isCurrentHotelChild =
    childDetails[0]?.data[0]?.ingoHotelId === currentHotelCode;
  const linkedProperty = isCurrentHotelChild ? parentDetails : childDetails;
  const showLinkage =
    linkedProperty?.[0]?.data[0]?.ingoHotelId === hotel?.hotelcode &&
    isIcsLinked;
  useLayoutEffect(() => {
    if (ref.current?.clientWidth && ref.current?.scrollWidth)
      if (showLinkage) {
        setShowTooltip(ref.current.clientWidth - 100 < ref.current.scrollWidth);
      } else {
        setShowTooltip(ref.current.clientWidth < ref.current.scrollWidth);
      }
  }, [ref.current]);

  const onNavigationClick = useCallback(() => {
    pushToOmniture({
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      cta: {
        name: 'property_name',
        type: 'click',
        componentName: 'property_Selector',
      },
      loadedComponents: hotel?.label,
    });
    hotelDropDownChange(hotel);
  }, [hotel, hotelDropDownChange]);

  const onNewWindowNavigationClick = useCallback(
    e => {
      pushToOmniture({
        event: OMNITURE_CATEGORIES.CTA_CLICK,
        cta: {
          name: 'property_name_other_window',
          type: 'click',
          componentName: 'property_Selector',
        },
        loadedComponents: hotel?.label,
      });
      openHotelInNewTab(e, hotel);
    },
    [hotel, openHotelInNewTab],
  );

  return (
    <div className="flex gap-2">
      <div
        className={`hotel-item-container px-4 ${
          hotel?.hotelcode === currentHotelCode ? 'active-hotel' : ''
        }`}
        onClick={onNavigationClick}
        style={style}
      >
        <div className="hotel-item">
          {showTooltip ? (
            <Tooltip content={hotel?.label}>
              <div className="flex">
                <div
                  className={`hotel-ddl-option-label ${
                    isStaff ? 'marginR25' : ''
                  } ${showLinkage ? 'w-[145px] !mr-0' : 'w-[260px]'}`}
                >
                  {hotel?.label}
                </div>
                {showLinkage && (
                  <div className="flex">
                    {STRINGS.LINKED_PROPERTY}
                    <LinkageIcon fill="#404040" height={'20px'} />
                  </div>
                )}
              </div>
            </Tooltip>
          ) : (
            <span
              ref={ref}
              className={`hotel-ddl-option-label flex ${
                isStaff ? 'marginR25' : ''
              }`}
            >
              {hotel?.label}
              {showLinkage && (
                <div className="flex">
                  <span>{STRINGS.LINKED_PROPERTY}</span>
                  <LinkageIcon fill="#404040" height={'20px'} />
                </div>
              )}
            </span>
          )}

          <div className="flex align-center">
            {isStaff && (
              <Tooltip content={STRINGS.TOTAL_DISPARITY_COUNT}>
                <span>{hotel?.count}</span>
              </Tooltip>
            )}

            <RedirectIcon
              onClick={e => onNewWindowNavigationClick(e)}
              className="marginL8"
            />
          </div>
        </div>
        <div className="subTitle">{`${hotel.city}, ${hotel.country}`}</div>
        <Divider className="mt-[14px]" />
      </div>
    </div>
  );
};

const getFilteredHotelList = (searchText: string, hotelList: IHotel[]) => {
  if (searchText === '') {
    return hotelList;
  }
  let updatedList = [...hotelList];

  searchText = searchText.toLowerCase();
  updatedList = updatedList.filter(
    hotel =>
      hotel?.label?.toLowerCase().indexOf(searchText) > -1 ||
      hotel?.city?.toLowerCase().indexOf(searchText) > -1 ||
      hotel?.country?.toLowerCase().indexOf(searchText) > -1,
  );
  return updatedList;
};

export default function HotelHeader(props: HotelHeaderProps) {
  const {
    hotelList,
    currentHotel,
    setCurrentHotel,
    handleHotelCodeSearch,
    STRINGS,
    showNewContentScore,
  } = props;

  const [RatingsAndReviews, COMMON_STRINGS] =
    useLangStrings('RatingsAndReviews');

  const normalizedCurrHotel: Partial<CurrentHotel> = currentHotel || {};

  const { isStaff } = useContext(AppContext);

  const { pathname } = useLocation();
  const isDashboard =
    pathname === routes.dashboard.base ||
    pathname === '/' ||
    pathname === routes.onboarding.myProperty;

  const {
    hotelcode: currentHotelCode,
    mmt_id: mmtId,
    showListings = false,
  } = normalizedCurrHotel;

  const [isHotelCodeError, setHotelCodeError] = useState(false);
  const [showOrHideDropdown, setDropdownShowHide] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [ratings, setRatings] = useState({
    loading: true,
    GI: { rating: null },
    MMT: { rating: null },
  });
  const [contentScore, setContentScoreData] = useState({
    loading: true,
    totalContentScore: '',
  });

  const [searchBy, setSearchBy] = useState('name');

  const filteredHotelList = useMemo(
    () =>
      (searchBy === 'name' || !isStaff) &&
      getFilteredHotelList(searchText, hotelList),
    [searchText, hotelList, searchBy, isStaff],
  );

  const hotelCodeInput = useRef<HTMLInputElement>();
  const hotelDisplayContainer = useRef<HTMLSpanElement>();
  const dropdwonRef = useRef<HTMLDivElement>();
  useClickOutside(dropdwonRef, showOrHideDropdown, setDropdownShowHide);

  const hotelDropdownChange = (newHotel: CurrentHotel) => {
    if (hotelCodeInput.current) {
      hotelCodeInput.current.value = '';
    }
    const searchObject = {
      event: 'searchMyProperties',
      cta: {
        name: 'search',
        type: 'click',
        componentName: 'Header',
      },
      navigationSearch: {
        suggestionClicked: 'yes',
      },
      loadedComponents: 'HotelMain|HotelHeader',
    };
    if (currentHotelCode !== newHotel.hotelcode) {
      setDropdownShowHide(false);
      setCurrentHotel(newHotel);
    }
    pushToOmniture(searchObject);
  };

  const openHotelInNewTab = (e, newHotel: CurrentHotel) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    if (currentHotelCode !== newHotel.hotelcode) {
      const url = getURLWithParams(window.location.href, {
        hotel_id: newHotel.hotelcode,
      });
      window.open(url, '_blank');
    }
  };

  const getHotelByHotelCode = () => {
    const searchObject = {
      event: 'searchMyProperties',
      cta: {
        name: 'search',
        type: 'click',
        componentName: 'HotelHeader',
      },
      navigationSearch: {
        suggestionClicked: 'no',
      },
      loadedComponents: 'HotelMain|HotelHeader',
    };
    pushToOmniture(searchObject);
    const validInput = /^10\d{8}$/;
    const hotelCode = searchText;
    if (validInput.test(hotelCode)) {
      handleHotelCodeSearch(hotelCode);
    } else {
      omnitureOnHotelCodeSearch({ hotelCode });
      setHotelCodeError(true);
    }
  };

  const onSearchInputChange = (input: string) => {
    setSearchText(input);
  };

  useEffect(() => {
    // Todo: Remove SWITCH_ON or make it true when ratings need to be taken live
    const SWITCH_ON = false;
    if (mmtId && currentHotelCode) {
      if (SWITCH_ON) {
        getRatings(currentHotelCode)
          .then(ratingsResponse => {
            setRatings({ ...ratingsResponse, loading: false });
          })
          .catch(err => {
            console.error('Error in fetching ratings: ', err);
          });
      }

      fetchContentScore({
        mmtId: mmtId,
        query: GET_ONLY_CONTENT_SCORE,
        getOnlyContentScore: true,
      })
        .then(resp => {
          if (resp?.success3?.success) {
            setContentScoreData({
              totalContentScore: resp?.getContentScoreData?.totalContentScore,
              loading: false,
            });
          } else {
            throw new Error(
              resp?.errorMessage3 || 'Error in fetching content score',
            );
          }
        })
        .catch(error => {
          console.error('Get content score details', error);
          setContentScoreData({
            totalContentScore: null,
            loading: false,
          });
        });
    }
  }, [currentHotelCode, mmtId]);

  useEffect(() => {
    if (showNewContentScore === false) {
      setContentScoreData({
        totalContentScore: null,
        loading: false,
      });
    }
  }, [showNewContentScore]);

  const isDuplicateFlow =
    normalizedCurrHotel.duplicated_to || normalizedCurrHotel.duplicated_from;
  const propertyTypeName =
    normalizedCurrHotel.property_type === 'room'
      ? 'Private Rooms'
      : 'Entire Property';

  const ratingShimmer = () => {
    return (
      <div className="flex items-center w-full">
        <div>
          <Skeleton
            height={40}
            width={150}
            variant="rectangular"
            className="rounded mt-1"
          />
        </div>
      </div>
    );
  };

  const onClickNavigation = useCallback(
    (e, link) => {
      if (
        link === routes.property.newContentScore ||
        link === routes.listing.newContentScore
      ) {
        pushToOmniture({
          event: OMNITURE_CATEGORIES.CTA_CLICK,
          cta: {
            name: 'content_score_header',
            type: 'click',
            componentName: 'header',
          },
          loadedComponents: `${contentScore?.totalContentScore}`,
        });
      }
      e.stopPropagation();
      navigateTo(link);
    },
    [contentScore?.totalContentScore],
  );

  const onSearchChange = useCallback(value => {
    setSearchBy(value);
    setHotelCodeError(false);
    pushToOmniture({
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      cta: {
        name: `${value}_radiobutton`,
        type: 'click',
        componentName: 'property_Selector',
      },
    });
  }, []);

  const onViewAllClick = useCallback(() => {
    pushToOmniture({
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      cta: {
        name: 'add_viewproperty',
        type: 'click',
        componentName: 'property_Selector',
      },
    });
    navigateTo('/');
  }, []);

  const renderLabel = (icon, label) => {
    return (
      <div className="flex gap-1 items-center">
        <div className="w-5 h-5">
          <img src={icon} className="w-full h-full" />
        </div>
        <div>
          <Typography variant="subtitle1">{label}</Typography>
        </div>
        <ChevronRight fill="text.primary" width={16} height={16} />
      </div>
    );
  };

  const ratingCard = (rating, icon) => {
    return rating ? (
      <div className="flex gap-1 items-end">
        <div className="w-[14px] pb-[3px]">
          <img src={icon} className="w-[100%]" />
        </div>

        <div className="flex  items-baseline">
          <Typography
            className="!text-size-20 !font-semibold"
            themeColor="text.primary"
          >
            {rating}
          </Typography>
          <Typography fb className="!text-20" themeColor="text.secondary">
            /5
          </Typography>
        </div>
      </div>
    ) : null;
  };

  // eslint-disable-next-line
  const renderRating = () => {
    return ratings?.loading ? (
      ratingShimmer()
    ) : ratings?.GI?.rating && ratings?.MMT?.rating ? (
      <div className="flex items-center xmd:gap-2 slg:gap-5 xmd:w-[160px] slg:w-[170px]">
        <Divider orientation="vertical" className="h-[40px]" />
        <div
          className="flex flex-col cursor-pointer  hover:bg-color-primary hover:rounded-md h-[52px] py-1 px-2"
          onClick={e => onClickNavigation(e, routes.reviews.ratingsDetails)}
        >
          {
            // @ts-ignore
            renderLabel(RatingsIcon, RatingsAndReviews.RATINGS)
          }

          <div className="flex gap-4">
            {ratingCard(ratings.MMT.rating, mmtBig)}
            {ratingCard(ratings.GI.rating, goibiboIcon)}
          </div>
        </div>
      </div>
    ) : null;
  };

  const renderContentScore = useMemo(() => {
    return contentScore?.loading ? (
      ratingShimmer()
    ) : isNullOrUndefined(contentScore?.totalContentScore) ? null : (
      <div
        className="flex flex-col cursor-pointer hover:bg-color-primary hover:rounded px-2 py-1"
        onClick={e =>
          onClickNavigation(
            e,
            showListings
              ? routes.listing.newContentScore
              : routes.property.newContentScore,
          )
        }
      >
        {renderLabel(ContentScore, COMMON_STRINGS.LABELS.CONTENT_SCORE)}
        <div className="flex h-[20px] items-center">
          <AnimatedNumbers
            includeComma={false}
            animateToNumber={parseInt(contentScore?.totalContentScore, 10)}
            fontStyle={{ fontSize: 20, fontWeight: 600, lineHeight: '28px' }}
            configs={[
              { mass: 1, tension: 220, friction: 90 },
              { mass: 1, tension: 180, friction: 120 },
              { mass: 1, tension: 280, friction: 150 },
            ]}
          ></AnimatedNumbers>
          <div className="h-[26px] flex items-end">
            <Typography variant="body2" themeColor="text.lightSecondary">
              /100
            </Typography>
          </div>
        </div>
      </div>
    );
  }, [
    contentScore?.loading,
    contentScore?.totalContentScore,
    COMMON_STRINGS,
    onClickNavigation,
  ]);

  useEffect(() => {
    if (contentScore?.totalContentScore) {
      pushToOmniture({
        event: OMNITURE_CATEGORIES.CTA_LOAD,
        cta: {
          name: 'content_score_header',
          type: 'load',
          componentName: 'header',
        },
        loadedComponents: `${contentScore?.totalContentScore}`,
      });
    }
  }, [contentScore?.totalContentScore]);

  const renderListDropdown = () => {
    return (
      <div
        ref={dropdwonRef}
        className="absolute w-[350px] bg-color-white h-[350px] overflow-hidden
        shadow-[0_0_10px_5px_rgba(0,0,0,0.1)] rounded-lg right-[0px] top-[60px] pt-4 z-[999] left-10"
      >
        {isStaff && (
          <div className="px-4 mb-2 flex gap-4 h-[30px] items-center">
            <Typography themeColor="common.dark">
              {STRINGS.SEARCH_HOTEL_BY}
            </Typography>
            <Radio
              value={searchBy}
              options={[
                {
                  label: 'Name',
                  value: 'name',
                },
                {
                  label: 'Code',
                  value: 'code',
                },
              ]}
              onChange={onSearchChange}
              customClasses={{
                radioContent: isSelected => {
                  return {
                    label: isSelected ? '!font-medium' : '',
                  };
                },
              }}
              inline
            />
          </div>
        )}

        <div className="flex flex-col px-4 mb-4">
          <Input
            type="text"
            value={searchText}
            onKeyDown={event => {
              if (event.key === 'Enter' && searchBy === 'code') {
                getHotelByHotelCode();
              }
            }}
            onChange={value => {
              if (searchBy === 'code') {
                setHotelCodeError(false);
                onSearchInputChange(value as string);
              } else {
                debounce(onSearchInputChange(value as string), 500);
              }
            }}
            leftElement={<SearchIcon />}
            placeholder={
              searchBy === 'code'
                ? COMMON_STRINGS.LABELS.PROPERTY_CODE_PLACEHOLDER
                : COMMON_STRINGS.LABELS.PROPERTY_NAME_OR_LOCATION
            }
            className="SearchIP max-w-[350px]"
            elementClassNames="pt-1"
            error={isHotelCodeError}
          />

          {isHotelCodeError ? (
            <Typography
              variant="subtitle1"
              themeColor="error.main"
              className="mt-2 px-2"
              fb
            >
              {COMMON_STRINGS.MESSAGES.NO_HOTEL_FOUND}
            </Typography>
          ) : null}
        </div>
        <div className={classNames(isStaff && 'h-[220px] overflow-hidden')}>
          {filteredHotelList?.length === 0 ? (
            <Typography
              variant="subtitle1"
              themeColor="text.secondary"
              className="px-5 h-[228px] flex items-center justify-center"
            >
              {COMMON_STRINGS.MESSAGES.NO_SUGGESTIONS_AVAILABLE}
            </Typography>
          ) : (
            <FixedSizeList
              height={350}
              itemCount={filteredHotelList.length}
              itemSize={70}
              itemData={{
                hotelList: filteredHotelList,
                isStaff: isStaff,
                STRINGS: STRINGS,
                hotelDropDownChange: hotelDropdownChange,
                openHotelInNewTab: openHotelInNewTab,
                currentHotelCode: currentHotelCode,
              }}
              className="sr-light-scrollbar pb-[120px]"
            >
              {HotelItemRender}
            </FixedSizeList>
          )}

          {!isStaff && (
            <div className="sticky bottom-0 bg-color-white h-[50px] z-[999] w-[100%] flex items-center justify-center border-solid border-0 border-t-[1px] border-t-divider">
              <Button variant="text" onClick={onViewAllClick} size="small">
                {STRINGS.ADD_OR_VIEW_ALL_LISTINGS}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  };

  const onLogoClick = () => {
    if (isDashboard) {
      return;
    }
    pushToOmniture({
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      cta: {
        name: 'ingo_menu',
        type: 'click',
        componentName: 'header',
      },
    });
    navigateTo(routes.dashboard.base);
  };

  const onPropertySelectorClick = e => {
    e.stopPropagation();
    setDropdownShowHide(prev => !prev);
    pushToOmniture({
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      cta: {
        name: 'property_Selector',
        type: 'click',
        componentName: 'header',
      },
    });
  };

  return (
    <header className="hotel-header-container flex  lg:gap-5 xmd:gap-3 items-center -ml-4">
      <div className="flexCenterWrap relative">
        <div className="header-dflex">
          <span className="flexCenterWrap">
            <>
              <span
                className={`flexCenterWrap w-[88px] justify-center ${
                  isDashboard ? '' : 'cursor-pointer'
                }`}
                onClick={onLogoClick}
              >
                <Lottie
                  animationData={connect}
                  style={{ height: '50px', width: '70px' }}
                  loop={2}
                />
              </span>
              <span
                ref={hotelDisplayContainer}
                className="flexCenterWrap  hover:bg-color-primary hover:rounded-md h-[52px] py-1 px-2"
              >
                <div
                  className="cur-pntr d-flex posRel"
                  onClick={e => onPropertySelectorClick(e)}
                >
                  <span
                    style={{
                      fontWeight: 400,
                    }}
                    className="db black-lt fb ico16 slg:max-w-[300px] xmd:max-w-[100px]"
                  >
                    <Typography
                      variant="body2"
                      themeColor="common.dark"
                      className="truncate !leading-[18px] !font-medium"
                    >
                      {normalizedCurrHotel.hotelname}
                    </Typography>
                    <div className="db black-lt ico12 padT5 truncate opacity7">
                      {!isNullOrUndefined(normalizedCurrHotel.starrating) &&
                        normalizedCurrHotel.starrating > 0 && (
                          <>{normalizedCurrHotel.starrating}-star </>
                      )}
                      {!isNullOrUndefined(
                        normalizedCurrHotel.property_category,
                      ) && (
                        <span
                          className={
                            !isNullOrUndefined(
                              normalizedCurrHotel.starrating,
                            ) && normalizedCurrHotel.starrating > 0
                              ? 'lowercase'
                              : 'capitalize'
                          }
                        >
                          {normalizedCurrHotel.property_category} |{' '}
                        </span>
                      )}
                      {isDuplicateFlow && !!propertyTypeName
                        ? `${propertyTypeName} | `
                        : null}{' '}
                      {normalizedCurrHotel.locality
                        ? `${normalizedCurrHotel.locality}, `
                        : null}
                      {normalizedCurrHotel.city ? normalizedCurrHotel.city : ''}
                    </div>
                  </span>
                  <span className="flex">
                    <ChevronDown fill="bg-color-primary" />
                  </span>
                </div>
              </span>
            </>
          </span>
        </div>
        {showOrHideDropdown ? renderListDropdown() : null}
      </div>
      {/** Commenting out for enabling once rating revamp is live */}
      {/* {renderRating()} */}
      {/* <Divider orientation="vertical" className="h-[40px]" /> */}
      {renderContentScore}
    </header>
  );
}
