import { useState } from 'react';
import { Unstable_Grid2 as Grid, Box } from 'connect-web-ui/mui';
import { Typography } from 'connect-web-ui';
import { useHotelChange, useLangStrings } from '../../utilities/CustomHooks';
import { getFooterDetails } from '../dashboard/footer/FooterAPIClient';
import * as BlockedWeb from '../../assets/modules/blocked-on-web';

import './BlockedWeb.scss';

export default function RCTHotelBlock() {
  const [contactDetails, setContactDetails] = useState<string>('');
  const [{ RCT_BLOCKER: STRINGS }] = useLangStrings<'App'>('App');

  useHotelChange(hotelCode => {
    getFooterDetails(hotelCode, true)
      .then(
        (resp: {
          contractbdo: { name?: string; mobile?: string; email: string };
        }) => {
          const contactBDO = { ...resp.contractbdo };
          let contact = `${contactBDO.name}`;
          if (contactBDO.mobile !== '') {
            contact += ` | ${contactBDO.mobile}`;
          }
          if (contactBDO.email !== '') {
            contact += ` | ${contactBDO.email}`;
          }
          setContactDetails(contact);
        },
      )
      .catch(error => {
        console.error('Error fetching get footer details', error);
      });
    return () => {
      setContactDetails('');
    };
  }, []);

  if (contactDetails === '') {
    return null;
  }

  return (
    <Box className="sr-container whiteBg blocked-on-web-container">
      <Box maxWidth={650} marginX="auto" height="100%">
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          className="inner-box"
        >
          <Grid>
            <img src={BlockedWeb.RCT} alt="rct blocked" />
          </Grid>
          <Grid>
            <Typography variant="h2" align="center">
              {STRINGS.TITLE}
            </Typography>
          </Grid>
          <Grid>
            <Typography themeColor="gray.main" align="center">
              {STRINGS.REACH_OUT}
            </Typography>
            <Typography themeColor="gray.main" align="center" fb>
              {contactDetails}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
