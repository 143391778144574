import { doQuery } from 'base/GraphQLClient';
import { apiDateFormat, baseUrl } from './../../../constants';
import { getFutureDate, getFormattedDate } from 'utilities/DateUtils';
import {
  TOP_CARDS_PARAMS_MAP,
  TOP_CARDS_PARAMS_MAP_NEW,
} from '../constants/Dashboard';
import {
  getCardsQuery,
  getCardsVariables,
  getTopCardsDataQuery,
  getTopCardsDataVariables,
  getWhatsNewMetaDataQuery,
} from './DashboardGraphQueries';
import { getWhatsNewMetaDataVariables } from './DashboardGraphVariables';
import { getValue, isNullOrUndefined } from 'utilities/Utils';
import { extractData } from './utils';
import { WhatsNewMetaData } from '../Dashboard.types';

export const getTopCardsData = (type, hotelId) => {
  return new Promise((resolve, reject) => {
    const cardParams = TOP_CARDS_PARAMS_MAP[type];
    const { field, KPI, kpiKey } = cardParams;

    const today = new Date();
    const tomorrow = getFutureDate(today, 1);
    const future7thDay = getFutureDate(tomorrow, 7);
    const past7thDay = getFutureDate(today, -7);

    const payload = {
      pipeline: 'transactions',
      include: {
        hotelId: hotelId,
        confirmStatusValue: [
          'pending',
          'confirmed',
          'amended',
          'noshow',
          'aborted',
        ],
        confirmStatus: [1, 2, 10, 11, 14],
      },
      intervals: [
        {
          field: field,
          type: 'range',
          range_intervals: [
            [
              'Last 7 Days',
              getFormattedDate(past7thDay, apiDateFormat),
              getFormattedDate(today, apiDateFormat),
            ],
            [
              'Today',
              getFormattedDate(today, apiDateFormat),
              getFormattedDate(tomorrow, apiDateFormat),
            ],
            [
              'Upcoming 7 Days',
              getFormattedDate(tomorrow, apiDateFormat),
              getFormattedDate(future7thDay, apiDateFormat),
            ],
          ],
        },
      ],
      KPI: KPI,
      custom_metric: ['boost_pc_prev'],
    };

    const variables = getTopCardsDataVariables(payload);

    doQuery(getTopCardsDataQuery, variables, { useLoader: false })
      .then(res => {
        const result = res.data.rawEndpoint.analyticsHistogram.modelType1;
        const { status, message, data, status_code: statusCode } = result;

        // This change has to be done at BE. Presently if data is not found, BE is sending status as failure, which is wrong. Already communicated to Shivangi.
        if (statusCode === 404) {
          resolve({
            data: {
              status,
              message,
              data,
            },
          });
        }

        const newData = {
          upcoming_week_value: data[field]['Upcoming 7 Days'][kpiKey].value,
          boost: data[field].Today[kpiKey].boost_pc_prev > 0,
          value: data[field].Today[kpiKey].value,
          boost_pc: Math.abs(data[field].Today[kpiKey].boost_pc_prev),
        };

        resolve({
          data: {
            status,
            message,
            data: newData,
          },
        });
      })
      .catch(reject);
  });
};

export const getAllPerformaceCardsData = hotelId => {
  const variable = getCardsVariables(hotelId);
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolves, rejects) => {
    try {
      const { data, error } = await doQuery(getCardsQuery, variable, {
        useLoader: false,
      });

      if (error) {
        rejects(error);
      }

      const defVal = {
        boost: false,
        shoutOut: 0,
        trend: 0,
        last7Days: 0,
        status: 'failed',
      };
      const processedData = Object.keys(TOP_CARDS_PARAMS_MAP_NEW).reduce(
        (resultObject, nodeName) => {
          const nodeData = getValue(
            data,
            TOP_CARDS_PARAMS_MAP_NEW[nodeName].pathKey,
          );

          if (nodeData?.status === 'success') {
            const normalizedData = extractData(nodeData.data, nodeName);

            //failing the card if value is NA
            if (normalizedData.shoutOut === 'NA') {
              return {
                ...resultObject,
                [nodeName]: {
                  ...defVal,
                  status: 'failed',
                },
              };
            }
            return {
              ...resultObject,
              [nodeName]: { ...normalizedData },
            };
          } else if (nodeData?.status_code === 404) {
            return {
              ...resultObject,
              [nodeName]: {
                ...defVal,
                status: 'success',
              },
            };
          }
          return {
            ...resultObject,
            [nodeName]: { ...defVal },
          };
        },
        {},
      );

      resolves(processedData);
    } catch (error) {
      rejects();
    }
  });
};

const getWhatsNewMetaData = async (
  mmtId: string,
  consumersKey = 'web_dashboard',
): Promise<Array<WhatsNewMetaData>> => {
  const res = await doQuery(
    getWhatsNewMetaDataQuery,
    getWhatsNewMetaDataVariables(mmtId, consumersKey),
    {
      useLoader: false,
      showError: false,
    },
  );
  const { data, message, status } = getValue(
    res,
    'data.rawEndpoint.analyticsDocuments.modelType1',
    {},
  );
  const getRedirectionUrl = (url: string, is_external_link: boolean) =>
    is_external_link
      ? url
      : url.split(url.includes('#') ? '#' : `${baseUrl}/`)[1] ?? url;

  if (status === 'success') {
    const whatsNewData: Array<WhatsNewMetaData> = data.docs.map(doc => {
      const isExternalLink =
        doc.consumers[consumersKey].ctas[0].meta.is_external_link ?? false;
      return {
        id: doc.id,
        title: doc.title,
        sale: false,
        background_image_url:
          doc.consumers[consumersKey].ctas[0].meta.background_image_url,
        redirect_link: getRedirectionUrl(
          doc.consumers[consumersKey].ctas[0].meta.redirect_link ?? '',
          isExternalLink,
        ),
        expiry_date: doc.expiry_date,
        is_external_link: isExternalLink,
        type: doc.type,
        subType: doc.sub_type,
      };
    });
    return whatsNewData;
  }
  throw new Error(message);
};

export const getWhatsNewMetaDataQueryOptions = (
  mmt_id: string,
  consumersKey = 'web_dashboard',
) => ({
  queryKey: [mmt_id, consumersKey],
  queryFn: async () => {
    try {
      const data = await getWhatsNewMetaData(mmt_id, consumersKey);
      return data;
    } catch (_) {
      return [];
    }
  },
  staleTime: Infinity,
  gcTime: Infinity,
});

const priorityBookingsQuery = `query AnalyticsDocuments($rawEndpointRequestData: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
    analyticsDocuments {
      modelType1 {
        data
        message
        status
        status_code
      }
    }
  }
}`;

const priorityBookingsVariable = (hotelId: string, date: String) => ({
  rawEndpointRequestData: {
    analyticsDocuments: {
      modelType: 'modelType1',
      endPointId: 1,
      body: {
        pipeline: 'transactions',
        fields: {
          include: [
            'roomName',
            'mealPlanValue',
            'user.name',
            'vendorId',
            'bookingId',
            'roomNights',
            'noOfRooms',
            'totalOccupancy',
            'checkout',
            'checkin',
          ],
        },
        include: {
          hotelId,
          checkin: {
            gte: date,
            lte: date,
          },
          confirmStatus: [1],
        },
        exclude: {
          bookingId: null,
        },
        sort: {
          checkin: 'desc',
        },
        size: 100,
      },
    },
  },
});

export const getPriorityBookings = async hotelId => {
  const res = await doQuery(
    priorityBookingsQuery,
    priorityBookingsVariable(
      hotelId,
      getFormattedDate(new Date(), 'YYYY-MM-DD'),
    ),
    { useLoader: false, showError: false },
  );
  const { data, message, status } = getValue(
    res,
    'data.rawEndpoint.analyticsDocuments.modelType1',
    {},
  );
  if (status === 'success' && data.docs.length > 0)
    return data.docs.filter(booking => !isNullOrUndefined(booking?.bookingId));
  throw new Error(message);
};

export const priorityBookingsQueryOptions = (hotelId: string) => ({
  queryKey: [hotelId, 'priorityBookings'],
  queryFn: async () => {
    try {
      const data = await getPriorityBookings(hotelId);
      return data;
    } catch (_) {
      return [];
    }
  },
  staleTime: Infinity,
  gcTime: 0,
});
