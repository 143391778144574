import React from 'react';
import {
  BtnArrow,
  FaqAnswer,
  FaqItem,
  FaqQuestion,
  FaqsList,
  FaqsSection,
  Heading,
  ReadAllButton,
  ReadAllContainer,
  ReadAllText,
  SubHeading,
} from './Faqs.styles';
import { LandingPageContainer } from 'modules/landingPage/LandingPage.styles';
import useStrings from 'modules/landingPage/hooks/useStrings';
import {
  COMMON_OMNITURE_VARIABLES,
  CONTENT_CONFIG,
  EXTERNAL_URLS,
} from 'modules/landingPage/constants';
import { getValue } from 'utilities/Utils';
import { OMNITURE_CATEGORIES, pushToOmniture } from 'utilities/gtm';
// import { useIntersectionObserver } from 'utilities/CustomHooks';

const Faqs = () => {
  const [STRINGS] = useStrings();
  const [activeIndex, setActiveIndex] = React.useState<number | null>(0);

  const handleFaqs = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);

    pushToOmniture({
      ...COMMON_OMNITURE_VARIABLES,
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      loadedComponents: `faq_${index + 1}`,
      cta: {
        name: activeIndex === index ? 'hide' : 'show',
        type: 'click',
        componentName: 'faq',
      },
    });
  };

  const handleReadAllFaq = () => {
    pushToOmniture({
      ...COMMON_OMNITURE_VARIABLES,
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      cta: {
        name: 'read_all_faqs',
        type: 'click',
        componentName: 'faq',
      },
    });
  };

  // useIntersectionObserver(
  //   'faqs-section',
  //   ([entry]) => {
  //     if (entry.isIntersecting) {
  //       pushToOmniture({
  //         ...COMMON_OMNITURE_VARIABLES,
  //         event: OMNITURE_CATEGORIES.CTA_LOAD,
  //         cta: {
  //           name: 'faq_section',
  //           type: 'load',
  //           componentName: 'faq',
  //         },
  //       });
  //     }
  //   },
  //   [],
  //   { rootMargin: '-50% 0px' },
  // );

  return (
    <FaqsSection className="faqs-section">
      <LandingPageContainer>
        <Heading>{STRINGS.faqsData.heading}</Heading>
        <SubHeading>{STRINGS.faqsData.subHeading}</SubHeading>
        <FaqsList>
          {CONTENT_CONFIG.faqs.map((faq, index) => (
            <FaqItem
              key={faq.id}
              active={activeIndex === index ? true : undefined}
            >
              <FaqQuestion onClick={() => handleFaqs(index)}>
                {getValue(STRINGS, faq.question)}
                <BtnArrow active={activeIndex === index ? true : undefined} />
              </FaqQuestion>
              <FaqAnswer active={activeIndex === index ? true : undefined}>
                {getValue(STRINGS, faq.answer)}
              </FaqAnswer>
            </FaqItem>
          ))}
        </FaqsList>

        <ReadAllContainer>
          <ReadAllText>{STRINGS.faqsData.note}</ReadAllText>
          <ReadAllButton
            href={EXTERNAL_URLS.PARTNER_SUPPORT}
            target="_blank"
            onClick={handleReadAllFaq}
          >
            {STRINGS.faqsData.subNote}
          </ReadAllButton>
        </ReadAllContainer>
      </LandingPageContainer>
    </FaqsSection>
  );
};

export default Faqs;
