import { generatePrefixForCurrencies } from 'utilities/Utils';
import { INTLFormat } from '../utils/utils';
import { routes } from 'app/routes';

export const months = {
  0: 'JAN',
  1: 'FEB',
  2: 'MAR',
  3: 'APR',
  4: 'MAY',
  5: 'JUN',
  6: 'JUL',
  7: 'AUG',
  8: 'SEP',
  9: 'OCT',
  10: 'NOV',
  11: 'DEC',
};

export const TRANSACTION = 'Transaction';
export const UGC = 'ugc';
export const PARITY = 'Parity';
export const PERSUASIONS = 'Clubbedpersuasions';
export const PRICING = 'Pricing';

export const SURVEY_URL = 'http://survey.webengage.com/ws/3hc4ngp';

export const TNC_FOR_REFERRAL_PROGRAM_URL =
  'https://gos3.ibcdn.com/5b5f1ea8dfe711ecb9f00242ac140008.pdf';

export const DASHBOARD_TOP_CARDS_TYPES = {
  TODAYS_CHECKIN: 'TODAYS_CHECKIN',
  SELLABILITY_SHARE: 'SELLABILITY_SHARE',
  TODAYS_REVENUE: 'TODAYS_REVENUE',
  TODAYS_AVERAGE_RENT: 'TODAYS_AVERAGE_RENT',
};

export const TOP_CARDS_PARAMS_MAP = {
  [DASHBOARD_TOP_CARDS_TYPES.TODAYS_CHECKIN]: {
    apiKey: 'GET_TODAYS_CHECKINS',
    field: 'checkin',
    KPI: [
      {
        noOfRooms: 'interval_avg_per_day',
      },
    ],
    kpiKey: 'noOfRooms',
  },
  [DASHBOARD_TOP_CARDS_TYPES.SELLABILITY_SHARE]: {
    apiKey: 'GET_SELLABILITY_SHARE',

    field: 'dayWise.date',
    KPI: [
      {
        occupancyShare: 'custom',
      },
    ],
    kpiKey: 'occupancyShare',
  },
  [DASHBOARD_TOP_CARDS_TYPES.TODAYS_REVENUE]: {
    apiKey: 'GET_TODAYS_REVENUE',

    field: 'dayWise.date',
    KPI: [
      {
        'dayWise.totalNetAmount': 'interval_avg_per_day',
      },
    ],
    kpiKey: 'dayWise.totalNetAmount',
  },
  [DASHBOARD_TOP_CARDS_TYPES.TODAYS_AVERAGE_RENT]: {
    apiKey: 'GET_TODAYS_AVG_ROOM_RENT',

    field: 'dayWise.date',
    KPI: [
      {
        avgSellPrice: 'custom',
      },
    ],
    kpiKey: 'avgSellPrice',
  },
};

export const HOTEL_CLOUD = 'HotelCloud';

export const DASHBOARD_TOP_CARDS_TYPES_NEW = {
  ROOM_NIGHTS: 'Today\'s Room Nights',
  REVENUE: 'Today\'s Revenue',
  AVERAGE_SELLING_PRICE: 'Today\'s Average Selling Price',
  CHECK_IN: 'Today\'s Check-ins',
  PROPERTY_VISITS: 'Property Visits',
  CONVERSION: 'Conversion',
};

export const TOP_CARDS_PARAMS_MAP_NEW = {
  [DASHBOARD_TOP_CARDS_TYPES_NEW.ROOM_NIGHTS]: {
    field: 'bookingDate',
    kpiKey: 'roomNights',
    pathKey: 'analyticsHistogram2.analyticsHistogram.modelType1',
  },
  [DASHBOARD_TOP_CARDS_TYPES_NEW.REVENUE]: {
    field: 'bookingDate',
    kpiKey: 'netAmount',
    pathKey: 'analyticsHistogram1.analyticsHistogram.modelType1',
  },
  [DASHBOARD_TOP_CARDS_TYPES_NEW.AVERAGE_SELLING_PRICE]: {
    field: 'bookingDate',
    kpiKey: 'avgSellPrice',
    pathKey: 'analyticsHistogram3.analyticsHistogram.modelType1',
  },
  [DASHBOARD_TOP_CARDS_TYPES_NEW.PROPERTY_VISITS]: {
    field: 'searchDate',
    kpiKey: 'visits',
    pathKey: 'analyticsHistogram4.analyticsHistogram.modelType1',
  },
  [DASHBOARD_TOP_CARDS_TYPES_NEW.CONVERSION]: {
    field: 'bookingDate',
    kpiKey: 'conversionRate',
    pathKey: 'analyticsHistogram5.analyticsHistogram.modelType1',
  },
  [DASHBOARD_TOP_CARDS_TYPES_NEW.CHECK_IN]: {
    field: 'checkin',
    kpiKey: 'parentVendorBookingId',
    pathKey: 'analyticsHistogram6.analyticsHistogram.modelType1',
  },
};

export const shoutOutPrefixSuffixMapper = {
  [DASHBOARD_TOP_CARDS_TYPES_NEW.ROOM_NIGHTS]: {
    prefix: () => '',
    value: value => value,
    suffix: '',
    isInfo: false,
  },
  [DASHBOARD_TOP_CARDS_TYPES_NEW.REVENUE]: {
    prefix: currency => generatePrefixForCurrencies(currency),
    value: (value, currency) => INTLFormat(Math.round(value), currency),
    suffix: '',
    isInfo: false,
  },
  [DASHBOARD_TOP_CARDS_TYPES_NEW.AVERAGE_SELLING_PRICE]: {
    prefix: currency => generatePrefixForCurrencies(currency),
    value: (value, currency) => INTLFormat(Math.round(value), currency),
    suffix: '',
    isInfo: false,
  },
  [DASHBOARD_TOP_CARDS_TYPES_NEW.PROPERTY_VISITS]: {
    prefix: () => '',
    value: (value, currency) => INTLFormat(Math.round(value), currency),
    suffix: '',
    isInfo: true,
  },
  [DASHBOARD_TOP_CARDS_TYPES_NEW.CONVERSION]: {
    prefix: () => '',
    value: value => value,
    suffix: '%',
    isInfo: true,
  },
  [DASHBOARD_TOP_CARDS_TYPES_NEW.CHECK_IN]: {
    prefix: () => '',
    value: value => value,
    suffix: '',
    isInfo: false,
  },
};

export const last7DaysPrefixSuffixMapper = {
  [DASHBOARD_TOP_CARDS_TYPES_NEW.ROOM_NIGHTS]: {
    prefix: () => '',
    value: value => value,
    suffix: ' Room Nights',
  },
  [DASHBOARD_TOP_CARDS_TYPES_NEW.REVENUE]: {
    prefix: currency => `${generatePrefixForCurrencies(currency)} `,
    value: (value, currency) => INTLFormat(Math.round(value), currency),
    suffix: '',
  },
  [DASHBOARD_TOP_CARDS_TYPES_NEW.AVERAGE_SELLING_PRICE]: {
    prefix: currency => `${generatePrefixForCurrencies(currency)} `,
    value: (value, currency) => INTLFormat(Math.round(value), currency),
    suffix: '',
  },
  [DASHBOARD_TOP_CARDS_TYPES_NEW.PROPERTY_VISITS]: {
    prefix: () => '',
    value: (value, currency) => INTLFormat(Math.round(value), currency),
    suffix: ' Visits',
  },
  [DASHBOARD_TOP_CARDS_TYPES_NEW.CONVERSION]: {
    prefix: () => '',
    value: value => value,
    suffix: '%',
  },
  [DASHBOARD_TOP_CARDS_TYPES_NEW.CHECK_IN]: {
    prefix: () => '',
    value: value => value,
    suffix: ' Check-ins',
  },
};

/**
 * TO:DO - Update the routes after pages are available
 */
export const performanceCardRouteMapper = {
  [DASHBOARD_TOP_CARDS_TYPES_NEW.ROOM_NIGHTS]: routes.analytics.roomNights,
  [DASHBOARD_TOP_CARDS_TYPES_NEW.REVENUE]: routes.analytics.revenue,
  [DASHBOARD_TOP_CARDS_TYPES_NEW.AVERAGE_SELLING_PRICE]: routes.analytics.asp,
  [DASHBOARD_TOP_CARDS_TYPES_NEW.PROPERTY_VISITS]:
    routes.analytics.propertyVisits,
  [DASHBOARD_TOP_CARDS_TYPES_NEW.CONVERSION]: routes.analytics.consversion,
  [DASHBOARD_TOP_CARDS_TYPES_NEW.CHECK_IN]: `${routes.bookings.BookingsListBase}/?showNewView=true`,
};

export const LAST_UPDATED_CONVERSION = '24 hrs ago';
