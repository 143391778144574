// FileUploaderSmall.tsx

import React, { memo, useEffect, useRef, useState } from 'react';
import { Delete, Retry } from 'assets/common';
import { showError } from 'base/APIClient';
import { DragNDropIcon } from 'group-property/modules/onboarding/assets';
import { Cross } from 'assets/modules/daily-steal-deal';
import { useController } from 'react-hook-form';
import { Button, Typography, PreviewFile } from 'connect-web-ui';
import {
  MAX_FILE_SIZE_SMALL,
  UploadingStage,
  ALLOWED_FILE_TYPES,
} from 'connect-web-ui/constants';
import { FileUploaderSmallProps } from './FileUploaderSmall.type';

const FileUploaderSmall = (props: FileUploaderSmallProps) => {
  const {
    name,
    control,
    customValidator,
    allowedFileType = ALLOWED_FILE_TYPES,
    formState,
    onFileUpload,
    onDelete,
    onClickRetry,
    isRequired = false,
    maxFileSize = MAX_FILE_SIZE_SMALL,
    uploadLimitDesc = 'Upload PDF/JPG/PNG file up to 15 MB.',
    onClickUploadCancel,
    fileUrl = null,
    disabled = false,
    errorMsg = null,
    onClickView = () => {},
  } = props;

  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const {
    field: { ref },
  } = useController({
    name,
    control,
    rules: {
      validate: customValidator,
    },
  });

  useEffect(() => {
    if (currentFile && formState === UploadingStage.Blank) {
      onFileUpload(currentFile);
    }
  }, [currentFile, formState, onFileUpload]);

  const fileValidations = (file: File) => {
    if (file.size < maxFileSize) {
      let fileTypeAllowed = true;
      if (allowedFileType.length) {
        const filePatterns = allowedFileType.map(item => new RegExp(item));
        fileTypeAllowed = filePatterns.some(item => item.test(file.type));
      }

      if (fileTypeAllowed) {
        setCurrentFile(file);
      } else {
        showError({ msg: 'File type not allowed' });
      }
    } else {
      showError({
        msg: `File size should be below ${Math.floor(
          maxFileSize / 1048576,
        )} MB.`,
      });
    }
  };

  const onFileChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (formState !== UploadingStage.Blank) return;
    if (event.target.files && event.target.files.length > 1) {
      showError({ msg: 'You can only upload at max one file' });
    } else if (event.target.files && event.target.files.length > 0) {
      fileValidations(event.target.files[0]);
    }
  };

  const onSelectFileClick = () => {
    if (disabled || !fileInputRef.current) return;
    fileInputRef.current.value = '';
    fileInputRef.current.click();
  };

  const dropHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (disabled) return;

    if (event.dataTransfer.files.length > 1) {
      showError({ msg: 'Only one file is allowed' });
    } else if (event.dataTransfer.files.length > 0) {
      fileValidations(event.dataTransfer.files[0]);
    }
  };

  const handleDelete = () => {
    if (disabled) return;
    onDelete();
  };

  const dragOverHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <div ref={ref} tabIndex={1} className="no-focus-border outline-none">
      {formState !== UploadingStage.Blank ? (
        <div
          className={`bg-color-light h-[88px] border-solid w-[112px] border flex items-center justify-center rounded ${
            formState === UploadingStage.FailedUploaded || errorMsg
              ? 'border-error-default'
              : 'border-divider'
          } relative`}
        >
          {formState === UploadingStage.Uploaded && (
            <div className="z-[2]">
              {!disabled && (
                <Delete
                  onClick={handleDelete}
                  className="absolute right-1 top-1 w-4 h-4 p-[2px] rounded bg-[#31313180] stroke-common-white cursor-pointer"
                />
              )}
              <Button
                size="small"
                variant="outlined"
                className="absolute right-6 left-6 bottom-2 hover:bg-color-white bg-color-white"
                onClick={onClickView}
              >
                <div className="!font-semibold">View</div>
              </Button>
            </div>
          )}
          {formState === UploadingStage.Uploading && (
            <div className="h-[84px] w-[84px] bg-[#4040404D] rounded z-[1] items-center flex justify-center relative">
              <div className="h-5 w-5 rounded-full bg-color-white flex items-center justify-center border-solid border-[2px] border-color-white border-t-primary-light animate-spin"></div>
              <Cross
                className="cursor-pointer stroke-common-light h-4 w-4 z-[2] absolute"
                onClick={onClickUploadCancel}
              />
            </div>
          )}
          {formState === UploadingStage.FailedUploaded && (
            <div className="h-[84px] w-[84px] bg-[#4040404D] rounded z-[2] items-center flex justify-center">
              <Retry
                className="bg-white h-6 w-6 p-[6px] rounded-full bg-color-white cursor-pointer"
                onClick={() => {
                  if (currentFile) onClickRetry(currentFile);
                }}
              />
              {!disabled && (
                <Delete
                  onClick={handleDelete}
                  className="absolute right-1 top-1 w-4 h-4 p-[2px] rounded bg-[#31313180] stroke-common-white cursor-pointer"
                />
              )}
            </div>
          )}
          {(fileUrl || currentFile) && (
            <PreviewFile
              src={fileUrl || URL.createObjectURL(currentFile)}
              fileName={fileUrl ?? currentFile.name}
            />
          )}
        </div>
      ) : (
        <div
          className={`bg-color-light h-[88px] w-[112px] border-dashed border flex items-center justify-center rounded ${
            isRequired || errorMsg ? 'border-error-default' : 'border-divider'
          } flex-shrink-0`}
          onDrop={dropHandler}
          onDragOver={dragOverHandler}
        >
          <img
            src={DragNDropIcon}
            alt="drag icon"
            height={32}
            onClick={onSelectFileClick}
            className={`${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
          />
        </div>
      )}
      {!disabled && (
        <>
          {errorMsg || formState === UploadingStage.FailedUploaded ? (
            <div className="text-error-default font-normal text-[10px] h-[14px] mt-1">
              {formState === UploadingStage.FailedUploaded
                ? 'Upload Failed'
                : errorMsg}
            </div>
          ) : (
            <Typography
              variant="legends"
              themeColor={'text.secondary'}
              className="mt-1"
            >
              {uploadLimitDesc}
            </Typography>
          )}
        </>
      )}
      <input
        style={{ display: 'none' }}
        type="file"
        onChange={onFileChanged}
        accept={allowedFileType.join(',')}
        ref={fileInputRef}
      />
    </div>
  );
};

export default memo(FileUploaderSmall);
