import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { showMessage } from 'utilities/Utils';
import { suspenseWrapper } from 'base/reactQueryWrapper';
import { useLangStrings } from 'utilities/CustomHooks';
import { Loader } from 'components/common';
import { pushEventToGtm } from 'modules/dashboard/utils/utils';
import { updateBookingStatus } from 'modules/dashboard/utils/DashboardAPIClient';
import { priorityBookingsQueryOptions } from 'modules/dashboard/utils/DashboardGraphClient';
import {
  type PriorityBookingDataType,
  type PriorityBookingsSectionProps,
} from 'modules/dashboard/Dashboard.types';
import { RejectReasonModal } from 'modules/dashboard/components';
import { PriorityBookingRow } from 'modules/dashboard/sections/PriorityBookingRow';
import { PriorityBookingsSectionSkeleton } from './PriorityBookingSectionskeleton';

export const PriorityBookingsSection = suspenseWrapper(
  (props: PriorityBookingsSectionProps) => {
    const { hotelId, gtmLabel, onSectionLoadUpdate } = props;
    const [STRINGS, COMMON_STRINGS] = useLangStrings<'Dashboard'>('Dashboard');
    const [bookingToReject, setBookingToReject] =
      useState<PriorityBookingDataType | null>(null);
    const queryClient = useQueryClient();
    const { data: bookings } = useSuspenseQuery(
      priorityBookingsQueryOptions(hotelId),
    );
    const bookingsToShow = useMemo(() => bookings.slice(0, 2), [bookings]);
    const showSection = useMemo(() => bookings.length > 0, [bookings]);

    const GTM_EVENT_ACTION = 'new_bookings';

    const updateBooking = useCallback(
      (booking: PriorityBookingDataType, status: string, reason: string) => {
        updateBookingStatus(booking.bookingId, status, reason)
          .then((response: { success: boolean }) => {
            if (response?.success) {
              queryClient.setQueryData(
                [hotelId, 'priorityBookings'],
                (prevBookings: PriorityBookingDataType[]) =>
                  prevBookings.filter(b => b.bookingId !== booking.bookingId),
              );
              showMessage({
                message: STRINGS.NEW_BOOKINGS.SUCCESS_MSG.replace(
                  '__placeholder__',
                  status,
                ),
              });
            } else throw new Error();
          })
          .catch(() => {
            showMessage({
              type: 'error',
              message: STRINGS.NEW_BOOKINGS.FAILED_ERR,
            });
          })
          .finally(() => {
            setBookingToReject(null);
          });
      },
      [],
    );

    const confirmBookingHandler = useCallback(
      (booking: PriorityBookingDataType) => {
        pushEventToGtm(GTM_EVENT_ACTION, 'confirm');
        updateBooking(booking, 'confirmed', 'auto_confirmed');
      },
      [],
    );

    const rejectBookingHandler = useCallback(
      reason => {
        if (bookingToReject) {
          pushEventToGtm(GTM_EVENT_ACTION, 'reject');
          updateBooking(bookingToReject, 'rejected', reason);
        }
      },
      [bookingToReject],
    );

    const rejectReasonBookingHandler = useCallback(
      (booking: PriorityBookingDataType) => {
        setBookingToReject(booking);
      },
      [],
    );

    useEffect(() => {
      if (bookings) {
        if (bookings.length > 0) onSectionLoadUpdate(true, gtmLabel);
        else onSectionLoadUpdate(false, gtmLabel);
      }
    }, [bookings]);

    if (!showSection) return null;
    return (
      <PriorityBookingsSectionSkeleton>
        <>
          {bookingsToShow.map(booking => (
            <PriorityBookingRow
              bookingData={booking}
              key={booking.bookingId}
              onReject={rejectReasonBookingHandler}
              onConfirm={confirmBookingHandler}
              STRINGS={STRINGS}
              COMMON_STRINGS={COMMON_STRINGS}
            />
          ))}
          {bookingToReject && (
            <RejectReasonModal
              close={() => setBookingToReject(null)}
              onReject={rejectBookingHandler}
            />
          )}
        </>
      </PriorityBookingsSectionSkeleton>
    );
  },
  {
    renderLoader: () => (
      <PriorityBookingsSectionSkeleton>
        <Loader />
      </PriorityBookingsSectionSkeleton>
    ),
  },
);
