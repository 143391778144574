import { initAndGetAnalyticsSdk } from '@mmt/event-logger';

import { EventDetailSchema, DatasetSchema } from './pdt.types';
import { createCommonPayload } from './pdt.utils';
import { PDT_CONSTANTS } from './pdt.constants';

let analyticsSdk = null;

export function initializePdt() {
  const { PDT_DATASET_NAME, PDT_SCHEMA_TEMPLATE_ID } = PDT_CONSTANTS;
  const shouldInitializePdt = PDT_DATASET_NAME && PDT_SCHEMA_TEMPLATE_ID;

  if (!shouldInitializePdt) return;

  analyticsSdk = initAndGetAnalyticsSdk<EventDetailSchema>({});
}

export function pushToPdt(eventDetailPayload: EventDetailSchema) {
  if (!analyticsSdk) return;

  const commonPdtPayload = createCommonPayload();
  const completePayload: DatasetSchema = {
    ...commonPdtPayload,
    event_detail: eventDetailPayload,
  };

  analyticsSdk.track(completePayload);
}
