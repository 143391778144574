import { Typography } from 'connect-web-ui';
import { useMemo, useState } from 'react';
import { useLangStrings } from 'utilities/CustomHooks';
import { getDateDifference } from 'utilities/DateUtils';
import { CountDownTimer } from '../utils';
import { GenericValue } from 'interfaces/types';

export const CountDown = (props: {
  end: string | Date;
  variant?: GenericValue;
}) => {
  const { end, variant = 'subtitle1' } = props;
  const [
    {
      SALE_HANDLING: { CAMPAIGN_EXPIRED },
    },
    COMMON,
  ] = useLangStrings<'Promotions'>('Promotions');
  const [
    {
      EXPIRY_COMPONENT: { EXPIRES_IN },
    },
  ] = useLangStrings<'GuestChat'>('GuestChat');
  const [timeEnded, setEnded] = useState(false);

  const time = useMemo(() => {
    const diff = getDateDifference(new Date(end), new Date(), 'seconds', true); //getting difference
    return diff * 1000; //returning in ms
  }, [end]);

  const [days, hours, minutes, seconds] = CountDownTimer({
    timer: time,
    timerEnded: () => setEnded(true),
  });

  let timer = `${days} ${COMMON.LABELS.DAYS}`;
  if (Number(days) === 1) {
    timer = `${days} ${COMMON.LABELS.DAY}`;
  }
  if (Number(days) === 0) {
    timer = `${hours}h:${minutes}m:${seconds}s`;
  }

  return (
    <Typography
      variant={variant}
      className="bg-color-overlay text-common-white w-fit px-[6px] py-[2px] rounded-md"
      themeColor="common.white"
    >
      {timeEnded ? CAMPAIGN_EXPIRED : `${EXPIRES_IN} ${timer}`}
    </Typography>
  );
};
