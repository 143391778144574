import { colors, typography } from 'style/zeplin-token-styledComponents';
import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
`;
export const Icon = styled.div`
  width: 51px;
  flex-shrink: 0;
  margin-right: 16px;
`;
export const HeaderRight = styled.div`
  flex: 1;
  margin-right: 16px;
  p:first-child {
    ${typography.titleBold};
    color: ${colors.grayMediumEmphasis};
  }
  p:last-child {
    ${typography.subTextRegular};
    color: ${colors.grayMediumEmphasis};
    margin-top: 4px;
  }
`;

export const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  margin-top: 20px;
`;
export const ButtonWrapper = styled.div`
  margin-top: 16px;
`;
