import { Pattern, PatternSmall } from 'assets/modules/landing';
import { EXTERNAL_URLS } from 'modules/landingPage/constants';
import { colors, typography } from 'style/zeplin-token-styledComponents';
import styled from 'styled-components';

export const Banner = styled.section`
  height: 721px;
  background: url(${EXTERNAL_URLS.BANNER_IMAGE}) no-repeat center center;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 120px;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(
      103.78deg,
      rgba(9, 6, 23, 0.71) 3.3%,
      rgba(9, 6, 23, 0.583333) 64.75%,
      rgba(9, 6, 23, 0) 105.01%
    );
    position: absolute;
    left: 0;
    bottom: 0;
  }
`;

export const BannerInner = styled.div`
  padding: 0px;
  position: relative;
  height: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

export const BannerLeft = styled.div`
  padding-right: 28px;

  p {
    ${typography.shoutoutBlack};
    color: ${colors.white};
    &:last-child {
      ${typography.bodyTextBaseRegular};
    }
  }
`;

export const BannerTitleWrap = styled.div`
  display: flex;
  align-items: center;
  ${typography.shoutoutBlack};
  color: ${colors.white};
`;

export const BannerTagLine = styled.div`
  margin-top: 35px;
  display: flex;
  align-items: center;
  ${typography.headingRegular};
  color: ${colors.white};

  & span {
    align-items: center;
    display: flex;
    position: relative;
    top: 5px;
  }
`;

export const PartnersContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 24px;
`;

export const BannerRightContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BannerPattern = styled.div<{ isExistingUser: boolean }>`
  position: absolute;
  width: ${props => (props.isExistingUser ? '649px' : '595px')};
  height: ${props => (props.isExistingUser ? '635px' : '388px')};
  ${props => props.isExistingUser && 'top: -43px;'}
  right: -40px;
  background: ${props =>
    props.isExistingUser ? `url(${Pattern})` : `url(${PatternSmall})`}
    no-repeat center center;
  background-size: cover;
`;

export const ScrollDownIcon = styled.img`
  position: absolute;
  width: 62px;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
`;

export const BannerRightOuter = styled.div`
  padding: 1px;
  background: radial-gradient(
      86.82% 86.82% at 50% 0.91%,
      ${colors.white} 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(
      50% 50% at 50% 100%,
      ${colors.white} 0%,
      rgba(255, 255, 255, 0) 100%
    );
  overflow: hidden;
  border-radius: 20px;
`;

export const BannerRight = styled.div`
  position: relative;
  width: 466px;
  align-items: center;
  padding: 20px 30px;
  border-radius: 19px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  h3 {
    margin-top: 8px;
    ${typography.bodyTextBaseRegular};
    color: ${colors.white};
    text-align: center;
    margin-bottom: 32px;
  }
`;

export const FormHeading = styled.p`
  ${typography.subheadingBlack};
  color: ${colors.white};
  text-align: center;
`;

export const SignInForm = styled.div<{ marginTop?: number }>`
  gap: 24px;
  display: flex;
  flex-direction: column;
  ${props => props.marginTop && `margin-top: ${props.marginTop}px;`}
`;

export const SignUpButton = styled.button`
  padding: 10px 14px;
  border-radius: 8px;
  background: ${colors.orange};
  outline: 0;
  border: 0;
  width: 100%;
  ${typography.subTextBold};
  color: ${colors.white};
  transition: all 0.3s linear;
  &:hover {
    background: #b63f15;
  }
`;

export const SignInNowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;

  p {
    ${typography.bodyTextBaseRegular};
    color: ${colors.grayMediumEmphasis};
    a {
      color: ${colors.linkBlueDefault};
      ${typography.subTextBold};
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const SignUpText = styled.p`
  text-align: center;
  margin-top: 16px !important;
  ${typography.bodyTextBaseRegular};
  color: ${colors.white};

  a {
    cursor: pointer;
    color: ${colors.white};
    margin-left: 2px;
    ${typography.subTextBlack}
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const LocationContainer = styled.div`
  margin-top: 20px;

  p {
    ${typography.shoutoutBlack};
    color: ${colors.white};
    &:last-child {
      ${typography.bodyTextBaseRegular};
    }
  }
`;

export const LocationTag = styled.p`
  margin-top: 10px !important;
  border-radius: 200px;
  padding: 9px 15px;
  display: inline-block;
  ${typography.bodyTextBaseRegular};
  color: ${colors.white};
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  display: inline-flex;
  align-self: flex-start;
`;

export const ForgotPasswordBtn = styled.div`
  text-align: right;
  flex-shrink: 0;
  margin-left: auto;
  a {
    color: ${colors.white};
    ${typography.subTextBlack};
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ErrorMessage = styled.p`
  ${typography.subTextRegular};
  color: #ff0008;
`;
