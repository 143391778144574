import { useEffect } from 'react';
import { Loader } from 'components/common';
import { TOKEN_STATUS } from './constants';

const InstagramOauthMain = ({ code }) => {
  useEffect(() => {
    if (code) {
      const channel = new BroadcastChannel('instagram-oauth');
      channel.postMessage({
        token: TOKEN_STATUS.AUTHORISED,
        code,
      });
      window.close();
    }
  }, [code]);
  return (
    <div className="flex items-center w-full h-full">
      <Loader className="global-loader-overlay" />
    </div>
  );
};
export default InstagramOauthMain;
