import React from 'react';
import { autobind } from '../../../utilities/Autobind';
import * as API from '../utils/DashboardAPIClient';
import { pushEventToGtm } from '../utils/utils';
import {
  DashboardLoader as Loader,
  DefaultMessageSection,
} from '../components';
import { navigateTo } from 'utilities/Utils';
import { getFormattedDate } from '../../../utilities/DateUtils';
import { wrappedStrings } from '../../../utilities/CustomHooks';
import { routes } from '../../../app/routes';
import { Dashboard } from '../../../utilities/TestIds';
import { Button } from 'connect-web-ui';

import './QualityScoreSection.scss';

const NewReviewRow = ({ review, openReplyModal, COMMON_STRINGS }) => {
  const { customerName, brand, checkin, checkout, comment } = review;
  const brandIcon = brand === 'mmt' ? 'mmt-icon' : 'gi-icon';
  return (
    <div className="width100">
      <div className="sr-flex-row">
        <div className="sr-flex1 marginT30">
          <div className={brandIcon}></div>
        </div>
        <div className="sr-flex3 marginT11 black-ltr">
          <p className="font14">{customerName}</p>
          <p className="font16">
            {getFormattedDate(checkin)} - {getFormattedDate(checkout)}
          </p>
          <p className="font14 opacity7">{comment}</p>
        </div>
        <div className="pad0 sr-flex1">
          <p className="marginT30 dflexcenter sr-justify-end">
            <Button
              onClick={openReplyModal}
              data-test-id="review-page-viewall-button"
            >
              {COMMON_STRINGS.BUTTONS.VIEW_ALL}
            </Button>
          </p>
        </div>
      </div>
    </div>
  );
};

const DataDisplaySection = ({ context, COMMON_STRINGS }) => {
  return (
    <div className="sr-panel-body sr-flex-col pad20">
      <div className="sr-flex-row">
        <NewReviewRow
          openReplyModal={context.toggleReviewReplyModal}
          review={context.state.data}
          COMMON_STRINGS={COMMON_STRINGS}
        />
        <hr />
      </div>
    </div>
  );
};

const SectionBody = ({ context, COMMON_STRINGS }) => {
  let filler = (
    <DefaultMessageSection message={context.defaultSectionMessage} />
  );
  if (context.state.isLoading) {
    filler = <Loader />;
  } else if (Object.keys(context.state.data).length > 0) {
    filler = (
      <DataDisplaySection context={context} COMMON_STRINGS={COMMON_STRINGS} />
    );
  }
  return filler;
};

class NewReviewsSection extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
    const { STRINGS } = this.props;
    this.title = STRINGS.NEW_REVIEWS.HEADING;
    this.hotel = props.hotel;
    this.state = {
      isLoading: false,
      data: {},
      showSection: true,
    };
    this.allReviews = [];
    this.GTM_EVENT_ACTION = 'new_reviews';
  }

  componentWillReceiveProps(props) {
    if (this.props.hotel.hotelId !== props.hotel.hotelId) {
      this.hotel = props.hotel;
      this.allReviews = [];
      this.fetchData();
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.setState({ isLoading: true }, () => {
      const params = {
        source: 'MMT',
        replyStatus: 'pending',
      };
      API.getUnansweredReviews(this.hotel.hotelId, params)
        .then(this.onSuccess)
        .catch(this.onFailure);
    });
  }

  onSuccess(response) {
    const { COMMON_STRINGS } = this.props;
    if (response.results.length === 0) {
      this.defaultSectionMessage = COMMON_STRINGS.MESSAGES.NO_RESPONSE;
      this.props.onSectionLoadUpdate(false, this.props.gtmLabel);
      this.setState({ isLoading: false, data: {}, showSection: false });
      return;
    }
    this.props.onSectionLoadUpdate(true, this.props.gtmLabel);
    const results = response.results;
    results.forEach(result => {
      const obj = {};
      obj.id = result.id;
      obj.customerName = result.bookingname;
      obj.checkin = result.checkin;
      obj.checkout = result.checkout;
      obj.comment = result.reviewContent;
      obj.voyagerId = result.voyagerId;
      obj.brand = result.source?.toLowerCase();
      this.allReviews.push(obj);
    });
    const latestReview = this.allReviews.length > 0 ? this.allReviews[0] : {};
    this.setState({ data: latestReview, isLoading: false, showSection: true });
  }

  onFailure() {
    const { COMMON_STRINGS } = this.props;
    this.props.onSectionLoadUpdate(false, this.props.gtmLabel);
    this.defaultSectionMessage = COMMON_STRINGS.MESSAGES.RETRY;
    this.setState({ isLoading: false, data: {}, showSection: false });
  }

  toggleReviewReplyModal() {
    pushEventToGtm(this.GTM_EVENT_ACTION, 'view_all');
    navigateTo(routes.reviews.base);
  }

  onReplyToReviewSuccess() {
    const { STRINGS } = this.props;
    alert(STRINGS.NEW_REVIEWS.REPLIED_ALERT);
    this.allReviews.splice(0, 1);
    this.setState({ data: this.allReviews[0] });
    this.toggleReviewReplyModal();
  }

  render() {
    const { showSection } = this.state;
    const { COMMON_STRINGS } = this.props;
    return (
      showSection && (
        <div className="sr-panel" data-test-id={Dashboard.reviews}>
          <div className="sr-panel-heading">
            <div className="font16">{this.title}</div>
          </div>
          <SectionBody context={this} COMMON_STRINGS={COMMON_STRINGS} />
        </div>
      )
    );
  }
}

export default wrappedStrings(NewReviewsSection, 'Dashboard');
