import {
  panRegex,
  gstRegex,
  tanRegex,
  ifscRegex,
  alphaNumericRegex,
} from '../../../utilities/allRegex';
import { capitalizeFirst, unflatten } from 'utilities/Utils';
import { GST_NOC_ID, hasAgreementSigned } from './utils';

export function getFieldNames(FIELDS) {
  return Object.keys(FIELDS).map(field => FIELDS[field].name);
}

export function unregisterAllFields(unregister, fieldsByIndex) {
  fieldsByIndex.forEach(fields => {
    unregister(getFieldNames(fields));
  });
}

export function getPANSectionFields(STRINGS) {
  const FIELDS = {
    PAN_NUMBER: {
      name: 'pan_details.pannumber',
      pattern: panRegex,
    },
    PAN_NAME: {
      name: 'pan_details.panname',
    },
    PAN_URL: {
      name: 'pan_details.pan_file_url',
    },
  };

  // add validations
  if (STRINGS) {
    FIELDS.PAN_NUMBER.requiredMessage =
      STRINGS.FINANCE.FORM_VALIDATION_MESSAGES.PAN_NUM_REQUIRED;

    FIELDS.PAN_NUMBER.patternMessage =
      STRINGS.FINANCE.FORM_VALIDATION_MESSAGES.PAN_NUM_PATTERN;
  }
  return FIELDS;
}

export function getGSTSectionFields(STRINGS) {
  const FIELDS = {
    GST_NUMBER: {
      name: 'gst_details.gstnumber',
      pattern: gstRegex,
    },
    NOC_STATUS: {
      name: 'gst_noc_status',
    },
    HAS_GST: {
      name: 'has_gst',
    },
    NOC_ID: {
      name: 'gst_noc_id',
    },
  };

  if (STRINGS) {
    FIELDS.GST_NUMBER.requiredMessage =
      STRINGS.FINANCE.FORM_VALIDATION_MESSAGES.GST_NUM_REQUIRED;

    FIELDS.GST_NUMBER.patternMessage =
      STRINGS.FINANCE.FORM_VALIDATION_MESSAGES.GST_NUM_PATTERN;

    FIELDS.NOC_STATUS.requiredMessage =
      STRINGS.FINANCE.FORM_VALIDATION_MESSAGES.NOC_STATUS_REQUIRED;
  }

  return FIELDS;
}

export function getServiceChargeFields() {
  const FIELDS = {
    SERVICE_CHARGE: {
      name: 'service_charge.other_tax',
    },
    EXTRA_GUEST_CHARGE: {
      name: 'service_charge.extra_guest',
    },
    GST_CODE: {
      name: 'service_charge.gstcode',
    },
  };

  return FIELDS;
}

export function getTANSectionFields(STRINGS) {
  const FIELDS = {
    TAN_NUMBER: {
      name: 'tan_details.tannumber',
      pattern: tanRegex,
    },
    TAN_STATUS: {
      name: 'tan_details.tan_status',
    },
  };

  if (STRINGS) {
    FIELDS.TAN_STATUS.requiredMessage = STRINGS.FINANCE.TAN.TAN_STATUS;
    FIELDS.TAN_NUMBER.requiredMessage = STRINGS.FINANCE.TAN.TAN_NUMBER_LABEL;
  }

  return FIELDS;
}

export function getBankSectionFields(STRINGS) {
  const brandPrefix = 'bank_details.both';
  const FIELDS = {
    BANK_NAME: {
      name: `${brandPrefix}.bank_name`,
    },
    ACCOUNT_NUM: {
      name: `${brandPrefix}.account_no`,
      pattern: alphaNumericRegex,
    },
    ACCOUNT_NAME: {
      name: `${brandPrefix}.account_name`,
    },
    ACCOUNT_TYPE: {
      name: `${brandPrefix}.acctype`,
    },
    IFSC_CODE: {
      name: `${brandPrefix}.ifsc_code`,
      pattern: ifscRegex,
    },
    CURRENCY: {
      name: `${brandPrefix}.currency`,
    },
  };

  if (STRINGS) {
    FIELDS.BANK_NAME.requiredMessage =
      STRINGS.FINANCE.FORM_VALIDATION_MESSAGES.BANK_NAME;
    FIELDS.ACCOUNT_NUM.requiredMessage =
      STRINGS.FINANCE.FORM_VALIDATION_MESSAGES.ACCOUNT_NUM_REQUIRED;

    FIELDS.ACCOUNT_NUM.patternMessage =
      STRINGS.FINANCE.FORM_VALIDATION_MESSAGES.ACCOUNT_NUM_PATTERN;

    FIELDS.IFSC_CODE.requiredMessage =
      STRINGS.FINANCE.FORM_VALIDATION_MESSAGES.IFSC_CODE_REQUIRED;

    FIELDS.IFSC_CODE.patternMessage =
      STRINGS.FINANCE.FORM_VALIDATION_MESSAGES.IFSC_CODE_PATTERN;
  }

  return FIELDS;
}

export function getServicetaxFields() {
  const FIELDS = {
    RELATED_TO: {
      name: 'service_tax.relatedto',
    },
    RELATED_CODE: {
      name: 'service_tax.relatedcode',
    },
    STAY_START: {
      name: 'service_tax.staystart',
    },
    STAY_END: {
      name: 'service_tax.stayend',
    },
    TAX_EXTRA_GUEST: {
      name: 'service_tax.taxonextrabedflag',
    },
  };

  return FIELDS;
}

export function getBankSectionDefaultValues(bankData) {
  const res = {};
  bankData.bank_details.forEach(item => {
    const FIELDS = getBankSectionFields();
    res[FIELDS.ACCOUNT_NAME.name] = item.account_name || '';
    res[FIELDS.ACCOUNT_NUM.name] = item.account_number || '';
    res[FIELDS.ACCOUNT_TYPE.name] = {
      label: capitalizeFirst(
        item.account_type === 'saving' ? 'Savings' : 'Current',
      ),
      value: item.account_type || 'saving',
    };
    res[FIELDS.BANK_NAME.name] = {
      label: item.bank_name,
      value: item.bank_name,
    };

    res[FIELDS.IFSC_CODE.name] = item.ifsc || '';
  });
  return unflatten(res);
}

export function getPANSectionDefaultValues(panData) {
  const res = {};
  panData.pan_details.forEach(item => {
    const FIELDS = getPANSectionFields();
    res[FIELDS.PAN_NUMBER.name] = (item.pan_number || '').toUpperCase();
    res[FIELDS.PAN_NAME.name] = item.name_on_pan || '';
    res[FIELDS.PAN_URL.name] = '';
  });

  return unflatten(res);
}

export function getTANSectionDefaultValues(tanData) {
  const res = {};
  tanData.tan_details.forEach(item => {
    const FIELDS = getTANSectionFields();
    res[FIELDS.TAN_STATUS.name] = item.tan_number
      ? true
      : item.tan_status === undefined
        ? null
        : item.tan_status;
    res[FIELDS.TAN_NUMBER.name] = item.tan_number || '';
  });

  return unflatten(res);
}

export function getGSTSectionDefaultValues(gstData, hotelAgreements) {
  const res = {};
  const FIELDS = getGSTSectionFields();
  res[FIELDS.GST_NUMBER.name] = gstData.gstn_details.gstn || '';
  res[FIELDS.NOC_ID.name] = GST_NOC_ID;
  res[FIELDS.NOC_STATUS.name] = hasAgreementSigned(hotelAgreements, GST_NOC_ID);

  if (res[FIELDS.GST_NUMBER.name].length > 0) {
    res[FIELDS.HAS_GST.name] = true;
  } else if (res[FIELDS.NOC_STATUS.name]) {
    res[FIELDS.HAS_GST.name] = false;
  } else {
    res[FIELDS.HAS_GST.name] = true;
  }

  // res[FIELDS.STATE.name] = gstData.gstn_details.state || '';

  return unflatten(res);
}

export function getServiceChargeDefaultValues(serviceCharge, extraGuestCharge) {
  const res = {};
  const FIELDS = getServiceChargeFields();
  res[FIELDS.SERVICE_CHARGE.name] = serviceCharge.other_tax || 0;
  res[FIELDS.EXTRA_GUEST_CHARGE.name] = extraGuestCharge;
  res[FIELDS.GST_CODE.name] = serviceCharge.gstcode;

  return unflatten(res);
}
