import React, { useEffect, useContext, useRef } from 'react';
import {
  Input,
  Checkbox,
  Radio,
  Drawer,
  LegacyFormElement as FormElement,
} from 'connect-web-ui';
import Uploader from '../components/uploader';
import { useForm } from 'react-hook-form';
import { dispatchFormSubmit, pushGTMEvent, GTM_ACTIONS } from '../utils';
import { getFieldNames } from '../FormFields';
import {
  HotelContext,
  BankDetailsContext,
} from '../../../../utilities/Context';
import FormAlert from '../components/FormAlert';
import {
  uploadDocument,
  pushToStateMachine,
  pushForFinanceApproval,
  acceptNocAgreements,
} from '../BankDetailsAPIClient';
import { findValueByPath, appendCDN, showMessage } from 'utilities/Utils';
import { useLangStrings } from '../../../../utilities/CustomHooks';

const OWNERSHIP_TYPES = [
  { label: 'Owned', value: 'registration' },
  { label: 'Leased', value: 'leaserent' },
];

const YES_OR_NO = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

const initializeFields = (FIELDS, register, setValue) => {
  register(FIELDS.OWNERSHIP_TYPE.name);
  register(FIELDS.DOCUMENT.name, { required: FIELDS.DOCUMENT.requiredMessage });
  register(FIELDS.HAS_DOC.name);
  setValue(FIELDS.OWNERSHIP_TYPE.name, OWNERSHIP_TYPES[0].value);
  setValue(FIELDS.DOCUMENT.name);
  setValue(FIELDS.HAS_DOC.name, YES_OR_NO[0].value);

  return FIELDS;
};

const initializeNOCFields = (FIELDS, register, setValue) => {
  register(FIELDS.NOC_STATUS.name, {
    required: FIELDS.NOC_STATUS.requiredMessage,
  });
  register(FIELDS.NOC_ID.name);
  register(FIELDS.NOC_NAME.name, { required: FIELDS.NOC_NAME.requiredMessage });
  register(FIELDS.NOC_ADDRESS.name, {
    required: FIELDS.NOC_ADDRESS.requiredMessage,
  });

  setValue(FIELDS.NOC_STATUS.name, false);
  setValue(FIELDS.NOC_ID.name, null);
};

function UploadLeaseDrawer(props) {
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    unregister,
  } = useForm({ shouldUnregister: true });
  const { isOpen, onClose, cityAgreements, agreements } = props;
  const { hotelcode, isHomeStay } = useContext(HotelContext).currentHotel;

  const [STRINGS, COMMON_STRINGS] = useLangStrings('Property');
  const { refreshHotelDocuments, refreshBankDetails } =
    useContext(BankDetailsContext);
  const { mismatchReason, altAccoIncorrectReason } =
    useContext(BankDetailsContext);

  const formRef = useRef();

  const FIELDS = {
    OWNERSHIP_TYPE: {
      name: 'ownership_type',
    },
    DOCUMENT: {
      name: 'document',
      requiredMessage: 'Document is required',
    },
    HAS_DOC: {
      name: 'has_doc',
    },
  };

  const NOC_FIELDS = {
    NOC_STATUS: {
      name: 'noc_status',
      requiredMessage: 'Please sign the NOC for GST',
    },
    NOC_ID: {
      name: 'noc_id',
    },
    NOC_NAME: {
      name: 'ownerName',
      requiredMessage: 'Owner\'s Name is required',
    },
    NOC_ADDRESS: {
      name: 'ownerAddress',
      requiredMessage: 'Owner\'s address is required',
    },
  };

  useEffect(() => {
    initializeFields(FIELDS, register, setValue);
    if (isHomeStay) {
      initializeNOCFields(NOC_FIELDS, register, setValue);
    }
  }, [register]);

  const fieldNames = getFieldNames(FIELDS);
  const nocFieldNames = getFieldNames(NOC_FIELDS);

  const [ownershipType, , hasDoc, nocStatus, , ownerName, ownerAddress] = watch(
    [...fieldNames, ...nocFieldNames],
  );

  useEffect(() => {
    if (hasDoc === 'no') {
      initializeNOCFields(NOC_FIELDS, register, setValue);
      unregister(FIELDS.DOCUMENT.name);
    } else if (hasDoc === 'yes') {
      unregister(nocFieldNames);
      register(FIELDS.DOCUMENT.name, {
        required: FIELDS.DOCUMENT.requiredMessage,
      });
    }
  }, [hasDoc]);

  const handleSave = () => {
    pushGTMEvent({
      eventAction: GTM_ACTIONS.upload_lease,
      eventLabel: 'submit',
    });
    dispatchFormSubmit(formRef);
  };

  const handleClose = () => {
    pushGTMEvent({
      eventAction: GTM_ACTIONS.upload_lease,
      eventLabel: 'cancel',
    });
    onClose();
  };

  const handleFormSuccess = data => {
    const formData = new FormData();
    formData.append('type', data.ownership_type);
    if (data.document) {
      formData.append('files', data.document.blob);
    }

    let promise;

    if (data.noc_id) {
      const payload = {
        agreement_list: [
          {
            id: data.noc_id,
            owner_name: data.ownerName,
            owner_address: data.ownerAddress,
          },
        ],
      };
      promise = acceptNocAgreements(payload, hotelcode).then(() => {
        showMessage({
          show: true,
          message: 'Updated Successfully',
          type: 'success',
        });
        onClose();
      });
    } else {
      promise = uploadDocument(hotelcode, formData)
        .then((res: { data: { id: unknown } }) => {
          refreshHotelDocuments();
          pushGTMEvent({
            eventAction: GTM_ACTIONS.upload_lease,
            eventLabel: 'successful',
            misc_id: res.data.id,
          });

          showMessage({
            show: true,
            message: 'Document uploaded successfully',
            type: 'success',
          });
        })
        .catch(err => {
          pushGTMEvent({
            eventAction: GTM_ACTIONS.upload_lease,
            eventLabel: 'error',
          });
          const { error } = err?.response?.data || {};
          showMessage({
            show: true,
            message: error,
            type: 'error',
            commonMessage: COMMON_STRINGS.MESSAGES.SOMETHING_WENT_WRONG,
          });
        })
        .finally(() => {
          onClose();
        });
    }

    /**
     * Push to QC or finance flow once bove calls are done
     */
    promise
      .then(() => {
        if (mismatchReason || altAccoIncorrectReason) {
          const reason = mismatchReason ?? altAccoIncorrectReason;
          return reason.type === 'qc'
            ? pushToStateMachine(hotelcode)
            : pushForFinanceApproval(hotelcode);
        }
        return undefined;
      })
      .then(res => {
        setTimeout(() => {
          refreshBankDetails();
        }, 1000);
        if (res) {
          showMessage({
            show: true,
            message: res.message,
            type: 'success',
          });
        }
      })
      .catch(error => {
        console.error('Refresh bank details', error);
      });
  };

  const handleFormError = () => {};

  const getErrorString = fieldName => {
    return findValueByPath(errors, fieldName)?.message;
  };

  const handleNOCStatusChange = val => {
    setValue(NOC_FIELDS.NOC_STATUS.name, val);
    /*if noc is checked then set the value of corresponding noc_id
    based on ownership type*/
    if (val) {
      if (ownershipType === 'registration') {
        setValue(NOC_FIELDS.NOC_ID.name, cityAgreements.owned?.id);
      } else if (ownershipType === 'leaserent') {
        setValue(NOC_FIELDS.NOC_ID.name, cityAgreements.lease?.id);
      }
    } else {
      setValue(NOC_FIELDS.NOC_ID.name, null);
    }
  };

  const handleOwneshipTypeChange = val => {
    // uncheck noc checkbox if ownership type is changed
    setValue(NOC_FIELDS.NOC_STATUS.name, false);
    setValue(NOC_FIELDS.NOC_ID.name, null);
    setValue(FIELDS.OWNERSHIP_TYPE.name, val);
  };

  useEffect(() => {
    pushGTMEvent({
      eventAction: GTM_ACTIONS.upload_lease,
    });
  }, []);

  const altAccoAgreements = {};

  if (isHomeStay) {
    agreements.reduce((acc, item) => {
      if (item.agreement_id === cityAgreements.lease.id) {
        acc.leaserent = item;
      }
      if (item.agreement_id === cityAgreements.owned.id) {
        acc.registration = item;
      }
      return acc;
    }, altAccoAgreements);
  }

  const renderOwnerDetails = () => {
    return (
      <>
        <FormElement
          label={STRINGS.FINANCE.AGREEMENTS_AND_DOCS.ENTER_OWNER_NAME}
          isRequired
          errorMsg={getErrorString(NOC_FIELDS.NOC_NAME.name)}
        >
          <Input
            value={ownerName}
            name={NOC_FIELDS.NOC_NAME.name}
            type="text"
            placeholder={STRINGS.FINANCE.AGREEMENTS_AND_DOCS.ENTER_OWNER_NAME}
            onChange={value => setValue(NOC_FIELDS.NOC_NAME.name, value)}
          />
        </FormElement>
        <FormElement
          label={STRINGS.FINANCE.AGREEMENTS_AND_DOCS.OWNER_ADDRESS}
          isRequired
          errorMsg={getErrorString(NOC_FIELDS.NOC_ADDRESS.name)}
        >
          <Input
            value={ownerAddress}
            name={NOC_FIELDS.NOC_ADDRESS.name}
            type="text"
            placeholder={STRINGS.FINANCE.AGREEMENTS_AND_DOCS.OWNER_ADDRESS}
            onChange={value => {
              setValue(NOC_FIELDS.NOC_ADDRESS.name, value);
            }}
          />
        </FormElement>
      </>
    );
  };

  return (
    <div>
      <Drawer
        isOpen={isOpen}
        header={{
          title: STRINGS.FINANCE.AGREEMENTS_AND_DOCS.UPLOAD_PROPERTY_DOC,
          hideCross: true,
        }}
        footer={{
          primaryBtn: {
            text: 'Submit',
            onClick: handleSave,
          },
          secondaryBtn: {
            text: 'Close',
            onClick: handleClose,
          },
        }}
        onClose={handleClose}
      >
        <form
          style={{ position: 'relative', height: 'calc(100% - 50px)' }}
          ref={formRef}
          onSubmit={handleSubmit(handleFormSuccess, handleFormError)}
        >
          <FormElement
            label={STRINGS.FINANCE.AGREEMENTS_AND_DOCS.SELECT_OWNERSHIP}
          >
            {fieldNames.length && (
              <Radio
                value={ownershipType || OWNERSHIP_TYPES[0].value}
                options={OWNERSHIP_TYPES}
                onChange={handleOwneshipTypeChange}
                inline
              />
            )}
          </FormElement>
          {isHomeStay && (
            <FormElement
              label={
                ownershipType === 'registration'
                  ? `${STRINGS.FINANCE.AGREEMENTS_AND_DOCS.DO_YOU_HAVE_OWNERSHIP}?`
                  : `${STRINGS.FINANCE.AGREEMENTS_AND_DOCS.DO_YOU_HAVE_LEASE}?`
              }
            >
              <Radio
                value={hasDoc || YES_OR_NO[0].value}
                options={YES_OR_NO}
                onChange={state => setValue(FIELDS.HAS_DOC.name, state)}
                inline
              />
            </FormElement>
          )}
          {fieldNames.length && hasDoc === 'yes' && (
            <FormElement
              errorMsg={getErrorString(FIELDS.DOCUMENT.name)}
              label={
                ownershipType === 'registration'
                  ? STRINGS.FINANCE.AGREEMENTS_AND_DOCS.UPLOAD_OWNERSHIP
                  : STRINGS.FINANCE.AGREEMENTS_AND_DOCS.UPLOAD_LEASE
              }
            >
              <Uploader
                onChange={file => setValue(FIELDS.DOCUMENT.name, file)}
                style={{ width: '228px', height: '140px' }}
                title="Upload document"
              />
            </FormElement>
          )}

          {hasDoc === 'no' && (
            <>
              {renderOwnerDetails()}
              <FormElement
                label=""
                errorMsg={getErrorString(NOC_FIELDS.NOC_STATUS.name)}
              >
                <Checkbox checked={nocStatus} onChange={handleNOCStatusChange}>
                  {`${STRINGS.FINANCE.AGREEMENTS_AND_DOCS.I_AGREE_TO_TERMS[0]} `}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={appendCDN(
                      altAccoAgreements[ownershipType]?.sample_url,
                    )}
                  >
                    {`${STRINGS.FINANCE.AGREEMENTS_AND_DOCS.I_AGREE_TO_TERMS[1]}`}
                  </a>
                  {}
                </Checkbox>
              </FormElement>
            </>
          )}

          <FormAlert
            style={{ position: 'absolute', bottom: '0px' }}
            message={STRINGS.FINANCE.AGREEMENTS_AND_DOCS.DOCUMENT_IS_ESSENTIAL}
          />
        </form>
      </Drawer>
    </div>
  );
}

export default UploadLeaseDrawer;
