type InfoWrapperProps = {
  className: string;
  children: React.ReactNode;
  testId: string;
};

const InfoWrapper = ({
  className = '',
  children,
  testId,
}: InfoWrapperProps) => {
  return (
    <div
      className={`flex flex-1 rounded-lg p-3  ${className}`}
      data-test-id={testId}
    >
      {children}
    </div>
  );
};

export default InfoWrapper;
