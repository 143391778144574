import React from 'react';
import { NavLink } from 'react-router-dom';
import { useLangStrings } from 'utilities/CustomHooks';
import { alpha, makeStyles } from 'connect-web-ui/mui';
import { isNullOrUndefined } from 'utilities/Utils';
import { Tag } from 'connect-web-ui';

type NavTabsOptions = {
  label: string | React.ReactNode;
  path: string;
  count?: number;
};
type NavTabsProps = {
  tabs: NavTabsOptions[];
  onRouteChange?: (path: string) => void;
  className?: string;
  highPriority?: Array<string>;
};
const useStyles = makeStyles(theme => ({
  list: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    listStyleType: 'none',
    margin: 0,
  },
  tab: {
    cursor: 'pointer',
    marginRight: 40,
    borderBottom: '3px solid transparent',
    padding: '8px 0 5px',
    textTransform: 'uppercase',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  navLink: {
    color: theme.palette.common.black,
    borderBottom: '3px solid transparent',
    padding: '8px 0 5px',
    textDecoration: 'none',
  },
  active: {
    color: theme.palette.primary.main,
    borderBottomColor: theme.palette.primary.main,
    padding: '8px 0 5px',
  },
  inactive: {
    '&:hover': {
      borderBottomColor: alpha(theme.palette.primary.main, 0.85),
      transform: 'translateY(-1px)',
      transition: 'all 150ms ease-in-out',
    },
  },
}));
export function NavTabs(props: NavTabsProps) {
  const { tabs, onRouteChange, className = '', highPriority = [] } = props;
  const classes = useStyles();
  const [COMMON_STRINGS] = useLangStrings<'Common'>('Common');

  return (
    <nav className={className}>
      <ul className={classes.list}>
        {tabs.map(tab => {
          const { path, label, count } = tab;

          return (
            <li className={classes.tab} key={path}>
              <NavLink
                onClick={() => onRouteChange?.(path)}
                to={path}
                className={`${classes.navLink} ${classes.inactive}`}
                activeClassName={classes.active}
                data-test-id="nav-link"
              >
                {label}
                {!isNullOrUndefined(count) && (
                  <span data-test-id="count">({count})</span>
                )}
              </NavLink>
              {highPriority.includes(path) && (
                <Tag
                  label={COMMON_STRINGS.LABELS.HIGH_PRIORITY}
                  variant="outlined"
                  color="error"
                  size="small"
                />
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
