import { routes } from 'app/routes';

export const ContentScoreRoutes = {
  space: {
    bedroom_images: {
      imagePath: 'missingPhotos',
      getRoute: id =>
        id
          ? `${routes.listing.photosAndVideos.base}?spaceId=${id}`
          : routes.listing.spaces,
    },
    bathroom_images: {
      imagePath: 'missingPhotos',
      getRoute: id =>
        id
          ? `${routes.listing.photosAndVideos.base}?spaceId=${id}`
          : routes.listing.spaces,
    },
    bathroom_amenities: {
      imagePath: 'missingAmenities',
      getRoute: id =>
        id
          ? `${routes.listing.amenities}?space_id=${id}`
          : routes.listing.spaces,
    },
    bedroom_general_info: {
      imagePath: 'bedroom_images',
      getRoute: id =>
        id ? `${routes.listing.spaces}?id=${id}` : routes.listing.spaces,
    },
    bedroom_amenities: {
      imagePath: 'missingAmenities',
      getRoute: id =>
        id
          ? `${routes.listing.amenities}?space_id=${id}`
          : routes.listing.spaces,
    },
    other_space_general_info: {
      imagePath: 'AdditionalSpaces',
      getRoute: id =>
        id ? `${routes.listing.spaces}?id=${id}` : routes.listing.spaces, // addtional space tab
    },
    other_space_images: {
      imagePath: 'missingPhotos',
      getRoute: id =>
        id
          ? `${routes.listing.photosAndVideos.base}?spaceId=${id}`
          : routes.listing.spaces,
    },
    other_space_amenities: {
      imagePath: 'missingAmenities',
      getRoute: id =>
        id
          ? `${routes.listing.amenities}?space_id=${id}`
          : routes.listing.spaces,
    },
    kitchen_images: {
      imagePath: 'missingPhotos',
      getRoute: id =>
        id
          ? `${routes.listing.photosAndVideos.base}?spaceId=${id}`
          : routes.listing.spaces,
    },
    living_room_images: {
      imagePath: 'missingPhotos',
      getRoute: id =>
        id
          ? `${routes.listing.photosAndVideos.base}?spaceId=${id}`
          : routes.listing.spaces,
    },
    living_room_general_info: {
      route: routes.listing.spaces,
      imagePath: 'CommonSpaces',
    },
    living_room_amenities: {
      imagePath: 'missingAmenities',
      getRoute: id =>
        id
          ? `${routes.listing.amenities}?space_id=${id}`
          : routes.listing.spaces,
    },
    kitchen_amenities: {
      imagePath: 'missingAmenities',
      getRoute: id =>
        id
          ? `${routes.listing.amenities}?space_id=${id}`
          : routes.listing.spaces,
    },
  },
  rules: {
    guests_suitability: {
      route: `${routes.listing.base}?type=accessibility`,
      imagePath: 'childGuestProfile',
    },
    caretaker_info: {
      route: routes.listing.caretaker,
      imagePath: 'caretakerInfo',
    },
    pet_policy: {
      route: `${routes.listing.base}?type=petPolicy&settings=true`,
      imagePath: 'Pet',
    },
    key_exchange_info: {
      route: `${routes.listing.base}?type=key_exchange_info&settings=true`,
      imagePath: 'missingAmenities',
    },
    acceptable_identity_proofs: {
      route: `${routes.listing.base}?type=acceptable_identity_proofs&settings=true`,
      imagePath: 'missingAmenities',
    },
    property_accessibility: {
      route: `${routes.listing.base}?type=property_accessibility`,
      imagePath: 'BookingHistory',
    },
    guest_profile: {
      route: `${routes.listing.base}?type=guest_profile&settings=true`,
      imagePath: 'childGuestProfile',
    },
    property_restrictions: {
      route: `${routes.listing.base}?type=property_restrictions&settings=true`,
      imagePath: 'complianceAgreement',
    },
  },
  property: {
    view_size: {
      route: `${routes.listing.base}?type=view_size`,
      imagePath: 'missingAmenities',
    },
    videos: {
      route: routes.listing.photosAndVideos.base,
      imagePath: 'missingVideo',
    },
    images: {
      route: routes.listing.photosAndVideos.base,
      imagePath: 'missingPhotos',
    },
    desc: {
      route: `${routes.listing.base}?type=desc`,
      imagePath: 'missingAmenities',
    },
    amenities: {
      route: routes.listing.amenities,
      imagePath: 'missingAmenities',
    },
  },
};
