/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { routes } from '../routes';

import {
  CurrentHotel,
  QualityScoreState,
  TabsInfo,
} from '../../interfaces/HotelContextInterface';
import { ITabsToHide } from './interfaces';

//Modules
//removed lazy loading for this page as it is the first to be loaded
import DashboardMain from '../../modules/dashboard/DashboardMain';

const PropertyMain = React.lazy(
  () => import('../../modules/property/PropertyMain'),
) as React.LazyExoticComponent<() => JSX.Element>;
const AnalyticsMain = React.lazy(
  () => import('../../modules/analytics/AnalyticsMain'),
);
export const InventoryMain = React.lazy(
  () => import('../../modules/inventory/InventoryMain'),
);
const CompetitiveAnalysisMain = React.lazy(
  () => import('../../modules/competitive-analysis/CompetitiveAnalysisMainNew'),
);
const PnCMain = React.lazy(
  () => import('../../modules/promotions-and-coupons/PnCMain'),
);
const SponsoredListingMain = React.lazy(
  () => import('../../modules/sponsored-listing/SponsoredListingMain'),
);
const PerformanceBoosterMain = React.lazy(
  () => import('../../modules/performanceBooster/PerformanceBoosterMain'),
);
export const GuestChatMain = React.lazy(
  () => import('../../modules/guest-chat/GuestChatMain'),
);

const ListingsMain = React.lazy(
  () => import('../../modules/host-web/modules/listing/ListingMain'),
);
export const BookingsRevampMain = React.lazy(
  () => import('../../modules/bookings-revamp/BookingsMain'),
);
export const BookingsRevampL2Main = React.lazy(
  () => import('../../modules/bookings-revamp/L2/BookingDetailsMain'),
);
const ReviewsMain = React.lazy(
  () => import('../../modules/reviews/ReviewsMain'),
);
// Commenting for hiding purpose as extranet cleanup task
// const InstayIssuesMain = React.lazy(
//   () => import('../../modules/instay-issues/InstayIssuesMain'),
// );
const AddonsMain = React.lazy(() => import('../../modules/addons/AddonsMain'));
const PLBMain = React.lazy(() => import('../../modules/plb/PLBMain'));
const ReportsMain = React.lazy(
  () => import('../../modules/reports/ReportsMain'),
);
// Commenting for hiding purpose as extranet cleanup task
// const InvoicingMain = React.lazy(
//   () => import('../../modules/invoicing/InvoicesMain'),
// );
const RateRecommendation = React.lazy(
  () => import('../../modules/rateRecommendation/RateRecommendation'),
);
const PaymentDashboardMain = React.lazy(
  () => import('../../modules/payments/PaymentsMain'),
);
const DayUseRoomsMain = React.lazy(
  () => import('../../modules/day-use-rooms/DayUseRoomsMain'),
);
const GroupRatesMain = React.lazy(
  () => import('../../modules/group-rates/GroupRatesMain'),
);
const CampaignMain = React.lazy(
  () => import('../../modules/campaign-manager/CampaignManager'),
);
const ActionDetail = React.lazy(
  () => import('../../modules/opportunity-center/ActionDetail'),
);

const MyImpactMain = React.lazy(
  () => import('../../modules/my-impact/MyImpactMain'),
);
const BlackGoTribeMain = React.lazy(
  () => import('../../modules/black-goTribe/BlackGoTribeMain'),
);

const PackagesLandingMain = React.lazy(
  () => import('../../modules/packages-landing/PackagesLandingMain'),
);
const PrebuyLedgerMain = React.lazy(
  () => import('../../modules/prebuy-ledger/PrebuyLedgerMain'),
);
const ParityMain = React.lazy(
  () => import('../../group-property/parity/ParityMain'),
);
const GroupDashboard = React.lazy(
  () => import('../../group-property/modules/group-dashboard/GroupDashboard'),
);
const UserManagementMain = React.lazy(
  () =>
    import('../../group-property/modules/user-management/UserManagementMain'),
);

export const filterTabs = (
  tabs: TabsInfo[],
  tabsToHide: ITabsToHide,
): TabsInfo[] => {
  return tabs.reduce((acc, item) => {
    if (item.children) {
      const filteredChild = filterTabs(item.children, tabsToHide);
      const temp = { ...item, children: filteredChild };
      acc.push(temp);
    } else if (tabsToHide[item.route] !== true) {
      acc.push(item);
    }
    return acc;
  }, []);
};

export const resellerOnlyTabs = [routes.prebuyLedger.base];

export const routeComponentMap = {
  [routes.dashboard.base]: DashboardMain,
  [routes.bookings.BookingsListBase]: BookingsRevampMain,
  [routes.bookings.oldSynxisListBase]: BookingsRevampMain,
  [routes.bookings.oldDerbyListBase]: BookingsRevampMain,
  [routes.bookings.paymentsListBase]: BookingsRevampMain,
  [routes.inventory.all]: InventoryMain,
  [routes.inventory.linkedRates]: InventoryMain,
  [routes.inventory.calSync]: InventoryMain,
  [routes.inventory.default]: InventoryMain,
  [routes.businessBooster.pnc.base]: PnCMain,
  [routes.businessBooster.blackGoTribe.base]: BlackGoTribeMain,
  [routes.packages.landing]: PackagesLandingMain,
  [routes.businessBooster.performance]: PerformanceBoosterMain,
  [routes.prebuyLedger.base]: PrebuyLedgerMain,
  [routes.payments.base]: PaymentDashboardMain,
  [routes.groupBookings.base]: GroupRatesMain,
  [routes.dayUse.base]: DayUseRoomsMain,
  [routes.competitiveAnalysis.base]: CompetitiveAnalysisMain,
  [routes.sponsoredListing.base]: SponsoredListingMain,
  [routes.reviews.ratings]: ReviewsMain,
  [routes.analytics.base]: AnalyticsMain,
  [routes.guestchat.base]: GuestChatMain,
  [routes.plb.base]: PLBMain,
  [routes.addons.base]: AddonsMain,
  [routes.rateRecommendation.base]: RateRecommendation,
  [routes.opportunityCenter.actionDetail]: ActionDetail,
  [routes.reports.base]: ReportsMain,
  [routes.listing.base]: ListingsMain,
  [routes.businessBooster.campaign.dailyStealDeal.base]: CampaignMain,
  [routes.businessBooster.campaign.selectProgramme]: CampaignMain,
  [routes.businessBooster.campaign.goStay]: CampaignMain,
  [routes.myImpactHomeStay.base]: MyImpactMain,
  [routes.myImpactHotel.base]: MyImpactMain,
  '/': GroupDashboard,
  [routes.onboarding.myProperty]: GroupDashboard,
  [routes.groupchat.base]: GuestChatMain,
  [routes.userManagement.team]: UserManagementMain,
  [routes.parity.base]: ParityMain,
};

const otherComponentsMap = {
  listing: ListingsMain,
  property: PropertyMain,
  myImpact: MyImpactMain,
  qualityScore: React.lazy(
    () => import('../../modules/quality-score/QualityScoreMain'),
  ),
  demandHeatMap: React.lazy(
    () => import('../../modules/demand-heat-map/DemandHeatMap'),
  ),
  reseller: React.lazy(() => import('../../modules/reseller/ResellerMain')),
  inventory: React.lazy(() => import('../../modules/inventory/InventoryMain')),
  bookings: React.lazy(() => import('modules/bookings-revamp/BookingsMain')),
  compset: React.lazy(() => import('../../modules/compset/CompsetMain')),
  packages: React.lazy(() => import('modules/package-inclusions/PackagesMain')),
  losPackage: React.lazy(
    () => import('modules/package-inclusions/pages/PackageLongStay'),
  ),
  saleHandling: React.lazy(
    () => import('modules/sale-handling/SaleHandlingMain'),
  ),
  verificationBlocker: React.lazy(
    () => import('modules/VerificationBlocker/VerificationBlockerMain'),
  ),
  occasionPackage: React.lazy(
    () => import('modules/package-occasion/PackageOccasionMain'),
  ),
};

type TabType = {
  condition: boolean;
  path?: string;
  component?: unknown;
  redirect?: true;
  to?: string;
  from?: string;
};

type GetOtherRoutesType = Array<TabType>;

export const getOtherRoutes = (
  currentHotel: CurrentHotel,
  isResellerView: boolean,
  shouldBlockUser: boolean,
  qualityScoreState: Partial<QualityScoreState>,
  isStaff: boolean,
  isResellerEligible: boolean,
): GetOtherRoutesType => {
  if (!currentHotel) return [];
  const routesArray: TabType[] = [
    {
      condition:
        !isResellerView && currentHotel.showListings && !shouldBlockUser,
      path: routes.listing.base,
      component: otherComponentsMap.listing,
    },
    {
      condition:
        !isResellerView && !currentHotel.showListings && !shouldBlockUser,
      path: routes.property.base,
      component: otherComponentsMap.property,
    },
    {
      condition:
        !currentHotel.showListings &&
        currentHotel.is_sustainability_enabled &&
        !isResellerView,
      path: routes.myImpactHotel.base,
      component: otherComponentsMap.myImpact,
    },
    {
      condition:
        !currentHotel.showListings &&
        currentHotel.is_sustainability_enabled &&
        !isResellerView,
      from: routes.myImpactHomeStay.base,
      to: routes.myImpactHotel.base,
      redirect: true,
    },
    {
      condition:
        currentHotel.showListings &&
        currentHotel.is_sustainability_enabled &&
        !isResellerView,
      from: routes.myImpactHotel.base,
      to: routes.myImpactHomeStay.base,
      redirect: true,
    },
    {
      condition: !shouldBlockUser && qualityScoreState.is_qs_active,
      path: routes.qualityScore.base,
      component: otherComponentsMap.qualityScore,
    },
    {
      condition: !shouldBlockUser,
      path: routes.demandHeatMap.base,
      component: otherComponentsMap.demandHeatMap,
    },
    {
      condition: !isStaff && isResellerEligible,
      path: routes.reseller.base,
      component: otherComponentsMap.reseller,
    },
    {
      condition: true,
      path: `${routes.inventory.base}/*`,
      component: otherComponentsMap.inventory,
    },
    {
      condition: true,
      path: `${routes.bookings.base}/*`,
      component: otherComponentsMap.bookings,
    },
    {
      condition: !isResellerView,
      path: routes.compset.base,
      component: otherComponentsMap.compset,
    },
    {
      condition: !isResellerView,
      path: routes.packages.base,
      component: otherComponentsMap.packages,
    },
    {
      condition: !isResellerView,
      path: routes.packages.losPackage,
      component: otherComponentsMap.losPackage,
    },
    {
      condition: !shouldBlockUser && !isResellerView,
      path: routes.packages.occasionPackage,
      component: otherComponentsMap.occasionPackage,
    },
    {
      condition: !shouldBlockUser && !isResellerView,
      path: `${routes.sale_handling.base}`,
      component: otherComponentsMap.saleHandling,
    },
    {
      condition: currentHotel.showListings,
      from: `${routes.property.base}/*`,
      to: routes.listing.base,
      redirect: true,
    },
    {
      condition: !currentHotel.showListings,
      from: `${routes.listing.base}/*`,
      to: routes.property.base,
      redirect: true,
    },
    {
      condition: !currentHotel.showListings,
      from: `${routes.listing.base}`,
      to: routes.property.base,
      redirect: true,
    },
    {
      condition: shouldBlockUser,
      component: otherComponentsMap.verificationBlocker,
    },
    {
      condition: !currentHotel.is_gostay,
      from: `${routes.businessBooster.campaign.goStay}`,
      to: routes.dashboard.base,
      redirect: true,
    },
    {
      condition: !currentHotel?.is_advantage,
      from: `${routes.businessBooster.campaign.selectProgramme}`,
      to: routes.dashboard.base,
      redirect: true,
    },
    {
      condition:
        currentHotel?.is_chat_enabled && !shouldBlockUser && !isResellerView,
      component: GuestChatMain,
      path: routes.guestchat.base,
    },
  ];

  return routesArray.filter(route => route.condition);
};
