export const combinedQueryForICSLinkageAndParentChildDetails = `
query CombinedQueryForICSLinkageAndParentChildDetails($rawEndpointRequestData: RawEndpointsRequestData, $rawEndpointsRequestData2: RawEndpointsRequestData) {
  parentChildDetails: rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
    fetchPropertyListingData {
      success
      message
      parentPropertyListing {
        active {
          hotelName
          ingoHotelId
          thumbnailUrl
          propertyDetails {
            propertyType
            sellableUnit
            propertySubType
          }
        }
        inActive {
          hotelName
          ingoHotelId
          thumbnailUrl
          propertyDetails {
            propertyType
            sellableUnit
            propertySubType
          }
        }
        progress {
          hotelName
          ingoHotelId
          thumbnailUrl
          propertyDetails {
            propertyType
            sellableUnit
            propertySubType
          }
        }
      }
      childPropertyListing {
        active {
          hotelName
          ingoHotelId
          thumbnailUrl
          propertyDetails {
            propertyType
            sellableUnit
            propertySubType
          }
        }
        inActive {
          hotelName
          ingoHotelId
          thumbnailUrl
          propertyDetails {
            propertyType
            sellableUnit
            propertySubType
          }
        }
        progress {
          hotelName
          ingoHotelId
          thumbnailUrl
          propertyDetails {
            propertyType
            sellableUnit
            propertySubType
          }
        }
      }
      errorCode {
        httpStatusCode
        errorCode
        errorMessage
        displayMessage
      }
    }
  }
  icsLinkageDetails: rawEndpoint(rawEndpointRequestData: $rawEndpointsRequestData2) {
    internalCalendarGetLinkage {
      modelType1 {
        data {
          roomtypecode
          linkage_id
          linked_parent_room
          linked_parent_hotel
          linked_room_hotelcode
        }
        success
        error
      }
    }
  }
}
`;
