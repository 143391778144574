import React from 'react';
import { TickerContainer, TickerItem } from './Ticker.styles';
import Slider from 'react-slick';

const Ticker = ({ items }: { items: Array<string> }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: false,
    autoplay: true,
    autoplaySpeed: 1000,
    speed: 800,
  };
  return (
    <TickerContainer>
      <Slider {...settings}>
        {items.map((item, i) => (
          <TickerItem key={i}>{item && <span>{item}</span>}</TickerItem>
        ))}
      </Slider>
    </TickerContainer>
  );
};

export default Ticker;
