import React, { useState, useRef, useEffect, forwardRef, Ref } from 'react';
import { __LegacyDropdown__ as Dropdown, Input } from 'connect-web-ui';
import { getCountriesList } from 'modules/dashboard/utils/DashboardAPIClient';
import { makeStyles } from 'connect-web-ui/mui';
import { classNames } from 'utilities/Utils';
import { RegisterOptions, useController } from 'react-hook-form';
import {
  type CountryResObj,
  type GetCountriesListType,
  type PhoneInputProps,
} from './PhoneInput.types';

const phoneRegex = /^\+?[-\d# ]{6,20}$/;
const domPhoneRegex = /^[0-9]{10}$/;

function phoneNumberValidator(value) {
  const { phone, country } = value;
  if (phone && country) {
    const isDomPhoneNo = country === '+91';
    const validRegex = isDomPhoneNo ? domPhoneRegex : phoneRegex;
    if (validRegex.test(phone)) {
      return true;
    }
    return 'Enter valid phone number';
  }
  return 'This field is required';
}

export const useStyles = makeStyles(theme => ({
  noborder: {
    '& [class*="MuiOutlinedInput-notchedOutline-"]': {
      display: 'none',
      width: '100% !important',
    },
  },
  groupedinputs: {
    height: '40px !important',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
    '& > div': {
      position: 'relative',
      width: 'unset',
      '&:not(:last-child)::after': {
        content: '\'\'',
        height: '70%',
        position: 'absolute',
        top: '15%',
        zIndex: 1,
      },
    },
    '& [class*="MuiOutlinedInput-root-"]': {
      width: '100% !important',
      padding: '0px !important',
    },
    '& [class*="MuiAutocomplete-endAdornment"]': {
      paddingRight: '0px !important',
      right: '-10px !important',
    },
    '& [class*="MuiIconButton-root"]': {
      padding: '0',
      marginRight: '9px',
      marginBottom: '2px',
    },

    '& .MuiOutlinedInput-notchedOutline': { display: 'none' },
    '&::after': {
      content: '\'\'',
      position: 'absolute',
      top: '1px',
      bottom: '0',
      left: '0',
      right: '0',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: '4px',
      pointerEvents: 'none',
    },
    '&:focus-within': { '&::after': { border: '2px solid #3543bf' } },
  },
  phoneinput: {
    '& .MuiInputBase-root': { width: '100%' },
    '& .MuiAutocomplete-inputRoot': { width: '110px', fontWeight: 'bold' },
    '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
      minWidth: 'fit-content',
      fontWeight: '400',
    },
    '& .MuiAutocomplete-root & .MuiTextField-root.medium & .MuiAutocomplete-inputRoot input':
      {
        padding: '6px',
      },
    '& .MuiOutlinedInput-root input': { minWidth: '100px' },
  },

  widthStyle: {
    width: '112px',
    height: '40px',
  },
  noWidth: {
    '& [class*="makeStyles-autocompleteRoot-"]': {
      width: 'unset',
      margin: '0',
    },
  },
  disableDropdown: {
    backgroundColor: theme.palette.backgrounds.dark,
    color: theme.palette.common.black,
  },
}));
export const DEFAULT_COUNTRY_CODE = 103;

const countryData = { list: [], default: null };

export const getCountry = initValue => {
  return countryData.list.find(item => {
    return item.value === initValue || item.id === initValue;
  });
};

const getFlag = country => {
  return country
    .toUpperCase()
    .replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397));
};

function PhoneInputComp(props: PhoneInputProps, ref: Ref<HTMLDivElement>) {
  const {
    phone,
    onPhoneChange,
    country,
    onCountryChange,
    //Optional
    placeholder,
    disabled = false,
    disableDropdown = false,
    errorMsg = '',
    onPhoneBlur,
    rightElement,
    className = '',
  } = props;
  const classes = useStyles();

  const containerRef = useRef();

  const getOption = (optionsProps, option) => {
    return <div {...optionsProps}>{option.label}</div>;
  };

  return (
    <div
      className={classNames(
        classes.noborder,
        classes.groupedinputs,
        classes.phoneinput,
        classes.noWidth,
        disableDropdown && classes.disableDropdown,
        className,
      )}
      ref={containerRef}
    >
      <Dropdown
        popupAnchor={containerRef}
        disableClear
        labelField="selectedLabel"
        optionVariant="custom"
        renderOption={getOption}
        disabled={disableDropdown}
        options={countryData.list}
        value={country}
        onChange={(value: string) => onCountryChange(value)}
        className={classNames(
          classes.widthStyle,
          disableDropdown && classes.disableDropdown,
        )}
        rigthIconColor="text-text-primary"
      />
      <Input
        ref={ref}
        value={phone}
        onChange={value => onPhoneChange(value)}
        type="number"
        placeholder={placeholder}
        disabled={disabled}
        onBlur={onPhoneBlur}
        error={errorMsg !== ''}
        rightElement={rightElement}
      />
    </div>
  );
}

export function usePhoneInput(
  initialPhone,
  initValue,
  initCountryData: Array<CountryResObj> = [],
) {
  const parsedInitValue = initValue?.id || initValue || DEFAULT_COUNTRY_CODE;
  const [country, onCountryChange] = useState(() => {
    const selectedCountry = getCountry(parsedInitValue);
    return selectedCountry ? selectedCountry : null;
  });
  const [phone, setPhone] = useState(initialPhone);
  const [errorMsg, setError] = useState('');

  const onPhoneChange = (value: number) => {
    setPhone(value as number);
    const toCheck = String(value);
    if (toCheck.length < 6 || toCheck.length > 14) {
      setError('');
    }
  };

  const onPhoneBlur = () => {
    const val = String(phone);
    if (val.length < 6 || val.length > 14) {
      setError('The phone number seems invalid');
    } else {
      setError('');
    }
  };

  const updatePhoneInput = (phoneNum: number, countryObj: string) => {
    onPhoneChange(phoneNum);
    onCountryChange(getCountry(countryObj));
  };

  useEffect(() => {
    const processData = (data: Array<CountryResObj>) => {
      let defaultCountry;
      countryData.list = data.map(list => {
        const {
          id,
          dialing_prefix: dialingPrefix,
          country: countryName,
          iso2_code: iso2Code,
        } = list;
        const countryObj = {
          label: `${getFlag(iso2Code)} ${countryName} (${dialingPrefix})`,
          selectedLabel: `${getFlag(iso2Code)} (${dialingPrefix})`,
          value: dialingPrefix,
          id: id,
        };
        if (dialingPrefix === parsedInitValue || id === parsedInitValue) {
          defaultCountry = countryObj;
        }
        return countryObj;
      });
      onCountryChange(defaultCountry);
    };

    if (initCountryData.length) {
      processData(initCountryData);
    } else if (countryData.list.length === 0) {
      getCountriesList()
        .then((res: GetCountriesListType) => {
          const { data = null, success = false } = res;
          if (success) {
            if (data) {
              processData(data);
            }
          }
        })
        .catch(error => {
          console.error('getCountriesList', error);
        });
    }
  }, []);

  return {
    country,
    onCountryChange,
    phone,
    onPhoneChange,
    errorMsg,
    onPhoneBlur,
    updatePhoneInput,
  };
}

const PhoneInput = forwardRef(PhoneInputComp);

function ControlledPhoneInput(props) {
  const { name, control, defaultValue, isRequired, ...additional } = props;

  const validations: RegisterOptions = {
    required: {
      value: isRequired,
      message: 'This field is required',
    },
  };
  if (isRequired) validations.validate = phoneNumberValidator;

  const {
    field: { ref, value, onChange },
  } = useController({
    name,
    control,
    defaultValue,
    rules: validations,
  });

  const hookProps = usePhoneInput(value?.phone, value?.country);
  const onPhoneChange = newValue => {
    hookProps.onPhoneChange(newValue);
    onChange({ phone: newValue, country: hookProps.country?.value });
  };

  const onCountryChange = newValue => {
    hookProps.onCountryChange(newValue);
    onChange({ phone: hookProps.phone, country: newValue?.value });
  };

  return (
    <PhoneInput
      ref={ref}
      {...hookProps}
      onPhoneChange={onPhoneChange}
      onCountryChange={onCountryChange}
      {...additional}
    />
  );
}

export { ControlledPhoneInput };

export default PhoneInput;
