export const getCancellationPolicyQuery = `
rpcGetCancellationPolicyResponse {
  ingoHotelId
  success
  message
  cancellationPolicies {
    code
    policyName
    policyType
    vendor
    isRecommendedForRateplan
    isActive
    templateId
    policyRuleCode
    policyRules {
      chargeValue
      chargeType
      day
      time
    }
    applicableRateplans{
      ingoRateplanId
    }
  }
}
`;

export const createRatePlanQuery = `
query IngoCreateRatePlan($endpointRequestData: EndpointRequestData) {  
  createRateplanAndCancellationPolicy(endpointRequestData: $endpointRequestData) {        
    success   
    message
    data
    error {
      errorCode
      errorMessage
      errorType    
    }   
  }
}
`;

export const CancellationPoliciesMetaQuery = `
rpcGetCancellationPolicyMetaDataResponse{
  ingoHotelId
  success
  message
  errorCode
  templatePolicies{
    templateId
    policyName
    priority
    isRecommended
    isActiveForHotel
    isDisabled
    disabledReason
    policyRuleCode
    policyRules {
        chargeValue
        chargeType
        day
        time
    }
  }
}
`;
