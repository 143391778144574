import React from 'react';
import {
  LegacyFormElement as FormElement,
  LegacyButton as Button,
  Calendar,
  Typography,
} from 'connect-web-ui';
import { getFormattedDate } from 'utilities/DateUtils';
import { displayDateFormatWithComma } from 'modules/promotions-and-coupons/PnC.constants';
import { ChevronDown, ChevronUp } from 'assets/common';

interface BookingDatesProps {
  visibility;
  toggleVisibility: (section: string) => void;
  bookingStartCalendarProps;
  bookingEndCalendarProps;
  isEdit: boolean;
  STRINGS;
  COMMON_STRINGS;
  toClearBookingEndDate;
  handleClearBookingEndDate: () => void;
  stayStartDate;
  maxDate;
  today;
  stayEndDate;
}

const BookingDates: React.FC<BookingDatesProps> = ({
  visibility,
  toggleVisibility,
  bookingStartCalendarProps,
  bookingEndCalendarProps,
  STRINGS,
  COMMON_STRINGS,
  toClearBookingEndDate,
  handleClearBookingEndDate,
  stayStartDate,
  maxDate,
  today,
  stayEndDate,
}) => {
  const bookingStartDate = getFormattedDate(
    bookingStartCalendarProps?.calendarProps?.selectedDays,
    displayDateFormatWithComma,
  );
  const bookingEndDate = getFormattedDate(
    bookingEndCalendarProps?.calendarProps?.selectedDays,
    displayDateFormatWithComma,
  );

  const bookingDatesMessage = !toClearBookingEndDate
    ? `${STRINGS.BOOKING_DATES_FROM} ${bookingStartDate} ${STRINGS.STAY_DATES_ONWARDS}`
    : `${STRINGS.BOOKING_DATES_FROM} ${bookingStartDate} ${STRINGS.STAY_DATES_TO} ${bookingEndDate}`;
  return (
    <>
      <div className="flex justify-between mt-[10px]">
        <Typography
          variant="subHeadingMedium"
          className="mr-4 flex items-center"
        >
          {STRINGS.BOOKING_DATES}
        </Typography>
        <Button
          onClick={() => toggleVisibility('bookingDates')}
          variant="text"
          className="!capitalize !text-size-14"
        >
          {visibility.bookingDates
            ? COMMON_STRINGS.BUTTONS.HIDE
            : COMMON_STRINGS.BUTTONS.SHOW}
          {visibility.bookingDates ? (
            <ChevronUp fill="#008CFF" className="ml-1" width={20} height={20} />
          ) : (
            <ChevronDown
              fill="#008CFF"
              className="ml-1"
              width={20}
              height={20}
            />
          )}
        </Button>
      </div>
      <Typography variant="body1" className="mb-4">
        {bookingDatesMessage}
      </Typography>
      {visibility.bookingDates && (
        <div>
          <div className="flex">
            <FormElement label={STRINGS.APPLICABLE_BOOKING_START}>
              <Calendar
                type="input"
                {...bookingStartCalendarProps}
                disabledAfter={(stayStartDate || maxDate) as Date}
              />
            </FormElement>
            <FormElement
              label={STRINGS.APPLICABLE_BOOKING_END}
              className="!mr-[0px]"
            >
              <Calendar
                type="input"
                {...bookingEndCalendarProps}
                disabledBefore={(stayStartDate || today) as Date}
                disabledAfter={(stayEndDate || maxDate) as Date}
              />
            </FormElement>
          </div>
          {toClearBookingEndDate && (
            <div className="w-[100%] flex justify-end pr-4 mt-[-14px]">
              <Button
                variant="text"
                noPadding
                onClick={handleClearBookingEndDate}
                className="!capitalize"
              >
                {STRINGS.CLEAR_END_DATE}
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default BookingDates;
