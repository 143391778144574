import { useState, useEffect, useCallback, useMemo } from 'react';
import { displayDateFormat } from '../../../constants';
import { getFormattedDate } from '../../../utilities/DateUtils';
import { showToast } from 'utilities/Utils';
import { tabList } from '../HelpCentre.constant';
import { actionTypes } from '../HelpCentre.reducer';
import { filterTicket } from '../HelpCentreGraphClient';

import { legacyPushToGTM, GTM_CATEGORIES } from 'utilities/gtm';
import { BackArrowIcon } from 'assets/old_shared';
import { Tabs } from 'connect-web-ui';

const getComments = otherinfo => {
  try {
    const info = JSON.parse(otherinfo);
    return info.comments;
  } catch (e) {
    return '';
  }
};

const RenderTicket = props => {
  const {
    issue: { id, createdat, closedat, secondarytype, ternarytype, otherinfo },
    STRINGS,
  } = props;
  const comments = getComments(otherinfo);
  return (
    <div className="view-ticket-card">
      <div>
        <span className="fb">{STRINGS.VIEW_TICKETS.REQUEST_NO}</span>
        <span>{id}</span>
      </div>
      <div>
        <span className="fb">{STRINGS.VIEW_TICKETS.RAISED_DATE}</span>
        <span>{getFormattedDate(createdat, displayDateFormat)}</span>
      </div>
      {!!closedat && (
        <div>
          <span className="fb">{STRINGS.VIEW_TICKETS.RESOLVED_DATE}</span>
          <span>{getFormattedDate(closedat, displayDateFormat)}</span>
        </div>
      )}
      <div>
        <span className="fb">{STRINGS.VIEW_TICKETS.ISSUE_CATEGORY}</span>
        <span>
          {secondarytype} {'>'} {ternarytype}
        </span>
      </div>
      {!!comments && (
        <div>
          <span className="fb">{STRINGS.VIEW_TICKETS.ISSUE_DETAIL}</span>
          <span>{comments}</span>
        </div>
      )}
    </div>
  );
};

export const ViewTickets = props => {
  const { state, dispatch, customerId, setMainScreen, hotelCode, STRINGS } =
    props;

  const count = useMemo(
    () => ({
      pending: state.tickets.pending.length,
      resolved: state.tickets.resolved.length,
    }),
    [state.tickets],
  );

  const tabs = tabList(count, STRINGS);
  const [activeTab, setActiveTab] = useState(tabs[0].value);

  const tickets =
    activeTab === 'resolved' ? state.tickets.resolved : state.tickets.pending;
  const isTicketsAvailable =
    state.tickets.resolved.length > 0 || state.tickets.pending.length > 0;

  const handleResponse = useCallback((response, callBackdispatch) => {
    const pending = response
      .filter(issues => issues.status <= 2)
      .sort((a, b) => b.createdat - a.createdat);
    const resolved = response
      .filter(issues => issues.status === 3)
      .sort((a, b) => b.createdat - a.createdat);
    callBackdispatch({
      type: actionTypes.UPDATE_STATE,
      data: {
        tickets: {
          pending,
          resolved,
        },
        hardRefresh: {
          ...state.hardRefresh,
          view_ticket: false,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (state.hardRefresh.view_ticket) {
      filterTicket(customerId, hotelCode)
        .then(res => {
          const { errorDTO, response } = res.data.filterServiceTicket;
          if (errorDTO) showToast(errorDTO, 'error');
          else handleResponse(response, dispatch);
        })
        .catch(error => {
          console.error('Error in filterTicket', error);
        });
    }
  }, [state.hardRefresh.view_ticket]);

  const openTabClicked = type => {
    legacyPushToGTM({
      eventCategory: GTM_CATEGORIES.HELP_CENTER,
      eventAction: 'my_issues',
      eventLabel:
        type === 'pending' ? 'toggle_pending_issues' : 'toggle_resolved_issues',
    });
    setActiveTab(type);
  };

  return (
    <div className="view-ticket-container">
      <div className="header">
        <div className="sr-flex-row">
          <img
            onClick={() => setMainScreen(true)}
            src={BackArrowIcon}
            alt="arrow-left"
            className="marginR10"
          />
          <div className="font18 fb">
            {STRINGS.HELP_CENTRE_MAIN_SCREEN.TITLE.ISSUE_SCREEN}
          </div>
        </div>
      </div>
      {isTicketsAvailable ? (
        <>
          <Tabs
            className="marginTB10"
            tabs={tabs}
            activeTab={activeTab}
            onChange={openTabClicked}
          />
          <div className="render_tickets">
            {tickets.length > 0 ? (
              tickets.map((issue, idx) => (
                <RenderTicket
                  key={`render_ticket_${idx}`}
                  issue={issue}
                  STRINGS={STRINGS}
                />
              ))
            ) : (
              <div className="empty-message">
                <span>
                  {activeTab === 'resolved'
                    ? STRINGS.EMPTY_MESSAGE.RESOLVED
                    : STRINGS.EMPTY_MESSAGE.PENDING}{' '}
                </span>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="render_tickets">
          <div className="empty-message">
            <span>{STRINGS.EMPTY_MESSAGE.NOT_REPORTED_ANY_ISSUES}</span>
          </div>
        </div>
      )}
    </div>
  );
};
