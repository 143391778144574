import { useCallback, useEffect, useRef, useState, useContext } from 'react';
import { LinearProgress, Box, makeStyles } from 'connect-web-ui/mui';
import { Carousel, Modal } from 'connect-web-ui';
import { AppEvents, EventNames } from 'utilities/EventBus';
import {
  uploadImage,
  uploadVideo,
} from 'modules/host-web/modules/listing/media/MediaGraphClient';
import { AppContext } from 'utilities/Context';
import { spaceEnum } from 'modules/host-web/modules/listing/media/media.constants';
import { showMessage, getUrlFromFileObj, getFileSize } from 'utilities/Utils';
import { getDateDifference } from 'utilities/DateUtils';
import { useLangStrings } from 'utilities/CustomHooks';
import { GTM_CATEGORIES, legacyPushToGTM } from 'utilities/gtm';

const useStyles = makeStyles(theme => ({
  mediaUploadRoot: {
    width: '364px',
    height: '95px',
    position: 'absolute',
    right: '109px',
    bottom: '0',
    boxShadow: '0px -1px 5px rgba(1, 16, 34, 0.1)',
    background: '#3543BF',
    padding: '16px 24px 14px',
    color: 'white',
    zIndex: 1,
  },
  linearBarCls: {
    background: theme.palette.common.white,
  },
  modalMediaCls: {
    height: '236px',
    borderRadius: '4px',
  },
}));

const countType = {
  SUCCESS: 'success',
  FAILURE: 'failure',
};

const initialCountState = {
  [countType.SUCCESS]: 0,
  [countType.FAILURE]: 0,
};

const callGTMEvent = (
  label,
  pushtoGtmFun = legacyPushToGTM,
  imageUploadLabel = '',
) => {
  const params = {
    eventCategory: GTM_CATEGORIES.LISTING,
    eventLabel: label,
    eventAction: 'listing_info|photos_and_videos|add_media',
  };
  pushtoGtmFun(params);
  if (imageUploadLabel) {
    params.eventCategory = GTM_CATEGORIES.LISTING_IMAGE_UPLOAD;
    params.eventLabel = imageUploadLabel;
    params.eventAction = 'image_upload';
    pushtoGtmFun(params);
  }
};

const RejectedMediaPopup = props => {
  const {
    showRejectedMediaPopup,
    onRejectedMediaPopupClose,
    classes,
    COMMON,
    STRINGS,
    pushEventToGtm,
  } = props;

  const rejectedCount = showRejectedMediaPopup?.length;

  useEffect(() => {
    const pipeSepratedRejectionMsg = showRejectedMediaPopup
      ?.map(val => val.rejectionMessage)
      ?.join('|');

    callGTMEvent(
      `error_popup_${pipeSepratedRejectionMsg}__${rejectedCount}`,
      pushEventToGtm,
    );
  }, []);

  return (
    <Modal
      isOpen
      size="custom"
      header={{
        title: `${rejectedCount} media(s) failed during uploading`,
      }}
      onClose={onRejectedMediaPopupClose}
      footer={{
        secondaryBtn: {
          text: COMMON.BUTTONS.CANCEL,
          onClick: onRejectedMediaPopupClose,
          'data-test-id': 'uploading-failed-modal-cancel-button',
        },
      }}
      customClasses={{
        footer: {
          secondaryBtn: '!w-full',
        },
      }}
    >
      <Box width="352px">
        <Carousel
          customClasses={{ indicatorContainer: '!mt-2' }}
          data-test-id="uploading-failed-modal"
        >
          {showRejectedMediaPopup.map(val => {
            const isPhoto = /^image\//.test(val.file.type);
            return (
              <Box width="100%" overflow="hidden">
                <Box display="flex" justifyContent="center" maxHeight="236px">
                  {isPhoto ? (
                    <img
                      className={classes.modalMediaCls}
                      src={getUrlFromFileObj(val.file)}
                      alt=""
                    />
                  ) : (
                    <video
                      className={classes.modalMediaCls}
                      src={getUrlFromFileObj(val.file)}
                    />
                  )}
                </Box>
                <Box mt={1.5} data-test-id="rejection-reason">
                  <Box component="span" fontWeight="bold">
                    {STRINGS.REASON}
                  </Box>{' '}
                  - {val.rejectionMessage}
                </Box>
              </Box>
            );
          })}
        </Carousel>
      </Box>
    </Modal>
  );
};

export default function MediaUploadProgress() {
  const classes = useStyles();
  const [{ MEDIA: STRINGS }, COMMON] = useLangStrings<'HostWeb'>('HostWeb');
  const { showGlobalLoader } = useContext(AppContext);
  const [showProgress, setShowProgress] = useState(false);
  const [countData, setCountData] = useState(initialCountState);
  const [showRejectedMediaPopup, setShowRejectedMediaPopup] = useState(null);
  const totalCountRef = useRef(null);
  const rejectedMediaDataRef = useRef([]);
  const pushEventToGtmRef = useRef(legacyPushToGTM);

  const progress = totalCountRef.current
    ? ((countData[countType.SUCCESS] + countData[countType.FAILURE]) /
        totalCountRef.current) *
      100
    : 0;

  const collectAllRejectedMedias = (
    file,
    rejectionMessage = 'Network failure',
  ) => {
    rejectedMediaDataRef.current = [
      ...rejectedMediaDataRef.current,
      { file, rejectionMessage },
    ];
  };

  const onRejectedMediaPopupClose = () => {
    rejectedMediaDataRef.current = [];
    setShowRejectedMediaPopup(null);
  };

  const incrementCount = type => {
    setCountData(_countData => {
      return { ..._countData, [type]: _countData[type] + 1 };
    });
  };

  const onUploadMedia = useCallback(data => {
    const {
      uploadedFiles,
      uploadData,
      hotelcode,
      uploadImgsObj,
      pushEventToGtm,
    } = data;
    if (pushEventToGtm) {
      pushEventToGtmRef.current = pushEventToGtm;
    }
    totalCountRef.current = uploadedFiles.length;
    setShowProgress(true);
    showGlobalLoader(true);
    const startTime = new Date();
    uploadedFiles.forEach((val, index) => {
      const isMinOneSpaceSelected =
        uploadData[index]?.collectionValue?.length > 0;
      const payload = {
        type: isMinOneSpaceSelected ? spaceEnum.SPACE : spaceEnum.HOTEL,
        description: uploadData[index]?.caption,
        caption: uploadData[index]?.caption,
        tags: uploadData[index]?.tagsValue?.map(_val => _val.value).join(','),
      };
      if (isMinOneSpaceSelected) {
        // @ts-ignore
        payload.type_ids = uploadData[index]?.collectionValue
          .map(_val => _val.value)
          .join(',');
      } else {
        // @ts-ignore
        payload.type_id = hotelcode;
      }

      const size = getFileSize(val.size);

      if (/^image\//.test(val.type)) {
        const img = uploadImgsObj[val.name];
        let gtmLabel = `${img.width}*${img.height}|${(
          img.width / img.height
        )?.toFixed(1)}|${size}|${val.type}|`;
        uploadImage(payload, val, isMinOneSpaceSelected)
          .then(res => {
            const endTime = new Date();
            const duration = getDateDifference(endTime, startTime, 's');
            gtmLabel += `${duration}s|`;
            if (res.success) {
              gtmLabel += 'sucess';
              incrementCount(countType.SUCCESS);
              AppEvents.emit(EventNames.UPDATE_MEDIADATA, {
                data: res.data,
                reloadMediaApi: false,
              });
              callGTMEvent(
                'upload__successful',
                pushEventToGtmRef.current,
                gtmLabel,
              );
            } else {
              gtmLabel += `error|${res.message}`;
              incrementCount(countType.FAILURE);
              callGTMEvent(
                'upload__error',
                pushEventToGtmRef.current,
                gtmLabel,
              );
              collectAllRejectedMedias(val, res.message);
            }
          })
          .catch(() => {
            const endTime = new Date();
            const duration = getDateDifference(endTime, startTime, 's');
            gtmLabel += `${duration}s|`;
            gtmLabel += 'error|upload__error';
            incrementCount(countType.FAILURE);
            callGTMEvent('upload__error', pushEventToGtmRef.current, gtmLabel);
            collectAllRejectedMedias(val);
          });
      } else {
        uploadVideo(payload, val, isMinOneSpaceSelected)
          .then(res => {
            if (res.success) {
              incrementCount(countType.SUCCESS);
              AppEvents.emit(EventNames.UPDATE_MEDIADATA, {
                data: res.data,
                reloadMediaApi: false,
              });
              callGTMEvent('upload__successful', pushEventToGtmRef.current);
            } else {
              incrementCount(countType.FAILURE);
              callGTMEvent('upload__error', pushEventToGtmRef.current);
              collectAllRejectedMedias(val, res.message);
            }
          })
          .catch(() => {
            incrementCount(countType.FAILURE);
            callGTMEvent('upload__error', pushEventToGtmRef.current);
            collectAllRejectedMedias(val);
          });
      }
    });
  }, []);

  useEffect(() => {
    if (!countData.success && !countData.failure) return;
    if (
      totalCountRef.current &&
      countData[countType.SUCCESS] + countData[countType.FAILURE] ===
        totalCountRef.current
    ) {
      setShowProgress(false);
      showGlobalLoader(false);
      let message = '';
      if (countData[countType.FAILURE] > 0) {
        setShowRejectedMediaPopup(() => rejectedMediaDataRef.current);
      }
      if (countData[countType.SUCCESS] > 0) {
        message = `${
          countData[countType.SUCCESS]
        } media(s) uploaded successfully. `;
        showMessage({
          message,
          type: 'success',
        });
      }
    }
  }, [countData]);

  useEffect(() => {
    if (!showProgress) {
      setCountData(initialCountState);
    }
  }, [showProgress]);

  useEffect(() => {
    AppEvents.on(EventNames.UPLOAD_MEDIA, onUploadMedia);
    return () => {
      AppEvents.off(EventNames.UPLOAD_MEDIA, onUploadMedia);
    };
  }, []);

  return (
    <>
      {showProgress && (
        <Box className={classes.mediaUploadRoot}>
          {totalCountRef.current > 0 && (
            <Box color="white" mb={1.5}>
              {countData[countType.SUCCESS] + countData[countType.FAILURE]}/
              {totalCountRef.current} media uploaded...
            </Box>
          )}
          <LinearProgress
            variant="determinate"
            value={progress}
            classes={{ bar: classes.linearBarCls }}
          />
        </Box>
      )}
      {showRejectedMediaPopup && (
        <RejectedMediaPopup
          showRejectedMediaPopup={showRejectedMediaPopup}
          onRejectedMediaPopupClose={onRejectedMediaPopupClose}
          classes={classes}
          COMMON={COMMON}
          STRINGS={STRINGS}
          pushEventToGtm={pushEventToGtmRef.current}
        />
      )}
    </>
  );
}
