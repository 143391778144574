export const getRTBPendingBookingRequests = () => {
  return `
      query RTBPersuasion($rawEndpointRequestData: RawEndpointsRequestData) {
        rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
          analyticsPersuasion {
            modelType1 {
              status
              message
              data {
                count
                total_opportunity_loss
                persuasions {
                  expiry_date
                  type
                  sub_type
                  type
                  data {
                    UUID
                    booking_amount
                    checkin
                    checkout
                    currency
                    cust_id
                    expiry_time
                    guest_name
                    hotel_code
                    vendor
                    pro_booking_id
                    vendor_booking_id
                  }
                }
              }
            }
          }
        }
      }
    `;
};

export const groupRatesPersuaionQuery = `query RawResponse($endpointRequests: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $endpointRequests) {
    analyticsPersuasion {
      modelType1 {
        status
        message
        data {
          persuasions {
            consumers {
              web {
                persuasion_title
                message
              }
            }
          }
        }
      }
    }
  }
}`;

export const getPersuasionsQuery = `
  query PerformanceDashboard(
      $endpointRequestData: EndpointRequestData,
      $grpcEndpointRequestData: GRPCEndpointRequestData,
      $rawEndpointsRequestData: RawEndpointsRequestData
    ) {
      altAccoActivityAndPerformanceDashboard(
        endpointRequestData: $endpointRequestData,
        grpcEndpointRequestData: $grpcEndpointRequestData
      ) {
        getMissingContent{
          status
          message
          data
        }
      }
      rawEndpoint(rawEndpointRequestData: $rawEndpointsRequestData,) {
        contentScoreResponses{
          modelType1{
            status
            status_code
            message
            data{
              doc_count
              group_count
              docs{
                totalContentScore
              }
            }
          }
        }
      }
    }`;

export function getPersuasionContentVariables(hotelCode, isWeb) {
  return {
    endpointRequestData: {
      hotelPersuasionBulkRequests: {
        getMissingContent: {
          endpointId: 32,
          body: {
            type: 'content',
            sub_type: 'missing',
            entity_id: [hotelCode],
            offset: '0',
            size: isWeb ? '100' : '250',
            consumers: isWeb ? '["web"]' : '["hostapp"]',
            action: 'retrieval',
          },
        },
      },
    },
    rawEndpointsRequestData: {
      contentScore: {
        modelType: 'modelType1',
        endPointId: 1,
        body: {
          pipeline: 'contentscore_v2',
          fields: ['totalContentScore'],
          include: {
            hotelcode: [hotelCode],
          },
          sort: {
            modifiedOn: 'desc',
          },
          size: 1,
        },
      },
    },
  };
}

//External Cal Sync
export const SUMMARY_API = `
mutation FetchCalSummary($rawEndpointRequestData: RawEndpointsRequestData) {
     rawEndpoint(endpointRequestData: $rawEndpointRequestData) {
       fetchCalSummary {
        modelType1 {message
             code
             success
             data
            } 
        }}
    }
`;
// Instagram SoucialMedia
export const getSocialMediaMetadataQuery = `
query SocialMediaMetadata($rawEndpointRequestData: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
    socialMediaMetadata {
      modelType1 {
        status
        platforms {
          platform
          client_id
          scope
          response_type
          auth_url
          redirect_url
        }
        errors {
          code
          message
        }
      }
    }
  }
}`;

export const getSocialMediaDetailsQuery = `
  query SocialMediaDetails($rawEndpointRequestData: RawEndpointsRequestData) {
    rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
      socialMediaDetails {
        modelType1 {
          status
          accesses {
            account_id
            token_status
            expires_in
            user_id
          }
          errors {
            code
            message
          }
        }
      }
    }
  }`;

export const createSocialMediaConnectionQuery = `mutation CreateSocialMediaConnection($rawEndpointRequestData: RawEndpointsRequestData) {
    rawEndpoint(endpointRequestData: $rawEndpointRequestData) {
      createSocialMediaConnection {
        modelType1 {
          status
          errors {
            code
            message
          }
        }
      }
    }
  }`;

export const updateSocialMediaConnectionQuery = `mutation UpdateSocialMediaConnection($rawEndpointRequestData: RawEndpointsRequestData) {
  rawEndpoint(endpointRequestData: $rawEndpointRequestData) {
    updateSocialMediaConnection {
      modelType1 {
        status
        errors {
          code
          message
        }
      }
    }
  }
}`;

export const getStaffInfoQuery = `query StaffInfo($rawEndpointRequestData: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
    staffInfo {
      modelType1 {
        contractbdo {
          mobile
          name
          email
        }
      }
    }
  }
}`;

export function getStaffInfoVariable(hotelCode) {
  return {
    rawEndpointRequestData: {
      staffInfo: {
        endPointId: 99,
        modelType: 'modelType1',
        urlParams: {
          hotelCode,
        },
      },
    },
  };
}

export function updateSocialMediaConnectionVariables(params) {
  return {
    rawEndpointRequestData: {
      updateSocialMediaConnection: {
        endPointId: 99,
        modelType: 'modelType1',
        body: {
          ...params,
          account_type: 'HOTEL',
        },
      },
    },
  };
}

export function createSocialMediaConnectionVariables(params) {
  return {
    rawEndpointRequestData: {
      createSocialMediaConnection: {
        endPointId: 99,
        modelType: 'modelType1',
        body: {
          ...params,
          account_type: 'HOTEL',
          permissions: 'user_profile,user_media',
        },
      },
    },
  };
}

export function getSocialMediaDetailsVariables(mmtHotelId, platform) {
  return {
    rawEndpointRequestData: {
      socialMediaDetails: {
        endPointId: 1,
        modelType: 'modelType1',
        queryParams: {
          platform,
          account_type: 'HOTEL',
          account_id: [mmtHotelId],
        },
      },
    },
  };
}

export function getSocialMediaMetadataVariables(platform) {
  return {
    rawEndpointRequestData: {
      socialMediaMetadata: {
        endPointId: 1,
        modelType: 'modelType1',
        queryParams: {
          platform,
        },
      },
    },
  };
}

export const GET_IP_USER_ELIGIBILTY_QUERY = `
query IPSUserEligibilityResponse($endpointRequestData: EndpointRequestData) {  
  fetchIpsUserEligibility(endpointRequestData: $endpointRequestData) { 
    isIpsUserEligible 
   }
  }
`;
export const GET_CONTENT_SCORE_QUERY = `
mutation GetContentScore($AnalyticsV1Persuasions: RawEndpointsRequestData) { 
  contentScorePersuasions: rawEndpoint(    endpointRequestData: $AnalyticsV1Persuasions\n  ) {    
    contentScorePersuasions {      
      modelType1 {        
        data       
        message       
        status                     
        }        
      }     
    } 
    }
`;
