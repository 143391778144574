import type { PaletteColorsType } from 'connect-web-ui/types';

export const OMNITURE_VARIABLES = {
  PAGE_NAME: 'prebuy-ledger',
};

export enum PENDING_ORDER_ACTION {
  APPROVE = 'approveorder',
  REJECT = 'rejectorder',
}

export enum ORDER_STATUS {
  PENDING = 'pending',
  EXTENSION_REQUESTED = 'extensionRequested',
  APPROVED = 'approved',
  EXTENSION_APPROVED = 'extensionApproved',
  EXPIRED = 'expired',
  REJECTED = 'rejected',
  EXTENSION_REJECTED = 'extensionRejected',
  ACTIVE = 'active',
  COMPLETED = 'completed',
}

export enum ORDER_UPDATE_STATUS {
  SUCCESS = 'success',
  FAILED = 'failed',
}

export enum ORDER_UPDATE_SOURCE {
  EXTRANET = 'extranet',
  EMAIL = 'email',
}

export const ORDER_STATUS_TO_LABEL_MAP: Record<ORDER_STATUS, string> = {
  [ORDER_STATUS.PENDING]: 'Pending',
  [ORDER_STATUS.EXTENSION_REQUESTED]: 'Extension Requested',
  [ORDER_STATUS.APPROVED]: 'Approved',
  [ORDER_STATUS.EXTENSION_APPROVED]: 'Extension Approved',
  [ORDER_STATUS.EXPIRED]: 'Expired',
  [ORDER_STATUS.REJECTED]: 'Rejected',
  [ORDER_STATUS.EXTENSION_REJECTED]: 'Extension Rejected',
  [ORDER_STATUS.ACTIVE]: 'Active',
  [ORDER_STATUS.COMPLETED]: 'Completed',
};

export const ORDER_STATUS_TO_LABEL_COLOR_MAP: Record<
ORDER_STATUS,
PaletteColorsType
> = {
  [ORDER_STATUS.PENDING]: 'warning.main',
  [ORDER_STATUS.EXTENSION_APPROVED]: 'success.dark',
  [ORDER_STATUS.APPROVED]: 'success.dark',
  [ORDER_STATUS.EXTENSION_REQUESTED]: 'warning.main',
  [ORDER_STATUS.EXPIRED]: 'error.dark',
  [ORDER_STATUS.REJECTED]: 'error.dark',
  [ORDER_STATUS.EXTENSION_REJECTED]: 'error.dark',
  [ORDER_STATUS.ACTIVE]: 'success.dark',
  [ORDER_STATUS.COMPLETED]: 'success.dark',
};
