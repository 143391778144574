import { useContext } from 'react';
import { legacyPushToGTM, GTM_CATEGORIES } from 'utilities/gtm';
import { errorIllustration } from 'assets/old_shared';
import { AuthContext } from 'utilities/Context';
import ErrorHeader from 'app/ErrorHeader';

interface ErrorScreenProps {
  fetchFailed?: boolean;
  retryFn?: () => void;
  showHeader?: boolean;
}

const blueColor = { color: '#3543bf', cursor: 'pointer' };
const refreshBrowser = () => window.location.reload();
export default function ErrorScreen(props: ErrorScreenProps) {
  const {
    fetchFailed = false,
    retryFn = refreshBrowser,
    showHeader = false,
  } = props;
  const helpCenterUrl = window.helpCenterUrl;
  const {
    langStringsData: {
      langStrings: {
        App: { ERROR_SCREEN: STRINGS },
      },
    },
  } = useContext(AuthContext);

  const openHelpdesk = () => {
    legacyPushToGTM({
      eventCategory: GTM_CATEGORIES.PAGE_LOAD,
      eventAction: 'helpdesk',
    });
  };

  return (
    <>
      {showHeader && <ErrorHeader />}
      <div
        className="sr-card sr-flex-col flex-1 flex-centralized"
        style={{
          fontSize: '12px',
          lineHeight: '1.67',
          color: '#212121',
          minHeight: 'calc(100vh - 60px)',
        }}
      >
        <img
          alt="There was an error"
          src={errorIllustration}
          className="marginB16"
        />
        <p style={{ margin: 0, opacity: '0.5' }}>
          {fetchFailed ? STRINGS.COULDNT_LOAD : STRINGS.OOPS}
        </p>
        <p style={{ margin: 0 }}>
          <span style={blueColor} onClick={retryFn}>
            {STRINGS.ACTION[0]}
          </span>
          {STRINGS.ACTION[1]}
          <a
            rel="noreferrer noopener"
            target="extranet_help_center"
            href={helpCenterUrl}
            style={blueColor}
            onClick={openHelpdesk}
          >
            {STRINGS.ACTION[2]}
          </a>
          {STRINGS.ACTION[3]}
        </p>
      </div>
    </>
  );
}
