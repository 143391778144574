import { makeStyles } from 'connect-web-ui/mui';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiBreadcrumbs-li': {
      padding: '0px 2px 0px 2px',
    },
    '& [class*="MuiBreadcrumbs-separator"]': {
      marginLeft: '0px',
      marginRight: '4px',
    },
    '& .MuiLink-root': {
      display: 'flex',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px',
      letterSpacing: '0.02em',
      textAlign: 'left',
    },
  },
  separatorIcon: {
    width: '16px',
    height: '16px',
    fill: theme.palette.text.primary,
  },
  disabledLink: {
    color: theme.palette.text.primary,
    cursor: 'not-allowed',
    pointerEvents: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  link: {
    color: theme.palette.text.link,
  },
  activeLink: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0.02em',
    textAlign: 'left',
    color: theme.palette.text.primary,
    userSelect: 'none',
  },
  divider: {
    marginTop: '8px',
    borderColor: theme.palette.backgrounds.divider,
  },
}));

export { useStyles };
