import React from 'react';
import { DATA_TYPE_ENUM, SCREEN_LEVEL } from './../SideNav.constants';
import type { Badge } from '../SideNav.types';

type ISideNavBadgeIcon = {
  badgeItem: Badge;
  fromScreen?: SCREEN_LEVEL;
};

export const SideNavBadgeIcon = ({
  badgeItem,
  fromScreen = SCREEN_LEVEL.L1,
}: ISideNavBadgeIcon) => {
  const { data: badgeData, type: badgeType } = badgeItem?.data || {};

  if (!badgeData) return null;

  const cssConfig = {
    [SCREEN_LEVEL.L1]: {
      [DATA_TYPE_ENUM.TEXT]: 'absolute bottom-[14px] left-5',
      dot: 'absolute bottom-[14px] left-5',
      [DATA_TYPE_ENUM.COUNT]:
        'absolute bg-[#ec2127] px-[3px] py-0 rounded-full -right-2 -top-[3px] h-[14px]',
      tag: 'absolute -top-[8px] right-[-8px] [&>img]:h-[10px]',
    },
    [SCREEN_LEVEL.L2]: {
      [DATA_TYPE_ENUM.COUNT]: 'bg-[#ec2127] px-[3px] py-0 rounded-2xl h-[14px]',
      tag: 'pl-1 w-fit [&>img]:h-[10px]',
      dot: 'pl-1 w-fit h-[20px] [&>img]:h-[10px]',
    },
  };

  switch (badgeType) {
    case DATA_TYPE_ENUM.COUNT:
      return (
        <div className={cssConfig[fromScreen][badgeType]}>
          <p
            className={`!font-lato text-center text-wrap text-size-12 leading-[14px] !m-0 text-common-white ${
              badgeData.label?.length === 1 ? 'w-2' : ''
            }`}
          >
            {badgeData.label}
          </p>
        </div>
      );
    case DATA_TYPE_ENUM.IMAGE:
      return (
        <div className={cssConfig[fromScreen][badgeData.imageType ?? 'tag']}>
          <img src={badgeData.url} alt="Icon" />
        </div>
      );
    default:
      return <></>;
  }
};
