import { Button, Typography } from 'connect-web-ui';
import { Cards404, RefreshReverse } from '../images';
import { useLangStrings } from 'utilities/CustomHooks';
import { OMNITURE_CATEGORIES, pushToOmniture } from 'utilities/gtm';
import { useEffect } from 'react';

type RefreshCardProps = {
  fetchCardData: (cardName: string) => void;
  cardName: string;
};

const RefreshCard = ({ fetchCardData, cardName }: RefreshCardProps) => {
  const [COMMON_STRINGS] = useLangStrings<'Common'>('Common');

  const onRetryClick = () => {
    pushToOmniture({
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      cta: {
        name: 'retry',
        type: 'click',
        componentName: `performance_overview|${cardName}`,
        misc_id_3: 'today',
      },
    });
    fetchCardData(cardName);
  };

  useEffect(() => {
    pushToOmniture({
      event: OMNITURE_CATEGORIES.CTA_LOAD,
      cta: {
        name: 'retry',
        type: 'load',
        componentName: `performance_overview|${cardName}`,
        misc_id_3: 'today',
      },
    });
  }, []);
  return (
    <>
      <Typography variant="body2" className="mb-4">
        {cardName}
      </Typography>
      <div className="flex">
        <img
          src={Cards404}
          alt="Refresh"
          className="w-[97.663px] h-[69.13px] mr-6"
        />
        <div>
          <Typography variant="body2" className="mb-1 text-text-primary">
            {COMMON_STRINGS.LABELS.OOPS}
          </Typography>
          <Typography variant="sectionHeading" className="mb-4 !normal-case">
            {COMMON_STRINGS.MESSAGES.DATA_CURRENTLY_NOT_AVAILABLE}
          </Typography>
          <div
            className="flex items-center cursor-pointer w-fit"
            onClick={onRetryClick}
          >
            <Button variant="text" size="small">
              <RefreshReverse width={16} height={16} stroke="#008CFF" />
              {COMMON_STRINGS.MESSAGES.RETRY.replaceAll('!', '')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RefreshCard;
