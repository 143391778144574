export const VERIFY_USER_EMAIL = `query GetUserDetail($rawEndpointRequestData: RawEndpointsRequestData) {
  getUserDetail: rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
    getUserDetail {
      basicInfo {
        firstName
        lastName
      }
      error_code
      error_message
      error_type
      display_message
    }
  }
}
`;
