import React from 'react';
import { IngoLoader } from 'assets/old_shared';

export default function Loader({ className = '' }) {
  return (
    <span
      className={`full-size-centered ${className}`}
      style={{ minHeight: '200px' }}
    >
      <img src={IngoLoader} alt="loader" height={60} width={60} />
    </span>
  );
}
