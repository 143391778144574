import { useContext } from 'react';

import { AuthContext } from 'utilities/Context';
import { Common, LandingPage as LandingPageStrings } from 'interfaces/Strings';

function useStrings(): [LandingPageStrings, Common] {
  const {
    langStringsData: { langStrings },
  } = useContext(AuthContext);

  return [
    langStrings.LandingPage as LandingPageStrings,
    langStrings.Common as Common,
  ];
}

export default useStrings;
