import axios, { apiWrapper } from '../../../base/APIClient';
import { DASHBOARD, INSTAY, VDI } from '../../../base/Url';
import { getRequiredFields } from '../../instay-issues/InstayIssuesAPIClient';

export const getUnansweredReviews = (hotelId, params) => {
  const { GET_UNANSWERED_REVIEWS } = DASHBOARD;
  const url = GET_UNANSWERED_REVIEWS(hotelId, { params });
  return apiWrapper(axios.get(url));
};
export const fetchVDItoShowCard = hotelCode => {
  const { FETCH_VDI_TO_SHOW_CARD } = VDI;
  const url = FETCH_VDI_TO_SHOW_CARD(hotelCode);
  return apiWrapper(axios.get(url));
};

export const updateBookingStatus = (bookingId, status, reason) => {
  const { BOOKING_STATUS_API_WITH_WEBHOOK } = DASHBOARD;
  const url = BOOKING_STATUS_API_WITH_WEBHOOK(bookingId);
  const data = { status, reason, source_key: 2 };
  const params = { bookingId };
  return apiWrapper(axios.put(url, data, { params, useLoader: true }));
};
export const updatePersuasions = (data, hotelId) => {
  const { UPDATE_PERSUASIONS } = DASHBOARD;
  const url = UPDATE_PERSUASIONS(hotelId);
  return apiWrapper(axios.post(url, data, { useLoader: true }));
};

export const downloadApp = phoneNumber => {
  const { DOWNLOAD_APP_LINK } = DASHBOARD;
  const data = {
    contact_address: phoneNumber,
    contact_type: 'phone',
    flow: 'download_app',
  };
  return apiWrapper(axios.post(DOWNLOAD_APP_LINK, data));
};

export const getInstayIssues = (
  hotelCode,
  status,
  { showLoader = true } = {},
) => {
  const url = INSTAY.GET_INSTAY_ISSUES(hotelCode);

  const data = {
    status: JSON.stringify(status) || undefined,
  };

  return apiWrapper(
    axios.get(url, { params: data, useLoader: showLoader }),
  ).then(res => {
    const count = res.count;
    const results = [];
    res.results.forEach(issue => {
      results.push(getRequiredFields(issue));
    });
    return { count: count, results: results };
  });
};

export const updateInstayIssues = (
  hotelCode,
  complaint_id,
  status,
  description,
) => {
  const url = INSTAY.UPDATE_INSTAY_ISSUES(hotelCode);
  const data = {
    complaint_id: complaint_id,
    status: status,
    description: description,
  };
  return apiWrapper(axios.post(url, data, { useLoader: true }));
};

export const fetchPendingRefundRequest = (
  hotelCode,
  { showLoader = true } = {},
) => {
  const { FETCH_BOOKINGS: url } = DASHBOARD;
  const params = {
    format: 'json',
    bookingvendor: 'all',
    hotelcode: hotelCode,
    action: 'waiver',
    fetch_customer_details: false,
    waiver_filter: 'pending',
  };

  return apiWrapper(axios.get(url, { params, useLoader: showLoader }));
};

export const getCountriesList = () => {
  const { GET_COUNTRIES_LIST } = DASHBOARD;
  return apiWrapper(axios.get(GET_COUNTRIES_LIST, { useLoader: true }));
};

export const getBankPersuasions = hotelCode => {
  const { GET_BANK_PERSUASION } = DASHBOARD;
  return apiWrapper(
    axios.get(GET_BANK_PERSUASION(hotelCode), { useLoader: false }),
  ).then(res => res.data);
};
