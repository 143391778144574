import { ConnectLogo } from 'assets/modules/landing';
import { colors, typography } from 'style/zeplin-token-styledComponents';
import styled from 'styled-components';

export const FooterStyled = styled.footer`
  background-color: ${colors.grayMediumEmphasis};
  padding: 42px 0 !important;
`;

export const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 42px;
  border-bottom: 1px ${colors.grayScreen} solid;
`;

export const Logo = styled(ConnectLogo)`
  width: 166px;
  flex-shrink: 0;
`;

export const FooterTopRight = styled.div`
  p {
    ${typography.titleRegular};
    color: #d1d5db;
  }

  ul {
    display: flex;
    gap: 16px;
    margin-top: 16px;

    li a {
      display: inline-flex;
      width: 135px;
      flex-shrink: 0;
    }
  }
`;

export const FooterBody = styled.div`
  padding-top: 42px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
`;
