import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLangStrings } from '../../utilities/CustomHooks';
import { clearQueryParams } from 'utilities/Utils';
import * as BlockedWeb from 'assets/modules/blocked-on-web';
import { appStoreIcon, googlPlayIcon } from 'assets/modules/app';

import AppDownloadPopUp from '../dashboard/footer/AppDownloadPopUp';

import {
  legacyPushToGTM,
  GTM_CATEGORIES,
  pushToOmniture,
  OMNITURE_CATEGORIES,
} from 'utilities/gtm';

import './BlockedWeb.scss';
import '../dashboard/footer/Footer.scss';

export const BlockedWebMain = ({ isIngoExpressBlocked }) => {
  const [DASHBOARD_STRINGS, COMMON_STRINGS] = useLangStrings('Dashboard');
  const [{ BLOCKED_SCREEN: BLOCKED_WEB_STRINGS }] = useLangStrings('App');
  const { pathname, search } = useLocation();
  const [showAppDownloadPopUp, setAppDownloadPopUp] = useState(() => {
    const hasShowDownload = clearQueryParams(
      pathname,
      search,
      'app_download_link',
    );
    return hasShowDownload === 'true';
  });

  const pushGAevent = label => {
    setAppDownloadPopUp(true);
    legacyPushToGTM({
      eventCategory: GTM_CATEGORIES.DASHBOARD,
      eventAction: 'new_dashboard_footer',
      eventLabel: label,
    });
  };

  useEffect(() => {
    pushToOmniture({
      event: OMNITURE_CATEGORIES.PAGE_VIEW,
      loadedComponents: 'app_onboarded_blocker',
    });
    pushToOmniture({
      event: OMNITURE_CATEGORIES.CTA_LOAD,
      pageType: 'Blocker Page',
      cta: {
        name: 'blocker_load',
        type: 'load',
        componentName: 'app_onboarded_property',
      },
    });
  }, []);

  return (
    <div className="sr-container whiteBg blocked-on-web-container">
      <div className="sr-flex-col sr-justify-center">
        <div className="sr-flex-row sr-justify-center blocked-main-illustration">
          <img
            className="marginT25"
            style={{ height: '40vh' }}
            src={
              isIngoExpressBlocked
                ? BlockedWeb.INGO_EXPRESS
                : BlockedWeb.HOST_APP
            }
            alt="blocked-main"
          />
        </div>
        <div className="sr-flex-row sr-justify-center fw500 title">
          <div className="font21">
            {isIngoExpressBlocked
              ? BLOCKED_WEB_STRINGS.INGO_EXPRESS_TITLE
              : BLOCKED_WEB_STRINGS.HOST_APP_TITLE}
          </div>
        </div>
        <div className="sr-flex-row sr-justify-center app-container">
          <div>
            <img
              src={BlockedWeb.QR}
              alt="blocked-QR-code"
              style={{ width: '89px', height: '89px' }}
            />
          </div>
          <div className="sr-flex-col fw500">
            <div className="font14 padB5 marginL30">
              {BLOCKED_WEB_STRINGS.VISIT_INFO}
            </div>
            <div className="font12 grey2 padB10 marginL30">
              {BLOCKED_WEB_STRINGS.QR_INFO}
            </div>
            <div className="marginL30">
              <div className="store-icon">
                <img
                  alt="googlePlayIcon"
                  className="sr-cursor marginR20"
                  src={googlPlayIcon}
                  onClick={() => pushGAevent('google_play')}
                />
                <img
                  alt="appStoreIcon"
                  className="sr-cursor"
                  src={appStoreIcon}
                  onClick={() => pushGAevent('app_store')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAppDownloadPopUp && (
        <AppDownloadPopUp
          closeModal={() => {
            setAppDownloadPopUp(false);
          }}
          STRINGS={DASHBOARD_STRINGS}
          COMMON_STRINGS={COMMON_STRINGS}
        />
      )}
    </div>
  );
};
