import moment from 'moment';

import { legacyPushToGTM } from 'utilities/gtm';
import { TOP_CARDS_PARAMS_MAP_NEW } from '../constants/Dashboard';
import { isNullOrUndefined } from 'utilities/Utils';

export const debounce = (func, delay) => {
  let inDebounce;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

export const convertArrayToStringArray = arr => {
  return `["${arr.join('","')}"]`;
};

export const formatToYYYY_MM_DD = date => {
  let month = date.getMonth();
  if (month < 10) {
    month = `0${month}`;
  }
  return `${date.getFullYear()}-${month}-${date.getDate()}`;
};

export const formatDateString = (date, currentFormat, expectedFormat) => {
  const momentDate = moment(date, currentFormat).format(expectedFormat);
  return momentDate;
};

export const substractFromToday = (amt, type) => {
  amt = amt - 1;
  const now = moment();
  let pastDate = now.subtract(amt, type);
  if (type === 'months') {
    const date = pastDate.date() - 1;
    pastDate = pastDate.subtract(date, 'days');
  }
  return pastDate.format('YYYY-MM-DD');
};

export const numberPostfix = num => {
  switch (num) {
    case 1:
      return `${num}st`;
    case 2:
      return `${num}nd`;
    case 3:
      return `${num}rd`;
    default:
      return `${num}th`;
  }
};

export const INRFormat = nStr => {
  if (!nStr) {
    return 0;
  }
  nStr += '';
  const x = nStr.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? `.${x[1]}` : '';
  let rgx = /(\d+)(\d{3})/;
  let z = 0;
  const len = String(x1).length;
  let num = parseInt(len / 2 - 1);

  while (rgx.test(x1)) {
    if (z > 0) {
      x1 = x1.replace(rgx, '$1,$2');
    } else {
      x1 = x1.replace(rgx, '$1,$2');
      rgx = /(\d+)(\d{2})/;
    }
    z++;
    num--;
    if (num === 0) {
      break;
    }
  }
  return x1 + x2;
};

export const INTLFormat = (nStr, currency) => {
  if (currency === 'INR') {
    return new Intl.NumberFormat('en-IN').format(nStr);
  }
  return new Intl.NumberFormat('en-US').format(nStr);
};

export const pushEventToGtm = (
  action,
  label,
  customData = {},
  category = 'web_dashboard',
) => {
  const GTM_CATEGORY_DASHBOARD = category;

  const defaultGAObject = {
    eventCategory: GTM_CATEGORY_DASHBOARD,
    eventAction: action,
    eventLabel: label,
  };
  const gaObject = Object.assign(defaultGAObject, customData);
  legacyPushToGTM(gaObject);
};

export const actionTypes = {
  FETCHING: 'fetching',
  SUCCESS: 'success',
  FAILED: 'failed',
  RESET: 'reset',
  UPDATE: 'update',
};

export const actionCreators = {
  fetching: id => ({ type: actionTypes.FETCHING, id }),
  success: (id, payload) => ({ type: actionTypes.SUCCESS, id, payload }),
  failed: id => ({ type: actionTypes.FAILED, id }),
  reset: id => ({ type: actionTypes.RESET, id }),
  update: (id, payload) => ({ type: actionTypes.update, id, payload }),
};

export const extractData = (dataNode, key) => {
  const cardParams = TOP_CARDS_PARAMS_MAP_NEW[key];
  const { field, kpiKey } = cardParams;

  const newData = {
    shoutOut: isNullOrUndefined(dataNode[field].Today[kpiKey].value)
      ? 'NA'
      : dataNode[field].Today[kpiKey].value,
    last7Days: isNullOrUndefined(dataNode[field]['Last 7 Days'][kpiKey].value)
      ? 'NA'
      : dataNode[field]['Last 7 Days'][kpiKey].value,
    status: 'success',
  };

  return { ...newData };
};
