import { generateCorrelationKey, getValue } from 'utilities/Utils';
import { CREATE_UPDTAE_PROMOTION_QUERY } from './PromotionsGraphQuery';
import { doMutation } from 'base/GraphQLClient';
import { PromotionPayloadType } from './promotion.types';

type PromotionCreateUpdatedVariableType = {
  mmtId: string | number;
  payLoad: Partial<PromotionPayloadType>;
};

type CreateUpdatePromotionType = PromotionCreateUpdatedVariableType & {
  showGlobalLoader: boolean;
};

export const getPromotionCreateUpdateVariables = (
  props: PromotionCreateUpdatedVariableType,
) => {
  const { mmtId, payLoad } = props;
  return {
    rawEndpointRequestData: {
      rpcUpsertPromotion: {
        modelType: 'modelType1',
        body: {
          ...payLoad,
          correlationKey: generateCorrelationKey(),
          mmtId: mmtId,
        },
      },
    },
  };
};

export const createUpdatePromotion = (props: CreateUpdatePromotionType) => {
  const { mmtId, payLoad, showGlobalLoader } = props;
  return new Promise((resolve, reject) => {
    doMutation(
      CREATE_UPDTAE_PROMOTION_QUERY,
      getPromotionCreateUpdateVariables({ mmtId, payLoad }),
      {
        useLoader: showGlobalLoader,
        headers: { country: 'in', language: 'en', platform: 'Desktop' },
      },
    )
      .then(res => {
        const rpcUpdatePromotionData = getValue(
          res,
          'data.rawEndpoint.rpcUpsertPromotion',
        );
        if (rpcUpdatePromotionData?.success) {
          resolve(rpcUpdatePromotionData);
        } else {
          reject(rpcUpdatePromotionData);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};
