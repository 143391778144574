import React, { useReducer, useState, useContext, useEffect } from 'react';
import { Drawer, Modal } from 'connect-web-ui';
import { CreateTicket } from './components/CreateTicket';
import { actionTypes, initialState, reducer } from './HelpCentre.reducer';
import { checkErrors, helpCenterPushGTM } from './HelpCentre.utils';
import { useLangStrings } from '../../utilities/CustomHooks';
import { ViewTickets } from './components/ViewTickets';
import { createTicket } from './HelpCentreGraphClient';
import { AppContext } from '../../utilities/Context';
import { showToast } from 'utilities/Utils';
import { getFooterDetails } from 'modules/dashboard/footer/FooterAPIClient';

import './HelpCentre.scss';

function HelpCentre(props) {
  const { currentHotel, showHelpdeskDrawer, setShowHelpdeskDrawer } = props;
  const {
    user: { profile },
  } = useContext(AppContext);
  const {
    id: customerId,
    first_name: customerFname,
    last_name: customerLname,
    email: customerEmail,
    mobile: customerContact,
  } = profile;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [STRINGS] = useLangStrings('HelpCentre');
  const [isMainScreen, setMainScreen] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [contactDetails, setContactDetails] = useState({});

  const getContactDetails = () => {
    getFooterDetails(currentHotel.hotelcode)
      .then(resp => {
        setContactDetails(resp);
      })
      .catch(error => {
        console.error('Error in getFooterDetails', error);
      });
  };

  const handleSubmit = () => {
    const errors = checkErrors(state);
    if (Object.keys(errors).length === 0) {
      helpCenterPushGTM('issue_submit', state.selectedCategory);
      const variables = {
        customerId: String(customerId),
        customerFname,
        customerLname,
        customerEmail,
        customerContact,
        issueCategory: state.selectedCategory,
        issueSubCategory: state.selectedIssue,
        hotelCode: currentHotel && currentHotel.hotelcode,
        comments: state.issue_details,
      };
      createTicket(variables)
        .then(res => {
          const errorDTO = res.data.createServiceTicket.errorDTO;
          if (errorDTO) showToast(errorDTO, 'error');
          else {
            showToast(STRINGS.SUCCESS, 'success');
            dispatch({
              type: actionTypes.UPDATE_STATE,
              data: {
                hardRefresh: {
                  ...state.hardRefresh,
                  view_ticket: true,
                },
              },
            });
            dispatch({
              type: actionTypes.RESET,
            });
            setMainScreen(false);
          }
        })
        .catch(error => {
          console.error('Error in createTicket', error);
        });
    } else {
      dispatch({
        type: actionTypes.UPDATE_STATE,
        data: {
          errors: errors ? errors : null,
        },
      });
    }
  };

  const discardConfirm = () => {
    helpCenterPushGTM('discard_changes_popup', 'CTA');
    setShowModal(false);
  };

  const discardCancel = () => {
    helpCenterPushGTM('discard_changes_popup', 'CTA');
    helpCenterPushGTM('close');
    dispatch({
      type: actionTypes.RESET,
    });
    setShowHelpdeskDrawer(false);
  };

  const onDrawerClose = () => {
    setShowModal(true);
    helpCenterPushGTM('discard_changes_popup', 'load');
  };

  useEffect(() => {
    getContactDetails();
  }, [currentHotel.hotelcode]);

  return (
    <section className="greydark">
      <Drawer
        isOpen={showHelpdeskDrawer}
        footer={
          !isMainScreen
            ? {
              secondaryBtn: {
                onClick: onDrawerClose,
                text: 'Close',
              },
            }
            : null
        }
        onClose={onDrawerClose}
        customClasses={{
          children: 'help-centre-container',
        }}
      >
        {isMainScreen ? (
          <CreateTicket
            {...{
              state,
              dispatch,
              setMainScreen,
              STRINGS,
              handleSubmit,
              onDrawerClose,
              contactDetails,
            }}
          />
        ) : (
          <ViewTickets
            {...{
              state,
              dispatch,
              customerId,
              setMainScreen,
              hotelCode: currentHotel.hotelcode,
              STRINGS,
            }}
          />
        )}
      </Drawer>
      {showModal && (
        <Modal
          isOpen
          variant="confirm"
          size="small"
          header={{
            title: STRINGS.DISCARD_MODAL.TITLE,
            hideCross: true,
          }}
          footer={{
            primaryBtn: {
              text: STRINGS.DISCARD_MODAL.POSITIVE_TEXT,
              onClick: discardConfirm,
            },
            secondaryBtn: {
              text: STRINGS.DISCARD_MODAL.NEGATIVE_TEXT,
              onClick: discardCancel,
            },
          }}
        >
          <p className="marginB8">{STRINGS.DISCARD_MODAL.BODY}</p>
        </Modal>
      )}
    </section>
  );
}

export default HelpCentre;
