import { doMutation, doQuery } from 'base/GraphQLClient';
import { displayTimeFormatWithSeconds, apiDateFormat } from '../../constants';
import { getFormattedDate, addToDate } from 'utilities/DateUtils';
import {
  dateAPIFormat,
  dateTimeFormat,
} from 'modules/promotions-and-coupons/Promotions/Promotions.constants';
import {
  persuasionDocumentQuery,
  persuasionHistogramQuery,
  updatePersuasionQuery,
} from './OpportunityCenterGraphqlQuery';

type Body = {
  include: {
    expiry_date: {
      gte: string;
    };
    hotel_code: string[];
    status: string;
    category?: string;
  };
  consumers: string[];
  sort: {
    priority: string;
  };
  fields: {
    include: string[];
  };
  size?: number;
};

export const getPriorityPersuasions = (
  hotelCode,
  page,
  category = null,
  { showLoader = true } = {},
) => {
  const consumerType =
    page === 'dashboard' ? 'opportunity_dashboard' : 'opportunity_listing';

  const body: Body = {
    include: {
      expiry_date: {
        gte: getFormattedDate(
          new Date(),
          `${apiDateFormat} ${displayTimeFormatWithSeconds}`,
        ),
      },
      hotel_code: [hotelCode],
      status: 'unresolved',
    },
    consumers: [consumerType],
    sort: { priority: 'asc' },
    fields: {
      include: [
        'type',
        'sub_type',
        'id',
        'category',
        'priority',
        'expiry_date',
        'title',
        'data.promo_type',
        'data.description',
        'data.promo_code',
        'data.oc_hide_expiry_date',
        'data.offer_type',
        'data.identifier',
        `consumers.${consumerType}`,
        'data.vendorBookingId',
      ],
    },
  };

  if (page === 'dashboard') {
    body.size = 5;
  }

  if (page === 'category-persuasion') {
    body.include.category = category;
  }

  const variable = {
    rawEndpointRequestData: {
      analyticsV1Persuasions: {
        modelType: 'modelType1',
        endPointId: 1,
        body,
      },
    },
  };
  return doMutation(persuasionDocumentQuery, variable, {
    useLoader: showLoader,
  });
};

export const getCategoryLevelPersuasionCount = hotelCode => {
  const body = {
    pipeline: 'persuasions_v2',
    include: {
      expiry_date: {
        gte: getFormattedDate(
          new Date(),
          `${apiDateFormat} ${displayTimeFormatWithSeconds}`,
        ),
      },
      hotel_code: [hotelCode],
      status: 'unresolved',
    },
    exclude: { 'consumers.opportunity_listing': null },
    KPI: [{ id: 'value_count' }],
    group_by: ['hotel_code', 'category'],
  };

  const variable = {
    rawEndpointRequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body,
      },
    },
  };
  return doQuery(persuasionHistogramQuery, variable);
};

export const updatPersuasioninIngo = (persuasion, period, hotelcode, user) => {
  const date =
    getFormattedDate(addToDate(period, 'months', new Date()), dateAPIFormat) +
    dateTimeFormat;

  const variable = {
    businessLogicData: {
      updateOfferAndResolveInput: {
        updateOffer: {
          body: {
            parent_group_id: null,
            checkoutdateend: date,
            bookingdateend: date,
            offercode: persuasion?.data?.promo_code,
          },
        },
        updatePersuasion: {
          body: {
            id: [persuasion.id],
            action: 'resolved',
            action_taken_by: user.name,
            entity_id: hotelcode,
            user_id: user?.profile?.id,
            is_staff: user?.profile?.is_staff,
            source: 'web',
            type: persuasion.type,
            sub_type: persuasion.sub_type,
            meta: {},
          },
        },
      },
    },
  };

  return doMutation(updatePersuasionQuery, variable);
};
