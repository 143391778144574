import { useCallback, useContext, useReducer } from 'react';
import reducer, { initialState } from './PerformanceDataHandler';
import { actionCreators } from 'modules/dashboard/utils/utils';
import { getAllPerformaceCardsData } from 'modules/dashboard/utils/DashboardGraphClient';
import { HotelContext } from 'utilities/Context';
import { DASHBOARD_TOP_CARDS_TYPES_NEW as allPerformanceCards } from 'modules/dashboard/constants/Dashboard';

const usePerformanceMetricData = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentHotel } = useContext(HotelContext);
  const { base_currency: currency, hotelcode } = currentHotel;

  const fetchCardData = useCallback(
    async id => {
      const allIds = Object.values(allPerformanceCards);

      if (id) {
        dispatch(actionCreators.fetching(id));
      } else {
        allIds.forEach(id => {
          dispatch(actionCreators.fetching(id));
        });
      }

      try {
        const response: any = await getAllPerformaceCardsData(hotelcode);
        Object.entries(response).forEach(([id, valResp]: [string, any]) => {
          if (valResp.status === 'success') {
            dispatch(actionCreators.success(id, { ...valResp, currency }));
          } else {
            dispatch(actionCreators.failed(id));
          }
        });
      } catch (error) {
        allIds.forEach(id => {
          dispatch(actionCreators.failed(id));
        });
      }
    },
    [currency, hotelcode],
  );
  const resetCardData = useCallback(id => {
    dispatch(actionCreators.reset(id));
  }, []);

  const updateCardData = useCallback((id, payload) => {
    dispatch(actionCreators.update(id, payload));
  }, []);

  return [state, fetchCardData, resetCardData, updateCardData];
};

export default usePerformanceMetricData;
