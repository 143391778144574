import { CurrentHotel } from '../../interfaces/HotelContextInterface';
import { BlockedWebMain } from '../../modules/blocked-on-web/BlockedWebMain';
import RCTHotelBlock from '../../modules/blocked-on-web/RCTHotelBlock';
import MigrationProgress from '../../modules/hostweb-migration/MigrationProgress';

type Props = {
  currentHotel: CurrentHotel;
};

export default function BlockedForExtranet(props: Props) {
  const { currentHotel } = props;

  if (
    currentHotel?.hostweb_migration === 'pending' ||
    currentHotel?.hostweb_migration === 'failed'
  ) {
    return <MigrationProgress />;
  } else if (currentHotel?.hotel_suspected) {
    return <RCTHotelBlock />;
  }
  return <BlockedWebMain isIngoExpressBlocked={currentHotel?.isIngoExpress} />;
}
