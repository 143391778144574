import { routes } from 'app/routes';
import { PNC_TAB } from 'interfaces/PncContextInterface';
import { getFormattedDate, onlyDateComparison } from 'utilities/DateUtils';
import { isNullOrUndefined, navigateTo, showMessage } from 'utilities/Utils';
import {
  defaultDealsData,
  MIN_STAY_NIGHTS,
  MIN_FREE_NIGHTS,
} from '../PnC.constants';
import {
  DEFAULT_FIRST_BLACKOUTDATE_IDX,
  DEFAULT_LAST_BLACKOUTDATE_IDX,
  LOS_OFFER_SET_VALUE,
  OFFER_SET_VALUE,
  dateAPIFormat,
  newPromotionTypes,
} from './Promotions.constants';
import { PromotionPayloadType } from './promotion.types';

export function isFormValid(data) {
  const errorsArr = [];
  const {
    promotionName,
    stayStartDate,
    stayEndDate,
    hasNoStayEndDate,
    bkgStartDate,
    bkgEndDate,
    hasNoBkgEndDate,
    applicableOnlyForStayDates,
    checkInBlackOutDatesArr,
    showBlackOutDates,
    maxLos,
    minLos,
  } = data;
  if (!promotionName) {
    errorsArr.push('Promotion Name is Mandatory');
  } else {
    if (promotionName.length < 3) {
      errorsArr.push('Promotion Name should have minimum 3 characters');
    }

    if (promotionName.length > 30) {
      errorsArr.push('Promotion Name should have maximum 30 characters');
    }
  }
  if (
    !hasNoStayEndDate &&
    onlyDateComparison(stayStartDate, stayEndDate) === 1
  ) {
    errorsArr.push('Stay Start Date cannot be greater than Stay End Date');
  }
  if (!applicableOnlyForStayDates) {
    if (!hasNoBkgEndDate) {
      if (onlyDateComparison(bkgStartDate, bkgEndDate) === 1) {
        errorsArr.push(
          'Booking Start Date cannot be greater than Booking End Date',
        );
      }
      if (onlyDateComparison(bkgEndDate, new Date()) === -1) {
        errorsArr.push(
          'Your Offer is Expired. Please change booking end date to reactivate.',
        );
      }
    }
    if (onlyDateComparison(stayStartDate, bkgStartDate) === -1) {
      errorsArr.push(
        'Stay Start Date cannot be lesser than Booking Start Date',
      );
    }
  }
  if (showBlackOutDates) {
    for (let i = 0; i < checkInBlackOutDatesArr.length; i++) {
      const currBDDate = checkInBlackOutDatesArr[i];

      if (!hasNoStayEndDate) {
        if (onlyDateComparison(currBDDate, stayEndDate) === 1) {
          errorsArr.push(
            `Stay Blackout dates should not be greater than ${stayEndDate.toDateString()}`,
          );
          break;
        }
      }
      if (onlyDateComparison(currBDDate, stayStartDate) === -1) {
        errorsArr.push(
          `Stay Blackout dates should not be lesser than ${stayStartDate.toDateString()}`,
        );
        break;
      }
    }
  }
  if (
    !isNullOrUndefined(maxLos) &&
    !isNullOrUndefined(minLos) &&
    minLos.value > maxLos.value
  ) {
    errorsArr.push('Min Los cannot be greater than Max Los');
  }
  const errorsLen = errorsArr.length;
  if (errorsLen > 0) {
    showMessage({
      show: true,
      type: 'error',
      message: errorsArr,
    });
  }
  return !errorsLen;
}

export function getOffsetValue(baseOfferValue, promotionType = null) {
  const offerSet =
    promotionType === newPromotionTypes.LOS
      ? LOS_OFFER_SET_VALUE
      : OFFER_SET_VALUE;
  return baseOfferValue > 86
    ? baseOfferValue > 90
      ? 0
      : 90 - baseOfferValue
    : offerSet;
}

export const handleOnBack = (
  isEditFlow,
  activeDealsTab,
  navigateToActive,
  allDealsData,
) => {
  if (isEditFlow) {
    if (activeDealsTab === PNC_TAB.expired) {
      navigateTo(routes.businessBooster.pnc.expired);
    } else if (
      JSON.stringify(allDealsData) === JSON.stringify(defaultDealsData)
    ) {
      navigateToActive();
    } else {
      navigateTo(routes.businessBooster.pnc.active);
    }
  } else {
    navigateTo(routes.businessBooster.pnc.create);
  }
};
export const DAYS_REGEX = /\d+ days/;
export const STAY_NIGHTS = /\d+ nights/;
export const getStayDays = (text, checkType = DAYS_REGEX) => {
  const regex = checkType;
  const match = regex.exec(text);
  const days = match?.[0]?.split(' ')[0];
  return parseInt(days);
};

export const getMinStayDurationOptions = minStay => {
  const { discount, freeRoomNight: freeNightValues = {} } = minStay || {};
  const {
    customValues: discountCustom,
    upfrontValues: discountUpfront,
    recommendedValueId: recommendedDurationId,
  } = discount;
  const {
    customValues: freeNightCustom = [],
    upfrontValues: freeNightUpfront = [],
    recommendedValueId: recommendedFreeNightsId,
  } = freeNightValues;
  const getCustomRule = type => {
    return { value: 0, label: `Set custom ${type}` };
  };
  const customStayDurationOptions = discountCustom?.map(val => ({
    value: getStayDays(val),
    label: val,
  }));

  const minStayDurationOptions = Object.values(discountUpfront)?.map(val => ({
    value: getStayDays(val),
    label: val,
  }));
  minStayDurationOptions.push(getCustomRule('duration'));
  const maxStayNights = freeNightCustom?.maxstaynights || 0;
  const freeNightRuleLimits = {
    stayNights: {
      min: MIN_STAY_NIGHTS,
      max: maxStayNights,
    },
    freeNights: {
      min: MIN_FREE_NIGHTS,
      max: maxStayNights - 1,
    },
  };
  const freeNightRule = Object.values(freeNightUpfront)?.map(val => ({
    value: getStayDays(val, STAY_NIGHTS),
    label: val,
  }));
  freeNightRule.push(getCustomRule('rule'));
  return {
    customStayDurationOptions,
    minStayDurationOptions,
    freeNightRuleLimits,
    freeNightRule,
    recommendedDurationId,
    recommendedFreeNightsId,
  };
};

/**
 * @param {Object} params.payloadArr - The payload array
 * @param {string[]} params.ExceptionKeys - The exception keys
 * @returns {Object[]} - The transformed payload
 * convert every (key: value) pair to (key: { value: value })
 */
export const transformPayload = (params: {
  payloadArr: Partial<PromotionPayloadType>[];
}) => {
  const { payloadArr = [] } = params;

  const transformValue = value => {
    if (
      Array.isArray(value) &&
      value.every(item => typeof item === 'object' && item !== null)
    ) {
      return value.map(transformValue);
    } else if (Array.isArray(value)) {
      return value;
    } else if (value !== null && typeof value === 'object') {
      //eslint-disable-next-line
      return transformObject(value);
    }
    return { value };
  };

  const transformObject = obj => {
    const transformedObj = {};
    Object.keys(obj).forEach(key => {
      transformedObj[key] = transformValue(obj[key]);
    });
    return transformedObj;
  };

  return payloadArr.map(transformObject);
};

/**
 * @param {Object} params.payloadArr - The payload array
 * @returns {Object[]} - The transformed payload
 * convert every (key: { value: value }) pair to (key: value)
 */
export const getFinalPromotionPayload = (params: {
  payloadArr: Partial<PromotionPayloadType>[];
}): Partial<PromotionPayloadType>[] => {
  const { payloadArr } = params;
  const transformedPayloadArr: Partial<PromotionPayloadType>[] =
    transformPayload({
      payloadArr: payloadArr,
    });
  return transformedPayloadArr;
};

export const getBlackoutDates = (params: { allDates: Date[][] }) => {
  const { allDates } = params;
  const modifiedDates = allDates
    .map(dateArr => dateArr.map(date => getFormattedDate(date, dateAPIFormat)))
    .map(
      dateArr =>
        `${dateArr?.[DEFAULT_FIRST_BLACKOUTDATE_IDX]},${
          dateArr?.[(dateArr?.length || DEFAULT_LAST_BLACKOUTDATE_IDX) - 1]
        }`,
    );
  return modifiedDates;
};
