import React, { useContext, useEffect, useState } from 'react';
import { DashboardEntry } from '../../analytics/sections/content_score/ActionCard';
import ScoreSlider from '../../analytics/sections/content_score/ScoreSlider';
import { getContentScoreDetails } from 'shared_logic/SharedGraphClient';
import ContentScoreCard from '../../host-web/modules/listing/components/ContentScoreCard';
import {
  useLangStrings,
  useCurrentHotel,
} from '../../../utilities/CustomHooks';
import { navigateTo } from 'utilities/Utils';

import { routes } from '../../../app/routes';
import { Dashboard } from '../../../utilities/TestIds';

import { legacyPushToGTM, GTM_CATEGORIES } from 'utilities/gtm';
import { HotelContext } from 'utilities/Context';
import { Button } from 'connect-web-ui';

export default function ContentScoreSection(props) {
  const { gtmLabel, onSectionLoadUpdate } = props;
  const [{ CONTENT_SCORE: STRINGS }] = useLangStrings('Dashboard');
  const [{ CONTENT_SCORE: ANALYTICS_STRINGS }] = useLangStrings('Analytics');

  const [hotelCode, { showListings }] = useCurrentHotel();
  const { showNewContentScore } = useContext(HotelContext) || {};

  const [isVisible, setIsVisible] = useState(false);
  const [csData, setCsData] = useState({
    score: 0,
    list: [],
  });
  const [contentScoreData, setContentScoreData] = useState({
    persuasions: {},
    totalContentScore: 0,
  });

  const navigateToCS = () => {
    legacyPushToGTM({
      eventCategory: GTM_CATEGORIES.DASHBOARD,
      eventAction: 'content_score',
      eventLabel: 'go_to_content_score',
    });
    navigateTo(routes.property.contentScore);
  };

  const navigateToListingCS = () => {
    legacyPushToGTM({
      eventCategory: GTM_CATEGORIES.DASHBOARD,
      eventAction: 'content_score',
      eventLabel: 'view_all',
    });
    navigateTo(routes.listing.base);
  };

  useEffect(() => {
    if (showListings) {
      getContentScoreDetails(hotelCode, false, { showLoader: false })
        .then(res => {
          if (res.totalContentScore) {
            setIsVisible(true);
            setContentScoreData(res);
            onSectionLoadUpdate(true, gtmLabel);
          } else {
            onSectionLoadUpdate(false, gtmLabel);
            setIsVisible(false);
          }
        })
        .catch(error => {
          console.error('Error fetching content score details', error);
        });
    } else if (!showNewContentScore) {
      getContentScoreDetails(hotelCode, true, { showLoader: false })
        .then(
          (resp: {
            totalContentScore: number;
            persuasions: { propertyData; roomData };
          }) => {
            if (resp.totalContentScore === 0) {
              onSectionLoadUpdate(false, gtmLabel);
              setIsVisible(false);
              setCsData(null);
            } else {
              const combinedList = [
                ...(resp.persuasions?.propertyData ?? []),
                ...(resp.persuasions?.roomData ?? []),
              ];
              onSectionLoadUpdate(true, gtmLabel);
              setCsData({
                score: resp.totalContentScore,
                list: combinedList.slice(0, 2),
              });
              setIsVisible(true);
            }
          },
        )
        .catch(error => {
          console.error('Error fetching content score details', error);
        });
    }
  }, [hotelCode]);

  if (!isVisible || showNewContentScore) {
    return null;
  }

  if (showListings && !showNewContentScore) {
    return (
      <ContentScoreCard
        data={contentScoreData}
        handleViewAllClick={navigateToListingCS}
      />
    );
  }

  return (
    <div className="sr-panel" data-test-id={Dashboard.contentScore}>
      <div className="sr-panel-heading ">
        <h3 className="font16 margin0">{STRINGS.TITLE}</h3>
      </div>
      <div className="sr-panel-body">
        <ScoreSlider showLabel isSmall value={csData?.score} />
        <div className="sr-flex-col sr-align-center">
          <div className="sr-card sr-width100 pad0 marginB8">
            {(csData?.list || []).map(action => (
              <DashboardEntry STRINGS={ANALYTICS_STRINGS} data={action} />
            ))}
          </div>
          <Button
            size="legacy"
            className="!my-[6px] !mx-4"
            variant="text"
            onClick={navigateToCS}
          >
            {STRINGS.CTA}
          </Button>
        </div>
      </div>
    </div>
  );
}
