import React from 'react';
import { getFormattedTime } from 'utilities/DateUtils';

const TimeBasedTimeline = ({
  timeLineStart,
  timeLineEnd,
  offerValue,
  overallDiscount,
  segmentType,
}) => {
  const startTime = new Date();
  startTime.setHours(12, 0, 0);

  const endTime = new Date(startTime);
  endTime.setDate(endTime.getDate() + 1);
  endTime.setHours(12, 0, 0);

  const timeRangeWidth = 385;

  const parseTime = timeString => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return { hours, minutes };
  };

  const timeToMinutes = timeStr => {
    const [hours, minutes] = timeStr.split(':').map(Number);
    let totalMinutes = hours * 60 + minutes;

    if (hours < 12) {
      totalMinutes += 720; // convert to 24-hour time
    } else {
      totalMinutes -= 720;
    }

    return totalMinutes;
  };

  const { hours: startHours, minutes: startMinutes } = parseTime(timeLineStart);
  const { hours: endHours, minutes: endMinutes } = parseTime(timeLineEnd);

  const timeLineStartTime = new Date(startTime);
  timeLineStartTime.setHours(startHours, startMinutes, 0);

  const timeLineEndTime = new Date(timeLineStartTime);
  timeLineEndTime.setHours(endHours, endMinutes, 0);

  if (timeLineEndTime <= timeLineStartTime) {
    timeLineEndTime.setDate(timeLineEndTime.getDate() + 1);
  }

  if (timeLineStartTime < startTime) {
    timeLineStartTime.setDate(timeLineStartTime.getDate() + 1);
    timeLineEndTime.setDate(timeLineEndTime.getDate() + 1);
  }

  const redLineStart =
    ((timeLineStartTime.getTime() - startTime.getTime()) /
      (endTime.getTime() - startTime.getTime())) *
    timeRangeWidth;

  const orangeSegments = [];
  const start = timeToMinutes(timeLineStart);
  const end = timeToMinutes(timeLineEnd);

  if (start < end) {
    orangeSegments.push([start, end]);
  } else {
    orangeSegments.push([start, 1440]); // to midnight
    orangeSegments.push([0, end]); // from midnight to end
  }

  const orangeLines = orangeSegments.map(([_start, _end]) => {
    const normalizedStart = (_start / 1440) * timeRangeWidth;
    const normalizedEnd = (_end / 1440) * timeRangeWidth;
    return { normalizedStart, normalizedEnd };
  });

  return (
    <div className="bg-[#F8F8F8]">
      <svg
        width={400}
        height={180}
        viewBox="-8 0 410 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M2 26 H383 V32 H2 V26 Z" fill="#B0B0B0" />

        <text
          x={-2}
          y={84}
          fontFamily="Roboto"
          letterSpacing="0.02em"
          fontSize="12px"
          fontWeight="400"
          fill="#404040"
          textAnchor="start"
        >
          {getFormattedTime(startTime, 'hh a')}
        </text>
        <text
          x={0}
          y={97}
          fontFamily="Roboto"
          letterSpacing="0.02em"
          fontSize="12px"
          fontWeight="400"
          fill="rgba(74, 74, 74, 0.7)"
          textAnchor="start"
        >
          Noon, today
        </text>
        <text
          x={351}
          y={84}
          fontFamily="Roboto"
          letterSpacing="0.02em"
          fontSize="12px"
          fontWeight="400"
          fill="#404040"
          textAnchor="start"
        >
          {getFormattedTime(endTime, 'hh a')}
        </text>
        <text
          x={300}
          y={97}
          fontFamily="Roboto"
          letterSpacing="0.02em"
          fontSize="12px"
          fontWeight="400"
          fill="rgba(74, 74, 74, 0.7)"
          textAnchor="start"
        >
          Noon, tomorrow
        </text>
        {orangeLines.map((line, index) => (
          <line
            key={index}
            x1={line.normalizedStart}
            y1={29}
            x2={line.normalizedEnd}
            y2={29}
            stroke="#3543BF"
            strokeWidth="4"
          />
        ))}

        <text
          x={0}
          y={-35}
          fontFamily="Roboto"
          letterSpacing="0.02em"
          textAnchor="start"
        >
          <tspan x={0} fill="#404040" fontWeight="500" fontSize="14px">
            Total Discount ({overallDiscount + offerValue}%)
          </tspan>
          <tspan x={0} dy="1.4em" fill="#404040" fontSize="12px">
            ={' '}
            {segmentType === 'MOBILE'
              ? 'Mobile Customers Discount'
              : 'India POS Discount'}{' '}
            ({offerValue}%) + Discount for time duration ({overallDiscount}%)
          </tspan>
        </text>

        {orangeLines.map((line, index) => {
          const textContent = `${overallDiscount + offerValue}%`;
          const textWidth = textContent.length * 6;
          const padding = 4;
          const xPosition = (line.normalizedStart + line.normalizedEnd) / 2;
          const yPosition = 10;

          return (
            <React.Fragment key={index}>
              <line
                x1={line.normalizedStart}
                y1={29}
                x2={line.normalizedEnd}
                y2={29}
                stroke="#3543BF"
                strokeWidth="4"
              />

              {line.normalizedStart + line.normalizedEnd !== 0 && (
                <>
                  <rect
                    x={xPosition - textWidth / 2 - padding}
                    y={yPosition - 5}
                    width={textWidth + padding * 2}
                    height={16}
                    fill="#3543BF"
                    rx={8}
                  />

                  {/* Percentage text */}
                  <text
                    x={xPosition}
                    y={yPosition + 7}
                    fontFamily="Roboto"
                    fontSize="11px"
                    fontWeight="500"
                    fill="#fff"
                    textAnchor="middle"
                  >
                    {textContent}
                  </text>
                </>
              )}
            </React.Fragment>
          );
        })}

        {orangeLines.length === 1 && (
          <>
            {orangeLines[0].normalizedStart / 2 !== 0 && (
              <text
                x={orangeLines[0].normalizedStart / 2}
                y={18}
                fontFamily="Roboto"
                fontSize="11px"
                fontWeight="500"
                fill="#4A4A4A"
                textAnchor={
                  orangeLines[0].normalizedStart >= 7.79 ? 'middle' : 'end'
                }
              >
                {offerValue}%
              </text>
            )}

            <text
              x={(orangeLines[0].normalizedEnd + timeRangeWidth) / 2}
              y={18}
              fontFamily="Roboto"
              fontSize="11px"
              fontWeight="500"
              fill="#4A4A4A"
              textAnchor="middle"
            >
              {offerValue}%
            </text>
          </>
        )}

        {orangeLines.length === 2 && (
          <>
            <text
              x={(redLineStart + orangeLines[1].normalizedEnd) / 2}
              y={18}
              fontFamily="Roboto"
              fontSize="10px"
              fontWeight="500"
              fill="#4A4A4A"
              textAnchor="middle"
            >
              {offerValue}%
            </text>
          </>
        )}

        {orangeLines.length > 0 && (
          <>
            <line
              x1={orangeLines[orangeLines.length - 1].normalizedEnd}
              y1={29}
              x2={orangeLines[orangeLines.length - 1].normalizedEnd}
              y2={40}
              stroke="black"
            />
            <line
              x1={orangeLines[orangeLines.length - 1].normalizedEnd}
              y1={19}
              x2={orangeLines[orangeLines.length - 1].normalizedEnd}
              y2={29}
              stroke="black"
              strokeDasharray="2 2"
            />

            {orangeLines[orangeLines.length - 1].normalizedEnd !== 0 &&
              (() => {
                const diff =
                  orangeLines[orangeLines.length - 1].normalizedEnd -
                  (redLineStart - 1);
                return (diff >= 14 && diff <= 18) ||
                  (diff <= -14 && diff >= -18) ? (
                  <text
                    x={orangeLines[orangeLines.length - 1].normalizedEnd}
                    y={52}
                    fontFamily="Roboto"
                    letterSpacing="0.01em"
                    fontSize="12px"
                    fontWeight="400"
                    fill="#404040"
                    textAnchor="middle"
                  >
                    <tspan>
                      {new Date(timeLineEndTime).getHours() % 12 || 12}
                    </tspan>
                    <tspan
                      fontSize="11px"
                      dy="0.9em"
                      dx="0.3em"
                      x={orangeLines[orangeLines.length - 1].normalizedEnd - 5}
                    >
                      {new Date(timeLineEndTime).getHours() < 12
                        ? ' am '
                        : ' pm '}
                    </tspan>
                  </text>
                  ) : (
                  <text
                    x={orangeLines[orangeLines.length - 1].normalizedEnd}
                    y={52}
                    fontFamily="Roboto"
                    letterSpacing="0.01em"
                    fontSize="12px"
                    fontWeight="400"
                    fill="#404040"
                    textAnchor="middle"
                  >
                    <tspan
                      fontSize="11px"
                      dx={
                        Math.abs(
                          orangeLines[orangeLines.length - 1].normalizedEnd -
                            16.041666666666664,
                        ) < 0.0000001
                          ? '1'
                          : '-3'
                      }
                    >
                      {getFormattedTime(timeLineEndTime, 'h a')}
                    </tspan>
                  </text>
                  );
              })()}
          </>
        )}

        {redLineStart - 1 !== -1 &&
          ((orangeLines[orangeLines.length - 1].normalizedEnd -
            (redLineStart - 1) >=
            14 &&
            orangeLines[orangeLines.length - 1].normalizedEnd -
              (redLineStart - 1) <=
              18) ||
          (orangeLines[orangeLines.length - 1].normalizedEnd -
            (redLineStart - 1) <=
            -14 &&
            orangeLines[orangeLines.length - 1].normalizedEnd -
              (redLineStart - 1) >=
              -18) ? (
            <text
              x={redLineStart - 1}
              y={52}
              fontFamily="Roboto"
              letterSpacing="0.01em"
              fontSize="12px"
              fontWeight="400"
              fill="#404040"
              textAnchor="middle"
            >
              <tspan>{new Date(timeLineStartTime).getHours() % 12 || 12}</tspan>
              <tspan
                fontSize="11px"
                dy="0.9em"
                dx="0.3em"
                x={redLineStart - 1 - 5}
              >
                {new Date(timeLineStartTime).getHours() < 12 ? ' am ' : ' pm '}
              </tspan>
            </text>
            ) : (
            <text
              x={redLineStart - 1}
              y={52}
              fontFamily="Roboto"
              letterSpacing="0.01em"
              fontSize="12px"
              fontWeight="400"
              fill="#404040"
              textAnchor="middle"
            >
              <tspan
                fontSize="11px"
                dx={
                  Math.abs(redLineStart - 1 - 367.95833333333337) < 0.0000001
                    ? '-3'
                    : '1'
                }
              >
                {getFormattedTime(timeLineStartTime, 'h a')}
              </tspan>
            </text>
            ))}

        {redLineStart !== 0 && (
          <>
            <line
              x1={redLineStart}
              y1={30}
              x2={redLineStart}
              y2={40}
              stroke="black"
            />
            <line
              x1={redLineStart}
              y1={19}
              x2={redLineStart}
              y2={29}
              stroke="black"
              strokeDasharray="2 2"
            />
          </>
        )}
        <line x1={384.5} y1={32} x2={384.5} y2={72} stroke="black" />
        <line
          x1={384.5}
          y1={19}
          x2={384.5}
          y2={32}
          stroke="black"
          strokeDasharray="2 2"
        />
        <line x1={0.5} y1={32} x2={0.5} y2={72} stroke="black" />
        <line
          x1={0.5}
          y1={18}
          x2={0.500001}
          y2={32}
          stroke="black"
          strokeDasharray="2 2"
        />
        <g>
          <rect x={2} y={112} width={8} height={8} fill="#B0B0B0" />
          <text
            x={20}
            y={120}
            fontFamily="Roboto"
            letterSpacing="0.02em"
            fontSize="12px"
            fontWeight="400"
            textAnchor="start"
          >
            <tspan fill="#404040">
              {segmentType === 'MOBILE'
                ? 'Mobile Customers Discount'
                : 'India POS Discount'}{' '}
              ({offerValue}%)
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  );
};

export default TimeBasedTimeline;
