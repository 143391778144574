import { onFCP, onLCP, onCLS, onINP, onTTFB } from 'web-vitals/attribution';

import { handleWebVitalMetric } from './webVitals.utils';

function initializeWebVitalsLogging() {
  onTTFB(handleWebVitalMetric);
  onFCP(handleWebVitalMetric);
  onLCP(handleWebVitalMetric);
  onCLS(handleWebVitalMetric);
  onINP(handleWebVitalMetric);
}

export default initializeWebVitalsLogging;
