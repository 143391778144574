import App from 'app/App';
import { IHotel } from 'app/hotel/interfaces';
import { useCallback, useContext, useEffect } from 'react';
import SENTRY from 'utilities/Sentry';
import { useSuspenseQuery } from '@tanstack/react-query';
import { getInitialData } from 'app/AppAPIClient';
import { AuthContext } from 'utilities/Context';
import { suspenseWrapper } from 'base/reactQueryWrapper';
import GlobalShimmer from 'app/shimmers/GlobalShimmer';
import { getAPIToken } from 'utilities/Utils';

type InitialData = {
  hotelList: IHotel[];
  user: unknown;
  isStaff: boolean;
  chainData: unknown;
  dayUsePricePercentages: unknown;
  isLoggedIn: boolean;
  isBdManager: boolean;
};

function useInitialDataFetcher() {
  const { setApiToken } = useContext(AuthContext);

  const fetchInitialData = useCallback(async (): Promise<InitialData> => {
    const response = await getInitialData();

    const {
      hotelList,
      user,
      isStaff,
      chainData,
      dayUsePricePercentages,
      token,
      isBdManager,
    } = response;
    const newHotelList = hotelList.map(item => {
      item.label = `${item.hotelname}, ${item.city}`;
      item.value = item.hotelcode;
      return item;
    });

    const tokenFromLoginAPI = getAPIToken();

    // for backward compatibility
    if (!tokenFromLoginAPI) {
      setApiToken(token);
    }

    return {
      hotelList: newHotelList,
      user,
      isStaff,
      chainData,
      dayUsePricePercentages,
      isLoggedIn: !!user,
      isBdManager,
    };
  }, []);
  return {
    fetcher: fetchInitialData,
    fetcherDeps: [],
  };
}

const MainApp = ({ langStringsData }) => {
  const { fetcher: initialDataFetcher, fetcherDeps: initialDataFetcherDeps } =
    useInitialDataFetcher();

  const { data: initialData } = useSuspenseQuery({
    queryFn: initialDataFetcher,
    queryKey: ['app', 'initialData', ...initialDataFetcherDeps],
    staleTime: Infinity,
  });

  useEffect(() => {
    SENTRY.init(initialData.user);
    // Clearing VCC password item, if it was set
    sessionStorage.removeItem('VCC_PASSWORD_SET');
  }, [initialData]);

  return <App {...initialData} {...langStringsData} />;
};

const MainSuspenseApp = suspenseWrapper(MainApp, {
  renderLoader: () => <GlobalShimmer />,
  showHeader: true,
});

export default MainSuspenseApp;
