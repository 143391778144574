const ignoreMethods = [
  'constructor',
  'render',
  'componentWillMount',
  'componentDidMount',
  'componentWillReceiveProps',
  'shouldComponentUpdate',
  'componentWillUpdate',
  'componentDidUpdate',
  'componentWillUnmount',
];

/**
 * Returns a .bind version of the function
 */
const boundMethod = (objPrototype, method, descriptor) => {
  const fn = descriptor.value;
  return {
    configurable: true,
    get() {
      if (this === objPrototype || this.hasOwnProperty(method)) {
        return fn;
      }

      const boundFn = fn.bind(this);
      Object.defineProperty(this, method, {
        value: boundFn,
        configurable: true,
        writable: true,
      });
      return boundFn;
    },
  };
};

/**
 * Created by Ankita Gulati on 10/02/18.
 * @autobind.js
 * Bind all the functions of a class with this
 */
export const autobind = context => {
  if (context === undefined) {
    console.error('Autobind error: No context provided.');
  } else {
    const allMethods = Object.getOwnPropertyNames(
      Object.getPrototypeOf(context),
    );
    allMethods.forEach(function (method) {
      const descriptor = Object.getOwnPropertyDescriptor(
        Object.getPrototypeOf(context),
        method,
      );
      // Return if it's special case function or if not a function at all
      if (
        ignoreMethods.indexOf(method) !== -1 ||
        typeof descriptor.value !== 'function'
      ) {
        return;
      }
      Object.defineProperty(
        Object.getPrototypeOf(context),
        method,
        boundMethod(Object.getPrototypeOf(context), method, descriptor),
      );
    });
  }
};
