export const RETRY_COUNT = 2;

export enum CTA_TYPE_ENUM {
  LOAD = 'load',
  REDIRECT = 'redirect',
}

export enum ITEM_TYPE_ENUM {
  MENU_ITEM = 'MENU_ITEM',
  LABELLED_ICON = 'LABELLED_ICON',
  STICKEY_ICON = 'STICKEY_ICON',
  FLOATING_ICON = 'FLOATING_ICON',
}

export enum DATA_TYPE_ENUM {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  COUNT = 'COUNT',
}

export enum COMPONENT_ID_ENUM {
  COMMON_SIDE_NAV = 'COMMON_SIDE_NAV',
  COMMON_RESELLER_SIDE_NAV = 'COMMON_RESELLER_SIDE_NAV',
  COMMON_GROUP_SIDE_NAV = 'COMMON_GROUP_SIDE_NAV',
}

export enum LAYOUT_ID_ENUM {
  COMMON_GRID = 'COMMON_GRID',
}

export enum SCREEN_LEVEL {
  L1 = 'L1',
  L2 = 'L2',
}
