import React, { useEffect, useRef } from 'react';
import { useExpanded, useFlexLayout, useRowState, useTable } from 'react-table';
import {
  Collapse,
  Table as MuiTable,
  Paper,
  TableContainer,
  Toolbar,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  withStyles,
  makeStyles,
  ChevronRight,
  KeyboardArrowDown,
} from 'connect-web-ui/mui';
import { TablePaginationActions } from './TablePagination';
import { DataAttribute } from 'interfaces/types';
import { getDataPropsFromRest, isNullOrUndefined } from 'utilities/Utils';

import './Table.scss';
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.grey[400],
    fontWeight: '700',
    padding: props => (props.isSmall ? '10px 12px' : '12px 16px'),
    fontSize: props => (props.isSmall ? '12px' : '14px'),
    '&.MuiTableCell-sizeSmall': {
      padding: '8px 16px',
    },
  },
  //Can style body cells with below
  body: {
    verticalAlign: 'top',
    fontSize: props => (props.isSmall ? '12px' : '14px'),
    fontWeight: '400',
    borderBottom: '1px solid rgb(224, 224, 224)',
  },
}))(TableCell);
const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const labelDisplayedRows = ({ from, to, count }) =>
  `Displaying ${from} - ${to} of ${count}`;

type TablePropStyles = {
  isSmall?: boolean;
};
const useStyles = makeStyles(() => ({
  headerRow: {
    padding: (props: TablePropStyles) => (props.isSmall ? '8px 0' : '10px 0'),
    fontSize: (props: TablePropStyles) => (props.isSmall ? '12px' : '14px'),
    backgroundColor: '#bdbdbd',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 'bold',
  },
  card: {
    margin: '8px 0',
    padding: (props: TablePropStyles) => (props.isSmall ? '10px 0' : '12px 0'),
    fontSize: (props: TablePropStyles) => (props.isSmall ? '12px' : '14px'),
    boxShadow: '0 2px 4px 0 rgb(0 0 0 / 16%)',
    border: '1px solid #d4d4d4',
    flexWrap: 'wrap',
  },
  //Only for card
  cardDataRow: {
    display: 'flex',
    width: '100%',
  },
  expandedArea: {
    minWidth: (props: TablePropStyles) =>
      props.isSmall ? 'calc(100% - 24px)' : 'calc(100% - 32px)',
    padding: (props: TablePropStyles) => (props.isSmall ? '0 12px' : '0 16px'),
    marginTop: (props: TablePropStyles) => (props.isSmall ? '10px' : '12px'),
  },
  cell: {
    display: 'flex',
    '&.right-border': {
      borderRight: 'solid 1px rgba(67, 73, 86, 0.2)',
    },
    '& > span': {
      width: '100%',
      padding: (props: TablePropStyles) =>
        props.isSmall ? '0 12px' : '0 16px',
    },
  },
}));
const noop = () => {};
type ColumnsProps = (
  | {
    accessor: string | ((props) => React.ReactNode);
  }
  | {
    Cell: (props) => React.ReactNode;
  }
) & {
  id: string;
  Header: string | ((props) => React.ReactNode);
  width?: number;
};
type TableProps = DataAttribute & {
  columns: ColumnsProps[];
  data: object[];
  noDataText?: string | React.ReactNode;
  title?: string;
  filterUI?: React.ReactNode;
  renderExpandedRow?: (ro?: object, row?: object) => React.ReactNode;
  isSmall?: boolean;
  isCardLayout?: boolean;
  noPaper?: boolean;
  rowInitialState?: (row?: object) => object;
  classes?;
  hiddenColumns?: string[];
  enablePagination?: boolean;
  pageNum?: number;
  pageSize?: number;
  totalCount?: number;
  setPageNum?: (number: number) => void;
  hideDisplayedRows?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [rest: string]: any;
};
function Table(props: TableProps) {
  const {
    title,
    filterUI,
    columns,
    data,
    noDataText,
    renderExpandedRow,
    isSmall = false,
    isCardLayout = false,
    noPaper = false,
    rowInitialState = () => ({}),
    classes,
    hiddenColumns, //This should be memoized by parent
    //From useTablePagination
    enablePagination = false,
    pageNum = 0,
    totalCount = 0,
    pageSize = 20,
    setPageNum,
    hideDisplayedRows,
    ...additionalData
  } = props;
  const dataRest = getDataPropsFromRest(additionalData);
  const styles = useStyles({ isSmall });
  const classNames = {
    header: classes?.header ?? '',
    row: classes?.row ?? '',
  };
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    setHiddenColumns,
  } = useTable(
    {
      columns,
      data,
      expandSubRows: false,
      initialRowStateAccessor: rowInitialState,
      initialState: {
        hiddenColumns: hiddenColumns ?? [],
      },
    },
    useExpanded,
    useRowState,
    isCardLayout ? useFlexLayout : noop,
  );
  const isFirstLoad = useRef(true);
  useEffect(() => {
    if (isFirstLoad.current) {
      isFirstLoad.current = false;
    } else {
      setHiddenColumns(hiddenColumns ?? []);
    }
  }, [hiddenColumns]);
  const renderHeader = _headerGroups => {
    if (isCardLayout) {
      return _headerGroups.map(headerGroup => (
        <div
          {...headerGroup.getHeaderGroupProps()}
          className={`${styles.headerRow} ${classNames.header}`}
        >
          {headerGroup.headers.map((column, i, arr) => {
            const colHeaderStyles: { flex?: string } = {};
            if (!isNullOrUndefined(column.pctWidth)) {
              colHeaderStyles.flex = `${column.pctWidth} ${column.pctWidth}`;
            } else if (!isNullOrUndefined(column.totalFlexWidth)) {
              colHeaderStyles.flex = `${column.totalFlexWidth} ${column.totalFlexWidth}`;
            }
            const cellClass = `${styles.cell} ${
              i !== arr.length - 1 ? 'right-border' : ''
            }`;
            return (
              <div
                className={cellClass}
                {...column.getHeaderProps()}
                style={colHeaderStyles}
              >
                <span>{column.render('Header', additionalData)}</span>
              </div>
            );
          })}
        </div>
      ));
    }
    return _headerGroups.map(headerGroup => (
      <TableRow
        {...headerGroup.getHeaderGroupProps()}
        classes={{ root: classNames.header }}
      >
        {headerGroup.headers.map(column => {
          const colHeaderStyles: { width?: string; flex?: string } = {};
          if (!isNullOrUndefined(column.pctWidth)) {
            colHeaderStyles.width = `${column.pctWidth}%`;
            colHeaderStyles.flex = `${column.pctWidth} ${column.pctWidth}`;
          }
          return (
            <StyledTableCell
              isSmall={isSmall}
              {...column.getHeaderProps()}
              style={colHeaderStyles}
            >
              {column.render('Header', additionalData)}
            </StyledTableCell>
          );
        })}
      </TableRow>
    ));
  };
  const renderRow = row => {
    if (isCardLayout) {
      return (
        <div
          {...row.getRowProps()}
          className={`${styles.card} ${classNames.header}`}
        >
          <div className={styles.cardDataRow}>
            {row.cells.map((cell, i, arr) => {
              const cellProps = cell.getCellProps();
              if (!isNullOrUndefined(cell.column.pctWidth)) {
                delete cellProps.style.width;
                cellProps.style.flex = `${cell.column.pctWidth} ${cell.column.pctWidth}`;
              }
              const cellClass = `${styles.cell} ${
                i !== arr.length - 1 ? 'right-border' : ''
              }`;
              return (
                <div {...cellProps} className={cellClass}>
                  <span>{cell.render('Cell', additionalData)}</span>
                </div>
              );
            })}
          </div>
          {!isNullOrUndefined(renderExpandedRow) && (
            <div className={styles.expandedArea}>
              {renderExpandedRow(row.original, row)}
            </div>
          )}
        </div>
      );
    }
    return (
      <>
        <StyledTableRow
          {...row.getRowProps()}
          classes={{ root: classNames.row }}
        >
          {row.cells.map(cell => {
            let showExpansion = cell.column.showExpansion;
            if (typeof showExpansion === 'function') {
              showExpansion = showExpansion({
                row,
                ...additionalData,
              });
            }
            return (
              <StyledTableCell isSmall={isSmall} {...cell.getCellProps()}>
                {showExpansion ? (
                  <span
                    {...row.getToggleRowExpandedProps()}
                    title="Expand Row"
                    className="mui-expander-cell"
                  >
                    {row.isExpanded ? <KeyboardArrowDown /> : <ChevronRight />}
                    <span>{cell.render('Cell', additionalData)}</span>
                  </span>
                ) : (
                  cell.render('Cell', additionalData)
                )}
              </StyledTableCell>
            );
          })}
        </StyledTableRow>
        {row.isExpanded ? (
          <TableRow>
            <StyledTableCell isSmall={isSmall} colSpan={visibleColumns.length}>
              <Collapse in={row.isExpanded} timeout="auto" unmountOnExit>
                {renderExpandedRow(row.original, row)}
              </Collapse>
            </StyledTableCell>
          </TableRow>
        ) : null}
      </>
    );
  };
  const tableContainerStyles: { padding?: string } = {};
  if (isCardLayout && !noPaper) {
    tableContainerStyles.padding = '0 16px';
  }
  const TableWithPagination = (
    <>
      {!isNullOrUndefined(title) && (
        <Toolbar>
          <h4>{title}</h4>
          {!isNullOrUndefined(filterUI) && (
            <span style={{ marginLeft: 'auto' }}>{filterUI}</span>
          )}
        </Toolbar>
      )}
      <TableContainer style={tableContainerStyles}>
        <MuiTable
          style={{ tableLayout: 'fixed' }}
          {...getTableProps()}
          size={isSmall ? 'small' : 'medium'}
          {...dataRest}
        >
          <TableHead>{renderHeader(headerGroups)}</TableHead>
          <TableBody>
            {rows.length ? (
              rows.map(row => {
                prepareRow(row);
                return renderRow(row);
              })
            ) : (
              <TableRow>
                <StyledTableCell
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  {noDataText ?? 'No data to show'}
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {enablePagination && (
        <TablePagination
          colSpan={visibleColumns.length}
          count={totalCount}
          page={pageNum}
          className="custom-class"
          rowsPerPage={pageSize}
          onPageChange={(_, newPageNum) => setPageNum(newPageNum)}
          component="div"
          labelDisplayedRows={
            hideDisplayedRows ? () => null : labelDisplayedRows
          }
          rowsPerPageOptions={[]}
          //@ts-ignore
          ActionsComponent={TablePaginationActions}
        />
      )}
    </>
  );
  return noPaper ? TableWithPagination : <Paper>{TableWithPagination}</Paper>;
}
export default Table;
