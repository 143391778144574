import React, { useRef, useState, useEffect } from 'react';
import * as BankDetails from 'assets/modules/bank-details';
import './uploader.scss';

function Uploader(props) {
  const {
    title,
    image,
    readOnly,
    onChange,
    onError,
    style,
    middleware,
    accept,
  } = props;
  const ref = useRef();
  const [file, setFile] = useState({
    url: image,
    blob: null,
  });

  useEffect(() => {
    setFile({
      url: image,
      blob: null,
    });
  }, [image]);

  const handleClick = () => {
    if (readOnly) return;
    ref.current.click();
  };

  const setCallback = (url, blob) => {
    setFile({
      url: url,
      blob: blob,
    });
    onChange({
      blob,
      url,
    });
  };

  const handleChange = e => {
    const { files } = e.target;
    const blob = files[0];

    if (files.length > 0) {
      const url = URL.createObjectURL(blob);
      if (middleware) {
        middleware(blob)
          .then(() => {
            setCallback(url, blob);
          })
          .catch(err => {
            onError(err);
            // setCallback(url, blob);
          });
      } else {
        setCallback(url, blob);
      }
    }
  };

  return (
    <div
      style={style}
      onClick={handleClick}
      className={`uploader ${readOnly ? 'no-click' : ''} ${
        !file.blob && !image ? 'no-hover' : ''
      } ${file.url && 'uploaded-image'}`}
    >
      <input
        accept={accept}
        ref={ref}
        onChange={handleChange}
        type="file"
        style={{ display: 'none' }}
      />
      {!file.url && (
        <div>
          <img className="upload-icon" src={BankDetails.Upload} alt="" />
          <p className="title">{title}</p>
        </div>
      )}
      {file.url && (
        <div className="uploaded-image">
          <img src={BankDetails.Uploaded} alt="" />
          <p className="title green">Uploaded</p>
        </div>
      )}
    </div>
  );
}

export default Uploader;
