export const Dashboard = {
  checkin: 'dashboard_card_checkin_kpi',
  sellability: 'dashboard_card_sellability_share_kpi',
  revenue: 'dashboard_card_revenue_kpi',
  roomrate: 'dashboard_card_roomrate_kpi',
  hostMissing: 'dashboard_persuasion_host_missing',
  unverified: 'dashboard_persuasion_user_unverified',
  hostDetailMissing: 'dashboard_persuasion_host_detail_missing',
  sponsoredListing: 'dashboard_card_sponsored_listing',
  competitiveAnalysis: 'dashboard_card_competitive_analysis',
  mmtBlack: 'dashboard_card_mmt_black',
  goCabs: 'dashboard_card_go_cabs',
  boostPerf: 'dashboard_card_boost_performance',
  contentScore: 'dashboard_card_content_score',
  hotelRating: 'dashboard_card_hotel_rating',
  roomNight: 'dashboard_card_room_night_performance',
  offers: 'dashboard_card_offers',
  visitors: 'dashboard_card_visitors',
  opportunities: 'dashboard_card_opportunities',
  corpInvoices: 'dashboard_section_corporate_invoices',
  bookings: 'dashboard_section_new_bookings',
  messages: 'dashboard_section_guest_messages',
  reviews: 'dashboard_section_new_reviews',
  refundRequests: 'dashboard_section_refund_requests',
  instay: 'dashboard_section_instay_issues',
  insightsTrends: 'dashboard_section_insights_trends',
  pendingBooking: 'dashboard_btn_pending_bookings',
  inbox: 'dashboard_iconbtn_ingobox_opportunity',
  userMenu: 'dashboard_dropdown_user_menu',
  userVerification: 'dashboard_popup_user_verification',
  hourlyStays: 'dashboard_hourly_stays',
  propertyAnalytics: 'dashboard_property_analytics_redirection',
  manageYourCompetitors: 'dashboard_manage_your_competitors_card',
};

export const Property = {
  rooms: {
    createRoom: 'property_rooms_button_create_new_room',
    editRoom: 'property_rooms_button_edit_room',
    editAmenities: 'property_rooms_button_edit_amenities',
    displayName: 'property_rooms_text_display_name',
    description: 'property_rooms_text_description',
    roomType: 'property_rooms_dropdown_room_type',
    roomView: 'property_rooms_dropdown_room_view',
    bedType: 'property_rooms_dropdown_bed_type',
    extraBedType: 'property_rooms_dropdown_extra_bed_type',
    totalRooms: 'property_rooms_text_total_rooms',
    baseAdult: 'property_rooms_dropdown_base_adult',
    maxAdult: 'property_rooms_dropdown_max_adult',
    maxGuest: 'property_rooms_dropdown_max_guest',
    baseChild: 'property_rooms_base_child',
    maxChild: 'property_rooms_max_child',
    roomsTable: 'property_rooms_table_data',
  },
  policies: {
    cancellationTable: 'property_policies_cancellation_table_data',
    filterBy: 'property_policies_cancellation_filter',
    guestProfile: 'property_policies_rules_card_guest_profile',
    idProofs: 'property_policies_rules_card_id_proofs',
    generalSafety: 'property_policies_rules_card_general_safety',
    roomSafety: 'property_policies_rules_card_room_safety',
    distancing: 'property_policies_rules_card_distancing',
    foodDrinks: 'property_policies_rules_card_food_drinks',
    restrictions: 'property_policies_rules_card_restrictions',
    petPolicy: 'property_policies_rules_card_pet_policy',
    guestsSuitability: 'property_policies_rules_card_guests_suitability',
    checkinout: 'property_policies_rules_card_checkinout',
    extraBed: 'property_policies_rules_card_extra_bed',
    custom: 'property_policies_rules_card_custom',
    childage: 'property_policies_policy_card_child_age',
    postBooking: 'property_policies_policy_card_post_booking',
  },
  listingInfo: {
    floorLevelDropdown:
      'property_listing_info_property_info_drawer_floor_level_dropdown',
    propertyViewDropdown:
      'property_listing_info_property_info_drawer_property_view_dropdown',
    checkIn: 'property_listing_info_property_info_drawer_checkin',
    checkout: 'property_listing_info_property_info_drawer_checkout',
  },
  settings: {
    bookingSettings: {
      childPolicy: 'property_settings_child_policy',
      postBookingPolicy: 'property_settings_post_booking_policy',
      cancellationPolicy: 'property_settings_cancellation_policy',
      directBooking: 'property_settings_direct_booking',
    },
    paymentAndCompliance: {
      payoutAndCompliance: 'property_settings_payout_and_compliance',
    },
    propertySettings: {
      rateplans: 'property_settings_rateplans',
      occupancy: 'property_settings_occupancy',
      propertyRules: 'property_settings_property_rules',
      inclusions: 'property_settings_inclusions',
      notices: 'property_settings_notices',
      channelManager: 'property_settings_channel_manager',
      vccSettings: 'property_settings_vcc_settings',
      snoozeListing: 'property_settings_snooze_listing',
      FoodPolicy: 'property_settings_food_policy_restrictions',
    },
    drawers: {
      childPolicyDrawer: 'property_settings_child_policy_drawer',
      occupancyDrawer: 'property_settings_occupancy_drawer',
      channelManagerDrawer: 'property_settings_channel_manager_drawer',
      postBoookingPolicyDrawer: 'property_settings_post_booking_policy_drawer',
    },
  },
  listingDetails: {
    listingAndPropertyInfo: {
      listingTitleDrawer:
        'listing_details_listing_and_property_info_listing_title_drawer_edit',
      listingDescriptionDrawer:
        'listing_details_listing_and_property_info_listing_description_drawer_edit',
      propertyInformationDrawer:
        'listing_details_listing_and_property_info_property_information_drawer_edit',
      hotelPolicyDrawer:
        'listing_details_listing_and_property_info_hotel_policy_drawer_edit',
    },
  },
  sideContent: {
    ecsPersuasion: 'property_calendar_not_synced',
    contentScore: 'property_content_score',
    hostLikeAProCard: 'property_host_like_a_pro',
  },
};

export const PropertyVibe = {
  twoMinVideoBtn: 'property_vibe_see_two_min_video_btn',
  videoPopup: 'property_vibe_see_two_min_video_popup',
  pageLayout: 'property_vibe-layout',
};

export const Onboarding = {
  common: {
    sectionHeader: 'list-description-title',
    title: 'onboarding-welcome-title',
    subTitle: 'onboarding-sub-title',
    sectionFooterCta: 'skip-list-new-property',
    stepDetailsCard: 'onboarding-step-details-card',
  },
  propertySelection: {
    selectPropertyType: 'onboarding-propertySelection-propertyTypeRadio',
    selectSubPropertyType: 'onboarding-propertySelection-subPropertyTypeRadio',
    homeStaySellableType:
      'onboarding-propertySelection-homeStaySellableTypeRadio',
  },
  basicInfo: {
    propertyName: 'empty-error-msg-property',
    hotelStarRating: 'onboarding-basicInfo-hotelStarRatingDropdown',
    propertyEstd: 'onboarding-basicInfo-propertyEstdDropdown',
    channelManagerName: 'channel-manager-name-title',
    channelManagerToggle: 'channel-manager-yes-no-label',
    mobileNumber: 'empty-error-msg',
    email: 'empty-error-msg-email',
  },
  location: {
    locationSearch: 'onboarding-location-locationSearch',
    currentLocation: 'onboarding-location-currentLocationButton',
    houseBuildingApartment: 'adrress-location',
    locality: 'onboarding-location-localityInput',
    pincode: 'pincode-location',
    tnc: 'terms-condition-checkbox',
  },
  rooms: {
    roomsList: 'onboarding-rooms-roomsList',
    editRoom: 'onboarding-rooms-editRoom',
    deleteRoom: 'onboarding-rooms-deleteRoom',
    addNewRoom: 'onboarding-rooms-addNewRoom',
    roomName: 'room-name-input',
    roomDescription: 'room-description-name-input',
    availableRooms: 'number-available-room-input',
    mealOption: 'select-meal-type',
    roomType: 'select-room-type',
    bedType: 'select-bed-type',
    roomSize: 'rooms-spaces-space-size-input',
    roomSizeUnit: 'rooms-spaces-space-size-unit',
    roomView: 'onboarding-rooms-roomViewDropdown',
    smokingAllowed: 'onboarding-rooms-smokingAllowedDropdown',
    extraBed: 'onboarding-rooms-extraBedToggle',
    extraBedType: 'onboarding-rooms-extraBedTypeDropdown',
    baseOccupancy: 'onboarding-rooms-baseAdultCountInput',
    adultOccupancy: 'onboarding-rooms-maxAdultCountInput',
    childOccupancy: 'onboarding-rooms-maxChildCountInput',
    extraGuests: 'onboarding-rooms-maxOccupancyInput',
    baseRate: 'base-price-input',
    extraAdultCharge: 'onboarding-rooms-extraAdultRateInput',
    childPrice: 'onboarding-rooms-childRateInput',
    availability: 'onboarding-rooms-availabilityStartDateInput',
  },
  roomsAndSpaces: {
    bedroom: 'room-spaces-bedroom',
    bedrooms: 'rooms-spaces-bedrooms',
    bathroom: 'room-spaces-bathroom',
    kitchen: 'onboarding-roomsAndSpaces-kitchen',
    additionalSpaces: 'room-spaces-additional',
    deleteSpace: 'rooms_spaces_delete',
    spaceName: 'rooms_spaces_space_name_{{spaceName}}',
    spaceDelete: 'rooms_spaces_{{spaceName}}_delete',
    spaceEdit: 'rooms_spaces_{{spaceName}}_edit',
    amenityAttributeCheckbox: 'onboarding-amenities-{{attributeName}}-checkbox',
    amenityAttributeRadio: 'onboarding-amenities-radio',
  },
  pricingAndAvailability: {
    roomCount: 'number-available-room-input',
    mealOption: 'select-meal-type',
    basePrice: 'base-price-input',
  },
  policies: {
    cinTime: 'policy-checkin',
    coutTime: 'policy-checkout',
    showCheckInEndTime: 'onboarding-policies-checkinEndTimeCheckbox',
    cinEndTime: 'onboarding-policies-checkinEndTimeDropdown',
    cancelOptions: 'policy-radio-check',
    newCancelOptions: 'new-cancellation-policy',
  },
  financeAndLegal: {
    ownershipType: 'onboarding-financeAndLegal-ownershipTypeDropdown',
    ownershipTypeDoc: 'onboarding-financeAndLegal-ownershipTypeDocRadio',
    relationshipType: 'onboarding-financeAndLegal-relationshipTypeDropdown',
    saveOwnership: 'onboarding-financeAndLegal-saveOwnershipButton',
    vccCurrency: 'vcc-select-type',
    paymentOption: 'vcc-wire-radiocheck',
    wireAccountHolderName:
      'onboarding-financeAndLegal-wireAccountHolderNameInput',
    wireAccountNumber: 'onboarding-financeAndLegal-wireAccountNumberInput',
    wireSwiftCode: 'swift-code-title',
    wireBankName: 'onboarding-financeAndLegal-wireBankNameInput',
    vccName: 'onboarding-financeAndLegal-vccNameInput',
    baseCurrency: 'onboarding-financeAndLegal-baseCurrencyDropdown',
    vatNumber: 'onboarding-financeAndLegal-vatNumberInput',
    bankAccountNo: 'account-number-input',
    bankAccountNoReEnter: 'account-number-input',
    ifscCode: 'ifsc-input',
    bankName: 'bank-name',
    gstInToggle: 'gstn-yes-no',
    gstInNo: 'onboarding-financeAndLegal-gstInNoInput',
    panNo: 'pan-number-input',
    gstNoc: 'no-terms-condition-gstn',
    tanToggle: 'pan-number-input',
    tanNo: 'tan-number-input',
    saveBankDetails: 'onboarding-financeAndLegal-saveBankDetailsButton',
    verifyConsent: 'final-agreement-onboarding-finance',
    idType: 'onboarding-financeAndLegal-idTypeDropdown',
    saveIdProof: 'onboarding-financeAndLegal-saveIdProofButton',
    nocAgreement: 'onboarding-financeAndLegal-nocAgreementCheckbox',
    nocReason: 'onboarding-financeAndLegal-nocReasonDropdown',
  },
  thankYou: {
    onComplete: 'congratulations-page-on-complete',
    viewListing: 'view-your-listing',
  },
};

export const ManageCompset = {
  searchComp: 'manage_your_compset_search_competitors',
  yourCompetitorsList: 'manage_your_compset_your_competitors_list',
  ingoSuggestionList: 'manage_your_compset_ingo_suggestion_list',
};

export const ParityView = {
  entry: {
    priceParityCard: 'parity_entry_screen_entry_card',
  },
  priceDashboard: {
    priceParityFilters: 'parity_price_header_filters',
    competitors: 'parity_price_competitors',
    searchHotel: 'parity_price_search_hotel',
    sorting: 'parity_price_sort_icon',
    hotelNavigate: 'parity_price_hotel_navigate',
    userType: 'usertype-dropdown',
    crawlDuration: 'crawl-duration-dropdown',
    viewType: 'view-type-options',
    priceType: 'price-type-options',
  },

  hotelDetails: {
    goToHotelier: 'competitive_analysis_go_to_hotelier_view',
    headerfilters: 'competitive_analysis_header_filters',
    disparityTabs: 'competitive_analysis_disparity_tab',
    dipsarityCardSelect: 'competitive_analysis_disparity_card',
    showDetailedComparison: 'competitive_analysis_show_detailed_comparison',
    resolveDispartiy: 'competitive_analysis_resolve_disparity',
    reportCrawl: 'competitive_analysis_report_crawl',
    updateTicket: 'competitive_analysis_update_ticket',
    backToPriceDashboard:
      'parity_competitive_analysis_navigate_price_parity_dashboard',
    resolutionDrawer: 'competitive_analysis_resolution_disparity_drawer',
    finalPriceModal: 'competitive_analysis_final_price_comparison_modal',
    supplyPriceModal: 'competitive_analysis_supply_price_comparison_modal',
    readMore: 'competitive_analysis_read_more_persuasion',
    competitorsCount: 'competitive_analysis_max_competitors_modal',
    stayDuration: 'stay-duration',
    adultCount: 'adult-count',
    priceDetails: 'vendor-price-details',
    roomDetails: 'room-details',
    otaPriceDetails: 'ota-price-details',
    disparityReason: 'disparity-reason',
  },
};

export const Inventory = {
  internalCalSync: {
    propertyCard: 'ics_property_card',
    icsHeading: 'ics_heading',
    faq: 'ics_faq',
    inventoryShimmer: 'inventory_shimmer',
  },
  externalCalSync: {
    youtubeLink: 'ecs_youtube_link',
    faq: 'ecs_faq',
    SyncButton: 'ecs_sync_button',
  },
};
//TODO: Top level popup - Pending agreement has id="extranet-gommt-tc-hotel"
