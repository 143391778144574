const STATS_QUERY = `
ingoStatsResponse{
  modelType1{
    success
    currency
    data{
      metrics{
        name
        score
        description
      }
    }
  }
}
`;

const ADSTATUS_QUERY = `
ingoWelcomeResponse {
  modelType1 {
    data {
      campaignEligibility {
        header
        eligibilityMap{
          MMT {
          isValidUserRating
          isCityConfigured
          questionAnswer {
            que
            ans
          }
          cta {
            text
            desc
            imgUrl
            redirectUrl
          }
        }
          GI {
          isValidUserRating
          isCityConfigured
          questionAnswer {
            que
            ans
          }
          cta {
            text
            desc
            imgUrl
            redirectUrl
          }
        }
        }
        adStatus {
          configured
          nextPage
          defaultWeek
        }
      }
    }
  }
}

`;

const SL_SDK_QUERY = `
ingoWelcomeResponse {
  modelType1 {
    data {
      campaignEligibility {
        adStatus {
          sdkConfigured
        }
      }
    }
  }
}
`;

const REPORTING_QUERY = `
reportingDashboard{
     modelType1{
        message
        requestId
        dashboardData{
          campaignData{
            returnStates
            liveCampaign{
              campaignsCount
              campaignList  
                
              
            }
            scheduledCampaign{
              campaignsCount
               campaignList  
              notificationInfo
              sponsoredListingInfo{
                heading
                txtAndImg{
                  heading
                  desc
                  imgUrl
                }
              }
            }
            fulfilledCampaign{
             campaignsCount
               campaignList  
              persuasion
              summary{
                normalData{
                  type
                  renderType
                  text
                  icon
                  tooltip
                  value{
                    key1
                  }
                }
                highlightedData{
                  type
                  renderType
                  text
                  icon
                  tooltip
                  value{
                    key1
                  }
                }
              }
            }
            refundedCampaign{
              campaignsCount
              campaignList
                
              summary{
                normalData{
                  type
                  renderType
                  text
                  icon
                  tooltip
                  value{
                    key1
                  }
                }
                highlightedData{
                  type
                  renderType
                  text
                  icon
                  tooltip
                  value{
                    key1
                  }
                }
              } 
            }
            
          }
        }
      }
}

`;

const PACKAGES_SOLDOUT_QUERY = `
adtechGetPackages{
  modelType1{
    message
    requestId
    data{
      packages{
        packageDetails{
          MMT{
            soldOut
            emptyPackageDetails{
              imgUrl
              text
              desc
            }
          }
          GI{
            soldOut
            emptyPackageDetails{
              imgUrl
              text
              desc
            }
          }
        }
      }
    }
  }
}


`;

export const GET_SPONSORED_LIST = `
query SponsoredListing($rawEndpointsRequestData: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $rawEndpointsRequestData) {
    ${REPORTING_QUERY}
    ${STATS_QUERY}
    ingoWelcomeResponse{
      modelType1{
        success
        currency
        requestId
        data{
          card{
            header
            desc
            cta{
              text
              desc
              imgUrl
              redirectUrl
            }
          }
          toolTip{
            header
            cta{
              text
              desc
              imgUrl
              redirectUrl
            }
            body{
              content{
                k1
                k2
              }
            }
            headerLabel{
              text
              desc
              imgUrl
              redirectUrl
            }
          }
          helpSection{
            header
            body{
              availableBrand
              gi{
                desc
                imgUrl
              }
              mmt{
                desc
                imgUrl
              }
            }
          }
          footer{
            desc
            cta{
              text
              desc
              imgUrl
              redirectUrl
            }
          }
          ingoDashBoard{
            cta{
              text
              desc
              imgUrl
              redirectUrl
            }
            contentData{
              text
              desc
              contentUrl
              contentType
              redirectUrl
            }
            body{
              header
              subHeader
              content{
                dataItems{
                  que
                  ans
                }
              }
            }
          }
          faqs{
            header
            questionAnswerList{
              que
              ans
            }
            cta{
              text
              redirectUrl
            }
          }
          campaignEligibility{
            header
            eligibilityMap{
              MMT{
                isValidUserRating
                isCityConfigured
                questionAnswer{
                  que
                  ans
                }
                cta{
                  text
                  desc
                  imgUrl
                  redirectUrl
                }
              }
              GI{
                isValidUserRating
                isCityConfigured
                questionAnswer{
                  que
                  ans
                }
                cta{
                  text
                  desc
                  imgUrl
                  redirectUrl
                }
              }
            }
            inEligibleMessage{
              text
              desc
            }
            adStatus{
              configured
              nextPage
              defaultWeek
              sdkConfigured
            }
          }
          testimonials{
            testimonialsList{
              desc
              hotelName
              hotelCity
              contentUrl
              contentType
            }
          }
        }
      }
    }
  }
}

`;

export const GET_REPORTINGDASHBOARD_PS_LIST = `
query SponsoredListing($rawEndpointsRequestData: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $rawEndpointsRequestData) {

    ${PACKAGES_SOLDOUT_QUERY}
  }
}

`;

export const INITIATE_PAYMENT = `
query SponsoredListing($rawEndpointsRequestData: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $rawEndpointsRequestData) {
    adtechBeginCheckout{
      modelType1{
        success
        message
        data{
          txnId
          checkoutUrl
          checkoutId
          paymentType
        }
      }
    }
  }
}
`;

export const PAYMENT_STATUS = `
query SponsoredListing($rawEndpointsRequestData: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $rawEndpointsRequestData) {
    adtechPaymentStatus{
      modelType1{
        success
        error
        message
        txnId
        data{
          paymentConfirmation{
            imgUrl
            text
            desc
            packageDetails{
              name
              actualPrice
              discountedPrice
              total
              taxes
              taxBreakUp{
                name
                perc
                amount
              }
              cta{
                text
                desc
                imgUrl
              }
            }
          }
          paymentFailure{
            imgUrl
            text
            desc
            suggestion
            date
          }
        }
      }
    }
  }
}
`;

export const GET_SPONSORED_PACKAGES = `
query SponsoredListing($rawEndpointsRequestData: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $rawEndpointsRequestData) {
    ${ADSTATUS_QUERY}
    ${STATS_QUERY}
    adtechGetPackages{
      modelType1{
        message
        requestId
        data{
          packages{
            header{
              text
              desc
              cta{
                redirectUrl
                text
                imgUrl
              }

            }
            glblPersuasion{
              imgUrl
              text
              desc
            }
            campaignConstraints{
              defaultWeek
              enableWeekBtn
              weekOptions
              availableWeeks{
                MMT{
                  label
                  key
                }
                GI{
                  label
                  key
                }
              }
            }
            packageDetails{
              MMT{
                foundPackages
                soldOut
                emptyPackageDetails{
                  imgUrl
                  text
                  desc
                }
                packages{
                  clicksText
                  showStrikedTotalPrice
                  duration
                  totalClicks
                  packageId
                  originalValue
                  discountedValue
                  discount
                  currency
                  persuasion{
                    imgUrl
                    text
                    persType
                  }
                  clickDetails{
                    text
                  }
                  pckgStartDate{
                    text
                  }
                  paymentOption{
                    type
                    popupType
                    description
                    confirmationReqd
                    paymentConfirmationDetails
                  }
                  totalPrice{
                    discountedPrice
                    total
                    taxes
                    taxBreakUp{
                      name
                      perc
                      amount
                    }
                  }
                  cta{
                    redirectUrl
                    text
                    imgUrl
                  }
                  soldOut
                }
              }
              GI{
                foundPackages
                soldOut
                emptyPackageDetails{
                  imgUrl
                  text
                  desc
                }
                packages{
                  clicksText
                  showStrikedTotalPrice
                  duration
                  totalClicks
                  packageId
                  originalValue
                  discountedValue
                  discount
                  currency
                  persuasion{
                    imgUrl
                    text
                    persType
                  }
                  clickDetails{
                    text
                  }
                  pckgStartDate{
                    text
                  }
                  paymentOption{
                    type
                    popupType
                    description
                    confirmationReqd
                    paymentConfirmationDetails
                  }
                  totalPrice{
                    discountedPrice
                    total
                    taxes
                    taxBreakUp{
                      name
                      perc
                      amount
                    }
                  }
                  cta{
                    redirectUrl
                    text
                    imgUrl
                  }
                  soldOut
                }
              }
            }
            faqs{
              id
              heading
              desc
            }
          }
        }
      }
    }
  }
}

`;

export const GET_REFUND_CAMPAIGN_REASON = `
query SponsoredListing($rawEndpointsRequestData: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $rawEndpointsRequestData) {
    getCampaignRefundReasons {
      modelType1 {
        success
        message
        data{
          refundReason
          adId
          positionInList
        }
      }
    }
  }
}
`;

export const GET_REFUND_DETAILS_CAMPAIGN = `
query SponsoredListing($rawEndpointsRequestData: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $rawEndpointsRequestData) {
    adtechGetRefundCampaign{
      modelType1{
        status
        paymentDetails{
          paymentType
          campaignAmount
          campaignDate
          paymentId
          adjustmentId
        }
        eventDetails{
          total
          used
          usedAmount
          eventType
        }
        refundDetails{
          invoiceUrl
          paymentStatus
          refundAmount
          note
          settlementDetails{
            amount
            date
            refId
            settlementType
          }
        }
      }
    }
  }
}
`;

export const PUSH_TO_SANDESH = `
query Sandesh($sandeshKafkaInput: SandeshKafkaInput) {
  pushToSandesh(sandeshKafkaInput: $sandeshKafkaInput) {
    success
  }
}
`;

export const SOLD_OUT_NOTIFY = `
query SponsoredListing($rawEndpointsRequestData: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $rawEndpointsRequestData) {
    ingoNotifyResponse{
      modelType1{
        success
        requestId
        message
        errorCode
        errorMsg
      }
    }
  }
}
`;

export const GET_REPORTING_DETAILS = `
  query SponsoredListing($rawEndpointsRequestData: RawEndpointsRequestData) {
    rawEndpoint(rawEndpointRequestData: $rawEndpointsRequestData) {
      reportingGetDetails {
        modelType1 {
          message
          requestId
          campaignDetailedData{
            adId
            campaignName
            paymentType
            currency
            campaignRunTime
            data{
             campaignDetails{
              text
              campaignDetailsCards{
                text
                value
                percentChange
                startedFrom
                fulfilled
                tooltipIcon
                tooltipText
                img
                info
                bannerType
              }
                persuasionDetailsList{
              header
              desc
              text
              textLink
            }
            timeFilter
            graphList
              dayWiseBreakup{
              text
              dayWiseInfo
              headers{
                  date{
                    text
                    subheaderOrder
                  }
                  clicks{
                    text
                  subheaderOrder
                  }
                 roomNights{
                  text
                  subheaderOrder
                }
                roas{
                  text
                  subheaderOrder
                }
                revenue{
                  text
                  subheaderOrder
                }
                }
              subHeadersText{
                  date
                  clicksServed
                clicksCosts
                attributedRoomNights
                roomNightsOverall
                attributedRevenue
                revenueOverall
                roas

                }
              trends{
                total{
                  date
                 clicksServed
                 clicksCosts
                 attributedRoomNights
                 roomNightsOverall
                 attributedRevenue
                 revenueOverall
                 roas
               }
                daywise{
                  date
                  clicksServed
                  clicksCosts
                  roas
                  attributedRoomNights
                  roomNightsOverall
                  attributedRevenue
                  revenueOverall

                }
              }

              }
             campaignPerformanceComparison{
              reason{
                header
                reasonList{
                  reason
                }
              }
              camparisonDetails{
                text
                percentChange
                comparisonData{
                  text
                  value
                }
                fulfilled
                tooltipIcon
                tooltipText
                startedFrom
                img
                info


              }
            }
              faq{
                heading
                txtAndImg{
                  heading
                  desc
                  imgUrl
                }
              }



            }
              adjustmentDetails{
                campaignAmount
                adjustedAmount
                pendingAmount
                adjustmentBreakup{
                   adjustmentDate
                  bookingId
                  adjustmentAmount
                  balancedAmount
                }
              }
              downloadDetails{
                hash
                download{
                  downloadInvoice
                  downloadReport
                }
              }

            }
          }

        }
      }
    }
  }
`;

export const GET_GRAPH_API = `
query SponsoredListing($rawEndpointsRequestData: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $rawEndpointsRequestData) {
    reportingGetGraph {
      modelType1 {
        message
        requestId
        adId
        timeFilter
        graph   
        }
      }
    }
  }
`;
export const GET_TOUCH_POINTS_DETAILS = `
query SponsoredListing($rawEndpointsRequestData: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $rawEndpointsRequestData) {
    sponsoredList {
      modelType1 {
        requestingLob
        requestId
        pageTypeEnum
        persuasionList {
          persuasion {
            htmlContent
            icon
            headerText
            cta {
              redirectTxt
              action
            }
          }
          revenueGraph {
            text
            toolTipData
            startIndicator
            legend {
              priorCampaignStart
              duringCampaign
              estimatedWithoutCampaign
            }
            revenueList {
              label
              priorCampaignStart
              duringCampaign
              estimatedWithoutCampaign
            }
            revenuePersusation {
              text
            }
          }
        }
      }
    }
  }
}
`;

export const IS_SL_SDK_ENABLED = `
query SponsoredListing($rawEndpointsRequestData: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $rawEndpointsRequestData) {
    ${SL_SDK_QUERY}
  }
}
`;
