import { routes } from 'app/routes';

export const compareWithFilters = [
  { key: 'hotel', value: 'Your competitors' },
  // {key : "locality", value: "All hotels in your locality"},
  // {key : "city", value: "All hotels in your city"}
];
export const periodFilters = [
  { key: '7-days', value: 'Last 7 days' },
  { key: '15-days', value: 'Last 15 days' },
  { key: '30-days', value: 'Last 30 days' },
  { key: '3-months', value: 'Last 3 months' },
  { key: '6-months', value: 'Last 6 months' },
];

export const daysPeriodFilters = [
  { key: '7-days', value: 'Last 7 days' },
  { key: '15-days', value: 'Last 15 days' },
];

export const brandFilters = [
  { key: 'all', value: 'All Brands' },
  { key: 'goibibo', value: 'Goibibo' },
  { key: 'mmt', value: 'MakeMyTrip' },
];

export const basedOnFilters = [
  { key: 'booking', value: 'Booking date' },
  { key: 'stay', value: 'Stay date' },
];
export const basedOnNewFilters = [
  { key: 'booking', value: 'Booking date' },
  { key: 'stay', value: 'Checkin date' },
];

export const months = {
  0: 'JAN',
  1: 'FEB',
  2: 'MAR',
  3: 'APR',
  4: 'MAY',
  5: 'JUN',
  6: 'JUL',
  7: 'AUG',
  8: 'SEP',
  9: 'OCT',
  10: 'NOV',
  11: 'DEC',
};

export const demandperiodFilters = [
  { key: '7-days', value: 'Last 7 days' },
  { key: '15-days', value: 'Last 15 days' },
  { key: '3-months', value: 'Last 3 months' },
];

export const demandbrandFilters = [
  { key: 'goibibo', value: 'Goibibo' },
  { key: 'mmt', value: 'MakeMyTrip' },
];

export const demandbasedOnFilters = [
  { key: 'booking', value: 'Search date' },
  { key: 'stay', value: 'Stay date' },
];

export const PricingbrandFilters = [
  { key: 'goibibo', value: 'Goibibo' },
  { key: 'mmt', value: 'MakeMyTrip' },
];

export const ugcbrandFilters = [
  { key: 'goibibo', value: 'Goibibo' },
  { key: 'mmt', value: 'MakeMyTrip' },
];

export const ugcperiodFilters = [
  { key: '7-days', value: 'Last 7 days' },
  { key: '15-days', value: 'Last 15 days' },
];

export const BOOKING_DAYS = ['D0', 'D1', 'D-2-3', 'D-4-5', 'D-6-10', 'D-10+'];
export const COMPARE_WITH = ['Last Year', 'Competitor', 'City'];
export const DAYS = ['1', '2', '3', '4', '5-7', '8-14', '15-30', '30+'];
export const PERIOD_FILTER_OPTIONS = [
  { label: 'Month till Date', value: 'Month till Date' },
  { label: 'Year till Date', value: 'Year till Date' },
];

export const TRANSACTION = 'Transaction';
export const UGC = 'ugc';
export const DEMAND = 'demand';
export const DEMAND_V2 = 'demand_v2';
export const PARITY = 'Parity';
export const PRICING = 'Pricing';
export const CONTENTSCORE = 'ContentScore';
export const RECOMMENDATION = 'Recommendation';

export const ANALYTICS_TABS_INDEX = {
  PERFORMANCE_ANALYTICS: 0,
  BOOKING_INSIGHTS: 1,
  ACTIVITY_FEED: 2,
  GUEST_RATING_INSIGHTS: 3,
  FUTURE_TRENDS: 4,
  CONTENT_SCORE: 5,
  RECOMMENDATION: 6,
};

export const PRICING_TOTAL_DAYS = 30;
export const PRICING_PAGE_SIZE = 10;

// ---------------- Content score constants ----------------

export const typeIdentifier = TYPESTRINGS => ({
  Description: {
    btnText: TYPESTRINGS.DESCRIPTION.BTN_TEXT,
    redirectUrl: routes.property.propertyDetails,
    gtmLabel: 'add_property_description',
  },
  hotel_images_tag: {
    btnText: TYPESTRINGS.HOTEL_IMAGES_TAGGING.BTN_TEXT,
    redirectUrl: routes.property.photosAndVideos.base,
    gtmLabel: 'update_property_images_tagging',
  },
  hotel_images_quality: {
    btnText: TYPESTRINGS.HOTEL_IMAGES_QUALITY.BTN_TEXT,
    redirectUrl: routes.property.photosAndVideos.base,
    gtmLabel: 'update_property_images_quality',
  },
  house_rules_details: {
    btnText: TYPESTRINGS.HOUSE_RULE_DETAILS.BTN_TEXT,
    redirectUrl: routes.property.propertyRules,
    gtmLabel: 'update_property_house_rules_details',
  },
  food_service_details: {
    btnText: TYPESTRINGS.FOOD_SERVICE_DETAILS.BTN_TEXT,
    redirectUrl: routes.property.foodService,
    gtmLabel: 'update_property_food_service_details',
  },
  hotel_amenities: {
    btnText: TYPESTRINGS.ROOM_AMENITIES.BTN_TEXT,
    redirectUrl: routes.property.functionalService,
    gtmLabel: 'add_property_amenities',
  },

  room_details: {
    btnText: TYPESTRINGS.ROOM_DETAILS.BTN_TEXT,
    redirectUrl: routes.property.rooms,
    gtmLabel: 'update_room_details',
  },
  room_amenities: {
    btnText: TYPESTRINGS.ROOM_AMENITIES.BTN_TEXT,
    redirectUrl: routes.property.functionalService,
    gtmLabel: 'add_room_amenities',
  },
  room_images_quality: {
    btnText: TYPESTRINGS.ROOM_IMAGES_QUALITY.BTN_TEXT,
    redirectUrl: routes.property.photosAndVideos.base,
    gtmLabel: 'update_room_images_quality',
  },
  room_images_tag: {
    btnText: TYPESTRINGS.ROOM_IMAGES_TAGGING.BTN_TEXT,
    redirectUrl: routes.property.photosAndVideos.base,
    gtmLabel: 'update_room_images_tagging',
  },
  address: {
    btnText: TYPESTRINGS.ADDRESS.BTN_TEXT,
    redirectUrl: routes.property.propertyDetails,
    gtmLabel: 'add_address',
  },
  email: {
    btnText: TYPESTRINGS.EMAIL.BTN_TEXT,
    redirectUrl: routes.property.contact,
    gtmLabel: 'add_email',
  },
  phoneNumber: {
    btnText: TYPESTRINGS.PHONE_NUMBER.BTN_TEXT,
    redirectUrl: routes.property.contact,
    gtmLabel: 'add_phone_number',
  },
  starRating: {
    btnText: TYPESTRINGS.STAR_RATING.BTN_TEXT,
    redirectUrl: routes.property.propertyDetails,
    gtmLabel: 'add_star_rating',
  },
  location: {
    btnText: TYPESTRINGS.LOCATION.BTN_TEXT,
    redirectUrl: routes.property.propertyDetails,
    gtmLabel: 'update_location',
  },
  childAge: {
    btnText: TYPESTRINGS.CHILD_AGE.BTN_TEXT,
    redirectUrl: routes.property.otherPolicies,
    gtmLabel: 'update_child_age',
  },
  masterImageTag: {
    btnText: TYPESTRINGS.MASTER_IMAGE_TAG.BTN_TEXT,
    redirectUrl: routes.property.photosAndVideos.base,
    gtmLabel: 'add_master_image',
  },
});

export const propertyKPIMap = {
  room_nights: 'sum',
  net_amount: 'sum',
  sell_amount: 'custom',
  demandHotelVisits: 'sum',
  visits: 'sum',
  conversionRate: 'custom',
  bookings: 'sum',
};

export const PREVIOUS_INTERVAL_STAT = 'PREVIOUS_INTERVAL_STAT';
export const CURRENT_INTERVAL_STAT = 'CURRENT_INTERVAL_STAT';
export const STAY_INTERVAL = 'Stay Interval';
export const CONFIRM_STATUS_VALUE = [
  'pending',
  'confirmed',
  'amended',
  'noshow',
  'aborted',
];

export const LOS_TREND_CUSTOM_INTERVALS = [
  ['1', 1, 2],
  ['2', 2, 3],
  ['3', 3, 4],
  ['4', 4, 5],
  ['5-7', 5, 8],
  ['8-14', 8, 15],
  ['15-30', 15, 31],
  ['30+', 31, 1000],
];

export const MEAL_PLAN_INTERVALS = [
  {
    type: 'filter',
    filter_intervals: {
      AP: {
        include: {
          mealPlanValue: 'AP',
        },
      },
      CP: {
        include: {
          mealPlanValue: 'CP',
        },
      },
      EP: {
        include: {
          mealPlanValue: 'EP',
        },
      },
      MAP: {
        include: {
          mealPlanValue: 'MAP',
        },
      },
      Others: {
        exclude: {
          mealPlanValue: ['AP', 'CP', 'EP', 'MAP'],
        },
      },
    },
  },
];

export const ROOM_NIGHTS_PAX_INTERVALS = [
  {
    type: 'filter',
    filter_intervals: {
      '1A0C': {
        include: {
          noOfAdults: 1,
          noOfChildren: 0,
        },
      },
      '1A1C': {
        include: {
          noOfAdults: 1,
          noOfChildren: 1,
        },
      },
      '2A0C': {
        include: {
          noOfAdults: 2,
          noOfChildren: 0,
        },
      },
      '2A1C': {
        include: {
          noOfAdults: 2,
          noOfChildren: 1,
        },
      },
      '2A2C': {
        include: {
          noOfAdults: 2,
          noOfChildren: 2,
        },
      },
      '3A0C': {
        include: {
          noOfAdults: 3,
          noOfChildren: 0,
        },
      },
      '3A1C': {
        include: {
          noOfAdults: 3,
          noOfChildren: 1,
        },
      },
      '3A2C': {
        include: {
          noOfAdults: 3,
          noOfChildren: 2,
        },
      },
      Others: {
        include: {
          noOfAdults: {
            gt: 3,
          },
        },
      },
    },
  },
];

export const AnalyticsPagesSectionsDataKeys = {
  ROOM_NIGHTS: 'Room Nights',
  REVENUE: 'Revenue',
  ASP_TOP: 'ASP_TOP',
  ASP_MAIN: 'ASP_MAIN',
  PROPERTY_VISITS: 'Property Visits',
  CONVERSION: 'Conversion',
  TABS: 'TabsData',
  GET_WISHLIST: 'GET_WISHLIST',
  UPDATE_WISHLIST: 'UPDATE_WISHLIST',
  WISHLIST: 'WISHLIST',
};

export const actionTypes = {
  FETCHING: 'fetching',
  SUCCESS: 'success',
  FAILED: 'failed',
  RESET: 'reset',
  UPDATE: 'update',
};
