import { Button, Typography } from 'connect-web-ui';
import { NewContainer } from 'components/common';
import { Auto } from 'assets/modules/howToReach';
import { classNames } from 'utilities/Utils';
import { makeStyles } from 'connect-web-ui/mui';
import { ReactNode } from 'react';
interface FooterLayoutProps {
  negativeText?: ReactNode;
  positiveText?: ReactNode;
  positiveButtonClick?: () => void;
  negativeButtonClick?: () => void;
  disabledFlag?: boolean;
  twoColLayout?: boolean;
  children?: React.ReactElement | [React.ReactElement, React.ReactElement];
  renderRightCard?: React.ReactNode;
  btnClassName?: string;
  errorMessage?: string;
  positiveButtonConfig?; // todo: ButtonProps type not exported
  negativeButtonConfig?; // todo: ButtonProps type not exported
}
const useStyles = makeStyles({
  footer: {
    position: 'fixed',
    bottom: 0,
    left: '88px',
    right: 0,
    height: '72px',
    background: '#FFFFFF',
    boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.1)',
    zIndex: 1200,
  },
  subhead: {
    height: Auto,
    marginTop: '16px',
    marginBottom: '16px',
    '&:not([style*="white-space:"])': {
      whiteSpace: 'pre !important',
    },
  },
  marginL: {
    marginLeft: '419px',
  },
});
const FooterLayout = (props: FooterLayoutProps) => {
  const {
    renderRightCard = null,
    negativeText = '',
    positiveText = '',
    positiveButtonClick,
    negativeButtonClick = null,
    disabledFlag,
    twoColLayout = false,
    children,
    btnClassName = '',
    errorMessage = '',
    positiveButtonConfig = {},
    negativeButtonConfig = {},
  } = props;
  const classes = useStyles();

  return (
    <>
      <div className={classes.footer}>
        <NewContainer
          widthSrContainer
          twoColLayout={twoColLayout}
          className="!py-0 !min-h-0"
        >
          <div
            className={classNames(
              'h-[72px] flex',
              'justify-between w-full',
              'items-center',
            )}
          >
            {negativeButtonClick ? (
              <Button
                variant="text"
                className={!negativeText ? 'w-full' : ''}
                onClick={negativeButtonClick}
                data-test-id="footer-negative-button"
                {...negativeButtonConfig}
              >
                {negativeText}
              </Button>
            ) : (
              <Typography>{negativeText || ''}</Typography>
            )}
            <div className="flex align-center">
              {errorMessage && (
                <Typography themeColor="error.main" className="flex pr-4">
                  {errorMessage}
                </Typography>
              )}
              {children}
              {!!positiveText && (
                <Button
                  disabled={disabledFlag}
                  className={classNames(classes.subhead, btnClassName)}
                  onClick={positiveButtonClick}
                  data-test-id="footer-positive-button"
                  {...positiveButtonConfig}
                >
                  {positiveText}
                </Button>
              )}
              {renderRightCard}
            </div>
          </div>
        </NewContainer>
      </div>
    </>
  );
};

export default FooterLayout;
