import { Tooltip } from 'connect-web-ui';
import { ReactElement } from 'react';

interface IConditionalTooltipWrapper {
  showTooltip: boolean;
  children: ReactElement;
  tooltipContent: string;
}

export const SideNavConditionalTooltipWrapper = ({
  showTooltip = false,
  children,
  tooltipContent,
}: IConditionalTooltipWrapper) => {
  if (!showTooltip) return <>{children}</>;
  return (
    <Tooltip content={tooltipContent} bgColor="common.black">
      {children}
    </Tooltip>
  );
};
