export const GET_DEALS_LIST_QUERY = `  query getPromotionsAndBenefits($endpointRequestData: EndpointRequestData, $rawEndpointRequestData: RawEndpointsRequestData) {
  fetchServicesV2(endpointRequestData: $endpointRequestData) {
      success
      message
      errorCode
      data
  }
  rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
      rpcGetOffer {
        success
        message
        errorCode
        correlationKey
        data {
          offerDataList {
            offerCode
            dealName
            dealType
            offerPerformanceData {
              offerRoomNights
              offerRevenue
            }
            expiringIn
            offerValueDataList {
              offerValue
              offerType
              segment
              id
              isActive
              discountErrorMessage
            }
            isActive
            status
            modifiedOn
            createdOn
            checkoutdateend
            checkinblackoutdates
            bookingdateend
            checkindatestart
            bookingdatestart
            showModification
            campaignDetails {
              imageUrl
              campaignId
            }
            minNights
            night
            offerCategory
            parentGroupId
          
          timeBasedOfferData {
            offerCode
            startTime
            endTime
            isApplicableTillNextDay
            offerValueDataList {
              segment
              isActive
              offerValue
             }
            }
          }
          hotelPerformanceData {
            hotelRoomNights
            hotelRevenue
          }
          statusCountData {
            active
            expired
            inActive
          }
        }
      }
    }
    rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
      rpcGetHotelDetailforMMTIdV2 {
        request_id
        hotel_details {
            blackEnrolledOn
        } 
      }
    }
}`;

export const GET_DEALS_META_DATA_QUERY = `query GetOffersMetadataV2($endpointRequestData: EndpointRequestData,
  $NetAvailableAndBookedInventoryRequestData: RawEndpointsRequestData,
  $BookedInventoryShareRequestData: RawEndpointsRequestData,
  $CompsetBookingsOnCugRequestData: RawEndpointsRequestData
)
{
  GetOffersMetadataV2(endpointRequestData: $endpointRequestData) {
    success
    message
    errorCode
    correlationKey
    data
  }
  netAvailableAndBookedInventory: rawEndpoint(rawEndpointRequestData: $NetAvailableAndBookedInventoryRequestData){
    analyticsHistogram {
      modelType1 {
        data
        message
        status
        status_code
      }
    }
  }
  bookedInventoryShare: rawEndpoint(rawEndpointRequestData: $BookedInventoryShareRequestData){
    analyticsHistogram {
      modelType1 {
        data
        message
        status
        status_code
      }
    }
  }
  compsetBookingsOnCug: rawEndpoint(rawEndpointRequestData: $CompsetBookingsOnCugRequestData){
    analyticsHistogram {
      modelType1 {
        data
        message
        status
        status_code
      }
    }
  }
}`;

export const CREATE_UPDATE_CUG_MUTATION = `mutation RpcUpdateCugV2($endpointRequestData: EndpointRequestData) {
  RpcUpdateCugV2(endpointRequestData: $endpointRequestData) {
      success
      mmtId
      message
      errorCode
       errorData {
        title
        content {
            heading
            bulletedList
            subText
        }
      }
      correlationKey
      data {
        success
        message
        errorCode
        offerData
        __typename
      }
  }
}`;

export const CREATE_CUG_MUTATION = `mutation RpcUpdateCug($rawEndpointRequestData: RawEndpointsRequestData) {
  rawEndpoint(endpointRequestData: $rawEndpointRequestData) {
    rpcUpdateCug {
      success
      mmtId
      message
      errorCode
      correlationKey
       data {
        success
        message
        errorCode
        offerData {
          offerCode
          offerName
          bookingDateStart
          bookingDateEnd
          checkinDateStart
          checkoutDateEnd
          checkinBlackoutDates
          offerValueList {
            isActive
            offerValue
            segment
          } 
          timeBasedOfferData {
            startTime
            endTime
            offerValueList {
              isActive
              offerValue
              segment
            } 
            offerCode
          }
        }
      }
    }
  }
}`;

export const CREATE_UPDATE_COUPON = `mutation RpcUpdateCoupon($rawEndpointRequestData: RawEndpointsRequestData) {
  rawEndpoint(endpointRequestData: $rawEndpointRequestData) {
      rpcUpdateCoupon {
          success
          mmtId
          message
          errorCode
          correlationKey
          data {
            success
            message
            errorCode
            offerData {
              offerCode
              offerName
              bookingDateStart
              bookingDateEnd
              checkinDateStart
              checkoutDateEnd
              checkinBlackoutDates
              offerValue{ 
                value
              }              
            }
          }
        }
      }
    }`;
