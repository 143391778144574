import React from 'react';
import {
  AppContextInterface,
  BankDetailsContextInterface,
  BookingsContextInterface,
  ChainContextInterface,
  GuestChatConversationContextInterface,
  HotelContextInterface,
  InclusionsContextInterface,
  InventoryContextInterface,
  InventoryTableContextInterface,
  OnboardingContextInterface,
  PerformanceContextInterface,
  PhotosContextInterface,
  QualityScoreContextInterface,
  VideosContextInterface,
  PropertyContextInterface,
  FoodServiceContextInterface,
  ReviewsContextInterface,
  BookingRevampContextInterface,
  GroupContextInterface,
  GroupRatesInterface,
  OfferedRatesContextInterface,
  UserManagementContextInterface,
  ParityContextInterface,
  ReviewContextInterface,
  LinkedRatesContextInterface,
  LinkedRatesStepperContextInterface,
  InstagramContextInterface,
  SaleHandlingContextInterface,
  PropertyInfoContextInterface,
  AuthContextInterface,
} from '../interfaces';
import CompsetContextInterface from 'interfaces/CompsetContextInterface';
import AnalyticsContextInterface from 'interfaces/AnalyticsContextInterface';
import DealsContextInterface from 'interfaces/PncContextInterface';
import MediaContextInterface from 'interfaces/MediaContextInterface';
import { CompAnalysisContextInterface } from 'interfaces/CompAnalysisContext';
import { IBlackGoTribeContextInterface } from 'interfaces/BlackGoTribeContext';
import { ReducerAction } from 'modules/black-goTribe/types/reducer';
import { CancellationPolicyContext as CancellationPolicyContextType } from 'interfaces/AuthContextInterface';

export const AuthContext = React.createContext<AuthContextInterface>(null);
export const ParityContext = React.createContext<ParityContextInterface>(null);
export const AppContext = React.createContext<AppContextInterface>(null);
export const HotelContext = React.createContext<HotelContextInterface>(null);
export const GroupContext = React.createContext<GroupContextInterface>(null);
export const OnboardingContext =
  React.createContext<OnboardingContextInterface>(null);
export const ChainContext = React.createContext<ChainContextInterface>(null);
export const PhotosContext = React.createContext<PhotosContextInterface>(null);
export const VideosContext = React.createContext<VideosContextInterface>(null);
export const QualityScoreContext =
  React.createContext<QualityScoreContextInterface>(null);
export const InclusionsContext =
  React.createContext<InclusionsContextInterface>(null);
//For all of Inventory route - all, optimization, bulk
export const InventoryContext =
  React.createContext<InventoryContextInterface>(null);
//For only table view on /all route
export const InventoryTableContext =
  React.createContext<InventoryTableContextInterface>(null);
export const PerformanceContext =
  React.createContext<PerformanceContextInterface>(null);
export const DealsContext = React.createContext<DealsContextInterface>(null);
export const BankDetailsContext =
  React.createContext<BankDetailsContextInterface>(null);
export const BookingsContext =
  React.createContext<BookingsContextInterface>(null);
export const GuestChatConversationContext =
  React.createContext<GuestChatConversationContextInterface>(null);
export const PropertyContext =
  React.createContext<PropertyContextInterface>(null);
export const ReviewContext = React.createContext<ReviewContextInterface>(null);
export const FoodServiceContext =
  React.createContext<FoodServiceContextInterface>(null);
export const ReviewsContext =
  React.createContext<ReviewsContextInterface>(null);
export const BookingsRevampContext =
  React.createContext<BookingRevampContextInterface>(null);
export const GroupBookingsContext =
  React.createContext<GroupRatesInterface>(null);
export const OfferedRatesContext =
  React.createContext<OfferedRatesContextInterface>(null);
export const UserManagementContext =
  React.createContext<UserManagementContextInterface>(null);
export const InstagramContext =
  React.createContext<InstagramContextInterface>(null);
export const LinkedRatesContext =
  React.createContext<LinkedRatesContextInterface>(null);
export const CompsetContext =
  React.createContext<CompsetContextInterface>(null);
export const PropertyInfoContext =
  React.createContext<PropertyInfoContextInterface>(null);
export const AnalyticsContext =
  React.createContext<AnalyticsContextInterface>(null);
export const LinkedRatesStepperContext =
  React.createContext<LinkedRatesStepperContextInterface>(null);
export const MediaContext = React.createContext<MediaContextInterface>(null);
export const SaleHandlingContext =
  React.createContext<SaleHandlingContextInterface>(null);
export const CompAnalysisContext =
  React.createContext<CompAnalysisContextInterface>(null);
export const BlackGoTribeContext =
  React.createContext<IBlackGoTribeContextInterface>(null);
export const BlackGoTribeDispatchContext =
  React.createContext<React.Dispatch<ReducerAction>>(null);
export const CancellationPolicyContext =
  React.createContext<CancellationPolicyContextType>(null);
