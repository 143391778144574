import { routes } from 'app/routes';
import { Modal } from 'connect-web-ui';
import { navigateTo } from 'utilities/Utils';
import IntroVideoModal from 'components/common/IntroVideoModal/IntroVideoModal';
import { useContext, useEffect, useState } from 'react';
import { useLangStrings } from 'utilities/CustomHooks';
import { pushToOmniture } from 'utilities/gtm';
import { HotelContext } from 'utilities/Context';
import { IAgreement } from 'interfaces/HotelContextInterface';

type ResellerOnboardingPopupProps = {
  onClose: () => void;
  onConfirm: () => void;
  isAgreementAccepted: boolean;
};

type ResellerOnboardingPopupWrapperProps = {
  isRedirectedFromResellerLink: boolean;
  resellerAgreement: IAgreement;
};

type ResellerIntroVideoProps = {
  onClose: () => void;
  onConfirm: () => void;
  isComingFromOnboarding: boolean;
};

const ResellerOnboardingPopup = (props: ResellerOnboardingPopupProps) => {
  const { onClose, onConfirm, isAgreementAccepted } = props;

  const [STRINGS, COMMON] = useLangStrings<'Dashboard'>('Dashboard');

  useEffect(() => {
    pushToOmniture({
      event: 'pageView',
      pageType: !isAgreementAccepted
        ? 'popup_onboard_hoteltravel'
        : 'popup_hoteltravel_resume_onboarding',
    });
  }, []);

  return (
    <Modal
      isOpen
      variant="confirm"
      size="small"
      header={{
        title: !isAgreementAccepted
          ? STRINGS.RESELLER_ONBOARDING_POPUP.TITLE_1
          : STRINGS.RESELLER_ONBOARDING_POPUP.TITLE_2,
      }}
      footer={{
        primaryBtn: {
          onClick: onConfirm,
          text: COMMON.LABELS.YES,
        },
        secondaryBtn: {
          onClick: onClose,
          text: COMMON.BUTTONS.CLOSE_TXT,
        },
      }}
      customClasses={{ paper: 'z-2' }}
    >
      <div className="flex flex-col font16">
        <div>
          {!isAgreementAccepted
            ? STRINGS.RESELLER_ONBOARDING_POPUP.RESELLER_INTEREST_TEXT
            : STRINGS.RESELLER_ONBOARDING_POPUP.RESELLER_DROPOFF_TEXT}
        </div>
        {!isAgreementAccepted && (
          <div className="font-medium mt-[10px]">
            {STRINGS.RESELLER_ONBOARDING_POPUP.CONTINUE_TEXT}
          </div>
        )}
      </div>
    </Modal>
  );
};

export const ResellerIntroVideo = (props: ResellerIntroVideoProps) => {
  const { onClose, onConfirm, isComingFromOnboarding } = props;

  const [STRINGS] = useLangStrings<'Dashboard'>('Dashboard');

  useEffect(() => {
    pushToOmniture({
      event: 'pageView',
      pageType: 'popup_hoteltravel_onboarding_video',
    });
  }, []);

  return (
    <IntroVideoModal
      langVideoUrlMap={{
        english: 'https://www.youtube.com/embed/zbrQXMDJFqE?autoplay=1',
      }}
      handleClose={onClose}
      handleContinue={onConfirm}
      title={STRINGS.RESELLER_ONBOARDING_POPUP.VIDEO_TITLE}
      subtitle={STRINGS.RESELLER_ONBOARDING_POPUP.VIDEO_SUBTITLE}
      qa={STRINGS.RESELLER_ONBOARDING_POPUP.RESELLER_VIDEO_QA}
      disableBackdropClick={!isComingFromOnboarding}
      showHeader={!isComingFromOnboarding}
      showTitle={!!isComingFromOnboarding}
    />
  );
};

const ResellerOnboardingPopupWrapper = (
  props: ResellerOnboardingPopupWrapperProps,
) => {
  const { isRedirectedFromResellerLink, resellerAgreement } = props;

  const {
    currentHotel: { hotelcode: hotelCode },
  } = useContext(HotelContext);

  const isAgreementAccepted = resellerAgreement.status === 'accepted';

  const [isResellerPopupOpen, setIsResellerPopupOpen] = useState(
    !isRedirectedFromResellerLink,
  );
  const [isResellerOnboardingVideoOpen, setIsResellerOnboardingVideoOpen] =
    useState(isRedirectedFromResellerLink);

  const onResellerPopupClose = () => {
    pushToOmniture({
      event: 'ctaClick',
      cta: {
        name: 'cancel',
        componentName: isAgreementAccepted
          ? 'hoteltravel_onboarding_video'
          : 'hoteltravel_onboarding_resume',
      },
    });

    sessionStorage.setItem(
      `hide-reseller-popup-${hotelCode}`,
      JSON.stringify(true),
    );
    setIsResellerPopupOpen(false);
  };

  const onResellerPopupConfirm = () => {
    setIsResellerPopupOpen(false);
    if (isAgreementAccepted) {
      pushToOmniture({
        event: 'ctaClick',
        cta: {
          name: 'continue',
          componentName: 'hoteltravel_onboarding_video',
        },
      });

      navigateTo(routes.reseller.base);
      return;
    }
    setIsResellerOnboardingVideoOpen(true);

    pushToOmniture({
      event: 'ctaClick',
      cta: {
        name: 'continue',
        componentName: 'hoteltravel_onboarding_resume',
      },
    });
  };

  const onResellerOnboardingVideoClose = () => {
    setIsResellerOnboardingVideoOpen(false);

    pushToOmniture({
      event: 'ctaClick',
      cta: {
        name: 'close',
        componentName: 'hoteltravel_onboarding_video',
      },
    });
  };

  const handleResellerOnboardingVideoContinue = () => {
    pushToOmniture({
      event: 'ctaClick',
      cta: {
        name: 'continue',
        componentName: 'hoteltravel_onboarding_video',
      },
    });
    navigateTo(routes.reseller.base);
  };

  return (
    <div>
      {isResellerPopupOpen && (
        <ResellerOnboardingPopup
          onClose={onResellerPopupClose}
          onConfirm={onResellerPopupConfirm}
          isAgreementAccepted={isAgreementAccepted}
          key={hotelCode}
        />
      )}
      {isResellerOnboardingVideoOpen && (
        <ResellerIntroVideo
          onClose={onResellerOnboardingVideoClose}
          onConfirm={handleResellerOnboardingVideoContinue}
          isComingFromOnboarding
        />
      )}
    </div>
  );
};

export default ResellerOnboardingPopupWrapper;
