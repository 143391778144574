import { memo } from 'react';
import { Tick, WarningAlertComp } from 'assets/common';
import { RenderIconProp } from './TypeAndSchemas';

const iconMap: Record<
string,
React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  WarningAlert: WarningAlertComp,
  'right-gray-tick': Tick,
};

const RenderIcon = (props: RenderIconProp) => {
  const {
    icon: { name, style },
    className = '',
    height = 16,
    width = 16,
  } = props;

  const IconComponent = iconMap[name];
  if (!IconComponent) {
    console.error('Icon not found', name);
    return null;
  }

  return (
    <IconComponent
      height={height}
      width={width}
      className={className}
      style={style}
    />
  );
};
export default memo(RenderIcon);
