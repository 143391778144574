export const tabList = (count, STRINGS) => [
  {
    label: 'Pending Issues',
    value: STRINGS.TAB_NAMES.PENDING,
    count: count.pending,
  },
  {
    label: 'Resolved Issues',
    value: STRINGS.TAB_NAMES.RESOLVED,
    count: count.resolved,
  },
];
