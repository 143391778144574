import React from 'react';
import { Heading, Subheading } from './SectionHeader.styles';

const SectionHeader = ({
  heading,
  subHeading,
}: {
  heading: string;
  subHeading: string;
}) => {
  return (
    <div>
      <Heading>{heading}</Heading>
      <Subheading>{subHeading}</Subheading>
    </div>
  );
};

export default SectionHeader;
