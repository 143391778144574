import { Box } from 'connect-web-ui';
import { autoSuggestParse } from 'connect-web-ui/utils';
import { deepFlatten } from 'utilities/Utils';

import { legacyPushToGTM, GTM_CATEGORIES } from 'utilities/gtm';

export const sanitizeConfigOptions = categories =>
  deepFlatten(
    categories.map(cat => cat.options.map(c => `${cat.label} > ${c}`)),
  );

export const checkErrors = state => {
  const errors = {};
  if (state.selectedCategory === '') {
    errors.selectedCategory = 'Please select a category';
  }
  if (state.selectedIssue === '') {
    errors.selectedIssue = 'Please select a issue';
  }
  if (/others/i.test(state.selectedIssue)) {
    if (state.issue_details === '') {
      errors.issue_details = 'Please explain your issue';
    }
  }
  return errors;
};

export const HighLightSearchValues = props => {
  const { category, issue } = props;
  return (
    <div>
      {category.map((cat, idx) => (
        <span
          key={idx}
          className={`${cat.highlight ? 'fb' : ''}`}
          style={{ whitespace: 'pre' }}
        >
          {cat.text}
        </span>
      ))}
      <br />
      {issue.map((i, idx) => (
        <span
          key={idx}
          className={`greydark ${
            i.highlight ? 'fb' : ''
          } font14 whitespace-normal`}
          style={{ whitespace: 'pre' }}
        >
          {i.text}
        </span>
      ))}
    </div>
  );
};

export const renderSearchOptions = (props, option, { inputValue }) => {
  const [category, issue] = option.split('>').map(x => x.trim());
  const categoryParts = autoSuggestParse(category, inputValue);
  const issueParts = autoSuggestParse(issue, inputValue);
  return (
    <Box
      {...props}
      sx={{
        fontWeight: 'inherit',
        fontSize: '14px',
        whiteSpace: 'pre',
        lineHeight: '20px',
      }}
    >
      {' '}
      <HighLightSearchValues category={categoryParts} issue={issueParts} />{' '}
    </Box>
  );
};

export const helpCenterPushGTM = (eventAction, eventLabel = '') => {
  const params = {
    eventCategory: GTM_CATEGORIES.HELP_CENTER,
    eventAction,
  };
  if (eventLabel) {
    params.eventLabel = eventLabel;
  }
  legacyPushToGTM(params);
};
