import React from 'react';
import { DownloadAppSection, QRCode } from 'assets/modules/landing';
import {
  DownloadAppSectionStyled,
  Heading,
  ImagesContainer,
  InfoMessage,
  SubHeading,
} from './DownloadApp.styles';
import { LandingPageContainer } from 'modules/landingPage/LandingPage.styles';
import useStrings from 'modules/landingPage/hooks/useStrings';
// import { useIntersectionObserver } from 'utilities/CustomHooks';
// import { OMNITURE_CATEGORIES, pushToOmniture } from 'utilities/gtm';
// import { COMMON_OMNITURE_VARIABLES } from 'modules/landingPage/constants';

interface DownloadAppComponentProps {
  downloadAppRef: React.RefObject<HTMLElement>;
}

const DownloadAppComponent = ({
  downloadAppRef,
}: DownloadAppComponentProps) => {
  const [STRINGS] = useStrings();

  // useIntersectionObserver(
  //   'download_app_section',
  //   ([entry]) => {
  //     if (entry.isIntersecting) {
  //       pushToOmniture({
  //         ...COMMON_OMNITURE_VARIABLES,
  //         event: OMNITURE_CATEGORIES.CTA_LOAD,
  //         cta: {
  //           name: 'download_app_section',
  //           type: 'load',
  //           componentName: 'download_app',
  //         },
  //       });
  //     }
  //   },
  //   [],
  //   { rootMargin: '-50% 0px' },
  // );

  return (
    <DownloadAppSectionStyled
      ref={downloadAppRef}
      className="download_app_section"
    >
      <LandingPageContainer>
        <Heading>{STRINGS.download_app.heading}</Heading>
        <SubHeading
          dangerouslySetInnerHTML={{ __html: STRINGS.download_app.subHeading }}
        />
        <ImagesContainer>
          <img src={DownloadAppSection} alt="download" />
          <img src={QRCode} alt="qr code" />
        </ImagesContainer>
        <InfoMessage>{STRINGS.download_app.infoMsg}</InfoMessage>
      </LandingPageContainer>
    </DownloadAppSectionStyled>
  );
};

export default DownloadAppComponent;
