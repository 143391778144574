import styled from 'styled-components';

export const LandingPageWrapper = styled.div`
  background-color: #efefef;
  overflow-x: auto;

  * {
    font-family: 'Lato', sans-serif !important;
  }

  img {
    max-width: 100%;
  }

  p,
  label {
    margin: 0;
  }

  section,
  footer {
    min-width: 1280px;
  }

  @media (min-width: 1280px) {
    overflow-x: hidden;
  }
`;

export const LandingPageContainer = styled.div<{ height?: string }>`
  width: 1280px;
  margin: 0 auto;
  padding: 0 40px;
  ${({ height }) =>
    height &&
    `
    height: ${height};
  `}
`;
