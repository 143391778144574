import { DownGif } from 'assets/latest_core';
import React, { RefObject } from 'react';
import { pushToOmniture } from 'utilities/gtm';

const scrollToEnd = (listRef: RefObject<HTMLDivElement>) => {
  if (listRef?.current) {
    listRef.current.scrollTo({
      top: listRef.current.scrollHeight,
      behavior: 'smooth',
    });
  }
};

export const MoreButton = ({
  listRef,
}: {
  listRef: RefObject<HTMLDivElement>;
}) => {
  const handleMoreClick = () => {
    pushToOmniture({
      event: 'ctaClick',
      cta: {
        name: 'morebelow_leftnav_click',
        type: 'click',
        componentName: 'left_nav',
      },
    });
    scrollToEnd(listRef);
  };

  return (
    <div
      className="animate-bounce fixed bottom-0 cursor-pointer z-10 h-7 w-7 bg-[#404040] text-common-white select-none rounded-full  flex text-size-10 ml-[30px] opacity-70 items-center justify-center"
      onClick={handleMoreClick}
    >
      <img src={DownGif} className="rounded-full h-6 w-6" />
    </div>
  );
};
