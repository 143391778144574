import React, { useState } from 'react';
import { downloadApp } from '../utils/DashboardAPIClient';
import { showMessage, showGlobalLoader } from 'utilities/Utils';
import { digitsRegex } from 'utilities/allRegex';
import { legacyPushToGTM, GTM_CATEGORIES } from 'utilities/gtm';
import { Button, Input, Typography, Divider, Modal } from 'connect-web-ui';
import { GreenCheck, Mobile } from 'assets/latest_core';

export default function AppDownloadPopUp(props) {
  const { closeModal, STRINGS, COMMON_STRINGS } = props;
  const [phoneNum, setPhoneNum] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [showError, setShowError] = useState(false);

  const phoneNumberChange = value => {
    const len = value.toString().length;
    if (len > 10) return;
    setIsValid(len === 10);
    if (len && showError) setShowError(false);
    const regRes = digitsRegex.test(value);
    if (value.toString().length === 0 || regRes) setPhoneNum(value);
  };

  const phoneNumberOnBlur = () => {
    setShowError(!isValid);
  };

  const handleSend = () => {
    if (isValid) {
      showGlobalLoader(true);
      legacyPushToGTM({
        eventCategory: GTM_CATEGORIES.DASHBOARD,
        eventAction: 'app_download_popup',
        eventLabel: 'send_link',
      });
      downloadApp(phoneNum)
        .then(() => {
          showMessage({
            type: 'success',
            show: true,
            message: STRINGS.FOOTER.LINK_SENT_SUCCESSFULLY,
          });
          closeModal();
        })
        .catch(() => {
          showMessage({
            type: 'error',
            show: true,
            message: COMMON_STRINGS.MESSAGES.PLEASE_TRY_AGAIN,
          });
        })
        .finally(() => showGlobalLoader(false));
    }
  };

  const DisAppFeatures = (str, index) => (
    <div key={index} className="flex gap-2 items-center">
      <GreenCheck height={20} width={20} />
      <Typography variant="body1">{str}</Typography>
    </div>
  );

  return (
    <Modal
      isOpen
      size="custom"
      header={{
        title: STRINGS.FOOTER.DOWNLOAD_APP_LINK,
      }}
      onClose={closeModal}
      customClasses={{
        children: '!p-0',
      }}
    >
      <div className="flex gap-4 p-6 items-center h-[268px] bg-[#F8F8F8] w-[500px]">
        <img
          alt="App screenshot"
          className="h-[220px] w-[171px]"
          src={Mobile}
        />
        <div className="flex gap-2 flex-col">
          {STRINGS.FOOTER.APP_FEATURE.map((feature, index) =>
            DisAppFeatures(feature, index),
          )}
        </div>
      </div>

      <Divider />
      <div className="flex p-4 flex-col gap-1">
        <Typography variant="body1">
          {STRINGS.FOOTER.GET_A_LINK_ON_MOBILE}
        </Typography>
        <Input
          error={showError}
          value={phoneNum}
          onChange={phoneNumberChange}
          onBlur={phoneNumberOnBlur}
          placeholder={COMMON_STRINGS.PLACEHOLDER.MOBILE_NUMBER_PLACEHOLDER}
          leftElement={
            <div className="flex justify-center items-center">
              <Typography className="pr-1" variant="body1">
                {COMMON_STRINGS.PLACEHOLDER.MOBILE_NUM}
              </Typography>
              <Divider orientation="vertical" className="h-6" />
            </div>
          }
          rightElement={
            <Button
              disabled={!isValid}
              variant="text"
              size="small"
              onClick={handleSend}
              className="!whitespace-nowrap"
            >
              {COMMON_STRINGS.BUTTONS.SEND_LINK_BTN}
            </Button>
          }
        />
        {showError && (
          <Typography variant="subtitle1" className="text-error-default">
            {COMMON_STRINGS.MESSAGES.ENTER_VALID_NUMBER}
          </Typography>
        )}
      </div>
    </Modal>
  );
}
