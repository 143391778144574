export const GET_CONTENT_SCORE = `
mutation GetContentScore($GetContentScore: RawEndpointsRequestData, $AnalyticsV1Persuasions: RawEndpointsRequestData, $AverageContentScore: RawEndpointsRequestData) {  
    getContentScore: rawEndpoint(endpointRequestData: $GetContentScore) {  
    getContentScore {      
        modelType1 {        
            data       
            success  
            error     
                 
        }        
    }   
     
}
contentScorePersuasions: rawEndpoint(endpointRequestData: $AnalyticsV1Persuasions) {    
    contentScorePersuasions {      
        modelType1 {        
            data        
            message        
            status                  
        }         
    }      
}
avgCompititorsContentScore: rawEndpoint( endpointRequestData: $AverageContentScore) {    
    avgCompititorsContentScore {      
        modelType1 {       
            data       
            message        
            status       
            status_code             
        }           
    } 
} }
`;

export const GET_ONLY_CONTENT_SCORE = `
mutation GetContentScore($GetContentScore: RawEndpointsRequestData) {  
    getContentScore: rawEndpoint(endpointRequestData: $GetContentScore) {  
    getContentScore {      
        modelType1 {        
            data       
            success  
            error     
                 
        }        
    }   
     
}}
`;
