import React from 'react';
import { useContext, useEffect } from 'react';
import { HotelContext } from 'utilities/Context';
import usePerformanceMetricData from './usePerformanceMetricData';
import PerformanceCard from 'modules/dashboard/components/PerformanceCard';
import RefreshCard from 'modules/dashboard/components/RefreshCard';
import PerformanceCardWrapper from './PerformanceCardWrapper';
import PerformanceCardShimmer from 'modules/dashboard/components/PerformanceCardShimmer';

type PerformanceMetricesProps = {
  children: React.ReactNode;
};

const PerformanceMetrices = ({ children }: PerformanceMetricesProps) => {
  const { currentHotel } = useContext(HotelContext);
  const [cardsData, fetchCardData] = usePerformanceMetricData();
  useEffect(() => {
    fetchCardData();
  }, [currentHotel.hotelcode]);

  const generateProps = i =>
    i === 0 ? { performanceData: cardsData, key: i } : {};

  const childrenWithProps = React.Children.map(children, (child, i) => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.

    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...generateProps(i) });
    }
    return child;
  });

  return (
    <>
      {childrenWithProps}
      {Object.keys(cardsData)
        .reduce((resultArray, key, i) => {
          //for dividing it in to two items per row
          const chunkIndex = Math.floor(i / 2);

          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
          }
          let item = null;
          if (cardsData[key].isLoading) {
            item = (
              <PerformanceCardWrapper
                className={i % 2 ? 'ml-3' : 'mr-3'}
                key={key}
                testId={`dashboard_card${key
                  .replaceAll('Today\'s', '')
                  .split(' ')
                  .join('_')
                  .toLowerCase()}`}
              >
                <PerformanceCardShimmer />
              </PerformanceCardWrapper>
            );
          } else if (cardsData[key].isError) {
            item = (
              <PerformanceCardWrapper
                className={i % 2 ? 'ml-3' : 'mr-3'}
                key={key}
                testId={`dashboard_card${key
                  .replaceAll('Today\'s', '')
                  .split(' ')
                  .join('_')
                  .toLowerCase()}`}
              >
                <RefreshCard fetchCardData={fetchCardData} cardName={key} />
              </PerformanceCardWrapper>
            );
          } else {
            item = (
              <PerformanceCardWrapper
                className={i % 2 ? 'ml-3' : 'mr-3'}
                key={key}
                testId={`dashboard_card${key
                  .replaceAll('Today\'s', '')
                  .split(' ')
                  .join('_')
                  .toLowerCase()}`}
              >
                <PerformanceCard
                  title={cardsData[key].id}
                  shoutOut={cardsData[key].shoutOut}
                  last7Days={cardsData[key].last7Days}
                  to={cardsData[key].to}
                  fetchCardData={fetchCardData}
                />
              </PerformanceCardWrapper>
            );
          }
          resultArray[chunkIndex].push(item);
          return resultArray;
        }, [])
        .map((cardsPerRow, i) => {
          return (
            <div className="flex flex-1 justify-between" key={i}>
              {cardsPerRow}
            </div>
          );
        })}
    </>
  );
};

export default PerformanceMetrices;
