import { doQuery, doMutation } from 'base/GraphQLClient';
import { getValue } from 'utilities/Utils';
import {
  getAgreementsQuery,
  getAgreementsVariables,
  updateDocumentQuery,
  updateDocumentVariables,
} from './BankDetailsGraphQueries';

export const getAgreements = hotelcode => {
  return doQuery(getAgreementsQuery, getAgreementsVariables(hotelcode)).then(
    res => getValue(res, 'data.rawEndpoint.hotelAgreementsV3.modelType1') || {},
  );
};

export const updateDocument = (id, payload) => {
  return doMutation(
    updateDocumentQuery,
    updateDocumentVariables(id, payload),
  ).then(
    res => getValue(res, 'data.rawEndpoint.documentUpdateV3.modelType1') || {},
  );
};
