import {
  FeaturesAnalytics,
  FeaturesOnboarding,
  FeaturesPayments,
  FeaturesPromotions,
  FeatureRatesBookings,
  FeatureRnR,
  SuccessStory,
  CeoProfile,
} from 'assets/modules/landing';

export const EXTERNAL_URLS = {
  BANNER_IMAGE:
    'https://go-assets.ibcdn.com/u/MMT/images/1725863352031-connect_bg.jpeg',
  // Redirection URLs
  APP_STORE:
    'https://apps.apple.com/in/app/ingommt-mmt-goibibo-partner/id1019426231',
  PLAY_STORE:
    'https://play.google.com/store/apps/details?id=com.ingoibibo&hl=en',
  PRIVACY_POLICY: 'https://in.goibibo.com/static/html/privacy_policy.html',
  ABOUT_US: 'https://www.makemytrip.com/about-us.php#',
  INVESTOR_RELATIONS: 'https://investors.makemytrip.com/investors/overview',
  MMT_FOUNDATION: 'https://www.makemytrip.com/csr/mmt_foundation.html',
  CSR_POLICY: 'https://www.makemytrip.com/csr/csr_policy.html',
  LEGAL:
    'https://www.makemytrip.com/legal/notice_privacy_policy.html?srsltid=AfmBOoro1ipiYUVTjScN8i_WcdgOdYkTbDh7ObZbLOnp5D29HdSWTghi',
  MAKE_MY_TRIP: 'https://www.makemytrip.com/',
  GOIBIBO: 'https://www.goibibo.com/',
  REDBUS: 'https://www.redbus.in/',
  MY_BIZ: 'https://mybiz.makemytrip.com/',
  MY_PARTNER:
    'https://mypartner.makemytrip.com/agent/home?crId=7c2c36ba-82fc-4c91-be30-60074e1c44bc',
  ADVERTISE_WITH_US: 'https://advertising.makemytrip.com/ad/solutions/',
  BOOK_MY_FOREX: 'https://www.bookmyforex.com/',
  LIST_HOMESTAY_MMT: '#top',
  LIST_HOTEL_MMT: '#top',
  LIST_VILLA_MMT: '#top',
  LIST_APARTMENT_MMT: '#top',
  PARTNER_SUPPORT: 'https://partners.go-mmt.com/support/home',
  PARTNER_COMMUNITY: 'https://www.youtube.com/@GoMMTPartnerCommunity',
};

export const TICKER_ITEMS = [
  'Hotel, Homestay',
  'Villa, Resort',
  'Hostel, Guest house',
  'Cottage, Apartments',
  'Lodge, Bed & B\'fast',
];

export const GET_THE_APP = 'Get the App';

export const COPYRIGHT_MSG = '© 2024 Go-MMT. All rights reserved';

export const EMAIL_NOT_FOUND_ERROR_CODE = 'AUTH_EHE_0001';
export const INCORRECT_CREDS_ERROR_CODE = 'AUTH_ELE_013';

export const PASSWORD_MIN_CHAR_LENGTH = 8;

export const COMMON_OMNITURE_VARIABLES = {
  pageName: 'landing_page',
  pageType: 'Main Page',
  datasource: 'ingo_legacy',
};

export const CONTENT_CONFIG = {
  navLinks: [
    {
      text: 'header.navLinks.features',
    },
    // {
    //   text: 'header.navLinks.success_stories',
    // },
  ],

  offerings_card: [
    {
      title: 'offerings_card.title_1',
      description: 'offerings_card.description_1',
    },
    {
      title: 'offerings_card.title_2',
      description: 'offerings_card.description_2',
    },
    {
      title: 'offerings_card.title_3',
      description: 'offerings_card.description_3',
    },
  ],

  partners_list: [
    {
      id: 'myBiz',
      title: 'offerings_partners.partners_list.myBiz.title',
      description: 'offerings_partners.partners_list.myBiz.description',
    },
    {
      id: 'myPartner',
      title: 'offerings_partners.partners_list.myPartner.title',
      description: 'offerings_partners.partners_list.myPartner.description',
    },
    {
      id: 'loyaltyPrograms',
      title: 'offerings_partners.partners_list.loyaltyPrograms.title',
      description:
        'offerings_partners.partners_list.loyaltyPrograms.description',
    },
    {
      id: 'homeStays',
      title: 'offerings_partners.partners_list.homeStays.title',
      description: 'offerings_partners.partners_list.homeStays.description',
    },
  ],

  features_list: [
    {
      title: 'features_data.features_list.feature_1.title',
      description: 'features_data.features_list.feature_1.description',
      image: FeaturesOnboarding,
      omnitureVariables: {
        loadedComponents: 'onboarding',
      },
    },
    {
      title: 'features_data.features_list.feature_2.title',
      description: 'features_data.features_list.feature_2.description',
      image: FeatureRatesBookings,
      omnitureVariables: {
        loadedComponents: 'inventory',
      },
    },
    {
      title: 'features_data.features_list.feature_3.title',
      description: 'features_data.features_list.feature_3.description',
      image: FeaturesAnalytics,
      omnitureVariables: {
        loadedComponents: 'analytics',
      },
    },
    {
      title: 'features_data.features_list.feature_4.title',
      description: 'features_data.features_list.feature_4.description',
      image: FeaturesPromotions,
      omnitureVariables: {
        loadedComponents: 'promotions',
      },
    },
    {
      title: 'features_data.features_list.feature_5.title',
      description: 'features_data.features_list.feature_5.description',
      image: FeatureRnR,
      omnitureVariables: {
        loadedComponents: 'reviews',
      },
    },
    {
      title: 'features_data.features_list.feature_6.title',
      description: 'features_data.features_list.feature_6.description',
      image: FeaturesPayments,
      omnitureVariables: {
        loadedComponents: 'payments',
      },
    },
  ],

  stories_list: [
    {
      message: 'success_stories.stories_list.story_1.message',
      partner_name: 'success_stories.stories_list.story_1.partner_name',
      partner_address: 'success_stories.stories_list.story_1.partner_address',
      image: SuccessStory,
    },
    {
      message: 'success_stories.stories_list.story_2.message',
      partner_name: 'success_stories.stories_list.story_2.partner_name',
      partner_address: 'success_stories.stories_list.story_2.partner_address',
      image: CeoProfile,
    },
    {
      message: 'success_stories.stories_list.story_3.message',
      partner_name: 'success_stories.stories_list.story_3.partner_name',
      partner_address: 'success_stories.stories_list.story_3.partner_address',
      image: SuccessStory,
    },
    {
      message: 'success_stories.stories_list.story_4.message',
      partner_name: 'success_stories.stories_list.story_4.partner_name',
      partner_address: 'success_stories.stories_list.story_4.partner_address',
      image: CeoProfile,
    },
    {
      message: 'success_stories.stories_list.story_5.message',
      partner_name: 'success_stories.stories_list.story_5.partner_name',
      partner_address: 'success_stories.stories_list.story_5.partner_address',
      image: SuccessStory,
    },
  ],

  footerSection: [
    {
      title: 'footerSection.sectionATitle',
      links: [
        {
          href: EXTERNAL_URLS.ABOUT_US,
          text: 'footerSection.links.about_us',
          target: '_blank',
        },
        {
          href: EXTERNAL_URLS.INVESTOR_RELATIONS,
          text: 'footerSection.links.investor_relations',
          target: '_blank',
        },
        {
          href: EXTERNAL_URLS.MMT_FOUNDATION,
          text: 'footerSection.links.mmt_foundation',
          target: '_blank',
        },
        {
          href: EXTERNAL_URLS.CSR_POLICY,
          text: 'footerSection.links.csr_policy',
          target: '_blank',
        },
        {
          href: EXTERNAL_URLS.LEGAL,
          text: 'footerSection.links.legal',
          target: '_blank',
        },
        {
          href: EXTERNAL_URLS.PRIVACY_POLICY,
          text: 'footerSection.links.privacy_policy',
          target: '_blank',
        },
        {
          href: EXTERNAL_URLS.PRIVACY_POLICY,
          text: 'footerSection.links.tnc',
          target: '_blank',
        },
      ],
    },
    {
      title: 'footerSection.sectionBTitle',
      links: [
        {
          href: EXTERNAL_URLS.MAKE_MY_TRIP,
          text: 'footerSection.links.mmt',
          target: '_blank',
        },
        {
          href: EXTERNAL_URLS.GOIBIBO,
          text: 'footerSection.links.goibibo',
          target: '_blank',
        },
        {
          href: EXTERNAL_URLS.REDBUS,
          text: 'footerSection.links.redbus',
          target: '_blank',
        },
        {
          href: EXTERNAL_URLS.MY_BIZ,
          text: 'footerSection.links.my_biz',
          target: '_blank',
        },
        {
          href: EXTERNAL_URLS.MY_PARTNER,
          text: 'footerSection.links.my_partner',
          target: '_blank',
        },
        {
          href: EXTERNAL_URLS.ADVERTISE_WITH_US,
          text: 'footerSection.links.advertise_with_us',
          target: '_blank',
        },
        {
          href: EXTERNAL_URLS.BOOK_MY_FOREX,
          text: 'footerSection.links.bookmyforex',
          target: '_blank',
        },
      ],
    },
    {
      title: 'footerSection.sectionCTitle',
      links: [
        {
          href: EXTERNAL_URLS.LIST_HOMESTAY_MMT,
          text: 'footerSection.links.list_homestay_mmt',
        },
        {
          href: EXTERNAL_URLS.LIST_HOTEL_MMT,
          text: 'footerSection.links.list_hotel_mmt',
        },
        {
          href: EXTERNAL_URLS.LIST_VILLA_MMT,
          text: 'footerSection.links.list_villa_mmt',
        },
        {
          href: EXTERNAL_URLS.LIST_APARTMENT_MMT,
          text: 'footerSection.links.list_apartment_mmt',
        },
        {
          href: EXTERNAL_URLS.PARTNER_SUPPORT,
          text: 'footerSection.links.partner_support',
          target: '_blank',
        },
        {
          href: EXTERNAL_URLS.PARTNER_COMMUNITY,
          text: 'footerSection.links.partner_community',
          target: '_blank',
        },
      ],
    },
    {
      title: 'footerSection.sectionDTitle',
      address: 'footerSection.address',
    },
  ],

  faqs: [
    {
      id: '1',
      question: 'faqsData.questions.question_1.question',
      answer: 'faqsData.questions.question_1.answer',
    },
    {
      id: '2',
      question: 'faqsData.questions.question_2.question',
      answer: 'faqsData.questions.question_2.answer',
    },
    {
      id: '3',
      question: 'faqsData.questions.question_3.question',
      answer: 'faqsData.questions.question_3.answer',
    },
    {
      id: '4',
      question: 'faqsData.questions.question_4.question',
      answer: 'faqsData.questions.question_4.answer',
    },
    {
      id: '5',
      question: 'faqsData.questions.question_5.question',
      answer: 'faqsData.questions.question_5.answer',
    },
    {
      id: '6',
      question: 'faqsData.questions.question_6.question',
      answer: 'faqsData.questions.question_6.answer',
    },
    {
      id: '7',
      question: 'faqsData.questions.question_7.question',
      answer: 'faqsData.questions.question_7.answer',
    },
  ],
};
