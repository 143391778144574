import { colors, typography } from 'style/zeplin-token-styledComponents';
import styled from 'styled-components';

interface LabelProps {
  labelColor?: string;
}

export const Container = styled.div`
  position: relative;
`;

export const Label = styled.label<LabelProps>`
  ${typography.subTextBold};
  color: ${({ labelColor }) => labelColor || colors.white};
  display: flex;
`;

export const Input = styled.input<{ isError?: boolean }>`
  margin-top: 6px !important;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid ${colors.grayMediumEmphasis};
  color: ${colors.grayHighEmphasis};
  background-color: ${colors.white};
  ${typography.bodyTextBaseRegular};
  width: 100%;
  height: 40px;

  &:focus {
    border: 1px solid ${colors.primaryButtonDefault};
    box-shadow: 0px 0px 0px 2px rgba(0, 140, 255, 0.6);
  }
  ${({ isError }) =>
    isError &&
    `
    border: 2px solid #FF0008;
    box-shadow: 0px 1px 2px 0px #0000000D;
  `}
`;

export const ErrorMsg = styled.p`
  ${typography.subTextRegular};
  color: ${colors.errorDefault};
`;

export const FooterActionComponent = styled.div`
  margin-top: 6px !important;
  display: flex;
  justify-content: space-between;
`;

export const InfoMsg = styled.p`
  ${typography.subTextRegular};
  color: ${colors.grayMediumEmphasis};
  margin-top: 6px !important;
`;

export const EndIconWrap = styled.span`
  position: absolute;
  right: 12px;
  top: 38px;
  cursor: pointer;
`;
