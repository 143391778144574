import { Modal } from 'connect-web-ui';
import CONSTANTS from 'modules/performanceBooster/PerformanceBooster.constants';
import { displayDateFormatWithComma } from 'modules/promotions-and-coupons/PnC.constants';
import {
  formatTimeString,
  getFormattedDate,
  unGroupDates,
} from 'utilities/DateUtils';
import { time24hrTo12Hr } from 'utilities/Utils';

export const confirmBoosterType = {
  DATE: 'date',
  DATE_LIST: 'dateList',
  OBJECT: 'object',
  STRINGS: 'strings',
  NUMBER: 'number',
};

const confirmBoosterPopup = obj => {
  return Object.keys(obj).map(offer => {
    const { type, initialValue, updatedValue, data, suffix = '' } = obj[offer];
    if (type === confirmBoosterType.DATE) {
      const stayStartDate = updatedValue;
      const stayEndDate = obj['New Stay End']?.updatedValue;
      const bookingStartDate = updatedValue;
      const bookingEndDate = obj['New Booking End']?.updatedValue;
      const hasStayDates =
        obj.hasOwnProperty('Stay Start') || obj.hasOwnProperty('Stay End');
      // Handle Stay Start and display
      if (offer === 'New Stay Start' && hasStayDates) {
        const formattedStayEndDate = getFormattedDate(
          stayEndDate,
          displayDateFormatWithComma,
        );
        const onwardText =
          formattedStayEndDate === '1st Jan, 2099'
            ? ' onwards'
            : ` to ${formattedStayEndDate}`;

        return (
          <p key={offer}>
            Stay Dates from{' '}
            {getFormattedDate(stayStartDate, displayDateFormatWithComma)}
            {onwardText}
            {suffix}
          </p>
        );
      }

      // Handle Booking Start and display
      const hasBookingDates =
        obj.hasOwnProperty('Booking Start') ||
        obj.hasOwnProperty('Booking End');
      if (offer === 'New Booking Start' && hasBookingDates) {
        const formattedBookingEndDate = getFormattedDate(
          bookingEndDate,
          displayDateFormatWithComma,
        );
        const onwardText =
          formattedBookingEndDate === '1st Jan, 2099'
            ? ' onwards'
            : ` to ${formattedBookingEndDate}`;

        return (
          <p key={offer}>
            Booking Dates from{' '}
            {getFormattedDate(bookingStartDate, displayDateFormatWithComma)}
            {onwardText}
            {suffix}
          </p>
        );
      }

      // Skip rendering for Stay End
      if (
        offer === 'New Stay End' ||
        offer === 'New Booking End' ||
        offer === 'Stay End' ||
        offer === 'Booking End'
      ) {
        return null;
      }

      // Render other dates normally
      return null;
    } else if (type === confirmBoosterType.DATE_LIST) {
      const updatedValueList = unGroupDates(updatedValue)
        .map(date => getFormattedDate(date, CONSTANTS.BOOSTER_DATE_FORMAT))
        .join(', ');

      if (!updatedValueList) {
        return (
          <p>
            {' '}
            Blackout Dates: <strong>No blackout date selected</strong>
          </p>
        );
      }

      return (
        <p>
          Blackout Dates: <strong>{updatedValueList}</strong> {suffix}
        </p>
      );
    } else if (type === confirmBoosterType.OBJECT) {
      if (
        offer === 'timeBasedOfferData' &&
        updatedValue.length > 0 &&
        Array.isArray(updatedValue)
      ) {
        // Check if there's at least one active time-based discount
        const activeOffers = updatedValue.filter(
          timeOffer => timeOffer.timeBasedDiscount !== 'no',
        );

        if (activeOffers.length === 0) {
          return (
            <p>
              Time Based Promotions: <strong>No Time-Based Offers Found</strong>
            </p>
          );
        }

        return activeOffers.map((timeOffer, index) => {
          const { startTime, endTime, offerValueList } = timeOffer;

          const offerValues = Array.isArray(offerValueList)
            ? offerValueList
              .map(_offer => _offer.offerValue?.value || 'N/A')
              .join(', ')
            : 'N/A';

          return (
            <p key={`${offer}-${index}`}>
              Time Based Discount :{' '}
              <strong>Discount offered: {offerValues}%</strong>, Valid from{' '}
              <strong>{formatTimeString(time24hrTo12Hr(startTime))} </strong>-
              <strong>{formatTimeString(time24hrTo12Hr(endTime))}</strong>
              {suffix}
            </p>
          );
        });
      }

      return <>{confirmBoosterPopup(data)}</>;
    } else if (type === confirmBoosterType.NUMBER) {
      return (
        <p>
          {offer}:{' '}
          {initialValue && (
            <>
              <strong>{initialValue}%</strong> to{' '}
            </>
          )}
          <strong>{updatedValue}%</strong> {suffix}
        </p>
      );
    }
    return (
      <p>
        {offer}:{' '}
        {initialValue && (
          <>
            <strong>{initialValue}</strong> to{' '}
          </>
        )}
        <strong>{updatedValue}</strong> {suffix}
      </p>
    );
  });
};

export default function ConfirmBooster(props) {
  const { data, onClose, onConfirm, title = 'Save Changes' } = props;

  return (
    <Modal
      isOpen
      size="small"
      variant="confirm"
      header={{
        title: title,
      }}
      footer={{
        primaryBtn: {
          text: 'Confirm',
          onClick: onConfirm,
        },
        secondaryBtn: {
          onClick: onClose,
        },
      }}
    >
      <div className="confirm-discount-modal">{confirmBoosterPopup(data)}</div>
    </Modal>
  );
}
