import { displayDateFormat } from '../../constants';

const today = new Date();
// Last year
const performanceFrom = new Date().setFullYear(today.getFullYear() - 1);
// Fallback date
const fallBackDate = new Date(new Date().setMonth(today.getMonth() + 6));
// Calendar max date
const maxCalendarDate = new Date(new Date().getFullYear() + 3, 0);
// Long running CUG date
const FOREVER_BOOSTER_DATE = '2099-12-31';

const CONSTANTS = {
  TYPE: {
    COUPONS: 'COUPONS',
    VDI: 'VDI',
    CUG: 'CUG',
    CUG_SEGMENT: 'CUG SEGMENT',
    MMT_BLACK: 'MMT BLACK',
    MMT_SELECT: 'MMT SELCT',
    CORPORATE: 'corporate',
    GEOGRAPHY: 'GEOGRAPHY',
    MY_PARTNER: 'b2a',
    HOLIDAYS: 'Holidays',
    MEMBER: 'member',
    MOBILE: 'Mobile',
  },
  STATUS: {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
  },
  PERFORMANCE: {
    ROOM_NIGHTS: 'room_nights',
    NET_AMOUNT: 'net_amount',
  },
  OFFER_CATEGORY_TYPE: {
    CUSTOMISED: 'customised',
    LAST_MINUTE: 'lastminute',
    EARLY_BIRD: 'earlybird',
  },
  today,
  performanceFrom,
  FALLBACK_DATE: fallBackDate,
  MAX_CALENDAR_DATE: maxCalendarDate,
  FOREVER_BOOSTER_DATE,
  TODAY_FORMAT: 'DD MMMM YYYY',
  BOOSTER_DATE_FORMAT: displayDateFormat,
  API_DATE_FORMAT: 'YYYY-MM-DD',
  EXPIRES_IN_THRESHOLD: 5,
  MINIMUM_ACCORDION_CONTENT: 2,
  OFFER_CATEGORY_INITIAL_VALUE: 15,
  COUPONS_META: {
    INITIAL_VALUE: 10,
    MIN_OFFER_VALUE: 3,
    MAX_OFFER_VALUE: 50,
  },
  CUG_CARD_WIDTH: 344,
  // (344(width of card) * 3 (3 cards) * 100 (convert to percent)
  CUG_LISTING_WIDTH: 103200,
  DEFAULT_EARLY_BIRD_MAX: 550,
  VDI_RULE_TYPE: 'vdiroomnights',
  AFFILIATE: 'Affiliate',
} as const;

const GENERIC_CUG_DRAWER = STRINGS => ({
  MOBILE: {
    TITLE: STRINGS.MOBILE.TITLE,
    SUB_TITLE: STRINGS.MOBILE.SUB_TITLE,
    BANNER_TEXT: STRINGS.MOBILE.BANNER_TEXT,
    TOOLTIP: STRINGS.MOBILE.TOOLTIP,
  },
  GEOGRAPHY: {
    TITLE: STRINGS.GEOGRAPHY.TITLE,
    SUB_TITLE: STRINGS.GEOGRAPHY.SUB_TITLE,
    BANNER_TEXT: STRINGS.GEOGRAPHY.BANNER_TEXT,
    TOOLTIP: STRINGS.GEOGRAPHY.TOOLTIP,
  },
  gcc: {
    TITLE: STRINGS.GCC.TITLE,
    SUB_TITLE: STRINGS.GCC.SUB_TITLE,
    BANNER_TEXT: STRINGS.GCC.BANNER_TEXT,
    TOOLTIP: STRINGS.GCC.TOOLTIP,
  },
  b2a: {
    TITLE: STRINGS.B2A.TITLE,
    SUB_TITLE: STRINGS.B2A.SUB_TITLE,
    BANNER_TEXT: STRINGS.B2A.BANNER_TEXT,
    TOOLTIP: STRINGS.B2A.TOOLTIP,
  },
  Holidays: {
    TITLE: STRINGS.HOLIDAYS.TITLE,
    SUB_TITLE: STRINGS.HOLIDAYS.SUB_TITLE,
    BANNER_TEXT: STRINGS.HOLIDAYS.BANNER_TEXT,
    TOOLTIP: STRINGS.TOOLTIP,
  },
  member: {
    TITLE: STRINGS.MEMBER.TITLE,
    SUB_TITLE: STRINGS.MEMBER.SUB_TITLE,
    BANNER_TEXT: STRINGS.MEMBER.BANNER_TEXT,
    POSITIVE_TEXT: STRINGS.MEMBER.POSITIVE_TEXT,
    TOOLTIP: STRINGS.TOOLTIP,
  },
});

export const CUGType = {
  MMT_SELECT: 'MMT Select',
  MMT_BLACK: 'MMT Black',
  MMT_BLACK_GO: 'MMT Black and Go Tribe',
};

export const getGenericCUGDrawer = (STRINGS, segment) => {
  const genericDrawers = GENERIC_CUG_DRAWER(STRINGS.DRAWER_META.GENERIC);
  return genericDrawers[segment];
};

export default CONSTANTS;
