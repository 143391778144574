import React, { useEffect, useState, useContext } from 'react';
import { HotelContext } from '../../../utilities/Context';
import { getFooterDetails } from './FooterAPIClient';
import AppDownloadPopUp from './AppDownloadPopUp';
import { useLocation } from 'react-router-dom';
import { clearQueryParams } from 'utilities/Utils';
import { useLangStrings } from '../../../utilities/CustomHooks';

import { legacyPushToGTM, GTM_CATEGORIES } from 'utilities/gtm';
import SENTRY from 'utilities/Sentry';

import './Footer.scss';

const defaultState = {
  contactDetails: {},
};

function Footer() {
  const [STRINGS, COMMON_STRINGS] = useLangStrings('Dashboard');
  const { pathname, search } = useLocation();
  const [footerDetails, setFooterDetails] = useState(defaultState);
  const [showAppDownloadPopUp, setAppDownloadPopUp] = useState(() => {
    const hasShowDownload = clearQueryParams(
      pathname,
      search,
      'app_download_link',
    );
    return hasShowDownload === 'true';
  });
  const hotelState = useContext(HotelContext);
  const hotelCode = hotelState.currentHotel.hotelcode;
  const isActiveHotel = hotelState.currentHotel.isactive;

  const getContactDetails = () => {
    getFooterDetails(hotelCode)
      .then(resp => {
        const contactDetails = resp;
        setFooterDetails({ ...footerDetails, contactDetails });
      })
      .catch(err => SENTRY.logError(err));
  };

  const pushGAevent = label => {
    setAppDownloadPopUp(true);
    legacyPushToGTM({
      eventCategory: GTM_CATEGORIES.DASHBOARD,
      eventAction: 'new_dashboard_footer',
      eventLabel: label,
    });
  };

  useEffect(() => {
    getContactDetails();
  }, [hotelCode]);

  const designationMapping = {
    contractbdo: 'Business Development Manager',
    contractmanager: 'Zonal Manager',
  };

  return (
    <div className="footer-component">
      <div className="footer-main">
        <div className="footer-link-section sr-flex1">
          <ul>
            <li>
              <strong>{STRINGS.FOOTER.FOOTER_ARRAY[0]}</strong>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.makemytrip.com/"
              >
                {COMMON_STRINGS.OTA.MMT}
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.goibibo.com/ "
              >
                {COMMON_STRINGS.OTA.GIB}
              </a>
            </li>
          </ul>
          <ul>
            <li>
              <strong>{STRINGS.FOOTER.FOOTER_ARRAY[1]}</strong>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://ingoibibo-prod.s3-ap-south-1.amazonaws.com/static/14.02.19_MakyMyTrip%20Privacy%20Policy_final%20draft.pdf"
              >
                {COMMON_STRINGS.LABELS.PRIVACY_POLICY}
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://ingoibibo-prod.s3-ap-south-1.amazonaws.com/static/14.02.19_User%20Agreement_Ingo%20MMT%20for%20Hotels.pdf"
              >
                {COMMON_STRINGS.LABELS.TERMS_OF_USE}
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/static/html/privacy_policy.html" // Policy works on npp only, not in  local env
              >
                {COMMON_STRINGS.LABELS.LISTING_POLICY}
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-download-app-section sr-flex1">
          <p className="f-500 font16">{STRINGS.FOOTER.FOOTER_ARRAY[2]}</p>
          <div className="flex">
            <div
              className="google-play sr-cursor"
              onClick={pushGAevent.bind(this, 'google_play')}
            ></div>
            <div
              className="app-store sr-cursor"
              onClick={pushGAevent.bind(this, 'app_store')}
            ></div>
          </div>
        </div>
        <div className="footer-query-section sr-flex1">
          {isActiveHotel && (
            <p className="f-500 font16 grey-color">
              {STRINGS.FOOTER.FOOTER_ARRAY[3]}
            </p>
          )}
          <div className="d-flex space-between">
            {!!footerDetails.contactDetails &&
              isActiveHotel &&
              Object.keys(footerDetails.contactDetails).map(item => {
                if (footerDetails.contactDetails[item]) {
                  return (
                    <div className="f-500 font16">
                      <p className="grey font12 fmed marginB3">
                        {designationMapping[item]}
                      </p>
                      <p className="black font16 fmed marginB7">
                        {footerDetails.contactDetails[item].name}
                      </p>
                      <p className="grey font13 marginB3">
                        <a
                          style={{
                            color: '#767a83',
                            textDecoration: 'underline',
                          }}
                          href={`mailto:${footerDetails.contactDetails[item].email}`}
                        >
                          {footerDetails.contactDetails[item].email}
                        </a>
                      </p>
                      <p className="grey font13">
                        {footerDetails.contactDetails[item].mobile}
                      </p>
                    </div>
                  );
                }
                return null;
              })}
          </div>
        </div>
      </div>

      <hr className="grey-color" />

      <div className="text-right m-t-10">
        <span className="grey-color copy-right font-16">&copy;</span>
        <span className="grey-color">
          {new Date().getFullYear()} {STRINGS.FOOTER.RIGHTS_RESERVED}
        </span>
      </div>
      {showAppDownloadPopUp && (
        <AppDownloadPopUp
          closeModal={() => {
            setAppDownloadPopUp(false);
          }}
          STRINGS={STRINGS}
          COMMON_STRINGS={COMMON_STRINGS}
        />
      )}
    </div>
  );
}

export default Footer;
