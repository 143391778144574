import * as Sentry from '@sentry/react';
import { isProd } from 'base/APIClient';

const SENTRY = {
  init: userInfo => {
    if (!isProd) return;
    const userObj = {
      id: userInfo?.profile.id,
      isStaff: userInfo?.profile?.is_staff,
      isActive: userInfo?.profile?.is_active,
    };
    Sentry.init({
      dsn: 'https://88f889aad26d48ed91a97076dffffb25@o162542.ingest.sentry.io/6389487',
      allowUrls: ['https://in.goibibo.com/newextranet/'],
      release: import.meta.env.VITE_EXTRANET_BUILD_ID?.replace(
        'ingo-extranet:',
        '',
      ),
      initialScope: {
        user: userObj,
      },
      environment: 'prod',
      beforeSend: function (event, hint) {
        const exception = hint.originalException;
        event.fingerprint = [
          '{{ level }}',
          (typeof exception === 'string'
            ? exception
            : (exception as Error)?.message) ?? '',
        ];
        return event;
      },
    });
  },
  logError: errorObj => {
    if (!isProd) return;
    Sentry.withScope(function (scope) {
      scope.setTag('errorType', 'BE');
      Sentry.captureException(errorObj);
    });
  },
  logFatalException: err => {
    if (!isProd) return;
    Sentry.withScope(function (scope) {
      scope.setLevel('fatal');
      scope.setTag('errorType', 'FE');
      Sentry.captureException(err);
    });
  },
};

export default SENTRY;
