import { createClient, OperationContext } from '@urql/core';
import {
  getAPIToken,
  isNullOrUndefined,
  showGlobalLoader,
} from 'utilities/Utils';
import {
  enigmaGraphBase as url,
  isProd,
  showError as errorToast,
  sendApiOrQueryErrorOmnitureEvent,
} from './APIClient';
import SENTRY from '../utilities/Sentry';
import { LogoutConfirmEvent } from 'utilities/EventBus';

// eslint-disable-next-line
export type UnSafe = any;

type RequestConfig = {
  headers: {
    authorization: string;
    'meta-data': string;
    'meta-data-source': string;
    'meta-data-platform': string;
    'meta-data-brand': string;
    platform: string;
    language: string;
    country: string;
    onboarding?: string;
  };
};

function handleGraphQLError(extensions, error) {
  if (extensions?.support) {
    const err = new Error(
      `[ENIGMA_ERROR]: Issue found in ${extensions.support.query_name} query with transactions ID: ${extensions.support.transaction_id}`,
    );
    SENTRY.logError(err);
  } else if (error) {
    SENTRY.logError(error);
  }
}

function fetchOptions() {
  const token = getAPIToken();
  const requestConfig: RequestConfig = {
    headers: {
      authorization: token ? `Token ${token}` : '',
      'meta-data': JSON.stringify({ source: 'extranet' }),
      'meta-data-source': 'ingo_web',
      'meta-data-platform': 'web',
      'meta-data-brand': 'INGO',
      platform: 'Desktop',
      language: 'en',
      country: 'in',
    },
  };

  if (!isProd) {
    requestConfig.headers['print-curl-response'] = true;
  }

  // For future usage if engima is used in onboarding screens
  if (window.location.href.includes('/onboarding')) {
    requestConfig.headers.onboarding = 'true';
  } else if (requestConfig.headers.onboarding) {
    delete requestConfig.headers.onboarding;
  }
  return requestConfig;
}

function createContext(headers) {
  const context: Partial<OperationContext> = {};
  if (!isNullOrUndefined(headers)) {
    const existing = fetchOptions();

    if (headers.url) {
      context.url = headers.url;
      delete headers.url;
    }

    context.fetchOptions = { headers: { ...existing.headers, ...headers } };
  }
  return context;
}

// Request configuration changing default to network-only
const requestPolicy = 'network-only';

const client = createClient({
  url,
  fetchOptions,
  requestPolicy,
  fetch: (...args: Parameters<typeof fetch>) => {
    const [originalUrl, otherArgs] = args;
    let urlWithQuery = originalUrl;
    // Add something like ?query=FetchAnalytics to help identifying API call
    // ...in network tab without checking its request-body content
    if (!isProd && typeof otherArgs?.body === 'string') {
      const regex = /(query|mutation)\s+([^(]+)/;
      const match = (otherArgs.body as string).match(regex);
      if (match && typeof match[2] === 'string') {
        urlWithQuery += `?${match[1]}=${match[2]}`;
      }
    }
    return fetch(urlWithQuery, otherArgs);
  },
});

const responseInterceptorHandler = (error, omnitureData) => {
  omnitureData = isNullOrUndefined(omnitureData)
    ? { cta: {} }
    : { ...omnitureData };
  const errObj = error?.graphQLErrors?.[0]?.extensions;
  omnitureData.cta.misc_id_2 = errObj?.id || error?.response?.status; // status code
  omnitureData.loadedComponents = 'GraphQL error';

  /*
    If the error is 401, we need to logout the user
    Detecting Frontend OR BE error will be done in sendApiOrQueryErrorOmnitureEvent
  */
  if ((errObj && errObj?.id === 401) || error?.response?.status === 401) {
    LogoutConfirmEvent.emit('logoutUser');
  }

  sendApiOrQueryErrorOmnitureEvent({ error, omnitureData });
};

export function doQuery<T = UnSafe>(
  query,
  variables,
  { useLoader = true, showError = true, headers = null } = {},
  omnitureData = null,
): Promise<{ data: T; error: UnSafe; errors? }> {
  return new Promise((resolve, reject) => {
    omnitureData = isNullOrUndefined(omnitureData)
      ? { cta: { misc_id_3: '' }, loadedComponents: '' }
      : { ...omnitureData };

    omnitureData.cta.misc_id_3 = query.split('(')?.[0]; // {query name/api call name}

    if (useLoader) {
      showGlobalLoader(true);
    }

    const failureHandler = error => {
      responseInterceptorHandler(error, omnitureData);

      if (showError) {
        errorToast(error);
      }
      reject(error);
    };

    const context = createContext(headers);

    client
      .query(query, variables, context)
      .toPromise()
      .then(res => {
        const { error, data, extensions } = res;
        handleGraphQLError(extensions, error);

        if (!isNullOrUndefined(data)) {
          if (res?.error) {
            omnitureData.loadedComponents = 'GraphQL error';
            sendApiOrQueryErrorOmnitureEvent({ error: res, omnitureData });
          }
          resolve({ data, error });
        } else failureHandler(error);
      })
      .catch(failureHandler)
      .finally(() => {
        if (useLoader) {
          showGlobalLoader(false);
        }
      });
  });
}

export function doMutation<T = UnSafe>(
  query,
  variables,
  { useLoader = true, showError = true, headers = null } = {},
  omnitureData = null,
): Promise<{ data: T; error: UnSafe; errors? }> {
  return new Promise((resolve, reject) => {
    omnitureData = isNullOrUndefined(omnitureData)
      ? { cta: { misc_id_3: '' }, loadedComponents: '' }
      : { ...omnitureData };

    omnitureData.cta.misc_id_3 = query.split('(')?.[0]; // {query name/api call name}

    if (useLoader) {
      showGlobalLoader(true);
    }

    const failureHandler = error => {
      responseInterceptorHandler(error, omnitureData);
      if (showError) {
        errorToast(error);
      }
      reject(error);
    };

    const context = createContext(headers);

    client
      .mutation(query, variables, context)
      .toPromise()
      .then(res => {
        const { error, data, extensions } = res;
        handleGraphQLError(extensions, error);

        if (!isNullOrUndefined(data)) {
          if (res.error) {
            omnitureData.loadedComponents = 'GraphQL error';
            sendApiOrQueryErrorOmnitureEvent({ error: res, omnitureData });
          }
          resolve({ data, error });
        } else {
          failureHandler(error);
        }
      })
      .catch(err => {
        failureHandler(err);
      })
      .finally(() => {
        if (useLoader) {
          showGlobalLoader(false);
        }
      });
  });
}
