import { colors, typography } from 'style/zeplin-token-styledComponents';
import styled from 'styled-components';

export const TickerContainer = styled.div`
  display: flex;
  overflow: hidden;
  height: 50px !important;
  margin-left: 12px;
  position: relative;
  > div {
    display: flex;
    align-items: center;
  }
  .slick-vertical .slick-slide {
    border: none !important;
  }
  .slick-slide {
    height: 50px !important;
  }
  .slick-slide > div {
    height: 50px !important;
  }
`;

export const TickerItem = styled.span`
  height: 50px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${colors.white};
  ${typography.shoutoutBlack};
  white-space: nowrap;
`;
