import { useCallback, useReducer, Reducer } from 'react';
import reducer, { initialState } from '.';
import { error, fetching, reset, success, update } from './actionCreaters';
import { API_MAPPER } from './apiMapper';
import { ApiAction, ApiState } from './types';

const useApiRequest = () => {
  const [state, dispatch] = useReducer<Reducer<ApiState, ApiAction>>(
    reducer,
    initialState,
  );

  const makeRequest = useCallback(async (api, payload) => {
    dispatch(fetching(api));
    try {
      const response = await API_MAPPER[api](payload);
      dispatch(success(api, response));
    } catch (e) {
      dispatch(error(api, e));
    }
  }, []);

  const resetRequest = useCallback(api => {
    dispatch(reset(api));
  }, []);

  const updateApiData = useCallback((api, payload) => {
    dispatch(update(api, payload));
  }, []);

  return [state, makeRequest, resetRequest, updateApiData] as const;
};

export default useApiRequest;
