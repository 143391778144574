import styled from 'styled-components';
import {
  colors,
  typography,
} from '../../../../style/zeplin-token-styledComponents';

export const FooterBarWrapper = styled.div`
  background-color: ${colors.grayHighEmphasis};
  padding: 28px 0;
  min-width: 1280px;
`;

export const FooterBarInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & p {
    ${typography.subTextRegular};
    color: ${colors.grayLowEmphasis};
  }
`;

export const SocialLinks = styled.ul`
  list-style-type: none;
  display: flex;
  gap: 15px;
  margin: 0;
  li {
    display: flex;
    align-items: center;
  }
  li a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
`;
