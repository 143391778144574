import { isNullOrUndefined } from 'utilities/Utils';
import axios, { apiWrapper } from '../../../base/APIClient';
import { APP_MAIN, PROPERTY } from '../../../base/Url';
import {
  areAllAccountsPending,
  isAnyAccountPending,
  ACCOUNT_STATUS,
  isRejectedBecauseOfMismatch,
  isAnyAccountRejected,
  isRejecteBecauseOfIncorrectAltAccoDocs,
} from './utils';

const { BANK_DETAILS } = PROPERTY;

// Parsers
const parseBankDetails = ({ tan, pan, bank, gst, ldc_info }, qcReasonList) => {
  /**
   * If PAN or TAN is empty create empty object to add PAN/TAN
   */
  let hasTAN = true;
  let hasPAN = true;
  let hasBank = true;
  let hasGST = true;

  if (tan.tan_details.length === 0 && tan.corporate_tan_details.length === 0) {
    const newTAN = {
      brand: 'both',
      tan_number: '',
    };

    tan.tan_details[0] = newTAN;
    hasTAN = false;
  }

  pan.hasPAN = true;
  if (pan.pan_details.length === 0 && pan.corporate_pan_details.length === 0) {
    const newPAN = {
      brand: 'both',
      name_on_pan: '',
      pan_number: '',
    };

    pan.pan_details[0] = newPAN;
    hasPAN = false;
  }

  if (
    bank.bank_details.length === 0 &&
    bank.corporate_bank_details.length === 0
  ) {
    const newAcccount = {
      status: '',
      bank_name: '',
      vendor_id: null,
      account_type: 'Savings',
      reject_reason: '',
      city: '',
      country: '',
      brand: 'both',
      ifsc: '',
      payment_term_code: '',
      branch_name: '',
      is_corporate: false,
      iban: '',
      account_number: '',
      address: '',
      vendor_type: null,
      account_name: '',
    };
    bank.bank_details[0] = newAcccount;
    hasBank = false;
  }

  if (
    Object.keys(gst.gstn_details).length === 0 &&
    gst.corporate_gstn_details.length === 0
  ) {
    const newGST = {
      gstn: '',
    };
    gst.gstn_details = newGST;
    hasGST = false;
  }

  /**
   * QC rejection
   */

  let vendorMismatchReason = null;
  let qcAltAccoIncorrectDocsReason = null;
  let qcMismatchReason = null;

  const rejectedAccounts = isAnyAccountRejected(bank.bank_details);
  if (!isNullOrUndefined(qcReasonList)) {
    const vendorReasonList = rejectedAccounts[0]?.reject_reason_list;

    vendorMismatchReason = isRejectedBecauseOfMismatch(vendorReasonList);
    qcMismatchReason = isRejectedBecauseOfMismatch(qcReasonList);
    qcAltAccoIncorrectDocsReason =
      isRejecteBecauseOfIncorrectAltAccoDocs(qcReasonList);

    /**
     * QC reasons are given higher priority than vendor reasons
     */

    const reason =
      qcMismatchReason ||
      (qcReasonList && qcReasonList[qcReasonList.length - 1]);

    if (reason) {
      bank.bank_details.forEach(item => {
        item.status = 'REJECTED';
        item.id = reason.id;
        item.reject_reason = reason.text;
      });
    }
  }

  const allAccountsPending = areAllAccountsPending(bank.bank_details);
  const pendingAccounts = isAnyAccountPending(bank.bank_details);

  tan.tan_details.map(item => {
    if (pendingAccounts.length) {
      if (item.brand === 'both') {
        item.status = ACCOUNT_STATUS.PENDING;
      } else {
        pendingAccounts.forEach(account => {
          if (item.brand === account.brand) {
            item.status = ACCOUNT_STATUS.PENDING;
          }
        });
      }
    }
    if (allAccountsPending) {
      item.status = ACCOUNT_STATUS.PENDING;
    }

    return item;
  });

  pan.pan_details.map(item => {
    if (pendingAccounts.length) {
      if (item.brand === 'both') {
        item.status = ACCOUNT_STATUS.PENDING;
      } else {
        pendingAccounts.forEach(account => {
          if (item.brand === account.brand) {
            item.status = ACCOUNT_STATUS.PENDING;
          }
        });
      }
    }
    if (allAccountsPending) {
      item.status = ACCOUNT_STATUS.PENDING;
    }

    return item;
  });

  /**
   * LDC Info to be shown to the user
   */
  const ldcStatement =
    ldc_info?.length > 0 ? ldc_info.map(item => item.statement) : null;
  if (!isNullOrUndefined(qcReasonList)) {
    return {
      vendorMismatchReason,
      qcMismatchReason,
      qcAltAccoIncorrectDocsReason,
      pan,
      bank,
      gst,
      tan,
      ldcStatement,
      hasPAN,
      hasBank,
      hasGST,
      hasTAN,
    };
  }
  return {
    pan,
    bank,
    gst,
    tan,
    ldcStatement,
    hasPAN,
    hasBank,
    hasGST,
    hasTAN,
  };
};

export const getBankList = hotelCode => {
  const url = BANK_DETAILS.GET_BANK_LIST(hotelCode);
  return apiWrapper(axios.get(url)).then(res => res);
};

export const getQCReasons = (hotelcode, useLoader) => {
  const url = BANK_DETAILS.GET_QC_REASONS(hotelcode);
  return apiWrapper(axios.get(url, { useLoader })).then(res => res.data);
};

export const getBankDetails = (hotelCode, is_masked, useLoader) => {
  let url = BANK_DETAILS.GET_BANK_DETAILS(hotelCode);

  if (is_masked) {
    url += '?is_masked=1';
  }

  const getBankDetailsPromise = apiWrapper(axios.get(url, { useLoader })).then(
    res => res.data,
  );

  // Conditionally run the second API call based on the value of useLoader
  if (!useLoader) {
    return getBankDetailsPromise.then(bankRes => {
      return parseBankDetails(bankRes, null); // Pass null as qcRes
    });
  }
  const qcReasonsPromise = getQCReasons(hotelCode, useLoader).then(
    data => data.reject_reasons,
  );

  return Promise.all([getBankDetailsPromise, qcReasonsPromise]).then(
    ([bankRes, qcRes]) => {
      return parseBankDetails(bankRes, qcRes);
    },
  );
};

export const getServiceCharge = hotelCode => {
  const url = BANK_DETAILS.GET_SERVICE_CHARGE(hotelCode);
  return apiWrapper(axios.get(url, { useLoader: true })).then(res => res);
};

export const updateServiceCharge = (gstCode, payload) => {
  const gstId = gstCode.split('GS')[1];
  const url = BANK_DETAILS.UPDATE_SERVICE_CHARGE(gstId);
  return apiWrapper(axios.put(url, payload, { useLoader: true })).then(
    res => res,
  );
};

export const updateExtraGuestCharge = (hotelCode, flag) => {
  const url = APP_MAIN.UPDATE_HOTEL_INFO;
  return apiWrapper(
    axios.post(
      url,
      {
        add_extraguest_to_declared_flag: flag,
        detail: 'pricingparams',
        hotelcode: hotelCode,
      },
      { useLoader: true },
    ),
  ).then(res => res);
};

export const getServiceTax = hotelCode => {
  const url = BANK_DETAILS.GET_SERVICE_TAX(hotelCode);
  return apiWrapper(axios.get(url, { useLoader: true })).then(res => res);
};

export const updateServiceTax = (taxCode, payload) => {
  const url = BANK_DETAILS.UPDATE_SERVICE_TAX(taxCode);
  return apiWrapper(axios.put(url, payload, { useLoader: true })).then(
    res => res,
  );
};

export const createServiceTax = payload => {
  const url = BANK_DETAILS.CREATE_SERVICE_TAX();
  return apiWrapper(axios.post(url, payload, { useLoader: true })).then(
    res => res,
  );
};

export const adharVerify = (hotelCode, payload) => {
  const url = BANK_DETAILS.ADHAR_VERIFICATION(hotelCode);

  return apiWrapper(axios.get(url, { params: payload, useLoader: true })).then(
    res => res.data,
  );
};

export const updateBankDetails = (hotelCode, payload) => {
  const url = BANK_DETAILS.UPDATE_BANK_DETAILS(hotelCode);
  return apiWrapper(axios.put(url, payload, { useLoader: true })).then(
    res => res,
  );
};

export const getCurrencies = hotelCode => {
  const url = BANK_DETAILS.GET_CURRENCIES(hotelCode);
  return apiWrapper(axios.get(url, { useLoader: true })).then(res => res);
};

export const togglePayment = (hotelCode, payload) => {
  const url = BANK_DETAILS.TOGGLE_PAYMENT(hotelCode);
  return apiWrapper(axios.put(url, payload, { useLoader: true })).then(
    res => res,
  );
};

export const triggerAccessCodeOTP = () => {
  const url = BANK_DETAILS.TRIGGER_ACCESS_CODE_OTP();
  return apiWrapper(axios.get(url)).then(res => res);
};

export const resetAccessCode = payload => {
  const url = BANK_DETAILS.VALIDATE_OTP();
  return apiWrapper(axios.put(url, payload, { useLoader: true })).then(
    res => res,
  );
};

export const validateOTP = otp => {
  const url = BANK_DETAILS.VALIDATE_OTP();
  const payload = {
    newpassword: '',
    otp,
  };
  return apiWrapper(axios.put(url, payload, { useLoader: true })).then(
    res => res,
  );
};

export const validateAccessCode = payload => {
  const url = BANK_DETAILS.VALIDATE_ACCESS_CODE();
  return apiWrapper(axios.post(url, payload, { useLoader: true })).then(
    res => res,
  );
};

export const getHistory = hotelCode => {
  const url = BANK_DETAILS.GET_HISTORY(hotelCode);
  return apiWrapper(axios.get(url, { useLoader: true })).then(res => {
    const { bank_account_history, pan_history, gst_history, tan_history } =
      res.data;

    const reduceByBrand = (acc, item) => {
      const { brand } = item;
      if (!acc[brand]) {
        acc[brand] = [];
      }

      acc[brand].push(item);
      return acc;
    };

    pan_history.pan_details = pan_history.pan_details.reduce(reduceByBrand, {});
    tan_history.tan_details = tan_history.tan_details.reduce(reduceByBrand, {});

    return {
      bank_account_history,
      pan_history,
      gst_history,
      tan_history,
    };
  });
};

export const getDocuments = hotelCode => {
  const url = BANK_DETAILS.GET_DOCUMENTS(hotelCode);
  return apiWrapper(axios.get(url, { useLoader: true })).then(res => res);
};

export const acceptNocAgreements = (payload, hotelcode) => {
  const url = BANK_DETAILS.NOC_AGREEMENTS(hotelcode);
  return apiWrapper(axios.put(url, payload, { useLoader: true })).then(
    res => res,
  );
};

export const acceptAgreements = payload => {
  const url = BANK_DETAILS.ACCEPT_AGREEMENTS();
  return apiWrapper(axios.post(url, payload, { useLoader: true })).then(
    res => res,
  );
};

export const acceptFCLAgreements = (hotelcode, payload) => {
  const url = BANK_DETAILS.ACCEPT_FCL_TC(hotelcode);
  return axios.post(url, payload, { useLoader: true });
};

export const uploadDocument = (hotelcode, payload) => {
  const url = BANK_DETAILS.UPLOAD_DOCUMENT(hotelcode);
  return apiWrapper(axios.post(url, payload, { useLoader: true })).then(
    res => res,
  );
};

export const pushToStateMachine = (
  hotelcode,
  payload = {
    state: 7,
  },
) => {
  const url = BANK_DETAILS.PUSH_TO_STATE_MACHINE(hotelcode);
  return apiWrapper(axios.post(url, payload, { useLoader: true })).then(
    res => res,
  );
};

export const pushForFinanceApproval = (hotelcode, payload = {}) => {
  const url = BANK_DETAILS.PUSH_FOR_FINANCE_APPROVAL(hotelcode);
  return apiWrapper(axios.post(url, payload, { useLoader: true })).then(
    res => res,
  );
};
