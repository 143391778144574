import React from 'react';
import {
  DownloadAppLogo,
  ConnectBlack,
  ConnectWhite,
} from 'assets/modules/landing';
import {
  HeaderBtnDownload,
  HeaderBtnSignIn,
  HeaderBtns,
  HeaderInner,
  HeaderNavBar,
  HeaderRightContainer,
  HeaderWrapper,
  HeaderLogo,
} from './Header.styles';
import { LandingPageContainer } from 'modules/landingPage/LandingPage.styles';
import useStrings from 'modules/landingPage/hooks/useStrings';
import { CONTENT_CONFIG } from 'modules/landingPage/constants';
import { getValue } from 'utilities/Utils';
import { OMNITURE_CATEGORIES, pushToOmniture } from 'utilities/gtm';
import { COMMON_OMNITURE_VARIABLES } from '../../constants';

interface HeaderSectionProps {
  scrollY: number;
  scrollToElement: (section: string) => void;
}

const HeaderSection = ({ scrollY, scrollToElement }: HeaderSectionProps) => {
  const [STRINGS] = useStrings();
  const isHeaderSticky = scrollY > 75;

  const handleLogoClick = () => {
    pushToOmniture({
      ...COMMON_OMNITURE_VARIABLES,
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      cta: {
        name: 'logo',
        type: 'click',
        componentName: 'header',
      },
    });
  };

  return (
    <HeaderWrapper issticky={isHeaderSticky ? true : undefined}>
      <LandingPageContainer>
        <HeaderInner>
          <HeaderLogo
            href="#"
            aria-label="app_logo"
            onClick={handleLogoClick}
            className="mt-1"
          >
            {isHeaderSticky ? <ConnectBlack /> : <ConnectWhite />}
          </HeaderLogo>
          <HeaderRightContainer>
            <HeaderNavBar issticky={isHeaderSticky ? true : undefined}>
              <ul>
                {CONTENT_CONFIG.navLinks.map((navLink, index) => {
                  return (
                    <li
                      onClick={() =>
                        scrollToElement(getValue(STRINGS, navLink.text))
                      }
                      key={index}
                    >
                      {getValue(STRINGS, navLink.text)}
                    </li>
                  );
                })}
              </ul>
            </HeaderNavBar>
            <HeaderBtns>
              <HeaderBtnDownload
                issticky={isHeaderSticky ? true : undefined}
                onClick={() => scrollToElement(STRINGS.header.download_app)}
              >
                <DownloadAppLogo />
                <span>{STRINGS.header.download_app}</span>
              </HeaderBtnDownload>
              <HeaderBtnSignIn
                onClick={() => scrollToElement(STRINGS.header.signUp)}
              >
                {STRINGS.header.LIST_NEW_PROPERTY_FOR_FREE}
              </HeaderBtnSignIn>
            </HeaderBtns>
          </HeaderRightContainer>
        </HeaderInner>
      </LandingPageContainer>
    </HeaderWrapper>
  );
};

export default HeaderSection;
