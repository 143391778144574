import { apiDateFormat, apiDateFormatV2 } from '../../../constants';
import { getFormattedDate, getFutureDate } from 'utilities/DateUtils';

export const getTopCardsDataQuery = `query AnalyticsHistogram($rawEndpointRequestData: RawEndpointsRequestData){
    rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData){
      analyticsHistogram{
        modelType1 {
          data
          message
          status
          status_code
        }
      }
    }
  }`;

export const getTopCardsDataVariables = data => {
  return {
    rawEndpointRequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body: data,
      },
    },
  };
};

export const getCardsQuery = `query AnalyticsHistogram(
  $AnalyticsHistogram1RequestData: RawEndpointsRequestData,
  $AnalyticsHistogram2RequestData: RawEndpointsRequestData,
  $AnalyticsHistogram3RequestData: RawEndpointsRequestData,
  $AnalyticsHistogram4RequestData: RawEndpointsRequestData,
  $AnalyticsHistogram5RequestData: RawEndpointsRequestData,
  $AnalyticsHistogram6RequestData: RawEndpointsRequestData) {
    analyticsHistogram1: rawEndpoint(rawEndpointRequestData: $AnalyticsHistogram1RequestData) {
      analyticsHistogram {
        modelType1 {
          data
          message
          status
          status_code
        }
      }
    }
    analyticsHistogram2: rawEndpoint(rawEndpointRequestData: $AnalyticsHistogram2RequestData) {
      analyticsHistogram {
        modelType1 {
          data
          message
          status
          status_code
        }
      }
    }
    analyticsHistogram3: rawEndpoint(rawEndpointRequestData: $AnalyticsHistogram3RequestData) {
      analyticsHistogram {
        modelType1 {
          data
          message
          status
          status_code
        }
      }
    }
    analyticsHistogram4: rawEndpoint(rawEndpointRequestData: $AnalyticsHistogram4RequestData) {
      analyticsHistogram {
        modelType1 {
          data
          message
          status
          status_code
        }
      }
    }
    analyticsHistogram5: rawEndpoint(rawEndpointRequestData: $AnalyticsHistogram5RequestData) {
      analyticsHistogram {
        modelType1 {
          data
          message
          status
          status_code
        }
      }
    }
    analyticsHistogram6: rawEndpoint(rawEndpointRequestData: $AnalyticsHistogram6RequestData) {
      analyticsHistogram {
        modelType1 {
          data
          message
          status
          status_code
        }
      }
    }
  }
`;

export const getCardsVariables = (hotelId: string) => {
  const today = new Date();
  const tomorrow = getFutureDate(today, 1);
  const past7thDay = getFutureDate(today, -7);

  const specialToday = getFutureDate(today, -1);
  return {
    AnalyticsHistogram1RequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body: {
          pipeline: 'transactions',
          include: {
            hotelId: hotelId,
            confirmStatusValue: [
              'pending',
              'confirmed',
              'amended',
              'noshow',
              'aborted',
            ],
          },
          intervals: [
            {
              field: 'bookingDate',
              type: 'range',
              range_intervals: [
                [
                  'Last 7 Days',
                  getFormattedDate(past7thDay, apiDateFormatV2),
                  getFormattedDate(today, apiDateFormatV2),
                ],
                [
                  'Today',
                  getFormattedDate(today, apiDateFormatV2),
                  getFormattedDate(tomorrow, apiDateFormatV2),
                ],
              ],
            },
          ],
          KPI: [
            {
              netAmount: 'sum',
            },
          ],
        },
      },
    },
    AnalyticsHistogram2RequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body: {
          pipeline: 'transactions',
          include: {
            hotelId: hotelId,
            confirmStatusValue: [
              'pending',
              'confirmed',
              'amended',
              'noshow',
              'aborted',
            ],
          },
          intervals: [
            {
              field: 'bookingDate',
              type: 'range',
              range_intervals: [
                [
                  'Last 7 Days',
                  getFormattedDate(past7thDay, apiDateFormatV2),
                  getFormattedDate(today, apiDateFormatV2),
                ],
                [
                  'Today',
                  getFormattedDate(today, apiDateFormatV2),
                  getFormattedDate(tomorrow, apiDateFormatV2),
                ],
              ],
            },
          ],
          KPI: [
            {
              roomNights: 'sum',
            },
          ],
        },
      },
    },
    AnalyticsHistogram3RequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body: {
          pipeline: 'transactions',
          include: {
            hotelId: hotelId,
            confirmStatusValue: [
              'pending',
              'confirmed',
              'amended',
              'noshow',
              'aborted',
            ],
          },
          intervals: [
            {
              field: 'bookingDate',
              type: 'range',
              range_intervals: [
                [
                  'Last 7 Days',
                  getFormattedDate(past7thDay, apiDateFormatV2),
                  getFormattedDate(today, apiDateFormatV2),
                ],
                [
                  'Today',
                  getFormattedDate(today, apiDateFormatV2),
                  getFormattedDate(tomorrow, apiDateFormatV2),
                ],
              ],
            },
          ],
          KPI: [
            {
              avgSellPrice: 'custom',
            },
          ],
        },
      },
    },
    AnalyticsHistogram4RequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body: {
          pipeline: 'demand_v2',
          include: {
            'hotel.hotelCode': hotelId,
          },
          intervals: [
            {
              field: 'searchDate',
              type: 'range',
              range_intervals: [
                [
                  'Last 7 Days',
                  getFormattedDate(past7thDay, apiDateFormat),
                  getFormattedDate(today, apiDateFormat),
                ],
                [
                  'Today',
                  getFormattedDate(specialToday, apiDateFormat),
                  getFormattedDate(today, apiDateFormat),
                ],
              ],
            },
          ],
          KPI: [
            {
              visits: 'sum',
            },
          ],
        },
      },
    },
    AnalyticsHistogram5RequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body: {
          pipeline: 'transactions',
          include: {
            hotelId: hotelId,
            confirmStatusValue: [
              'pending',
              'confirmed',
              'amended',
              'noshow',
              'aborted',
            ],
          },
          intervals: [
            {
              field: 'bookingDate',
              type: 'range',
              range_intervals: [
                [
                  'Last 7 Days',
                  getFormattedDate(past7thDay, apiDateFormatV2),
                  getFormattedDate(today, apiDateFormatV2),
                ],
                [
                  'Today',
                  getFormattedDate(specialToday, apiDateFormatV2),
                  getFormattedDate(today, apiDateFormatV2),
                ],
              ],
            },
          ],
          KPI: [
            {
              conversionRate: 'custom',
            },
          ],
        },
      },
    },
    AnalyticsHistogram6RequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body: {
          pipeline: 'transactions',
          include: {
            hotelId: hotelId,
            confirmStatusValue: [
              'pending',
              'confirmed',
              'amended',
              'noshow',
              'aborted',
            ],
          },
          intervals: [
            {
              field: 'checkin',
              type: 'range',
              range_intervals: [
                [
                  'Last 7 Days',
                  getFormattedDate(past7thDay, apiDateFormat),
                  getFormattedDate(today, apiDateFormat),
                ],
                [
                  'Today',
                  getFormattedDate(today, apiDateFormat),
                  getFormattedDate(tomorrow, apiDateFormat),
                ],
              ],
            },
          ],
          KPI: [
            {
              parentVendorBookingId: 'cardinality',
            },
          ],
        },
      },
    },
  };
};

export const getWhatsNewMetaDataQuery = `query AnalyticsDocuments(
  $rawEndpointRequestData: RawEndpointsRequestData) {
    rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
      analyticsDocuments {
        modelType1 {
          data
          message
          status
          status_code
        }
      }
    }
  }
`;
