import { isNullOrUndefined } from 'utilities/Utils';
import { doQuery, doMutation } from '../../base/GraphQLClient';
import { filterTypes } from './SponsoredListingConstants';
import {
  GET_SPONSORED_LIST,
  GET_SPONSORED_PACKAGES,
  INITIATE_PAYMENT,
  PAYMENT_STATUS,
  GET_REFUND_CAMPAIGN_REASON,
  GET_REFUND_DETAILS_CAMPAIGN,
  PUSH_TO_SANDESH,
  GET_REPORTING_DETAILS,
  SOLD_OUT_NOTIFY,
  GET_REPORTINGDASHBOARD_PS_LIST,
  GET_TOUCH_POINTS_DETAILS,
  GET_GRAPH_API,
  IS_SL_SDK_ENABLED,
} from './SponsoredListingQuery';

//setting Auth header for authentication
//mmt_id is treated as Hotel's Locusid in mmt
const contextHeaders = {
  isAdtechCall: true,
};

function getMmtGiId(mmtid, voyagerId, hotelCode) {
  const body = [];
  body.push({
    id: hotelCode,
    idType: 'INGO',
  });
  if (!isNullOrUndefined(mmtid)) {
    body.push({
      id: mmtid,
      idType: 'MMT',
    });
  }
  if (!isNullOrUndefined(voyagerId)) {
    body.push({
      id: voyagerId,
      idType: 'GI',
    });
  }
  return body;
}

function getStatsVariables(mmtid, voyagerId, hotelCode) {
  const requestObject = {
    modelType: 'modelType1',
    body: {
      requestingLob: 'INGO',
      reportType: 'ALL',
    },
  };
  requestObject.body.entityIds = getMmtGiId(mmtid, voyagerId, hotelCode);
  return requestObject;
}

function getWelcomeVariabled(mmtid, voyagerId, hotelCode) {
  const requestObject = {
    modelType: 'modelType1',
    body: {
      requestingLob: 'INGO',
    },
  };
  requestObject.body.entityIds = getMmtGiId(mmtid, voyagerId, hotelCode);
  return requestObject;
}

function getReportingDashboard(mmtid, voyagerId, hotelCode) {
  const requestObject = {
    modelType: 'modelType1',
    body: {
      requestedCampaigns: [
        filterTypes.LIVE_FILTER,
        filterTypes.SCHEDULED_FILTER,
        filterTypes.FULFILLED_FILTER,
        filterTypes.REFUNDABLE_FILTER,
      ],
      requestingLob: 'INGO',
    },
  };
  requestObject.body.entityIds = getMmtGiId(mmtid, voyagerId, hotelCode);
  return requestObject;
}

function getAdtechPackages(
  selectedWeek,
  mmtid,
  voyagerId,
  hotelCode,
  gstn,
  pan_number,
  currency,
) {
  const requestObject = {
    modelType: 'modelType1',
    body: {
      totalWeek: selectedWeek,
      requestingLob: 'INGO',
      currency: currency,
      hotelDetails: {
        gstin: gstn,
        pan: pan_number,
      },
    },
  };
  requestObject.body.entityIds = getMmtGiId(mmtid, voyagerId, hotelCode);
  return requestObject;
}

export function getSLOnboardingStatus(mmtid, voyagerId, hotelCode, currency) {
  const query = GET_SPONSORED_LIST;
  const variables = {
    rawEndpointsRequestData: {
      ingoStatsRequest: getStatsVariables(mmtid, voyagerId, hotelCode),
      ingoWelcomeRequest: getWelcomeVariabled(mmtid, voyagerId, hotelCode),
      reportingDashboard: getReportingDashboard(mmtid, voyagerId, hotelCode),
    },
  };

  return doQuery(query, variables, {
    headers: { ...contextHeaders, currency: currency },
  });
}

export function getSoldOutDetails(
  mmtid,
  voyagerId,
  hotelCode,
  currency,
  // eslint-disable-next-line
  selectedWeek = '',
  gstn,
  pan_number,
) {
  const query = GET_REPORTINGDASHBOARD_PS_LIST;
  const variables = {
    rawEndpointsRequestData: {
      adtechGetPackages: getAdtechPackages(
        selectedWeek,
        mmtid,
        voyagerId,
        hotelCode,
        gstn,
        pan_number,
        currency,
      ),
    },
  };

  return doQuery(query, variables, {
    headers: { ...contextHeaders, currency: currency },
  });
}

export function getPackagesData(
  selectedWeek,
  mmtid,
  voyagerId,
  hotelCode,
  gstn,
  pan_number,
  currency,
) {
  const query = GET_SPONSORED_PACKAGES;

  const variables = {
    rawEndpointsRequestData: {
      ingoWelcomeRequest: getWelcomeVariabled(mmtid, voyagerId, hotelCode),
      ingoStatsRequest: getStatsVariables(mmtid, voyagerId, hotelCode),
      adtechGetPackages: getAdtechPackages(
        selectedWeek,
        mmtid,
        voyagerId,
        hotelCode,
        gstn,
        pan_number,
        currency,
      ),
    },
  };

  return doQuery(query, variables, {
    headers: { ...contextHeaders, currency: currency },
  });
}

export function getPayment(
  vendorStateCode,
  campaignName,
  packageId,
  paymentOptionType,
  mmtid,
  voyagerId,
  hotelCode,
  vendor_code,
  vendor_gstn,
  address,
  email,
  mobile,
  name,
  brand,
  currency,
) {
  const query = INITIATE_PAYMENT;
  const variables = {
    rawEndpointsRequestData: {
      adtechBeginCheckout: {
        modelType: 'modelType1',
        body: {
          entityIds: getMmtGiId(mmtid, voyagerId, hotelCode),
          org: brand,
          packageId,
          campaignName,
          paymentType: paymentOptionType,
          vendorCode: vendor_code,
          vendorGstIn: vendor_gstn,
          vendorAddress: address,
          requestingLob: 'INGO',
          userDetails: {
            email: email,
            mobile: mobile,
            name: name,
          },
          vendorStateCode,
        },
      },
    },
  };

  return doQuery(query, variables, {
    headers: { ...contextHeaders, currency: currency },
  });
}

export function getPaymentStatus(txnId, currency) {
  const query = PAYMENT_STATUS;
  const variables = {
    rawEndpointsRequestData: {
      adtechPaymentStatus: {
        modelType: 'modelType1',
        queryParams: {
          txnId,
        },
      },
    },
  };

  return doQuery(query, variables, {
    headers: { ...contextHeaders, currency: currency },
  });
}

export function getRefundCampaignReason(brand, adId, currency) {
  const query = GET_REFUND_CAMPAIGN_REASON;
  const variables = {
    rawEndpointsRequestData: {
      getCampaignRefundReasons: {
        modelType: 'modelType1',
        body: {
          org: brand,
          adId: adId,
        },
      },
    },
  };

  return doQuery(query, variables, {
    headers: { ...contextHeaders, currency: currency },
  });
}

export function getRefundDetailsCampaign(adId, currency) {
  const query = GET_REFUND_DETAILS_CAMPAIGN;
  const variables = {
    rawEndpointsRequestData: {
      adtechGetRefundCampaign: {
        modelType: 'modelType1',
        body: {
          adId: adId,
          requestingLob: 'INGO',
        },
      },
    },
  };

  return doQuery(query, variables, {
    headers: { ...contextHeaders, currency: currency },
  });
}

export function triggerSandesh(
  eventId,
  hotelCode,
  selectedReason,
  reasonFreeText,
  hotelname,
  city,
  campaignName,
  brand,
) {
  const query = PUSH_TO_SANDESH;
  const variables = {
    sandeshKafkaInput: {
      topic: 'NonTransactionLowPriorityMessageFromIngo',
      value: {
        event_id: eventId,
        user_ids: {
          auth_user: [],
          hotel_user: hotelCode,
        },
        event_data: {
          refundReason: selectedReason,
          message: reasonFreeText,
          hotelcode: hotelCode,
          hotelname,
          city,
          campaignName,
          brand: brand,
        },
      },
    },
  };

  return doQuery(query, variables, { useLoader: false });
}

export function triggerSoldOutNotifyMe(
  mmtid,
  voyagerId,
  hotelCode,
  brand,
  currency,
) {
  const query = SOLD_OUT_NOTIFY;
  const variables = {
    rawEndpointsRequestData: {
      ingoNotify: {
        modelType: 'modelType1',
        body: {
          entityIds: getMmtGiId(mmtid, voyagerId, hotelCode),
          orgList: brand,
          requestingLob: 'INGO',
          requestId: 'b28580c1-844c-4369-981c-8019dca28e65',
        },
      },
    },
  };

  return doMutation(query, variables, {
    headers: { ...contextHeaders, currency: currency },
  });
}

export function getReportingDetails(campaignId, currency, hotelCode) {
  const query = GET_REPORTING_DETAILS;

  const variables = {
    rawEndpointsRequestData: {
      reportingGetDetails: {
        modelType: 'modelType1',
        body: {
          requestingLob: 'INGO',
          requestId: 'requestId123',
          adId: campaignId,
          hotelCodes: hotelCode,
        },
      },
    },
  };

  return doQuery(query, variables, {
    headers: { ...contextHeaders, currency: currency },
  });
}

export function getGraphAPI(
  requestList,
  adId,
  brand,
  timeFilter,
  currency,
  hotelCode,
) {
  const query = GET_GRAPH_API;
  const variables = {
    rawEndpointsRequestData: {
      reportingGetGraph: {
        modelType: 'modelType1',
        body: {
          requestList: requestList,
          requestId: '1234',
          adId: adId,
          org: brand,
          timeFilter: timeFilter,
          hotelCodes: hotelCode,
        },
      },
    },
  };

  return doQuery(query, variables, {
    headers: { ...contextHeaders, currency: currency },
  });
}

export function getTouchPointsDetails(
  hotelCode,
  mmtid,
  voyagerId,
  pageType,
  currency,
) {
  const query = GET_TOUCH_POINTS_DETAILS;
  const variables = {
    rawEndpointsRequestData: {
      sponsoredList: {
        modelType: 'modelType1',
        endPointId: 1,
        urlParams: {
          hotelCode: hotelCode,
        },
        body: {
          entityIds: [
            {
              id: mmtid,
              idType: 'MMT',
            },
            {
              id: voyagerId,
              idType: 'GI',
            },
            {
              id: hotelCode,
              idType: 'INGO',
            },
          ],
          requestingLob: 'INGO',
          requestId: 'tst1234',
          pageType: pageType,
        },
      },
    },
  };

  return doQuery(query, variables, {
    useLoader: false,
    headers: { ...contextHeaders, currency: currency },
  });
}

export function checkIfSlSDKEnabledForCity(
  mmtid,
  voyagerId,
  hotelCode,
  currency,
) {
  const query = IS_SL_SDK_ENABLED;
  const variables = {
    rawEndpointsRequestData: {
      ingoWelcomeRequest: getWelcomeVariabled(mmtid, voyagerId, hotelCode),
    },
  };
  return doQuery(query, variables, {
    headers: { ...contextHeaders, currency: currency },
  });
}
