import { colors, typography } from 'style/zeplin-token-styledComponents';
import styled from 'styled-components';

export const ToastOverlay = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
`;

export const ToastWrapper = styled.div`
  padding: 14px 16px 14px 16px;
  border-radius: 8px;
  position: fixed;
  z-index: 1001;
  bottom: 32px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 16px;
  width: 600px;
  align-items: center;
  p {
    font-family: 'Lato' !important;
  }
  &.success {
    background: ${colors.successDefault};
  }
  &.cosmos-error {
    background: ${colors.errorDefault};
    color: ${colors.white};
  }
`;

export const ToastMessage = styled.p`
  flex: 1;
  color: ${colors.white};
  ${typography.bodyTextBaseBlack};
  margin: 0;
`;

export const CounterText = styled.p`
  color: ${colors.white};
  ${typography.microTextRegular};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ToastCounter = styled.div<{ duration: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .circle__item {
    position: relative;
    width: 24px;
    height: 24px;
  }

  .circle__spinner {
    width: 24px;
    height: 24px;
  }

  .circle__spinner .path {
    stroke: ${colors.white};
    stroke-linecap: round;
    animation: ${({ duration }) => `dash ${duration}s linear`};
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 130, 150;
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
  }
`;
