import { colors, typography } from 'style/zeplin-token-styledComponents';
import styled from 'styled-components';

export const FtrLinks = styled.div`
  h6 {
    color: ${colors.white};
    ${typography.microTextBold}
    text-transform: uppercase;
    margin-bottom: 24px;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    margin-bottom: 16px;
  }
  a {
    color: ${colors.white};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  p {
    color: ${colors.white};
    margin-top: 16px;
    line-height: 24px;
  }
`;
